import React, { useEffect, useState } from 'react';
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  BarChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Bar,
  Sector,
} from 'recharts';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Paper,
  CardHeader,
  IconButton,
  Button,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import FullscreenIcon from '@material-ui/icons/Fullscreen'; // Import Fullscreen icon
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { resultStyle } from './textAnalysis';
import IsearcherStyle from '../Layout/IsearcherStyle';

const COLORS = [
  '#1e3246',
  '#dc3223',
  '#19aa6e',
  '#4b5a69',
  '#788291',
  '#d2d7dc',
  '#eb827d',
  '#fad7d2',
  '#d2f0e1',
  '#73cdaa',
  '#9b875f',
  '#afa082',
  '#ebe6dc',
  '#c3b9a0',
  '#C3DDF6',
  '#468AD1',
  '#F8A600',
  '#2f475f',
  '#95372f',
  '#7a231b',
  '#94a2b8',
  '#5b8f75',
  '#546c60',
  '#36443d',
  '#748c80',
  '#4fa7ff',
  '#174471',
  '#384a5c',
  '#acacad',
  '#bdbdbd',
];
const getPath = (x, y, width, height) => `M${x},${y + height}
            C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3} ${
  x + width / 2
}, ${y}
            C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${
  x + width
}, ${y + height}
            Z`;
const TriangleBar = (props) => {
  const { fill, x, y, width, height } = props;

  return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
};

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`PV ${value}`}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        {`(Rate ${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

const COLORS_ENTITY = ['#DC3223', '#19AA6E'];

interface KpisData {
  data1?: {};
  data2?: {};
  data3?: {};
  data4?: {};
  data5?: {};
  payload?: {};
}

const Kpis = ({
  data1 = {},
  data2 = {},
  data3 = {},
  data4 = {},
  data5 = {},
  payload,
}: KpisData) => {
  const classes2 = resultStyle();
  const classes = IsearcherStyle();

  const [graphType, setGraphType] = React.useState<any>('bar');

  const [activeIndex1, setActivate1] = useState(0);
  const [activeIndex2, setActivate2] = useState(0);
  const [activeIndex3, setActivate3] = useState(0);
  const [activeIndex4, setActivate4] = useState(0);
  const [activeIndex5, setActivate5] = useState(0);

  const handleChangeGraph = (event, passedData) => {
    setGraphType(passedData);
    if (passedData === '') {
      setGraphType('bar');
    }
  };

  const onPieEnter1 = (_, index) => {
    setActivate1(index);
  };
  const onPieEnter2 = (_, index) => {
    setActivate2(index);
  };
  const onPieEnter3 = (_, index) => {
    setActivate3(index);
  };
  const onPieEnter4 = (_, index) => {
    setActivate4(index);
  };
  const onPieEnter5 = (_, index) => {
    setActivate5(index);
  };
  const handleFullScreen1 = useFullScreenHandle(); // For the first chart
  const handleFullScreen2 = useFullScreenHandle(); // For the second chart
  const handleFullScreen3 = useFullScreenHandle(); // For the third chart
  const handleFullScreen4 = useFullScreenHandle(); // For the first chart
  const handleFullScreen5 = useFullScreenHandle(); // For the second chart
  const handleFullScreen6 = useFullScreenHandle(); // For the third chart
  const fullScreenStyle = {
    backgroundColor: '#fff', // Match the background color of your CardContent
    padding: '20px', // Add padding if needed
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    placeContent: 'center',
    height: '100vh', // Fullscreen height
    width: '100vw', // Fullscreen width
    boxSizing: 'border-box',
    overflowX: 'scroll',
  };

  return (
    <>
      {data1?.Data1?.length > 0 ||
      data2?.Data?.length > 0 ||
      data3?.Data?.length > 0 ||
      data4?.Data?.length > 0 ||
      data5?.Data?.length > 0 ? (
        <>
          <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ToggleButtonGroup
              size="small"
              value={graphType}
              exclusive
              onChange={handleChangeGraph}
              className={classes.toggle}
            >
              <ToggleButton value="bar">
                <span>
                  <b style={{ textTransform: 'none' }}>BAR GRAPH</b>
                </span>
              </ToggleButton>
              <ToggleButton value="pie">
                <span>
                  <b style={{ textTransform: 'none' }}>PIE GRAPH</b>
                </span>
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          {graphType === 'pie' && (
            <Box className={classes2.ToggleResultContainer}>
              <Box className={classes2.ToggleResBox}>
                {data1?.Data.length > 0 && (
                  <Card className={classes2.cardRoot}>
                    <CardHeader title={`${data1?.name}`} />
                    <CardContent className={classes2.cardContent}>
                      <PieChart width={600} height={400} className={classes2.pieChart}>
                        <Pie
                          isAnimationActive={true}
                          activeIndex={activeIndex1}
                          data={data1?.Data}
                          activeShape={renderActiveShape}
                          cx="50%"
                          cy="50%"
                          innerRadius={90}
                          outerRadius={120}
                          fill="#8884d8"
                          dataKey="value"
                          legendType="circle"
                          onMouseEnter={onPieEnter1}
                        >
                          {data1?.Data.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS_ENTITY[index % COLORS.length]}
                            />
                          ))}
                        </Pie>
                        <Tooltip />
                      </PieChart>
                    </CardContent>
                  </Card>
                )}
                {data2?.Data?.length > 0 && (
                  <Card className={classes2.cardRoot}>
                    <CardHeader title={`${data2?.name}`} />
                    <CardContent className={classes2.cardContent}>
                      <PieChart width={600} height={400} className={classes2.pieChart}>
                        <Pie
                          isAnimationActive={true}
                          activeIndex={activeIndex2}
                          data={data2?.Data}
                          activeShape={renderActiveShape}
                          cx="50%"
                          cy="50%"
                          innerRadius={90}
                          outerRadius={120}
                          fill="#8884d8"
                          dataKey="value"
                          legendType="circle"
                          onMouseEnter={onPieEnter2}
                        >
                          {data2?.Data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                          ))}
                        </Pie>
                        <Tooltip />
                      </PieChart>
                    </CardContent>
                  </Card>
                )}
              </Box>
              <Box className={classes2.ToggleResBox}>
                {data3?.Data?.length > 0 && (
                  <Card className={classes2.cardRoot}>
                    <CardHeader title={`${data3?.name}`} />
                    <CardContent className={classes2.cardContent}>
                      <PieChart className={classes2.pieChart} width={600} height={400}>
                        <Pie
                          isAnimationActive={true}
                          activeIndex={activeIndex3}
                          data={data3?.Data}
                          activeShape={renderActiveShape}
                          cx="50%"
                          cy="50%"
                          innerRadius={90}
                          outerRadius={120}
                          fill="#8884d8"
                          dataKey="value"
                          legendType="circle"
                          onMouseEnter={onPieEnter3}
                        >
                          {data3?.Data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                          ))}
                        </Pie>
                        <Tooltip />
                      </PieChart>
                    </CardContent>
                  </Card>
                )}
                {data4?.Data?.length > 0 && (
                  <Card Card className={classes2.cardRoot}>
                    <CardHeader title={`${data4?.name}`} />
                    <CardContent className={classes2.cardContent}>
                      <PieChart className={classes2.pieChart} width={600} height={400}>
                        <Pie
                          isAnimationActive={true}
                          activeIndex={activeIndex4}
                          data={data4?.Data}
                          activeShape={renderActiveShape}
                          cx="50%"
                          cy="50%"
                          innerRadius={90}
                          outerRadius={120}
                          fill="#8884d8"
                          dataKey="value"
                          legendType="circle"
                          onMouseEnter={onPieEnter4}
                        >
                          {data4?.Data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                          ))}
                        </Pie>
                        <Tooltip />
                      </PieChart>
                    </CardContent>
                  </Card>
                )}
              </Box>
              <Box>
                {data5?.Data?.length > 0 && (
                  <Card Card className={classes2.cardRoot2}>
                    <CardHeader title={`${data5?.name}`} />
                    <CardContent className={classes2.cardContent}>
                      <PieChart className={classes2.pieChart} width={600} height={400}>
                        <Pie
                          isAnimationActive={true}
                          activeIndex={activeIndex5}
                          data={data5?.Data}
                          activeShape={renderActiveShape}
                          cx="50%"
                          cy="50%"
                          innerRadius={90}
                          outerRadius={120}
                          fill="#8884d8"
                          dataKey="value"
                          legendType="circle"
                          onMouseEnter={onPieEnter5}
                        >
                          {data5?.Data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                          ))}
                        </Pie>
                        <Tooltip />
                      </PieChart>
                    </CardContent>
                  </Card>
                )}
              </Box>
            </Box>
          )}
          {graphType === 'bar' && (
            <Box className={classes2.ToggleResultContainer}>
              <Box className={classes2.ToggleResBox}>
                {data1?.Data?.length > 0 && (
                  <Card className={classes2.cardRoot}>
                    <CardHeader
                      title={`${data1?.name}`}
                      action={
                        <IconButton
                          title="Full Screen"
                          style={{ padding: 0 }}
                          onClick={handleFullScreen1.enter}
                        >
                          <FullscreenIcon />
                        </IconButton>
                      }
                    />
                    <CardContent>
                      <Box
                        className={data1?.Data?.length > 5 ? classes2.barGraph : classes2.barGraph2}
                      >
                        <FullScreen handle={handleFullScreen1}>
                          <BarChart
                            width={data1?.Data?.length < 4 ? 300 : data1?.Data.length * 90}
                            height={350}
                            data={data1?.Data?.sort((a: any, b: any) => {
                              return a.value - b.value;
                            }).reverse()}
                            layout="horizontal"
                            style={handleFullScreen1.active ? fullScreenStyle : {}}
                            margin={{
                              bottom: handleFullScreen1.active ? 150 : 75,
                              left: 40,
                            }}
                          >
                            <XAxis
                              dataKey="name"
                              textAnchor="end"
                              sclaeToFit="true"
                              verticalAnchor="start"
                              interval={0}
                              angle="-45"
                              stroke="#000"
                              fontSize={10}
                            />
                            <YAxis />
                            <CartesianGrid strokeDasharray="3 3" />
                            <Tooltip />
                            <Legend verticalAlign="top" height={36} align="right" />
                            <Bar
                              dataKey="value"
                              fill="#DC3223"
                              shape={<TriangleBar />}
                              barSize={50}
                            />
                          </BarChart>
                        </FullScreen>
                      </Box>
                    </CardContent>
                  </Card>
                )}
                {data2?.Data?.length > 0 && (
                  <Card className={classes2.cardRoot}>
                    <CardHeader
                      title={`${data2?.name}`}
                      action={
                        <IconButton
                          title="Full Screen"
                          style={{ padding: 0 }}
                          onClick={handleFullScreen2.enter}
                        >
                          <FullscreenIcon />
                        </IconButton>
                      }
                    />
                    <CardContent>
                      <Box
                        className={data2?.Data?.length > 5 ? classes2.barGraph : classes2.barGraph2}
                      >
                        <FullScreen handle={handleFullScreen2}>
                          <BarChart
                            width={data2?.Data?.length < 4 ? 300 : data2?.Data.length * 120}
                            height={350}
                            data={data2?.Data?.sort((a: any, b: any) => {
                              return a.value - b.value;
                            }).reverse()}
                            layout="horizontal"
                            style={handleFullScreen2.active ? fullScreenStyle : {}}
                            margin={{
                              bottom: handleFullScreen2.active ? 150 : 75,
                              left: 40,
                            }}
                          >
                            <XAxis
                              dataKey="name"
                              textAnchor="end"
                              sclaeToFit="true"
                              verticalAnchor="start"
                              interval={0}
                              angle="-45"
                              stroke="#000"
                              fontSize={10}
                            />
                            <YAxis />
                            <CartesianGrid strokeDasharray="3 3" />
                            <Tooltip />
                            <Legend verticalAlign="top" height={36} align="right" />
                            <Bar
                              dataKey="value"
                              fill="#1e3246"
                              shape={<TriangleBar />}
                              barSize={50}
                            />
                          </BarChart>
                        </FullScreen>
                      </Box>
                    </CardContent>
                  </Card>
                )}
              </Box>
              <Box className={classes2.ToggleResBox}>
                {data3?.Data?.length > 0 && (
                  <Card Card className={classes2.cardRoot}>
                    <CardHeader
                      title={`${data3?.name}`}
                      action={
                        <IconButton
                          title="Full Screen"
                          style={{ padding: 0 }}
                          onClick={handleFullScreen3.enter}
                        >
                          <FullscreenIcon />
                        </IconButton>
                      }
                    />
                    <CardContent>
                      <Box
                        className={data3?.Data?.length > 5 ? classes2.barGraph : classes2.barGraph2}
                      >
                        <FullScreen handle={handleFullScreen3}>
                          <BarChart
                            width={data3?.Data?.length < 4 ? 300 : data3?.Data?.length * 90}
                            height={350}
                            data={data3?.Data?.sort((a: any, b: any) => {
                              return a.value - b.value;
                            }).reverse()}
                            layout="horizontal"
                            style={handleFullScreen3.active ? fullScreenStyle : {}}
                            margin={{
                              bottom: handleFullScreen3.active ? 150 : 75,
                              left: 40,
                            }}
                          >
                            <XAxis
                              dataKey="name"
                              textAnchor="end"
                              sclaeToFit="true"
                              verticalAnchor="start"
                              interval={0}
                              angle="-45"
                              stroke="#000"
                              fontSize={10}
                            />
                            <YAxis />
                            <CartesianGrid strokeDasharray="3 3" />
                            <Tooltip />
                            <Legend verticalAlign="top" height={36} align="right" />
                            <Bar
                              dataKey="value"
                              fill="#205723"
                              shape={<TriangleBar />}
                              barSize={50}
                            />
                          </BarChart>
                        </FullScreen>
                      </Box>
                    </CardContent>
                  </Card>
                )}
                {data4?.Data?.length > 0 && (
                  <Card Card className={classes2.cardRoot}>
                    <CardHeader
                      title={`${data4?.name}`}
                      action={
                        <IconButton
                          title="Full Screen"
                          style={{ padding: 0 }}
                          onClick={handleFullScreen4.enter}
                        >
                          <FullscreenIcon />
                        </IconButton>
                      }
                    />
                    <CardContent>
                      <Box
                        className={data4?.Data?.length > 5 ? classes2.barGraph : classes2.barGraph2}
                      >
                        <FullScreen handle={handleFullScreen4}>
                          <BarChart
                            width={data4?.Data?.length < 5 ? 300 : data4?.Data?.length * 90}
                            height={350}
                            data={data4?.Data?.sort((a: any, b: any) => {
                              return a.value - b.value;
                            }).reverse()}
                            layout="horizontal"
                            style={handleFullScreen4.active ? fullScreenStyle : {}}
                            margin={{
                              bottom: handleFullScreen4.active ? 150 : 75,
                              left: 40,
                            }}
                          >
                            <XAxis
                              dataKey="name"
                              textAnchor="end"
                              sclaeToFit="true"
                              verticalAnchor="start"
                              interval={0}
                              angle="-45"
                              stroke="#000"
                              fontSize={10}
                            />
                            <YAxis />
                            <CartesianGrid strokeDasharray="3 3" />
                            <Tooltip />
                            <Legend verticalAlign="top" height={36} align="right" />
                            <Bar
                              dataKey="value"
                              fill="#5e35b1"
                              shape={<TriangleBar />}
                              barSize={50}
                            />
                          </BarChart>
                        </FullScreen>
                      </Box>
                    </CardContent>
                  </Card>
                )}
              </Box>
              <Box>
                {data5?.Data?.length > 0 && (
                  <Card Card className={classes2.cardRoot2}>
                    <CardHeader
                      title={`${data5?.name}`}
                      action={
                        <IconButton
                          title="Full Screen"
                          style={{ padding: 0 }}
                          onClick={handleFullScreen5.enter}
                        >
                          <FullscreenIcon />
                        </IconButton>
                      }
                    />
                    <CardContent
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Box
                        className={data5?.Data?.length > 5 ? classes2.barGraph : classes2.barGraph2}
                      >
                        <FullScreen handle={handleFullScreen5}>
                          <BarChart
                            width={data5?.Data?.length === 1 ? 900 : data5?.Data?.length * 120}
                            height={350}
                            data={data5?.Data?.sort((a: any, b: any) => {
                              return a.value - b.value;
                            }).reverse()}
                            layout="horizontal"
                            style={handleFullScreen5.active ? fullScreenStyle : {}}
                            margin={{
                              bottom: handleFullScreen5.active ? 150 : 75,
                              left: 40,
                            }}
                          >
                            <XAxis
                              dataKey="name"
                              textAnchor="end"
                              sclaeToFit="true"
                              verticalAnchor="start"
                              interval={0}
                              angle="-45"
                              stroke="#000"
                              fontSize={10}
                            />
                            <YAxis />
                            <CartesianGrid strokeDasharray="3 3" />
                            <Legend verticalAlign="top" height={36} align="right" />
                            <Tooltip />
                            <Bar
                              dataKey="value"
                              fill="#4fa7f9"
                              shape={<TriangleBar />}
                              barSize={50}
                            />
                          </BarChart>
                        </FullScreen>
                      </Box>
                    </CardContent>
                  </Card>
                )}
              </Box>
            </Box>
          )}
        </>
      ) : null}
    </>
  );
};

export default Kpis;
