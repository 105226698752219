/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// Tree.js
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSelection,
  IntegratedSorting,
  PagingState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  Grid as ExpressGrid,
  PagingPanel,
  Table,
  TableColumnResizing,
  TableFilterRow,
  TableHeaderRow,
  TableSelection,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import { makeStyles } from '@mui/styles';
import {
  Badge,
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Modal,
  Paper,
  Typography,
} from '@material-ui/core';
import { Tree, TreeNode } from 'react-organizational-chart';
import DownloadIcon from '@material-ui/icons/CloudDownload'; // Import the download icon
import { getObjectives } from '../../../../../api/users';
import Loading from '../../../../../components/Core/Loading';
import ToolBarPlugin from '../../../../../components/Datagrid/ToolBarPlugin';
import TableComponent from '../../../../../components/Datagrid/TableComponent';
import { rowComponent, toolbarComponent } from '../../../../../components/Datagrid/TStyles';
import ProjectContext from '../../../../Project/ProjectContext';
import ObjectiveTree from './ObjectiveTree';
import { ModalEmployee, ModalPaperEmployee } from '../styles';

const columns = [
  { name: 'First_Name', title: 'First Name' },
  { name: 'Last_Name', title: 'Last Name' },
  { name: 'Level', title: 'Level' },
  { name: 'Job_Title', title: 'Job Title' },
  { name: 'Job_description', title: 'Job Description' },
  { name: 'Objectives', title: 'Objectives' },
  { name: 'Project_Details', title: 'Project Details' },
  { name: 'Project_Title', title: 'Project Title' },
];

const Employee = ({
  objectives,
  setObjectives,
  setShowTree,
  employeeDetails,
  setEmployeeDetails,
}) => {
  const { setSnack } = useContext(ProjectContext);
  const exporterRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [pageSizes] = useState([5, 10, 20, 50]);
  const [toggleHeight, setToggleHeight] = useState(false);
  const [selectionRows, setSelectionRows] = useState<any[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedObjective, setSelectedObjective] = useState(null);

  const handleCellClick = (row) => {
    setSelectedObjective(row);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedObjective(null);
  };

  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);
  const onDownload = (workbook: any) => {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'data.xlsx');
    });
  };

  // Function to convert Markdown to HTML
  const markdownToHtml = (markdown) => {
    // Replace newline characters with <br/>
    let html = markdown.replace(/\n/g, '<br/>');

    // Convert headers (####)
    html = html.replace(/#### (.*?)<br\/>/g, '<h4>$1</h4>');

    // Convert bold text
    html = html.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');

    // Replace additional headers if necessary (e.g., ###, ##, #)
    html = html.replace(/### (.*?)<br\/>/g, '<h3>$1</h3>');
    html = html.replace(/## (.*?)<br\/>/g, '<h2>$1</h2>');
    html = html.replace(/# (.*?)<br\/>/g, '<h1>$1</h1>');

    return html;
  };

  const handleRegenerateObjectives = async () => {
    setLoading(true);
    // Get selected rows
    const selectedData = objectives.filter((_, index) => selectionRows.includes(index));
    // Your logic to regenerate objectives with AI using selectedData here

    // Map through selected data and extract the desired fields
    const employees_info = selectedData.map((row) => ({
      job_title: row.Job_Title,
      job_description: row.Job_description,
      firstname: row.First_Name,
      lastname: row.Last_Name,
    }));

    const checkPayload = employees_info.map((row) => {
      return `${row.firstname} ${row.lastname}`;
    });
    const payload = {
      manager_info: employeeDetails.manager_info,
      employees_info,
    };

    const response = await getObjectives(payload);
    if (response?.output) {
      setLoading(false);
      setSelectionRows([]);
    }
    // Remove escaping and parse the JSON string
    const parsedData = JSON.parse(response?.output);

    // Process the Objectives
    const newGeneratedData = parsedData.map((item) => ({
      ...item,
      Objectives: markdownToHtml(item.Objectives.replace(/\\/g, '')),
    }));

    // Log the parsed data
    const prevData = objectives.filter(
      (data) => !checkPayload.includes(`${data.First_Name} ${data.Last_Name}`) && data.Level !== 'N'
    );

    setObjectives([...newGeneratedData, ...prevData]);
  };

  const CustomToolbar = ({ handleRegenerateObjectives, onDownload }) => {
    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '10px', width: '100%' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleRegenerateObjectives}
          startIcon={loading && <CircularProgress style={{ color: 'white' }} size={20} />}
          style={{
            backgroundColor: '#19AA6E',
            color: 'white',
            border: '1px solid #19AA6E',
            padding: '10px 15px',
            borderRadius: '20px',
            cursor: 'pointer',
            fontSize: '14px',
            fontWeight: 'bold',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
            transition: 'background-color 0.3s',
            marginRight: '10px', // Space between buttons
          }}
        >
          Regenerate Objectives with AI
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onDownload} // Call the download function
          style={{
            backgroundColor: '#19AA6E',
            color: 'white',
            border: '1px solid #19AA6E',
            padding: '10px 15px',
            borderRadius: '20px',
            cursor: 'pointer',
            fontSize: '14px',
            fontWeight: 'bold',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <DownloadIcon style={{ marginRight: '5px' }} /> {/* Download icon */}
          Download
        </Button>
      </div>
    );
  };
  // Function to create a structured hierarchy from flat data
  const getStructuredData = (data: Node[]): Node => {
    const topLevel = data.filter((item) => item?.Level === 'N')[0];

    return {
      parent: topLevel,
      children: data.filter((item) => item?.Level === 'N-1'),
    };
  };

  const structuredData = getStructuredData(objectives);

  const genderTree = (node) => {
    // Check if node is an array and not empty
    if (Array.isArray(node) && node.length > 0) {
      return node.map((item) => (
        <TreeNode
          key={item.First_Name + item.Last_Name}
          label={
            <ObjectiveTree
              node={{
                First_Name: '',
                Last_Name: '',
                Objectives: '',
                Level: '',
                ...item,
              }}
              index={0}
            />
          }
        >
          {genderTree(item)}
        </TreeNode>
      ));
    }
    return null; // Return null if node is undefined or empty
  };

  return (
    <div style={{ margin: '10px' }}>
      <>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setShowTree(true);
            setEmployeeDetails({ manager_info: {}, employees_info: {} });
          }}
          style={{ marginBottom: '20px' }}
        >
          Back to Tree View
        </Button>
        <Tree
          lineWidth="2px"
          lineColor="#002a45"
          lineBorderRadius="10px"
          label={
            <ObjectiveTree
              node={{
                First_Name: '',
                Last_Name: '',
                Objectives: '',
                Level: '',
                ...structuredData?.parent,
              }}
              index={0}
            />
          }
        >
          {genderTree(structuredData?.children)}
        </Tree>
        <Box
          style={{
            backgroundColor: '#EEF1F9',
            border: '1px solid #002A45',
            borderRadius: '20px',
            marginTop: '20px',
          }}
        >
          <ExpressGrid
            rows={objectives}
            columns={[
              { name: 'First_Name', title: 'First Name' },
              { name: 'Last_Name', title: 'Last Name' },
              { name: 'Level', title: 'Level' },
              { name: 'Job_Title', title: 'Job Title' },
              { name: 'Job_description', title: 'Job Description' },
              {
                name: 'Objectives',
                title: 'Objectives',
                getCellValue: (row) => (
                  <span
                    onClick={() => handleCellClick(row)}
                    style={{ cursor: 'pointer', color: '#' }}
                  >
                    {row.Objectives.length > 150 ? (
                      <div
                        dangerouslySetInnerHTML={{ __html: `${row.Objectives.slice(0, 150)}...` }}
                      />
                    ) : (
                      <div dangerouslySetInnerHTML={{ __html: row.Objectives }} />
                    )}
                  </span>
                ),
              },
              { name: 'Project_Details', title: 'Project Details' },
              { name: 'Project_Title', title: 'Project Title' },
            ]}
          >
            {/* <DataTypeProvider
                for={['created_at']}
                formatterComponent={({ value }) => (
                  <span>{formatDate(value, formatLocal('en'))}</span>
                )}
              /> */}
            <FilteringState />
            {/* <IntegratedFiltering /> */}
            {/* <SelectionState selection={selectionRows} onSelectionChange={setSelectionRows} /> */}
            <SelectionState
              selection={selectionRows}
              onSelectionChange={(selection) => {
                // Prevent selection of first row
                if (selection.includes(0)) {
                  // Do not allow the first row to be selected
                  const newSelection = selection.filter((index) => index !== 0);
                  setSelectionRows(newSelection);
                  setSnack('First row cannot be selected', 'error');
                } else {
                  setSelectionRows(selection);
                }
              }}
            />
            <IntegratedSelection />
            {/* <SortingState
                defaultSorting={[{ columnName: 'First_Name_N_minus_1', direction: 'asc' }]}
              /> */}
            {/* <IntegratedSorting /> */}
            <PagingState
              currentPage={currentPage}
              onCurrentPageChange={setCurrentPage}
              pageSize={pageSize}
              onPageSizeChange={setPageSize}
            />
            <IntegratedPaging />
            <Table
              tableComponent={TableComponent}
              columnExtensions={[
                {
                  columnName: 'Objectives',
                  wordWrapEnabled: toggleHeight,
                },
              ]}
              // columnExtensions={[
              //   { columnName: 'id', width: '50px' },
              //   { columnName: 'project_id', width: '50px' },
              //   { columnName: 'project_name', width: '200px' },
              //   { columnName: 'user_id', width: '200px' },
              //   { columnName: 'module', width: '200px' },
              //   { columnName: 'action', width: '200px' },
              //   { columnName: 'user_name', width: '200px' },
              //   { columnName: 'created_at', width: '200px' },
              //   {
              //     columnName: 'error',
              //     width: '200px',
              //   },
              // ]}
            />
            {/* <TableColumnResizing
                defaultColumnWidths={[
                  { columnName: 'id', width: '100px' },
                  { columnName: 'project_id', width: '100px' },
                  { columnName: 'project_name', width: '100px' },
                  { columnName: 'user_id', width: '70px' },
                  { columnName: 'module', width: '100px' },
                  { columnName: 'action', width: '100px' },
                  { columnName: 'user_name', width: '100px' },
                  { columnName: 'created_at', width: '100px' },
                  {
                    columnName: 'error',
                    width: '50px',
                  },
                ]}
              /> */}
            <TableHeaderRow />
            <TableFilterRow rowComponent={rowComponent} />
            {/* <Toolbar rootComponent={toolbarComponent} /> */}
            <Toolbar
              rootComponent={() => (
                <CustomToolbar
                  handleRegenerateObjectives={handleRegenerateObjectives}
                  onDownload={startExport}
                />
              )}
            />
            {/* <ToolBarPlugin
                  name="Height"
                  title="Wrap Text"
                  onClick={() => {
                    setToggleHeight(!toggleHeight);
                  }}
                /> */}
            {/* MUI Button in the toolbar */}
            {/* <Button
                component="button"
                variant="contained"
                color="primary"
                onClick={handleRegenerateObjectives}
                style={{
                  backgroundColor: '#19AA6E',
                  color: 'white',
                  border: '1px solid #19AA6E',
                  padding: '10px 15px',
                  borderRadius: '20px',
                  cursor: 'pointer',
                  fontSize: '14px',
                  fontWeight: 'bold',
                  marginLeft: '74%',
                  marginTop: '5px',
                  marginBottom: '5px',
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                  transition: 'background-color 0.3s',
                }}
              >
                Regenerate Objectives with AI
              </Button> */}
            <PagingPanel pageSizes={pageSizes} />
            <TableSelection showSelectAll />
            {/* <TableSelection showSelectAll cellComponent={SelectionCellComponent} /> */}
          </ExpressGrid>
          <GridExporter ref={exporterRef} rows={objectives} columns={columns} onSave={onDownload} />
        </Box>
      </>

      {/* Modal for displaying objective details */}
      <Modal open={modalOpen} onClose={handleCloseModal}>
        <ModalEmployee>
          <ModalPaperEmployee>
            <Typography variant="h3">Objectives</Typography>
            <div
              style={{ fontSize: '1rem' }}
              dangerouslySetInnerHTML={{ __html: selectedObjective?.Objectives }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => setModalOpen(false)}
              style={{ marginTop: '20px', marginLeft: 'auto' }}
            >
              Close
            </Button>
          </ModalPaperEmployee>
        </ModalEmployee>
      </Modal>
    </div>
  );
};

export default Employee;
