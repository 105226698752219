import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ITopic } from '../../../interfaces/ITopic';
import { apiEndpoints } from '../../../utils/apiEndpoints';
import useAxiosAuth from '../../../API/axios';

interface TopicDeletionDlgProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  currentUserId: number;
  topic: ITopic;
  replaceOldTopicByUpdatedTopic: (deletedTopic: ITopic, isDelete: boolean) => void;
}

const TopicDeletionDlg: React.FC<TopicDeletionDlgProps> = ({
  open,
  setOpen,
  topic,
  currentUserId,
  replaceOldTopicByUpdatedTopic,
}) => {
  const { AxiosCall } = useAxiosAuth();
  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteTopic = () => {
    const payload = {
      url: `${apiEndpoints.API_TOPIC_URL}/${topic.id}`,
      method: 'DELETE',
      data: null,
      header: {
        'Content-Type': 'application/json',
        'X-USER-ID': currentUserId.toString(),
      },
    };
    AxiosCall(payload)
      .then((res) => res)
      .then(() => {
        replaceOldTopicByUpdatedTopic(topic, true);
        handleClose();
      });
  };

  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Reject topic `{topic.title}` ?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You are about to reject the topic `{topic.title}`.
            <br />
            Are you sure ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            No
          </Button>
          <Button onClick={handleDeleteTopic} variant="outlined" color="error">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default TopicDeletionDlg;
