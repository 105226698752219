/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { Box, ClickAwayListener, Typography, Button, IconButton, Tooltip } from '@material-ui/core';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import M2GlobalContext from '../../../containers/ContextMager/M2GlobalContext';

const Section = ({ copy, ClearTexts, DetectLanguage, SwapData, handleCopy }: any) => {
  const { state, dispatch } = React.useContext(M2GlobalContext);
  const refResultText = React.useRef();
  const { textTranslator } = state;
  const {
    text,
    destText,
    sourceLanguage,
    textTranslationHistory,
    destinationLanguage,
  } = textTranslator;

  return (
    <>
      <Box className="textTrans-container-rowtwo">
        <Box className="textTrans-container-rowtwo-first-half">
          <textarea
            placeholder="Please enter text here........"
            className="textTrans-container-rowtwo-first-half-textArea-first"
            value={text}
            maxLength={6700}
            onChange={(e) => {
              DetectLanguage(e.target.value);
              // if (text.length > e.target.value.length) {
              //   ClearTexts(e.target.value);
              // } else if (e.target.value) {
              //   DetectLanguage(e.target.value);
              // }
              dispatch({ type: 'text_change', payload: e.target.value });
              // setText(e.target.value);
            }}
          />
        </Box>
        <Box className="textTrans-container-rowtwo-second-half">
          <textarea
            className="textTrans-container-rowtwo-first-half-textArea-second"
            value={destText}
            ref={refResultText}
            disabled
          />
          {/* <div
            style={{
              position: 'absolute',
              bottom: '10px',
              right: '10px',
            }}
          >
            <IconButton
              style={{ color: copy ? 'lightgreen' : '#002a45', padding: 0 }}
              onClick={handleCopy}
            >
              {copy ? <AssignmentTurnedInIcon /> : <AssignmentIcon />}
            </IconButton>
          </div> */}
        </Box>
        <div className="swap-btn" onClick={SwapData}>
          <SwapHorizIcon />
        </div>
      </Box>
    </>
  );
};

export default Section;
