import React from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import AppContextProvider from './components/appContextProvider/AppContextProvider';
import { Router } from './route/router';
import { SignIn } from './components/Auth/SignIn';

function App() {
  return (
    <AppContextProvider>
      <Router />
      {/* <UnauthenticatedTemplate>
        <SignIn />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <Router />
      </AuthenticatedTemplate> */}
    </AppContextProvider>
  );
}

export default App;
