import { Box, Typography } from '@material-ui/core';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import DragAndDrop from '../../Core/DragAndDrop';
import FileTranslatorContext from '../FileTranslatorContext';
import Filescolumns from '../const/DocFiles';
import useAppToken from '../../../app/useAppToken';
import TableComponent from '../../Datagrid/TableComponent';
import { rowComponent } from '../../Datagrid/TStyles';
import ExcelDoc, { CsvDoc, DocxDoc, PdfDoc, PptDoc } from '../const/png';
import UserUploadFile from '../API/UserUploadFile';
import FileDataTable from './FileDataTable';

const DocumentFiles = () => {
  const { files, fileSelection, setFileSelection, glossaryFiles, state, dispatch } = useContext(
    FileTranslatorContext
  );
  const [open, setOpen] = React.useState(false);
  const { selected_glossary, selectedFileData, glossaryToLanuageMatch, model } = state;

  const handleDocumentSelectionChange = useCallback(
    (selectedKeys: any) => {
      try {
        const ArrayFiles = [...selectedKeys];
        if (ArrayFiles.length > 3) {
          return;
        }

        if (ArrayFiles.length > selectedFileData.length) {
          const lastIndex = ArrayFiles.pop();
          dispatch({
            type: 'Update_selected_docment',
            payload: [...selectedFileData, ...[{ document: files[lastIndex] }]],
          });
        } else {
          const filenames = ArrayFiles.map((data) => {
            return files[data].filename;
          });
          const filterdDoc = selectedFileData.filter((data) => {
            return filenames.includes(data.document.filename);
          });
          dispatch({ type: 'Update_selected_docment', payload: filterdDoc });
        }

        setFileSelection(selectedKeys);
      } catch (error) {
        console.log(error);
      }
    },
    [selected_glossary, selectedFileData, files]
  );

  // console.log(selectedFileData);
  const handleGlossaryCheckBox = useCallback(
    (item) => {
      try {
        if (item.checked) {
          setOpen(false);
          const { fromLang, toLang } = item.glossaryFileDetails;
          const { document } = item.linkedDocument;
          const temp = selectedFileData.map((data) => {
            if (data.document.filename === document.filename) {
              return {
                document,
                glossaryFile: item.glossaryFileDetails,
                glossaryFileName: item.glossaryFileName,
              };
            }
            return data;
          });
          dispatch({ type: 'Update_selected_docment', payload: temp });

          dispatch({
            type: 'auto_detect_doc_lang',
            payload: {
              FromLang:
                toLang === glossaryToLanuageMatch || selectedFileData.length === 1 ? fromLang : '',
              ToLang:
                toLang === glossaryToLanuageMatch || selectedFileData.length === 1 ? toLang : '',
            },
          });

          // if (!glossaryToLanuageMatch) {
          //   dispatch({
          //     type: 'auto_detect_doc_lang',
          //     payload: { FromLang: fromLang, ToLang: toLang },
          //   });
          // } else if (glossaryToLanuageMatch !== toLang && selectedFileData.length > 1) {
          //   dispatch({
          //     type: 'auto_detect_doc_lang',
          //     payload: { FromLang: '', ToLang: '' },
          //   });
          // } else {
          //   dispatch({
          //     type: 'auto_detect_doc_lang',
          //     payload: { FromLang: fromLang, ToLang: toLang },
          //   });
          // }
        } else {
          const temp = selectedFileData.map((data) => {
            if (data.document.filename === document.filename) {
              return {
                document,
              };
            }
            return data;
          });
          // console.log(temp);
          dispatch({ type: 'Update_selected_docment', payload: temp });
        }
      } catch (error) {
        console.log(error);
      }
    },
    [selected_glossary, selectedFileData]
  );

  // console.log(state);
  // console.log(selected_glossary);
  // console.log(state);

  return (
    <>
      <FileDataTable
        pinFiles={glossaryFiles}
        selection={fileSelection}
        setSelection={setFileSelection}
        Filescolumns={Filescolumns}
        selectedFileData={selectedFileData}
        handleSelectionChange={handleDocumentSelectionChange}
        handleRadioSelection={handleGlossaryCheckBox}
        files={files}
        open={open}
        model={model}
        setOpen={setOpen}
      />
    </>
  );
};

export default DocumentFiles;
