import React, { ReactNode } from 'react';

export const HomeContext = React.createContext({
  user: {} as any,
  allStatus: {} as any,
  allStatusAdmin: {} as any,
  allStatusUser: {} as any,
});

type Props = {
  value: { allStatus: any; allStatusAdmin: any; allStatusUser: any; user: any };
  children: ReactNode;
};

const HomeContextProvider = ({ value, children }: Props): ReactNode => {
  return <HomeContext.Provider value={value}>{children}</HomeContext.Provider>;
};

export default HomeContextProvider;
