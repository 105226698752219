import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#002A45',
    },
    secondary: {
      main: '#19AA6E',
      light: '#E15A50',
    },
    info: {
      main: '#002A45',
      // light: "#E15A50",
    },
    success: {
      main: '#19aa6e',
    },
    error: {
      main: '#E15A50',
    },
  },
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: { color: 'red' },
      },
    },
  },
});
