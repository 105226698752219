import axios from 'axios';
import { BACKEND_URL } from '../const';

export const getToken = (): string | null => {
  try {
    return JSON.parse(localStorage.getItem('appJWT') || '').access_token;
  } catch (error) {
    return null;
  }
};

export const downloadExcelFile = async (dataSetURL, requestData) => {
  try {
    const { fileName } = requestData;

    const response = await axios({
      url: `${BACKEND_URL.downloadDataset}${dataSetURL}`,
      method: 'POST',
      data: requestData,
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });

    // Create a link element
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileName}.xlsx`);
    document.body.appendChild(link);
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  } catch (error) {
    console.error('Error downloading the Excel file', error);
  }
};
