import { Avatar, Stack, Tooltip } from '@mui/material';
import { TDateISO } from '../../../interfaces/TDateISO';
import './chat.css';
import { IUser } from '../../../interfaces/IUser';

interface ChatBoxProps {
  commentator: IUser;
  date: TDateISO;
  message: string;
  leftOrRight: 'left' | 'right';
}

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}

const ChatBox: React.FC<ChatBoxProps> = ({ message, leftOrRight, date, commentator }) => {
  const fullName = commentator.firstName + ' ' + commentator.lastName;

  return (
    <>
      {leftOrRight === 'left' && (
        <Stack direction="row" spacing={2}>
          <Tooltip arrow title={fullName}>
            <Avatar {...stringAvatar(fullName)} alt={fullName} />
          </Tooltip>
          <li className={leftOrRight}>
            <p>{message}</p>
            <span className="time">{new Date(date).toDateString()}</span>
          </li>
        </Stack>
      )}
      {leftOrRight === 'right' && (
        <li className={leftOrRight}>
          <p>{message}</p>
          <span className="time">{new Date(date).toDateString()}</span>
        </li>
      )}
    </>
  );
};

export default ChatBox;
