/* eslint-disable react/button-has-type */
import React, { memo, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { isEmpty } from '@antv/util';
import RexChild from './RexChild';
import NoResultsFound from '../../../components/Core/NoResultsFound';
import Requirements from './Reqiurements';
import ExpertSearch2 from './ExpertSearch2';
import ChangeRequest from './ChangeRequest';
import SixSigma from './SixSigma';
import Rexbook from './Rexbook';
import RexButtons, { getRexData } from '../TextAnalysisResultContainer/const/rexconst';
import Test from './Test';
import { getSixSigmaData } from '../TextAnalysisResultContainer/const/sixsigmaconst';
import { ENV } from '../../../const';
import RexApsys from './RexApsys';
import RexCardGenA from './RexCardGenA';
import RexGenA from './REXGenA';
import CSSProject from './CSSProject/CSSProject';
import REXBook from '../../Workspace/Components/REXAnalysis/REXBook';
// import RexChild from '../../REXAnalysis/ChildCompnents/RexChild';
// import Requirements from '../../REXAnalysis/ChildCompnents/Reqiurements';
// import ExpertSearch2 from '../../REXAnalysis/ChildCompnents/ExpertSearch2';
// import '../../REXAnalysis/ChildCompnents/transitionCss.css';
// import NoResultsFound from '../../../../../components/Core/NoResultsFound';
// import ChangeRequest from '../../REXAnalysis/ChildCompnents/ChangeRequest';
// import IsensChatNew from '../../GPT2/ChatContainer';
// import Test from '../../REXAnalysis/ChildCompnents/Test';
// import RexButtons, { getRexData } from './const/rexconst';
// import { getSixSigmaData } from './const/sixsigmaconst';
// import SixSigma from '../../REXAnalysis/ChildCompnents/SixSigma';
// import { ENV } from '../../../../../const';
// import Rexbook from '../../REXAnalysis/ChildCompnents/Rexbook';

const REX360 = ({ input, REXData, operation, setCountResult, setSnack }) => {
  // console.log(input);
  // console.log(operation);
  const data = REXData?.data;
  const result = REXData?.result || false;
  const { projectId } = useParams() as any;
  const [RexRow, setRows] = useState([]);
  const [RexCol, setRexCol] = useState([]);
  const [RexKPI1, setRexKPI1] = useState();
  const [RexKPI2, setRexKPI2] = useState();
  const [RexKPI3, setRexKPI3] = useState();
  const [RexKPI4, setRexKPI4] = useState();

  const [CRKPI1, setCRKPI1] = useState();
  const [CRKPI2, setCRKPI2] = useState();
  const [CRKPI3, setCRKPI3] = useState();
  const [CRKPI4, setCRKPI4] = useState();
  const [CRKPI5, setCRKPI5] = useState();

  const [sixSigmaRow, setSixSigmaRow] = useState([]);
  const [sixSigmaCol, setSixSigmaCol] = useState([]);

  const fetchData = async () => {
    const keysIssuerSiteWiseDistribution = Object.keys(data[0]?.kpis?.issuerSiteWiseDistribution);
    const valuesIssuerSiteWiseDistribution = Object.values(
      data[0]?.kpis?.issuerSiteWiseDistribution
    );
    const IssuerSiteWiseDistribution: any = [];
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < keysIssuerSiteWiseDistribution.length; index++) {
      if (valuesIssuerSiteWiseDistribution[index] !== 0) {
        IssuerSiteWiseDistribution.push({
          name: keysIssuerSiteWiseDistribution[index].replace(/ /g, '_'),
          value: valuesIssuerSiteWiseDistribution[index],
        });
      }
    }
    const obj1 = { Data: IssuerSiteWiseDistribution, name: 'IssuerSite Wise Distribution' };
    setRexKPI1(obj1);

    const keysplatformWiseDistribution = Object.keys(data[0]?.kpis?.platformWiseDistribution);
    const valuesplatformWiseDistribution = Object.values(data[0]?.kpis?.platformWiseDistribution);
    const platformWiseDistribution: any = [];
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < keysplatformWiseDistribution.length; index++) {
      if (valuesplatformWiseDistribution[index] !== 0) {
        platformWiseDistribution.push({
          name: keysplatformWiseDistribution[index].replace(/ /g, '_'),
          value: valuesplatformWiseDistribution[index],
        });
      }
    }
    const obj2 = { Data: platformWiseDistribution, name: 'Platform Wise Distribution' };
    setRexKPI2(obj2);

    const keysstatusWiseDistribution = Object.keys(data[0]?.kpis?.statusWiseDistribution);
    const valuesstatusWiseDistribution = Object.values(data[0]?.kpis?.statusWiseDistribution);
    const statusWiseDistribution: any = [];
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < keysstatusWiseDistribution.length; index++) {
      if (valuesstatusWiseDistribution[index] !== 0) {
        statusWiseDistribution.push({
          name: keysstatusWiseDistribution[index].replace(/ /g, '_'),
          value: valuesstatusWiseDistribution[index],
        });
      }
    }

    const obj3 = { Data: statusWiseDistribution, name: 'REX Source Wise Distribution' };
    setRexKPI3(obj3);

    const keystypologyWiseDistribution = Object.keys(data[0]?.kpis?.typologyWiseDistribution);
    const valuestypologyWiseDistribution = Object.values(data[0]?.kpis?.typologyWiseDistribution);
    const typologyWiseDistribution: any = [];
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < keystypologyWiseDistribution.length; index++) {
      if (valuestypologyWiseDistribution[index] !== 0) {
        typologyWiseDistribution.push({
          name: keystypologyWiseDistribution[index].replace(/ /g, '_'),
          value: valuestypologyWiseDistribution[index],
        });
      }
    }

    const obj4 = { Data: typologyWiseDistribution, name: 'Activities (As per PM2020 ABS)' };
    setRexKPI4(obj4);

    const { column, row } = await getRexData(data[0].searchResults);
    setRows(() => row);
    setRexCol(() => column);
    // console.log(data[0]);
    const keyProductWiseDistribution = Object.keys(data[4]?.kpis?.productWiseDistribution);
    const valueProductWiseDistribution = Object.values(data[4]?.kpis?.productWiseDistribution);
    const ProductWiseDistribution: any = [];
    // console.log(keyProductWiseDistribution);
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < keyProductWiseDistribution.length; index++) {
      if (
        valueProductWiseDistribution[index] !== 0 &&
        keyProductWiseDistribution[index] &&
        valueProductWiseDistribution[index]
      ) {
        ProductWiseDistribution.push({
          name: keyProductWiseDistribution[index].replace(/ /g, '_'),
          value: valueProductWiseDistribution[index],
        });
      }
    }
    const CRobj2 = { Data: ProductWiseDistribution, name: 'Product Wise Distribution' };
    // console.log(CRobj2);
    setCRKPI2(CRobj2);

    const keyProjectWiseDistribution = Object.keys(data[4]?.kpis?.projectWiseDistribution);
    const valueProjectWiseDistribution = Object.values(data[4]?.kpis?.projectWiseDistribution);
    const ProjectWiseDistribution: any = [];
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < keyProjectWiseDistribution.length; index++) {
      if (valueProjectWiseDistribution[index] !== 0) {
        ProjectWiseDistribution.push({
          name: keyProjectWiseDistribution[index].replace(/ /g, '_'),
          value: valueProjectWiseDistribution[index],
        });
      }
    }
    const CRobj1 = { Data: ProjectWiseDistribution, name: 'Project Wise Distribution' };
    setCRKPI1(CRobj1);

    const keySeverityWiseDistribution = Object.keys(data[4]?.kpis?.severityWiseDistribution);
    const valueSeverityWiseDistribution = Object.values(data[4]?.kpis?.severityWiseDistribution);
    const SeverityWiseDistribution: any = [];
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < keySeverityWiseDistribution.length; index++) {
      if (valueSeverityWiseDistribution[index] !== 0) {
        SeverityWiseDistribution.push({
          name: keySeverityWiseDistribution[index].replace(/ /g, '_'),
          value: valueSeverityWiseDistribution[index],
        });
      }
    }
    const CRobj3 = { Data: SeverityWiseDistribution, name: 'Severity Wise Distribution' };
    setCRKPI3(CRobj3);

    const keySiteWiseDistribution = Object.keys(data[4]?.kpis?.siteWiseDistribution);
    const valueSiteWiseDistribution = Object.values(data[4]?.kpis?.siteWiseDistribution);
    const SiteWiseDistribution: any = [];
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < keySiteWiseDistribution.length; index++) {
      if (valueSiteWiseDistribution[index] !== 0) {
        SiteWiseDistribution.push({
          name: keySiteWiseDistribution[index].replace(/ /g, '_'),
          value: valueSiteWiseDistribution[index],
        });
      }
    }
    const CRobj4 = { Data: SiteWiseDistribution, name: 'Site Wise Distribution' };
    setCRKPI4(CRobj4);

    const keyTypeWiseDistribution = Object.keys(data[4]?.kpis?.typeWiseDistribution);
    const valueTypeWiseDistribution = Object.values(data[4]?.kpis?.typeWiseDistribution);
    const TypeWiseDistribution: any = [];
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < keyTypeWiseDistribution.length; index++) {
      if (valueTypeWiseDistribution[index] !== 0) {
        TypeWiseDistribution.push({
          name: keyTypeWiseDistribution[index].replace(/ /g, '_'),
          value: valueTypeWiseDistribution[index],
        });
      }
    }
    const CRobj5 = { Data: TypeWiseDistribution, name: 'Type Wise Distribution' };
    setCRKPI5(CRobj5);
  };

  useEffect(() => {
    try {
      if (data !== undefined && data?.length > 0 && data[0] !== null) {
        fetchData();
      }
      // console.log(RexButtons);
      if (data[5]?.searchResults) {
        const { column, row } = getSixSigmaData(data[5]?.searchResults);
        setSixSigmaRow(row);
        setSixSigmaCol(column);
      }
    } catch (e) {
      console.log(e);
    }
  }, []);

  const renderChildComponet = () => {
    // console.log(RexButtons[operation]);
    // console.log('RexButtons:', RexButtons);
    switch (RexButtons[operation]) {
      case 'REX Cards':
        if (!result) {
          return null;
        }
        if (data === undefined || data[0]?.numberOfResults === 0 || data[0] === null) {
          return <NoResultsFound />;
        }
        return (
          <RexChild
            rows={RexRow}
            columns={RexCol}
            data1={RexKPI1}
            data2={RexKPI2}
            data3={RexKPI3}
            data4={RexKPI4}
            rexCashCount={data[0]?.numberOfCashRex}
            setSnack={setSnack}
          />
        );

      // return <RexChild input={input} result={data[0]} show={show} />;
      case 'Requirements':
        if (!result) {
          return null;
        }
        if (data === undefined || data[1]?.numberOfResultsFound === 0 || data[1] === null) {
          return <NoResultsFound />;
        }
        return <Requirements input={input} result={data[1]} />;
      case 'Experts':
        if (!result) {
          return null;
        }
        if (data === undefined || isEmpty(data[2]) || data[2] === null) {
          return <NoResultsFound />;
        }
        return <ExpertSearch2 result={data[2]} />;
      case 'Tests':
        if (!result) {
          return null;
        }
        if (data === undefined || data[3]?.numberOfResultsFound === 0 || data[3] === null) {
          return <NoResultsFound />;
        }
        return <Test input={input} result={data[3]} />;
      case 'Change Requests':
        if (!result) {
          return null;
        }
        if (data === undefined || data[4]?.numberOfResults === 0 || data[4] === null) {
          return <NoResultsFound />;
        }
        return (
          <ChangeRequest
            result={data[4]}
            data1={CRKPI1}
            data2={CRKPI2}
            data3={CRKPI3}
            data4={CRKPI4}
            data5={CRKPI5}
          />
        );

      case 'AEC':
        if (!result) {
          return null;
        }
        if (data === undefined || data[5]?.numberOfResultsFound === 0 || data[5] === null) {
          return <NoResultsFound />;
        }
        return <Requirements input={input} result={data[5]} />;

      case 'Lean Six Sigma':
        if (!result) {
          return null;
        }
        if (data === undefined || data[5]?.numberOfResults === 0 || data[5] === null) {
          return <NoResultsFound />;
        }
        return (
          <SixSigma
            rows={sixSigmaRow}
            columns={sixSigmaCol}
            data1={[]}
            data2={[]}
            data3={[]}
            data4={[]}
          />
        );
      case 'APSYS':
        return <RexApsys />;
      case 'REX Book':
        return projectId ? <Rexbook /> : <REXBook />;
      case 'CSS Project':
        return <CSSProject />;
      // case 'REX Chat':
      //   return <RexCardGenA inputValue={input} />;

      default:
        // eslint-disable-next-line consistent-return
        return null;
    }
  };

  // const changeComponet = (mode) => {
  //   setMode(mode);
  // };

  return (
    <>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          width: '100%',
        }}
      >
        {/* <RexGenA initPrompt={input} /> */}
        <div>{renderChildComponet()}</div>
      </Box>
    </>
  );
};

export default memo(REX360);
