import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ITopic } from '../../../interfaces/ITopic';
import { Close, Forum } from '@mui/icons-material';
import ChatBox from '../ChatBox/ChatBox';
import InputChatMessage from '../InputChatMessage/InputChatMessage';

interface TopicCommentDialogProps {
  currentUserId: number;
  visibleFilterTopics: ITopic[];
  topicId: number;
  open: boolean;
  handleClose: () => void;
  replaceOldTopicByUpdatedTopic: (topic: ITopic) => void;
}

const StyledDialog = styled(Dialog)(() => ({
  '& .MuiDialog-paper': {
    minHeight: '50vh',
  },
}));

const TopicCommentDialog: React.FC<TopicCommentDialogProps> = ({
  currentUserId,
  visibleFilterTopics,
  topicId,
  open,
  handleClose,
  replaceOldTopicByUpdatedTopic,
}) => {
  const topic = visibleFilterTopics.find((t) => t.id === topicId);

  return (
    <StyledDialog open={open} onClose={handleClose} maxWidth="md" fullWidth scroll={'paper'}>
      <DialogTitle
        id="scroll-dialog-title"
        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
      >
        <div>
          <Forum color="secondary" sx={{ display: 'inline' }} />
          <Typography sx={{ display: 'inline', paddingLeft: 1 }} variant="h6">
            {topic?.title} - Comments
          </Typography>
        </div>
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers={true}>
        <DialogContentText tabIndex={-1} component="div">
          <Grid container>
            <Grid item md={12}>
              <div className="chat-area">
                <div className="chatbox">
                  <div className="modal-dialog-scrollable">
                    <div className="modal-content">
                      <div className="modal-body">
                        <div className="msg-body">
                          <ul>
                            {topic?.topicComments
                              .sort((a, b) => a.id - b.id)
                              .map((comment) => (
                                <ChatBox
                                  key={comment.id}
                                  date={comment.updatedAt}
                                  commentator={comment.commentator}
                                  message={comment.comment}
                                  leftOrRight={
                                    comment.commentatorId === currentUserId ? 'right' : 'left'
                                  }
                                />
                              ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {topic && (
          <InputChatMessage
            replaceOldTopicByUpdatedTopic={replaceOldTopicByUpdatedTopic}
            handleClose={handleClose}
            topicId={topic.id}
            currentUserId={currentUserId}
          />
        )}
      </DialogActions>
    </StyledDialog>
  );
};

export default TopicCommentDialog;
