export const LIFECYCLE_COLORS = {
  Drafted: '#19AA6E',
  Submitted: '#19AA6E',
  EvalForum: '#002A45',
  Accepted: '#002A45',
  Rejected: '#E15A50',
  Postponed: '#E15A50',
  InProgress: '#788291',
  MakeOrBuy: '#788291',
  BuyInProgress: '#788291',
  MakeInProgress: '#788291',
  DeliveryInProd: '#AFA082',
};

export const LIFECYCLE_COLORS_CHIP = {
  Drafted: 'secondary',
  Submitted: 'secondary',
  EvalForum: 'info',
  Accepted: 'info',
  Rejected: 'error',
  Postponed: 'error',
  InProgress: 'primary',
  MakeOrBuy: 'primary',
  BuyInProgress: 'primary',
  MakeInProgress: 'primary',
  DeliveryInProd: 'success',
} as any;

export const stageStatus = (label: string) => {
  switch (label) {
    case 'Submitted':
      return 1;
    case 'EvalForum':
    case 'NeedClarification':
      return 2;
    case 'Accepted':
      return 3;
    case 'InProgress':
    case 'MakeOrBuy':
    case 'BuyInProgress':
    case 'MakeInProgress':
      return 4;
    case 'DeliveryInProd':
      return 5;
  }
  return 0;
};

export const userStatusLifecycle = [
  'Submitted',
  'EvalForum',
  'Accepted',
  'InProgress',
  'DeliveryInProd',
];

export const userStatusMapping = {
  Drafted: 'Drafted',
  Submitted: 'Submitted',
  EvalForum: 'EvalForum',
  NeedClarification: 'EvalForum',
  Accepted: 'Accepted',
  MakeOrBuy: 'InProgress',
  MakeInProgress: 'InProgress',
  BuyInProgress: 'InProgress',
  Rejected: 'Rejected',
  Postponed: 'Postponed',
  DeliveryInProd: 'DeliveryInProd',
} as any;

export const crossDomainsList = [
  'Opportunities & Tenders Management',
  'Project Management',
  'Architecture & Design',
  'Simulation',
  'PLM & Standard parts management',
  'CAD',
  'Operations',
  'Digital Continuity and E2E CCM & ECM',
  'Change & Configuration Management',
  'Services and After-Sales',
  'Quality',
  'RAM',
  'Safety',
  'Cyber',
  'V&V',
  'HR',
  'Finance',
  'Legal',
  'Others',
];
