import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Dialog, IconButton, Typography } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  heading: {
    backgroundColor: theme.palette.primary.light,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 8,
    padding: 2,
    textAlign: 'justify',
    marginBottom: 10,
    color: 'white',
  },
}));

type Props = {
  open: boolean;
  onConfirm: any;
  onCancel: any;
  label?: string;
};

export default function ModalConfirm({ open, onConfirm, onCancel, label }: Props) {
  const classes = useStyles();

  const objCheck = {
    isDeleteRow: 'Are you sure you want to delete the selected row(s) ?',
    characterizer: 'Are you sure you want to run characterizer ?',
    assgin: 'Are you sure you want to run assignment ?',
    ProjectDelete: 'Are you sure you want to delete the selected projects ?',
    isDeleteTitle: 'Are you sure you want to delete the current chat history ?',
    isDeleteChildTitle: 'Are you sure you want to delete the current Child Item?',
    isDeleteFolderTitle: 'Are you sure you want to delete this Folder?',
    waitCapture: 'Wait for capture data',
    waitCharacterizer: 'wait for charaterizer data',
    isHierarchyChange: 'Are you sure you want to proceed with the hierarchy?',
    glossary:
      'The To language is not synchronized with the glossary. Do you still want to continue?',
  };

  return (
    <>
      <Dialog
        onClose={onCancel}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle id="customized-dialog-title">
          <Typography variant="h6">Please Confirm</Typography>
          <IconButton aria-label="close" className={classes.closeButton} onClick={onCancel}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Typography variant="subtitle1" className={classes.heading}>
            {objCheck[label]}
          </Typography>
        </DialogContent>
        <DialogActions>
          {!['waitCapture', 'waitCharacterizer'].includes(label) ? (
            <>
              <Button autoFocus onClick={onCancel} color="default" variant="contained">
                Cancel
              </Button>
              <Button onClick={onConfirm} color="secondary" variant="contained">
                Confirm
              </Button>
            </>
          ) : (
            <Button onClick={onCancel} color="secondary" variant="contained">
              Ok
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
