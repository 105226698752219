/* eslint-disable react/destructuring-assignment */
import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import {
  ColumnChooser,
  Grid as ExpressGrid,
  PagingPanel,
  Table,
  TableColumnResizing,
  TableColumnVisibility,
  TableFilterRow,
  TableHeaderRow,
  TableSelection,
  Toolbar,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import MultiSelect from 'react-multi-select-component';
import { makeStyles, withStyles } from '@material-ui/styles';
import { useHistory, useParams } from 'react-router-dom';
import { Workload3Create } from 'WorkloadType';
import SettingsApplicationsOutlinedIcon from '@mui/icons-material/SettingsApplicationsOutlined';
import { fileDetails, iCaptureCsv, iCaptureExcel } from '../../../../../api/module1';
import {
  workloadCreate,
  workloadGetById,
  workloadRunPost,
  workloadRunDocToDocPDF,
  workloadRunPostPdfCsv,
} from '../../../../../api/workloads';
import ExcelDoc from '../../../../../assets/icons8-excel-48.png';
import PdfDoc from '../../../../../assets/pdf_ins.png';
import CsvDoc from '../../../../../assets/csv_icons.png';
import PptDoc from '../../../../../assets/icons8-powerpoint-48.png';
import DocxDoc from '../../../../../assets/icons8-microsoft-word-2019-48.png';
import docIcon from '../../../../../assets/docx_ico.png';
import pdfIcon from '../../../../../assets/pdf_ico.png';
import xlsIcon from '../../../../../assets/xlsx_ico.png';
import ToolBarPlugin from '../../../../../components/Datagrid/ToolBarPlugin';
import TStyles, {
  rowComponent,
  tableAllocatorComponent,
  tableHeaderComponent,
  toolbarComponent,
  tUseStyles,
} from '../../../../../components/Datagrid/TStyles';
import formatBytes from '../../../../../tools/formatBytes';
import Module1Context from '../../../Module2Context';
import NERParamTarget from '../../../../../components/NERParamTarget';
import { getCaptureName } from '../../../../../api/breakdowns';
import Loading from '../../../../../components/Core/Loading';
import NavigationGoto from '../../../../../components/Core/NavigationGoto';
import MultiAllocTableComp from '../../../../../components/Datagrid/MultiAllocTableComp';
import NavigationGotoNew from '../../../../../components/Core/NavigationGotoNew';
import FileSetting from './FileSetting';

// table header
const TableComponentBase = ({ classes, ...restProps }: any) => (
  <Table.Table {...restProps} className={classes.tableStriped} />
);

const TableComponent = withStyles(TStyles, { name: 'TableComponent' })(TableComponentBase);
const useStyles = makeStyles((theme) => ({
  root: {
    // '& .MuiPaper-root': {
    //   backgroundColor: theme.palette.background.paper,
    // },
    // backgroundColor: 'rgb(238, 241, 249)',
  },
  paper: {
    '& .MuiPaper-rounded': {
      background: 'red !important',
    },
  },
  captureHandle: {
    '& .MuiInputLabel-outlined': {
      color: '#002A45 !important',
      fontWeight: 'bold',
    },
  },
  pagingPanelContainer: {
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    // marginTop: '10px',
    // width: '50% !important',
    padding: '0px !important',
    marginRight: '244px !important',
    '& .Pager-pager': {
      padding: 0,
    },
    // Apply other custom styles as needed
    '& .MuiIconButton-label': {
      color: '#001F3C',
      padding: 5,
      backgroundColor: '#FFFFFF',
      borderRadius: '50%',
      boxShadow: '#00000029',
    },
    // '& .MuiButton-label': {
    //   color: '#001F3C',
    //   padding: 2,
    //   backgroundColor: '#FFFFFF',
    //   borderRadius: '50%',
    //   boxShadow: '#00000029',
    // },
    '& .MuiButton-root': {
      '&.MuiButton-textPrimary .Pagination-activeButton': {
        backgroundColor: 'red !important',
        color: 'white !important',
        // Add other custom styles as needed
      },
      color: '#001F3C',
      padding: '0px 5px',
      backgroundColor: 'white',
      // borderRadius: '50%',
      boxShadow: '#00000029',
      // gap: 10,
      margin: 9,
      // '&.Pagination-activeButton': {
      //   background: 'red !important',
      // },
      // '& .Pagination-button': {
      //   '& .Pagination-activeButton': {
      //     background: 'red !important',
      //   },
      // },
    },
  },
}));

const columns: any = [
  { name: 'filename', title: 'File Name' },
  { name: 'size', title: 'Size' },
  { name: 'folderName', title: 'Folder' },
  { name: 'date', title: 'Date upload' },
];

type Props = {
  id?: number;
  files?: any;
  setFiles?: any;
};

function DocSimilarity({ id: workloadId, files, setFiles }: Props) {
  const history = useHistory();
  const classes = useStyles();
  const tableUseStyles = tUseStyles();
  const { projectId } = useParams() as any;
  const [toggleHeight, setToggleHeight] = useState(false);
  const [hiddenColumnNames, setHiddenColumnNames] = useState([]);
  const [captureNames, setCaptureNames] = useState<any[]>([]);
  const [currentCaptureName, setCurrentCaptureName] = useState<any>('');
  const [disOP, setDisOP] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [dis, setDis] = useState(false);
  const [tempPage, setTemPage] = useState(0);

  const [currentPage1, setCurrentPage1] = useState(0);
  const [tempPage1, setTemPage1] = useState(0);
  const [dis1, setDis1] = useState(true);
  const [pageSize1, setPageSize1] = useState(5);
  const [pageSizes] = useState([5, 10, 20, 50]);
  const handlePageNum1 = () => {
    const rel = tempPage1 - 1;
    setCurrentPage1(rel);
  };

  const handlePageNum = () => {
    const rel = tempPage - 1;
    setCurrentPage(rel);
  };
  const exporterRef = useRef(null);
  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);

  const onSave = (workbook: any) => {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        `${currentCaptureName}.xlsx`
      );
    });
  };
  const { project } = useContext(Module1Context);
  // const [files, setFiles] = useState<any[]>([]);
  const [selection, setSelection] = useState([]);

  // to control the loader and display data full Grid
  const [loading, setLoading] = useState(false);
  const [display, setDisplay] = useState(false);

  const [loadingResult, setLoadingResult] = useState(false);

  const { setSnack } = useContext(Module1Context);
  const [messageToDisplay, setMessageToDisplay] = useState<any>();

  const [dataoutput, setDataoutput] = useState<any>([]);
  const [columnWidth, setColumnWidth] = useState<any>([]);

  // required when we dont have fixed named columns
  const [rows, setRows] = useState<any>([]);
  const [columnsAre, setColumnsAre] = useState<any>([]);
  const [columnNamesAre, setColumnNamesAre] = useState<any>([]);
  const [newLine, setNewLine] = useState<any>('');
  const [filter, setFilter] = useState<any>([]);
  const [filterEntity, setFilterEntity] = useState<any>([]);

  const [resourcesAre, setResourcesAre] = useState<any>([]);
  const [inputValue, setInputValue] = useState('');
  const inputHandler = (event: any) => setInputValue(event.target.value);

  const [fileName1, setFileName1] = useState<any>();
  const [fileName2, setFileName2] = useState<any>();

  const [sheetName0, setSheetName0] = useState<any>();
  const [targetName0, setTargetName0] = useState<any>();
  const [columnName0, setColumnName0] = useState<any>();

  const [sheetName1, setSheetName1] = useState<any>();
  const [targetName1, setTargetName1] = useState<any>();
  const [columnName1, setColumnName1] = useState<any>();
  const [errorMessage, setErrorMessage] = useState('');
  const [similarityScore, setSimilarityScore] = useState<any>();

  const [targetColumnsAre, setTargetColumnsAre] = useState<any>([]);

  const handleSetRowsColumns = async (myRows: {}[]) => {
    const araryCol = Object.keys(myRows[0]);
    const myCols: any = [];
    araryCol.map((key) => {
      // const keyTemp = key.replace(' ', '_');
      if (key !== 'RawData') {
        if (key !== 'Coordinates') {
          if (key !== 'i18n') {
            myCols.push({
              name: key,
              title: key.toUpperCase(),
            });
          }
        }
      }
      return key;
    });
    setColumnsAre(myCols);
    const tempArr: any = [];
    // eslint-disable-next-line array-callback-return
    myCols.map((c: any) => {
      tempArr.push(c.name);
    });
    setColumnNamesAre(tempArr);
    setRows(myRows);
  };
  useEffect(() => {
    getCaptureName(projectId).then((resp) => {
      if (resp) {
        setCaptureNames(resp);
      }
    });
  }, []);
  /**
   * Get all files from project
   */
  useEffect(() => {
    const temp = files.map((data: any) => {
      return {
        ...data,
        isNew: false,
      };
    });
    setFiles(temp);
  }, []);

  /**
   * if projectId && workloadId present -> render results
   * wait for allfiles are loaded
   */
  useEffect(() => {
    if (files.length === 0 || projectId === undefined || workloadId === undefined) {
      return;
    }
    setLoading(true);
    workloadGetById(workloadId)
      .then((payload) => {
        // console.log(payload);
        // console.log(payload.resources);
        setLoading(false);
        setDisplay(true);
        // console.log(JSON.parse(payload.results_data[0].data.output.DocToDoc_Result));
        setResourcesAre(payload?.resources[0]?.scopeSettingOP);
        handleSetRowsColumns(JSON.parse(payload.results_data[0].data.output.DocToDoc_Result));
        setCurrentCaptureName(payload?.resources[0]?.output?.NameOfCapture);
        setSimilarityScore(
          payload.results_data[0].data.output.Overall_Document_Similarity.Similarity
        );
      })
      .catch(() => {
        setLoading(false);
        setDisplay(false);
        setSnack(messageToDisplay, 'error');
      });
  }, [files, messageToDisplay, projectId, setSnack, workloadId]);

  useEffect(() => {
    if (pageSize > files.length) {
      if (dis) {
        setDis((prev) => {
          return !prev;
        });
      }
    } else if (pageSize < files.length) {
      setDis((prev) => {
        return !prev;
      });
    }
  }, [pageSize]);

  useEffect(() => {
    if (pageSize1 > rows.length) {
      if (dis1) {
        setDis1((prev) => {
          return !prev;
        });
      }
    } else if (pageSize1 < rows.length) {
      setDis1((prev) => {
        return !prev;
      });
    }
  }, [pageSize1]);

  const getFileDetails = (inputFiles: any[]) => {
    setLoading(true);
    setDisplay(false);
    const falseArray: any = [];
    const arrayIs: any = [{ columnName: 'Entity', width: 300 }];
    for (const myFile of inputFiles) {
      falseArray.push(
        fileDetails(files[myFile].filename, files[myFile].filename, files[myFile].container)
      );
      arrayIs.push({
        columnName: files[myFile].filename.split('.').slice(0, -1).join('.'),
        width: 300,
      });
    }

    setColumnWidth(arrayIs);
    Promise.all(falseArray)
      .then((responses) => {
        // Get a JSON object from each of the responses
        return Promise.all(
          responses.map((response) => {
            return response;
          })
        );
      })
      .then((data) => {
        const ar: any = [];
        // console.log(data);
        data.map((c: any) => ar.push(c.output));
        setDataoutput(ar);
        // console.log(ar);
        setLoading(false);
        setDisOP(true);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });

    // promise all end
  };

  const handleSelectionChange = (selectedKeys: any) => {
    // console.log(files);
    setErrorMessage('');
    setFilter([]);
    setFilterEntity([]);
    setDisOP(false);
    const ArrayFiles = selectedKeys;
    // console.log(selectedKeys);
    setSelection(selectedKeys);
    if (ArrayFiles.length > 0) {
      // 10485760 = 10 MB
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < ArrayFiles.length; index++) {
        if (files[ArrayFiles[index]].SizeInByte > 10485760) {
          setSnack(
            `filename: .${files[ArrayFiles[0]].filename} more than 10 MB file size not allowed`,
            'error'
          );
        }
      }
    }
    if (ArrayFiles.length === 2) {
      //   if (files[ArrayFiles[0]].size > '10 MB' || files[ArrayFiles[1]].size > '10 MB') {
      //     setSnack('more than 10 MB file size not allowed', 'error');
      //   }
      setFileName1(files[ArrayFiles[0]].filename);
      setFileName2(files[ArrayFiles[1]].filename);
    } else {
      setFileName1('');
      setFileName2('');
    }
    if (ArrayFiles.length > 2) {
      setSnack('more than 2 files not allowed!', 'error');
    } else {
      getFileDetails(ArrayFiles);
    }
  };

  function HandleReg(e: any, indexIs: any) {
    // console.log('dataoutput is: ', dataoutput);
    if (e.target.name === 'sheetname') {
      const state = [...targetColumnsAre];
      state[indexIs] =
        dataoutput[indexIs].Sheets[dataoutput[indexIs].Sheet_names.indexOf(e.target.value)];
      setTargetColumnsAre(state);
      Object.assign(dataoutput[indexIs], { SheetName: e.target.value });
      // console.log('F:', dataoutput);
      if (indexIs === 0) {
        setSheetName0(e.target.value);
      } else {
        setSheetName1(e.target.value);
      }
    }
    if (e.target.name === 'targetColumn') {
      Object.assign(dataoutput[indexIs], { TargetColumn: e.target.value });
      if (indexIs === 0) {
        setTargetName0(e.target.value);
      } else {
        setTargetName1(e.target.value);
      }
    }
    if (e.target.name === 'columnname') {
      Object.assign(dataoutput[indexIs], { TargetColumn: e.target.value });
      if (indexIs === 0) {
        setColumnName0(e.target.value);
      } else {
        setColumnName1(e.target.value);
      }
    }
  }

  function handleAnalyzePdf(fileExtensionOne: any, fileExtensionTwo: any) {
    const containerIs = dataoutput[0].Container;
    let tempdataoutput: any = [];
    tempdataoutput = JSON.parse(JSON.stringify(dataoutput));
    let fileone = null;
    let filetwo = null;
    if (dataoutput[0].FileType === 'PDF') {
      fileone = {
        output: { NameOfCapture: inputValue, FileName: dataoutput[0].Blob },
        TextLength: 100000,
        settingsModule: {
          FileName: dataoutput[0].Blob,
          FilePath: dataoutput[0].FilePath,
          Container: dataoutput[0].Container,
          BlobName: dataoutput[0].Blob,
          id: dataoutput[0].Blob,
          FileType: 'pdf',
          FileIndex: 0,
          Language: dataoutput[0].Language,
          StartPage: 0,
          EndPage: 0,
          HeaderMargin: 0,
          FooterMargin: 0,
          TypesOfCapture: 'Paragraph_Capture',
          RegId: '',
          TableSwitch: true,
          MergeBullet: false,
          SmartHeaderFooter: true,
          SmartTableOfContent: true,
          TranslateTo: ['en'],
          NameOfCapture: inputValue,
        },
      };
    }
    if (dataoutput[1].FileType === 'PDF') {
      filetwo = {
        output: { NameOfCapture: inputValue, FileName: dataoutput[1].Blob },
        TextLength: 100000,
        settingsModule: {
          FileName: dataoutput[1].Blob,
          FilePath: dataoutput[1].FilePath,
          Container: dataoutput[1].Container,
          BlobName: dataoutput[1].Blob,
          id: dataoutput[1].Blob,
          FileType: 'pdf',
          fileIndex: 1,
          Language: dataoutput[1].Language,
          StartPage: 0,
          EndPage: 0,
          HeaderMargin: 0,
          FooterMargin: 0,
          TypesOfCapture: 'Paragraph_Capture',
          RegId: '',
          TableSwitch: true,
          MergeBullet: false,
          SmartHeaderFooter: true,
          SmartTableOfContent: true,
          TranslateTo: ['en'],
          NameOfCapture: inputValue,
        },
      };
    }

    if (dataoutput[0].FileType === 'PDF' && dataoutput[1].FileType === 'PDF') {
      const bothPdfFile: any = [fileone, filetwo];
      console.log('start pdf and pdf');
      console.log(bothPdfFile);
      console.log('End pdf and pdf');
      workloadCreate({
        project_id: projectId,
        app: 'module2',
        action: 'DocToDoc',
        resources: bothPdfFile,
      })
        .then((workloadNew: Workload3Create) => {
          workloadRunDocToDocPDF(Number(workloadNew.id), bothPdfFile, 'DocToDocPDF')
            .then(() => {
              window.history.pushState('', '', `/projects/${projectId}`);
              history.push(`/projects/${projectId}`);
            })
            .catch((e) => {
              setErrorMessage(e.detail);
              setSnack('Error while workload post.', 'error');
              setDisplay(false);
              setLoading(false);
            })
            .finally(() => {});
        })
        .catch(() => {
          setSnack('Error while workload create.', 'error');
          setDisplay(false);
          setLoading(false);
        })
        .finally(() => {});
    } else {
      let filePDF: any = null;
      let fileIndex: any = null;
      if (dataoutput[0].FileType === 'PDF') {
        filePDF = fileone;
        fileIndex = 1;
      }
      if (dataoutput[1].FileType === 'PDF') {
        filePDF = filetwo;
        fileIndex = 0;
      }
      Promise.all([
        fileDetails(
          files[selection[fileIndex]].filename,
          files[selection[fileIndex]].filename,
          files[selection[fileIndex]].container
        ),
        // fileDetails(
        //   files[selection[1]].filename,
        //   files[selection[1]].filename,
        //   files[selection[1]].container
        // ),
      ])
        .then(async (responseAll) => {
          // for data 0 process to do iCapture
          console.log(responseAll);
          const arrPromises: any = [];
          function captureAll(): Promise[] {
            for (const [i, itemData] of responseAll.entries()) {
              if (itemData.output.FileType === 'Excel') {
                let columnPosition: any;
                let sheetNameFind: any;
                let targetNames: any;
                console.log(`${sheetName0},${sheetName1}`);
                if (sheetName0 !== undefined) {
                  if (sheetName0 !== null) {
                    columnPosition = (responseAll[0]?.output?.Sheet_names).indexOf(sheetName0);
                    sheetNameFind = sheetName0;
                    targetNames = targetName0;
                  }
                }
                if (sheetName1 !== undefined) {
                  if (sheetName1 !== null) {
                    columnPosition = (responseAll[0]?.output?.Sheet_names).indexOf(sheetName1);
                    sheetNameFind = sheetName1;
                    targetNames = targetName1;
                  }
                }
                const body = {
                  FilePath: itemData.output.FilePath,
                  Container: itemData.output.Container,
                  BlobName: itemData.output.Blob,
                  NameOfCapture: inputValue,
                  Sheet: sheetNameFind,
                  TargetColumn: targetNames,
                  ColumnNames: itemData?.output?.Sheets[columnPosition]?.ColumNames,
                  Header: true,
                };
                console.log(body);
                arrPromises.push(iCaptureExcel(body));
                // console.log(tempdataoutput);
                // to arrange sheet number in first position because of middleware 388-405
                // eslint-disable-next-line no-plusplus
                for (let index = 0; index < tempdataoutput.length; index++) {
                  if (tempdataoutput[index].FileName === itemData.output.Blob) {
                    // eslint-disable-next-line no-plusplus
                    for (let jindex = 0; jindex < tempdataoutput[index].Sheets.length; jindex++) {
                      // eslint-disable-next-line eqeqeq
                      if (tempdataoutput[index].Sheets[jindex].SheetName == sheetNameFind) {
                        tempdataoutput[index].Sheet = tempdataoutput[index].Sheets[jindex];
                      }
                    }
                  }
                }
              } else if (itemData.output.FileType === 'CSV') {
                const body = {
                  FilePath: itemData.output.FilePath,
                  Container: itemData.output.Container,
                  BlobName: itemData.output.Blob,
                  NameOfCapture: inputValue,
                  TargetColumn: columnName0 === undefined ? columnName1 : columnName0,
                  ColumnNames: itemData?.output?.ColumnNames,
                  Header: true,
                };
                arrPromises.push(iCaptureCsv(body));
              }
            }
            return arrPromises;
          }
          const responseCharacters = await Promise.all(captureAll());
          let len1 = null;
          let len2 = null;
          if (fileIndex === 0) {
            len1 =
              JSON.parse(responseCharacters[0].output?.Text)?.length > 0
                ? JSON.parse(responseCharacters[0].output?.Text)?.length
                : 0;
            len2 = 10000;
          }
          if (fileIndex === 1) {
            len2 =
              JSON.parse(responseCharacters[0].output?.Text)?.length > 0
                ? JSON.parse(responseCharacters[0].output?.Text)?.length
                : 0;
            len1 = 10000;
          }
          if (len1 === 0 || len2 === 0) {
            setSnack('Invalid file format', 'error');
            setLoadingResult(false);
            setDisplay(false);
            return;
          }
          const TextLen = len1 > len2 ? len1 : len2;

          const rrr = responseCharacters.map((d, index) => ({
            ...d,
            FileName: responseAll[index].output.Blob,
            scopeSettingOP: tempdataoutput,
            TextLength: TextLen,
            filesPDF: filePDF,
          }));
          const totalcount: any = [];
          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < responseCharacters.length; index++) {
            if (responseCharacters[index].output !== undefined) {
              Object.keys(responseCharacters[index]?.output).forEach((k: any) => {
                if (k === 'TargetColumn') {
                  totalcount.push('found');
                }
              });
            }
          }
          if (responseCharacters.length === totalcount.length) {
            // workload here
            workloadCreate({
              project_id: projectId,
              app: 'module2',
              action: 'DocToDoc',
              resources: rrr,
            })
              .then((workloadNew: Workload3Create) => {
                workloadRunPostPdfCsv(Number(workloadNew.id), rrr, 'm2-doctodoc-pdf-csv')
                  .then(() => {
                    window.history.pushState('', '', `/projects/${projectId}`);
                    history.push(`/projects/${projectId}`);
                  })
                  .catch((e) => {
                    setErrorMessage(e.detail);
                    setSnack(
                      `Error from workload post api, either one of file is non-extractable`,
                      'error'
                    );
                    setDisplay(false);
                    setLoading(false);
                    setLoadingResult(false);
                  })
                  .finally(() => {});
              })
              .catch(() => {
                setSnack('Error from workload create api.', 'error');
                setDisplay(false);
                setLoading(false);
                setLoadingResult(false);
              })
              .finally(() => {});
          } else {
            setSnack('Please select all dropdown options.', 'error');
            setDisplay(false);
            setLoading(false);
            setLoadingResult(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setSnack('Error while processing the scopeSetting for both files.', 'error');
          setDisplay(false);
          setLoading(false);
          setLoadingResult(false);
        });
    }
  }

  function handleAnalyze() {
    setErrorMessage('');
    const fileExtensionOne = files[selection[0]].filename.toLowerCase().split('.').pop();
    const fileExtensionTwo = files[selection[1]].filename.toLowerCase().split('.').pop();
    if (files[selection[0]].SizeInByte > 10485760 || files[selection[1]].SizeInByte > 10485760) {
      setSnack('File size is grater than 10 MB. Not allowed for processing.', 'error');
      return;
    }
    if (inputValue.trim().length === 0) {
      setSnack('Enter activity name', 'error');
      return;
    }
    if (captureNames.includes(inputValue.trim())) {
      setSnack('That capture name is taken.Please try another', 'error');
      return;
    }
    setLoadingResult(true);
    setDisplay(false);
    if (fileExtensionOne === 'pdf' || fileExtensionTwo === 'pdf') {
      handleAnalyzePdf(fileExtensionOne, fileExtensionTwo);
    } else {
      const containerIs = dataoutput[0].Container;
      let tempdataoutput: any = [];
      tempdataoutput = JSON.parse(JSON.stringify(dataoutput));
      // console.log(tempdataoutput);
      Promise.all([
        fileDetails(
          files[selection[0]].filename,
          files[selection[0]].filename,
          files[selection[0]].container
        ),
        fileDetails(
          files[selection[1]].filename,
          files[selection[1]].filename,
          files[selection[1]].container
        ),
      ])
        .then(async (responseAll) => {
          // for data 0 process to do iCapture
          const arrPromises: any = [];
          function captureAll(): Promise[] {
            for (const [i, itemData] of responseAll.entries()) {
              if (itemData.output.FileType === 'Excel') {
                // console.log('itemData', itemData);
                let columnPosition: any;
                // console.log(`${sheetName0},${sheetName1}`);
                if (sheetName0 !== undefined) {
                  if (sheetName0 !== null) {
                    columnPosition = (responseAll[0]?.output?.Sheet_names).indexOf(sheetName0);
                  }
                }
                if (sheetName1 !== undefined) {
                  if (sheetName1 !== null) {
                    columnPosition = (responseAll[1]?.output?.Sheet_names).indexOf(sheetName1);
                  }
                }
                const sheetNameFind = i === 0 ? sheetName0 : sheetName1;
                // console.log('sheetnamefind', sheetNameFind);
                let columnPositionNewColumns: any;
                // eslint-disable-next-line no-plusplus
                for (let index = 0; index < itemData.output.Sheets.length; index++) {
                  // eslint-disable-next-line eqeqeq
                  if (sheetNameFind == itemData.output.Sheets[index].SheetName) {
                    // console.log('got sheet name');
                    columnPositionNewColumns = itemData.output.Sheets[index].ColumNames;
                  }
                }
                // console.log('columnPositionNewColumns', columnPositionNewColumns);
                const body = {
                  FilePath: itemData.output.FilePath,
                  Container: itemData.output.Container,
                  BlobName: itemData.output.Blob,
                  NameOfCapture: inputValue,
                  // Sheet: i === 0 ? sheetName0 : sheetName1,
                  Sheet: sheetNameFind,
                  TargetColumn: i === 0 ? targetName0 : targetName1,
                  // ColumnNames: itemData?.output?.Sheets[columnPosition]?.ColumNames,
                  ColumnNames: columnPositionNewColumns,
                  Header: true,
                };
                arrPromises.push(iCaptureExcel(body));
                // console.log(tempdataoutput);
                // to arrange sheet number in first position because of middleware 388-405
                // eslint-disable-next-line no-plusplus
                for (let index = 0; index < tempdataoutput.length; index++) {
                  if (tempdataoutput[index].FileName === itemData.output.Blob) {
                    // eslint-disable-next-line no-plusplus
                    for (let jindex = 0; jindex < tempdataoutput[index].Sheets.length; jindex++) {
                      // eslint-disable-next-line eqeqeq
                      if (tempdataoutput[index].Sheets[jindex].SheetName == sheetNameFind) {
                        tempdataoutput[index].Sheet = tempdataoutput[index].Sheets[jindex];
                      }
                    }
                  }
                }
              } else if (itemData.output.FileType === 'CSV') {
                const body = {
                  FilePath: itemData.output.FilePath,
                  Container: itemData.output.Container,
                  BlobName: itemData.output.Blob,
                  NameOfCapture: inputValue,
                  TargetColumn: i === 0 ? columnName0 : columnName1,
                  ColumnNames: itemData?.output?.ColumnNames,
                  Header: true,
                };
                arrPromises.push(iCaptureCsv(body));
              }
            }
            return arrPromises;
          }

          const responseCharacters = await Promise.all(captureAll());
          const len1 =
            JSON.parse(responseCharacters[0].output?.Text)?.length > 0
              ? JSON.parse(responseCharacters[0].output?.Text)?.length
              : 0;
          const len2 =
            JSON.parse(responseCharacters[1].output?.Text)?.length > 0
              ? JSON.parse(responseCharacters[1].output?.Text)?.length
              : 0;
          if (len1 === 0 || len2 === 0) {
            setSnack('Invalid file format', 'error');
            setLoadingResult(false);
            setDisplay(false);
            return;
          }
          const TextLen = len1 > len2 ? len1 : len2;

          const r = responseCharacters.map((d, index) => ({
            ...d,
            FileName: responseAll[index].output.Blob,
            scopeSettingOP: tempdataoutput,
            TextLength: TextLen,
          }));
          // console.log(r);
          const totalcount: any = [];
          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < responseCharacters.length; index++) {
            if (responseCharacters[index].output !== undefined) {
              Object.keys(responseCharacters[index]?.output).forEach((k: any) => {
                if (k === 'TargetColumn') {
                  totalcount.push('found');
                }
              });
            }
          }
          if (responseCharacters.length === totalcount.length) {
            // workload here
            workloadCreate({
              project_id: projectId,
              app: 'module2',
              action: 'DocToDoc',
              resources: r,
            })
              .then((workloadNew: Workload3Create) => {
                workloadRunPost(Number(workloadNew.id), r, 'm2-doctodoc')
                  .then(() => {
                    window.history.pushState('', '', `/projects/${projectId}`);
                    history.push(`/projects/${projectId}`);
                  })
                  .catch((e) => {
                    setErrorMessage(e.detail);
                    setSnack(
                      `Error from workload post api, either one of file is non-extractable`,
                      'error'
                    );
                    setDisplay(false);
                    setLoading(false);
                    setLoadingResult(false);
                  })
                  .finally(() => {});
              })
              .catch(() => {
                setSnack('Error from workload create api.', 'error');
                setDisplay(false);
                setLoading(false);
                setLoadingResult(false);
              })
              .finally(() => {});
          } else {
            setSnack('Please select all dropdown options.', 'error');
            setDisplay(false);
            setLoading(false);
            setLoadingResult(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setSnack('Error while processing the scopeSetting for both files.', 'error');
          setDisplay(false);
          setLoading(false);
          setLoadingResult(false);
        });
    }
  }

  const renderFileName = ({ row }: any) => {
    const ext = row.filename.split('.');
    const extensions = {
      xlsx: ExcelDoc,
      csv: CsvDoc,
      pptx: PptDoc,
      docx: DocxDoc,
      pdf: PdfDoc,
    };
    return (
      <div
        style={{
          position: 'relative',
          padding: '0.3rem',
          width: 'max-content',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: '0.2rem' }}>
          <img src={extensions[ext[ext.length - 1]]} height="30" width="30" alt="img" />
          <Typography>{row.filename}</Typography>
        </div>
        {row?.isNew && (
          <div
            style={{
              width: 'max-content',
              padding: '0.2rem',
              borderRadius: '10px',
              backgroundColor: '#19AA6E',
              color: 'white',
              position: 'absolute',
              top: '-12px',
              right: '-35px',
            }}
          >
            <Typography style={{ fontSize: '0.85em' }}>New !</Typography>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <Box style={{ backgroundColor: '#EEF1F9', border: '1px solid #002A45' }}>
        <ExpressGrid rows={files} columns={columns}>
          <DataTypeProvider for={['filename']} formatterComponent={renderFileName} />
          <SelectionState selection={selection} onSelectionChange={handleSelectionChange} />
          <FilteringState />
          <IntegratedFiltering />
          <SortingState />
          <IntegratedSorting />
          <PagingState
            currentPage={currentPage}
            onCurrentPageChange={setCurrentPage}
            pageSize={pageSize}
            onPageSizeChange={setPageSize}
          />
          <IntegratedPaging />
          <VirtualTable />
          <Table
            tableComponent={MultiAllocTableComp}
            containerComponent={tableAllocatorComponent}
            headComponent={tableHeaderComponent}
          />
          <TableHeaderRow showSortingControls />
          <TableFilterRow rowComponent={rowComponent} />
          {/* <PagingPanel pageSizes={pageSizes} /> */}
          <PagingPanel
            pageSizes={pageSizes}
            containerComponent={(props) => (
              <>
                <PagingPanel.Container {...props} className={classes.pagingPanelContainer} />
              </>
            )}
          />
          <TableSelection />
        </ExpressGrid>
        <NavigationGotoNew
          setPageSize={setPageSize}
          fileLength={files.length}
          pageSize={pageSize}
          dis={dis}
          setTemPage={setTemPage}
          handlePageNum={handlePageNum}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </Box>
      <span style={{ color: 'black' }}>
        <h3>Total files selected: {selection.length}</h3>{' '}
      </span>
      {loading && <Loading />}
      {disOP && (
        <>
          {' '}
          <Box style={{ border: '1px solid #002A45' }}>
            {dataoutput.length > 0 && (
              <>
                <div className={classes.root}>
                  <Grid container style={{ marginLeft: 10 }}>
                    <div
                      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >
                      <div>
                        <SettingsApplicationsOutlinedIcon style={{ width: 40, height: 40 }} />
                      </div>
                      <div>
                        <h2>Scope Settings</h2>
                      </div>
                    </div>
                  </Grid>
                  <div>
                    <center>
                      <h3 style={{ color: 'blue' }}>
                        {fileName1 && fileName2 && (
                          <>
                            {fileName1} <b style={{ color: 'red', fontSize: 20 }}>vs</b> {fileName2}{' '}
                          </>
                        )}
                      </h3>
                    </center>
                  </div>
                  <FileSetting
                    dataoutput={dataoutput}
                    targetColumnsAre={targetColumnsAre}
                    HandleReg={HandleReg}
                  />
                  <Grid
                    container
                    xs={12}
                    style={{
                      backgroundColor: '#EEF1F9',
                      padding: '1rem',
                      borderTop: '1px solid #002A45',
                    }}
                  >
                    <Grid item xs={6} alignItems="center" justify="center">
                      {errorMessage !== '' && (
                        <Typography style={{ color: '#DC3223' }}>Error : {errorMessage}</Typography>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <Box style={{ display: 'flex' }}>
                        <Box style={{ flexBasis: '30%' }}>{}</Box>
                        <Box
                          style={{
                            flexBasis: '78%',
                            display: 'flex',
                            height: '2.5rem',
                            gap: '20px',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                          }}
                        >
                          <TextField
                            id="outlined-basic"
                            label="Enter Activity Name"
                            variant="outlined"
                            name="captureName"
                            onChange={inputHandler}
                            size="small"
                            style={{
                              width: '170px',
                              height: '38px',
                              marginTop: 6,
                              marginBottom: 6,
                              backgroundColor: 'white',
                            }}
                          />
                          <Button
                            type="button"
                            disabled={selection.length < 2 || selection.length > 2}
                            variant="contained"
                            startIcon={
                              loadingResult && (
                                <CircularProgress style={{ color: 'white' }} size={20} />
                              )
                            }
                            color="primary"
                            onClick={handleAnalyze}
                            style={{
                              width: '150px',
                              height: '38px',
                              marginTop: 6,
                              marginBottom: 6,
                            }}
                          >
                            Analyze
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </div>
              </>
            )}
          </Box>{' '}
        </>
      )}
      {/* {loadingResult ? <Loading /> : ''} */}
      {display && (
        <>
          <Paper style={{ borderColor: 'black' }}>
            <Box style={{ backgroundColor: '#002A45', color: 'white', padding: '0.5rem' }}>
              <Typography variant="h5">Resource details:</Typography>
            </Box>
            <Box style={{ padding: '1rem' }}>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                }}
              >
                {resourcesAre?.map((data: any) => {
                  return (
                    <>
                      {data?.FileName && (
                        <Box
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: '#EEF1F9',
                            height: '2rem',
                            paddingLeft: '1rem',
                          }}
                        >
                          <Box style={{ flexBasis: '25%' }}>
                            <Typography style={{ fontWeight: 'bold' }}>
                              File Name: {data?.FileName}
                            </Typography>
                          </Box>
                          <Box style={{ flexBasis: '25%' }}>
                            <Typography style={{ fontWeight: 'bold' }}>
                              File Type: {data?.FileType}
                            </Typography>
                          </Box>
                          <Box style={{ flexBasis: '25%' }}>
                            <Typography style={{ fontWeight: 'bold' }}>
                              Sheet Name: {data?.SheetName != null ? data?.SheetName : 'NA'}
                            </Typography>
                          </Box>
                          <Box style={{ flexBasis: '25%' }}>
                            <Typography style={{ fontWeight: 'bold' }}>
                              Target Column: {data?.TargetColumn}
                            </Typography>
                          </Box>
                        </Box>
                      )}
                    </>
                  );
                })}
              </Box>
            </Box>
          </Paper>
          <Paper style={{ borderColor: 'black' }}>
            <div style={{ marginLeft: '5px' }}>
              <h3>Document Similarity: {similarityScore}% </h3>
            </div>
          </Paper>
          <Box style={{ marginTop: 5, backgroundColor: '#EEF1F9', border: '1px solid #002A45' }}>
            <ExpressGrid rows={rows} columns={columnsAre}>
              <DataTypeProvider
                for={columnNamesAre}
                formatterComponent={({ value }) => (
                  <span title={value} style={{ whiteSpace: `${newLine}` }}>
                    {value}
                  </span>
                )}
              />
              <SortingState />
              <IntegratedSorting />
              <PagingState
                currentPage={currentPage1}
                onCurrentPageChange={setCurrentPage1}
                pageSize={pageSize1}
                onPageSizeChange={setPageSize1}
              />
              <IntegratedPaging />
              <Table
                tableComponent={MultiAllocTableComp}
                containerComponent={tableAllocatorComponent}
                headComponent={tableHeaderComponent}
                columnExtensions={columnsAre.map((c: { name: any }) => ({
                  columnName: c.name,
                  wordWrapEnabled: toggleHeight,
                }))}
              />
              <TableHeaderRow showSortingControls />
              <TableColumnVisibility
                hiddenColumnNames={hiddenColumnNames}
                onHiddenColumnNamesChange={setHiddenColumnNames}
              />
              <Toolbar rootComponent={toolbarComponent} />
              <ColumnChooser />
              <ToolBarPlugin name="Download" onClick={startExport} />
              <ToolBarPlugin
                name="Height"
                title="Wrap Text"
                onClick={() => {
                  setToggleHeight(!toggleHeight);
                }}
              />
              <ToolBarPlugin
                name="NewLine"
                onClick={() => (newLine !== 'pre-line' ? setNewLine('pre-line') : setNewLine(''))}
              />
              <PagingPanel
                pageSizes={pageSizes}
                containerComponent={(props) => (
                  <>
                    <PagingPanel.Container {...props} className={classes.pagingPanelContainer} />
                  </>
                )}
              />
            </ExpressGrid>
            <GridExporter ref={exporterRef} rows={rows} columns={columnsAre} onSave={onSave} />
            <NavigationGotoNew
              setPageSize={setPageSize1}
              fileLength={rows.length}
              pageSize={pageSize1}
              dis={dis1}
              setTemPage={setTemPage1}
              handlePageNum={handlePageNum1}
              currentPage={currentPage1}
              setCurrentPage={setCurrentPage1}
            />
          </Box>
        </>
      )}
    </>
  );
}

export default DocSimilarity;
