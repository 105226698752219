/* eslint-disable react/destructuring-assignment */
import {
  DataTypeProvider,
  FilteringState,
  GroupingState,
  IntegratedFiltering,
  IntegratedGrouping,
  IntegratedPaging,
  IntegratedSelection,
  IntegratedSorting,
  PagingState,
  SearchState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  Grid as ExpressGrid,
  Table,
  PagingPanel,
  TableFilterRow,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
  Toolbar,
  TableColumnVisibility,
  ColumnChooser,
  TableColumnResizing,
  DragDropProvider,
  TableColumnReordering,
  TableGroupRow,
  SearchPanel,
  GroupingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import Highlighter from 'react-highlight-words';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  BarChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Bar,
} from 'recharts';
import { makeStyles, withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import {
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Box,
  IconButton,
  FormControl,
  Grid,
  InputLabel,
  Paper,
  Select,
  TextField,
  Popover,
} from '@material-ui/core';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import MultiSelect from 'react-multi-select-component';
import { Workload3Create } from 'WorkloadType';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { iCapturePdf, fileDetails, iCaptureExcel, iCaptureCsv } from '../../../../../api/module1';
import {
  idocdiffer,
  multiText,
  textToText,
  textToTextInitialFilter,
  runSimilarityDoc,
} from '../../../../../api/module2';
import ExcelDoc from '../../../../../assets/icons8-excel-48.png';
import PdfDoc from '../../../../../assets/pdf_ins.png';
import CsvDoc from '../../../../../assets/csv_icons.png';
import PptDoc from '../../../../../assets/icons8-powerpoint-48.png';
import DocxDoc from '../../../../../assets/icons8-microsoft-word-2019-48.png';
import Module1Context from '../../../Module2Context';
import formatBytes from '../../../../../tools/formatBytes';
import docdiffer from '../../../fixtures/docdiffer.json';
import ToolBarPlugin from '../../../../../components/Datagrid/ToolBarPlugin';
import TableHeaderCellWrap from '../../../../../components/Datagrid/TableHeaderCellWrap';
import '../../../../tabulationStyle.css';
import TStyles, {
  rowComponent,
  tableAllocatorComponent,
  tableHeaderComponent,
  toolbarComponent,
  tUseStyles,
} from '../../../../../components/Datagrid/TStyles';
import { DOCTODATASET_COLORS, DOCTODATASET_LABELS } from '../../../../../../src/const';
import {
  workloadCreate,
  workloadGetById,
  workloadRunPost,
  workloadRunPostPDF,
} from '../../../../../../src/api/workloads';
import { getCaptureName } from '../../../../../api/breakdowns';
import Loading from '../../../../../components/Core/Loading';
import NavigationGoto from '../../../../../components/Core/NavigationGoto';
import '../../highlight.css';
// import Highlight from 'react-highlight-words';
import DropSelectMui from '../../../../../components/Core/DropSelectMui';
import MultiAllocTableComp from '../../../../../components/Datagrid/MultiAllocTableComp';
import NavigationGotoNew from '../../../../../components/Core/NavigationGotoNew';
import M2GlobalContext from '../../../../ContextMager/M2GlobalContext';
import SimilarityDocFilter from '../../../../../components/Filter/Components/SimilarityDocFilter';

// table header
const TableComponentBase = ({ classes, ...restProps }: any) => (
  <Table.Table {...restProps} className={classes.tableStriped} />
);

const TableComponent = withStyles(TStyles, { name: 'TableComponent' })(TableComponentBase);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  root2: {
    flexGrow: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    '& .MuiInputLabel-outlined': {
      color: '#002A45 !important',
      fontWeight: 'bold',
    },
  },
  captureHandle: {
    '& .MuiInputLabel-outlined': {
      color: '#002A45 !important',
      fontWeight: 'bold',
    },
  },
  gridStyle: {
    border: '10px solid black ',
  },
  formControlMultiTextlebel: {
    marginTop: 10,
  },
  formControlMulti: {
    margin: theme.spacing(1),
    marginTop: -2,
    minWidth: 230,
    maxWidth: 230,
  },
  pagingPanelContainer: {
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    // marginTop: '10px',
    // width: '50% !important',
    padding: '0px !important',
    marginRight: '244px !important',
    '& .Pager-pager': {
      padding: 0,
    },
    // Apply other custom styles as needed
    '& .MuiIconButton-label': {
      color: '#001F3C',
      padding: 5,
      backgroundColor: '#FFFFFF',
      borderRadius: '50%',
      boxShadow: '#00000029',
    },
    // '& .MuiButton-label': {
    //   color: '#001F3C',
    //   padding: 2,
    //   backgroundColor: '#FFFFFF',
    //   borderRadius: '50%',
    //   boxShadow: '#00000029',
    // },
    '& .MuiButton-root': {
      '&.MuiButton-textPrimary .Pagination-activeButton': {
        backgroundColor: 'red !important',
        color: 'white !important',
        // Add other custom styles as needed
      },
      color: '#001F3C',
      padding: '0px 5px',
      backgroundColor: 'white',
      // borderRadius: '50%',
      boxShadow: '#00000029',
      // gap: 10,
      margin: 9,
      // '&.Pagination-activeButton': {
      //   background: 'red !important',
      // },
      // '& .Pagination-button': {
      //   '& .Pagination-activeButton': {
      //     background: 'red !important',
      //   },
      // },
    },
  },
}));

const columns: any = [
  { name: 'filename', title: 'File Name' },
  { name: 'size', title: 'Size' },
  { name: 'folderName', title: 'Folder' },
  { name: 'date', title: 'Date upload' },
  { name: 'userName', title: 'Uploaded by' },
];

type Props = {
  id?: number;
  files?: any;
  setFiles?: any;
};

const COLORS = ['#FF875A', '#002A45', 'red', '#FFFF99', '#FFBB28', 'green'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
      fontSize="16px"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

function SimilarityAnalysisNew({ id: workloadId, files, setFiles }: Props) {
  const classes = useStyles();
  const history = useHistory();
  const { state, dispatch } = useContext(M2GlobalContext);
  const { similarityFilter } = state;
  const {
    simi_FilterPlatforms,
    simi_AlstomEntities,
    simi_AlstomEntitiesDef,
    simi_FilterPlatformsDef,
    simi_FilterProjects,
    simi_FilterProjectsDef,
    simi_FilterDocumentProfiles,
    simi_FilterDocumentProfilesDef,
    simi_FilterSolution,
    simi_FilterSolutionDef,
    simi_FilterProductDef,
    simi_FilterProduct,
    dropDown1,
    dropDown2,
    dropDown3,
    dropDown4,
    dropDown5,
    dropDown6,
  } = similarityFilter;
  const { projectId } = useParams() as any;
  const tableUseStyles = tUseStyles();
  const { project } = useContext(Module1Context);
  // const [files, setFiles] = useState<any[]>([]);
  // const onChangeAttribute = (value: any) => {
  //   // console.log('value: ', value, 'type of value:', typeof value, JSON.stringify(value));
  //   if (value.length > 1) {
  //     // selection.slice(-1).pop();
  //     selection.pop();
  //     setSnack('Deselect and then select another file OR click again', 'warning');
  //   } else {
  //     setSelection(value);
  //   }
  // };

  const [selection, setSelection] = useState([]);
  // to control the loader and display data full Grid
  const { setSnack } = useContext(Module1Context);
  const [loading, setLoading] = useState(false);
  const [loadingSecond, setLoadingSecond] = useState(false);
  const [display, setDisplay] = useState(false);
  const [sheetNames, setSheetNames] = useState<any[]>([]);
  const [columnName, setColumnName] = useState<any[]>([]);
  const [sheets, setSheets] = useState<any[]>([]);
  const [selectedSheetName, setSelectedSheetName] = useState<any>();
  const [selectedColumnName, setSelectedColumnName] = useState<any>();
  const [fileType, setFileType] = useState<any>();
  const [displayGrid, setDisplayGrid] = useState<any>();
  const [errorMsg, setErrorMsg] = useState<any>();
  const [pageSizes] = useState([5, 10, 20, 50]);
  const [rows, setRows] = useState<any>([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [columnsAre, setColumnsAre] = useState<any>([]);
  const [csvDataframe, setCsvDataframe] = useState<any>();
  const [pdfDataframe, setPdfDataframe] = useState<any>();
  const [columnWidths, setColumnWidths] = useState([] as any);
  const exporterRef = useRef(null);
  const [captureNames, setCaptureNames] = useState<any[]>([]);
  const [currentCaptureName, setCurrentCaptureName] = useState<any>('');
  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);
  const onSave = (workbook: any) => {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        `${currentCaptureName}.xlsx`
      );
    });
  };
  const [scopeSettingExcel, setScopeSettingExcel] = useState<any>();
  const [scopeSettingCsv, setScopeSettingCsv] = useState<any>();
  const [scopeSettingPdf, setScopeSettingPdf] = useState<any>();
  const [excelbool, setExcelbool] = useState<boolean>(false);
  const [csvbool, setCsvbool] = useState<boolean>(false);
  const [pdfbool, setPdfbool] = useState<boolean>(false);
  const [graphOP, setGraphOP] = useState<any>();
  const [toggleHeight, setToggleHeight] = useState(false);
  const [hiddenColumnNames, setHiddenColumnNames] = useState([
    // 'Project1',
    // 'Project2',
    // 'Project3',
    // 'Text1',
    // 'Text2',
    // 'Text3',
    // 'Score1',
    // 'Score2',
    // 'Score3',
    'RBS1',
    'RBS2',
    'RBS3',
    'Allocation1',
    'Allocation2',
    'Allocation3',
    // 'Compliance1',
    // 'Compliance2',
    // 'Compliance3',
    'Entity1',
    'Entity2',
    'Entity3',
    'DOCUMENT_TITLE',
    'Document Title1',
    'Document Title2',
    'Document Title3',
    'Document Title',
    'Document Profile',
    'Document Profile1',
    'Document Profile2',
    'Document Profile3',
    'Document Path',
    'Document Path1',
    'Document Path2',
    'Document Path3',
    'Document Version',
    'Document Version1',
    'Document Version2',
    'Document Version3',
    'Last Modified By',
    'Last Modified By1',
    'Last Modified By2',
    'Last Modified By3',
    'Last Modified On',
    'Last Modified On1',
    'Last Modified On2',
    'Last Modified On3',
    'URL',
    'URL1',
    'URL2',
    'URL3',
    // 'Combined Compliance Status',
    // 'Compliance status influenced by',
    'Cleaned_column',
  ]);
  const [hiddenColumnSelected, setHiddenColumnSelected] = useState([]);

  const [targetCol, setTargetCol] = useState<any>('');
  const [alstomEntitiesDefault, setAlstomEntitiesDefault] = useState<any>();
  const [filterPlatformsDefault, setFilterPlatformsDefault] = useState<any>();
  const [filterProjectsDefault, setFilterProjectsDefault] = useState<any>();
  const [filterDocumentProfilesDefault, setFilterDocumentProfilesDefault] = useState<any>();

  const [alstomEntities, setAlstomEntities] = useState<any>([]);
  const [filterProjects, setFilterProjects] = useState<any[]>([]);
  const [filterDocumentProfiles, setFilterDocumentProfiles] = useState<any[]>([]);
  const [filterPlatforms, setFilterPlatforms] = useState<any[]>([]);

  const [selectedEntities, setSelectedEntities] = useState([]);
  const [payloadHere, setPayloadHere] = useState<any>();
  const [selectedPlatforms, setSelectedPlatforms] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [selectedDocumentProfiles, setSelectedDocumentProfiles] = useState([]);

  const [multiFilterDisplay, setMultiFilterDisplay] = useState<any>(false);
  const [inputCaptureName, setInputCaptureName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [dis, setDis] = useState(false);
  const [tempPage, setTemPage] = useState(0);

  const [currentPage1, setCurrentPage1] = useState(0);
  const [tempPage1, setTemPage1] = useState(0);
  const [dis1, setDis1] = useState(true);
  const [pageSize1, setPageSize1] = useState(5);

  const handlePageNum1 = () => {
    const rel = tempPage1 - 1;
    setCurrentPage1(rel);
  };

  const handlePageNum = () => {
    const rel = tempPage - 1;
    setCurrentPage(rel);
  };

  const inputHandler = (event: any) => setInputCaptureName(event.target.value);

  // function onChangeEntity(option: any) {
  //   setSelectedEntities(option);
  //   // extra argu implemented but not in use
  //   const extra = 'noChange_entity';
  //   // eslint-disable-next-line @typescript-eslint/no-use-before-define
  //   multifilterCall(option, selectedPlatforms, selectedProjects, selectedDocumentProfiles, extra);
  // }

  // function onChangePlatform(option: any) {
  //   setSelectedPlatforms(option);
  //   const extra = 'noChange_platform';
  //   // eslint-disable-next-line @typescript-eslint/no-use-before-define
  //   multifilterCall(selectedEntities, option, selectedProjects, selectedDocumentProfiles, extra);
  // }

  // function onChangeSolution(option: any) {
  //   // setDropDown1(option);
  //   dispatch({ type: 'change_simi_Solution', payload: option });
  //   const extra = 'change_solution';
  //   // eslint-disable-next-line @typescript-eslint/no-use-before-define
  //   multifilterCall(dropDown1, dropDown2, dropDown3, dropDown4, option, dropDown6, extra);
  // }

  // function onChangeProject(option: any) {
  //   setSelectedProjects(option);
  //   const extra = 'noChange_project';
  //   // eslint-disable-next-line @typescript-eslint/no-use-before-define
  //   multifilterCall(selectedEntities, selectedPlatforms, option, selectedDocumentProfiles, extra);
  // }

  // function onChangeDocumentProfile(option: any) {
  //   setSelectedDocumentProfiles(option);
  //   const extra = 'noChange_documentProfile';
  //   // eslint-disable-next-line @typescript-eslint/no-use-before-define
  //   multifilterCall(selectedEntities, selectedPlatforms, selectedProjects, option, extra);
  // }

  // function multifilterCall(en: any, plat: any, proj: any, docprof: any, extra: any) {
  //   let entityVal: any = [];
  //   let platformVal: any = [];
  //   let projectVal: any = [];
  //   let documentProfileVal: any = [];
  //   en.forEach((o: any) => {
  //     entityVal.push(o);
  //   });
  //   plat.forEach((o: any) => {
  //     platformVal.push(o);
  //   });
  //   proj.forEach((o: any) => {
  //     projectVal.push(o);
  //   });
  //   docprof.forEach((o: any) => {
  //     documentProfileVal.push(o);
  //   });
  //   if (entityVal.length === 0 || entityVal.length === alstomEntitiesDefault.length) {
  //     entityVal = ['*'];
  //   }
  //   if (platformVal.length === 0 || platformVal.length === filterPlatformsDefault.length) {
  //     platformVal = ['*'];
  //   }
  //   if (projectVal.length === 0 || projectVal.length === filterProjectsDefault.length) {
  //     projectVal = ['*'];
  //   }
  //   if (
  //     documentProfileVal.length === 0 ||
  //     documentProfileVal.length === filterDocumentProfilesDefault.length
  //   ) {
  //     documentProfileVal = ['*'];
  //   }
  //   // if (artifactEnt.length === 0 || artifactEnt.length === artifactDefault.length) {
  //   //   artifactEnt = ['*'];
  //   // }
  //   textToText(entityVal, platformVal, projectVal, documentProfileVal)
  //     .then((payload: any) => {
  //       if (payload) {
  //         // console.log(payload);
  //         const entityAfterFilter: any = [];
  //         // eslint-disable-next-line guard-for-in
  //         for (const i in payload.filters.alstomEntities) {
  //           const item = payload.filters.alstomEntities[i];
  //           entityAfterFilter.push({
  //             label: item,
  //             value: item,
  //           });
  //         }
  //         if (extra !== 'noChange_entity') {
  //           setAlstomEntities(entityAfterFilter);
  //         }
  //         const platformAfterFilter: any = [];
  //         // eslint-disable-next-line guard-for-in
  //         for (const i in payload.filters.platforms) {
  //           const item = payload.filters.platforms[i];
  //           platformAfterFilter.push({
  //             label: item,
  //             value: item,
  //           });
  //         }
  //         if (extra !== 'noChange_platform') {
  //           setFilterPlatforms(platformAfterFilter);
  //         }
  //         const projectAfterFilter: any = [];
  //         // eslint-disable-next-line guard-for-in
  //         for (const i in payload.filters.projects) {
  //           const item = payload.filters.projects[i];
  //           projectAfterFilter.push({
  //             label: item,
  //             value: item,
  //           });
  //         }
  //         if (extra !== 'noChange_project') {
  //           setFilterProjects(projectAfterFilter);
  //         }
  //         const documentProfileAfterFilter: any = [];
  //         // eslint-disable-next-line guard-for-in
  //         for (const i in payload.filters.documentProfiles) {
  //           const item = payload.filters.documentProfiles[i];
  //           documentProfileAfterFilter.push({
  //             label: item,
  //             value: item,
  //           });
  //         }
  //         if (extra !== 'noChange_documentProfile') {
  //           setFilterDocumentProfiles(documentProfileAfterFilter);
  //         }
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     })
  //     .finally(() => {});
  // }

  function onChangeProductLineValue(option: any) {
    // setDropDown1(option);
    const mapdata = option.map((data) => {
      return {
        label: data,
        value: data,
      };
    });
    dispatch({ type: 'change_simi_entity', payload: mapdata });
    // extra argu implemented but not in use
    const extra = 'change_entity';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    multifilterCall(option, dropDown2, dropDown3, dropDown4, dropDown5, dropDown6, extra);
  }

  function onChangePlatformValue(option: any) {
    // setDropDown1(option);
    const mapdata = option.map((data) => {
      return {
        label: data,
        value: data,
      };
    });
    dispatch({ type: 'change_simi_Platform', payload: mapdata });
    const extra = 'change_platform';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    multifilterCall(dropDown1, option, dropDown3, dropDown4, dropDown5, dropDown6, extra);
  }

  function onChangeProjectValue(option: any) {
    // setDropDown1(option);
    const mapdata = option.map((data) => {
      return {
        label: data,
        value: data,
      };
    });
    dispatch({ type: 'change_simi_Project', payload: mapdata });
    const extra = 'change_project';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    multifilterCall(dropDown1, dropDown2, option, dropDown4, dropDown5, dropDown6, extra);
  }

  function onChangeDocumentProfile(option: any) {
    // setDropDown1(option);
    const mapdata = option.map((data) => {
      return {
        label: data,
        value: data,
      };
    });
    dispatch({ type: 'change_simi_DocumentProfile', payload: mapdata });
    const extra = 'change_documentProfile';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    multifilterCall(dropDown1, dropDown2, dropDown3, option, dropDown5, dropDown6, extra);
  }

  function onChangeSolution(option: any) {
    // setDropDown1(option);
    const mapdata = option.map((data) => {
      return {
        label: data,
        value: data,
      };
    });
    dispatch({ type: 'change_simi_Solution', payload: mapdata });
    const extra = 'change_solution';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    multifilterCall(dropDown1, dropDown2, dropDown3, dropDown4, option, dropDown6, extra);
  }

  function onChangeProduct(option: any) {
    // setDropDown1(option);
    const mapdata = option.map((data) => {
      return {
        label: data,
        value: data,
      };
    });
    dispatch({ type: 'change_simi_Product', payload: mapdata });
    const extra = 'change_product';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    multifilterCall(dropDown1, dropDown2, dropDown3, dropDown4, dropDown5, option, extra);
  }

  function multifilterCall(
    en: any,
    plat: any,
    proj: any,
    docprof: any,
    sol: any,
    prod: any,
    extra: any
  ) {
    const entityVal: any = [];
    const platformVal: any = [];
    const projectVal: any = [];
    const documentProfileVal: any = [];
    const solutionVal: any = [];
    const productVal: any = [];
    en?.forEach((o: any) => {
      entityVal?.push(o?.value);
    });
    plat?.forEach((o: any) => {
      platformVal?.push(o?.value);
    });
    sol?.forEach((o: any) => {
      solutionVal?.push(o?.value);
    });
    prod?.forEach((o: any) => {
      productVal?.push(o?.value);
    });
    proj?.forEach((o: any) => {
      projectVal?.push(o?.value);
    });
    docprof?.forEach((o: any) => {
      documentProfileVal?.push(o?.value);
    });

    textToText(entityVal, platformVal, solutionVal, productVal, projectVal, documentProfileVal)
      .then((payload: any) => {
        if (payload) {
          // console.log(payload);
          const entityAfterFilter: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters?.alstomEntities) {
            const item = payload.filters.alstomEntities[i];
            entityAfterFilter.push({
              label: item,
              value: item,
            });
          }
          // if (extra !== 'noChange_entity') {
          //   setAlstomEntities(entityAfterFilter);
          // }
          const platformAfterFilter: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters?.platforms) {
            const item = payload.filters.platforms[i];
            platformAfterFilter.push({
              label: item,
              value: item,
            });
          }

          const solutionAfterFilter: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters?.solutions) {
            const item = payload.filters.solutions[i];
            solutionAfterFilter.push({
              label: item,
              value: item,
            });
          }

          const productAfterFilter: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters?.products) {
            const item = payload.filters.products[i];
            productAfterFilter.push({
              label: item,
              value: item,
            });
          }

          // if (extra !== 'noChange_platform') {
          //   setFilterPlatforms(platformAfterFilter);
          // }
          const projectAfterFilter: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters?.projects) {
            const item = payload.filters.projects[i];
            projectAfterFilter.push({
              label: item,
              value: item,
            });
          }
          // if (extra !== 'noChange_project') {
          //   setFilterProjects(projectAfterFilter);
          // }
          const documentProfileAfterFilter: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters?.documentProfiles) {
            const item = payload.filters.documentProfiles[i];
            documentProfileAfterFilter.push({
              label: item,
              value: item,
            });
          }
          // if (extra !== 'noChange_documentProfile') {
          //   setFilterDocumentProfiles(documentProfileAfterFilter);
          // }

          switch (extra) {
            case 'change_entity': {
              const obj = {
                filterPlatforms: platformAfterFilter,
                filterProjects: projectAfterFilter,
                filterDocumentProfiles: documentProfileAfterFilter,
                filterSolution: solutionAfterFilter,
                filterProduct: productAfterFilter,
              };
              dispatch({ type: 'simi_noChange_in_entity', payload: obj });
              break;
            }

            case 'change_platform': {
              const obj = {
                alstomEntities: entityAfterFilter,
                filterProjects: projectAfterFilter,
                filterDocumentProfiles: documentProfileAfterFilter,
                filterSolution: solutionAfterFilter,
                filterProduct: productAfterFilter,
              };
              dispatch({ type: 'simi_noChange_in_platform', payload: obj });
              break;
            }

            case 'change_solution': {
              const obj = {
                alstomEntities: entityAfterFilter,
                filterPlatforms: platformAfterFilter,
                filterProjects: projectAfterFilter,
                filterDocumentProfiles: documentProfileAfterFilter,
                filterProduct: productAfterFilter,
              };
              dispatch({ type: 'simi_noChange_in_solution', payload: obj });
              break;
            }

            case 'change_product': {
              const obj = {
                alstomEntities: entityAfterFilter,
                filterPlatforms: platformAfterFilter,
                filterProjects: projectAfterFilter,
                filterDocumentProfiles: documentProfileAfterFilter,
                filterSolution: solutionAfterFilter,
              };
              dispatch({ type: 'simi_noChange_in_product', payload: obj });
              break;
            }

            case 'change_project': {
              const obj = {
                alstomEntities: entityAfterFilter,
                filterPlatforms: platformAfterFilter,
                filterDocumentProfiles: documentProfileAfterFilter,
                filterSolution: solutionAfterFilter,
                filterProduct: productAfterFilter,
              };
              dispatch({ type: 'simi_noChange_in_project', payload: obj });
              break;
            }

            case 'change_documentProfile': {
              const obj = {
                alstomEntities: entityAfterFilter,
                filterPlatforms: platformAfterFilter,
                filterProjects: projectAfterFilter,
                filterSolution: solutionAfterFilter,
                filterProduct: productAfterFilter,
              };
              dispatch({ type: 'simi_noChange_in_documentProfile', payload: obj });
              break;
            }
            default:
              break;
          }
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  }

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleSetRowsColumns = async (myRows: {}[]) => {
    // const myCols = Object.keys(myRows[0]).map((key) => ({
    //   name: key,
    //   title: key.toUpperCase(),
    // })) as Column[];
    const araryCol = Object.keys(myRows[0]);
    const myCols: any = [];
    araryCol.map((key) => {
      // const keyTemp = key.replace(' ', '_');
      if (key !== 'RawData') {
        if (key !== 'Coordinates') {
          if (key !== 'i18n') {
            myCols.push({
              name: key,
              title: key.toUpperCase(),
            });
          }
        }
      }
      return key;
    });
    const resizeColumns = myCols.map((c, index) => {
      return {
        columnName: c.name,
        width: 300,
      };
    });
    setColumnWidths(resizeColumns);
    setColumnsAre(myCols);
    setRows(myRows);
  };

  useEffect(() => {
    initialCall();
    const temp = files.map((data) => {
      return {
        ...data,
        isNew: false,
      };
    });
    setFiles(temp);
  }, []);

  let fileIs1;
  if (selection.length > 0 && selection[0] !== undefined && selection.length < 2) {
    fileIs1 = files[selection[0]].filename || '';
    // console.log(fileIs1);
  }

  function initialCall() {
    // textToTextInitialFilter()
    //   .then((payload: any) => {
    //     if (payload) {
    //       const ent: any = [];
    //       // eslint-disable-next-line guard-for-in
    //       for (const i in payload.filters.alstomEntities) {
    //         const item = payload.filters.alstomEntities[i];
    //         ent.push({
    //           label: item,
    //           value: item,
    //         });
    //       }
    //       // console.log(employees);
    //       setAlstomEntities(ent);
    //       setAlstomEntitiesDefault(ent);
    //       // another one for platform
    //       const plat: any = [];
    //       // eslint-disable-next-line guard-for-in
    //       for (const i in payload.filters.platforms) {
    //         const item = payload.filters.platforms[i];
    //         plat.push({
    //           label: item,
    //           value: item,
    //         });
    //       }
    //       // console.log(employees);
    //       setFilterPlatforms(plat);
    //       setFilterPlatformsDefault(plat);
    //       // another one for projects
    //       const pro: any = [];
    //       // eslint-disable-next-line guard-for-in
    //       for (const i in payload.filters.projects) {
    //         const item = payload.filters.projects[i];
    //         pro.push({
    //           label: item,
    //           value: item,
    //         });
    //       }
    //       // console.log(employees);
    //       setFilterProjects(pro);
    //       setFilterProjectsDefault(pro);
    //       // another one for projects
    //       const docpro: any = [];
    //       // eslint-disable-next-line guard-for-in
    //       for (const i in payload.filters.documentProfiles) {
    //         const item = payload.filters.documentProfiles[i];
    //         docpro.push({
    //           label: item,
    //           value: item,
    //         });
    //       }
    //       // console.log(employees);
    //       setFilterDocumentProfiles(docpro);
    //       setFilterDocumentProfilesDefault(docpro);
    //       // seperate code
    //       // setPayloadHere(payload);
    //     } else {
    //       setAlstomEntities([{ label: '', value: '' }]);
    //       setAlstomEntitiesDefault([{ label: '', value: '' }]);
    //       setFilterPlatforms([{ label: '', value: '' }]);
    //       setFilterPlatformsDefault([{ label: '', value: '' }]);
    //       setFilterProjects([{ label: '', value: '' }]);
    //       setFilterProjectsDefault([{ label: '', value: '' }]);
    //       setFilterDocumentProfiles([{ label: '', value: '' }]);
    //       setFilterDocumentProfilesDefault([{ label: '', value: '' }]);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   })
    //   .finally(() => {});

    textToTextInitialFilter()
      .then((payload: any) => {
        if (payload) {
          const ent = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters?.alstomEntities) {
            const item = payload.filters.alstomEntities[i];
            ent.push({
              label: item,
              value: item,
            });
          }
          const plat: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters?.platforms) {
            const item = payload.filters.platforms[i];
            plat.push({
              label: item,
              value: item,
            });
          }
          const sol: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters?.solutions) {
            const item = payload.filters.solutions[i];
            sol.push({
              label: item,
              value: item,
            });
          }
          const prod: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters?.products) {
            const item = payload.filters.products[i];
            prod.push({
              label: item,
              value: item,
            });
          }
          const pro: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters?.projects) {
            const item = payload.filters.projects[i];
            pro.push({
              label: item,
              value: item,
            });
          }
          const docpro: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters?.documentProfiles) {
            const item = payload.filters.documentProfiles[i];
            docpro.push({
              label: item,
              value: item,
            });
          }

          const obj = {
            alstomEntitiesDef: ent,
            filterProjectsDef: pro,
            filterSolutionDef: sol,
            filterProductDef: prod,
            filterDocumentProfilesDef: docpro,
            filterPlatformsDef: plat,
          };
          sessionStorage.setItem('simi_def_filter', JSON.stringify(obj));
          dispatch({ type: 'set_simi_default_fill', payload: obj });
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  }

  function getFileDetails(filename: any, filenameCopy: any, container: any) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    setDisplay(false);
    setDisplayGrid(false);
    setMultiFilterDisplay(true);
    setErrorMsg('');
    setErrorMessage('');
    fileDetails(filename, filenameCopy, container)
      .then((payload: any) => {
        if (payload) {
          // console.log(payload);
          setErrorMsg(payload.error);
          if (payload.error === null) {
            if (payload.output.FileType === 'Excel') {
              setSheetNames(payload.output.Sheet_names);
              setSheets(payload.output.Sheets);
              setFileType('Excel');
              setScopeSettingExcel(payload);
            } else if (payload.output.FileType === 'CSV') {
              setColumnName(payload.output.ColumnNames);
              setFileType('CSV');
              setCsvDataframe(payload.output.Text);
              setScopeSettingCsv(payload);
            } else if (payload.output.FileType === 'PDF') {
              setFileType('PDF');
              setPdfDataframe(payload.output.Text);
              setScopeSettingPdf(payload);
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setDisplay(true);
      });
  }

  function HandleReg(e: any) {
    if (e.target.name === 'sheetname') {
      setColumnName(['']);
      // console.log(e.currentTarget.value);
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < sheets.length; i++) {
        if (sheets[i].SheetName === e.currentTarget.value) {
          setColumnName(sheets[i].ColumNames);
          setSelectedSheetName(e.currentTarget.value);
        }
      }
    }
    if (e.target.name === 'columnname') {
      setSelectedColumnName(e.currentTarget.value);
    }
  }
  // console.log(columnName);

  function getMultiText() {
    setErrorMessage('');
    if (
      inputCaptureName.trim().length === 0 &&
      selectedSheetName !== undefined &&
      selectedColumnName !== undefined
    ) {
      setSnack('Enter activity name', 'error');
      return;
    }
    if (captureNames.includes(inputCaptureName.trim())) {
      setSnack('That activity name is taken.Please try another', 'error');
      return;
    }

    // start here
    let entityIs: any = [];
    let platform: any = [];
    let project: any = [];
    let documentProfile: any = [];
    let sol: any = [];
    let prod: any = [];
    // console.log(alignment);
    dropDown1.forEach((obj: any) => {
      entityIs.push(obj.value);
    });
    dropDown2.forEach((obj: any) => {
      platform.push(obj.value);
    });
    dropDown3.forEach((obj: any) => {
      project.push(obj.value);
    });
    dropDown4.forEach((obj: any) => {
      documentProfile.push(obj.value);
    });
    dropDown5.forEach((obj: any) => {
      sol.push(obj.value);
    });
    dropDown6.forEach((obj: any) => {
      prod.push(obj.value);
    });

    if (entityIs.length === 0) {
      entityIs = ['*'];
    }
    if (platform.length === 0) {
      platform = ['*'];
    }
    if (project.length === 0) {
      project = ['*'];
    }
    if (documentProfile.length === 0) {
      documentProfile = ['*'];
    }
    if (sol.length === 0) {
      sol = ['*'];
    }
    if (prod.length === 0) {
      prod = ['*'];
    }
    // done here
    setDisplayGrid(false);
    // setLoadingSecond(true);
    setLoading(true);
    if (selectedSheetName === undefined || selectedColumnName === undefined) {
      setSnack('Select both dropdown options', 'error');
      // setLoadingSecond(false)
      setLoading(false);
    } else {
      let dataframe: any;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < sheets.length; i++) {
        if (sheets[i].SheetName === selectedSheetName) {
          dataframe = sheets[i].Text;
        }
      }
      const columnNameFilter = columnName.filter(function (item) {
        return item !== selectedColumnName;
      });
      setHiddenColumnNames(hiddenColumnNames.concat(columnNameFilter));
      // console.log('scopeSettingExcel:', scopeSettingExcel);
      iCaptureExcel({
        FilePath: scopeSettingExcel.output.FilePath,
        Container: scopeSettingExcel.output.Container,
        BlobName: scopeSettingExcel.output.Blob,
        NameOfCapture: 'm2-iSimilarizer_Doc2Dataset_Excel',
        Sheet: selectedSheetName,
        TargetColumn: selectedColumnName,
        ColumnNames: columnName,
        Header: true,
        businessEntity: entityIs,
        platform,
        solution: sol,
        product: prod,
        project,
        documentProfile,
      })
        .then((payload: any) => {
          const len =
            JSON.parse(payload.output.Text)?.length > 0
              ? JSON.parse(payload.output.Text)?.length
              : 0;
          if (len === 0) {
            setSnack('Invalid file format', 'error');
            setDisplayGrid(false);
            // setLoadingSecond(false);
            return;
          }

          const generateComplianceStatus = excelbool;
          const r = {
            Text: payload.output.Text,
            TextLength: len,
            selectedColumn: selectedColumnName,
            generateComplianceBoolean: generateComplianceStatus,
            entityPassed: entityIs,
            platformPassed: platform,
            solutionPassed: sol,
            productPassed: prod,
            projectPassed: project,
            documentProfilePassed: documentProfile,
            FileName: scopeSettingExcel.output.Blob,
            FilePath: scopeSettingExcel.output.FilePath,
            NameOfCapture: inputCaptureName,
            Sheet: selectedSheetName,
          };
          const rr: any = [];
          rr.push(r);
          // instead of multitext API now have to call async API
          workloadCreate({
            project_id: projectId,
            app: 'module2',
            action: 'DocToDataset',
            resources: rr,
          })
            .then((workloadNew: Workload3Create) => {
              workloadRunPost(
                Number(workloadNew.id),
                {
                  dataframe: { Text: payload.output.Text },
                  column_name: selectedColumnName,
                  generateComplianceStatus,
                  businessEntity: entityIs,
                  platform,
                  solution: sol,
                  product: prod,
                  project,
                  documentProfile,
                },
                'm2-multi-text'
              )
                .then(() => {
                  window.history.pushState('', '', `/projects/${projectId}`);
                  history.push(`/projects/${projectId}`);
                })
                .catch((e) => {
                  setErrorMessage(e.detail);
                  setSnack('middleware API calling error catch here', 'error');
                })
                .finally(() => {
                  setLoading(false);
                });
            })
            .catch(() => {
              setSnack('middleware API calling error catch here', 'error');
            })
            .finally(() => {});
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    }
  }

  useEffect(() => {
    if (files.length === 0 || projectId === undefined || workloadId === undefined) {
      return;
    }
    setDisplayGrid(false);
    setLoadingSecond(true);
    workloadGetById(workloadId)
      .then((payload) => {
        // setDisplayGrid(true);
        // setLoadingSecond(false);
        setPayloadHere(payload);
        if (payload) {
          const { resources } = payload;
          const obj = {
            dataframe:
              payload?.resources[0]?.generateComplianceBoolean === true
                ? payload?.results_data[0]?.data?.output[0]
                : payload?.results_data[0]?.data?.output,

            targetColumns: resources[0].selectedColumn,
          };
          // console.log('old payload for similarity iner', obj);
          setPayloadHere(payload);
          if (
            payload?.resources[0]?.generateComplianceBoolean === false ||
            payload?.resources[0]?.doctodatset?.generateComplianceStatus === false
          ) {
            handleSetRowsColumns(JSON.parse(payload?.results_data[0]?.data?.output));
            setCurrentCaptureName(payload?.resources[0]?.NameOfCapture);
          } else {
            handleSetRowsColumns(JSON.parse(payload?.results_data[0]?.data?.output[0]));
            setCurrentCaptureName(payload?.resources[0]?.NameOfCapture);
            const r = JSON.parse(payload?.results_data[0]?.data?.output[1]);
            // console.log('r', r);
            setGraphOP(
              r.map((rec: any) => {
                return { name: rec['Compliance Status label'], value: rec.Count };
              })
            );
          }

          setDisplayGrid(true);
          setLoadingSecond(false);
        }
        // const { resources } = payload;
        // setTargetCol(resources[0].selectedColumn);
        // if (payload) {
        //   const obj = {
        //     dataframe:
        //       payload?.resources[0]?.generateComplianceBoolean === true
        //         ? payload?.results_data[0]?.data?.output[0]
        //         : payload?.results_data[0]?.data?.output,

        //     targetColumns: resources[0].selectedColumn,
        //   };

        //   try {
        //     setDisplayGrid(true);
        //     setLoadingSecond(false);
        //     const obj2 = [];
        //     // obj2.push(JSON?.parse(data)?.df);
        //     obj2.push(payload?.results_data[0]?.data?.output[1]);
        //     obj2.push(payload?.results_data[0]?.data?.output[2]);
        //     // console.log(payload);
        //     if (payload?.resources[0]?.generateComplianceBoolean === false) {
        //       // console.log(payload.resources[0].NameOfCapture);
        //       // handleSetRowsColumns(JSON.parse(obj2[0]));
        //       setCurrentCaptureName(payload?.resources[0]?.NameOfCapture);
        //       // console.log('setCurrentCaptureName', currentCaptureName);
        //     } else {
        //       // handleSetRowsColumns(JSON.parse(obj2[0]));
        //       setCurrentCaptureName(payload?.resources[0]?.NameOfCapture);
        //       const r = JSON.parse(payload?.results_data[0]?.data?.output[1]);
        //       setGraphOP(
        //         r.map((rec: any) => {
        //           return {
        //             name: rec['Compliance Status label'],
        //             value: rec.Count,
        //           };
        //         })
        //       );
        //     }
        //   } catch (e) {
        //     console.log(e);
        //   }
        //   // console.log('old payload for similarity iner', obj);
        //   // runSimilarityDoc(obj).then((data) => {
        //   //   // console.log('old result for similarity iner', JSON?.parse(data));

        //   // });
        // }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  }, [files, projectId, workloadId]);

  useEffect(() => {
    if (pageSize > files.length) {
      if (dis) {
        setDis((prev) => {
          return !prev;
        });
      }
    } else if (pageSize < files.length) {
      setDis((prev) => {
        return !prev;
      });
    }
  }, [pageSize]);
  // console.log('graphOP', graphOP);
  useEffect(() => {
    if (pageSize1 > rows.length) {
      if (dis1) {
        setDis1((prev) => {
          return !prev;
        });
      }
    } else if (pageSize1 < rows.length) {
      setDis1((prev) => {
        return !prev;
      });
    }
  }, [pageSize1]);

  useEffect(() => {
    getCaptureName(projectId).then((resp) => {
      if (resp) {
        setCaptureNames(resp);
        // console.log('respo', resp);
      }
    });
  }, []);
  function clearFilter() {
    // setAlstomEntities(alstomEntitiesDefault);
    // setSelectedEntities([]);
    // setFilterPlatforms(filterPlatformsDefault);
    // setSelectedPlatforms([]);
    // setFilterProjects(filterProjectsDefault);
    // setSelectedProjects([]);
    // setFilterDocumentProfiles(filterDocumentProfilesDefault);
    // setSelectedDocumentProfiles([]);
    dispatch({ type: 'clear_simiFilters' });
  }

  function getMultiTextCSV() {
    setErrorMessage('');
    if (
      inputCaptureName.trim().length === 0 &&
      (selectedSheetName !== undefined || selectedColumnName !== undefined)
    ) {
      setSnack('enter capture name', 'error');
      return;
    }
    if (captureNames.includes(inputCaptureName.trim())) {
      setSnack('That capture name is taken.Please try another', 'error');
      return;
    }
    // start here
    let entityIs: any = [];
    let platform: any = [];
    let sol: any = [];
    let prod: any = [];
    let project: any = [];
    let documentProfile: any = [];
    // console.log(alignment);
    dropDown1.forEach((obj: any) => {
      entityIs.push(obj.value);
    });
    dropDown2.forEach((obj: any) => {
      platform.push(obj.value);
    });
    dropDown3.forEach((obj: any) => {
      project.push(obj.value);
    });
    dropDown4.forEach((obj: any) => {
      documentProfile.push(obj.value);
    });
    dropDown5.forEach((obj: any) => {
      sol.push(obj.value);
    });
    dropDown6.forEach((obj: any) => {
      prod.push(obj.value);
    });

    if (entityIs.length === 0 || entityIs.length === simi_AlstomEntitiesDef.length) {
      entityIs = ['*'];
    }
    if (platform.length === 0 || platform.length === simi_FilterPlatformsDef.length) {
      platform = ['*'];
    }
    if (sol.length === 0 || sol.length === simi_FilterSolutionDef.length) {
      sol = ['*'];
    }
    if (prod.length === 0 || prod.length === simi_FilterProductDef.length) {
      prod = ['*'];
    }
    if (project.length === 0 || project.length === simi_FilterProjectsDef.length) {
      project = ['*'];
    }
    if (
      documentProfile.length === 0 ||
      documentProfile.length === simi_FilterDocumentProfilesDef.length
    ) {
      documentProfile = ['*'];
    }
    // done here
    setDisplayGrid(false);
    // setLoadingSecond(true);
    setLoading(true);
    if (selectedColumnName === undefined) {
      setSnack('Select dropdown option', 'error');
      setLoading(false);
    } else {
      const columnNameFilter = columnName.filter(function (item) {
        return item !== selectedColumnName;
      });
      setHiddenColumnNames(hiddenColumnNames.concat(columnNameFilter));
      iCaptureCsv({
        FilePath: scopeSettingCsv.output.FilePath,
        Container: scopeSettingCsv.output.Container,
        BlobName: scopeSettingCsv.output.Blob,
        NameOfCapture: 'm2-iSimilarizer_Doc2Dataset_Csv',
        TargetColumn: selectedColumnName,
        ColumnNames: columnName,
        Header: true,
      })
        .then((payload: any) => {
          const generateComplianceStatus = csvbool;
          const len = payload.output.Text?.length > 0 ? payload.output.Text?.length : 0;
          if (len === 0) {
            setSnack('Invalid file format', 'error');
            setDisplayGrid(false);
            setLoading(false);
            // setLoadingSecond(false);
            return;
          }
          const r = {
            Text: payload.output.Text,
            TextLength: len,
            selectedColumn: selectedColumnName,
            generateComplianceBoolean: generateComplianceStatus,
            entityPassed: entityIs,
            platformPassed: platform,
            solutionPassed: sol,
            productPassed: prod,
            projectPassed: project,
            documentProfilePassed: documentProfile,
            FileName: scopeSettingCsv.output.Blob,
            FilePath: scopeSettingCsv.output.FilePath,
            NameOfCapture: inputCaptureName,
          };
          const doctodoc = {
            dataframe: { Text: payload.output.Text },
            column_name: selectedColumnName,
            generateComplianceStatus,
            businessEntity: entityIs,
            platform,
            solution: sol,
            product: prod,
            project,
            documentProfile,
          };
          // calling middleware APi
          const rr: any = [];
          rr.push(r);
          workloadCreate({
            project_id: projectId,
            app: 'module2',
            action: 'DocToDataset',
            resources: rr,
          })
            .then((workloadNew: Workload3Create) => {
              workloadRunPost(
                Number(workloadNew.id),
                {
                  dataframe: { Text: payload.output.Text },
                  column_name: selectedColumnName,
                  generateComplianceStatus,
                  businessEntity: entityIs,
                  platform,
                  solution: sol,
                  product: prod,
                  project,
                  documentProfile,
                },
                'm2-multi-text'
              )
                .then(() => {
                  window.history.pushState('', '', `/projects/${projectId}`);
                  history.push(`/projects/${projectId}`);
                })
                .catch((e) => {
                  setErrorMessage(e.detail);
                  setSnack('middleware API calling error catch here', 'error');
                })
                .finally(() => {
                  setLoading(false);
                });
            })
            .catch(() => {
              setSnack('middleware API calling error catch here', 'error');
            })
            .finally(() => {});
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    }
  }

  function docToDatasetPdfFile() {
    setErrorMessage('');
    if (inputCaptureName.trim().length === 0) {
      setSnack('enter capture name', 'error');
      return;
    }
    if (captureNames.includes(inputCaptureName.trim())) {
      setSnack('That capture name is taken.Please try another', 'error');
      return;
    }
    // start here
    let entityIs: any = [];
    let platform: any = [];
    let sol: any = [];
    let prod: any = [];
    let project: any = [];
    let documentProfile: any = [];
    // console.log(alignment);
    dropDown1.forEach((obj: any) => {
      entityIs.push(obj.value);
    });
    dropDown2.forEach((obj: any) => {
      platform.push(obj.value);
    });
    dropDown3.forEach((obj: any) => {
      project.push(obj.value);
    });
    dropDown4.forEach((obj: any) => {
      documentProfile.push(obj.value);
    });
    dropDown5.forEach((obj: any) => {
      sol.push(obj.value);
    });
    dropDown6.forEach((obj: any) => {
      prod.push(obj.value);
    });

    if (entityIs.length === 0 || entityIs.length === simi_AlstomEntitiesDef.length) {
      entityIs = ['*'];
    }
    if (platform.length === 0 || platform.length === simi_FilterPlatformsDef.length) {
      platform = ['*'];
    }
    if (sol.length === 0 || sol.length === simi_FilterSolutionDef.length) {
      sol = ['*'];
    }
    if (prod.length === 0 || prod.length === simi_FilterProductDef.length) {
      prod = ['*'];
    }
    if (project.length === 0 || project.length === simi_FilterProjectsDef.length) {
      project = ['*'];
    }
    if (
      documentProfile.length === 0 ||
      documentProfile.length === simi_FilterDocumentProfilesDef.length
    ) {
      documentProfile = ['*'];
    }
    // done here
    setDisplayGrid(false);
    const generateComplianceStatus = pdfbool;
    const r = [
      {
        output: { NameOfCapture: inputCaptureName, FileName: scopeSettingPdf.output.Blob },
        TextLength: 100000,
        settingsModule: {
          FileName: scopeSettingPdf.output.Blob,
          FilePath: scopeSettingPdf.output.FilePath,
          Container: scopeSettingPdf.output.Container,
          BlobName: scopeSettingPdf.output.Blob,
          id: scopeSettingPdf.output.Blob,
          FileType: 'pdf',
          Language: scopeSettingPdf.output.Language,
          StartPage: 0,
          EndPage: 0,
          HeaderMargin: 0,
          FooterMargin: 0,
          TypesOfCapture: 'Paragraph_Capture',
          RegId: '',
          TableSwitch: true,
          MergeBullet: false,
          SmartHeaderFooter: true,
          SmartTableOfContent: true,
          TranslateTo: ['en'],
          NameOfCapture: inputCaptureName,
          modeService: 'DocToDataset',
        },
        doctodatset: {
          businessEntity: entityIs,
          generateComplianceStatus,
          platform,
          solution: sol,
          product: prod,
          project,
          documentProfile,
        },
      },
    ];
    workloadCreate({
      project_id: projectId,
      app: 'module2',
      action: 'DocToDataset',
      resources: r,
    })
      .then((workloadNew: Workload3Create) => {
        workloadRunPostPDF(Number(workloadNew.id), r, 'm2-multi-text')
          .then(() => {
            window.history.pushState('', '', `/projects/${projectId}`);
            history.push(`/projects/${projectId}`);
          })
          .catch((e) => {
            setErrorMessage(e.detail);
            setSnack('Error while workload post.', 'error');
            setDisplay(false);
            setLoading(false);
          })
          .finally(() => {});
      })
      .catch(() => {
        setSnack('Error while workload create.', 'error');
        setDisplay(false);
        setLoading(false);
      })
      .finally(() => {});
  }

  // eslint-disable-next-line consistent-return
  const customEntityValue = () => {
    if (!selectedEntities.length) {
      return <b style={{ color: '#002A45' }}>Entities</b>;
    }
  };
  // eslint-disable-next-line consistent-return
  const customPlatformValue = () => {
    if (!selectedPlatforms.length) {
      return <b style={{ color: '#002A45' }}>Platforms</b>;
    }
  };
  // eslint-disable-next-line consistent-return
  const customProjectValue = () => {
    if (!selectedProjects.length) {
      return <b style={{ color: '#002A45' }}>Projects</b>;
    }
  };
  // eslint-disable-next-line consistent-return
  const customDocumentProfileValue = () => {
    if (!selectedDocumentProfiles.length) {
      return <b style={{ color: '#002A45' }}>Document Profiles</b>;
    }
  };

  const onChangeAttribute = (selection: any) => {
    const newselection: any = [0];
    if (selection[0] === undefined) {
      // eslint-disable-next-line no-param-reassign
      selection[0] = selection.slice(-1).pop();
    }
    // eslint-disable-next-line prefer-destructuring
    newselection[0] = selection[selection.length - 1];
    setSelection(newselection);

    if (selection.length === 0) {
      // console.log('if condition no action required: ', selection);
    } else {
      const fileno = selection.slice(-1).pop();
      if (fileno !== undefined) {
        getFileDetails(files[fileno].filename, files[fileno].filename, files[fileno].container);
      } else {
        setDisplay(false);
      }
    }
  };

  function handleCheckboxBool(e: any) {
    if (e.target.name === 'excelCheckbox') {
      setExcelbool(e.target.checked);
    }
    if (e.target.name === 'csvCheckbox') {
      setCsvbool(e.target.checked);
    }
    if (e.target.name === 'pdfCheckbox') {
      setPdfbool(e.target.checked);
    }
  }

  const renderHighlighter = ({ row, value }) => {
    const { Input_Entities } = row;
    const arry = Input_Entities?.split(',') ?? [];

    return (
      <Highlighter
        highlightClassName="highlight"
        searchWords={arry}
        autoEscape={true}
        textToHighlight={value}
      />
    );
  };

  const renderHighlighter2 = ({ row, value }) => {
    const { Output_Entities } = row;
    const arry = Output_Entities?.split(',') ?? [];

    return (
      <Highlighter
        highlightClassName="orange"
        searchWords={arry}
        autoEscape={true}
        textToHighlight={value}
      />
    );
  };

  const renderFileName = ({ row }: any) => {
    const ext = row.filename.split('.');
    const extensions = {
      xlsx: ExcelDoc,
      csv: CsvDoc,
      pptx: PptDoc,
      docx: DocxDoc,
      pdf: PdfDoc,
    };
    return (
      <div
        style={{
          position: 'relative',
          padding: '0.3rem',
          width: 'max-content',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: '0.2rem' }}>
          <img src={extensions[ext[ext.length - 1]]} height="30" width="30" alt="img" />
          <Typography>{row.filename}</Typography>
        </div>
        {row?.isNew && (
          <div
            style={{
              width: 'max-content',
              padding: '0.2rem',
              borderRadius: '10px',
              backgroundColor: '#19AA6E',
              color: 'white',
              position: 'absolute',
              top: '-12px',
              right: '-35px',
            }}
          >
            <Typography style={{ fontSize: '0.85em' }}>New !</Typography>
          </div>
        )}
      </div>
    );
  };

  function getUniqueWords(text1, text2) {
    // Tokenize by matching words, numbers with optional decimals, and any individual punctuation
    const tokenize = (text) => text.toLowerCase().match(/\d+\.\d+|\w+|[^\s\w]/g) || [];

    // Tokenize each text after converting to lowercase
    const tokens1 = new Set(tokenize(text1));
    const tokens2 = new Set(tokenize(text2));

    // Find tokens unique to text1
    const uniqueToText1 = [...tokens1].filter((token) => !tokens2.has(token));
    // Find tokens unique to text2
    const uniqueToText2 = [...tokens2].filter((token) => !tokens1.has(token));

    return {
      uniqueToText1,
      uniqueToText2,
    };
  }

  const renderChapter = ({ row, value, column }: DataTypeProvider.ValueFormatterProps) => {
    if (!value) return null;
    // console.log(row, 'value');
    const mostSimilarText = row['Most Similar Text'];
    // Find common words between Chapter and Most Similar Text
    const commonWords = getUniqueWords(`${value}`, `${mostSimilarText}`);
    // console.log(commonWords, 'chacommonWords');
    return (
      <Highlighter
        searchWords={commonWords.uniqueToText1} // Array of common words to highlight
        textToHighlight={value} // The text in which to highlight the words
        autoEscape={true}
        highlightStyle={{ backgroundColor: 'yellow', fontWeight: 'bold' }} // Style for highlighted words
      />
    );
  };
  const renderSimilar = ({ row, value }: DataTypeProvider.ValueFormatterProps) => {
    // console.log(
    //   'selectedColumnName',
    //   payloadHere?.resources[0]?.selectedColumn ? payloadHere?.resources[0]?.selectedColumn : 'Text'
    // );
    if (!value) return null;
    // console.log(row, 'value');
    const mostSimilarText =
      row[
        payloadHere?.resources[0]?.selectedColumn
          ? payloadHere?.resources[0]?.selectedColumn
          : 'Text'
      ];
    // Find common words between Chapter and Most Similar Text
    const commonWords = getUniqueWords(`${value}`, `${mostSimilarText}`);
    return (
      <Highlighter
        searchWords={commonWords.uniqueToText1} // Array of common words to highlight
        textToHighlight={value} // The text in which to highlight the words
        autoEscape={true}
        highlightStyle={{ backgroundColor: 'yellow', fontWeight: 'bold' }} // Style for highlighted words
      />
    );
  };

  return (
    <>
      <div>
        <div>
          <span>
            <b>Select one file:</b>
          </span>
          <span>
            <br />
            {/* Total rows selected: {selection.length} */}
            <span style={{ marginRight: '10px' }}>
              Selected File Name: <b>{fileIs1 || 'NA'}</b>
            </span>
          </span>
          <br />
        </div>
        <div>
          <Box>
            <div style={{ marginTop: 5, backgroundColor: '#EEF1F9', border: '1px solid #002A45' }}>
              <ExpressGrid rows={files} columns={columns}>
                <DataTypeProvider for={['filename']} formatterComponent={renderFileName} />
                <SelectionState selection={selection} onSelectionChange={onChangeAttribute} />
                <FilteringState defaultFilters={[{ columnName: 'filename', value: '' }]} />
                <IntegratedFiltering />
                <SortingState />
                <IntegratedSorting />
                <PagingState
                  currentPage={currentPage}
                  onCurrentPageChange={setCurrentPage}
                  pageSize={pageSize}
                  onPageSizeChange={setPageSize}
                />
                <IntegratedPaging />
                <VirtualTable />
                <Table
                  tableComponent={MultiAllocTableComp}
                  containerComponent={tableAllocatorComponent}
                  headComponent={tableHeaderComponent}
                />
                <TableHeaderRow showSortingControls />
                <TableFilterRow rowComponent={rowComponent} />
                <TableSelection selectByRowClick highlightRow showSelectionColumn={true} />
                {/* <PagingPanel pageSizes={pageSizes} /> */}
                <PagingPanel
                  pageSizes={pageSizes}
                  containerComponent={(props) => (
                    <>
                      <PagingPanel.Container {...props} className={classes.pagingPanelContainer} />
                    </>
                  )}
                />
              </ExpressGrid>
              <NavigationGotoNew
                setPageSize={setPageSize}
                fileLength={files.length}
                pageSize={pageSize}
                dis={dis}
                setTemPage={setTemPage}
                handlePageNum={handlePageNum}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
            <span style={{ color: 'red' }}>{errorMsg}</span>
            <div>
              {display && errorMsg === null && (
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    backgroundColor: 'white',
                    borderLeft: '1px solid #002A45',
                    borderBottom: '1px solid #002A45',
                    borderRight: '1px solid #002A45',
                    padding: '1rem',
                    width: '100%',
                    gap: '20px',
                  }}
                >
                  {fileType === 'Excel' ? (
                    <FormControl style={{ flexBasis: '20%' }} variant="outlined">
                      <InputLabel size="small" htmlFor="outlined-age-native-simple">
                        Sheet Name
                      </InputLabel>
                      <Select
                        native
                        label="Sheet Name"
                        name="sheetname"
                        size="small"
                        onChange={HandleReg}
                        defaultValue=""
                      >
                        <option aria-label="None" value="" disabled />
                        {sheetNames.length > 0
                          ? sheetNames.map((cap: string, index: number) => (
                              <option key={`capture-type-${index}`} value={cap}>
                                &nbsp;&nbsp;{cap}
                              </option>
                            ))
                          : ''}
                      </Select>
                    </FormControl>
                  ) : (
                    <Box style={{ flexBasis: '20%', height: '2.5rem' }}>{}</Box>
                  )}
                  {(fileType === 'CSV' || fileType === 'Excel') && (
                    <FormControl variant="outlined" style={{ flexBasis: '20%', height: '2.5rem' }}>
                      <InputLabel size="small" htmlFor="outlined-age-native-simple">
                        Target Column
                      </InputLabel>
                      <Select
                        native
                        label="Column  Name"
                        name="columnname"
                        size="small"
                        onChange={HandleReg}
                        defaultValue=""
                      >
                        <option aria-label="None" value="" disabled />
                        {columnName.length > 0
                          ? columnName.map((cap: string, index: number) => (
                              <option key={`capture-type-${index}`} value={cap}>
                                &nbsp;&nbsp;{cap}
                              </option>
                            ))
                          : ''}
                      </Select>
                    </FormControl>
                  )}
                  <Box style={{ flexBasis: '20%' }}>
                    <TextField
                      style={{ width: '100%', height: '100%' }}
                      id="outlined-basic"
                      label="Enter Activity Name"
                      variant="outlined"
                      name="captureName"
                      onChange={inputHandler}
                    />
                  </Box>
                  {fileType === 'Excel' && (
                    <Box style={{ flexBasis: '20%', height: '2.5rem' }}>
                      <Checkbox
                        defaultChecked={false}
                        onChange={handleCheckboxBool}
                        name="excelCheckbox"
                        color="primary"
                        value="yes"
                      />
                      Generate Compliance Matrix
                    </Box>
                  )}
                  {fileType === 'CSV' && (
                    <Box style={{ flexBasis: '20%', height: '2.5rem' }}>
                      <Checkbox
                        defaultChecked={false}
                        onChange={handleCheckboxBool}
                        name="csvCheckbox"
                        color="primary"
                        value="yes"
                      />
                      Generate Compliance Matrix
                    </Box>
                  )}
                  {fileType === 'PDF' && (
                    <Box style={{ flexBasis: '20%', height: '2.5rem' }}>
                      <Checkbox
                        defaultChecked={false}
                        onChange={handleCheckboxBool}
                        name="pdfCheckbox"
                        color="primary"
                        value="yes"
                      />
                      Generate Compliance Matrix
                    </Box>
                  )}
                  <Box
                    style={{
                      flexBasis: '20%',
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'flex-end',
                      gap: '20px',
                    }}
                  >
                    <Button
                      type="button"
                      disabled={selectedSheetName === '' || selectedColumnName === ''}
                      variant="contained"
                      color="primary"
                      style={{ flexBasis: '70%' }}
                      startIcon={
                        loading && <CircularProgress style={{ color: 'white' }} size={20} />
                      }
                      onClick={() => {
                        if (fileType === 'Excel') {
                          getMultiText();
                        } else if (fileType === 'CSV') {
                          getMultiTextCSV();
                        } else if (fileType === 'PDF') {
                          docToDatasetPdfFile();
                        }
                      }}
                    >
                      Analyse
                    </Button>
                    <SimilarityDocFilter />
                    {/* <IconButton
                      style={{
                        backgroundColor: '#EEF1F9',
                        boxShadow: '0px 4px 8px #00000029',
                        height: '100%',
                        flexBasis: '20%',
                      }}
                      variant="contained"
                      onClick={handleClick}
                    >
                      <FilterAltOutlinedIcon size="small" />
                    </IconButton> */}
                    {/* <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      PaperProps={{
                        style: {
                          backgroundColor: '#EEF1F9',
                          marginTop: '10px',
                          boxShadow: 'none',
                          overflowX: 'visible',
                          overflowY: 'visible',
                        },
                      }}
                    >
                      <Box
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: '10px',
                          padding: '0.8rem',
                          maxHeight: '8rem',
                          width: '60rem',
                        }}
                      >
                        {multiFilterDisplay && (
                          <>
                            <Box
                              style={{
                                flexBasis: '20%',
                              }}
                            >
                              <DropSelectMui
                                options={simi_AlstomEntities ?? []}
                                value={dropDown1?.map((item) => item?.value) ?? []}
                                onChange={onChangeProductLineValue}
                                labelledBy="Product Line"
                              />
                            </Box>
                            <Box
                              style={{
                                flexBasis: '20%',
                              }}
                            >
                              <DropSelectMui
                                options={simi_FilterPlatforms ?? []}
                                labelledBy="Platform"
                                value={dropDown2?.map((item) => item?.value) ?? []}
                                onChange={onChangePlatformValue}
                              />
                            </Box>

                            <Box
                              style={{
                                flexBasis: '20%',
                              }}
                            >
                              <DropSelectMui
                                options={simi_FilterSolution ?? []}
                                value={dropDown5?.map((item) => item?.value) ?? []}
                                onChange={onChangeSolution}
                                labelledBy="Solution"
                              />
                            </Box>

                            <Box
                              style={{
                                flexBasis: '20%',
                              }}
                            >
                              <DropSelectMui
                                options={simi_FilterProduct ?? []}
                                labelledBy="Product"
                                value={dropDown6?.map((item) => item?.value) ?? []}
                                onChange={onChangeProduct}
                              />
                            </Box>

                            <Box
                              style={{
                                flexBasis: '20%',
                              }}
                            >
                              <DropSelectMui
                                options={simi_FilterProjects ?? []}
                                labelledBy="Project"
                                value={dropDown3?.map((item) => item?.value) ?? []}
                                onChange={onChangeProjectValue}
                              />
                            </Box>
                            <Box
                              style={{
                                flexBasis: '20%',
                              }}
                            >
                              <DropSelectMui
                                options={simi_FilterDocumentProfiles ?? []}
                                labelledBy="Document Profile"
                                value={dropDown4?.map((item) => item?.value) ?? []}
                                onChange={onChangeDocumentProfile}
                              />
                            </Box>
                            <Box
                              style={{
                                flexBasis: '20%',
                              }}
                            >
                              <Button
                                style={{ width: '100%', height: '100%' }}
                                type="button"
                                variant="contained"
                                color="primary"
                                onClick={clearFilter}
                                startIcon={<FilterAltOffIcon />}
                              >
                                Clear Filter
                              </Button>
                            </Box>
                          </>
                        )}
                      </Box>
                    </Popover> */}
                  </Box>
                </Box>
              )}
              <Grid item xs={12} alignItems="center" justify="center">
                {errorMessage !== '' && (
                  <Typography style={{ color: '#DC3223' }}>Error : {errorMessage}</Typography>
                )}
              </Grid>
            </div>
          </Box>
        </div>
      </div>
      {loadingSecond ? (
        <div style={{ width: '100%', textAlign: 'center' }}>
          <Loading />
        </div>
      ) : (
        <></>
      )}
      {displayGrid && (
        <div>
          <br />
          {/* {console.log('payloadHere', payloadHere)} */}
          <span style={{ color: 'black' }}>
            <b>Document Name: </b>{' '}
            {payloadHere?.resources[0]?.FileName
              ? payloadHere?.resources[0]?.FileName
              : payloadHere?.resources[0]?.output?.FileName}
            &nbsp;&nbsp;&nbsp;&nbsp;
            <b>Selected Column: </b>{' '}
            {payloadHere?.resources[0]?.selectedColumn
              ? payloadHere?.resources[0]?.selectedColumn
              : 'Text'}
          </span>
          <br />

          <Grid>
            <div style={{ marginTop: 5, backgroundColor: '#EEF1F9', border: '1px solid #002A45' }}>
              <ExpressGrid rows={rows} columns={columnsAre}>
                <FilteringState defaultFilters={[{ columnName: selectedColumnName, value: '' }]} />
                <IntegratedFiltering />
                <DataTypeProvider
                  for={[
                    payloadHere?.resources[0]?.selectedColumn
                      ? payloadHere?.resources[0]?.selectedColumn
                      : 'Text',
                  ]}
                  formatterComponent={
                    // ({ value }) => console.log('value', value)
                    // <span title="Hi">{value}</span>;
                    renderChapter
                  }
                />
                <DataTypeProvider for={['Most Similar Text']} formatterComponent={renderSimilar} />
                <SortingState />
                <IntegratedSorting />
                <PagingState
                  currentPage={currentPage1}
                  onCurrentPageChange={setCurrentPage1}
                  pageSize={pageSize1}
                  onPageSizeChange={setPageSize1}
                />
                <IntegratedPaging />
                <Table
                  // tableComponent={TableComponent}
                  tableComponent={MultiAllocTableComp}
                  containerComponent={tableAllocatorComponent}
                  headComponent={tableHeaderComponent}
                  columnExtensions={columnsAre.map((c) => ({
                    columnName: c.name,
                    wordWrapEnabled: toggleHeight,
                  }))}
                />

                <TableColumnResizing
                  onColumnWidthsChange={setColumnWidths}
                  columnWidths={columnWidths}
                  resizingMode="nextColumn"
                />
                <DataTypeProvider for={[targetCol]} formatterComponent={renderHighlighter} />
                {/* <DataTypeProvider
                  for={['Most Similar Text']}
                  formatterComponent={renderHighlighter2}
                /> */}
                <TableFilterRow rowComponent={rowComponent} rowHeight={32} />
                <TableHeaderRow showSortingControls />
                <TableColumnVisibility
                  hiddenColumnNames={hiddenColumnNames}
                  onHiddenColumnNamesChange={setHiddenColumnNames}
                />
                {/* {console.log('hiddenhhh', columnsAre)}
                {console.log('hiddenColumnNames', hiddenColumnNames)}
                {console.log(
                  'hiddenhhh22',
                  columnsAre.filter((item) => !hiddenColumnNames.includes(item.name))
                )} */}
                <Toolbar rootComponent={toolbarComponent} />
                <ColumnChooser />
                <ToolBarPlugin name="Download" onClick={startExport} />
                <ToolBarPlugin
                  name="Height"
                  title="Wrap text"
                  onClick={() => setToggleHeight(!toggleHeight)}
                />
                {/* <PagingPanel pageSizes={pageSizes} /> */}
                <PagingPanel
                  pageSizes={pageSizes}
                  containerComponent={(props) => (
                    <>
                      <PagingPanel.Container {...props} className={classes.pagingPanelContainer} />
                    </>
                  )}
                />
              </ExpressGrid>
              <GridExporter
                ref={exporterRef}
                rows={rows}
                columns={columnsAre.filter((item) => !hiddenColumnNames.includes(item.name))}
                onSave={onSave}
              />
              <NavigationGotoNew
                setPageSize={setPageSize1}
                fileLength={rows.length}
                pageSize={pageSize1}
                dis={dis1}
                setTemPage={setTemPage1}
                handlePageNum={handlePageNum1}
                currentPage={currentPage1}
                setCurrentPage={setCurrentPage1}
              />
            </div>
          </Grid>
          <br />
          {
            // eslint-disable-next-line eqeqeq
            graphOP != null && (
              <Grid container>
                <Grid lg={6} sm={6} md={6}>
                  <PieChart width={500} height={400}>
                    <Pie
                      data={graphOP}
                      cy="center"
                      labelLine={false}
                      label={renderCustomizedLabel}
                      outerRadius={140}
                      fill="#8884d8"
                      dataKey="value"
                    >
                      {graphOP.map((entry: any, index: any) => (
                        <Cell key={`cell-${index}`} fill={DOCTODATASET_COLORS[entry.name]} />
                      ))}
                    </Pie>
                    <Tooltip />
                  </PieChart>
                  <div style={{ textAlign: 'center' }}>
                    {graphOP.map((d: any, index: any) => (
                      <Chip
                        // label={`${DOCTODATASET_LABELS[d.name]} (${d.value})`}
                        label={`${d.name} (${d.value})`}
                        color="primary"
                        style={{
                          backgroundColor: DOCTODATASET_COLORS[d.name],
                          marginRight: '3px',
                          marginTop: '5px',
                        }}
                        key={`chip-${index}`}
                      />
                    ))}
                  </div>
                </Grid>
                <Grid xs={6} style={{ marginTop: 50 }}>
                  <BarChart
                    width={graphOP.length * 120}
                    height={400}
                    data={graphOP
                      .sort((a: any, b: any) => {
                        return a.value - b.value;
                      })
                      .reverse()}
                    layout="horizontal"
                  >
                    <XAxis dataKey="name" fontSize={10} />
                    <YAxis />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip />
                    {/* <Legend /> */}
                    <Bar dataKey="value" fill="#DC3223" barSize={20}>
                      {graphOP.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={DOCTODATASET_COLORS[entry.name]} />
                      ))}
                    </Bar>
                  </BarChart>
                </Grid>
              </Grid>
            )
          }
        </div>
      )}
    </>
  );
}

export default SimilarityAnalysisNew;
