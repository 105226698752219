/* eslint-disable react/destructuring-assignment */
import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import SettingsApplicationsOutlinedIcon from '@mui/icons-material/SettingsApplicationsOutlined';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import {
  ColumnChooser,
  Grid as ExpressGrid,
  PagingPanel,
  Table,
  TableColumnResizing,
  TableColumnVisibility,
  TableFilterRow,
  TableHeaderRow,
  TableSelection,
  Toolbar,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  Paper,
  Select,
  TextField,
  Typography,
  // Tooltip,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { styled } from '@mui/material/styles';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import MultiSelect from 'react-multi-select-component';
import { useHistory, useParams } from 'react-router-dom';
import { Workload3Create } from 'WorkloadType';
import { fileDetails } from '../../../../../api/module1';
import { workloadCreate, workloadGetById, workloadRunPost } from '../../../../../api/workloads';
import ExcelDoc from '../../../../../assets/icons8-excel-48.png';
import PdfDoc from '../../../../../assets/pdf_ins.png';
import PptDoc from '../../../../../assets/icons8-powerpoint-48.png';
import DocxDoc from '../../../../../assets/icons8-microsoft-word-2019-48.png';
import CsvDoc from '../../../../../assets/csv_icons.png';
import docIcon from '../../../../../assets/docx_ico.png';
import pdfIcon from '../../../../../assets/pdf_ico.png';
import scopeIcon from '../../../../../assets/scopeSet.png';
import xlsIcon from '../../../../../assets/xlsx_ico.png';
import ToolBarPlugin from '../../../../../components/Datagrid/ToolBarPlugin';
import TStyles, {
  rowComponent,
  tableAllocatorComponent,
  tableHeaderComponent,
  toolbarComponent,
  tUseStyles,
} from '../../../../../components/Datagrid/TStyles';
import { WorkloadBase } from '../../../../../react-app-env';
import formatBytes from '../../../../../tools/formatBytes';
import Module1Context from '../../../Module2Context';
import NERParamTarget from '../../../../../components/NERParamTarget';
import { getCaptureName } from '../../../../../api/breakdowns';
import NavigationGoto from '../../../../../components/Core/NavigationGoto';
import Loading from '../../../../../components/Core/Loading';
import NavigationGotoNew from '../../../../../components/Core/NavigationGotoNew';
import MultiAllocTableComp from '../../../../../components/Datagrid/MultiAllocTableComp';

// table header
const TableComponentBase = ({ classes, ...restProps }: any) => (
  <Table.Table {...restProps} className={classes.tableStriped} />
);

const TableComponent = withStyles(TStyles, { name: 'TableComponent' })(TableComponentBase);

const columns: any = [
  { name: 'filename', title: 'File Name' },
  { name: 'size', title: 'Size' },
  { name: 'folderName', title: 'Folder' },
  { name: 'date', title: 'Date upload' },
];

type PayloadText2Dataset = {
  Entity: string;
  Entity_type: string;
};
type PayloadText2Dataset1 = {
  Entity: string;
  Entity_type: string;
};
type Props = {
  id?: number;
  files?: any;
  setFiles?: any;
};
const useStyles = makeStyles((theme) => ({
  customScrollbar: {
    '&::-webkit-scrollbar': {
      width: '8px', // Set the width of the scrollbar
    },
    // '&::-webkit-scrollbar-thumb': {
    //   background: '#888', // Color of the thumb
    //   borderRadius: '5px', // Rounded corners for the thumb
    // },
    // '&::-webkit-scrollbar-thumb:hover': {
    //   background: '#555', // Color of the thumb on hover
    // },
  },
  cardStyle: {
    paddingBottom: 0,
    '& .MuiCardContent-root:last-child': {
      paddingBottom: 5,
    },
  },
  pagingPanelContainer: {
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    // marginTop: '10px',
    // width: '50% !important',
    padding: '0px !important',
    marginRight: '244px !important',
    '& .Pager-pager': {
      padding: 0,
    },
    // Apply other custom styles as needed
    '& .MuiIconButton-label': {
      color: '#001F3C',
      padding: 5,
      backgroundColor: '#FFFFFF',
      borderRadius: '50%',
      boxShadow: '#00000029',
    },
    // '& .MuiButton-label': {
    //   color: '#001F3C',
    //   padding: 2,
    //   backgroundColor: '#FFFFFF',
    //   borderRadius: '50%',
    //   boxShadow: '#00000029',
    // },
    '& .MuiButton-root': {
      '&.MuiButton-textPrimary .Pagination-activeButton': {
        backgroundColor: 'red !important',
        color: 'white !important',
        // Add other custom styles as needed
      },
      color: '#001F3C',
      padding: '0px 5px',
      backgroundColor: 'white',
      // borderRadius: '50%',
      boxShadow: '#00000029',
      // gap: 10,
      margin: 9,
      // '&.Pagination-activeButton': {
      //   background: 'red !important',
      // },
      // '& .Pagination-button': {
      //   '& .Pagination-activeButton': {
      //     background: 'red !important',
      //   },
      // },
    },
  },
}));
const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    // maxWidth: 1500,
    backgroundColor: '#002A45',
    border: '1px solid black',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    padding: 1,
    transition: 'opacity 1s',
    '& .MuiTooltip-arrow': {
      color: '#eef1f9',
      '&:before': {
        backgroundColor: '#002A45',
        boxShadow: 'none',
        borderBottom: `1px solid black`,
        borderRight: `1px solid black`,
        fontSize: 50,
      },
    },
  },
});

const Highlighter = ({ children, highlight }) => {
  if (!highlight) return children;
  const regexp = new RegExp(highlight, 'gi');
  const matches = children.match(regexp);
  const parts = children.split(new RegExp(`${highlight.replace()}`, 'gi'));

  for (let i = 0; i < parts.length; i += 1) {
    if (i !== parts.length - 1) {
      let match = matches[i];
      // While the next part is an empty string, merge the corresponding match with the current
      // match into a single <span/> to avoid consequent spans with nothing between them.
      while (parts[i + 1] === '') {
        match += matches[(i += 1)];
      }

      parts[i] = (
        <React.Fragment key={i}>
          {parts[i]}
          <span className="highlighted">{match}</span>
        </React.Fragment>
      );
    }
  }
  return <div className="highlighter">{parts}</div>;
};

function ExtractTopics({ id: workloadId, files, setFiles }: Props) {
  const history = useHistory();
  const tClasses = useStyles();
  const { projectId } = useParams() as any;
  const [toggleHeight, setToggleHeight] = useState(false);
  const [hiddenColumnNames, setHiddenColumnNames] = useState([]);
  const [pageSizes] = useState([5, 10, 20, 50]);
  const exporterRef = useRef(null);
  const [captureNames, setCaptureNames] = useState<any[]>([]);
  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);
  const onSave = (workbook: any) => {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'data.xlsx');
    });
  };
  const { project } = useContext(Module1Context);
  // const [files, setFiles] = useState<any[]>([]);
  const [selection, setSelection] = useState([]);

  // to control the loader and display data full Grid
  const [loading, setLoading] = useState(false);
  const [display, setDisplay] = useState(false);

  const [loadingResult, setLoadingResult] = useState(false);

  const { setSnack } = useContext(Module1Context);
  const [messageToDisplay, setMessageToDisplay] = useState<any>();

  const [dataoutput, setDataoutput] = useState<any>([]);
  const [columnWidth, setColumnWidth] = useState<any>([]);

  const [filterEntities, setFilterEntities] = useState<any[]>([]);
  const [selectedEntities, setSelectedEntities] = useState<any>([]);

  const [filterEntitiesType, setFilterEntitiesType] = useState<any[]>([]);
  const [selectedEntitiesType, setSelectedEntitiesType] = useState([]);

  // required when we dont have fixed named columns
  const [rows, setRows] = useState<any>([]);
  const [columnsAre, setColumnsAre] = useState<any>([]);
  const [columnNamesAre, setColumnNamesAre] = useState<any>([]);
  const [newLine, setNewLine] = useState<any>('pre-line');
  const [filter, setFilter] = useState<any>([]);
  const [filterEntity, setFilterEntity] = useState<any>([]);
  const [payload1, setPayload1] = useState<any>();
  const [resourcesAre, setResourcesAre] = useState<any>();
  const [inputValue, setInputValue] = useState('');
  const [tempPage, setTemPage] = useState(0);
  const [dis, setDis] = useState(true);
  // const [pageSizes] = useState([5, 10, 20, 50]);
  const [pageSize, setPageSize] = useState(5);
  const [pageSize2, setPageSize2] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPage2, setCurrentPage2] = useState(0);
  const [col, setCol] = useState('');
  const inputHandler = (event: any) => setInputValue(event.target.value);
  const [columnWidths, setColumnWidths] = useState([] as any);

  const handleSetRowsColumns = async (myRows: {}[]) => {
    const araryCol = Object.keys(myRows[0]);
    const myCols: any = [];
    araryCol.map((key) => {
      // const keyTemp = key.replace(' ', '_');
      if (key !== 'i18n') {
        myCols.push({
          name: key,
          title: key.toUpperCase(),
        });
      }
      return key;
    });
    const resizeColumns = myCols.map((c, i) => {
      // console.log(i);
      if (i === 0) {
        return {
          columnName: c.name,
          width: Math.ceil(400 / myCols.length),
        };
      }
      if (i === 1) {
        return {
          columnName: c.name,
          width: Math.ceil(400 / myCols.length),
        };
      }
      return {
        columnName: c.name,
        width: Math.ceil(2300 / myCols.length),
      };
    });
    setColumnWidths(resizeColumns);
    setColumnsAre(myCols);
    setCol(myCols[2].name);
    const tempArr: any = [];
    // eslint-disable-next-line array-callback-return
    myCols.map((c: any) => {
      tempArr.push(c.name);
    });
    setColumnNamesAre(tempArr);
    setRows(myRows);
  };

  useEffect(() => {
    getCaptureName(projectId).then((resp) => {
      if (resp) {
        setCaptureNames(resp);
      }
    });
  }, []);

  const handlePageNum = () => {
    const rel = tempPage - 1;
    setCurrentPage(rel);
    setCurrentPage2(rel);
  };

  useEffect(() => {
    if (pageSize > files.length) {
      if (dis) {
        setDis((prev) => {
          return !prev;
        });
      }
    } else if (pageSize < files.length) {
      setDis((prev) => {
        return !prev;
      });
    }
  }, [pageSize]);
  useEffect(() => {
    if (pageSize2 > rows.length) {
      if (dis) {
        setDis((prev) => {
          return !prev;
        });
      }
    } else if (pageSize2 < files.length) {
      setDis((prev) => {
        return !prev;
      });
    }
  }, [pageSize2]);

  /**
   * Get all files from project
   */
  useEffect(() => {
    const temp = files.map((data) => {
      return {
        ...data,
        isNew: false,
      };
    });
    setFiles(temp);
  }, []);

  /**
   * if projectId && workloadId present -> render results
   * wait for allfiles are loaded
   */
  useEffect(() => {
    if (files.length === 0 || projectId === undefined || workloadId === undefined) {
      return;
    }
    setLoading(true);
    // setLoadingResult(true);
    workloadGetById(workloadId)
      .then((workload: WorkloadBase) => {
        setResourcesAre(workload?.resources);
        setMessageToDisplay(workload?.status?.errors[0]?.msg);
        // const { resources, results_data } = workload;
        setDisplay(true);
        setLoading(false);
        // setLoadingResult(false);
        // console.log('workload.results_data[0].data', workload);
        setPayload1(workload.results_data[0].data);
        const arr: any = [];
        const arr1: any = [];
        JSON.parse(workload.results_data[0].data.output).filter((row: PayloadText2Dataset) =>
          arr.push(row.Entity_type)
        );
        // Entity multiFilter
        const ent: any = [];
        const entType: any = [];
        JSON.parse(workload.results_data[0].data.output).filter((row: PayloadText2Dataset) =>
          ent.push({
            label: row.Entity,
            value: row.Entity,
          })
        );

        setFilterEntities(ent.filter((v, i, a) => a.indexOf(v) === i));
        // Entity multiFilter End

        JSON.parse(workload.results_data[0].data.output).filter((row: PayloadText2Dataset1) =>
          arr1.push(row.Entity)
        );
        setFilter(Array.from(new Set(arr)));
        const r = Array.from(new Set(arr));
        const enttype: any = [];

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < r.length; i++) {
          enttype.push({
            label: r[i],
            value: r[i],
          });
        }
        setFilterEntitiesType(enttype);
        setFilterEntity(Array.from(new Set(arr1)));
        handleSetRowsColumns(JSON.parse(workload.results_data[0].data.output));
      })
      .catch(() => {
        setLoading(false);
        setSnack(messageToDisplay, 'error');
      });
  }, [files, projectId, setSnack, workloadId]);

  const getFileDetails = (inputFiles: any[]) => {
    setLoading(true);
    setDisplay(false);
    const falseArray: any = [];
    const arrayIs: any = [{ columnName: 'Entity', width: 300 }];
    // console.log('passedArray: ', selectedRawFiles);

    for (const myFile of inputFiles) {
      falseArray.push(
        fileDetails(files[myFile].filename, files[myFile].filename, files[myFile].container)
      );
      arrayIs.push({
        columnName: files[myFile].filename.split('.').slice(0, -1).join('.'),
        width: 300,
      });
    }
    // eslint-disable-next-line no-plusplus
    // for (let index = 0; index < f.length; index++) {
    //   falseArray.push(
    //     fileDetails(files[f[index]].filename, files[f[index]].filename, files[f[index]].container)
    //   );
    //   arrayIs.push({
    //     columnName: files[f[index]].filename.split('.').slice(0, -1).join('.'),
    //     width: 300,
    //   });
    // }
    setColumnWidth(arrayIs);
    Promise.all(falseArray)
      .then((responses) => {
        // Get a JSON object from each of the responses
        return Promise.all(
          responses.map((response) => {
            return response;
          })
        );
      })
      .then((data) => {
        const ar: any = [];
        // console.log(data);
        data.map((c: any) => ar.push(c.output));
        setDataoutput(ar);
        // console.log(ar);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });

    // promise all end
  };

  const handleSelectionChange = (selectedKeys: any) => {
    setFilter([]);
    setFilterEntity([]);
    const ArrayFiles = selectedKeys;
    setSelection(selectedKeys);
    // // eslint-disable-next-line no-plusplus
    // for (const selectedFile of selectedKeys) {
    //   ArrayFiles.push(selectedFile);
    // }
    // for (let index = 0; index < selectedKeys.length; index++) {
    //   ArrayFiles.push(selectedKeys[index]);
    // }
    // console.log('ArrayFiles: ', ArrayFiles);
    if (ArrayFiles.length > 5) {
      setSnack('more than 5 files not allowed!', 'error');
    } else {
      getFileDetails(ArrayFiles);
    }
  };
  const [targetColumnsAre, setTargetColumnsAre] = useState<any>([]);

  function HandleReg(e: any, indexIs: any) {
    // console.log('dataoutput is: ', dataoutput);
    if (e.target.name === 'sheetname') {
      // console.log(
      //   'e.target.name:',
      //   e.target.name,
      //   'e.target.value:',
      //   e.target.value,
      //   'index:',
      //   indexIs
      // );
      const state = [...targetColumnsAre];
      state[indexIs] =
        dataoutput[indexIs].Sheets[dataoutput[indexIs].Sheet_names.indexOf(e.target.value)];
      setTargetColumnsAre(state);
      Object.assign(dataoutput[indexIs], { SheetName: e.target.value });
      // console.log('F:', dataoutput);
    }
    if (e.target.name === 'targetColumn') {
      Object.assign(dataoutput[indexIs], { TargetColumn: e.target.value });
    }
    if (e.target.name === 'columnname') {
      Object.assign(dataoutput[indexIs], { TargetColumn: e.target.value });
    }
  }

  function handleAnalyze(): any {
    if (inputValue.trim().length === 0) {
      setSnack('Enter activity name', 'error');
      return;
    }
    if (captureNames.includes(inputValue.trim())) {
      setSnack('That capture name is taken.Please try another', 'error');
      return;
    }
    setLoadingResult(true);
    setDisplay(false);
    const containerIs = dataoutput[0].Container;
    let tempdataoutput: any = [];
    tempdataoutput = JSON.parse(JSON.stringify(dataoutput));
    // eslint-disable-next-line no-plusplus
    for (const tempdata of tempdataoutput) {
      delete tempdata?.FileName;
      delete tempdata?.FilePath;
      delete tempdata?.FileType;
      delete tempdata?.Sheet_names;
      delete tempdata?.Sheets;
      delete tempdata?.Text;
      delete tempdata?.Process;
      delete tempdata?.Error;
      delete tempdata?.Language;
      delete tempdata?.NumberOfPages;
      delete tempdata?.EstimatedTimeToCapture;
      delete tempdata?.StartPage;
      delete tempdata?.EndPage;
      delete tempdata?.HeaderMargin;
      delete tempdata?.FooterMargin;
      delete tempdata?.NameOfCapture;
      delete tempdata?.TypesOfCapture;
      delete tempdata?.NumberOfRecords;
      delete tempdata?.NumberOfColumns;
      delete tempdata?.ColumnNames;
    }
    const totalcount: any = [];
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < tempdataoutput.length; index++) {
      const fileExt = tempdataoutput[index].Blob.split('.').pop().toLowerCase();
      if (fileExt === 'pdf' || fileExt === 'doc' || fileExt === 'docx') {
        // eslint-disable-next-line dot-notation
        tempdataoutput[index]['TargetColumn'] = 'forced';
      }
      Object.keys(tempdataoutput[index]).forEach((k: any) => {
        if (k === 'TargetColumn') {
          totalcount.push('found');
        }
      });
    }
    // console.log(tempdataoutput);
    if (tempdataoutput.length === totalcount.length) {
      const body = {
        Container: containerIs,
        BlobFiles: tempdataoutput,
        nameOfCapture: inputValue,
      };
      workloadCreate({
        project_id: projectId,
        app: 'module2',
        action: 'iner',
        resources: body,
      })
        .then((workloadNew: Workload3Create) => {
          workloadRunPost(Number(workloadNew.id), body, 'm2-onto-param-analysis')
            .then(() => {
              window.history.pushState('', '', `/projects/${projectId}`);
              history.push(`/projects/${projectId}`);
            })
            .catch(() => {
              setSnack('Error while analyzing the file. Please check the input files.', 'error');
            })
            .finally(() => {});
        })
        .catch(() => {
          setSnack('Error while analyzing the file. Please check the input files.', 'error');
        })
        .finally(() => {});
    } else {
      setLoadingResult(false);
      setDisplay(false);
      setSnack('Please select dropdown option', 'error');
    }
  }

  function filterActivation(valueIs: any) {
    if (valueIs === 'default') {
      handleSetRowsColumns(JSON.parse(payload1.output));
    } else {
      handleSetRowsColumns(
        JSON.parse(payload1.output).filter(
          (row: PayloadText2Dataset) => row.Entity_type === valueIs
        )
      );
    }
  }

  function onChangeEntity(option: any) {
    // console.log(option);
    setSelectedEntities(option);
    setSelectedEntitiesType([]);
    const selectedArray = option.map((obj: any, i: any) => option[i].value);
    // console.log(selectedArray);

    if (selectedArray.length === 0) {
      handleSetRowsColumns(JSON.parse(payload1.output));
    } else {
      handleSetRowsColumns(
        JSON.parse(payload1.output).filter((row: PayloadText2Dataset) =>
          selectedArray.includes(row.Entity)
        )
      );
    }
  }
  function onChangeEntityType(option: any) {
    setSelectedEntitiesType(option);
    setSelectedEntities([]);
    const selectedArray = option.map((obj: any, i: any) => option[i].value);
    // console.log(selectedArray);

    if (selectedArray.length === 0) {
      handleSetRowsColumns(JSON.parse(payload1.output));
    } else {
      handleSetRowsColumns(
        JSON.parse(payload1.output).filter((row: PayloadText2Dataset) =>
          selectedArray.includes(row.Entity_type)
        )
      );
    }
  }

  function filterByEntity(valueIs: any) {
    if (valueIs === 'default') {
      handleSetRowsColumns(JSON.parse(payload1.output));
    } else {
      handleSetRowsColumns(
        JSON.parse(payload1.output).filter((row: PayloadText2Dataset) => row.Entity === valueIs)
      );
    }
  }

  const renderHighlighter = ({ row, value }) => {
    const { Entity } = row;

    return (
      <>
        <Highlighter highlight={Entity}>{value}</Highlighter>
      </>
    );
  };
  const tableHeaderComponents: React.FunctionComponent<Table.RowProps> = (props) => {
    return (
      <Table.TableHead
        {...props}
        style={{
          position: 'unset',
          top: 0,
          zIndex: 400,
        }}
      />
    );
  };

  const renderFileName = ({ row }: any) => {
    const ext = row.filename.split('.');
    const extensions = {
      xlsx: ExcelDoc,
      csv: CsvDoc,
      pdf: PdfDoc,
      pptx: PptDoc,
      docx: DocxDoc,
    };
    return (
      <div
        style={{
          position: 'relative',
          padding: '0.3rem',
          width: 'max-content',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: '0.2rem' }}>
          <img src={extensions[ext[ext.length - 1]]} height="30" width="30" alt="img" />
          <Typography>{row.filename}</Typography>
        </div>
        {row?.isNew && (
          <div
            style={{
              width: 'max-content',
              padding: '0.2rem',
              borderRadius: '10px',
              backgroundColor: '#19AA6E',
              color: 'white',
              position: 'absolute',
              top: '-12px',
              right: '-35px',
            }}
          >
            <Typography style={{ fontSize: '0.85em' }}>New !</Typography>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <Box style={{ backgroundColor: '#EEF1F9', border: '1px solid #002A45' }}>
        <ExpressGrid rows={files} columns={columns}>
          <DataTypeProvider for={['filename']} formatterComponent={renderFileName} />
          <SelectionState selection={selection} onSelectionChange={handleSelectionChange} />
          <FilteringState />
          <IntegratedFiltering />
          <SortingState />
          <IntegratedSorting />
          <PagingState
            currentPage={currentPage}
            onCurrentPageChange={setCurrentPage}
            pageSize={pageSize}
            onPageSizeChange={setPageSize}
          />
          <IntegratedPaging />
          <VirtualTable />
          <Table
            tableComponent={MultiAllocTableComp}
            containerComponent={tableAllocatorComponent}
            headComponent={tableHeaderComponent}
          />
          <TableColumnResizing
            defaultColumnWidths={[
              { columnName: 'filename', width: '200px' },
              { columnName: 'size', width: '200px' },
              { columnName: 'folderName', width: '200px' },
              { columnName: 'date', width: '200px' },
            ]}
          />
          <TableHeaderRow showSortingControls />
          <TableFilterRow rowComponent={rowComponent} />
          {/* <PagingPanel pageSizes={pageSizes} /> */}
          <PagingPanel
            pageSizes={pageSizes}
            containerComponent={(props) => (
              <>
                <PagingPanel.Container {...props} className={tClasses.pagingPanelContainer} />
              </>
            )}
          />
          <TableSelection />
        </ExpressGrid>
        <NavigationGotoNew
          setPageSize={setPageSize}
          fileLength={files.length}
          pageSize={pageSize}
          dis={dis}
          setTemPage={setTemPage}
          handlePageNum={handlePageNum}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </Box>
      {selection.length > 0 && (
        <span style={{ color: 'black' }}>
          <h3>Total files selected: {selection.length}</h3>{' '}
        </span>
      )}
      {dataoutput.length > 0 && (
        <Paper>
          <>
            <div
              style={{
                border: 'solid black',
                backgroundColor: 'rgb(255, 255, 255)',
                borderWidth: '0.1px',
              }}
            >
              <Grid container>
                <Grid container style={{ marginLeft: 10 }}>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div>
                      <SettingsApplicationsOutlinedIcon style={{ width: 40, height: 40 }} />
                    </div>
                    <div>
                      <h2>Scope Settings</h2>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid spacing={0}>
                <Grid container>
                  {dataoutput.map((c, indexIs) => (
                    <Grid container spacing={1} style={{ padding: '10px' }} key={indexIs}>
                      <Grid item xs={3}>
                        <FormControl variant="outlined">
                          <span>
                            {c?.FileType === 'unsupported' ? (
                              <img
                                src={docIcon}
                                alt="isens"
                                style={{
                                  width: 20,
                                  display: 'inline',
                                  height: 25,
                                  // transform: 'translateY(8px)',
                                }}
                              />
                            ) : (
                              ''
                            )}
                            {c?.FileType === 'Excel' || c?.FileType === 'CSV' ? (
                              <img
                                src={xlsIcon}
                                alt="isens"
                                style={{
                                  width: 20,
                                  height: 25,
                                  display: 'inline',
                                  // transform: 'translateY(8px)',
                                }}
                              />
                            ) : (
                              ''
                            )}
                            {c?.FileType === 'PDF' ? (
                              <img
                                src={pdfIcon}
                                alt="isens"
                                style={{
                                  width: 20,
                                  height: 25,
                                  display: 'inline',
                                  // transform: 'translateY(8px)',
                                }}
                              />
                            ) : (
                              ''
                            )}
                            &nbsp;
                            <Tooltip title={c?.Blob} placement="top" arrow>
                              <b style={{ color: 'blue', wordWrap: 'break-word' }}>
                                {c?.Blob.substring(0, 25)}
                              </b>
                            </Tooltip>
                          </span>
                          {c?.FileType !== 'unsupported' ? (
                            <span>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <span style={{ color: 'red' }}>{c?.Error}</span>
                            </span>
                          ) : (
                            ''
                          )}
                        </FormControl>
                      </Grid>
                      {c?.FileType === 'Excel' ? (
                        <>
                          <Grid item xs={3}>
                            <FormControl variant="outlined" size="small">
                              <InputLabel htmlFor="outlined-age-native-simple">
                                Sheet Column
                              </InputLabel>
                              <Select
                                native
                                label="Sheet  Name"
                                // name={'sheetname'.concat(indexIs)}
                                name="sheetname"
                                style={{ width: 200 }}
                                onChange={(e) => HandleReg(e, indexIs)}
                                defaultValue=""
                              >
                                <option aria-label="None" value="" disabled />
                                {c?.Sheet_names !== null
                                  ? c?.Sheet_names.map((cap: string, index: number) => (
                                      <option key={`capture-type-${index}`} value={cap}>
                                        &nbsp;&nbsp;{cap}
                                      </option>
                                    ))
                                  : ''}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={3} style={{ marginLeft: '40px' }}>
                            <FormControl variant="outlined" size="small">
                              <InputLabel htmlFor="outlined-age-native-simple">
                                Target Column
                              </InputLabel>
                              <Select
                                native
                                label="Target  Column"
                                name="targetColumn"
                                style={{ width: 200 }}
                                onChange={(e) => HandleReg(e, indexIs)}
                                defaultValue=""
                              >
                                <option aria-label="None" value="" disabled />
                                <NERParamTarget
                                  key={`NERParamTarget-${indexIs}`}
                                  data={targetColumnsAre[indexIs]}
                                />
                              </Select>
                            </FormControl>
                          </Grid>
                        </>
                      ) : (
                        ''
                      )}
                      {c?.FileType === 'CSV' ? (
                        <>
                          <Grid item xs={3}>
                            <FormControl variant="outlined" size="small">
                              <InputLabel htmlFor="outlined-age-native-simple">
                                Column Name
                              </InputLabel>
                              <Select
                                native
                                label="Column Name"
                                name="columnname"
                                style={{ minWidth: 450 }}
                                onChange={(e) => HandleReg(e, indexIs)}
                                defaultValue=""
                              >
                                <option aria-label="None" value="" disabled />
                                {c?.ColumnNames !== null
                                  ? c?.ColumnNames.map((cap: string, index: number) => (
                                      <option key={`capture-type-${index}`} value={cap}>
                                        &nbsp;&nbsp;{cap}
                                      </option>
                                    ))
                                  : ''}
                              </Select>
                            </FormControl>
                          </Grid>
                        </>
                      ) : (
                        ''
                      )}
                    </Grid>
                  ))}
                  <div
                    style={{
                      borderTop: 'solid rgba(0, 0, 0, 0.87)',
                      width: '100%',
                      backgroundColor: 'rgb(238, 241, 249)',
                      borderWidth: '0.5px',
                    }}
                  >
                    <Grid item xs={12}>
                      <div style={{ float: 'right', marginLeft: 0 }}>
                        <TextField
                          id="outlined-basic"
                          size="small"
                          label="Enter activity name"
                          variant="outlined"
                          name="captureName"
                          onChange={inputHandler}
                          style={{
                            width: '170px',
                            height: '38px',
                            marginTop: 6,
                            marginRight: 10,
                            marginBottom: 6,
                            backgroundColor: 'white',
                          }}
                        />
                        &nbsp;&nbsp;
                        <Button
                          type="button"
                          disabled={selection.length < 1 || selection.length > 5}
                          variant="contained"
                          color="primary"
                          startIcon={
                            loadingResult && (
                              <CircularProgress style={{ color: 'white' }} size={20} />
                            )
                          }
                          onClick={handleAnalyze}
                          style={{
                            width: '150px',
                            height: '38px',
                            marginTop: 6,
                            marginRight: 10,
                            marginBottom: 6,
                          }}
                        >
                          Analyze
                        </Button>
                      </div>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </div>
          </>
        </Paper>
      )}
      {loading && <Loading />}
      {display && (
        <>
          <Paper style={{ color: '#002A45', border: '1px solid #002A45' }}>
            <Card className={tClasses.cardStyle}>
              <CardContent>
                <Grid container>
                  <Grid xs={6}>
                    <Grid container>
                      <Grid xs={1}>
                        <SettingsOutlinedIcon style={{ color: '#002A45' }} />
                      </Grid>
                      <Grid xs={11}>
                        <b style={{ fontSize: 16, marginLeft: -5 }}>Details</b>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Paper
              style={{
                borderTop: '1px solid #002A45',
                borderBottom: '1px solid #002A45',
                // borderWidth: '0.6px',
                backgroundColor: '#EEF1F9',
                borderRadius: 0,
              }}
            >
              <div style={{ marginLeft: '5px' }}>
                <h3>Resource details: {resourcesAre?.nameOfCapture}</h3>
                {resourcesAre?.BlobFiles?.map((data: any) => {
                  return (
                    <p>
                      {data?.Blob && (
                        <>
                          <b>File Name:</b>&nbsp;
                          <span>{data.Blob}&nbsp;&nbsp;</span>
                        </>
                      )}
                      {data?.SheetName && (
                        <>
                          <b>Sheet Name:</b>&nbsp;
                          <span>{data.SheetName}&nbsp;&nbsp;</span>
                        </>
                      )}
                      {data?.TargetColumn && (
                        <>
                          <b>Target Column:</b>&nbsp;
                          <span>{data.TargetColumn}&nbsp;&nbsp;</span>
                        </>
                      )}
                    </p>
                  );
                })}
              </div>
            </Paper>
            <br />
            <Grid container style={{ padding: 2 }}>
              {filter && (
                <Grid item xs={6}>
                  <Grid container spacing={0} alignItems="center">
                    <Grid item xs={3}>
                      <b>Select Entity Type: </b>
                    </Grid>
                    <Grid item xs={9}>
                      <MultiSelect
                        options={filterEntitiesType}
                        value={selectedEntitiesType}
                        onChange={onChangeEntityType}
                        labelledBy="EntitiesType"
                        // style={{ width: '50%' }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {filter && (
                <Grid item xs={6}>
                  <Grid container spacing={0} alignItems="center">
                    <Grid item xs={2}>
                      <b>&nbsp; Select Entity: </b>
                    </Grid>
                    <Grid item xs={10}>
                      <MultiSelect
                        // isOpen={true}
                        options={filterEntities}
                        value={selectedEntities}
                        onChange={onChangeEntity}
                        labelledBy="Entities"
                        // style={{ width: '50%' }}
                        displayValue="entities"
                        className="entities"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <br />
          </Paper>
          <div style={{ backgroundColor: '#EEF1F9', border: '1px solid #002A45' }}>
            <ExpressGrid rows={rows} columns={columnsAre}>
              <DataTypeProvider
                for={['Entity']}
                formatterComponent={({ value }) => (
                  <CustomWidthTooltip
                    // placement="bottom-start"
                    arrow
                    // style={{
                    //   whiteSpace: `${newLine}`,
                    //   fontWeight: 'bold',
                    //   color: '#002A45',
                    //   overflowY: newLine === 'pre-line' ? 'scroll' : 'hidden',
                    //   maxHeight: '15vh',
                    //   padding: 5,
                    // }}
                    // open={true}
                    title={
                      <>
                        <Card
                          style={{
                            background: 'while',
                            color: '#002A45',
                            fontWeight: 'bold',
                            margin: 0,
                            padding: 5,
                            // overflowY: 'scroll',
                            // maxHeight: '25vh',
                            // padding: 5,
                          }}
                          // className={tClasses.customScrollbar}
                        >
                          {value}
                        </Card>
                      </>
                    }
                  >
                    <span
                      title={value}
                      style={{ whiteSpace: `${newLine}`, fontWeight: 'bold', color: '#002A45' }}
                    >
                      {value}
                    </span>
                  </CustomWidthTooltip>
                )}
              />
              <DataTypeProvider
                for={['Entity_type']}
                formatterComponent={({ value }) => (
                  <CustomWidthTooltip
                    // placement="bottom-start"
                    arrow
                    // style={{
                    //   whiteSpace: `${newLine}`,
                    //   fontWeight: 'bold',
                    //   color: '#002A45',
                    //   overflowY: newLine === 'pre-line' ? 'scroll' : 'hidden',
                    //   maxHeight: '15vh',
                    //   padding: 5,
                    // }}
                    // open={true}
                    title={
                      <>
                        <Card
                          style={{
                            background: 'while',
                            color: '#002A45',
                            fontWeight: 'bold',
                            margin: 0,
                            padding: 5,
                            // overflowY: 'scroll',
                            // maxHeight: '25vh',
                            // padding: 5,
                          }}
                          // className={tClasses.customScrollbar}
                        >
                          {value}
                        </Card>
                      </>
                    }
                  >
                    <span
                      title={value}
                      style={{ whiteSpace: `${newLine}`, fontWeight: 'bold', color: '#002A45' }}
                    >
                      {value}
                    </span>
                  </CustomWidthTooltip>
                  // <span
                  //   title={value}
                  //   style={{ whiteSpace: `${newLine}`, fontWeight: 'bold', color: '#002A45' }}
                  // >
                  //   {value}
                  // </span>
                )}
              />
              <DataTypeProvider
                for={[
                  ...columnNamesAre.map((data, i) => {
                    if (i >= 2) {
                      return data;
                    }
                    return null;
                  }),
                ]}
                formatterComponent={({ value }) => (
                  <CustomWidthTooltip
                    // placement="bottom-start"
                    arrow
                    // style={{
                    //   whiteSpace: `${newLine}`,
                    //   fontWeight: 'bold',
                    //   color: '#002A45',
                    //   overflowY: newLine === 'pre-line' ? 'scroll' : 'hidden',
                    //   maxHeight: '15vh',
                    //   padding: 5,
                    // }}
                    // open={true}
                    title={
                      <>
                        <Card
                          style={{
                            background: 'while',
                            color: '#002A45',
                            fontWeight: 'bold',
                            margin: 0,
                            padding: 5,
                            overflowY: 'scroll',
                            maxHeight: '25vh',
                            // padding: 5,
                          }}
                          className={tClasses.customScrollbar}
                        >
                          {value.replace(/-{28,}/g, '')}
                        </Card>
                      </>
                    }
                  >
                    <Card
                      // title={value.replace(/-{28,}/g, '')}
                      style={{
                        whiteSpace: `${newLine}`,
                        fontWeight: 'bold',
                        color: '#002A45',
                        overflowY: newLine === 'pre-line' ? 'scroll' : 'hidden',
                        maxHeight: '15vh',
                        padding: 5,
                      }}
                      className={tClasses.customScrollbar}
                    >
                      {value.replace(/-{28,}/g, '')}
                    </Card>
                  </CustomWidthTooltip>
                  // <Card
                  //   title={value.replace(/-{28,}/g, '')}
                  //   style={{
                  //     whiteSpace: `${newLine}`,
                  //     fontWeight: 'bold',
                  //     color: '#002A45',
                  //     overflowY: newLine === 'pre-line' ? 'scroll' : 'hidden',
                  //     maxHeight: '15vh',
                  //     padding: 5,
                  //   }}
                  // >
                  //   {value.replace(/-{28,}/g, '')}
                  // </Card>
                )}
              />
              {/* <DataTypeProvider for={[col]} formatterComponent={renderHighlighter} /> */}
              {/* <DataTypeProvider for={['PRD_CCS']} formatterComponent={handlePRD} /> */}
              <SortingState />
              <IntegratedSorting />
              <PagingState
                currentPage={currentPage2}
                onCurrentPageChange={setCurrentPage2}
                pageSize={pageSize2}
                onPageSizeChange={setPageSize2}
              />
              <IntegratedPaging />
              <Table
                tableComponent={MultiAllocTableComp}
                containerComponent={tableAllocatorComponent}
                headComponent={tableHeaderComponents}
                // columnExtensions={[
                //   {
                //     columnName: columnNamesAre[0] || '',
                //     width: 50,
                //     wordWrapEnabled: toggleHeight,
                //   },
                //   {
                //     columnName: columnNamesAre[1] || '',
                //     width: 50,
                //     wordWrapEnabled: toggleHeight,
                //   },
                //   {
                //     columnName: columnNamesAre[2] || '',
                //     width: 300,
                //     wordWrapEnabled: toggleHeight,
                //   },
                // ]}
                columnExtensions={[
                  ...columnNamesAre.map((c, i) => {
                    return {
                      columnName: c.name,
                      width: 'auto',
                      wordWrapEnabled: toggleHeight,
                    };
                  }),
                ]}
              />
              {/* <TableColumnResizing
                defaultColumnWidths={[
                  {
                    columnName: columnNamesAre[0] || '',
                    width: '200px',
                  },
                  {
                    columnName: columnNamesAre[1] || '',
                    width: '400px',
                  },
                  {
                    columnName: columnNamesAre[2] || '',
                    width: '300px',
                  },
                ]}
              /> */}
              <TableColumnResizing
                onColumnWidthsChange={setColumnWidths}
                columnWidths={columnWidths}
                resizingMode="nextColumn"
              />
              <TableHeaderRow showSortingControls />
              <TableColumnVisibility
                hiddenColumnNames={hiddenColumnNames}
                onHiddenColumnNamesChange={setHiddenColumnNames}
              />
              <Toolbar rootComponent={toolbarComponent} />
              <ColumnChooser />
              <ToolBarPlugin name="Download" onClick={startExport} />
              {/* <ToolBarPlugin
                name="Height"
                title="Wrap Text"
                onClick={() => {
                  setToggleHeight(!toggleHeight);
                }}
              /> */}
              <ToolBarPlugin
                name="NewLine"
                onClick={() => {
                  return newLine !== 'pre-line' ? setNewLine('pre-line') : setNewLine('');
                }}
              />
              {/* <PagingPanel pageSizes={pageSizes} /> */}
              <PagingPanel
                pageSizes={pageSizes}
                containerComponent={(props) => (
                  <>
                    <PagingPanel.Container {...props} className={tClasses.pagingPanelContainer} />
                  </>
                )}
              />
            </ExpressGrid>
            <NavigationGotoNew
              setPageSize={setPageSize}
              fileLength={rows.length}
              pageSize={pageSize2}
              dis={dis}
              setTemPage={setTemPage}
              handlePageNum={handlePageNum}
              currentPage={currentPage2}
              setCurrentPage={setCurrentPage2}
            />
            {/* <NavigationGoto
              setPageSize={setPageSize}
              fileLength={rows.length}
              pageSize={pageSize2}
              dis={dis}
              setTemPage={setTemPage}
              handlePageNum={handlePageNum}
              currentPage={currentPage2}
              setCurrentPage={setCurrentPage2}
            /> */}
            <GridExporter ref={exporterRef} rows={rows} columns={columnsAre} onSave={onSave} />
          </div>
        </>
      )}
    </>
  );
}

export default ExtractTopics;
