import { Save } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import {
  DialogContentText,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled, ThemeProvider } from '@mui/material/styles';
import dayjs from 'dayjs';
import * as React from 'react';
import useAxiosAuth from '../../../API/axios';
import { IParamDetail } from '../../../interfaces/IParamDetail';
import { ITopic } from '../../../interfaces/ITopic';
import { apiEndpoints } from '../../../utils/apiEndpoints';
import { crossDomainsList } from '../../../utils/statusLifercycle';
import { theme } from '../../../utils/theme';
import MuiDatePicker from '../DatePicker/MuiDatePicker';
import FileAttachment from '../FileAttachment/FileAttachment';
import { topicTypeLevels, topicUrgencyLevels } from './urgencyLevel';
import '../../../index.css';

interface TopicCreationDialogProps {
  open: boolean;
  currentUserId: number;
  currentUserFullName: string;
  currentUserEmail: string;
  currentUserFunction: string;
  setCurrentUserFunction: React.Dispatch<React.SetStateAction<string>>;
  currentUserDepartment: string;
  setCurrentUserDepartment: React.Dispatch<React.SetStateAction<string>>;
  setOpen: (open: boolean) => void;
  topicStatuses: IParamDetail[];
  replaceOldTopicByUpdatedTopic: (updatedTopic: ITopic, isDelete: boolean, isAdd: boolean) => void;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const TopicCreationDialog: React.FC<TopicCreationDialogProps> = ({
  open,
  checkDialog,
  setOpen,
  currentUserId,
  currentUserFullName,
  currentUserEmail,
  currentUserFunction,
  setCurrentUserFunction,
  currentUserDepartment,
  setCurrentUserDepartment,
  replaceOldTopicByUpdatedTopic,
}) => {
  // const [topicStatusId, setTopicStatusId] = React.useState<number>(-1);
  const [urgencyLvl, setUrgencyLvl] = React.useState<number>(-1);
  const [typeOfTopic, setTypeOfTopic] = React.useState<'idea' | 'demand'>('idea');
  const [isValidationError, setIsValidationError] = React.useState(false);
  const [eventDate, setEventDate] = React.useState<Date | undefined>();
  const [formData, setFormData] = React.useState<any>({
    title: '',
    cross_domain: '',
    targetPopulation: '',
    dataAvailability: '',
    successCriteria: '',
    estimationSaving: '',
    estimationMonetaryGain: '',
    problemStatement: '',
    description: '',
  });

  const [fileBuffer, setFileBuffer] = React.useState<string | ArrayBuffer | null>(null);
  const [fileName, setFileName] = React.useState('');
  const [mimeType, setMimeType] = React.useState('');
  const { AxiosCall } = useAxiosAuth();

  React.useEffect(() => {
    if (checkDialog.draft) {
      const payload = {
        url: `${apiEndpoints.API_TOPIC_URL}/${checkDialog.id}`,
        method: 'GET',
        data: null,
        header: null,
      };
      AxiosCall(payload).then((res) => {
        console.log('Drafted res', res);
        setFormData({ ...res });
      });
    }
  }, [checkDialog]);

  React.useEffect(() => {
    setUrgencyLvl(-1);
    setIsValidationError(false);
    setFileBuffer(null);
    setFileName('');
    setMimeType('');
  }, [open]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <ThemeProvider theme={theme}>
      <BootstrapDialog
        aria-modal
        onClose={handleClose}
        open={open}
        maxWidth="md"
        scroll="paper"
        PaperProps={{
          component: 'form',
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const submitter = (event.nativeEvent as SubmitEvent).submitter as HTMLButtonElement;
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries((formData as any).entries());
            const formJsonKeys = Object.keys(formJson);
            const notRequiredFields = [
              'attachFile',
              'financialSponsor',
              'budget',
              'targetPopulation',
              'dataAvailability',
              'successCriteria',
              'estimationSaving',
              'estimationMonetaryGain',
            ];
            const requiredFields = formJsonKeys.filter((k) => !notRequiredFields.includes(k));
            if (requiredFields.filter((k) => !formJson[k]).length > 0) {
              setIsValidationError(true);
              return;
            }
            // if (topicStatusId === -1) {
            //     setValidationError(true);
            //     return;
            // }
            if (!eventDate || isNaN(eventDate.getTime())) {
              setIsValidationError(true);
              return;
            }
            formJson.eventDate = eventDate.toISOString();
            if (urgencyLvl === -1) {
              setIsValidationError(true);
              return;
            }
            setIsValidationError(false);
            // formJson.topicStatus = { id: topicStatusId };
            // Handle attachment
            formJson.attachFile = fileBuffer;
            formJson.filename = fileName;
            formJson.mimetype = mimeType;

            const tempPayload =
              submitter.name === 'save'
                ? { ...formJson, draftStatus: 'draft' }
                : { draftStatus: 'submitted', ...formJson };

            // console.log('tempPayload', tempPayload);

            const payload = {
              url: checkDialog.draft
                ? `${apiEndpoints.API_TOPIC_URL}/${checkDialog.id}`
                : `${apiEndpoints.API_TOPIC_URL}`,
              method: checkDialog.draft ? 'PUT' : 'POST',
              data: tempPayload,
            };

            // console.log('payload deaft', payload);
            AxiosCall(payload).then((updatedTopic) => {
              // console.log('updatedTopic', updatedTopic);
              replaceOldTopicByUpdatedTopic(updatedTopic, false, true);
              setCurrentUserFunction(formJson.function);
              setCurrentUserDepartment(formJson.department);
              handleClose();
            });
          },
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          <div style={{ display: 'flex', justifyContent: isValidationError ? 'space-around' : '' }}>
            <div>AI Demand Creation</div>
            <div
              style={{
                color: isValidationError ? 'red' : 'InfoText',
                display: isValidationError ? 'block' : 'none',
                marginLeft: '1rem',
              }}
            >
              Please fill out all the information below:
            </div>
          </div>
          {/* <LogoMini /> */}
        </DialogTitle>

        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {/* <DialogContentText sx={{ color: isValidationError ? 'red' : 'InfoText' }}>
            Please fill out all the information below:
          </DialogContentText> */}
          <Grid container spacing={1}>
            <Grid item md={6}>
              <TextField
                required
                name="creatorName"
                margin="dense"
                label="Name"
                type="text"
                fullWidth
                onChange={handleChange}
                disabled
                value={currentUserFullName}
                variant="standard"
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                required
                name="email"
                margin="dense"
                onChange={handleChange}
                label="Email"
                type="text"
                fullWidth
                value={currentUserEmail}
                disabled
                variant="standard"
              />
            </Grid>
            <Grid item md={6}>
              <Tooltip
                title={
                  <span className="custom-tooltip">
                    Describe your idea or demand in one sentance
                  </span>
                }
                arrow
              >
                <TextField
                  required
                  name="title"
                  value={formData?.title ?? ''}
                  onChange={handleChange}
                  margin="dense"
                  label="Title"
                  type="text"
                  fullWidth
                  variant="standard"
                  placeholder="Describe your idea or demand in one sentance"
                />
              </Tooltip>
            </Grid>
            <Grid item md={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Cross Domain</InputLabel>
                <Select
                  name="cross_domain"
                  label="Cross Domain"
                  value={formData?.cross_domain}
                  onChange={handleChange}
                >
                  {crossDomainsList.map((c) => (
                    <MenuItem key={c} value={c}>
                      {c}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {/* <Grid item md={6}>
              <TextField
                name="financialSponsor"
                margin="dense"
                label="Financial Sponsor"
                type="text"
                fullWidth
                variant="standard"
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                name="budget"
                margin="dense"
                label="Budget"
                type="text"
                fullWidth
                variant="standard"
              />
            </Grid> */}
            <Grid item md={6}>
              <Tooltip
                title={
                  <span className="custom-tooltip">
                    Estimate potential number of users globally
                  </span>
                }
                arrow
              >
                <TextField
                  name="targetPopulation"
                  margin="dense"
                  value={formData.targetPopulation ?? ''}
                  onChange={handleChange}
                  label="Target Population"
                  type="text"
                  fullWidth
                  variant="standard"
                  placeholder="Estimate potential number of users globally"
                />
              </Tooltip>
            </Grid>
            <Grid item md={6}>
              <Tooltip
                title={
                  <span className="custom-tooltip">
                    Confirm that you identifies necessary data sources
                  </span>
                }
                arrow
              >
                <TextField
                  name="dataAvailability"
                  margin="dense"
                  value={formData.dataAvailability ?? ''}
                  onChange={handleChange}
                  label="Data Availability"
                  type="text"
                  fullWidth
                  variant="standard"
                  placeholder="Confirm that you identifies necessary data sources"
                />
              </Tooltip>
            </Grid>
            <Grid item md={12}>
              <Tooltip
                title={<span className="custom-tooltip">List key success factors</span>}
                arrow
              >
                <TextField
                  name="successCriteria"
                  value={formData.successCriteria ?? ''}
                  onChange={handleChange}
                  margin="dense"
                  label="Success Criteria"
                  type="text"
                  fullWidth
                  variant="standard"
                  placeholder="List key success factors"
                />
              </Tooltip>
            </Grid>
            <Grid item md={12}>
              <Tooltip
                title={
                  <span className="custom-tooltip">
                    If your idea targets to save costs, provide estimation in kEUR per year
                  </span>
                }
                arrow
              >
                <TextField
                  name="estimationSaving"
                  value={formData.estimationSaving ?? ''}
                  onChange={handleChange}
                  margin="dense"
                  label="Business Estimated Saving"
                  type="text"
                  fullWidth
                  variant="standard"
                  placeholder="If your idea targets to save costs, provide estimation in kEUR per year"
                />
              </Tooltip>
            </Grid>
            <Grid item md={12}>
              <Tooltip
                title={
                  <span className="custom-tooltip">
                    If your idea creates new revenue stream, provide estimation in kEUR per year
                  </span>
                }
                arrow
              >
                <TextField
                  name="estimationMonetaryGain"
                  margin="dense"
                  value={formData.estimationMonetaryGain ?? ''}
                  onChange={handleChange}
                  label="Estimated Monetary Gain"
                  type="text"
                  fullWidth
                  variant="standard"
                  placeholder="If your idea creates new revenue stream, provide estimation in kEUR per year"
                />
              </Tooltip>
            </Grid>
            <Grid item md={4}>
              <MuiDatePicker
                name="eventDate"
                label="Event Date"
                value={formData.eventDate}
                initialDate={dayjs()}
                onChange={setEventDate}
              />
            </Grid>
            <Grid item md={4}>
              <FormControl required sx={{ marginTop: 2 }}>
                <FormLabel>Urgency</FormLabel>
                <RadioGroup
                  aria-required="true"
                  name="urgencyLevel"
                  value={urgencyLvl}
                  onChange={(e) => setUrgencyLvl(+e.target.value)}
                >
                  {topicUrgencyLevels.map((urgencyLvl) => (
                    <FormControlLabel
                      key={`urgency-lvl-${urgencyLvl.value}`}
                      value={urgencyLvl.value}
                      control={<Radio />}
                      label={urgencyLvl.label}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl required sx={{ marginTop: 2 }}>
                <FormLabel>Type</FormLabel>
                <RadioGroup
                  aria-required="true"
                  name="typeOfTopic"
                  value={typeOfTopic}
                  onChange={(e) => setTypeOfTopic(e.target.value as any)}
                >
                  {topicTypeLevels.map((c) => (
                    <FormControlLabel
                      key={`typeOfTopic-lvl-${c.value}`}
                      value={c.value}
                      control={<Radio />}
                      label={c.label}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item md={12}>
              <FileAttachment
                currentUserId={currentUserId}
                onFileChange={(fileBuffer, mimeType, fileName) => {
                  setFileBuffer(fileBuffer);
                  setFileName(fileName);
                  setMimeType(mimeType);
                }}
              />
            </Grid>
          </Grid>
          <Tooltip
            title={<span className="custom-tooltip">Describe what problem you are addressing</span>}
            arrow
          >
            <TextField
              required
              name="problemStatement"
              margin="dense"
              label="Problem Statement"
              onChange={handleChange}
              value={formData.problemStatement ?? ''}
              placeholder="Describe what problem you are addressing"
              type="text"
              multiline
              rows={7}
              fullWidth
              variant="outlined"
            />
          </Tooltip>
          <Tooltip
            title={
              <span className="custom-tooltip">Describe proposed solution to the problem</span>
            }
            arrow
          >
            <TextField
              required
              name="description"
              margin="dense"
              label="Need's description"
              onChange={handleChange}
              value={formData.description ?? ''}
              placeholder="Describe proposed solution to the problem"
              type="text"
              multiline
              rows={7}
              fullWidth
              variant="outlined"
            />
          </Tooltip>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            name="save"
            variant="contained"
            color="secondary"
            startIcon={<Save />}
          >
            Save
          </Button>
          <Button
            type="submit"
            name="submit"
            variant="contained"
            color="secondary"
            startIcon={<SendIcon />}
          >
            Submit
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </ThemeProvider>
  );
};

export default TopicCreationDialog;
