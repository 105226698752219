const hiddenColumns = {
  Validation_Method: true,
  Source: true,
  Rationale: true,
  Validation_Evidence: true,
  comment: true,
  PageNo: false,
  Original_Hierarchy: false,
  // copyHerarchy: true,
  id: false,
  // RawData: false,
  // Coordinates: false,
  Recheck: false,

  // i18n: false,

  updated_at: false,

  Hierarchy: true,

  Category: false,
  System_Entities: false,
  Activities: false,

  project_management: false,
  Finance: false,
  Commercial: false,
  Contractual: false,
  Digital: false,
  Cybersecurity: false,
  Standards: false,
  kpi_compliance: false,

  // lifecycles: false,
};

export default hiddenColumns;
