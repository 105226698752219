import { iCaptureCsv, iCaptureExcel, iCapturePdf } from '../../../../../../api/module1';
import {
  UserWorkloadCreate,
  userWorkloadRunPost,
  userWorkloadRunPostForREX,
} from '../../../../../../api/workloads';

const userRexBookCall = (
  scopeSetting: any[],
  fileSetting: { [x: string]: { columnNames: any } },
  captureName: any,
  checkBoxValue: any,
  rexFilter: any
) => {
  const stateToUpdate = {
    error: '',
    loading: false,
    toogleTab: { left: '10rem' },
    workload: 0,
  };
  function openArray(arr) {
    return arr?.map((obj) => obj.value);
  }
  if (true) {
    const allPromisesFun = scopeSetting.map((data, index) => {
      if (data.FileType === 'Excel') {
        return {
          ...data,
          FilePath: data.FilePath,
          FileName: data.FileName,
          Container: data.Container,
          BlobName: data.Blob,
          NameOfCapture: captureName,
          fileName: captureName,
          fileType: checkBoxValue,
          target: 'Text',
          Sheet: fileSetting[index].sheetName,
          TargetColumn: fileSetting[index].targetColumn,
          ColumnNames: fileSetting[index].columns,
          Header: true,
        };
      }

      if (data.FileType === 'CSV') {
        return {
          ...data,
          FilePath: data.FilePath,
          FileName: data.FileName,
          Container: data.Container,
          BlobName: data.Blob,
          NameOfCapture: captureName,
          fileName: captureName,
          fileType: checkBoxValue,
          target: 'Text',
          TargetColumn: fileSetting[index].columnName,
          ColumnNames: fileSetting[index].columnNames,
          Header: true,
        };
      }

      if (data.FileType === 'PDF') {
        return {
          ...data,
          FilePath: data.FilePath,
          FileName: data.FileName,
          Container: data.Container,
          BlobName: data.Blob,
          NameOfCapture: captureName,
          fileName: captureName,
          TypesOfCapture: 'Paragraph_Capture',
          target: 'Text',
          fileType: checkBoxValue,
          Header: true,
          RegId: '',
          AutoHeaderFooterDetection: false,
          TableSwitch: false,
          MergeBullet: false,
        };
      }
      return null;
    });

    const passedData = {
      // productLine: openArray(rexFilter?.filterrexProductLine) || ['*'],
      // platform: openArray(rexFilter?.filterexPlatform) || ['*'],
      // product: openArray(rexFilter?.filterrexProduct) || ['*'],
      // solution: openArray(rexFilter?.filterrexSolution) || ['*'],
      // project: openArray(rexFilter?.filterrexProject) || ['*'],
      // country: openArray(rexFilter?.filterrexCountry) || ['*'],
      // status: openArray(rexFilter?.filterrexStatus) || ['*'],
      // issuerSite: openArray(rexFilter?.filterissureSite) || ['*'],
      // typology: openArray(rexFilter?.filterTypology) || ['*'],
      // rexSource: openArray(rexFilter?.filterrexSource) || ['*'],

      productLine:
        rexFilter?.productrexLine?.length !== 0 ? openArray(rexFilter?.productrexLine) : ['*'],
      platform: rexFilter?.rexPlatform?.length !== 0 ? openArray(rexFilter?.rexPlatform) : ['*'],
      product: rexFilter?.product?.length !== 0 ? openArray(rexFilter?.product) : ['*'],
      solution: rexFilter?.solution?.length !== 0 ? openArray(rexFilter?.solution) : ['*'],
      project: rexFilter?.project?.length !== 0 ? openArray(rexFilter?.project) : ['*'],
      country: rexFilter?.country?.length !== 0 ? openArray(rexFilter?.country) : ['*'],
      status: rexFilter?.rexStatus?.length !== 0 ? openArray(rexFilter?.rexStatus) : ['*'],
      issuerSite: rexFilter?.issureSite?.length !== 0 ? openArray(rexFilter?.issureSite) : ['*'],
      typology: rexFilter?.rexTypology?.length !== 0 ? openArray(rexFilter?.rexTypology) : ['*'],
      rexSource: rexFilter?.rexSource?.length !== 0 ? openArray(rexFilter?.rexSource) : ['*'],
    };

    // console.log('passedData useroot:', passedData);

    UserWorkloadCreate({
      app: 'module2',
      action: 'RexBook',
      resources: [
        {
          settingsModule: { ...allPromisesFun[0] },
          filters: passedData,
        },
      ],
    })
      .then((newWorkload) => {
        userWorkloadRunPostForREX(Number(newWorkload.id), 'RexBook')
          .then((data) => {
            // console.log('Run post', data);
            stateToUpdate.toogleTab = { left: '10rem' };
            stateToUpdate.loading = false;
            stateToUpdate.workload = Number(newWorkload.id);
            // setLoadingResult(false);
          })
          .catch(() => {
            stateToUpdate.error = 'Error while workload post.';
            stateToUpdate.loading = false;
          })
          .finally(() => {});
      })
      .catch(() => {
        stateToUpdate.error = 'Error while workload create.';
        stateToUpdate.loading = false;
      })
      .finally(() => {});
  }

  return stateToUpdate;
};

export default userRexBookCall;
