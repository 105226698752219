/* eslint-disable react/no-danger */
/* eslint-disable react/button-has-type */
import React, { memo, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

import FileCopyIcon from '@material-ui/icons/FileCopy';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  btnCopy: {
    color: 'white',
    position: 'absolute',
    top: '10px',
    right: '10px',
    '@media print': {
      display: 'none',
    },
  },

  btnCopied: {
    color: '#dc3223',
    position: 'absolute',
    top: '10px',
    right: '10px',
    '@media print': {
      display: 'none',
    },
  },
  // following is the markdownContent class added to put scroll
  markdownContent: {
    '& h6': {
      color: 'black',
      height: '190px', // Adjust the height as needed
      fontSize: '15px', // Adjust the font size to match <p>
      fontWeight: 'normal', // Adjust the font weight to match <p>
      padding: 0,
      position: 'relative',
      marginTop: '3px', // Move the <h6> element upwards
      overflowY: 'scroll',
    },
  },
}));

const CodeBlock = ({ language, value }) => {
  const classes = useStyles();

  const [isCodeCopied, setIsCodeCopied] = useState(false);

  const handleCopyCode = () => {
    setIsCodeCopied(true);
  };

  return (
    <div className={classes.root}>
      <SyntaxHighlighter style={dracula} language={language}>
        {value}
      </SyntaxHighlighter>
      {isCodeCopied ? (
        <CopyToClipboard text={value} onCopy={handleCopyCode}>
          <FileCopyIcon className={classes.btnCopied} />
        </CopyToClipboard>
      ) : (
        <CopyToClipboard text={value} onCopy={handleCopyCode}>
          <FileCopyOutlinedIcon className={classes.btnCopy} />
        </CopyToClipboard>
      )}
    </div>
  );
};

// Utility function to preprocess content and replace '\n' with <br />
function preprocessContent(content) {
  return content.replace(/\\n|\\r|\n|\r/g, '  <br/>');
}

const MarkdownContent = ({ content, gptEngine }: any) => {
  const classes = useStyles();
  return (
    <ReactMarkdown
      className={classes.markdownContent} // Added to put scroll
      components={{
        code({ node, inline, className, children, ...props }) {
          const match = /language-(\w+)/.exec(className || '');
          return !inline && match ? (
            <CodeBlock language={match[1]} value={String(children).trim()} />
          ) : (
            // python by default
            <CodeBlock language="python" value={String(children).trim()} />
            // <code className={className} {...props}>
            //   {children}
            // </code>
          );
        },
      }}
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeRaw]} // Enables raw HTML rendering
    >
      {/* <div dangerouslySetInnerHTML={{ __html: content }} /> */}

      {gptEngine === 'rex' ? preprocessContent(content) : content}

      {/* {content} */}
      {/* {preprocessContent(content)} */}
      {/* {preprocess ? preprocessContent(content) : content} */}
    </ReactMarkdown>
  );
};

export default memo(MarkdownContent);
