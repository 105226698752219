import React, { memo, useContext, useEffect, useMemo, useState } from 'react';
import {
  Badge,
  Box,
  Button,
  CardContent,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  Paper,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LinearProgress from '@mui/material/LinearProgress';

import Similarizer from '../../Similarizer';
import NCandGAP from './NCandGAP';
import preValidation, { getPayloadForAnalysis } from '../../utils/analysisPreValidation';
import RejectCompliance from './RejectCompliance';
import useAnalysisOperations from '../../AnalysisFlow/Hooks/useAnalysisOperations';
import formatDate, { formatLocal } from '../../../../../../../tools/formatDate';
import { ExpandMore, FormContainer, RequirementTitle, TextDiv } from '../styles/modalAnalysis';
import RenderPdfFile from '../../components/RenderPdfFile';
import { ButtonMUI, ButtonMUIToggle, ToogleContainer } from '../../Styles/utils';

const ModalAnalysisForms = ({ props }) => {
  const {
    onClose,
    trackCurrentRequirement,
    firstIndex,
    lastIndex,
    IncrementIndex,
    decrementIndex,
    totalRequirements,
    currentIndex,
    gapList,
    setGapList,
    nclist,
    setNcList,
    setNotify,
    updateRequirement,
    setUpdateRequirement,
    workloadId,
    filename,
    projectId,
    project,
    userId,
    setMetaDAta,
    langCheck,
    languagesCode,
    selectedIds,
    metaData,
    urlFile,
    type,
  } = props;

  const {
    text,
    Coordinates,
    owning_id,
    allocation_id,
    pbs,
    compliance,
    rationale,
    created_at,
    compliant,
    comments,
    reject,
    rejectValue,
  } = trackCurrentRequirement;

  const [currentCompliance, setCurrentCompliance] = useState('none');
  const [currentNcorGap, setCurrentNcorGap] = useState('');
  const [respectiveComplianeRationale, setRespectiveComplianeRationale] = useState('');
  const [currentComments, setCurrentComments] = useState('');
  const [currentRejectStatus, setCurrentRejectStatus] = useState(false);
  const [currentRejectValue, setCurrentRejectValue] = useState('');
  const [expanded, setExpanded] = useState(false);
  const [showRightBlock, setShowRightBlock] = useState(true);

  useMemo(() => {
    setCurrentCompliance(compliance ?? 'none');
    setRespectiveComplianeRationale(rationale ?? '');
    setCurrentNcorGap(compliant ?? '');
    setCurrentRejectStatus(reject);
    setCurrentRejectValue(rejectValue ?? '');
  }, [trackCurrentRequirement]);

  const {
    loading,
    handleRejectAnalysis,
    handleAnalysisSave,
    handleAnalysisSaveAll,
  } = useAnalysisOperations({
    workloadId,
    filename,
    owning_id,
    allocation_id,
    projectId,
    userId,
    setMetaDAta,
    project,
    langCheck,
    languagesCode,
    selectedIds,
    RecjectValue: currentRejectValue,
    metaData,
    onClose,
    setNotify,
  });

  const handleComplianceChange = (event) => {
    setCurrentRejectValue('');
    updateRequirement[currentIndex].compliance = event.target.value;
    updateRequirement[currentIndex].compliant = '';
    updateRequirement[currentIndex].rationale = '';
    updateRequirement[currentIndex].created_at = '';
    updateRequirement[currentIndex].analysis_dataset_id = '';
    setUpdateRequirement(updateRequirement);
    setCurrentCompliance(event.target.value);
    setCurrentNcorGap('');
    setRespectiveComplianeRationale('');
  };

  const handleNCorGapCompliance = (event) => {
    // updateRequirement[currentIndex].compliance = event.compliance;
    updateRequirement[currentIndex].rationale = event.rationale;
    updateRequirement[currentIndex].compliant = event.compliance;
    updateRequirement[currentIndex].created_at = event.created_at;
    updateRequirement[currentIndex].analysis_dataset_id = event.analysis_dataset_id;
    setUpdateRequirement(updateRequirement);
    setCurrentNcorGap(event.compliance);
    setRespectiveComplianeRationale(event.rationale);
  };

  const updateCurrentRequirementForReject = () => {
    updateRequirement[currentIndex].rationale = '';
    updateRequirement[currentIndex].compliance = 'none';
    updateRequirement[currentIndex].compliant = '';
    updateRequirement[currentIndex].created_at = '';
    updateRequirement[currentIndex].analysis_dataset_id = '';
    updateRequirement[currentIndex].reject = true;
    updateRequirement[currentIndex].rejectValue = currentRejectValue;
    setUpdateRequirement(updateRequirement);
    setCurrentRejectStatus(true);
    setCurrentCompliance('none');
    setRespectiveComplianeRationale('');
    setCurrentNcorGap('');
  };

  const propsForNCandGAP = {
    gapList,
    setGapList,
    nclist,
    setNcList,
    handleNCorGapCompliance,
    type: currentCompliance,
    setCurrentValue: setCurrentNcorGap,
    currentValue: currentNcorGap,
    projectId,
    setNotify,
  };

  const checkData = (callBack) => {
    if (currentRejectStatus) {
      return callBack();
    }
    const { message, check } = preValidation(
      currentCompliance,
      currentNcorGap,
      respectiveComplianeRationale
    );

    if (check) {
      return callBack();
    }
    setNotify(message, 'error');
    return null;
  };

  const handleNext = () => {
    const fn = () => {
      return IncrementIndex();
    };
    checkData(fn);
  };

  const handleReset = () => {
    setCurrentCompliance('none');
    setRespectiveComplianeRationale('');
    setCurrentNcorGap('');
    setCurrentRejectStatus(false);
    updateRequirement[currentIndex].rationale = '';
    updateRequirement[currentIndex].compliance = 'none';
    updateRequirement[currentIndex].compliant = '';
    updateRequirement[currentIndex].created_at = '';
    updateRequirement[currentIndex].analysis_dataset_id = '';
    updateRequirement[currentIndex].reject = false;
    setUpdateRequirement(updateRequirement);
  };

  const handleTextFeilds = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case 'comments': {
        const newComment = {
          created_at: formatDate(new Date().toString(), formatLocal('en')),
          content: value.trim(),
          isNew: true,
        };

        if (comments.length > 1 && comments[0].isNew) {
          updateRequirement[currentIndex].comments[0] = {
            ...newComment,
          };
        } else {
          updateRequirement[currentIndex].comments[0] = {
            ...newComment,
          };
        }
        setUpdateRequirement(updateRequirement);
        setCurrentComments(value);
        return;
      }

      case 'Rationale': {
        updateRequirement[currentIndex].rationale = value;
        setUpdateRequirement(updateRequirement);
        setRespectiveComplianeRationale(value);
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleSave = () => {
    if (currentRejectStatus && currentIndex === 0) {
      return;
    }
    try {
      const { message = 'please select compliance', check } = preValidation(
        currentCompliance,
        currentNcorGap,
        respectiveComplianeRationale
      );
      if (check && currentCompliance !== 'none') {
        // console.log('updateRequirement', updateRequirement);
        const temp = updateRequirement.slice(0, currentIndex + 1).map((eachItem) => {
          const fn = () => {
            return getPayloadForAnalysis(eachItem.compliance, eachItem);
          };
          return fn;
        });
        // console.log('temp', temp);
        const payload = temp.map((fn) => checkData(fn)).filter((eachItem) => eachItem !== null);
        // console.log('payload', payload);
        if (payload) handleAnalysisSave(payload);
      } else {
        setNotify(message, 'error');
      }
    } catch (error) {
      console.error('Error in handleSave:', error);
    }
  };

  const handleSaveAll = () => {
    try {
      const { message = 'please select compliance', check } = preValidation(
        currentCompliance,
        currentNcorGap,
        respectiveComplianeRationale
      );

      if (check && currentCompliance !== 'none') {
        const payload = updateRequirement
          .filter((eachItem) => {
            return eachItem.reject !== true;
          })
          .map((eachItem) => {
            return getPayloadForAnalysis(eachItem.compliance, eachItem);
          });

        let temp = [];
        if (currentIndex < totalRequirements - 1) {
          temp = updateRequirement
            .slice(currentIndex)
            .map((eachItem) => {
              return getPayloadForAnalysis(
                updateRequirement[currentIndex].compliance,
                eachItem,
                true,
                {
                  compliance: updateRequirement[currentIndex].compliance,
                  rationale: updateRequirement[currentIndex].rationale,
                  created_at: updateRequirement[currentIndex].created_at,
                  comments: updateRequirement[currentIndex].comments,
                  analysis_dataset_id: updateRequirement[currentIndex].analysis_dataset_id ?? '',
                }
              );
            })
            .filter((eachItem) => eachItem !== null);
          if (payload) {
            handleAnalysisSaveAll([...payload.slice(0, currentIndex), ...temp]);
            return;
          }
        }
        if (payload) {
          handleAnalysisSaveAll([...payload, ...temp]);
        }
      } else {
        setNotify(message, 'error');
      }
    } catch (error) {
      console.log('error in handle save alll', error);
    }
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleReject = async () => {
    if (currentRejectValue === '') {
      setNotify('Please select reason to reject', 'error');
      return;
    }
    const response = await handleRejectAnalysis();
    if (response !== undefined) {
      setMetaDAta(response);
      setNotify('Successfully Rejected', 'success');
      updateCurrentRequirementForReject();
    }
  };

  const disableReject =
    (!!currentCompliance && currentCompliance !== 'none') || currentRejectStatus;

  const propsReject = {
    currentRejectValue,
    setCurrentRejectValue,
    updateCurrentRequirementForReject,
    handleReject,
    disableReject,
    loading,
  };

  return (
    <>
      {' '}
      <Grid container xs={12} sm spacing={0.1} height="100%">
        <Grid
          item
          xs={6}
          direction="column"
          spacing={1}
          height="100%"
          sx={{
            // border: '1px solid #002A45',
            borderRadius: '10px',
            position: 'relative',
            p: 0,
            m: 0,
          }}
        >
          {loading && <LinearProgress color="secondary" />}

          <Grid container direction="column" sm xs={12} spacing={1} flexGrow={1}>
            <Grid item xs={12} spacing={1} height="100%">
              <Grid container xs={12} sm spacing={1}>
                <Grid item xs={6} spacing={1}>
                  <Grid container xs={12} direction="column" spacing={1} sx={{ p: 1 }}>
                    <Grid item>
                      <RequirementTitle>
                        <Typography color="info">
                          <b>Requirement</b> {currentIndex + 1} <b>of</b> {totalRequirements}{' '}
                          <b>iSenS id :-</b>
                          {owning_id}
                        </Typography>
                      </RequirementTitle>{' '}
                      <b>Text : </b>
                      <TextDiv>{text} </TextDiv>
                    </Grid>
                    <Grid item>
                      <Typography color="info">
                        {' '}
                        <b>Allocation : </b>
                        {pbs}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <TextField
                        id="outlined-basic"
                        label="Internal"
                        variant="outlined"
                        color="info"
                        style={{ width: '100%', color: '#002A45' }}
                        name="comments"
                        value={currentComments}
                        onChange={(event) => {
                          handleTextFeilds(event);
                        }}
                        disabled={currentRejectStatus}
                      />
                    </Grid>
                    <Grid item>
                      <Badge badgeContent={comments?.length} color="secondary">
                        <small style={{ marginRight: 6 }}>Comments </small>
                      </Badge>{' '}
                      <ExpandMore
                        expand={expanded}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </ExpandMore>
                      <Collapse
                        in={expanded}
                        timeout="auto"
                        unmountOnExit
                        sx={{
                          width: '100%',
                          // maxWidth: 360,
                          // bgcolor: 'background.paper',
                          // position: 'relative',
                          border: '1px solid #002A45',
                          overflow: 'auto',
                          maxHeight: 100,
                          // '& ul': { padding: 0 },
                        }}
                      >
                        {comments
                          .sort((a, b) => (a.created_at < b.created_at ? 1 : -1))
                          .map((eachComm, index) => (
                            <p key={`intComm-${index}`}>
                              {eachComm.created_at} : {eachComm.content}
                            </p>
                          ))}
                      </Collapse>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6} spacing={1}>
                  <Grid container direction="column" sm spacing={1}>
                    <Grid item>
                      <Grid container spacing={1} sm>
                        <Grid item xs={6}>
                          <FormControl color="info">
                            <RadioGroup
                              aria-labelledby="demo-radio-buttons-group-label"
                              name="radio-buttons-group"
                              color="info"
                              value={currentCompliance}
                              onChange={handleComplianceChange}
                            >
                              <FormContainer>
                                <FormControlLabel
                                  value="compliant"
                                  control={<Radio color="secondary" size="small" />}
                                  label="Compliant"
                                  color="info"
                                  disabled={currentRejectStatus}
                                />
                              </FormContainer>
                              <FormContainer>
                                <FormControlLabel
                                  value="nc"
                                  control={<Radio color="secondary" size="small" />}
                                  label="Non Compliant"
                                  color="info"
                                  disabled={currentRejectStatus}
                                />
                              </FormContainer>
                              <FormContainer>
                                <FormControlLabel
                                  value="gap"
                                  control={<Radio color="secondary" size="small" />}
                                  label="Gap"
                                  disabled={currentRejectStatus}
                                />
                              </FormContainer>
                              <FormContainer>
                                <FormControlLabel
                                  value="tbd"
                                  control={<Radio color="secondary" size="small" />}
                                  label="Partial Compliant"
                                  color="info"
                                  disabled={currentRejectStatus}
                                />
                              </FormContainer>
                              <FormContainer>
                                <FormControlLabel
                                  value="need-clarification"
                                  control={<Radio color="secondary" size="small" />}
                                  label="Need Clarification"
                                  color="info"
                                  disabled={currentRejectStatus}
                                />
                              </FormContainer>
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <RejectCompliance {...propsReject} />

                          {type.toLowerCase() === 'pdf' && (
                            <ToogleContainer style={{ display: 'flex', marginTop: '10px' }}>
                              {' '}
                              <ButtonMUIToggle
                                variant="contained"
                                style={
                                  showRightBlock
                                    ? { background: 'transparent', color: '#002a45' }
                                    : { backgroundColor: '#DC3223' }
                                }
                                // color={showRightBlock ? 'info' : 'secondary'}
                                onClick={() => {
                                  setShowRightBlock(false);
                                }}
                              >
                                PDF
                              </ButtonMUIToggle>
                              <ButtonMUIToggle
                                variant="contained"
                                style={
                                  showRightBlock || type !== 'pdf'
                                    ? { backgroundColor: '#DC3223' }
                                    : { background: 'transparent', color: '#002a45' }
                                }
                                // color={showRightBlock ? 'secondary' : 'info'}
                                onClick={() => {
                                  setShowRightBlock(true);
                                }}
                              >
                                iSimilizer
                              </ButtonMUIToggle>
                            </ToogleContainer>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>

                    {['nc', 'gap'].includes(currentCompliance) && (
                      <Grid item container direction="column">
                        <Box>
                          <NCandGAP {...propsForNCandGAP} />
                        </Box>
                      </Grid>
                    )}

                    <Grid item>
                      <TextField
                        value={respectiveComplianeRationale}
                        id="outlined-multiline-static"
                        label="Rationale"
                        style={{ width: '100%', color: '#002A45' }}
                        color="info"
                        name="Rationale"
                        onChange={(event) => {
                          handleTextFeilds(event);
                        }}
                        multiline
                        rows={3}
                        defaultValue="Default Value"
                        disabled={currentRejectStatus}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                padding: 0,
                display: 'flex',
                gap: '3px',
                flexGrow: 1,
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <ButtonMUI
                onClick={() => {
                  handleReset();
                }}
                variant="outlined"
                color="secondary"
              >
                Reset
              </ButtonMUI>

              <ButtonMUI
                onClick={() => {
                  decrementIndex();
                }}
                variant="contained"
                color="info"
                disabled={firstIndex || loading}
              >
                Previous
              </ButtonMUI>

              <ButtonMUI
                onClick={() => {
                  handleNext();
                }}
                variant="contained"
                color="info"
                disabled={lastIndex || loading}
              >
                Next
              </ButtonMUI>
              <ButtonMUI
                onClick={() => {
                  handleSave();
                }}
                variant="contained"
                color="secondary"
                disabled={loading || currentRejectStatus}
              >
                Save
              </ButtonMUI>

              <ButtonMUI
                onClick={() => {
                  handleSaveAll();
                }}
                variant="contained"
                color="info"
                disabled={loading || currentRejectStatus}
              >
                Save for All
              </ButtonMUI>
            </Grid>
          </Grid>
          {/* <div style={{ position: 'absolute', bottom: '10px', right: '10px' }}> */}

          {/* </div> */}
        </Grid>

        <Grid item xs={6} direction="column" sx={{ p: 2.2 }}>
          {showRightBlock ? (
            <Similarizer inputSimList={[text]} />
          ) : (
            <RenderPdfFile
              urlFile={urlFile}
              Text={text}
              coordinates={Coordinates}
              propStyle={{ width: '98%', display: 'flex', height: '100%', padding: '5px' }}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default ModalAnalysisForms;
