/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Box, Chip, makeStyles } from '@material-ui/core';
import React, { useContext, useState } from 'react';

import { fontWeight } from '@mui/system';
import parseAnswer, { parseAnswerAzure, ParsedAnswer } from './tools/answerParser';
import { extractTitle } from './ChatContainer';
import { ChatContext } from './ChatContext';
import { gptEngineType } from './ChatReducer';

const useStyles = makeStyles((theme) => ({
  listItem: {
    // padding: theme.spacing(1),
    // backgroundColor: theme.palette.background.default,
    '&.active': {
      // backgroundColor: theme.palette.primary.main,
      // color: theme.palette.common.white,
      fontWeight: 'bold',
    },
  },
}));

function DiscussBoardItemButtonsCitations({ input, classes, onOpenCitation }: any) {
  const classesLi = useStyles();
  const { context, content } = input;
  const { state } = useContext(ChatContext);
  const [citId, setCitId] = useState('');
  let outputCitation: ParsedAnswer;
  let ButtonCitations = [] as any[];

  // init
  outputCitation = {
    citations: [],
    markdownFormatText: content || '',
    assistant: false,
  };
  try {
    // version azure ai
    if (context && context?.messages === undefined) {
      outputCitation = parseAnswerAzure({
        answer: content,
        citations: context.citations,
        assistant: false,
      });
    } else if (
      // version openai
      context &&
      context?.messages[0].role === 'tool' &&
      context?.messages[0].content
    ) {
      const { citations } = JSON.parse(context?.messages[0].content);
      ButtonCitations = citations;
      outputCitation = parseAnswer({
        answer: content,
        citations: ButtonCitations,
        assistant: false,
      });
    }

    ButtonCitations = outputCitation.citations.sort((a: any, b: any) => a.id - b.id);
  } catch (error) {
    console.error(error);
    return null;
  }
  return (
    <div>
      {ButtonCitations.length > 0 && (
        <Box display="flex" flexDirection="row" className={classes.boxMessage}>
          <Box className={classes.imgMessage} />
          <Box className={classes.contentMessage}>
            <ul>
              {ButtonCitations.map((b, keyCit) => {
                const key = `cit-item-${keyCit + 1}`;
                const title = extractTitle(b.document ?? b, state.gptEngine as gptEngineType);
                return (
                  <li className={`${classesLi.listItem} ${key === citId ? 'active' : ''}`}>
                    <button
                      type="button"
                      onClick={(e) => {
                        e.preventDefault(); // Prevents unintended page reload
                        onOpenCitation(b.document ?? b, b?.captions);
                        setCitId(key);
                      }}
                      className="citation-button" // Optional: style as needed
                      aria-label={`Open citation for doc ${b.id}`}
                    >
                      {state?.gptEngine === 'private' ? `ref ${b.id}` : `doc ${b.id}`}
                    </button>
                    {title && <>:</>} {/* citation for private */}
                    {b?.document?.URL ? (
                      <a
                        href={b.document.URL}
                        target="_blank"
                        rel="noreferrer"
                        style={{ textDecoration: 'underline', color: 'blue' }}
                      >
                        {title}
                      </a>
                    ) : (
                      title
                    )}
                  </li>
                );
              })}
            </ul>
            {/* {ButtonCitations.map((b, keyCit) => {
              const key = `cit-item-${keyCit + 1}`;
              return (
                <Chip
                  size="medium"
                  className={classes.buttonCitations}
                  onClick={() => {
                    onOpenCitation(b);
                    setCitId(key);
                  }}
                  title={b.title || `doc ${b.id}`}
                  label={`doc ${b.id}`}
                  key={key}
                  variant={key === citId ? 'default' : 'outlined'}
                />
              );
            })} */}
          </Box>
        </Box>
      )}
    </div>
  );
}

export default DiscussBoardItemButtonsCitations;
