import React, { StrictMode, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { ThemeProvider } from '@mui/material/styles';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './components/Auth/msalConfig.ts';
import { theme } from './utils/theme.ts';
import App from './App.tsx';

const msalInstance = new PublicClientApplication(msalConfig);

const IndeationApp = () => {
  // useEffect(() => {
  //   localStorage.removeItem('appJWT');
  //   localStorage.removeItem('azJWT');
  //   localStorage.removeItem('Module_1_column_state');
  // }, []);

  return (
    <>
      <MsalProvider instance={msalInstance}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </MsalProvider>
    </>
  );
};

export default IndeationApp;
