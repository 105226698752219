import React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import { ITopic } from '../../../interfaces/ITopic';
import { IParamDetail } from '../../../interfaces/IParamDetail';
import Topic from '../Topic/Topic';

interface TopicDetailProps {
  topic: ITopic;
  user: 'admin' | 'normal';
  topicStatuses: IParamDetail[];
  replaceOldTopicByUpdatedTopic: (updatedTopic: ITopic) => void;
  currentUserId: number;
  visibleFilterTopics: ITopic[];
  mailToSubjectTemplate: string;
  mailToContentTemplate: string;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
}));

const TopicDetail: React.FC<TopicDetailProps> = ({
  topic,
  topicStatuses,
  user,
  replaceOldTopicByUpdatedTopic,
  currentUserId,
  visibleFilterTopics,
  mailToSubjectTemplate,
  mailToContentTemplate,
}) => {
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <BootstrapDialog
        maxWidth="lg"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        {/* <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    {topic.title}
                </DialogTitle> */}
        {/* <DialogContent> */}
        <Topic
          showBackButton
          handleCloseDlg={handleClose}
          disabledCollapse
          defaultExpanded
          mailToSubjectTemplate={mailToSubjectTemplate}
          mailToContentTemplate={mailToContentTemplate}
          topicStatuses={topicStatuses}
          user={user}
          topic={topic}
          visibleFilterTopics={visibleFilterTopics}
          currentUserId={currentUserId}
          replaceOldTopicByUpdatedTopic={replaceOldTopicByUpdatedTopic}
        />
        {/* </DialogContent> */}
      </BootstrapDialog>
    </React.Fragment>
  );
};

export default TopicDetail;
