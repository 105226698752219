/* eslint-disable react/button-has-type */
import { Box, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import TranslateIcon from '@mui/icons-material/Translate';
import { getAllProjectCreationLanguages, getProjectGlossaryFiles } from '../../../../api/projects';
import FileTranslator from '../../../../components/DocumentTranslator/FileTranslator';
import Module1Context from '../../../Module1/Module1Context';
import ProjectContext from '../../../Project/ProjectContext';
import formatBytes from '../../../../tools/formatBytes';
import useAppToken from '../../../../app/useAppToken';
import TextTranslation from '../../../../components/TextTranlator/TextTranslator';
import Module2Context from '../../Module2Context';

// import FileTranslator from './FileTranslator';

const mode = {
  Text_Translator: { left: '0%' },
  Document_Translator: { left: '50%' },
};
const SupportedFiles = [
  'xlsx',
  'csv',
  'pdf',
  'docx',
  'pptx',
  'ppt',
  'htm',
  'msg',
  'xls',
  'xlf',
  'html',
  'markdown',
  'mdown',
  'mkdn',
  'md',
  'mkd',
  'mdwn',
  'mdtxt',
  'mdtext',
  'rmd',
  'mthml',
  'mht',
  'doc',
  'odt',
  'odp',
  'ods',
  'rtf',
  'tsv',
  'tab',
  'txt',
];
const Translator = () => {
  const [tab, setTab] = useState('Text_Translator');
  const [languageCode, setLanguageCode] = useState([]);
  const [files, setFiles] = useState([]);
  const { projectId, moduleroute } = useParams() as any;
  const [glossaryFiles, setGlossaryFiles] = useState([]);
  const { historyTranslation, setHistoryTranslation } = useContext(Module2Context);
  const { projects, setSnack } = useContext(ProjectContext);
  const { appJWT } = useAppToken();
  const { user_id } = appJWT;
  const modetab = { key: 'project', id: projectId, project: projects[0] };

  useEffect(() => {
    getProjectGlossaryFiles(projectId, user_id).then((result) => {
      const allResources = [] as any;
      if (result.length > 0) {
        const { resources } = result[0];
        for (const r of resources) {
          allResources.push({
            ...r,
            size: formatBytes(Number(r.size), 0),
            id: r.etag,
            folderName: 'user',
            folderId: 0,
            isNew: false,
          });
        }
      }
      setGlossaryFiles(allResources.sort((a, b) => new Date(b.date) - new Date(a.date)));
    });
    getAllProjectCreationLanguages().then((dbProjects) => {
      const db = dbProjects.map((item) => {
        return `${item.language}-${item.codes}`;
      });
      setLanguageCode(db);
    });
    const { folders } = projects[0];
    const allResources = [] as any[];
    if (folders) {
      for (const eachFolder of folders) {
        if (eachFolder.resources && eachFolder.resources.length > 0)
          for (const r of eachFolder.resources) {
            const fileExtension = r.filename.toLowerCase().split('.').pop();
            if (SupportedFiles.includes(fileExtension)) {
              allResources.push({
                ...r,
                size: formatBytes(Number(r.size), 0),
                id: r.etag,
                folderName: eachFolder.name,
                folderId: eachFolder.id,
                isNew: false,
              });
            }
          }
      }
    }
    setFiles(allResources.sort((a, b) => new Date(b.date) - new Date(a.date)));
  }, []);

  return (
    <Box className="Translator-home">
      <div className="Translator-home-button-Box">
        <div style={mode[tab]} className="Translator-home-btn">
          {}
        </div>
        <button
          type="button"
          className="Translator-home-toggleBtn"
          style={mode[tab]?.left === '0%' ? { color: 'white' } : {}}
          onClick={() => {
            setTab('Text_Translator');
          }}
        >
          <TranslateIcon />
          Text Translate
        </button>
        <button
          type="button"
          className="Translator-home-toggleBtn"
          style={mode[tab]?.left === '50%' ? { color: 'white' } : {}}
          onClick={() => {
            setTab('Document_Translator');
          }}
        >
          <DocumentScannerIcon />
          Document Translate
        </button>
      </div>
      {tab === 'Text_Translator' && (
        //  <TranslationNew languageCode={languageCode} home="Home" />
        <TextTranslation
          setSnack={setSnack}
          languageCode={languageCode}
          historyTranslation={historyTranslation}
          setHistoryTranslation={setHistoryTranslation}
        />
      )}
      {tab === 'Document_Translator' && (
        // <FileTranslator languageCode={languageCode} files={files} setFiles={setFiles} home="Home" />

        <FileTranslator
          setSnack={setSnack}
          languageCode={languageCode}
          files={files}
          mode={modetab}
          setFiles={setFiles}
          glossaryFiles={glossaryFiles}
          setGlossaryFiles={setGlossaryFiles}
        />
      )}
    </Box>
  );
};

export default Translator;
