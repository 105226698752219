import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface StatusChangedConfirmationProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  changeStatusCallback: (e: React.FormEvent<HTMLFormElement>, changeStatusReason?: string) => void;
}
const StatusChangedConfirmation: React.FC<StatusChangedConfirmationProps> = ({
  open,
  setOpen,
  changeStatusCallback,
}) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries((formData as any).entries());
            changeStatusCallback(event, formJson.reason);
            handleClose();
          },
        }}
      >
        <DialogTitle>Change Topic Status</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tell us the reason you want to change topic status backward
          </DialogContentText>
          <TextField
            autoFocus
            required
            name="reason"
            margin="dense"
            label="Reason"
            type="text"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="outlined" color="error" type="submit">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default StatusChangedConfirmation;
