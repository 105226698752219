import React, { useContext, useEffect, useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TranslateIcon from '@mui/icons-material/TranslateOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
// import './m3ToggleStyle.css';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Paper, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import CdaTabTableResult from './CdaTabTableResult';
import CdaProductTableResult from './CdaProductTableResult';
import CdaPhaseTabTableResult from './CdaPhaseTabTableResult';

const AntTabs = withStyles((theme) => ({
  root: {
    marginTop: 5,
    border: '1px solid #002a45',
    borderRadius: 22,
    // float: 'right',
  },
  indicator: {
    display: 'none', // Set the display property to none
  },
}))(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    '&.MuiTab-labelIcon': {
      minHeight: '0vh',
      paddingTop: 11,
    },
    '& .MuiTab-wrapper': {
      flexDirection: 'row',
    },
    textTransform: 'none',
    minWidth: 72,
    maxWidth: 'unset',
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(0),
    flexGrow: 1,
    // padding: '10px 20px',
    cursor: 'pointer',
    fontSize: '0.9rem',
    background: 'white',
    // transition: 'color 0.5s',
    transition: 'background-color 0.5s',
    borderRadius: '30px',
    border: 'none',
    outline: 'none',
    position: 'relative',
    textAlign: 'center',
    color: '#002a45',
    opacity: 1,
    // '&:hover': {
    //   color: '#40a9ff',
    //   opacity: 1,
    //   background: '#1890ff',
    // },
    '&$selected': {
      color: '#fff',
      fontWeight: theme.typography.fontWeightMedium,
      background: '#002a45',
      boxShadow: '0px 4px 8px #00000029',
      transition: '1.0s',
    },
    '&:focus': {
      color: 'white',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);
const data = [
  // ... (your data here)
];

function CustomerDocumentTab({ payloadFinal, listRfp, fileName }) {
  //   const arr = [];
  //   listRfp?.map((value, i) => {
  //     return arr.push(JSON.parse(value.results_data));
  //   });
  const fileNameToFilter = 'CT3770_small.xlsx'; // Replace with the desired file name
  const newjArray = [];
  const uniqueEntities = new Set();

  listRfp
    .filter((item) => item.fileName === fileName)
    .forEach((item) => {
      item.results_data.forEach((result) => {
        if (typeof result === 'string') {
          const parsedResults = JSON.parse(result);
          // console.log('parsedResults', parsedResults);
          parsedResults.forEach((parsedResult) => {
            if (!uniqueEntities.has(parsedResult.Entity_from_doc)) {
              uniqueEntities.add(parsedResult.Entity_from_doc);
              newjArray.push({
                Entity_from_doc: parsedResult.Entity_from_doc,
                PBS_Element_ID: parsedResult['PBS Element Name'],
              });
            }
          });
        } else if (result.message) {
          const noDataMessage = 'No data';
          if (!uniqueEntities.has(noDataMessage)) {
            uniqueEntities.add(noDataMessage);
            newjArray.push({ Entity_from_doc: noDataMessage });
          }
        }
      });
    });
  const newArray = [];
  // const fileNameToFilter = 'CT3770_small.xlsx'; // Replace with the desired file name
  // const newArray = [];

  listRfp
    .filter((item) => item.fileName === fileName)
    .forEach((item) => {
      item.results_data.forEach((result) => {
        if (typeof result === 'string') {
          const parsedResults = JSON.parse(result);
          // console.log('parsedResults', parsedResults);
          parsedResults.forEach((parsedResult) => {
            newArray.push({ Entity_from_doc: parsedResult.Entity_from_doc, PBS_Element_ID: '' });
          });
        } else if (result.message) {
          newArray.push({ Entity_from_doc: 'No data' });
        }
      });
    });
  // listRfp.forEach((item) => {
  //   item.results_data.forEach((result) => {
  //     if (typeof result === 'string') {
  //       const parsedResults = JSON.parse(result);
  //       parsedResults.forEach((parsedResult) => {
  //         newArray.push({ Entity_from_doc: parsedResult.Entity_from_doc });
  //       });
  //     } else if (result.message) {
  //       newArray.push({ Entity_from_doc: 'No data' });
  //     }
  //   });
  // });
  const [value, setValue] = React.useState(0);
  const [tabs, setTabs] = useState([
    { service: 'iDCA', icons: <DescriptionOutlinedIcon /> },
    { service: 'iQualimeter', icons: <VerifiedUserOutlinedIcon /> },
    { service: 'iVerifier', icons: <CheckCircleOutlineIcon /> },
    { service: 'iTestGenerator', icons: <StickyNote2OutlinedIcon /> },
  ]);
  const { projectId, workloadId } = useParams() as any;
  //   const { userSetting } = useContext(ProjectContext);
  const queryParams = new URLSearchParams(window.location.search);
  const actionIs = queryParams.get('action');
  const [openTreeItem, setOpenTreeItem] = useState<any>([]);
  const [submodule3_0, setSubModule3_0] = useState('');
  const [submodule3_1, setSubModule3_1] = useState('');
  const [submodule3_2, setSubModule3_2] = useState('');
  const [submodule3_3, setSubModule3_3] = useState('');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div>
        <AntTabs
          value={value}
          onChange={handleChange}
          style={{
            // maxWidth: `${150 * tabs.length}px`,
            width: '100%',
            float: 'inline-end',
            // float: 'right',
            // width: 'fit-content',
          }}
        >
          {/* {tabs.map((tab, index) => (
            <AntTab label={tab?.service} key={index} icon={tab.icons} />
          ))} */}
          <AntTab label="Phases / Geographical Areas / Trains" />
          <AntTab label="Key Dates" />
          <AntTab label="Products" />
        </AntTabs>
        <Grid container>
          {value === 1 && (
            <Grid item xs={12}>
              <CdaTabTableResult
                streamDates={
                  payloadFinal[0]?.stream_dataes?.response === undefined
                    ? []
                    : payloadFinal[0]?.stream_dataes?.response
                }
              />
            </Grid>
          )}
          {value === 2 && (
            <Grid item xs={12}>
              <CdaProductTableResult streamDates={newjArray} />
            </Grid>
          )}
          {value === 0 && (
            <Grid item xs={12}>
              <CdaPhaseTabTableResult
                streamDates={
                  payloadFinal[1]?.stream_phases?.response === undefined
                    ? []
                    : payloadFinal[1]?.stream_phases?.response
                }
              />
            </Grid>
          )}
          {/* {value === 3 && (
            <Grid item xs={12}>
              <br />
              <TestGenerator />
            </Grid>
          )} */}
        </Grid>
      </div>
    </>
  );
}

export default CustomerDocumentTab;
