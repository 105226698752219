import React, { memo, useMemo, useState } from 'react';
import {
  Badge,
  Box,
  Button,
  CardContent,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  Paper,
} from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import useListCompliance from '../../AnalysisFlow/Hooks/useListCompliance';
import { NCandGapContainer } from '../styles/modalAnalysis';

const NCandGAP = (props) => {
  const [open, setOpen] = useState(false);
  const {
    gapList,
    setGapList,
    nclist,
    setNcList,
    handleNCorGapCompliance,
    type,
    setCurrentValue,
    currentValue,
    projectId,
    setNotify,
  } = props;
  const service = type === 'gap' ? 'gap' : 'nc';
  const list = type === 'gap' ? gapList : nclist;
  const [newCompliance, setNewCompliance] = useState({
    name: service,
    newComplianceValue: '',
    newRationaleValue: '',
    submitCheck: true,
    isCompianceDuplicate: false,
    message: '',
  });

  const {
    newComplianceValue,
    newRationaleValue,
    submitCheck,
    message,
    isCompianceDuplicate,
  } = newCompliance;

  const { handleChange, handleNewNCcompliance, handleNewGapcompliance } = useListCompliance({
    service,
    handleNCorGapCompliance,
    list,
    setGapList,
    setNcList,
    setNotify,
  });
  // console.log('gapList', gapList);
  // console.log('nclist', nclist);

  // console.log('newCompliance', newCompliance);
  const validateEnterValues = (value, arrList, key) => {
    // console.log(arrList);
    const arrCheck = arrList.map((eachItem) => {
      return eachItem[key];
    });

    if (arrCheck.includes(value)) {
      return true;
    }

    return false;
  };

  const handleTextFeilds = (event) => {
    const { name, value } = event.target;
    // console.log(value);
    switch (name) {
      case 'gap': {
        if (validateEnterValues(value, gapList, 'name')) {
          setNewCompliance((prev) => ({
            ...prev,
            newComplianceValue: value,
            isCompianceDuplicate: true,
            submitCheck: true,
            message: 'gap name already taken',
          }));
        } else {
          setNewCompliance((prev) => ({
            ...prev,
            newComplianceValue: value,
            isCompianceDuplicate: false,
            submitCheck: false,
          }));
        }
        break;
      }
      case 'nc': {
        if (validateEnterValues(value, nclist, 'name')) {
          setNewCompliance((prev) => ({
            ...prev,
            newComplianceValue: value,
            isCompianceDuplicate: true,
            submitCheck: true,
            message: 'nc name already taken',
          }));
        } else {
          setNewCompliance((prev) => ({
            ...prev,
            newComplianceValue: value,
            isCompianceDuplicate: false,
            submitCheck: false,
          }));
        }
        break;
      }
      case 'rationale': {
        if (validateEnterValues(value, list, 'rationale')) {
          setNewCompliance((prev) => ({
            ...prev,
            newRationaleValue: value,
            submitCheck: true,
            message: 'rationale already taken',
          }));
        } else {
          setNewCompliance((prev) => ({
            ...prev,
            newRationaleValue: value,
            submitCheck: false,
          }));
        }
        break;
      }
      default: {
        break;
      }
    }
  };

  // console.log('ref', ref);
  const handleClick = () => {
    switch (service) {
      case 'nc':
        handleNewNCcompliance(projectId, newComplianceValue, newRationaleValue);
        break;

      case 'gap':
        handleNewGapcompliance(projectId, newComplianceValue, newRationaleValue);
        break;

      default:
        break;
    }
  };
  return (
    <>
      <NCandGapContainer>
        <Grid container direction="column" sm spacing={1}>
          <Grid item>
            <Typography style={{ color: 'gray', fontSize: '1rem' }}>
              Select a {service.toUpperCase()} or create new one
            </Typography>
            <FormControl variant="standard" size="small" sx={{ minWidth: 120 }}>
              <InputLabel color="info" id="demo-simple-select-standard-label">
                {service}
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={currentValue}
                onChange={handleChange}
                color="info"
                label={service}
              >
                {list.map((data, index) => {
                  return (
                    <MenuItem value={data.name} key={index}>
                      {data.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item>
            <Button
              sx={{
                marginBottom: '5px',
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-start',
              }}
              onClick={() => {
                setOpen((prev) => !prev);
              }}
              startIcon={open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
            >
              Create new {service}
            </Button>
            {open && (
              <Grid container sm direction="column" spacing={1}>
                <Grid item>
                  <TextField
                    id="outlined-basic"
                    label={service}
                    name={service}
                    value={newComplianceValue}
                    variant="outlined"
                    color="info"
                    onChange={(event) => {
                      handleTextFeilds(event);
                    }}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    id="outlined-basic"
                    label="Rationale"
                    name="rationale"
                    value={newRationaleValue}
                    disabled={isCompianceDuplicate}
                    variant="outlined"
                    color="info"
                    onChange={(event) => {
                      handleTextFeilds(event);
                    }}
                  />
                </Grid>

                <Grid item sx={{ p: 0 }}>
                  <IconButton
                    color="secondary"
                    disabled={submitCheck || !newCompliance || !newRationaleValue}
                    onClick={() => {
                      handleClick();
                    }}
                  >
                    <AddCircleOutlineIcon />
                  </IconButton>
                  {submitCheck && <Typography style={{ color: 'red' }}>{message}</Typography>}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </NCandGapContainer>
    </>
  );
};

export default memo(NCandGAP);
