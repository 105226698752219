import { Add } from '@mui/icons-material';
import { Fab, FabProps } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

const baseBackground = '#19AB6C';
const StyledNewIdeaFab = styled(Fab)<FabProps>(({ theme }) => ({
  '&.MuiFab-root': {
    background: alpha(baseBackground, 0.85),
    color: alpha(theme.palette.common.white, 0.85),
    '&:hover': {
      background: alpha(baseBackground, 1),
      color: 'white',
    },
  },
}));

const NewIdeaFab: React.FC<FabProps> = ({ onClick, title, sx }) => {
  return (
    <StyledNewIdeaFab variant="extended" onClick={onClick} title={title} sx={sx}>
      <Add sx={{ mr: 1 }} />
      New Idea
    </StyledNewIdeaFab>
  );
};

export default NewIdeaFab;
