import React from 'react';
import { Tooltip, tooltipClasses } from '@mui/material';
import { styled } from '@mui/styles';
import InfoIcon from '@material-ui/icons/Info'; // assume appropriate import for InfoIcon
import { makeStyles } from '@material-ui/core/styles';

// const CustomTooltip = styled(({ className, ...props }) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))(({ theme }) => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     backgroundColor: '#EEF1F9',
//     color: '#000', // Adjust text color as needed
//     fontSize: 12,
//     border: '1px solid #dadde9',
//     maxHeight: '250px', // Adjust the maximum height as needed
//     overflowY: 'auto', // Enables vertical scrolling
//   },
// }));

const TooltipComponent = ({ message, classes }) => {
  const filterString = message?._internal?.filters || '';

  // Splitting based on ` and` which seems to be our approximation for the pattern.
  const rawFilterPairs = filterString?.split(' and ');

  const filterPairs = [];
  // filterString.split(' and ').flatMap((part) => part.split(' or '));

  // Since we have mismatched parentheses, we will only add pairs correctly.
  rawFilterPairs?.forEach((part) => {
    part?.split(' or ')?.forEach((subPart) => {
      const trimmedPart = subPart.trim();
      // Ensure each filter component starts and ends properly.
      if (trimmedPart.startsWith('(') && trimmedPart.endsWith(')')) {
        filterPairs.push(trimmedPart);
      } else if (trimmedPart.startsWith('(')) {
        filterPairs.push(`${trimmedPart})`);
      } else if (trimmedPart.endsWith(')')) {
        filterPairs.push(`(${trimmedPart}`);
      } else {
        filterPairs.push(`(${trimmedPart})`);
      }
    });
  });

  const filterMap = {};

  filterPairs?.forEach((filter) => {
    const match = filter?.match(/\((.*?) eq ['"](.*?)['"]\)/);
    if (match) {
      const key = match[1]?.trim().replace(/\[(.*?)\]/, '$1') || '';
      const value = match[2]?.trim() || '';
      if (key) {
        if (!filterMap[key]) filterMap[key] = [];
        filterMap[key].push(value);
      }
    }
  });

  return (
    <Tooltip
      title={
        message?._internal?.filters ? (
          <div
            style={{
              padding: '10px',
              maxWidth: '250px',
              textAlign: 'center',
            }}
          >
            <h4 style={{ margin: 0, fontWeight: 'bold', textDecoration: 'underline' }}>
              Filter used
            </h4>
            {Object.keys(filterMap).map((key, index) => (
              <div key={index} style={{ marginBottom: '3px', textAlign: 'left' }}>
                <span style={{ fontWeight: 'bold' }}>{key}:</span> {filterMap[key].join(', ')}
              </div>
            ))}
          </div>
        ) : (
          'No filters applied'
        )
      }
      arrow
      PopperProps={{
        sx: {
          '& .MuiTooltip-tooltip': {
            backgroundColor: '#EEF1F9',
            color: '#000',
            fontSize: 12,
            maxHeight: '250px',
            overflowY: 'auto',
            border: 'none', // Remove the default border
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Optional: Customize box shadow
          },
        },
      }}
    >
      <InfoIcon className={classes.btnThumbIcon} style={{ marginRight: '10px' }} />
    </Tooltip>
  );
};

export default TooltipComponent;
