/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { useContext } from 'react';
import { Avatar, Button, FormControl, Grid, makeStyles } from '@material-ui/core';
import MultiSelect from 'react-multi-select-component';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { Box, IconButton } from '@mui/material';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import {
  iSearcheSixLayerNew,
  textToText,
  textToTextInitialFilter,
  RexFiltersNew,
} from '../../../../api/module2';
import M2GlobalContext from '../../../ContextMager/M2GlobalContext';
import { textAnalysis } from '../../../../components/Filter/textAnalysis';

const useStyles = makeStyles((theme: Theme) => ({
  containerNew: {
    display: 'flex',
    alignItems: 'center',
  },
  iconButtonNew: {
    marginLeft: theme.spacing(1),
  },
  multiselect: {
    '& .select-panel': {
      // overflowY: 'scroll',
      maxHeight: '22.5vh',
      overflowX: 'hidden',
    },
    '& .options': {
      overflowY: 'unset',
    },
  },
  formControlMulti: {
    minWidth: 220,
    maxWidth: 220,
  },
  formControlMultiTextlebel: {
    marginTop: 10,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 160,
  },
}));

const PrivateGenAFilter = ({ ChatGptFilter }: any) => {
  const classes = textAnalysis();
  const selClasses = useStyles();
  const { state, dispatch } = useContext(M2GlobalContext);
  const { similarityFilter } = state;

  const {
    simi_AlstomEntitiesDef,
    simi_AlstomEntities,
    simi_FilterProjectsDef,
    simi_FilterProjects,
    simi_FilterDocumentProfilesDef,
    simi_FilterDocumentProfiles,
    simi_FilterPlatformsDef,
    simi_FilterPlatforms,
    simi_FilterSolution,
    simi_FilterProduct,
    dropDown1,
    dropDown2,
    dropDown3,
    dropDown4,
    dropDown5,
    dropDown6,
  } = similarityFilter;

  const onChangeEntity = (option: any) => {
    dispatch({ type: 'change_simi_entity', payload: option });
    // setFilt1(option);
    const extra = 'change_entity';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    multifilterCall(option, dropDown2, dropDown3, dropDown4, dropDown5, dropDown6, extra, 1);
  };

  const onChangePlatform = (option: any) => {
    dispatch({ type: 'change_simi_Platform', payload: option });
    // setFilt2(option);
    const extra = 'change_platform';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    multifilterCall(dropDown1, option, dropDown3, dropDown4, dropDown5, dropDown6, extra, 1);
  };

  const onChangeSolutionNew = (option: any) => {
    dispatch({ type: 'change_simi_Solution', payload: option });
    // setFilt1(option);
    const extra = 'change_Solution';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    multifilterCall(dropDown1, dropDown2, dropDown3, dropDown4, option, dropDown6, extra, 1);
  };

  const onChangeProductNew = (option: any) => {
    dispatch({ type: 'change_simi_Product', payload: option });
    // setFilt1(option);
    const extra = 'change_Product';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    multifilterCall(dropDown1, dropDown2, dropDown3, dropDown4, dropDown5, option, extra, 1);
  };

  const onChangeProject = (option: any) => {
    dispatch({ type: 'change_simi_Project', payload: option });
    // setFilt3(option);
    const extra = 'change_project';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    multifilterCall(dropDown1, dropDown2, option, dropDown4, dropDown5, dropDown6, extra, 1);
  };

  const onChangeDocumentProfile = (option: any) => {
    dispatch({ type: 'change_simi_DocumentProfile', payload: option });
    // setFilt4(option);
    const extra = 'change_documentProfile';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    multifilterCall(dropDown1, dropDown2, dropDown3, option, dropDown5, dropDown6, extra, 1);
  };

  function multifilterCall(
    en: any,
    plat: any,
    proj: any,
    docprof: any,
    sol: any,
    prod: any,
    extra: any,
    m: any
  ) {
    const entityVal: any = [];
    const platformVal: any = [];
    const solutionVal: any = [];
    const productVal: any = [];
    const projectVal: any = [];
    const documentProfileVal: any = [];
    // const countryVal: any = [];
    en.forEach((o: any) => {
      entityVal.push(o.value);
    });
    plat.forEach((o: any) => {
      platformVal.push(o.value);
    });
    sol.forEach((o: any) => {
      solutionVal.push(o.value);
    });
    prod.forEach((o: any) => {
      productVal.push(o.value);
    });
    proj.forEach((o: any) => {
      projectVal.push(o.value);
    });
    docprof.forEach((o: any) => {
      documentProfileVal.push(o.value);
    });
    // cont.forEach((o: any) => {
    //   countryVal.push(o.value);
    // });
    // console.log(m);
    if (m === 1) {
      textToText(entityVal, platformVal, solutionVal, productVal, projectVal, documentProfileVal)
        .then((payload: any) => {
          if (payload) {
            // console.log(payload);
            const entityAfterFilter: any = [];
            // eslint-disable-next-line guard-for-in
            for (const i in payload.filters?.alstomEntities) {
              const item = payload.filters.alstomEntities[i];
              entityAfterFilter.push({
                label: item,
                value: item,
              });
            }
            // if (extra !== 'noChange_entity') {
            //   setAlstomEntities(entityAfterFilter);
            // }
            const platformAfterFilter: any = [];
            // eslint-disable-next-line guard-for-in
            for (const i in payload.filters?.platforms) {
              const item = payload.filters.platforms[i];
              platformAfterFilter.push({
                label: item,
                value: item,
              });
            }

            const solutionAfterFilter: any = [];
            // eslint-disable-next-line guard-for-in
            for (const i in payload.filters?.solutions) {
              const item = payload.filters.solutions[i];
              solutionAfterFilter.push({
                label: item,
                value: item,
              });
            }

            const productAfterFilter: any = [];
            // eslint-disable-next-line guard-for-in
            for (const i in payload.filters?.products) {
              const item = payload.filters.products[i];
              productAfterFilter.push({
                label: item,
                value: item,
              });
            }

            // if (extra !== 'noChange_platform') {
            //   setFilterPlatforms(platformAfterFilter);
            // }
            const projectAfterFilter: any = [];
            // eslint-disable-next-line guard-for-in
            for (const i in payload.filters?.projects) {
              const item = payload.filters.projects[i];
              projectAfterFilter.push({
                label: item,
                value: item,
              });
            }
            // if (extra !== 'noChange_project') {
            //   setFilterProjects(projectAfterFilter);
            // }
            const documentProfileAfterFilter: any = [];
            // eslint-disable-next-line guard-for-in
            for (const i in payload.filters?.documentProfiles) {
              const item = payload.filters.documentProfiles[i];
              documentProfileAfterFilter.push({
                label: item,
                value: item,
              });
            }
            // if (extra !== 'noChange_documentProfile') {
            //   setFilterDocumentProfiles(documentProfileAfterFilter);
            // }

            switch (extra) {
              case 'change_entity': {
                const obj = {
                  filterPlatforms: platformAfterFilter,
                  filterProjects: projectAfterFilter,
                  filterDocumentProfiles: documentProfileAfterFilter,
                  filterSolution: solutionAfterFilter,
                  filterProduct: productAfterFilter,
                };
                dispatch({ type: 'simi_noChange_in_entity', payload: obj });

                const obj2 = {
                  filterPlatforms: platformAfterFilter,
                  filterSolution: solutionAfterFilter,
                  filterProduct: productAfterFilter,
                  filterProjects: projectAfterFilter,
                  filterDocumentProfiles: documentProfileAfterFilter,
                  filterCountry: [],
                };
                dispatch({ type: 'noChange_in_productLine', payload: obj2 });

                break;
              }

              case 'change_platform': {
                const obj = {
                  alstomEntities: entityAfterFilter,
                  filterProjects: projectAfterFilter,
                  filterDocumentProfiles: documentProfileAfterFilter,
                  filterSolution: solutionAfterFilter,
                  filterProduct: productAfterFilter,
                };
                dispatch({ type: 'simi_noChange_in_platform', payload: obj });

                const obj2 = {
                  productLine: entityAfterFilter,
                  filterSolution: solutionAfterFilter,
                  filterProduct: productAfterFilter,
                  filterProjects: projectAfterFilter,
                  filterDocumentProfiles: documentProfileAfterFilter,
                  filterCountry: [],
                };
                dispatch({ type: 'noChange_in_platform', payload: obj2 });
                break;
              }

              case 'change_solution': {
                const obj = {
                  alstomEntities: entityAfterFilter,
                  filterPlatforms: platformAfterFilter,
                  filterProjects: projectAfterFilter,
                  filterDocumentProfiles: documentProfileAfterFilter,
                  filterProduct: productAfterFilter,
                };
                dispatch({ type: 'simi_noChange_in_solution', payload: obj });

                const obj2 = {
                  productLine: entityAfterFilter,
                  filterPlatforms: platformAfterFilter,
                  filterProduct: productAfterFilter,
                  filterProjects: projectAfterFilter,
                  filterDocumentProfiles: documentProfileAfterFilter,
                  filterCountry: [],
                };
                dispatch({ type: 'noChange_in_Solution', payload: obj2 });

                break;
              }

              case 'change_product': {
                const obj = {
                  alstomEntities: entityAfterFilter,
                  filterPlatforms: platformAfterFilter,
                  filterProjects: projectAfterFilter,
                  filterDocumentProfiles: documentProfileAfterFilter,
                  filterSolution: solutionAfterFilter,
                };
                dispatch({ type: 'simi_noChange_in_product', payload: obj });

                const obj2 = {
                  productLine: entityAfterFilter,
                  filterPlatforms: platformAfterFilter,
                  filterSolution: solutionAfterFilter,
                  filterProjects: projectAfterFilter,
                  filterDocumentProfiles: documentProfileAfterFilter,
                  filterCountry: [],
                };
                dispatch({ type: 'noChange_in_Product', payload: obj2 });

                break;
              }

              case 'change_project': {
                const obj = {
                  alstomEntities: entityAfterFilter,
                  filterPlatforms: platformAfterFilter,
                  filterDocumentProfiles: documentProfileAfterFilter,
                  filterSolution: solutionAfterFilter,
                  filterProduct: productAfterFilter,
                };
                dispatch({ type: 'simi_noChange_in_project', payload: obj });

                const obj2 = {
                  productLine: entityAfterFilter,
                  filterPlatforms: platformAfterFilter,
                  filterSolution: solutionAfterFilter,
                  filterProduct: productAfterFilter,
                  filterDocumentProfiles: documentProfileAfterFilter,
                  filterCountry: [],
                };
                dispatch({ type: 'noChange_in_project', payload: obj2 });

                break;
              }

              case 'change_documentProfile': {
                const obj = {
                  alstomEntities: entityAfterFilter,
                  filterPlatforms: platformAfterFilter,
                  filterProjects: projectAfterFilter,
                  filterSolution: solutionAfterFilter,
                  filterProduct: productAfterFilter,
                };

                dispatch({ type: 'simi_noChange_in_documentProfile', payload: obj });

                const obj2 = {
                  productLine: entityAfterFilter,
                  filterPlatforms: platformAfterFilter,
                  filterSolution: solutionAfterFilter,
                  filterProduct: productAfterFilter,
                  filterProjects: projectAfterFilter,
                  filterCountry: [],
                };
                dispatch({ type: 'noChange_in_documentProfile', payload: obj2 });

                break;
              }
              default:
                break;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    }
  }

  // multiselect dropdown custom labels
  // eslint-disable-next-line consistent-return
  const customEntityValue = () => {
    if (!dropDown1?.length) {
      return <b style={{ color: '#002A45' }}>Product Line</b>;
    }
  };
  // eslint-disable-next-line consistent-return
  const customPlatformValue = () => {
    if (!dropDown2?.length) {
      return <b style={{ color: '#002A45' }}>Platform</b>;
    }
  };
  // eslint-disable-next-line consistent-return
  const customSolutionValueRET = () => {
    if (!dropDown5.length) {
      return <b style={{ color: '#002A45' }}>Solution</b>;
    }
  };
  // eslint-disable-next-line consistent-return
  const customProductValueRET = () => {
    if (!dropDown6.length) {
      return <b style={{ color: '#002A45' }}>Product</b>;
    }
  };

  // eslint-disable-next-line consistent-return
  const customProjectValue = () => {
    if (!dropDown3.length) {
      return <b style={{ color: '#002A45' }}>Project</b>;
    }
  };
  // eslint-disable-next-line consistent-return
  const customDocumentProfileValue = () => {
    if (!dropDown4.length) {
      return <b style={{ color: '#002A45' }}>Document Profile</b>;
    }
  };

  // Clearing filers for content search, expert search, people search
  function ClearFilter() {
    dispatch({ type: 'clear_simiFilters' });
  }

  return (
    <Grid
      style={{
        padding: 5,
        backgroundColor: '#EEF1F9',
        marginTop: 0,
      }}
    >
      <Grid spacing={1} className={selClasses.containerNew}>
        <Grid item xs={6}>
          <Grid item xs={2} style={{ padding: 5 }}>
            <FormControl variant="outlined" className={selClasses.formControlMulti}>
              <MultiSelect
                options={simi_AlstomEntities ?? []}
                labelledBy="Entities"
                isCreatable={true}
                value={dropDown1 ?? []}
                onChange={onChangeEntity}
                valueRenderer={customEntityValue}
                overrideStrings={{
                  allItemsAreSelected: dropDown1
                    .map((item) => {
                      return item?.value;
                    })
                    .join(','),
                }}
                className={classes.formControlMulti1}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2} style={{ padding: 5 }}>
            <FormControl variant="outlined" className={selClasses.formControlMulti}>
              <MultiSelect
                options={simi_FilterPlatforms}
                labelledBy="Select"
                isCreatable={true}
                value={dropDown2 ?? []}
                onChange={onChangePlatform}
                valueRenderer={customPlatformValue}
                overrideStrings={{
                  allItemsAreSelected: dropDown2
                    .map((item) => {
                      return item?.value;
                    })
                    .join(','),
                }}
                className={classes.formControlMulti1}
              />
            </FormControl>
          </Grid>

          <Grid item xs={2} style={{ padding: 5 }}>
            <FormControl variant="outlined" className={selClasses.formControlMulti}>
              <MultiSelect
                options={simi_FilterSolution ?? []}
                labelledBy="Select"
                isCreatable={true}
                value={dropDown5 ?? []}
                onChange={onChangeSolutionNew}
                valueRenderer={customSolutionValueRET}
                overrideStrings={{
                  allItemsAreSelected: dropDown5
                    .map((item) => {
                      return item?.value;
                    })
                    .join(','),
                }}
                className={classes.formControlMulti1}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid item xs={2} style={{ padding: 5 }}>
            <FormControl variant="outlined" className={selClasses.formControlMulti}>
              <MultiSelect
                options={simi_FilterProduct ?? []}
                labelledBy="Select"
                isCreatable={true}
                value={dropDown6 ?? []}
                onChange={onChangeProductNew}
                valueRenderer={customProductValueRET}
                overrideStrings={{
                  allItemsAreSelected: dropDown6
                    .map((item) => {
                      return item?.value;
                    })
                    .join(','),
                }}
                className={classes.formControlMulti1}
              />
            </FormControl>
          </Grid>

          <Grid item xs={2} style={{ padding: 5 }}>
            <FormControl variant="outlined" className={selClasses.formControlMulti}>
              <MultiSelect
                options={simi_FilterProjects ?? []}
                labelledBy="Select"
                isCreatable={true}
                value={dropDown3 ?? []}
                onChange={onChangeProject}
                valueRenderer={customProjectValue}
                overrideStrings={{
                  allItemsAreSelected: dropDown3
                    .map((item) => {
                      return item?.value;
                    })
                    .join(','),
                }}
                className={classes.formControlMulti1}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2} style={{ padding: 5 }}>
            <FormControl variant="outlined" className={selClasses.formControlMulti}>
              <MultiSelect
                options={simi_FilterDocumentProfiles ?? []}
                labelledBy="Select"
                isCreatable={true}
                value={dropDown4 ?? []}
                onChange={onChangeDocumentProfile}
                valueRenderer={customDocumentProfileValue}
                overrideStrings={{
                  allItemsAreSelected: dropDown4
                    .map((item) => {
                      return item?.value;
                    })
                    .join(','),
                }}
                className={classes.formControlMulti1}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ float: 'right' }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<FilterAltOffIcon />}
          onClick={ClearFilter}
          style={{ marginRight: '5px' }}
        >
          Clear Filter
        </Button>
        <br />
      </Grid>
    </Grid>
  );
};

export default PrivateGenAFilter;
