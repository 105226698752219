import {
  Box,
  Button,
  Chip,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';
import HistoryIcon from '@mui/icons-material/History';
import React, { useMemo, useState } from 'react';
import RenderText from '../components/RenderText';
import { charScoreColor, lifeCycle } from '../const/allocatorConst';

const CustomInput = ({ onBlur, Options, data }) => {
  const [value, setValue] = useState(data);

  const changeValue = (event: any) => {
    onBlur(event);
    setValue(event.target.value);
  };

  return (
    <>
      <FormControl variant="standard" sx={{ m: 0, minWidth: 120 }}>
        {/* <InputLabel id="demo-simple-select-standard-label">Age</InputLabel> */}
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={value}
          onChange={changeValue}
          // label="Age"
        >
          {Options?.map((data: any, index: any) => {
            return (
              <MenuItem key={index} value={data}>
                {data}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </>
  );
};

const useColumnData = (props) => {
  const {
    editedRow,
    textColum,
    displayColumns,
    levelsHierarchy,
    hiddenColumns,
    data,
    setEditedRow,
    service,
    type,
    onChangeModalData,
  } = props;

  const hideColumns = useMemo(() => {
    const levelCol = {};

    for (const item of levelsHierarchy) {
      levelCol[item] = false;
    }

    return { ...hiddenColumns, ...levelCol };
  }, [hiddenColumns, levelsHierarchy]);

  // console.log('displayColumns', displayColumns);

  const extractDisplayColumns = useMemo(() => {
    if (displayColumns) {
      return displayColumns
        .filter((data) => data !== textColum)
        .map((item, index) => {
          return {
            header: item,
            accessorKey: `Column_${index + 1}`,
            enableGrouping: false,
            filterFn: (row, id, filterValue) => {
              // console.log('row startsWith', row);
              return (
                row.getValue(id).startsWith(filterValue) || row.getValue(id).includes(filterValue)
              );
            },
            enableEditing: false,
            grow: true,
            size: item.length > 16 ? 500 : 200,
          };
        });
    }
    return [
      {
        header: 'Hierarchy',
        accessorKey: `Hierarchy`,
        enableGrouping: false,
        enableEditing: false,
        grow: true,
        size: 200,
      },
    ];
  }, [displayColumns]);

  // console.log('extractDisplayColumns', extractDisplayColumns);

  const extraColumns = useMemo(() => {
    if (data.length === 0) {
      return [];
    }

    const keysForMetaData = [...Object.keys(hiddenColumns), ...levelsHierarchy];

    const addColumnExcel = displayColumns ?? [];

    const filterdcol = [...keysForMetaData.filter((data) => data !== 'id' && data !== 'Hierarchy')];

    return filterdcol.map((item) => {
      return {
        header: item,
        accessorKey: item,
        enableGrouping: false,
        enableEditing: false,
        grow: true,
        size: 200,
        filterFn: 'startsWith',
      };
    });
  }, [data, levelsHierarchy]);

  // console.log('editedRow', data);

  const columns = useMemo(
    () => [
      {
        header: 'iSenS_ID',
        accessorKey: 'id',
        enableGrouping: false,
        enableEditing: false,
        grow: true,
        filterFn: 'startsWith',
        size: 150,
        Cell: ({ renderedCellValue, row }) => {
          return <Typography color="info">{renderedCellValue}</Typography>;
        },
      },
      {
        header: textColum,
        accessorKey: 'extractedText',
        enableGrouping: false,
        enableEditing: false,
        grow: true,
        size: 550,
        // filterFn: 'startsWith',
        filterFn: (row, id, filterValue) => {
          // console.log('row startsWith', row);
          return row.getValue(id).startsWith(filterValue) || row.getValue(id).includes(filterValue);
        },
        Cell: ({ renderedCellValue, row }) => {
          return <RenderText renderedCellValue={renderedCellValue} row={row.original} />;
        },
      },
      ...extractDisplayColumns,
      {
        header: 'Characterization',
        accessorKey: 'Characterization',
        enableEditing: true,
        editVariant: 'select',
        grow: true,
        enableGrouping: false,
        size: 200,
        Edit: ({ cell, column, row, table }) => {
          const { Characterization } = row.original || [];

          const options = ['Requirement', 'Information'].includes(Characterization)
            ? ['Requirement', 'Information']
            : [Characterization];

          const onBlur = (event) => {
            // eslint-disable-next-line no-param-reassign
            // row._valuesCache.Characterization = event.target.value;
            // if (table.isCreating) {
            //   table.setCreatingRow(row);
            // } else if (table.isEditing) {
            //   table.setEditingRow(row);
            // }
            setEditedRow({
              ...editedRow,
              [row.id]: {
                ...row.original,
                Characterization: event.target.value,
              },
            });
          };

          return (
            <CustomInput
              onBlur={onBlur}
              Options={options}
              data={row.original.Characterization ?? ''}
            />
          );
        },
      },
      {
        header: 'SubType',
        accessorKey: 'SubType',
        enableGrouping: false,
        enableEditing: true,
        grow: true,
        size: 200,
        editVariant: 'select',
        Edit: ({ cell, column, row, table }) => {
          const { SubType } = row._valuesCache || [];

          const options = ['Bullet', 'ParaBullet', 'Heading', 'Paragraph'];
          const onBlur = (event) => {
            // eslint-disable-next-line no-param-reassign
            row._valuesCache.SubType = event.target.value;
            if (table.isCreating) {
              table.setCreatingRow(row);
            } else if (table.isEditing) {
              table.setEditingRow(row);
            }
            setEditedRow({
              ...editedRow,
              [row.id]: {
                ...row.original,
                SubType: event.target.value,
              },
            });
          };

          if (row.original.Characterization === 'Image') {
            // onClick={() => modalImage(row, 'images')}
            return <RenderText renderedCellValue={row._valuesCache.SubType} row={row.original} />;
          }
          //  onClick={() => modalImage(row, 'tables')}
          if (row.original.Characterization === 'Table') {
            return <RenderText renderedCellValue={row._valuesCache.SubType} row={row.original} />;
          }

          return (
            <CustomInput onBlur={onBlur} Options={options} data={row._valuesCache.SubType ?? ''} />
          );
        },
      },
      {
        header: 'Context',
        accessorKey: 'Context',
        enableGrouping: false,
        enableEditing: false,
        grow: true,
        size: 200,
      },
      {
        header: 'Type',
        accessorKey: 'Type',
        enableGrouping: false,
        grow: true,
        size: 200,
      },
      {
        header: 'History',
        accessorKey: 'History',
        enableGrouping: false,
        enableEditing: false,
        grow: true,
        size: 200,
        Cell: ({ renderedCellValue, row }) => {
          if (['Requirement', 'Technical Req'].includes(row?.original?.Characterization)) {
            return (
              <Button
                title="Assigned history"
                onClick={() => {
                  onChangeModalData((prev) => {
                    return {
                      ...prev,
                      allocationHistory: { open: true, requirements: [], keys: row?.original?.id },
                    };
                  });
                }}
              >
                {' '}
                <HistoryIcon />{' '}
              </Button>
            );
          }
          return '';
        },
      },
      {
        header: 'Char_Score',
        accessorKey: 'Char_Score',
        enableGrouping: false,
        grow: true,
        enableEditing: false,
        size: 200,
        Cell: ({ renderedCellValue, row }) => {
          return (
            <>
              <Chip
                sx={{
                  width: 100,
                  backgroundColor: charScoreColor[renderedCellValue],
                  color: 'white',
                }}
                label={renderedCellValue}
              />
            </>
          );
        },
      },
      {
        header: 'kpi_lifecycle',
        accessorKey: 'kpi_lifecycle',
        enableEditing: false,
        enableGrouping: false,
        grow: true,
        size: 200,
        Cell: ({ renderedCellValue, row }) => {
          return (
            <Chip
              sx={{
                width: 100,
                backgroundColor: lifeCycle[row.original.kpi_lifecycle],
                color: 'white',
              }}
              label={row.original.kpi_lifecycle}
            />
          );
        },
      },
    ],
    [editedRow, textColum, extractDisplayColumns]
  );

  const analysisColumns = useMemo(
    () => [
      {
        header: 'iSenS_ID',
        accessorKey: 'id',
        enableGrouping: false,
        enableEditing: false,
        grow: true,
        size: 150,
        Cell: ({ renderedCellValue, row }) => {
          return <Typography color="info">{renderedCellValue}</Typography>;
        },
      },
      {
        header: textColum,
        accessorKey: 'extractedText',
        enableGrouping: false,
        enableEditing: false,
        grow: true,
        size: 550,
        filterFn: 'startsWith',
        Cell: ({ renderedCellValue, row }) => {
          return <RenderText renderedCellValue={renderedCellValue} row={row.original} />;
        },
      },
      ...extractDisplayColumns,
      {
        header: 'Characterization',
        accessorKey: 'Characterization',
        enableEditing: false,
        grow: true,
        enableGrouping: false,
        size: 200,
      },
      {
        header: 'SubType',
        accessorKey: 'SubType',
        enableGrouping: false,
        enableEditing: false,
        grow: true,
        size: 200,
      },
      {
        header: 'Context',
        accessorKey: 'Context',
        enableGrouping: false,
        enableEditing: false,
        grow: true,
        size: 200,
      },
      {
        header: 'Type',
        accessorKey: 'Type',
        enableGrouping: false,
        enableEditing: false,
        grow: true,
        size: 200,
      },
      {
        header: 'History',
        accessorKey: 'History',
        enableGrouping: false,
        enableEditing: false,
        grow: true,
        size: 200,
        Cell: ({ renderedCellValue, row }) => {
          if (['Requirement', 'Technical Req'].includes(row?.original?.Characterization)) {
            return (
              <Button
                title="Assigned history"
                onClick={() => {
                  onChangeModalData((prev) => {
                    return {
                      ...prev,
                      allocationHistory: { open: true, requirements: [], keys: row?.original?.id },
                    };
                  });
                }}
              >
                {' '}
                <HistoryIcon />{' '}
              </Button>
            );
          }
          return '';
        },
      },
      {
        header: 'Char_Score',
        accessorKey: 'Char_Score',
        enableGrouping: false,
        grow: true,
        enableEditing: false,
        size: 200,
        Cell: ({ renderedCellValue, row }) => {
          return (
            <>
              <Chip
                sx={{
                  width: 100,
                  backgroundColor: charScoreColor[renderedCellValue],
                  color: 'white',
                }}
                label={renderedCellValue}
              />
            </>
          );
        },
      },
      {
        header: 'kpi_lifecycle',
        accessorKey: 'kpi_lifecycle',
        enableEditing: false,
        enableGrouping: false,
        grow: true,
        size: 200,
        Cell: ({ renderedCellValue, row }) => {
          return (
            <Chip
              sx={{
                width: 100,
                backgroundColor: lifeCycle[row.original.kpi_lifecycle],
                color: 'white',
              }}
              label={row.original.kpi_lifecycle}
            />
          );
        },
      },
    ],
    [editedRow, textColum, extractDisplayColumns]
  );

  const columnsTableInject = useMemo(() => {
    if (type !== 'pdf' && service !== 'analysis') {
      const removedCol = columns.filter((item) => item.accessorKey !== 'SubType');
      return [...removedCol, ...extraColumns];
    }

    if (type !== 'pdf') {
      const removedCol = analysisColumns.filter((item) => item.accessorKey !== 'SubType');
      return [...removedCol, ...extraColumns];
    }

    if (service !== 'analysis') {
      return [...columns, ...extraColumns];
    }

    return [...analysisColumns, ...extraColumns];
  }, [columns, extraColumns, analysisColumns]);

  return {
    extractedColumns: columnsTableInject,
    hideColumns,
  };
};

export default useColumnData;
