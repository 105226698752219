/**
 * This component renders a Filter page
 *  Get all Data related to M2 inside the Filters
 *  Css also fixed here
 *  new six layer implementation of content,Expert, People search, Requirement, Text and expert done by @ranjan @Rahul
 * filter UI implementation done by @ranjan
 * @param {string} component callOperation params.
 * @returns {ReactNode} A React element that renders Filter Page.
 *  Copyright @ Alstom 2023
 *  Changes done by @Ranjan
 */
import { Box, Button, Grid, Slider, Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import MultiSelect from 'react-multi-select-component';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
// import Module2Context from '../../../../Module2Context';
import './Filters.css';
import { textAnalysis } from '../textAnalysis';
import M2GlobalContext from '../../../containers/ContextMager/M2GlobalContext';
import {
  iSearcherGetFiltersNew,
  iSearcheSixLayerNew,
  RexFiltersNew,
  textToText,
} from '../../../api/module2';

const Filters = ({ callOperation }) => {
  const classes = textAnalysis();
  const { state, dispatch } = useContext(M2GlobalContext);
  const { rexFilter, isearcherFilter, similarityFilter } = state;
  // console.log('star', state);
  const {
    issureSiteDef,
    rexPlatformDef,
    rexSourceDef,
    rexStatusDef,
    rexTypologydef,
    rexProductLineDef,
    rexProductDef,
    rexSolutionDef,
    rexProjectDef,
    rexCountryDef,
    issureSite,
    maxResultsValue: rexMaxValue,
    rexPlatform,
    rexSource,
    rexStatus,
    rexTypology,
    productrexLine,
    product,
    solution,
    project,
    country,
    filterissureSite,
    filterexPlatform,
    filterrexSource,
    filterrexStatus,
    filterTypology,
    // added
    filterrexProductLine,
    filterrexProduct,
    filterrexSolution,
    filterrexProject,
    filterrexCountry,
  } = rexFilter;
  // console.log(
  //   issureSiteDef,
  //   rexPlatformDef,
  //   rexSourceDef,
  //   rexStatusDef,
  //   rexTypologydef,
  //   rexProductLineDef,
  //   rexProductDef,
  //   rexSolutionDef,
  //   rexProjectDef,
  //   rexCountryDef,
  //   issureSite,
  //   // added
  //   filterissureSite,
  //   filterexPlatform,
  //   filterrexSource,
  //   filterrexStatus,
  //   filterTypology,
  //   // added
  //   filterrexProductLine,
  //   filterrexProduct,
  //   filterrexSolution,
  //   filterrexProject,
  //   filterrexCountry
  // );

  const {
    productLineDef,
    productLine,
    filterProjectsDef,
    filterProjects,
    filterDocumentProfilesDef,
    filterDocumentProfiles,
    filterPlatformsDef,
    filterPlatforms,
    filterSolution,
    filterProduct,
    filterCountry,
    artiFact,
    productLinefilt1,
    filterPlatformsfilt2,
    filterProjectsfilt3,
    filterDocumentProfilesfilt4,
    filt5,
    filterSolutionfilt6,
    filterProductfilt7,
    filt8,
    maxResultsValue,
    door,
  } = isearcherFilter;
  // console.log('filt1', filt1);

  // console.log(isearcherFilter);

  const {
    simi_AlstomEntitiesDef,
    simi_AlstomEntities,
    simi_FilterProjectsDef,
    simi_FilterProjects,
    simi_FilterDocumentProfilesDef,
    simi_FilterDocumentProfiles,
    simi_FilterPlatformsDef,
    simi_FilterPlatforms,
    simi_FilterSolution,
    simi_FilterProduct,
    dropDown1,
    dropDown2,
    dropDown3,
    dropDown4,
    dropDown5,
    dropDown6,
    countValue,
    threshold,
  } = similarityFilter;

  function multifilterSimiCall(
    en: any,
    plat: any,
    proj: any,
    docprof: any,
    sol: any,
    prod: any,
    extra: any
  ) {
    const entityVal: any = [];
    const platformVal: any = [];
    const projectVal: any = [];
    const documentProfileVal: any = [];
    const solutionVal: any = [];
    const productVal: any = [];
    en.forEach((o: any) => {
      entityVal.push(o.value);
    });
    plat.forEach((o: any) => {
      platformVal.push(o.value);
    });
    sol.forEach((o: any) => {
      solutionVal.push(o.value);
    });
    prod.forEach((o: any) => {
      productVal.push(o.value);
    });
    proj.forEach((o: any) => {
      projectVal.push(o.value);
    });
    docprof.forEach((o: any) => {
      documentProfileVal.push(o.value);
    });

    textToText(entityVal, platformVal, solutionVal, productVal, projectVal, documentProfileVal)
      .then((payload: any) => {
        if (payload) {
          // console.log(payload);
          const entityAfterFilter: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters?.alstomEntities) {
            const item = payload.filters.alstomEntities[i];
            entityAfterFilter.push({
              label: item,
              value: item,
            });
          }
          // if (extra !== 'noChange_entity') {
          //   setAlstomEntities(entityAfterFilter);
          // }
          const platformAfterFilter: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters?.platforms) {
            const item = payload.filters.platforms[i];
            platformAfterFilter.push({
              label: item,
              value: item,
            });
          }

          const solutionAfterFilter: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters?.solutions) {
            const item = payload.filters.solutions[i];
            solutionAfterFilter.push({
              label: item,
              value: item,
            });
          }

          const productAfterFilter: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters?.products) {
            const item = payload.filters.products[i];
            productAfterFilter.push({
              label: item,
              value: item,
            });
          }

          // if (extra !== 'noChange_platform') {
          //   setFilterPlatforms(platformAfterFilter);
          // }
          const projectAfterFilter: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters?.projects) {
            const item = payload.filters.projects[i];
            projectAfterFilter.push({
              label: item,
              value: item,
            });
          }
          // if (extra !== 'noChange_project') {
          //   setFilterProjects(projectAfterFilter);
          // }
          const documentProfileAfterFilter: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters?.documentProfiles) {
            const item = payload.filters.documentProfiles[i];
            documentProfileAfterFilter.push({
              label: item,
              value: item,
            });
          }
          // if (extra !== 'noChange_documentProfile') {
          //   setFilterDocumentProfiles(documentProfileAfterFilter);
          // }

          switch (extra) {
            case 'change_entity': {
              const obj = {
                filterPlatforms: platformAfterFilter,
                filterProjects: projectAfterFilter,
                filterDocumentProfiles: documentProfileAfterFilter,
                filterSolution: solutionAfterFilter,
                filterProduct: productAfterFilter,
              };
              dispatch({ type: 'simi_noChange_in_entity', payload: obj });
              break;
            }

            case 'change_platform': {
              const obj = {
                alstomEntities: entityAfterFilter,
                filterProjects: projectAfterFilter,
                filterDocumentProfiles: documentProfileAfterFilter,
                filterSolution: solutionAfterFilter,
                filterProduct: productAfterFilter,
              };
              dispatch({ type: 'simi_noChange_in_platform', payload: obj });
              break;
            }

            case 'change_solution': {
              const obj = {
                alstomEntities: entityAfterFilter,
                filterPlatforms: platformAfterFilter,
                filterProjects: projectAfterFilter,
                filterDocumentProfiles: documentProfileAfterFilter,
                filterProduct: productAfterFilter,
              };
              dispatch({ type: 'simi_noChange_in_solution', payload: obj });
              break;
            }

            case 'change_product': {
              const obj = {
                alstomEntities: entityAfterFilter,
                filterPlatforms: platformAfterFilter,
                filterProjects: projectAfterFilter,
                filterDocumentProfiles: documentProfileAfterFilter,
                filterSolution: solutionAfterFilter,
              };
              dispatch({ type: 'simi_noChange_in_product', payload: obj });
              break;
            }

            case 'change_project': {
              const obj = {
                alstomEntities: entityAfterFilter,
                filterPlatforms: platformAfterFilter,
                filterDocumentProfiles: documentProfileAfterFilter,
                filterSolution: solutionAfterFilter,
                filterProduct: productAfterFilter,
              };
              dispatch({ type: 'simi_noChange_in_project', payload: obj });
              break;
            }

            case 'change_documentProfile': {
              const obj = {
                alstomEntities: entityAfterFilter,
                filterPlatforms: platformAfterFilter,
                filterProjects: projectAfterFilter,
                filterSolution: solutionAfterFilter,
                filterProduct: productAfterFilter,
              };
              dispatch({
                type: 'simi_noChange_in_documentProfile',
                payload: obj,
              });
              break;
            }
            default:
              break;
          }
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  }

  function multifilterCall(
    en: any,
    plat: any,
    sol: any,
    prod: any,
    proj: any,
    docprof: any,
    cont: any,
    extra: any,
    m: any
  ) {
    const entityVal: any = [];
    const platformVal: any = [];
    const solutionVal: any = [];
    const productVal: any = [];
    const projectVal: any = [];
    const documentProfileVal: any = [];
    const countryVal: any = [];
    en.forEach((o: any) => {
      entityVal.push(o.value);
    });
    plat.forEach((o: any) => {
      platformVal.push(o.value);
    });
    sol.forEach((o: any) => {
      solutionVal.push(o.value);
    });
    prod.forEach((o: any) => {
      productVal.push(o.value);
    });
    proj.forEach((o: any) => {
      projectVal.push(o.value);
    });
    docprof.forEach((o: any) => {
      documentProfileVal.push(o.value);
    });
    cont.forEach((o: any) => {
      countryVal.push(o.value);
    });
    // console.log(m);
    if (m === 1) {
      iSearcheSixLayerNew(
        entityVal,
        platformVal,
        solutionVal,
        productVal,
        projectVal,
        documentProfileVal,
        countryVal
      )
        .then((payload: any) => {
          if (payload) {
            // console.log(payload);
            const entityAfterFilter: any = [];
            // eslint-disable-next-line guard-for-in
            for (const i in payload.filters?.productLines) {
              const item = payload.filters.productLines[i];
              entityAfterFilter.push({
                label: item,
                value: item,
              });
            }
            // if (extra !== 'noChange_entity') {
            //   setAlstomEntities2(entityAfterFilter);
            // }

            const platformAfterFilter: any = [];
            // eslint-disable-next-line guard-for-in
            for (const i in payload.filters?.platforms) {
              const item = payload.filters.platforms[i];
              platformAfterFilter.push({
                label: item,
                value: item,
              });
            }
            // if (extra !== 'noChange_platform') {
            //   setFilterPlatforms2(platformAfterFilter);
            // }
            const projectAfterFilter: any = [];
            // eslint-disable-next-line guard-for-in
            for (const i in payload.filters?.projects) {
              const item = payload.filters.projects[i];
              projectAfterFilter.push({
                label: item,
                value: item,
              });
            }
            // if (extra !== 'noChange_project') {
            //   setFilterProjects2(projectAfterFilter);
            // }
            const documentProfileAfterFilter: any = [];
            // eslint-disable-next-line guard-for-in
            for (const i in payload.filters?.documentProfiles) {
              const item = payload.filters.documentProfiles[i];
              documentProfileAfterFilter.push({
                label: item,
                value: item,
              });
            }
            const solutionAfterFilter: any = [];
            // eslint-disable-next-line guard-for-in
            for (const i in payload.filters?.solutions) {
              const item = payload.filters.solutions[i];
              solutionAfterFilter.push({
                label: item,
                value: item,
              });
            }
            const productAfterFilter: any = [];
            // eslint-disable-next-line guard-for-in
            for (const i in payload.filters?.products) {
              const item = payload.filters.products[i];
              productAfterFilter.push({
                label: item,
                value: item,
              });
            }
            const countryAfterFilter: any = [];
            // eslint-disable-next-line guard-for-in
            for (const i in payload.filters?.countries) {
              const item = payload.filters.countries[i];
              countryAfterFilter.push({
                label: item,
                value: item,
              });
            }

            switch (extra) {
              case 'change_entity': {
                const obj = {
                  filterPlatforms: platformAfterFilter,
                  filterSolution: solutionAfterFilter,
                  filterProduct: productAfterFilter,
                  filterProjects: projectAfterFilter,
                  filterDocumentProfiles: documentProfileAfterFilter,
                  filterCountry: countryAfterFilter,
                };
                dispatch({ type: 'noChange_in_productLine', payload: obj });
                break;
              }

              case 'change_platform': {
                const obj = {
                  productLine: entityAfterFilter,
                  filterSolution: solutionAfterFilter,
                  filterProduct: productAfterFilter,
                  filterProjects: projectAfterFilter,
                  filterDocumentProfiles: documentProfileAfterFilter,
                  filterCountry: countryAfterFilter,
                };
                dispatch({ type: 'noChange_in_platform', payload: obj });
                break;
              }
              case 'change_Solution': {
                const obj = {
                  productLine: entityAfterFilter,
                  filterPlatforms: platformAfterFilter,
                  filterProduct: productAfterFilter,
                  filterProjects: projectAfterFilter,
                  filterDocumentProfiles: documentProfileAfterFilter,
                  filterCountry: countryAfterFilter,
                };
                dispatch({ type: 'noChange_in_Solution', payload: obj });
                break;
              }
              case 'change_Product': {
                const obj = {
                  productLine: entityAfterFilter,
                  filterPlatforms: platformAfterFilter,
                  filterSolution: solutionAfterFilter,
                  filterProjects: projectAfterFilter,
                  filterDocumentProfiles: documentProfileAfterFilter,
                  filterCountry: countryAfterFilter,
                };
                dispatch({ type: 'noChange_in_Product', payload: obj });
                break;
              }

              case 'change_project': {
                const obj = {
                  productLine: entityAfterFilter,
                  filterPlatforms: platformAfterFilter,
                  filterSolution: solutionAfterFilter,
                  filterProduct: productAfterFilter,
                  filterDocumentProfiles: documentProfileAfterFilter,
                  filterCountry: countryAfterFilter,
                };
                dispatch({ type: 'noChange_in_project', payload: obj });
                break;
              }

              case 'change_documentProfile': {
                const obj = {
                  productLine: entityAfterFilter,
                  filterPlatforms: platformAfterFilter,
                  filterSolution: solutionAfterFilter,
                  filterProduct: productAfterFilter,
                  filterProjects: projectAfterFilter,
                  filterCountry: countryAfterFilter,
                };
                dispatch({ type: 'noChange_in_documentProfile', payload: obj });
                break;
              }
              case 'change_Country': {
                const obj = {
                  productLine: entityAfterFilter,
                  filterPlatforms: platformAfterFilter,
                  filterSolution: solutionAfterFilter,
                  filterProduct: productAfterFilter,
                  filterProjects: projectAfterFilter,
                  filterDocumentProfiles: documentProfileAfterFilter,
                };
                dispatch({ type: 'noChange_in_Country', payload: obj });
                break;
              }
              default:
                break;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    }
  }
  function multifilterRexCardCall(
    issuerD: any,
    rexSourcesD: any,
    statusD: any,
    typoD: any,
    en: any,
    plat: any,
    sol: any,
    prod: any,
    proj: any,
    cont: any,

    extra: any,
    m: any
  ) {
    const IssuerVal: any = [];
    const rexSourcesVal: any = [];
    const statusVal: any = [];
    const typoVal: any = [];
    const entityVal: any = [];
    const platformVal: any = [];
    const solutionVal: any = [];
    const productVal: any = [];
    const projectVal: any = [];
    const countryVal: any = [];
    en.forEach((o: any) => {
      entityVal.push(o.value);
    });
    plat.forEach((o: any) => {
      platformVal.push(o.value);
    });
    sol.forEach((o: any) => {
      solutionVal.push(o.value);
    });
    prod.forEach((o: any) => {
      productVal.push(o.value);
    });
    proj.forEach((o: any) => {
      projectVal.push(o.value);
    });
    // docprof.forEach((o: any) => {
    //   documentProfileVal.push(o.value);
    // });
    cont.forEach((o: any) => {
      countryVal.push(o.value);
    });
    issuerD.forEach((o: any) => {
      IssuerVal.push(o.value);
    });
    rexSourcesD.forEach((o: any) => {
      rexSourcesVal.push(o.value);
    });
    statusD.forEach((o: any) => {
      statusVal.push(o.value);
    });
    typoD.forEach((o: any) => {
      typoVal.push(o.value);
    });
    // console.log(m);
    if (m === 1) {
      RexFiltersNew(
        IssuerVal,
        rexSourcesVal,
        statusVal,
        typoVal,
        entityVal,
        platformVal,
        solutionVal,
        productVal,
        projectVal,
        countryVal
      )
        .then((payload: any) => {
          if (payload) {
            // console.log('rex a', payload);
            const entityAfterFilter: any = [];
            // eslint-disable-next-line guard-for-in
            for (const i in payload.filters?.productLines) {
              const item = payload.filters.productLines[i];
              entityAfterFilter.push({
                label: item,
                value: item,
              });
            }
            // if (extra !== 'noChange_entity') {
            //   setAlstomEntities2(entityAfterFilter);
            // }

            const platformAfterFilter: any = [];
            // eslint-disable-next-line guard-for-in
            for (const i in payload.filters?.platforms) {
              const item = payload.filters.platforms[i];
              platformAfterFilter.push({
                label: item,
                value: item,
              });
            }
            // if (extra !== 'noChange_platform') {
            //   setFilterPlatforms2(platformAfterFilter);
            // }
            const projectAfterFilter: any = [];
            // eslint-disable-next-line guard-for-in
            for (const i in payload.filters?.projects) {
              const item = payload.filters.projects[i];
              projectAfterFilter.push({
                label: item,
                value: item,
              });
            }
            // if (extra !== 'noChange_project') {
            //   setFilterProjects2(projectAfterFilter);
            // }
            const solutionAfterFilter: any = [];
            // eslint-disable-next-line guard-for-in
            for (const i in payload.filters?.solutions) {
              const item = payload.filters.solutions[i];
              solutionAfterFilter.push({
                label: item,
                value: item,
              });
            }
            const productAfterFilter: any = [];
            // eslint-disable-next-line guard-for-in
            for (const i in payload.filters?.products) {
              const item = payload.filters.products[i];
              productAfterFilter.push({
                label: item,
                value: item,
              });
            }
            const countryAfterFilter: any = [];
            // eslint-disable-next-line guard-for-in
            for (const i in payload.filters?.countries) {
              const item = payload.filters.countries[i];
              countryAfterFilter.push({
                label: item,
                value: item,
              });
            }
            const issuerAfterFilter: any = [];
            // eslint-disable-next-line guard-for-in
            for (const i in payload.filters?.sites) {
              const item = payload.filters.sites[i];
              issuerAfterFilter.push({
                label: item,
                value: item,
              });
            }
            const statusesAfterFilter: any = [];
            // eslint-disable-next-line guard-for-in
            for (const i in payload.filters?.statuses) {
              const item = payload.filters.statuses[i];
              statusesAfterFilter.push({
                label: item,
                value: item,
              });
            }
            const sourcesAfterFilter: any = [];
            // eslint-disable-next-line guard-for-in
            for (const i in payload.filters?.sources) {
              const item = payload.filters.sources[i];
              sourcesAfterFilter.push({
                label: item,
                value: item,
              });
            }
            const typologiesAfterFilter: any = [];
            // eslint-disable-next-line guard-for-in
            for (const i in payload.filters?.typologies) {
              const item = payload.filters.typologies[i];
              typologiesAfterFilter.push({
                label: item,
                value: item,
              });
            }

            switch (extra) {
              case 'change_Issuer': {
                const obj = {
                  // issureSiteDef: issuerAfterFilter,
                  // rexSourceDef: sourcesAfterFilter,
                  // rexStatusDef: statusesAfterFilter,
                  // rexTypologydef: typologiesAfterFilter,
                  // rexProductLineDef: entityAfterFilter,
                  // rexPlatformDef: platformAfterFilter,
                  // rexSolutionDef: solutionAfterFilter,
                  // rexProductDef: productAfterFilter,
                  // rexProjectDef: projectAfterFilter,
                  // rexCountryDef: countryAfterFilter,
                  // filterissureSite: issuerAfterFilter,
                  filterexPlatform: platformAfterFilter,
                  filterrexSource: sourcesAfterFilter,
                  filterrexStatus: statusesAfterFilter,
                  filterTypology: typologiesAfterFilter,
                  // added
                  filterrexProductLine: entityAfterFilter,
                  filterrexProduct: productAfterFilter,
                  filterrexSolution: solutionAfterFilter,
                  filterrexProject: projectAfterFilter,
                  filterrexCountry: countryAfterFilter,
                };
                // console.log('obj', obj);
                dispatch({ type: 'nochange_issuer_site', payload: obj });
                break;
              }
              case 'change_Sources': {
                const obj = {
                  // issureSiteDef: issuerAfterFilter,
                  // // rexSourceDef: sourcesAfterFilter,
                  // rexStatusDef: statusesAfterFilter,
                  // rexTypologydef: typologiesAfterFilter,
                  // rexProductLineDef: entityAfterFilter,
                  // rexPlatformDef: platformAfterFilter,
                  // rexSolutionDef: solutionAfterFilter,
                  // rexProductDef: productAfterFilter,
                  // rexProjectDef: projectAfterFilter,
                  // rexCountryDef: countryAfterFilter,
                  filterissureSite: issuerAfterFilter,
                  filterexPlatform: platformAfterFilter,
                  // filterrexSource: sourcesAfterFilter,
                  filterrexStatus: statusesAfterFilter,
                  filterTypology: typologiesAfterFilter,
                  // added
                  filterrexProductLine: entityAfterFilter,
                  filterrexProduct: productAfterFilter,
                  filterrexSolution: solutionAfterFilter,
                  filterrexProject: projectAfterFilter,
                  filterrexCountry: countryAfterFilter,
                };
                dispatch({ type: 'nochange_RexSource', payload: obj });
                break;
              }
              case 'change_Typology': {
                const obj = {
                  // issureSiteDef: issuerAfterFilter,
                  // rexSourceDef: sourcesAfterFilter,
                  // rexStatusDef: statusesAfterFilter,
                  // // rexTypologydef: typologiesAfterFilter,
                  // rexProductLineDef: entityAfterFilter,
                  // rexPlatformDef: platformAfterFilter,
                  // rexSolutionDef: solutionAfterFilter,
                  // rexProductDef: productAfterFilter,
                  // rexProjectDef: projectAfterFilter,
                  // rexCountryDef: countryAfterFilter,
                  filterissureSite: issuerAfterFilter,
                  filterexPlatform: platformAfterFilter,
                  filterrexSource: sourcesAfterFilter,
                  filterrexStatus: statusesAfterFilter,
                  // filterTypology: typologiesAfterFilter,
                  // added
                  filterrexProductLine: entityAfterFilter,
                  filterrexProduct: productAfterFilter,
                  filterrexSolution: solutionAfterFilter,
                  filterrexProject: projectAfterFilter,
                  filterrexCountry: countryAfterFilter,
                };
                dispatch({ type: 'nochange_RexTypology', payload: obj });
                break;
              }
              case 'change_Status': {
                const obj = {
                  // issureSiteDef: issuerAfterFilter,
                  // rexSourceDef: sourcesAfterFilter,
                  // // rexStatusDef: statusesAfterFilter,
                  // rexTypologydef: typologiesAfterFilter,
                  // rexProductLineDef: entityAfterFilter,
                  // rexPlatformDef: platformAfterFilter,
                  // rexSolutionDef: solutionAfterFilter,
                  // rexProductDef: productAfterFilter,
                  // rexProjectDef: projectAfterFilter,
                  // rexCountryDef: countryAfterFilter,
                  filterissureSite: issuerAfterFilter,
                  filterexPlatform: platformAfterFilter,
                  filterrexSource: sourcesAfterFilter,
                  // filterrexStatus: statusesAfterFilter,
                  filterTypology: typologiesAfterFilter,
                  // added
                  filterrexProductLine: entityAfterFilter,
                  filterrexProduct: productAfterFilter,
                  filterrexSolution: solutionAfterFilter,
                  filterrexProject: projectAfterFilter,
                  filterrexCountry: countryAfterFilter,
                };
                dispatch({ type: 'nochange_RexStatus', payload: obj });
                break;
              }
              case 'change_ProductLine': {
                const obj = {
                  // issureSiteDef: issuerAfterFilter,
                  // rexSourceDef: sourcesAfterFilter,
                  // rexStatusDef: statusesAfterFilter,
                  // rexTypologydef: typologiesAfterFilter,
                  // // rexProductLineDef: entityAfterFilter,
                  // rexPlatformDef: platformAfterFilter,
                  // rexSolutionDef: solutionAfterFilter,
                  // rexProductDef: productAfterFilter,
                  // rexProjectDef: projectAfterFilter,
                  // rexCountryDef: countryAfterFilter,
                  filterissureSite: issuerAfterFilter,
                  filterexPlatform: platformAfterFilter,
                  filterrexSource: sourcesAfterFilter,
                  filterrexStatus: statusesAfterFilter,
                  filterTypology: typologiesAfterFilter,
                  // added
                  // filterrexProductLine: entityAfterFilter,
                  filterrexProduct: productAfterFilter,
                  filterrexSolution: solutionAfterFilter,
                  filterrexProject: projectAfterFilter,
                  filterrexCountry: countryAfterFilter,
                };
                dispatch({ type: 'nochange_ProductLine_rex', payload: obj });
                break;
              }

              case 'change_Platform': {
                const obj = {
                  // issureSiteDef: issuerAfterFilter,
                  // rexSourceDef: sourcesAfterFilter,
                  // rexStatusDef: statusesAfterFilter,
                  // rexTypologydef: typologiesAfterFilter,
                  // rexProductLineDef: entityAfterFilter,
                  // // rexPlatformDef: platformAfterFilter,
                  // rexSolutionDef: solutionAfterFilter,
                  // rexProductDef: productAfterFilter,
                  // rexProjectDef: projectAfterFilter,
                  // rexCountryDef: countryAfterFilter,
                  filterissureSite: issuerAfterFilter,
                  // filterexPlatform: platformAfterFilter,
                  filterrexSource: sourcesAfterFilter,
                  filterrexStatus: statusesAfterFilter,
                  filterTypology: typologiesAfterFilter,
                  // added
                  filterrexProductLine: entityAfterFilter,
                  filterrexProduct: productAfterFilter,
                  filterrexSolution: solutionAfterFilter,
                  filterrexProject: projectAfterFilter,
                  filterrexCountry: countryAfterFilter,
                };
                dispatch({ type: 'nochange_RexPlatForm', payload: obj });
                break;
              }
              case 'change_Solution': {
                const obj = {
                  // issureSiteDef: issuerAfterFilter,
                  // rexSourceDef: sourcesAfterFilter,
                  // rexStatusDef: statusesAfterFilter,
                  // rexTypologydef: typologiesAfterFilter,
                  // rexProductLineDef: entityAfterFilter,
                  // rexPlatformDef: platformAfterFilter,
                  // // rexSolutionDef: solutionAfterFilter,
                  // rexProductDef: productAfterFilter,
                  // rexProjectDef: projectAfterFilter,
                  // rexCountryDef: countryAfterFilter,
                  filterissureSite: issuerAfterFilter,
                  filterexPlatform: platformAfterFilter,
                  filterrexSource: sourcesAfterFilter,
                  filterrexStatus: statusesAfterFilter,
                  filterTypology: typologiesAfterFilter,
                  // added
                  filterrexProductLine: entityAfterFilter,
                  filterrexProduct: productAfterFilter,
                  // filterrexSolution: solutionAfterFilter,
                  filterrexProject: projectAfterFilter,
                  filterrexCountry: countryAfterFilter,
                };
                dispatch({ type: 'nochange_Solution_rex', payload: obj });
                break;
              }
              case 'change_Product': {
                const obj = {
                  // issureSiteDef: issuerAfterFilter,
                  // rexSourceDef: sourcesAfterFilter,
                  // rexStatusDef: statusesAfterFilter,
                  // rexTypologydef: typologiesAfterFilter,
                  // rexProductLineDef: entityAfterFilter,
                  // rexPlatformDef: platformAfterFilter,
                  // rexSolutionDef: solutionAfterFilter,
                  // // rexProductDef: productAfterFilter,
                  // rexProjectDef: projectAfterFilter,
                  // rexCountryDef: countryAfterFilter,
                  filterissureSite: issuerAfterFilter,
                  filterexPlatform: platformAfterFilter,
                  filterrexSource: sourcesAfterFilter,
                  filterrexStatus: statusesAfterFilter,
                  filterTypology: typologiesAfterFilter,
                  // added
                  filterrexProductLine: entityAfterFilter,
                  // filterrexProduct: productAfterFilter,
                  filterrexSolution: solutionAfterFilter,
                  filterrexProject: projectAfterFilter,
                  filterrexCountry: countryAfterFilter,
                };
                dispatch({ type: 'nochange_Product_rex', payload: obj });
                break;
              }

              case 'change_Project': {
                const obj = {
                  // issureSiteDef: issuerAfterFilter,
                  // rexSourceDef: sourcesAfterFilter,
                  // rexStatusDef: statusesAfterFilter,
                  // rexTypologydef: typologiesAfterFilter,
                  // rexProductLineDef: entityAfterFilter,
                  // rexPlatformDef: platformAfterFilter,
                  // rexSolutionDef: solutionAfterFilter,
                  // rexProductDef: productAfterFilter,
                  // // rexProjectDef: projectAfterFilter,
                  // rexCountryDef: countryAfterFilter,
                  filterissureSite: issuerAfterFilter,
                  filterexPlatform: platformAfterFilter,
                  filterrexSource: sourcesAfterFilter,
                  filterrexStatus: statusesAfterFilter,
                  filterTypology: typologiesAfterFilter,
                  // added
                  filterrexProductLine: entityAfterFilter,
                  filterrexProduct: productAfterFilter,
                  filterrexSolution: solutionAfterFilter,
                  // filterrexProject: projectAfterFilter,
                  filterrexCountry: countryAfterFilter,
                };
                dispatch({ type: 'nochange_Project_rex', payload: obj });
                break;
              }
              case 'change_Country': {
                const obj = {
                  // issureSiteDef: issuerAfterFilter,
                  // rexSourceDef: sourcesAfterFilter,
                  // rexStatusDef: statusesAfterFilter,
                  // rexTypologydef: typologiesAfterFilter,
                  // rexProductLineDef: entityAfterFilter,
                  // rexPlatformDef: platformAfterFilter,
                  // rexSolutionDef: solutionAfterFilter,
                  // rexProductDef: productAfterFilter,
                  // rexProjectDef: projectAfterFilter,
                  // // rexCountryDef: countryAfterFilter,
                  filterissureSite: issuerAfterFilter,
                  filterexPlatform: platformAfterFilter,
                  filterrexSource: sourcesAfterFilter,
                  filterrexStatus: statusesAfterFilter,
                  filterTypology: typologiesAfterFilter,
                  // added
                  filterrexProductLine: entityAfterFilter,
                  filterrexProduct: productAfterFilter,
                  filterrexSolution: solutionAfterFilter,
                  filterrexProject: projectAfterFilter,
                  // filterrexCountry: countryAfterFilter,
                };
                dispatch({ type: 'nochange_Country_rex', payload: obj });
                break;
              }
              default:
                break;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    }
  }

  const handleCountChange = (event: any, newValue: any) => {
    // marks[1] = {
    //   value: newValue,
    //   label: newValue,
    // };
    dispatch({ type: 'simi_change_count', payload: newValue });
    // setCountValue(newValue);
  };
  const handleThresholdChange = (event: any, newValue: any) => {
    // marksSliderSec[1] = {
    //   value: newValue,
    //   label: newValue,
    // };
    dispatch({ type: 'simi_change_Threshold', payload: newValue });
    // setThreshold(newValue);
  };

  // multiselect dropdown custom labels
  // eslint-disable-next-line consistent-return
  const customEntityValue = () => {
    if (!(productLinefilt1.length || dropDown1.length)) {
      return <b style={{ color: '#002A45' }}>Product Line</b>;
    }
  };
  // eslint-disable-next-line consistent-return
  const customPlatformValue = () => {
    if (!(filterPlatformsfilt2.length || dropDown2.length || rexPlatform.length)) {
      return <b style={{ color: '#002A45' }}>Platform</b>;
    }
  };
  // eslint-disable-next-line consistent-return
  const customSolutionValue = () => {
    if (!dropDown5.length || !filterSolutionfilt6.length) {
      return <b style={{ color: '#002A45' }}>Solution</b>;
    }
  };
  // eslint-disable-next-line consistent-return
  const customSolutionValueCPE = () => {
    if (!filterSolutionfilt6.length) {
      return <b style={{ color: '#002A45' }}>Solution</b>;
    }
  };
  // eslint-disable-next-line consistent-return
  const customProductValueCPE = () => {
    if (!filterProductfilt7.length) {
      return <b style={{ color: '#002A45' }}>Product</b>;
    }
  };
  // eslint-disable-next-line consistent-return
  const customSolutionValueRET = () => {
    if (!filterSolutionfilt6.length) {
      return <b style={{ color: '#002A45' }}>Solution</b>;
    }
  };
  // eslint-disable-next-line consistent-return
  const customProductValueRET = () => {
    if (!filterProductfilt7.length) {
      return <b style={{ color: '#002A45' }}>Product</b>;
    }
  };

  // eslint-disable-next-line consistent-return
  const customProductValue = () => {
    if (!dropDown6.length || !filterProductfilt7.length) {
      return <b style={{ color: '#002A45' }}>Product</b>;
    }
  };
  // eslint-disable-next-line consistent-return
  const customCountryValue = () => {
    if (!filt8.length) {
      return <b style={{ color: '#002A45' }}>Country</b>;
    }
  };
  // eslint-disable-next-line consistent-return
  const customCountryValueCPE = () => {
    if (!filt8.length) {
      return <b style={{ color: '#002A45' }}>Country</b>;
    }
  };

  // eslint-disable-next-line consistent-return
  const customProjectValue = () => {
    if (!(filterProjectsfilt3.length || dropDown3.length)) {
      return <b style={{ color: '#002A45' }}>Project</b>;
    }
  };
  // eslint-disable-next-line consistent-return
  const customDocumentProfileValue = () => {
    if (!(filterDocumentProfilesfilt4.length || dropDown4.length)) {
      return <b style={{ color: '#002A45' }}>Document Profile</b>;
    }
  };
  // eslint-disable-next-line consistent-return
  const customArtifactValue = () => {
    if (!filt5.length) {
      return <b style={{ color: '#002A45' }}>Artifact</b>;
    }
  };
  // eslint-disable-next-line consistent-return
  const customIsserSite = () => {
    if (!issureSite.length) {
      return <b style={{ color: '#002A45' }}>Issuer Site</b>;
    }
  };
  // eslint-disable-next-line consistent-return
  const customProductLineRex = () => {
    if (!productrexLine.length) {
      return <b style={{ color: '#002A45' }}>Product Line</b>;
    }
  };
  // eslint-disable-next-line consistent-return
  const customSolutionsRex = () => {
    if (!solution.length) {
      return <b style={{ color: '#002A45' }}>Solution</b>;
    }
  };
  // eslint-disable-next-line consistent-return
  const customProductRex = () => {
    if (!product.length) {
      return <b style={{ color: '#002A45' }}>Product</b>;
    }
  };
  // eslint-disable-next-line consistent-return
  const customProjectRex = () => {
    if (!project.length) {
      return <b style={{ color: '#002A45' }}>Project</b>;
    }
  };
  // eslint-disable-next-line consistent-return
  const customCountryRex = () => {
    if (!country.length) {
      return <b style={{ color: '#002A45' }}>Country of destination</b>;
    }
  };
  // eslint-disable-next-line consistent-return
  const customRexSource = () => {
    if (!rexSource.length) {
      return <b style={{ color: '#002A45' }}>Rex Source</b>;
    }
  };
  // eslint-disable-next-line consistent-return
  const customStatus = () => {
    if (!rexStatus.length) {
      return <b style={{ color: '#002A45' }}>Status</b>;
    }
  };
  // eslint-disable-next-line consistent-return
  const customTypology = () => {
    if (!rexTypology.length) {
      return <b style={{ color: '#002A45' }}>Typology</b>;
    }
  };
  const onChangeIssuerSite = (option: any) => {
    // console.log('option', option);
    dispatch({ type: 'change_issuer_site', payload: option });
    const extra = 'change_Issuer';
    // setFilt1REX(option);
    multifilterRexCardCall(
      option,
      rexSource,
      rexStatus,
      rexTypology,
      productrexLine,
      rexPlatform,
      solution,
      product,
      project,
      country,
      extra,
      1
    );
  };
  const onChangeRexSource = (option: any) => {
    dispatch({ type: 'change_RexSource', payload: option });
    // setFilt3REX(option);
    const extra = 'change_Sources';
    multifilterRexCardCall(
      issureSite,
      option,
      rexStatus,
      rexTypology,
      productrexLine,
      rexPlatform,
      solution,
      product,
      project,
      country,
      extra,
      1
    );
  };
  const onChangeRexStatus = (option: any) => {
    dispatch({ type: 'change_RexStatus', payload: option });
    // setFilt4REX(option);
    const extra = 'change_Status';
    multifilterRexCardCall(
      issureSite,
      rexSource,
      option,
      rexTypology,
      productrexLine,
      rexPlatform,
      solution,
      product,
      project,
      country,
      extra,
      1
    );
  };
  const onChangeRexTypology = (option: any) => {
    dispatch({ type: 'change_RexTypology', payload: option });
    // setFilt5REX(option);
    const extra = 'change_Typology';
    multifilterRexCardCall(
      issureSite,
      rexSource,
      rexStatus,
      option,
      productrexLine,
      rexPlatform,
      solution,
      product,
      project,
      country,
      extra,
      1
    );
  };
  const onChangeProductLineRex = (option: any) => {
    dispatch({ type: 'change_ProductLine_rex', payload: option });
    const extra = 'change_ProductLine';
    // setFilt1REX(option);
    multifilterRexCardCall(
      issureSite,
      rexSource,
      rexStatus,
      rexTypology,
      option,
      rexPlatform,
      solution,
      product,
      project,
      country,
      extra,
      1
    );
  };
  const onChangeRexPlatForm = (option: any) => {
    dispatch({ type: 'change_RexPlatForm', payload: option });
    // setFilt2REX(option);
    const extra = 'change_Platform';
    multifilterRexCardCall(
      issureSite,
      rexSource,
      rexStatus,
      rexTypology,
      productrexLine,
      option,
      solution,
      product,
      project,
      country,
      extra,
      1
    );
  };

  const onChangeSolutionRex = (option: any) => {
    dispatch({ type: 'change_Solution_rex', payload: option });
    // setFilt1REX(option);
    const extra = 'change_Solution';
    multifilterRexCardCall(
      issureSite,
      rexSource,
      rexStatus,
      rexTypology,
      productrexLine,
      rexPlatform,
      option,
      product,
      project,
      country,
      extra,
      1
    );
  };
  const onChangeProductRex = (option: any) => {
    dispatch({ type: 'change_Product_rex', payload: option });
    // setFilt1REX(option);
    const extra = 'change_Product';
    multifilterRexCardCall(
      issureSite,
      rexSource,
      rexStatus,
      rexTypology,
      productrexLine,
      rexPlatform,
      solution,
      option,
      project,
      country,
      extra,
      1
    );
  };
  const onChangeProjectRex = (option: any) => {
    dispatch({ type: 'change_Project_rex', payload: option });
    // setFilt1REX(option);
    const extra = 'change_Project';
    multifilterRexCardCall(
      issureSite,
      rexSource,
      rexStatus,
      rexTypology,
      productrexLine,
      rexPlatform,
      solution,
      product,
      option,
      country,
      extra,
      1
    );
  };

  const onChangeCountryRex = (option: any) => {
    dispatch({ type: 'change_Country_rex', payload: option });
    // setFilt1REX(option);
    const extra = 'change_Country';
    multifilterRexCardCall(
      issureSite,
      rexSource,
      rexStatus,
      rexTypology,
      productrexLine,
      rexPlatform,
      solution,
      product,
      project,
      option,
      extra,
      1
    );
  };

  const onChangeEntity = (option: any) => {
    dispatch({ type: 'isearchser_productLine_change', payload: option });
    // setFilt1(option);
    const extra = 'change_entity';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    multifilterCall(
      option,
      filterPlatformsfilt2,
      filterSolutionfilt6,
      filterProductfilt7,
      filterProjectsfilt3,
      filterDocumentProfilesfilt4,
      filt8,
      extra,
      1
    );
  };

  const onChangePlatform = (option: any) => {
    dispatch({ type: 'isearchser_Platform_change', payload: option });
    // setFilt2(option);
    const extra = 'change_platform';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    multifilterCall(
      productLinefilt1,
      option,
      filterSolutionfilt6,
      filterProductfilt7,
      filterProjectsfilt3,
      filterDocumentProfilesfilt4,
      filt8,
      extra,
      1
    );
  };
  const onChangeSolutionNew = (option: any) => {
    dispatch({ type: 'isearchser_Solution_change', payload: option });
    // setFilt1(option);
    const extra = 'change_Solution';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    multifilterCall(
      productLinefilt1,
      filterPlatformsfilt2,
      option,
      filterProductfilt7,
      filterProjectsfilt3,
      filterDocumentProfilesfilt4,
      filt8,
      extra,
      1
    );
  };
  const onChangeProductNew = (option: any) => {
    dispatch({ type: 'isearchser_Product_change', payload: option });
    // setFilt1(option);
    const extra = 'change_Product';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    multifilterCall(
      productLinefilt1,
      filterPlatformsfilt2,
      filterSolutionfilt6,
      option,
      filterProjectsfilt3,
      filterDocumentProfilesfilt4,
      filt8,
      extra,
      1
    );
  };

  const onChangeProject = (option: any) => {
    dispatch({ type: 'isearchser_Project_change', payload: option });
    // setFilt3(option);
    const extra = 'change_project';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    multifilterCall(
      productLinefilt1,
      filterPlatformsfilt2,
      filterSolutionfilt6,
      filterProductfilt7,
      option,
      filterDocumentProfilesfilt4,
      filt8,
      extra,
      1
    );
  };

  const onChangeDocumentProfile = (option: any) => {
    dispatch({ type: 'isearchser_DocProfile_change', payload: option });
    // setFilt4(option);
    const extra = 'change_documentProfile';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    multifilterCall(
      productLinefilt1,
      filterPlatformsfilt2,
      filterSolutionfilt6,
      filterProductfilt7,
      filterProjectsfilt3,
      option,
      filt8,
      extra,
      1
    );
  };
  const onChangeCouuntryNew = (option: any) => {
    dispatch({ type: 'isearchser_Country_change', payload: option });
    // setFilt1(option);
    const extra = 'change_Country';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    multifilterCall(
      productLinefilt1,
      filterPlatformsfilt2,
      filterSolutionfilt6,
      filterProductfilt7,
      filterProjectsfilt3,
      filterDocumentProfilesfilt4,
      option,
      extra,
      1
    );
  };

  const onChangeEntity2 = (option: any) => {
    // setDropDown1(option);
    dispatch({ type: 'change_simi_entity', payload: option });
    // extra argu implemented but not in use
    const extra = 'change_entity';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    multifilterSimiCall(option, dropDown2, dropDown3, dropDown4, dropDown5, dropDown6, extra);
  };

  const onChangePlatform2 = (option: any) => {
    dispatch({ type: 'change_simi_Platform', payload: option });
    // setDropDown2(option);
    const extra = 'change_platform';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    multifilterSimiCall(dropDown1, option, dropDown3, dropDown4, dropDown5, dropDown6, extra);
  };

  const onChangeProject2 = (option: any) => {
    dispatch({ type: 'change_simi_Project', payload: option });
    // setDropDown3(option);
    const extra = 'change_project';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    multifilterSimiCall(dropDown1, dropDown2, option, dropDown4, dropDown5, dropDown6, extra);
  };

  const onChangeDocumentProfile2 = (option: any) => {
    dispatch({ type: 'change_simi_DocumentProfile', payload: option });
    // setDropDown4(option);
    const extra = 'change_documentProfile';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    multifilterSimiCall(dropDown1, dropDown2, dropDown3, option, dropDown5, dropDown6, extra);
  };

  const onChangeSolution = (option: any) => {
    // setDropDown1(option);
    dispatch({ type: 'change_simi_Solution', payload: option });
    const extra = 'change_solution';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    multifilterSimiCall(dropDown1, dropDown2, dropDown3, dropDown4, option, dropDown6, extra);
  };

  const onChangeProduct = (option: any) => {
    // setDropDown1(option);
    dispatch({ type: 'change_simi_Product', payload: option });
    const extra = 'change_product';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    multifilterSimiCall(dropDown1, dropDown2, dropDown3, dropDown4, dropDown5, option, extra);
  };

  const onChangeArtifact = (option: any) => {
    dispatch({ type: 'artifact_change', payload: option });
  };

  const handleMaxResultsChange = (event, newValue) => {
    // marks[1] = {
    //   value: newValue,
    //   label: newValue,
    // };
    dispatch({ type: 'set_isearcher_max', payload: newValue });
    // setMaxResultsValue(newValue);
  };

  const handleREXMaxResultsChange = (event, newValue) => {
    dispatch({ type: 'change_Rex_Max', payload: newValue });
  };

  const handleChangeDoor = (event, newDoor) => {
    if (newDoor === null) {
      dispatch({ type: 'set_isearcher_door', payload: '*' });
    } else {
      dispatch({ type: 'set_isearcher_door', payload: newDoor });
    }
  };

  // Clearing filers for content search, expert search, people search
  function ClearFilter() {
    dispatch({ type: 'clear_isearch_filter' });
  }

  function ClearFilterRex() {
    dispatch({ type: 'clear_rex_filter' });
    // setDoor('*');
    // setMaxResultsValue(100);
  }

  function handleFilter2() {
    dispatch({ type: 'clear_simiFilters' });
  }
  // console.log('product', filt1);
  return (
    <>
      <Box className="filter-Container" style={{ height: '200px' }}>
        {['Content Search', 'Expert Search', 'People Search'].includes(callOperation) && (
          <Grid container spacing={2}>
            <Grid container item xs={12}>
              <Grid item xs={3}>
                <MultiSelect
                  options={productLine}
                  labelledBy="Entities"
                  isCreatable={true}
                  value={productLinefilt1}
                  onChange={onChangeEntity}
                  valueRenderer={customEntityValue}
                  overrideStrings={{
                    allItemsAreSelected: productLinefilt1
                      .map((item: { value: any }) => {
                        return item?.value;
                      })
                      .join(','),
                  }}
                  className={classes.formControlMulti1}
                />
              </Grid>
              <Grid item xs={3}>
                <MultiSelect
                  options={filterPlatforms}
                  labelledBy="Select"
                  isCreatable={true}
                  value={filterPlatformsfilt2}
                  onChange={onChangePlatform}
                  valueRenderer={customPlatformValue}
                  overrideStrings={{
                    allItemsAreSelected: filterPlatformsfilt2
                      .map((item) => {
                        return item?.value;
                      })
                      .join(','),
                  }}
                  className={classes.formControlMulti1}
                />
              </Grid>
              <Grid item xs={3}>
                <MultiSelect
                  options={filterSolution}
                  labelledBy="Select"
                  isCreatable={true}
                  value={filterSolutionfilt6}
                  onChange={onChangeSolutionNew}
                  valueRenderer={customSolutionValueCPE}
                  overrideStrings={{
                    allItemsAreSelected: filterSolutionfilt6
                      .map((item) => {
                        return item?.value;
                      })
                      .join(','),
                  }}
                  className={classes.formControlMulti1}
                />
              </Grid>
              <Grid item xs={3}>
                <MultiSelect
                  options={filterProduct}
                  labelledBy="Select"
                  isCreatable={true}
                  value={filterProductfilt7}
                  onChange={onChangeProductNew}
                  valueRenderer={customProductValueCPE}
                  overrideStrings={{
                    allItemsAreSelected: filterProductfilt7
                      .map((item) => {
                        return item?.value;
                      })
                      .join(','),
                  }}
                  className={classes.formControlMulti1}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={3}>
                <MultiSelect
                  options={filterProjects}
                  labelledBy="Select"
                  isCreatable={true}
                  value={filterProjectsfilt3}
                  onChange={onChangeProject}
                  valueRenderer={customProjectValue}
                  overrideStrings={{
                    allItemsAreSelected: filterProjectsfilt3
                      .map((item) => {
                        return item?.value;
                      })
                      .join(','),
                  }}
                  className={classes.formControlMulti1}
                />
              </Grid>
              <Grid item xs={3}>
                <MultiSelect
                  options={filterDocumentProfiles}
                  labelledBy="Select"
                  isCreatable={true}
                  value={filterDocumentProfilesfilt4}
                  onChange={onChangeDocumentProfile}
                  valueRenderer={customDocumentProfileValue}
                  overrideStrings={{
                    allItemsAreSelected: filterDocumentProfilesfilt4
                      .map((item) => {
                        return item?.value;
                      })
                      .join(','),
                  }}
                  className={classes.formControlMulti1}
                />
              </Grid>
              <Grid item xs={3} style={{ display: 'none' }}>
                <MultiSelect
                  options={filterCountry}
                  labelledBy="Select"
                  isCreatable={true}
                  value={filt8}
                  onChange={onChangeCouuntryNew}
                  valueRenderer={customCountryValueCPE}
                  overrideStrings={{
                    allItemsAreSelected: filt8
                      .map((item) => {
                        return item?.value;
                      })
                      .join(','),
                  }}
                  className={classes.formControlMulti1}
                />
              </Grid>
              <Grid item xs={3}>
                <Box className="filter-child1-toogle">
                  <ToggleButtonGroup
                    size="small"
                    value={door}
                    exclusive
                    onChange={handleChangeDoor}
                    className={classes.toggle}
                  >
                    <ToggleButton value="*">All</ToggleButton>
                    <ToggleButton value="DOORS">Dataset</ToggleButton>
                    {callOperation === 'Content Search' && (
                      <ToggleButton value="AEC">AEC</ToggleButton>
                    )}
                    <ToggleButton value="user_docs">User Docs</ToggleButton>
                  </ToggleButtonGroup>
                </Box>
              </Grid>
            </Grid>
            <Grid container item xs={12} style={{ background: 'rgb(238, 241, 249)' }}>
              <Grid item xs={12}>
                <Box style={{ width: '100%' }}>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      gap: 10,
                    }}
                  >
                    <Box className="filter-child2-slider-container" style={{ width: '178px' }}>
                      {/* <Typography>Maximum Results: {maxResultsValue}</Typography> */}
                      <Box className="filter-child2-slider-container-child1">
                        <Box className="filter-child2-slider-container-child1-stone">
                          <Typography>min</Typography>
                          <Typography>5</Typography>
                        </Box>
                        <Slider
                          min={5}
                          max={100}
                          value={maxResultsValue}
                          step={1}
                          onChangeCommitted={handleMaxResultsChange}
                          valueLabelDisplay="auto"
                          aria-labelledby="discrete-slider-always"
                          defaultValue={20}
                        />
                        <Box className="filter-child2-slider-container-child1-stone">
                          <Typography>max</Typography>
                          <Typography>100</Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      // style={{ float: 'inline-end' }}
                      onClick={() => {
                        ClearFilter();
                      }}
                      startIcon={<FilterAltOffIcon />}
                    >
                      Clear Filter
                    </Button>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          // <>
          //   <Box className="filter-child1">
          //     <MultiSelect
          //       options={alstomEntities}
          //       labelledBy="Entities"
          //       isCreatable={true}
          //       value={filt1}
          //       onChange={onChangeEntity}
          //       valueRenderer={customEntityValue}
          //       overrideStrings={{
          //         allItemsAreSelected: filt1
          //           .map((item) => {
          //             return item?.value;
          //           })
          //           .join(','),
          //       }}
          //       className={classes.formControlMulti1}
          //     />

          // <Box className="filter-child1-toogle">
          //   <ToggleButtonGroup
          //     size="small"
          //     value={door}
          //     exclusive
          //     onChange={handleChangeDoor}
          //     className={classes.toggle}
          //   >
          //     <ToggleButton value="*">All</ToggleButton>
          //     <ToggleButton value="DOORS">Dataset</ToggleButton>
          //     {callOperation === 'Content Search' && (
          //       <ToggleButton value="AEC">AEC</ToggleButton>
          //     )}
          //     <ToggleButton value="user_docs">User Docs</ToggleButton>
          //   </ToggleButtonGroup>
          // </Box>
          //   </Box>
          //   <Box className="filter-child2">
          //     <MultiSelect
          //       options={filterPlatforms}
          //       labelledBy="Select"
          //       isCreatable={true}
          //       value={filt2}
          //       onChange={onChangePlatform}
          //       valueRenderer={customPlatformValue}
          //       overrideStrings={{
          //         allItemsAreSelected: filt2
          //           .map((item) => {
          //             return item?.value;
          //           })
          //           .join(','),
          //       }}
          //       className={classes.formControlMulti1}
          //     />
          //   <Box className="filter-child2-slider-container">
          //     <Typography>Maximum Results: {maxResultsValue}</Typography>
          //     <Box className="filter-child2-slider-container-child1">
          //       <Box className="filter-child2-slider-container-child1-stone">
          //         <Typography>min</Typography>
          //         <Typography>5</Typography>
          //       </Box>
          //       <Slider
          //         min={5}
          //         max={100}
          //         value={maxResultsValue}
          //         step={1}
          //         onChangeCommitted={handleMaxResultsChange}
          //         valueLabelDisplay="auto"
          //         aria-labelledby="discrete-slider-always"
          //         defaultValue={20}
          //       />
          //       <Box className="filter-child2-slider-container-child1-stone">
          //         <Typography>max</Typography>
          //         <Typography>100</Typography>
          //       </Box>
          //     </Box>
          //   </Box>
          // </Box>
          //   <Box className="filter-child2">
          //     <MultiSelect
          //       options={filterProjects}
          //       labelledBy="Select"
          //       isCreatable={true}
          //       value={filt3}
          //       onChange={onChangeProject}
          //       valueRenderer={customProjectValue}
          //       overrideStrings={{
          //         allItemsAreSelected: filt3
          //           .map((item) => {
          //             return item?.value;
          //           })
          //           .join(','),
          //       }}
          //       className={classes.formControlMulti1}
          //     />
          //     <MultiSelect
          //       options={artiFact}
          //       labelledBy="Select"
          //       isCreatable={true}
          //       value={filt5}
          //       onChange={onChangeArtifact}
          //       valueRenderer={customArtifactValue}
          //       overrideStrings={{
          //         allItemsAreSelected: filt5
          //           .map((item) => {
          //             return item?.value;
          //           })
          //           .join(','),
          //       }}
          //       className={classes.formControlMulti1}
          //     />
          //   </Box>

          //   <Box className="filter-child2">
          //     <MultiSelect
          //       options={filterDocumentProfiles}
          //       labelledBy="Select"
          //       isCreatable={true}
          //       value={filt4}
          //       onChange={onChangeDocumentProfile}
          //       valueRenderer={customDocumentProfileValue}
          //       overrideStrings={{
          //         allItemsAreSelected: filt4
          //           .map((item) => {
          //             return item?.value;
          //           })
          //           .join(','),
          //       }}
          //       className={classes.formControlMulti1}
          //     />

          //     <Button
          //       type="button"
          //       variant="contained"
          //       color="primary"
          //       onClick={ClearFilter}
          //       startIcon={<FilterAltOffIcon />}
          //     >
          //       Clear Filter
          //     </Button>
          //   </Box>
          // </>
        )}
        {callOperation === 'Similarity Search' && (
          <>
            <Box className="simi-Filter-container">
              <Box className="simi-Filter-container-child">
                <Box className="simi-Filter-container-child-element">
                  <MultiSelect
                    options={simi_AlstomEntities}
                    labelledBy="Select"
                    isCreatable={true}
                    value={dropDown1}
                    onChange={onChangeEntity2}
                    valueRenderer={customEntityValue}
                    overrideStrings={{
                      allItemsAreSelected: dropDown1
                        .map((item) => {
                          return item?.value;
                        })
                        .join(','),
                    }}
                    className={classes.formControlMultiSimi}
                  />
                </Box>

                <Box className="simi-Filter-container-child-element">
                  <MultiSelect
                    options={simi_FilterPlatforms}
                    labelledBy="Select"
                    isCreatable={true}
                    value={dropDown2 ?? []}
                    onChange={onChangePlatform2}
                    valueRenderer={customPlatformValue}
                    overrideStrings={{
                      allItemsAreSelected: dropDown2
                        .map((item) => {
                          return item?.value;
                        })
                        .join(','),
                    }}
                    className={classes.formControlMultiSimi}
                  />
                </Box>

                <Box className="simi-Filter-container-child-element">
                  <MultiSelect
                    options={simi_FilterSolution ?? []}
                    labelledBy="Select"
                    value={dropDown5 ?? []}
                    onChange={onChangeSolution}
                    valueRenderer={customSolutionValue}
                    disableSearch={true}
                    overrideStrings={{
                      allItemsAreSelected: dropDown5
                        .map((item) => {
                          return item?.value;
                        })
                        .join(','),
                    }}
                    className={classes.formControlMultiSimi}
                  />
                </Box>

                <Box className="simi-Filter-container-child-element">
                  <MultiSelect
                    options={simi_FilterProduct ?? []}
                    labelledBy="Select"
                    value={dropDown6 ?? []}
                    onChange={onChangeProduct}
                    valueRenderer={customProductValue}
                    disableSearch={true}
                    overrideStrings={{
                      allItemsAreSelected: dropDown6
                        .map((item) => {
                          return item?.value;
                        })
                        .join(','),
                    }}
                    className={classes.formControlMultiSimi}
                  />
                </Box>

                <Box className="simi-Filter-container-child-element">
                  <MultiSelect
                    options={simi_FilterProjects}
                    labelledBy="Select"
                    isCreatable={true}
                    value={dropDown3}
                    onChange={onChangeProject2}
                    valueRenderer={customProjectValue}
                    overrideStrings={{
                      allItemsAreSelected: dropDown3
                        .map((item) => {
                          return item?.value;
                        })
                        .join(','),
                    }}
                    className={classes.formControlMultiSimi}
                  />
                </Box>

                <Box className="simi-Filter-container-child-element">
                  <MultiSelect
                    options={simi_FilterDocumentProfiles}
                    labelledBy="Select"
                    isCreatable={true}
                    value={dropDown4}
                    onChange={onChangeDocumentProfile2}
                    valueRenderer={customDocumentProfileValue}
                    overrideStrings={{
                      allItemsAreSelected: dropDown4
                        .map((item) => {
                          return item?.value;
                        })
                        .join(','),
                    }}
                    className={classes.formControlMultiSimi}
                  />
                </Box>
              </Box>
              <Box className="simi-Filter-container-slider-parent">
                <Box className="simi-Filter-container-slider-parent-container">
                  <Typography>Maximum Results: {countValue}</Typography>
                  <Box className="filter-child2-slider-container-child1">
                    <Box className="filter-child2-slider-container-child1-stone">
                      <Typography>min</Typography>
                      <Typography>5</Typography>
                    </Box>
                    <Slider
                      name="count-slider"
                      min={5}
                      max={50}
                      defaultValue="60"
                      valueLabelDisplay="auto"
                      aria-labelledby="discrete-slider-always"
                      value={countValue}
                      step={1}
                      onChangeCommitted={handleCountChange}
                    />
                    <Box className="filter-child2-slider-container-child1-stone">
                      <Typography>max</Typography>
                      <Typography>50</Typography>
                    </Box>
                  </Box>
                </Box>
                <Box className="simi-Filter-container-slider-parent-container">
                  <Typography>Similarity Score: {threshold}</Typography>
                  <Box className="filter-child2-slider-container-child1">
                    <Box className="filter-child2-slider-container-child1-stone">
                      <Typography>min</Typography>
                      <Typography>60</Typography>
                    </Box>
                    <Slider
                      min={60}
                      max={100}
                      valueLabelDisplay="auto"
                      aria-labelledby="discrete-slider-always"
                      name="threshold-slider"
                      defaultValue="60"
                      step={1}
                      value={threshold}
                      onChangeCommitted={handleThresholdChange}
                    />
                    <Box className="filter-child2-slider-container-child1-stone">
                      <Typography>max</Typography>
                      <Typography>100</Typography>
                    </Box>
                  </Box>
                </Box>
                <Box className="simi-Filter-container-child-element">
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    style={{ width: '100%' }}
                    onClick={() => {
                      handleFilter2();
                    }}
                    startIcon={<FilterAltOffIcon />}
                  >
                    Clear Filter
                  </Button>
                </Box>
              </Box>
            </Box>
          </>
        )}
        {['Requirements', 'Experts', 'Tests'].includes(callOperation) && (
          <>
            <Grid container spacing={2}>
              <Grid container item xs={12}>
                <Grid item xs={3}>
                  <MultiSelect
                    options={productLine}
                    labelledBy="Entities"
                    isCreatable={true}
                    value={productLinefilt1}
                    onChange={onChangeEntity}
                    valueRenderer={customEntityValue}
                    overrideStrings={{
                      allItemsAreSelected: productLinefilt1
                        .map((item) => {
                          return item?.value;
                        })
                        .join(','),
                    }}
                    className={classes.formControlMulti1}
                  />
                </Grid>
                <Grid item xs={3}>
                  <MultiSelect
                    options={filterPlatforms}
                    labelledBy="Select"
                    isCreatable={true}
                    value={filterPlatformsfilt2}
                    onChange={onChangePlatform}
                    valueRenderer={customPlatformValue}
                    overrideStrings={{
                      allItemsAreSelected: filterPlatformsfilt2
                        .map((item) => {
                          return item?.value;
                        })
                        .join(','),
                    }}
                    className={classes.formControlMulti1}
                  />
                </Grid>
                <Grid item xs={3}>
                  <MultiSelect
                    options={filterSolution}
                    labelledBy="Select"
                    isCreatable={true}
                    value={filterSolutionfilt6}
                    onChange={onChangeSolutionNew}
                    valueRenderer={customSolutionValueRET}
                    overrideStrings={{
                      allItemsAreSelected: filterSolutionfilt6
                        .map((item) => {
                          return item?.value;
                        })
                        .join(','),
                    }}
                    className={classes.formControlMulti1}
                  />
                </Grid>
                <Grid item xs={3}>
                  <MultiSelect
                    options={filterProduct}
                    labelledBy="Select"
                    isCreatable={true}
                    value={filterProductfilt7}
                    onChange={onChangeProductNew}
                    valueRenderer={customProductValueRET}
                    overrideStrings={{
                      allItemsAreSelected: filterProductfilt7
                        .map((item) => {
                          return item?.value;
                        })
                        .join(','),
                    }}
                    className={classes.formControlMulti1}
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={3}>
                  <MultiSelect
                    options={filterProjects}
                    labelledBy="Select"
                    isCreatable={true}
                    value={filterProjectsfilt3}
                    onChange={onChangeProject}
                    valueRenderer={customProjectValue}
                    overrideStrings={{
                      allItemsAreSelected: filterProjectsfilt3
                        .map((item) => {
                          return item?.value;
                        })
                        .join(','),
                    }}
                    className={classes.formControlMulti1}
                  />
                </Grid>
                <Grid item xs={3}>
                  <MultiSelect
                    options={filterDocumentProfiles}
                    labelledBy="Select"
                    isCreatable={true}
                    value={filterDocumentProfilesfilt4}
                    onChange={onChangeDocumentProfile}
                    valueRenderer={customDocumentProfileValue}
                    overrideStrings={{
                      allItemsAreSelected: filterDocumentProfilesfilt4
                        .map((item) => {
                          return item?.value;
                        })
                        .join(','),
                    }}
                    className={classes.formControlMulti1}
                  />
                </Grid>
                <Grid item xs={3} style={{ display: 'none' }}>
                  <MultiSelect
                    options={filterCountry}
                    labelledBy="Select"
                    isCreatable={true}
                    value={filt8}
                    onChange={onChangeCouuntryNew}
                    valueRenderer={customCountryValue}
                    overrideStrings={{
                      allItemsAreSelected: filt8
                        .map((item) => {
                          return item?.value;
                        })
                        .join(','),
                    }}
                    className={classes.formControlMulti1}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Box className="rex-filter-container-slider-parent-container">
                    {/* <Typography>Maximum Results: {maxResultsValue}</Typography> */}
                    <Box className="filter-child2-slider-container-child1">
                      <Box className="filter-child2-slider-container-child1-stone">
                        <Typography>min</Typography>
                        <Typography>5</Typography>
                      </Box>
                      <Slider
                        min={5}
                        max={100}
                        value={maxResultsValue}
                        step={1}
                        onChangeCommitted={handleMaxResultsChange}
                        valueLabelDisplay="auto"
                        aria-labelledby="discrete-slider-always"
                        defaultValue={20}
                      />
                      <Box className="filter-child2-slider-container-child1-stone">
                        <Typography>max</Typography>
                        <Typography>100</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Grid container item xs={12} style={{ background: 'rgb(238, 241, 249)' }}>
                <Grid item xs={12}>
                  <Box style={{ width: '100%' }}>
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      style={{ float: 'inline-end' }}
                      onClick={() => {
                        ClearFilter();
                      }}
                      startIcon={<FilterAltOffIcon />}
                    >
                      Clear Filter
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
        {callOperation === 'REX Cards' && (
          <>
            {/* {console.log('issuer', issureSite, rexSourceDef)} */}
            <Box className="rex-filter-container">
              <Grid
                container
                style={{
                  fontSize: 16,
                  fontWeight: 'bold',
                  color: 'rgb(0, 42, 69)',
                }}
              >
                <Grid item xs={6}>
                  Six Layers Filters From Orchestra
                </Grid>
                <Grid item xs={6}>
                  REX Card Filters From KM Tools
                </Grid>
              </Grid>
              <Box className="rex-filter-container-child">
                <Box className="rex-filter-container-child-element">
                  <MultiSelect
                    options={
                      filterrexProductLine.length > 0 ? filterrexProductLine : rexProductLineDef
                    }
                    labelledBy="Select"
                    isCreatable={true}
                    value={productrexLine}
                    onChange={onChangeProductLineRex}
                    valueRenderer={customProductLineRex}
                    overrideStrings={{
                      allItemsAreSelected: productrexLine
                        .map((item) => {
                          return item?.value;
                        })
                        .join(','),
                    }}
                    className={classes.formControlMulti1}
                  />
                </Box>

                <Box className="rex-filter-container-child-element">
                  <MultiSelect
                    options={filterexPlatform.length > 0 ? filterexPlatform : rexPlatformDef}
                    labelledBy="Select"
                    isCreatable={true}
                    value={rexPlatform}
                    onChange={onChangeRexPlatForm}
                    valueRenderer={customPlatformValue}
                    overrideStrings={{
                      allItemsAreSelected: rexPlatform
                        .map((item) => {
                          return item?.value;
                        })
                        .join(','),
                    }}
                    className={classes.formControlMulti1}
                  />
                </Box>
                <Box className="rex-filter-container-child-element">
                  <MultiSelect
                    options={filterissureSite}
                    labelledBy="Select"
                    isCreatable={true}
                    value={issureSite}
                    onChange={onChangeIssuerSite}
                    valueRenderer={customIsserSite}
                    overrideStrings={{
                      allItemsAreSelected: issureSite
                        .map((item) => {
                          return item?.value;
                        })
                        .join(','),
                    }}
                    className={classes.formControlMulti1}
                  />
                </Box>
                <Box className="rex-filter-container-child-element">
                  <MultiSelect
                    options={filterrexSource}
                    labelledBy="Select"
                    isCreatable={true}
                    value={rexSource}
                    onChange={onChangeRexSource}
                    valueRenderer={customRexSource}
                    overrideStrings={{
                      allItemsAreSelected: rexSource
                        .map((item) => {
                          return item?.value;
                        })
                        .join(','),
                    }}
                    className={classes.formControlMulti1}
                  />
                </Box>
              </Box>
              <Box className="rex-filter-container-child">
                <Box className="rex-filter-container-child-element">
                  <MultiSelect
                    options={filterrexSolution.length > 0 ? filterrexSolution : rexSolutionDef}
                    labelledBy="Select"
                    isCreatable={true}
                    value={solution}
                    onChange={onChangeSolutionRex}
                    valueRenderer={customSolutionsRex}
                    overrideStrings={{
                      allItemsAreSelected: solution
                        .map((item) => {
                          return item?.value;
                        })
                        .join(','),
                    }}
                    className={classes.formControlMulti1}
                  />
                </Box>
                <Box className="rex-filter-container-child-element">
                  <MultiSelect
                    options={filterrexProduct.length > 0 ? filterrexProduct : rexProductDef}
                    labelledBy="Select"
                    isCreatable={true}
                    value={product}
                    onChange={onChangeProductRex}
                    valueRenderer={customProductRex}
                    overrideStrings={{
                      allItemsAreSelected: product
                        .map((item) => {
                          return item?.value;
                        })
                        .join(','),
                    }}
                    className={classes.formControlMulti1}
                  />
                </Box>
                <Box className="rex-filter-container-child-element">
                  <MultiSelect
                    options={filterrexStatus}
                    labelledBy="Select"
                    isCreatable={true}
                    value={rexStatus}
                    onChange={onChangeRexStatus}
                    valueRenderer={customStatus}
                    overrideStrings={{
                      allItemsAreSelected: rexStatus
                        .map((item) => {
                          return item?.value;
                        })
                        .join(','),
                    }}
                    className={classes.formControlMulti1}
                  />
                </Box>
                <Box className="rex-filter-container-child-element">
                  <MultiSelect
                    options={filterTypology}
                    labelledBy="Select"
                    isCreatable={true}
                    value={rexTypology}
                    onChange={onChangeRexTypology}
                    valueRenderer={customTypology}
                    overrideStrings={{
                      allItemsAreSelected: rexTypology
                        .map((item) => {
                          return item?.value;
                        })
                        .join(','),
                    }}
                    className={classes.formControlMulti1}
                  />
                </Box>
              </Box>
              <Box className="rex-filter-container">
                <Box className="rex-filter-container-child">
                  <Box className="rex-filter-container-child-element">
                    <MultiSelect
                      options={filterrexProject.length > 0 ? filterrexProject : rexProjectDef}
                      labelledBy="Select"
                      isCreatable={true}
                      value={project}
                      onChange={onChangeProjectRex}
                      valueRenderer={customProjectRex}
                      overrideStrings={{
                        allItemsAreSelected: project
                          .map((item) => {
                            return item?.value;
                          })
                          .join(','),
                      }}
                      className={classes.formControlMulti1}
                    />
                  </Box>

                  <Box className="rex-filter-container-child-element">
                    <MultiSelect
                      options={filterrexCountry}
                      labelledBy="Select"
                      isCreatable={true}
                      value={country}
                      onChange={onChangeCountryRex}
                      valueRenderer={customCountryRex}
                      overrideStrings={{
                        allItemsAreSelected: country
                          .map((item) => {
                            return item?.value;
                          })
                          .join(','),
                      }}
                      className={classes.formControlMulti1}
                    />
                  </Box>
                  <Box className="rex-filter-container-child-element">
                    <Box className="rex-filter-container-child-element" style={{ width: '200px' }}>
                      <Box>
                        <Typography style={{ marginLeft: 35 }}>
                          Maximum Results: {rexMaxValue}
                        </Typography>
                        <Box
                          className="filter-child2-slider-container-child1"
                          style={{ marginTop: -15 }}
                        >
                          <Box className="filter-child2-slider-container-child1-stone">
                            <Typography>min</Typography>
                            <Typography>5</Typography>
                          </Box>
                          <Slider
                            min={5}
                            max={500}
                            value={rexMaxValue}
                            step={1}
                            onChangeCommitted={handleREXMaxResultsChange}
                            valueLabelDisplay="auto"
                            aria-labelledby="discrete-slider-always"
                            defaultValue={350}
                          />
                          <Box className="filter-child2-slider-container-child1-stone">
                            <Typography>max</Typography>
                            <Typography>500</Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box className="rex-filter-container-child-element">
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      style={{ paddingRight: 56, paddingLeft: 56 }}
                      onClick={() => {
                        ClearFilterRex();
                      }}
                      startIcon={<FilterAltOffIcon />}
                    >
                      Clear Filter
                    </Button>
                  </Box>
                </Box>
              </Box>
              {/* <Box className="rex-filter-container-slider-parent">
                <Box>
                  <Typography>Maximum Results: {rexMaxValue}</Typography>
                  <Box>
                    <Box>
                      <Typography>min</Typography>
                      <Typography>5</Typography>
                    </Box>
                    <Slider
                      min={5}
                      max={500}
                      value={rexMaxValue}
                      step={1}
                      onChangeCommitted={handleREXMaxResultsChange}
                      valueLabelDisplay="auto"
                      aria-labelledby="discrete-slider-always"
                      defaultValue={350}
                    />
                    <Box>
                      <Typography>max</Typography>
                      <Typography>500</Typography>
                    </Box>
                  </Box>
                </Box>
                <Box className="rex-filter-container-half-typo">
                  <Box className="rex-filter-container-half-typo-half">
                    <MultiSelect
                      options={rexProjectDef}
                      labelledBy="Select"
                      isCreatable={true}
                      value={project}
                      onChange={onChangeProjectRex}
                      valueRenderer={customProjectRex}
                      overrideStrings={{
                        allItemsAreSelected: project
                          .map((item) => {
                            return item?.value;
                          })
                          .join(','),
                      }}
                      className={classes.formControlMulti1}
                    />
                  </Box>
                  <Box className="rex-filter-container-half-typo-half">
                    <MultiSelect
                      options={rexCountryDef}
                      labelledBy="Select"
                      isCreatable={true}
                      value={country}
                      onChange={onChangeCountryRex}
                      valueRenderer={customCountryRex}
                      overrideStrings={{
                        allItemsAreSelected: country
                          .map((item) => {
                            return item?.value;
                          })
                          .join(','),
                      }}
                      className={classes.formControlMulti1}
                    />
                  </Box>

                  <Box className="">
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        ClearFilterRex();
                      }}
                      startIcon={<FilterAltOffIcon />}
                    >
                      Clear Filter
                    </Button>
                  </Box>
                </Box>
              </Box> */}
            </Box>
          </>
        )}

        {callOperation === 'REX Book' && (
          <>
            <Box className="rex-filter-container">
              <Grid
                container
                style={{
                  fontSize: 16,
                  fontWeight: 'bold',
                  color: 'rgb(0, 42, 69)',
                }}
              >
                <Grid item xs={6}>
                  Six Layers Filters From Orchestra
                </Grid>
                <Grid item xs={6}>
                  REX Cards Filters From KM Tools
                </Grid>
              </Grid>
              <Box className="rex-filter-container-child">
                <Box className="rex-filter-container-child-element">
                  <MultiSelect
                    options={
                      filterrexProductLine.length > 0 ? filterrexProductLine : rexProductLineDef
                    }
                    labelledBy="Select"
                    isCreatable={true}
                    value={productrexLine}
                    onChange={onChangeProductLineRex}
                    valueRenderer={customProductLineRex}
                    overrideStrings={{
                      allItemsAreSelected: productrexLine
                        .map((item) => {
                          return item?.value;
                        })
                        .join(','),
                    }}
                    className={classes.formControlMulti1}
                  />
                </Box>

                <Box className="rex-filter-container-child-element">
                  <MultiSelect
                    options={filterexPlatform.length > 0 ? filterexPlatform : rexPlatformDef}
                    labelledBy="Select"
                    isCreatable={true}
                    value={rexPlatform}
                    onChange={onChangeRexPlatForm}
                    valueRenderer={customPlatformValue}
                    overrideStrings={{
                      allItemsAreSelected: rexPlatform
                        .map((item) => {
                          return item?.value;
                        })
                        .join(','),
                    }}
                    className={classes.formControlMulti1}
                  />
                </Box>
                <Box className="rex-filter-container-child-element">
                  <MultiSelect
                    options={filterissureSite}
                    labelledBy="Select"
                    isCreatable={true}
                    value={issureSite}
                    onChange={onChangeIssuerSite}
                    valueRenderer={customIsserSite}
                    overrideStrings={{
                      allItemsAreSelected: issureSite
                        .map((item) => {
                          return item?.value;
                        })
                        .join(','),
                    }}
                    className={classes.formControlMulti1}
                  />
                </Box>
                <Box className="rex-filter-container-child-element">
                  <MultiSelect
                    options={filterrexSource}
                    labelledBy="Select"
                    isCreatable={true}
                    value={rexSource}
                    onChange={onChangeRexSource}
                    valueRenderer={customRexSource}
                    overrideStrings={{
                      allItemsAreSelected: rexSource
                        .map((item) => {
                          return item?.value;
                        })
                        .join(','),
                    }}
                    className={classes.formControlMulti1}
                  />
                </Box>
              </Box>
              <Box className="rex-filter-container-child">
                <Box className="rex-filter-container-child-element">
                  <MultiSelect
                    options={filterrexSolution.length > 0 ? filterrexSolution : rexSolutionDef}
                    labelledBy="Select"
                    isCreatable={true}
                    value={solution}
                    onChange={onChangeSolutionRex}
                    valueRenderer={customSolutionsRex}
                    overrideStrings={{
                      allItemsAreSelected: solution
                        .map((item) => {
                          return item?.value;
                        })
                        .join(','),
                    }}
                    className={classes.formControlMulti1}
                  />
                </Box>
                <Box className="rex-filter-container-child-element">
                  <MultiSelect
                    options={filterrexProduct.length > 0 ? filterrexProduct : rexProductDef}
                    labelledBy="Select"
                    isCreatable={true}
                    value={product}
                    onChange={onChangeProductRex}
                    valueRenderer={customProductRex}
                    overrideStrings={{
                      allItemsAreSelected: product
                        .map((item) => {
                          return item?.value;
                        })
                        .join(','),
                    }}
                    className={classes.formControlMulti1}
                  />
                </Box>
                <Box className="rex-filter-container-child-element">
                  <MultiSelect
                    options={filterrexStatus}
                    labelledBy="Select"
                    isCreatable={true}
                    value={rexStatus}
                    onChange={onChangeRexStatus}
                    valueRenderer={customStatus}
                    overrideStrings={{
                      allItemsAreSelected: rexStatus
                        .map((item) => {
                          return item?.value;
                        })
                        .join(','),
                    }}
                    className={classes.formControlMulti1}
                  />
                </Box>
                <Box className="rex-filter-container-child-element">
                  <MultiSelect
                    options={filterTypology}
                    labelledBy="Select"
                    isCreatable={true}
                    value={rexTypology}
                    onChange={onChangeRexTypology}
                    valueRenderer={customTypology}
                    overrideStrings={{
                      allItemsAreSelected: rexTypology
                        .map((item) => {
                          return item?.value;
                        })
                        .join(','),
                    }}
                    className={classes.formControlMulti1}
                  />
                </Box>
              </Box>
              <Box className="rex-filter-container">
                <Box className="rex-filter-container-child">
                  <Box className="rex-filter-container-child-element">
                    <MultiSelect
                      options={filterrexProject.length > 0 ? filterrexProject : rexProjectDef}
                      labelledBy="Select"
                      isCreatable={true}
                      value={project}
                      onChange={onChangeProjectRex}
                      valueRenderer={customProjectRex}
                      overrideStrings={{
                        allItemsAreSelected: project
                          .map((item) => {
                            return item?.value;
                          })
                          .join(','),
                      }}
                      className={classes.formControlMulti1}
                    />
                  </Box>

                  <Box className="rex-filter-container-child-element">
                    <MultiSelect
                      options={filterrexCountry}
                      labelledBy="Select"
                      isCreatable={true}
                      value={country}
                      onChange={onChangeCountryRex}
                      valueRenderer={customCountryRex}
                      overrideStrings={{
                        allItemsAreSelected: country
                          .map((item) => {
                            return item?.value;
                          })
                          .join(','),
                      }}
                      className={classes.formControlMulti1}
                    />
                  </Box>
                  <Box className="rex-filter-container-child-element">
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      style={{ paddingRight: 56, paddingLeft: 56 }}
                      onClick={() => {
                        ClearFilterRex();
                      }}
                      startIcon={<FilterAltOffIcon />}
                    >
                      Clear Filter
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default Filters;
