import { Button, ButtonProps } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

interface CustomBackgroundButtonProps extends ButtonProps {
  bgColor: string;
}

const StyledBackgroundButton = styled(Button, {
  shouldForwardProp: (props) => props !== 'bgColor',
})<CustomBackgroundButtonProps>(({ theme, bgColor }) => ({
  '&.MuiButtonBase-root': {
    background: alpha(bgColor, 0.85),
    color: alpha(theme.palette.common.white, 0.85),
    '&:hover': {
      background: alpha(bgColor, 1),
      color: 'white',
    },
  },
}));

const CustomBackgroundButton: React.FC<CustomBackgroundButtonProps> = (props) => {
  return <StyledBackgroundButton variant="contained" {...props} />;
};

export default CustomBackgroundButton;
