import React, { memo, useCallback, useContext, useRef, useState } from 'react';
import { UserWorkloadCreate, userWorkloadRunPostForTranslator } from '../../../api/workloads';
import useAppToken from '../../../app/useAppToken';
import UserUploadFile, { UserUploadGlossaryFile } from '../API/UserUploadFile';
import FileTranslatorContext from '../FileTranslatorContext';
import TabContainer from './TabContainer';

const UserWorkspaceTableContainer = (props) => {
  const [workLoads, setWorkloads] = useState([]);

  const {
    setSnack,
    files,
    setFiles,
    glossaryFiles,
    languageCode,
    fileSelection,
    setGlossaryFiles,
    state,
    mode,
    dispatch,
  } = useContext(FileTranslatorContext);
  const [transformLine, setTransformLine] = useState('0rem');
  const [hideTranslateButton, setHideTranslateButton] = useState(false);
  const inputRefFile = useRef();
  const { appJWT } = useAppToken();
  let intervalCall = null as any;
  const { user_id } = appJWT;
  const inputGrossary = useRef();
  const { selectedFileData, GlossaryFromLang, GlossaryToLang, ToLang, FromLang, model } =
    state || [];

  // console.log(state);
  const handleChangeFiles = async (event: any) => {
    const result = await UserUploadFile(event, files, user_id);
    if (result.status === 'error') {
      setSnack(result.message, 'error');
    } else if (result.status === 'success') {
      setFiles((prev: any) => {
        return [...result.data, ...prev];
      });
      setSnack(result.message, 'success');
    }
  };

  const handleModel = (value) => {
    dispatch({ type: 'change_model', payload: value });
    dispatch({ type: 'Change_in_To_Lang', payload: '' });
  };

  const draghandleChangeFiles = (event: any) => {
    const arr = [];
    arr.push(event);
    handleChangeFiles(arr);
  };

  const handleChangeGlossaryFiles = async (event: any) => {
    const result = await UserUploadGlossaryFile(
      event,
      glossaryFiles,
      user_id,
      GlossaryFromLang,
      GlossaryToLang
    );
    // console.log('result', result);
    if (result.status === 'error') {
      setSnack(result.message, 'error');
    } else if (result.status === 'success') {
      setGlossaryFiles(result.data);
      setSnack(result.message, 'success');
    }
  };

  const draghandleChangGlossaryeFiles = (event: any) => {
    if (!GlossaryFromLang) {
      setSnack(`please select from Language`, 'error');
      return;
    }

    if (!GlossaryToLang) {
      setSnack(`please select To Language`, 'error');
      return;
    }
    const arr = [];
    arr.push(event);
    handleChangeGlossaryFiles(arr);
  };

  const UploadFile = () => {
    inputRefFile.current.click();
    // if (project && project.folders && !isEmpty(project.folders)) {
    //   inputRef.current.click();
    // } else {
    //   setSnack('Please Create folder', 'error');
    // }
  };

  const UploadFileGlossary = () => {
    if (!GlossaryFromLang) {
      setSnack(`please select from Language`, 'error');
      return;
    }

    if (!GlossaryToLang) {
      setSnack(`please select To Language`, 'error');
      return;
    }
    inputGrossary.current.click();
    // if (project && project.folders && !isEmpty(project.folders)) {
    //   inputRef.current.click();
    // } else {
    //   setSnack('Please Create folder', 'error');
    // }
  };

  const filehandleChangeFiles = (event: any) => {
    const e = event?.target?.files || '';
    handleChangeFiles(e);
  };

  const filehandleChangeGlossaryFiles = (event: any) => {
    const e = event?.target?.files || '';
    handleChangeGlossaryFiles(e);
  };

  // console.log(state);

  const TranslateFile = () => {
    setHideTranslateButton(true);
    if (!intervalCall) {
      clearInterval(intervalCall);
    }
    const selectedRows = selectedFileData.map((data, index) => {
      const { document, glossaryFile } = data || null;

      if (glossaryFile) {
        return {
          document: {
            FileName: document.filename,
            Container: document.container,
            size: document.size,
          },
          glossaryFile: {
            FileName: glossaryFile.filename,
            Container: glossaryFile.container,
            size: glossaryFile.size,
          },
          fromLanguage: state.FromLang,
          toLanguage: state.ToLang,
        };
      }

      const obj = {
        document: {
          FileName: document.filename,
          Container: document.container,
          size: document.size,
        },
        fromLanguage: state.FromLang,
        toLanguage: state.ToLang,
      };

      return obj;
    });
    // console.log('selected Payload', selectedRows);
    const promisses = [];
    const res = [] as any;
    for (const eachpayload of selectedRows) {
      const obj = {
        ...eachpayload,
      };
      res.push(obj);
      const workloadCreatePayload = {
        ...eachpayload.document,
        toLanguage: eachpayload.toLanguage,
        deepL: model === 'DeepL' ? 'yes' : 'no',
      };
      // console.log('selected create workload', workloadCreatePayload);
      promisses.push(() =>
        UserWorkloadCreate({
          app: 'module2',
          action: 'Translator',
          resources: [workloadCreatePayload],
        })
      );
    }
    // console.log('res', res);
    intervalCall = setTimeout(() => {
      Promise.all(promisses.map((fn) => fn()))
        .then((result) => {
          const wl = result.map((data) => {
            return data.id;
          });
          setWorkloads(wl);
          const translatorCALL = result.map((data, index) => {
            return () => {
              // workloadRunPostForTranslator(Number(data.id), user_id, state.ToLang, data?.resources);
              userWorkloadRunPostForTranslator(
                Number(data.id),
                user_id,
                state.ToLang,
                [res[index]],
                model === 'DeepL' ? 'yes' : 'no'
              );
            };
          });
          Promise.all(translatorCALL.map((fn) => fn()))
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .then((payload) => {
              // setShowProcessFile(true);
              // setToggleTab((prev) => {
              //   return {
              //     ...prev,
              //     left: '5rem',
              //   };
              // });
              // setRunLoad(true);
              setHideTranslateButton(false);
              setTransformLine('6rem');
              clearInterval(intervalCall);
            })
            .catch(() => {
              setSnack('Error while workload post.', 'error');
            })
            .finally(() => {});
        })
        .catch(() => {
          setSnack('Error while workload create.', 'error');
        })
        .finally(() => {});
    }, 3000);
  };

  const OnChangeFromLangugae = useCallback((lang, str) => {
    dispatch({ type: str, payload: lang });
  }, []);

  const OnChangeToLangugae = useCallback((lang, str) => {
    dispatch({ type: str, payload: lang });
  }, []);

  return (
    <>
      <input type="file" multiple ref={inputRefFile} hidden onChange={filehandleChangeFiles} />
      <input
        type="file"
        multiple
        ref={inputGrossary}
        hidden
        onChange={filehandleChangeGlossaryFiles}
      />

      <TabContainer
        draghandleChangeFiles={draghandleChangeFiles}
        draghandleChangGlossaryeFiles={draghandleChangGlossaryeFiles}
        workLoads={workLoads}
        OnChangeFromLangugae={OnChangeFromLangugae}
        OnChangeToLangugae={OnChangeToLangugae}
        languageCode={languageCode}
        selectedFileData={selectedFileData}
        fileSelection={fileSelection}
        TranslateFile={TranslateFile}
        UploadFile={UploadFile}
        GlossaryFromLang={GlossaryFromLang}
        GlossaryToLang={GlossaryToLang}
        FromLang={FromLang}
        ToLang={ToLang}
        setSnack={setSnack}
        UploadFileGlossary={UploadFileGlossary}
        filehandleChangeFiles={filehandleChangeFiles}
        filehandleChangeGlossaryFiles={filehandleChangeGlossaryFiles}
        transformLine={transformLine}
        hideTranslateButton={hideTranslateButton}
        setHideTranslateButton={setHideTranslateButton}
        mode={mode}
        model={model}
        handleModel={handleModel}
        setTransformLine={setTransformLine}
      />
    </>
  );
};
export default UserWorkspaceTableContainer;

// const UserWorkSpaceEnhancedComponent = withUserWorkSpaceTableContainer(<TabContainer />);

// export default UserWorkSpaceEnhancedComponent;
