/* eslint-disable react/button-has-type */
import React, { useEffect, useState, useRef, memo, useMemo } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { ClickAwayListener, IconButton } from '@material-ui/core';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Select, {
  OptionDiv,
  SelectContainer,
  InputDiv,
  Input,
  SubMenuListColumn,
  SubMenuListDiv,
  Button,
  Cross,
} from '../styles/MultiColumnsSelectorStyles';

const MultiColumnsSelector = ({
  label,
  OnChangeLangugae,
  languageCode,
  value: placeValue,
}: any) => {
  const [show, setShow] = useState(false);
  const [text, setText] = useState('');
  const [value, setValue] = useState('');
  const [lang, setLang] = useState(languageCode);

  useMemo(() => {
    setLang(languageCode);
  }, [languageCode]);
  // console.log(languageCode);

  const menuRef = useRef(null);

  const handleLangugeValue = (value) => {
    setValue(value);
    OnChangeLangugae(value);
    if (show) setShow(false);
  };

  useEffect(() => {
    const menu = menuRef.current;
    const menuWidth = menu.offsetWidth;
    const windowWidth = window.innerWidth;
    const menuPosition = menu.getBoundingClientRect().left;
    // console.log(menuWidth + menuPosition);
    // console.log(windowWidth);
    const handleResize = () => {
      if (menuWidth + menuPosition > windowWidth) {
        // console.log('minus', windowWidth - menuWidth);
        menu.style.right = `${0}px`;
      } else {
        menu.style.right = '';
      }
    };
    handleResize();
  }, [label]);

  // console.log(languageCode);

  const handleFilter = (e) => {
    setText(e.target.value);
    const filtereData = languageCode.filter((data) => {
      const low = data.toLowerCase();
      const v = e.target.value.toLowerCase();
      return low.includes(v);
    });
    setLang(filtereData);
  };

  // console.log(label);
  // console.log(placeValue);
  return (
    <>
      <SelectContainer>
        <Select
          onClick={() => {
            setShow(true);
          }}
          style={show ? { border: '1px solid #002a45' } : {}}
        >
          <p style={{ margin: 0, padding: 0 }}>{!placeValue ? label.split('-')[0] : placeValue}</p>

          <IconButton
            style={{ padding: 0, position: 'absolute', right: 0 }}
            // onClick={() => {
            //   setShow(true);
            // }}
          >
            <KeyboardArrowDownIcon />
          </IconButton>
        </Select>
        <ClickAwayListener
          mouseEvent="onMouseDown"
          touchEvent="onTouchStart"
          onClickAway={() => show && setShow(false)}
        >
          <OptionDiv ref={menuRef} style={{ visibility: show ? 'visible' : 'hidden', zIndex: 999 }}>
            <InputDiv>
              <Input
                type="text"
                placeholder="Enter Language"
                onChange={(e) => {
                  handleFilter(e);
                }}
              />
              <Cross>
                <IconButton
                  style={{ padding: 0 }}
                  onClick={() => {
                    setShow(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Cross>
            </InputDiv>
            <SubMenuListDiv>
              <SubMenuListColumn>
                {lang.slice(0, 18).map((data: string) => {
                  const name = data.split('-').shift();
                  // const { language } = data;
                  return (
                    <div key={data}>
                      <Button
                        style={
                          placeValue === data
                            ? {
                                background: '#dae2f8',
                                border: '1px solid #ccd6f2',
                                borderRadius: '5px',
                              }
                            : {}
                        }
                        // className={`sub-menu-item-btn${label === data ? '-selected' : ''}`}
                        // className="sub-menu-item-btn"
                        onClick={() => {
                          handleLangugeValue(data);
                        }}
                      >
                        {name}
                      </Button>
                    </div>
                  );
                })}
              </SubMenuListColumn>
              <SubMenuListColumn>
                {lang.slice(18, 36).map((data: string) => {
                  const name = data.split('-').shift();
                  return (
                    <div key={data}>
                      <Button
                        style={
                          placeValue === data
                            ? {
                                background: '#dae2f8',
                                border: '1px solid #ccd6f2',
                                borderRadius: '5px',
                              }
                            : {}
                        }
                        // className={`sub-menu-item-btn${label === data ? '-selected' : ''}`}
                        // className="sub-menu-item-btn"
                        onClick={() => {
                          handleLangugeValue(data);
                        }}
                      >
                        {name}
                      </Button>
                    </div>
                  );
                })}
              </SubMenuListColumn>
              <SubMenuListColumn>
                {lang.slice(36, 54).map((data: string) => {
                  const name = data.split('-').shift();
                  return (
                    <div key={data}>
                      <Button
                        style={
                          placeValue === data
                            ? {
                                background: '#dae2f8',
                                border: '1px solid #ccd6f2',
                                borderRadius: '5px',
                              }
                            : {}
                        }
                        // className={`sub-menu-item-btn${valueLang === data ? '-selected' : ''}`}
                        // className="sub-menu-item-btn"
                        onClick={() => {
                          handleLangugeValue(data);
                        }}
                      >
                        {name}
                      </Button>
                    </div>
                  );
                })}
              </SubMenuListColumn>
              <SubMenuListColumn>
                {lang.slice(54, 70).map((data: string) => {
                  const name = data.split('-').shift();
                  return (
                    <div key={data}>
                      <Button
                        style={
                          placeValue === data
                            ? {
                                background: '#dae2f8',
                                border: '1px solid #ccd6f2',
                                borderRadius: '5px',
                              }
                            : {}
                        }
                        // className={`sub-menu-item-btn${valueLang === data ? '-selected' : ''}`}
                        // className="sub-menu-item-btn"
                        onClick={() => {
                          handleLangugeValue(data);
                        }}
                      >
                        {name}
                      </Button>
                    </div>
                  );
                })}
              </SubMenuListColumn>
            </SubMenuListDiv>
          </OptionDiv>
        </ClickAwayListener>
      </SelectContainer>
    </>
  );
};

export default memo(MultiColumnsSelector);
