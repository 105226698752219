export const topicUrgencyLevels = [
  {
    label: 'Within the next 3 months',
    value: 1,
  },
  {
    label: 'To be defined',
    value: 2,
  },
];

export const topicTypeLevels = [
  {
    label: 'Idea',
    value: 'idea',
  },
  {
    label: 'Demand',
    value: 'demand',
  },
];
