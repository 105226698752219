/* eslint-disable no-param-reassign */
import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  ColumnChooser,
  Grid as ExpressGrid,
  PagingPanel,
  Table,
  TableColumnResizing,
  TableColumnVisibility,
  TableFilterRow,
  TableHeaderRow,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import { Dialog, DialogTitle, Grid, IconButton, makeStyles, Paper } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { useCallback, useEffect, useRef, useState } from 'react';
// import '../../../../../assets/treeview.css';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import Loading from '../../../components/Core/Loading';
import TableComponent from '../../../components/Datagrid/TableComponent';
import {
  tableContainerComponent,
  tableHeaderComponent,
  toolbarComponent,
} from '../../../components/Datagrid/TStyles';
import ToolBarPlugin from '../../../components/Datagrid/ToolBarPlugin';
import { get_allocationActivity_byId } from '../../../api/allocationNew';

// import Loading from '../../components/Core/Loading';
// import TableComponent from '../../components/Datagrid/TableComponent';
// import ToolBarPlugin from '../../components/Datagrid/ToolBarPlugin';
// import {
//   tableContainerComponent,
//   tableHeaderComponent,
//   toolbarComponent,
// } from '../../components/Datagrid/TStyles';
// import { handleColumns } from '../../tools/datagrid';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContent: {
    backgroundColor: theme.palette.action.hover,
    fontSize: '1rem',
  },
  heading: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    padding: 10,
  },
  textField: {
    margin: '15px 5px',
    paddingRight: 10,
  },
  radio: {
    textAlign: 'center',
  },
  label: {
    marginRight: '30px',
  },
}));

type Props = {
  rows: any[];
  //   template?: {
  //     name: string;
  //     version: string;
  //   };
  onClose?: any;
};
// const columns = ['isens_allocation', 'requirement_text', 'explanation', 'created_at'];
const columns = [
  { name: 'isens_allocation', title: 'allocation', width: 200 },
  { name: 'requirement_text', title: 'requirement_text', width: 200 },
  { name: 'explanation', title: 'explanation', width: 200 },
  { name: 'created_at', title: 'created_at', width: 200 },
];

export default function ModalAllocation({ rows, onClose }: Props) {
  // console.log('modalrow', rows);
  const classes = useStyles();

  const [loading, setLoading] = useState<any>(false);
  const [toggleHeight, setToggleHeight] = useState(false);
  const [gridRows, setGridRows] = useState<any[]>([]);

  const exporterRef = useRef(null);
  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);
  const onSave = (workbook: any) => {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'data.xlsx');
    });
  };

  useEffect(() => {
    setLoading(true);
    get_allocationActivity_byId(rows.id)
      .then((response) => {
        // console.log(response);
        setGridRows(response);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const renderFunction = ({ value }: DataTypeProvider.ValueFormatterProps) => {
    return (
      <>
        <b>{value}</b>
      </>
    );
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Paper>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={true}
        maxWidth="lg"
        fullWidth={true}
      >
        <DialogTitle id="customized-dialog-title" className={classes.heading}>
          {/* Template : {template?.name} version : {template?.version} */}
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {loading ? (
          <Loading />
        ) : (
          <>
            <Grid container>
              <Grid item xs={12}>
                <ExpressGrid rows={gridRows} columns={columns}>
                  {/* <DataTypeProvider for={['function']} formatterComponent={renderFunction} /> */}

                  {/* filtering */}
                  <FilteringState />
                  <SortingState />
                  <IntegratedSorting />
                  <IntegratedFiltering />
                  <PagingState defaultCurrentPage={0} defaultPageSize={10} />
                  <IntegratedPaging />
                  <Table
                    columnExtensions={columns.map((c) => ({
                      columnName: c.name,
                      wordWrapEnabled: true,
                    }))}
                    tableComponent={TableComponent}
                    containerComponent={tableContainerComponent}
                    headComponent={tableHeaderComponent}
                  />
                  <TableHeaderRow showSortingControls />
                  <TableFilterRow />
                  <TableColumnVisibility defaultHiddenColumnNames={[]} />
                  <Toolbar rootComponent={toolbarComponent} />
                  <ColumnChooser />
                  <ToolBarPlugin name="Download" onClick={startExport} />
                  <ToolBarPlugin
                    name="Height"
                    title="Wrap Text"
                    onClick={() => {
                      setToggleHeight(!toggleHeight);
                    }}
                  />
                  <PagingPanel pageSizes={[10, 20, 50]} />
                </ExpressGrid>
                <GridExporter ref={exporterRef} rows={gridRows} columns={columns} onSave={onSave} />
              </Grid>
            </Grid>
          </>
        )}
      </Dialog>
    </Paper>
  );
}
