/* eslint-disable react/button-has-type */
import { Box, Tooltip as CustomTooltip, makeStyles, Theme } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import TranslateIcon from '@mui/icons-material/Translate';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import UploadIcon from '@mui/icons-material/Upload';
import useAppToken from '../../../app/useAppToken';
import DragAndDrop from '../../Core/DragAndDrop';
import UserUploadFile, { UserUploadGlossaryFile } from '../API/UserUploadFile';
import DocumentFiles from '../Components/DocumentFiles';
import FileStatus from '../Components/FileStatus';
import FileTranslateHistory from '../Components/FileTranslateHistory';
import GlossaryFiles from '../Components/GlossaryFiles';
import FileTranslatorContext from '../FileTranslatorContext';
import TabContainerDiv, {
  TabBtnContainer,
  Button,
  TabHeader,
  Line,
  DropDownContainer,
  TabMain,
  GlossaryButton,
} from '../styles/TabContainerStyle';
import MultiColumnsSelector from './MultiColumnsSelector';
import {
  UserWorkloadCreate,
  userWorkloadRunPostForTranslator,
  workloadRunPostForTranslator,
} from '../../../api/workloads';
import ModalConfirm from '../../../containers/Admin/components/ModalConfirm';
import { deepl_language } from '../../../const';
import DropDownLanguages from '../../TextTranlator/Components/DropDownLanguages';

const useStyles = makeStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: 16,
    // textAlign: 'justify',
    border: '1px solid #dadde9',
  },
  btnToggle: {
    height: '2.3rem',
    '&.MuiToggleButtonGroup-root': {
      border: '1px solid',
      borderColor: theme.palette.primary.main,
      borderRadius: '30px !important',
      backgroundColor: 'white',
    },
    '& .MuiToggleButton-root': {
      color: 'black',
      textTransform: 'none',
      paddingLeft: '15px',
      paddingRight: '15px',
      border: '0',
      '&.Mui-selected': {
        borderRadius: '30px !important',
        borderColor: theme.palette.secondary.main,
        background: 'rgb(0, 42, 69)',
        color: 'white',
      },
    },
  },
}));

const TabContainer = (props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const classes = useStyles();
  const {
    draghandleChangeFiles,
    draghandleChangGlossaryeFiles,
    workLoads,
    OnChangeFromLangugae,
    OnChangeToLangugae,
    languageCode,
    fileSelection,
    TranslateFile,
    selectedFileData,
    GlossaryFromLang,
    GlossaryToLang,
    FromLang,
    ToLang,
    setSnack,
    UploadFile,
    UploadFileGlossary,
    hideTranslateButton,
    transformLine,
    mode,
    model,
    handleModel,
    setTransformLine,
  } = props;

  const destLanguageOptions = useMemo(() => {
    return model === 'DeepL'
      ? deepl_language.map((item) => {
          return `${item.language}-${item.code}`;
        })
      : languageCode;
  }, [model, languageCode]);

  // console.log('destLanguageOptions', ToLang);

  // console.log(FromLang);
  // console.log(ToLang);

  const HandleComponent = () => {
    switch (transformLine) {
      case '0rem':
        return (
          <DragAndDrop onChange={draghandleChangeFiles}>
            <DocumentFiles />
          </DragAndDrop>
        );
      case 'glossary':
        return (
          <DragAndDrop onChange={draghandleChangGlossaryeFiles}>
            <GlossaryFiles />
          </DragAndDrop>
        );

      case '6rem':
        return <FileStatus workLoads={workLoads} setTransformLine={setTransformLine} mode={mode} />;
      case '12rem':
        return <FileTranslateHistory mode={mode} />;

      default:
        return null;
    }
  };

  const ToolTipComponent = ({ children }) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const rows = [
      { col1: 'Fluid', col2: 'Fluide' },
      { col1: 'Fuel', col2: 'Combustible' },
      { col1: 'Fuse', col2: 'Fusible' },
      { col1: 'Pneumatic', col2: 'Pneumatique' },
    ];

    return (
      <>
        <div
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          {children}{' '}
          {/* <div
            style={{
              position: 'absolute',
              top: '8px',
              right: '3px',
              height: '15px',
              width: '15px',
              borderRadius: '50%',
              // backgroundColor: 'red',
              border: ' 1px solid',
              color: 'white',
              padding: '2px',
              fontSize: '9px',
              textAlign: 'center',
            }}
          >
            i
          </div> */}
        </div>
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: 'none',
          }}
          PaperProps={{
            style: {
              width: '25rem',
              backgroundColor: '#002A45',
              color: 'white',
              borderRadius: '19px',
              textAlign: 'justify',
              marginTop: '1.2rem',
            },
          }}
          // style={{
          //   width: '40rem',
          //   backgroundColor: '#002A45',
          //   // color: 'white',
          //   // borderRadius: '19px',
          // }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography sx={{ p: 1 }} style={{ fontSize: '0.98em' }}>
            {' '}
            A glossary is a list of terms with definitions that you can create for the Document
            Translation service to use during the translation process.
          </Typography>
          <Typography sx={{ p: 1 }} style={{ fontSize: '0.98em' }}>
            {' '}
            The following shall be the format of the glossary containing two columns: (
            <b> note :- only CSV file is supported </b>)
          </Typography>
          <Paper sx={{ overflow: 'hidden', margin: '20px' }}>
            <TableContainer sx={{ maxHeight: 300 }}>
              <Table size="small" aria-label="caption table">
                {/* <caption>A basic table example with a caption</caption> */}

                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.col1}>
                      <TableCell scope="row">{row.col1}</TableCell>
                      <TableCell align="left">{row.col2}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Popover>
      </>
    );
  };

  const HandleButton = () => {
    const handleTranslate = () => {
      // console.log(selectedFileData);
      // console.log(ToLang);
      let flag = false;
      const toLanguageData = selectedFileData.map((data) => {
        const { glossaryFile } = data;
        if (glossaryFile) {
          flag = true;
          return glossaryFile.toLang;
        }

        return null;
      });

      // if (!FromLang) {
      //   setSnack('Please select From language', 'error');
      //   return;
      // }

      if (!ToLang) {
        setSnack('Please select To language', 'error');
        return;
      }
      if (fileSelection.length === 0) {
        setSnack('Please select any file', 'error');
        return;
      }

      // console.log(toLanguageData);
      if (toLanguageData.length > 0 && !toLanguageData.includes(ToLang) && flag) {
        setModalOpen(true);
      } else {
        TranslateFile();
      }
    };

    // GlossaryFromLang,
    // GlossaryToLang,
    // FromLang,
    // ToLang,
    switch (transformLine) {
      case 'glossary':
        return (
          <>
            <MultiColumnsSelector
              label="From-glossary"
              value={GlossaryFromLang}
              OnChangeLangugae={(args: any) => {
                OnChangeFromLangugae(args, 'Change_in_From_Lang_for_glossary');
              }}
              languageCode={languageCode}
            />
            <MultiColumnsSelector
              label="To-glossary"
              value={GlossaryToLang}
              OnChangeLangugae={(args: any) => {
                OnChangeToLangugae(args, 'Change_in_To_Lang_for_glossary');
              }}
              languageCode={languageCode}
            />
            <Button
              style={{
                backgroundColor: '#002a45',
                color: 'white',
                borderRadius: '5px',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '0.5rem',
                width: '8rem',
              }}
              onClick={UploadFileGlossary}
            >
              <UploadIcon />
              Upload
            </Button>
          </>
        );
      case '0rem':
        return (
          <>
            {/* <MultiColumnsSelector
              label="From"
              value={FromLang}
              OnChangeLangugae={(args: any) => {
                OnChangeFromLangugae(args, 'Change_in_From_Lang');
              }}
              languageCode={languageCode}
            /> */}
            <DropDownLanguages
              languageCode={destLanguageOptions}
              onChange={(args: any) => {
                console.log('args', args);
                OnChangeToLangugae(args, 'Change_in_To_Lang');
              }}
              // setValueLang={setDestLang}
              valueLang={ToLang}
              row_gap={model === 'DeepL' ? 9 : 18}
              palceHolder="Enter translate to"
              file={true}
            />

            {/* <MultiColumnsSelector
              label="To-file"
              value={ToLang}
              OnChangeLangugae={(args: any) => {
                OnChangeToLangugae(args, 'Change_in_To_Lang');
              }}
              languageCode={destLanguageOptions}
            /> */}
            {fileSelection.length > 0 ? (
              <Button
                style={{
                  backgroundColor: hideTranslateButton ? 'grey' : '#002a45',
                  color: hideTranslateButton ? '#002a45' : 'white',
                  cursor: hideTranslateButton ? 'none' : 'pointer',
                  borderRadius: '5px',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '0.5rem',
                  width: '8rem',
                }}
                disabled={hideTranslateButton}
                onClick={handleTranslate}
              >
                <TranslateIcon />
                Translate
              </Button>
            ) : (
              <Button
                style={{
                  backgroundColor: '#002a45',
                  color: 'white',
                  borderRadius: '5px',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '0.5rem',
                  width: '8rem',
                }}
                onClick={UploadFile}
              >
                <UploadIcon />
                Upload
              </Button>
            )}
          </>
        );

      // case '12rem':
      //   return <FileStatus />;
      // case '18rem':
      //   return <FileTranslateHistory />;

      default:
        return null;
    }
  };

  return (
    <>
      {modalOpen && (
        <ModalConfirm
          open={modalOpen}
          onConfirm={() => {
            setModalOpen(false);
            TranslateFile();
          }}
          onCancel={() => {
            setModalOpen(false);
          }}
          label="glossary"
          // label={rowsEdited.length ? 'characterizer' : 'waitCharaterizer'}
        />
      )}
      <TabContainerDiv>
        <TabHeader>
          <TabBtnContainer>
            <div style={{ display: 'flex' }}>
              <Button
                onClick={() => {
                  setTransformLine('0rem');
                }}
              >
                Documents
              </Button>

              <Button
                onClick={() => {
                  setTransformLine('6rem');
                }}
              >
                status
              </Button>
              <Button
                onClick={() => {
                  setTransformLine('12rem');
                }}
              >
                History
              </Button>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', gap: '5px' }}>
              {model !== 'DeepL' && (
                <>
                  {['0rem', 'glossary'].includes(transformLine) && (
                    <ToolTipComponent>
                      {' '}
                      <GlossaryButton
                        onClick={() => {
                          setTransformLine('glossary');
                        }}
                      >
                        <div>Glossary</div>{' '}
                      </GlossaryButton>
                    </ToolTipComponent>
                  )}
                  <Line
                    style={
                      transformLine !== 'glossary'
                        ? { left: transformLine }
                        : { right: '0rem', transition: 'right 0.5s' }
                    }
                  >
                    {}
                  </Line>
                </>
              )}

              {transformLine === '0rem' && (
                <CustomTooltip
                  title="If you are not satisfied with translation, consider chossing a different translation model"
                  arrow
                  classes={{ tooltip: classes.tooltip }}
                >
                  <ToggleButtonGroup
                    value={model}
                    exclusive
                    onChange={(e, value) => {
                      return handleModel(value);
                    }}
                    aria-label="Platform"
                    className={classes.btnToggle}
                  >
                    <ToggleButton size="small" value="Microsoft">
                      Microsoft
                    </ToggleButton>

                    <ToggleButton size="small" value="DeepL">
                      DeepL
                    </ToggleButton>
                  </ToggleButtonGroup>
                </CustomTooltip>
              )}

              <Line
                style={
                  transformLine !== 'glossary'
                    ? { left: transformLine }
                    : { right: '0rem', transition: 'right 0.5s' }
                }
              >
                {}
              </Line>
            </div>
          </TabBtnContainer>
          <DropDownContainer>{HandleButton()}</DropDownContainer>
        </TabHeader>
        <TabMain>{HandleComponent()}</TabMain>
      </TabContainerDiv>
    </>
  );
};

export default TabContainer;
