/**
 * This component renders a Wecome HomePage with shortcuts.
 * Css also fixed here
 * @param {string} No props
 * fixed new Ui of ShortCuts button
 * @returns {ReactNode} A React element that HomePage.
 *  Copyright @ Alstom 2023
 *  auther @Ranjan
 */

/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Avatar,
  Button,
  Grid,
  IconButton,
  List,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Paper,
  Popover,
  Typography,
} from '@material-ui/core';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { isEqual } from '@antv/util';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import ChatIcon from '@mui/icons-material/Chat';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import ImageIcon from '@mui/icons-material/Image';
import QuestionAnswerOutlinedIcon from '@material-ui/icons/QuestionAnswerOutlined';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import ShortcutOutlinedIcon from '@mui/icons-material/ShortcutOutlined';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ClearIcon from '@mui/icons-material/Clear';
import TranslateOutlinedIcon from '@material-ui/icons/TranslateOutlined';
import GroupsIcon from '@mui/icons-material/Groups';
import logo from '../../../assets/FinalLogo.png';
import logobackground from '../../../assets/logobackground.png';
import chat_home from '../../../assets/images/chat_icon_mini.png';
import translatorIcon from '../../../assets/shorcutsIcons/translatorIcon.png';
import GenaIcon from '../../../assets/shorcutsIcons/GenaIcon.png';
import similaritySearchIcon from '../../../assets/shorcutsIcons/similaritySearchIcon.png';
import summrizerTextIcon from '../../../assets/shorcutsIcons/summrizerTextIcon.png';
import expertSearchIcon from '../../../assets/shorcutsIcons/expertSearchIcon.png';
import rex360Icon from '../../../assets/shorcutsIcons/rex360Icon.png';
import peopleSearchIcon from '../../../assets/shorcutsIcons/peopleSearchIcon.png';
import ontologyIcon from '../../../assets/shorcutsIcons/ontologyIcon.png';
import contentSearchIcon from '../../../assets/shorcutsIcons/contentSearchIcon.png';
import GenerateImageIcon from '../../../assets/shorcutsIcons/GenerateImageIcon.png';
import askQuestionIcon from '../../../assets/shorcutsIcons/askQuestionIcon.png';
import './App.css';
import Usercustomprofile from './Usercustomprofile';
import rexSvgIcon from '../../../assets/rexicons/rexSvgIcon.svg';
import { textAnalysis, ListItem } from '../../../components/Filter/textAnalysis';
import useShortCuts from '../../Module2/NewModule/TextAnaylsis/core/useShortCuts';
import ProjectContext from '../../Project/ProjectContext';
import useAppToken from '../../../app/useAppToken';
import { getShortcuts, updateShortcuts } from '../../../api/shortcuts';
import UserProfile from '../../User/components/UserProfile';
import HomePageMenu from './HomePageMenu';
import WorkSpaceMenu from './WorkSpaceMenu';

// All css  style here

const RexIcon = () => {
  return (
    <>
      <img src={rexSvgIcon} alt="rex" />
    </>
  );
};
// all tabs with name, icons and color data

const Homepage = () => {
  const history = useHistory();

  // const { shortCuts, setShortCuts } = useShortCuts();
  const {
    shortCuts,
    setShortCuts,
    projects_lite,
    projects,
    projectBread,
    setProjectBread,
    userSetting,
    setUserSetting,
    open,
    setOpen,
  } = useContext(ProjectContext);

  // const [open, setOpen] = useState<boolean>(false);
  const { appJWT } = useAppToken();
  const [modalUserOpen, setModalUserOpen] = React.useState(false);
  const [anchorE2, setAnchorE2] = React.useState(null);
  // const [projectid, setProjectid] = useState();
  // const classes = useStyles();

  useEffect(() => {
    if (projects.length > 0) {
      setProjectBread(projects[0]?.id);
    } else if (projects_lite.length > 0) {
      setProjectBread(projects_lite[0]?.id);
    }
  }, [projects]);

  const handleModalProfileShut = (e) => {
    setAnchorE2(null);
    setModalUserOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <div
        style={{
          textAlign: 'center',
          background: `url('${logobackground}')`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          marginTop: -3,
          padding: '1.5rem',
          position: 'relative',
        }}
      >
        {/* <WorkSpaceMenu /> */}
        <div style={{ position: 'absolute', left: 0, top: 10, width: '100%' }}>
          <WorkSpaceMenu profileData={userSetting} />
        </div>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="space-evenly"
          style={{ minHeight: '80vh' }}
        >
          <div style={{ display: 'flex', alignSelf: 'flex-start' }}>
            <Usercustomprofile
              userId={appJWT?.user_id}
              profileData={userSetting}
              open={open}
              setOpen={setOpen}
              setUserSetting={setUserSetting}
            />
          </div>
          <Grid item xs={12}>
            <div>
              {/* <img src={logo} alt="logo" style={{ width: 600 }} useMap="#image-map" />
            <map name="image-map">
              <area
                target="_blank"
                alt="community"
                title="community"
                href="https://alstomgroup.sharepoint.com/sites/iSenS_Communication"
                coords="7,185,179,116"
                shape="rect"
              />
              <area
                target="_self"
                alt="myprojects"
                title="My Projects"
                href="#"
                coords="57,299,218,236"
                shape="rect"
                onClick={() => history.push('/projects')}
              />
              <area
                target="_self"
                alt="myprojects"
                title="Engg Readiness"
                href="#"
                coords="373,297,549,230"
                shape="rect"
                onClick={() => history.push('/me/enggReadinessHome')}
              />
              <area
                target="_self"
                alt="contacts"
                title="Contacts"
                href="#contacts"
                onClick={() => history.push('/me/contact')}
                coords="424,182,594,128"
                shape="rect"
              />
              <area
                target="_self"
                alt="mailbox"
                title="My Inbox"
                href="#"
                onClick={() => history.push('/me/inbox')}
                coords="373,75,543,12"
                shape="rect"
              />
              <area
                target="_self"
                alt="myinformation"
                title="My Information"
                href="#"
                onClick={() => history.push('/me/profile')}
                coords="52,72,219,6"
                shape="rect"
              />
            </map> */}
              <img src={logo} alt="logo" style={{ width: 600 }} useMap="#image-map" />
              <map name="image-map">
                <area
                  target="_blank"
                  alt="community"
                  title="community"
                  href="https://alstomgroup.sharepoint.com/sites/iSenS_Communication"
                  coords="5,142,186,221"
                  shape="rect"
                />
                <area
                  target="_self"
                  alt="myprojects"
                  title="My Projects"
                  href="#"
                  coords="220,270,391,353"
                  shape="rect"
                  onClick={() => history.push('/projects')}
                />
                <area
                  target="_self"
                  alt="contacts"
                  title="Contacts"
                  href="#"
                  onClick={() => history.push('/me/contact')}
                  coords="413,147,598,225"
                  shape="rect"
                />
                <area
                  target="_self"
                  alt="mailbox"
                  title="My Inbox"
                  href="#"
                  coords="384,7,561,80"
                  onClick={() => history.push('/me/inbox')}
                  shape="rect"
                />
                <area
                  target="_self"
                  alt="myinformation"
                  title="My Information"
                  href="#"
                  onClick={() => setModalUserOpen(true)}
                  coords="39,5,224,77"
                  shape="rect"
                />
              </map>
              <UserProfile
                open={modalUserOpen}
                onConfirm={handleModalProfileShut}
                onCancel={handleModalProfileShut}
                label="ProjectDelete"
              />
            </div>
            <p>
              Copyright © Alstom SA 2024
              <br />
              iSenS Version: 4.2.0
              {/* {ENV === 'production' ? <>{APP_VERSION}</> : <Link to="/version">{APP_VERSION}</Link>} */}
            </p>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Homepage;
