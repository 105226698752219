import React, { useContext, useEffect, useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TranslateIcon from '@mui/icons-material/TranslateOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
// import './m3ToggleStyle.css';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Paper, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import ProjectContext from '../../Project/ProjectContext';
import Idca from '../components/Idca';
import IQualimeter from '../components/IQualimeter';
import IverifierProjectConfigs from '../../Iverifier/IverifierProjectConfigs';
import { ENV } from '../../../const';
import TestGenerator from './Component/TestGenerator';

const AntTabs = withStyles((theme) => ({
  root: {
    marginTop: 5,
    border: '1px solid #002a45',
    borderRadius: 22,
    // float: 'right',
  },
  indicator: {
    display: 'none', // Set the display property to none
  },
}))(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    '&.MuiTab-labelIcon': {
      minHeight: '0vh',
      paddingTop: 11,
    },
    '& .MuiTab-wrapper': {
      flexDirection: 'row',
    },
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(0),
    flexGrow: 1,
    // padding: '10px 20px',
    cursor: 'pointer',
    fontSize: '0.9rem',
    background: 'white',
    // transition: 'color 0.5s',
    transition: 'background-color 0.5s',
    borderRadius: '30px',
    border: 'none',
    outline: 'none',
    position: 'relative',
    textAlign: 'center',
    color: '#002a45',
    opacity: 1,
    // '&:hover': {
    //   color: '#40a9ff',
    //   opacity: 1,
    //   background: '#1890ff',
    // },
    '&$selected': {
      color: '#fff',
      fontWeight: theme.typography.fontWeightMedium,
      background: '#002a45',
      boxShadow: '0px 4px 8px #00000029',
      transition: '1.0s',
    },
    '&:focus': {
      color: 'white',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

function Module3ContainerNew() {
  const [value, setValue] = React.useState(0);
  const [tabs, setTabs] = useState([
    { service: 'iDCA', icons: <DescriptionOutlinedIcon /> },
    { service: 'iQualimeter', icons: <VerifiedUserOutlinedIcon /> },
    { service: 'iVerifier', icons: <CheckCircleOutlineIcon /> },
    { service: 'iTestGenerator', icons: <StickyNote2OutlinedIcon /> },
  ]);
  const { projectId, workloadId } = useParams() as any;
  const { userSetting } = useContext(ProjectContext);
  const queryParams = new URLSearchParams(window.location.search);
  const actionIs = queryParams.get('action');
  const [openTreeItem, setOpenTreeItem] = useState<any>([]);
  const [submodule3_0, setSubModule3_0] = useState('');
  const [submodule3_1, setSubModule3_1] = useState('');
  const [submodule3_2, setSubModule3_2] = useState('');
  const [submodule3_3, setSubModule3_3] = useState('');

  useEffect(() => {
    userSetting.map((data: any) => {
      data?.m3.map((item: any) => {
        if (item?.value === 'iTestGenerator') {
          setSubModule3_0(item?.value);
        } else if (item?.value === 'iQualimeter') {
          setSubModule3_1(item?.value);
        } else if (item?.value === 'iVerifier') {
          setSubModule3_2(item?.value);
        } else if (item?.value === 'iDCA') {
          setSubModule3_3(item?.value);
        }
        return 0;
      });
      return 0;
    });
  }, [userSetting]);
  useEffect(() => {
    if (
      submodule3_1 === 'iQualimeter' ||
      submodule3_2 === 'iVerifier' ||
      submodule3_3 === 'iDCA' ||
      submodule3_0 === 'iTestGenerator'
    ) {
      setTabs(
        [
          { service: submodule3_3, icons: <DescriptionOutlinedIcon /> },
          { service: submodule3_1, icons: <VerifiedUserOutlinedIcon /> },
          { service: submodule3_2, icons: <CheckCircleOutlineIcon /> },
          { service: submodule3_0, icons: <StickyNote2OutlinedIcon /> },
        ].filter((item) => item?.service !== '')
      );
    }
  }, [submodule3_1, submodule3_2, submodule3_3, submodule3_0]);
  useEffect(() => {
    if (actionIs) {
      const index = tabs.findIndex((tab) => tab?.service === actionIs);
      if (index > -1) setValue(index);
    }
  }, [actionIs, tabs]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const renderComponent = (service) => {
    switch (service) {
      case 'iDCA':
        return <Idca />;
      case 'iQualimeter':
        return <IQualimeter id={workloadId ? Number(workloadId) : undefined} />;
      case 'iVerifier':
        return <IverifierProjectConfigs project_id={Number(projectId)} />;
      case 'iTestGenerator':
        return <TestGenerator />;
      default:
        return null;
    }
  };

  return (
    <>
      <div style={{ border: '2px solid black', padding: 5, margin: 10 }}>
        <AntTabs
          value={value}
          onChange={handleChange}
          style={{
            maxWidth: `${150 * tabs.length}px`,
            width: '100%',
            float: 'inline-end',
            // float: 'right',
            // width: 'fit-content',
          }}
        >
          {tabs.map((tab, index) => (
            <AntTab label={tab?.service} key={index} icon={tab.icons} />
          ))}
          {/* <AntTab label="Tab 1" icon={<TranslateIcon />} /> */}
        </AntTabs>
        {/* <TransitionGroup>
        <CSSTransition key={value} classNames="fade" timeout={500}>
          <div className="tab-content">
            {value === 0 && (
              <Typography component="div" style={{ padding: 8 * 3 }}>
                Tab 1 Content
              </Typography>
            )}
            {value === 1 && (
              <Typography component="div" style={{ padding: 8 * 3 }}>
                Tab 2 Content
              </Typography>
            )}
            {value === 2 && (
              <Typography component="div" style={{ padding: 8 * 3 }}>
                Tab 3 Content
              </Typography>
            )}
          </div>
        </CSSTransition>
      </TransitionGroup> */}
        {/* {value === 0 && <Typography component="div">Tab 1 Content</Typography>} */}
        {value >= 0 ? (
          <Grid container>
            <Grid item xs={12}>
              <br />
              {renderComponent(tabs[value]?.service)}
            </Grid>
          </Grid>
        ) : (
          <Typography variant="h6" align="center">
            No tabs available
          </Typography>
        )}
        {/* <Grid container>
          {value === 0 && (
            <Grid item xs={12}>
              <Idca />
            </Grid>
          )}
          {value === 1 && (
            <Grid item xs={12}>
              <br />
              <IQualimeter id={workloadId ? Number(workloadId) : undefined} />
            </Grid>
          )}
          {value === 2 && (
            <Grid item xs={12}>
              <br />
              <IverifierProjectConfigs project_id={Number(projectId)} />
            </Grid>
          )}
          {value === 3 && (
            <Grid item xs={12}>
              <br />
              <TestGenerator />
            </Grid>
          )} 
        </Grid> */}
      </div>
    </>
  );
}

export default Module3ContainerNew;
