import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSelection,
  IntegratedSorting,
  PagingState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  Grid as ExpressGrid,
  Table,
  PagingPanel,
  TableFilterRow,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
  TableColumnVisibility,
  ColumnChooser,
  Toolbar,
  TableColumnResizing,
} from '@devexpress/dx-react-grid-material-ui';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
// import Tooltip as NewTooltips, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { useHistory, useParams } from 'react-router-dom';
import { Theme, useTheme } from '@material-ui/core/styles';
import { makeStyles, withStyles } from '@material-ui/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Switch from '@material-ui/core/Switch';
import {
  Avatar,
  Button,
  Card,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from '@material-ui/core';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import {
  HeatMapComponent,
  Inject,
  Legend as LegendHeat,
  Tooltip as TooltipHeat,
} from '@syncfusion/ej2-react-heatmap';
import { Workload3Create } from 'WorkloadType';
import MultiSelect from 'react-multi-select-component';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import FontDownloadOutlinedIcon from '@material-ui/icons/FontDownloadOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import ReactJson from 'react-json-view';
import Loading from '../../../components/Core/Loading';
import { workloadCreate, workloadGetById, workloadRunPost } from '../../../api/workloads';
import {
  incoseFile,
  incoseText,
  incoseTextNew,
  incoseDatasetDetails,
  incoseDataset,
} from '../../../api/module2';
import removeFilter1 from '../../../assets/removeFilter1.png';
import {
  getiQualimeterFilterData,
  inQualimeterDataset,
  getiQualimeterRules,
} from '../../../api/module3';
import Module1Context from '../Module3Context';
import formatBytes from '../../../tools/formatBytes';
import { fileDetails } from '../../../api/module1';
import ToolBarPlugin from '../../../components/Datagrid/ToolBarPlugin';
import TStyles, {
  rowComponent,
  tableAllocatorComponent,
  toolbarComponent,
  tUseStyles,
} from '../../../components/Datagrid/TStyles';
import TabStyle from '../../../components/Layout/TabStyle';
import IQulimeterFile from './IQulimeterFile';
import NavigationGoto from '../../../components/Core/NavigationGoto';
import { ENV } from '../../../const';
import { CustomWidthTooltip } from '../../../components/Core/CustomWidthTooltip';
import NavigationGotoNew from '../../../components/Core/NavigationGotoNew';
import MultiAllocTableComp from '../../../components/Datagrid/MultiAllocTableComp';

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}
// const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
//   <NewTooltips {...props} classes={{ popper: className }} />
// ))({
//   [`& .${tooltipClasses.tooltip}`]: {
//     maxWidth: 500,
//     // maxWidth: 1500,
//     backgroundColor: '#002A45',
//     border: '1px solid black',
//     boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
//     padding: 1,
//     transition: 'opacity 1s',
//     '& .MuiTooltip-arrow': {
//       color: '#eef1f9',
//       '&:before': {
//         backgroundColor: '#002A45',
//         boxShadow: 'none',
//         borderBottom: `1px solid black`,
//         borderRight: `1px solid black`,
//         fontSize: 50,
//       },
//     },
//   },
// });

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
  contain: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '10px',
    padding: '5px',
  },
  formControlMultiTextlebel: {
    marginTop: 10,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 160,
  },
  formControlMulti: {
    // margin: theme.spacing(1),
    minWidth: '95%',
    maxWidth: '50%',
  },
  formControlMultiFile: {
    margin: theme.spacing(1),
    minWidth: 300,
    maxWidth: 300,
  },
  formControlMulti1: {
    margin: theme.spacing(1),
    minWidth: 220,
    maxWidth: 220,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  tabstyle: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '33px',
    background: '#DC3223 0% 0% no-repeat padding-box',
    color: '#fff !important',
    fontSize: 14,
    fontWeight: 700,
  },
  MultiSelect: {
    // border: '2px solid red',
    // opacity: 1,
    '& .dropdown-container': {
      border: '1px solid #002A45',
      opacity: 1,
      // position: 'fixed',
    },
    '& .gray': {
      color: '#002A45 !important',
    },
    '& .dropdown-container[aria-disabled="true"]': {
      // overFlow: 'visible',
      // position: 'fixed',
      // backgroundColor: '#C4D4EC',
    },
    // '& .dropdown-content': {
    //   // overflowY: 'auto',
    //   backgroundColor: 'red',
    //   position: 'initial',
    // },
    '& .select-menu-outer': {
      position: 'fixed',
    },
    // '& .rmsc': {
    //   borderColor: 'white',
    // },
    // '& .dropdown-heading': {
    //   color: 'red',
    // },
  },
  customScrollbar: {
    '&::-webkit-scrollbar': {
      width: '8px', // Set the width of the scrollbar
    },
    // '&::-webkit-scrollbar-thumb': {
    //   background: '#888', // Color of the thumb
    //   borderRadius: '5px', // Rounded corners for the thumb
    // },
    // '&::-webkit-scrollbar-thumb:hover': {
    //   background: '#555', // Color of the thumb on hover
    // },
  },
  pagingPanelContainer: {
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    // marginTop: '10px',
    // width: '50% !important',
    padding: '0px !important',
    marginRight: '244px !important',
    '& .Pager-pager': {
      padding: 0,
    },
    // Apply other custom styles as needed
    '& .MuiIconButton-label': {
      color: '#001F3C',
      padding: 5,
      backgroundColor: '#FFFFFF',
      borderRadius: '50%',
      boxShadow: '#00000029',
    },
    // '& .MuiButton-label': {
    //   color: '#001F3C',
    //   padding: 2,
    //   backgroundColor: '#FFFFFF',
    //   borderRadius: '50%',
    //   boxShadow: '#00000029',
    // },
    '& .MuiButton-root': {
      '&.MuiButton-textPrimary .Pagination-activeButton': {
        backgroundColor: 'red !important',
        color: 'white !important',
        // Add other custom styles as needed
      },
      color: '#001F3C',
      padding: '0px 5px',
      backgroundColor: 'white',
      // borderRadius: '50%',
      boxShadow: '#00000029',
      // gap: 10,
      margin: 9,
      // '&.Pagination-activeButton': {
      //   background: 'red !important',
      // },
      // '& .Pagination-button': {
      //   '& .Pagination-activeButton': {
      //     background: 'red !important',
      //   },
      // },
    },
  },
}));

const data = [
  { index: 'Unnamed: 11', '0': null },
  { index: 'R1', '0': 0.0 },
  { index: 'R2', '0': 1.0 },
  { index: 'R5', '0': 1.0 },
  { index: 'R7', '0': 1.0 },
  { index: 'R8', '0': 1.0 },
  { index: 'R9', '0': 0.5 },
  { index: 'R10', '0': 1.0 },
  { index: 'R13', '0': 1.0 },
  { index: 'R15', '0': 0.0 },
  { index: 'R16', '0': 1.0 },
  { index: 'R17', '0': 1.0 },
  { index: 'R18', '0': 1.0 },
  { index: 'R19', '0': 1.0 },
  { index: 'R21', '0': 1.0 },
  { index: 'R24', '0': 1.0 },
  { index: 'R25', '0': 1.0 },
  { index: 'R32', '0': 1.0 },
  { index: 'R33', '0': 1.0 },
  { index: 'R34', '0': 1.0 },
  { index: 'R35', '0': 1.0 },
];

const Columns = [
  { name: 'filename', title: 'File Name' },
  { name: 'size', title: 'Size' },
  { name: 'folderName', title: 'Folder' },
  { name: 'date', title: 'Date upload' },
  { name: 'userName', title: 'Uploaded by' },
];

function IQualimeter({ id: workloadId }: Props) {
  const classesTab = TabStyle();
  const tableUseStyles = tUseStyles();
  const classes = useStyles();
  const theme = useTheme();
  const { setSnack } = useContext(Module1Context);
  // const [pageSizes] = useState([5, 10, 20, 50, 100]);
  const [rows, setRows] = useState<any>([]);
  const [columns, setColumns] = useState<any>([]);
  const [rowsdataset, setRowsdataset] = useState<any>([]);
  const [columnsdataset, setColumnsdataset] = useState<any>([]);
  const [rowstext, setRowstext] = useState<any>([]);
  const [columnstext, setColumnstext] = useState<any>([]);
  const [selection, setSelection] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingFilterData, setLoadingFilterData] = useState(true); // for filter selection
  const [display, setDisplay] = useState(false);
  const [displayGrid, setDisplayGrid] = useState<any>();
  const [errorMsg, setErrorMsg] = useState<any>();
  const [sheetNames, setSheetNames] = useState<any[]>([]);
  const [columnName, setColumnName] = useState<any[]>([]);
  const [sheets, setSheets] = useState<any[]>([]);
  const [selectedSheetName, setSelectedSheetName] = useState<any>();
  const [selectedColumnName, setSelectedColumnName] = useState<any>();
  const [fileType, setFileType] = useState<any>();
  const [graphData, setGraphData] = useState<any>();
  const [graphDataDisplay, setGraphDataDisplay] = useState<any>(false);
  const [graphData1, setGraphData1] = useState<any>();
  const [graphDataDisplay1, setGraphDataDisplay1] = useState<any>(false);
  const [csvDataframe, setCsvDataframe] = useState<any>();
  const [projectNames, setProjectNames] = useState<any[]>([]);
  const [displayDatasetResult, setDisplayDatasetResult] = useState<any>();
  const [heatChartDataX, setHeatChartDataX] = useState<string[]>([]);
  const [heatChartDataY, setHeatChartDataY] = useState<string[]>([]);
  const [heatChartData, setHeatChartData] = useState<string[]>([]);
  const [barplotData, setBarplotData] = useState<any>();
  const [textValue, setTextValue] = React.useState('');
  const [columnWidths, setColumnWidths] = useState([] as any);
  const [columnWidthsText, setColumnWidthsText] = useState([] as any);
  const [columnsAre, setColumnsAre] = useState<any>([]);
  const handleTextChange = (e: { target: { value: React.SetStateAction<string> } }) => {
    setTextValue(e.target.value);
  };
  const exporterRef = useRef(null);
  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);
  const onSave = (workbook: any) => {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'data.xlsx');
    });
  };
  const [toggleHeight, setToggleHeight] = useState(false);
  const [newLine, setNewLine] = useState<any>('pre-line');
  const [selected, setSelected] = useState([]);
  const [selectedProjectName, setSelectedProjectName] = useState();
  const [options, setOptions] = useState<any[]>([]);

  const { project } = useContext(Module1Context);
  const [files, setFiles] = useState<any[]>([]);
  const history = useHistory();

  const [filterEntities, setFilterEntities] = useState<any[]>([]);
  const [filterPlatforms, setFilterPlatforms] = useState<any[]>([]);
  const [filterProjects, setFilterProjects] = useState<any[]>([]);
  const [filterDocumentProfiles, setFilterDocumentProfiles] = useState<any[]>([]);
  const [filterSolutions, setFilterSolutions] = useState<any[]>([]);
  const [filterProducts, setFilterProducts] = useState<any[]>([]);
  const [filterCountries, setFilterCountries] = useState<any[]>([]);

  const [entitiesDefault, setEntitiesDefault] = useState<any>();
  const [filterPlatformsDefault, setFilterPlatformsDefault] = useState<any>();
  const [filterProjectsDefault, setFilterProjectsDefault] = useState<any>();
  const [filterDocumentProfilesDefault, setFilterDocumentProfilesDefault] = useState<any>();
  const [filterSolutionsDefault, setFilterSolutionsDefault] = useState<any>();
  const [filterProductsDefault, setFilterProductsDefault] = useState<any>();
  const [filterCountriesDefault, setFilterCountriesDefault] = useState<any>();

  const [payloadHere, setPayloadHere] = useState<any>();
  const [payloadData, setPayloadData] = useState<any>();

  const [selectedEntities, setSelectedEntities] = useState([]);
  const [selectedPlatforms, setSelectedPlatforms] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [selectedDocumentProfiles, setSelectedDocumentProfiles] = useState([]);
  const [selectedSolutions, setSelectedSolutions] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);

  const [datasetResultScore, setDatasetResultScore] = useState<any>();

  const [allRulesText, setAllRulesText] = useState([]);
  const [selectedRules, setSelectedRules] = useState([]);
  const [selectedRulesText, setSelectedRulesText] = useState([]);
  const [pageSizes] = useState([5, 10, 20, 50]);
  const [tempPage, setTemPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [pageSize2, setPageSize2] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPage2, setCurrentPage2] = useState(0);
  const [toggleButtonHere, setToggleButtonHere] = useState<boolean>(false);

  const [hiddenColumnNames, setHiddenColumnNames] = useState([]);

  const [dis, setDis] = useState(true);
  const handlePageNum = () => {
    const rel = tempPage - 1;
    setCurrentPage(rel);
    setCurrentPage2(rel);
  };
  const { projectId } = useParams() as any;
  const queryParams = new URLSearchParams(window.location.search);
  const actionIs = queryParams.get('action');

  let treeVal: any = 0;
  if (actionIs === 'iQualimeter') {
    treeVal = 1;
  }

  const [value, setValue] = React.useState(treeVal);

  const handleChanges = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  // table header
  const TableComponentBase = ({ classes, ...restProps }: any) => (
    <Table.Table {...restProps} className={classes.tableStriped} />
  );

  const TableComponent = withStyles(TStyles, { name: 'TableComponent' })(TableComponentBase);

  const handleChangeToggleButton = () => {
    setToggleButtonHere(!toggleButtonHere);
  };
  const handleSetRowsColumnsdataset = async (myRows: {}[]) => {
    const myCols = Object.keys(myRows[0]).map((key) => ({
      name: key,
      title: key.toUpperCase(),
    })) as Column[];
    const resizeColumns = myCols.map((c, i) => {
      // if (i === 4) {
      //   return {
      //     columnName: c.name,
      //     width: Math.ceil(2300 / myCols.length),
      //   };
      // }
      // if (i === 5) {
      //   return {
      //     columnName: c.name,
      //     width: Math.ceil(2300 / myCols.length),
      //   };
      // }
      if (c.name === 'score') return { columnName: c.name, width: 100 };
      return {
        columnName: c.name,
        width: Math.ceil(1750 / myCols.length),
      };
    });
    setColumnWidths(resizeColumns);
    // console.log('resizeColumns', resizeColumns);
    setColumnsdataset(myCols);
    setRowsdataset(myRows);
    // console.log('mycols:', myCols);
  };

  const handleSetRowsColumnstext = async (myRows: {}[]) => {
    const myCols = Object.keys(myRows[0]).map((key) => ({
      name: key,
      title: key.toUpperCase(),
    })) as Column[];
    const resizeColumns = myCols.map((c) => {
      if (c.name === 'score') return { columnName: c.name, width: 100 };
      // if (c.name === 'Text') return { columnName: c.name, width: 100 };
      // if (c.name === 'warning') return { columnName: c.name, width: 100 };
      return {
        columnName: c.name,
        width: Math.ceil(1500 / myCols.length),
      };
    });
    setColumnWidthsText(resizeColumns);

    setColumnstext(myCols);
    setRowstext(myRows);
  };

  const [searchText, setSearchText] = useState<any>('');
  const [textScore, setTextScore] = useState<any>('');
  const getText = () => {
    if (textValue.trim() === '' || textValue.split(' ').length < 4) {
      setSnack('Please provide 4 or more words', 'error');
    } else {
      setLoading(true);
      const Rules: any = [];
      if (selectedRulesText.length === 0 || selectedRulesText.length === allRulesText.length) {
        Rules.push('*');
      } else {
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < selectedRulesText.length; index++) {
          Rules.push(selectedRulesText[index].value);
        }
      }
      // @Rahul Kr : no different API's call changes required now
      // if (ENV !== 'production' && ENV !== 'test') {
      incoseTextNew(textValue.trim(), Rules, projectId)
        .then((payload: any) => {
          if (payload) {
            // console.log(payload);
            // check for second dataframe exist or not
            console.log(typeof payload?.rephrased_text?.dataframe);
            // if (typeof payload?.rephrased_text?.dataframe === 'undefined') {
            if (
              Object.keys(payload?.rephrased_text).length > 0 &&
              payload?.rephraseStatus?.status !== 'openAI exception' &&
              payload?.rephraseStatus?.status === 'Success'
            ) {
              // console.log('inside rephrased_text exist');
              const a = JSON.parse(payload?.output?.dataframe);
              const b = JSON.parse(payload?.rephrased_text?.dataframe);
              const t = [
                {
                  'Rephrased Text': b[0]?.Text,
                  'Rephrased Warning': b[0]?.warning,
                  'Rephrased Score': b[0]?.score,
                },
              ];
              const merged_json_obj = { ...a[0], ...t[0] };
              const arr = [];
              arr.push(merged_json_obj);
              handleSetRowsColumnstext(arr);
            } else {
              const a = JSON.parse(payload?.output?.dataframe);
              const t = [
                {
                  'Rephrased Text': 'NA',
                  'Rephrased Warning': 'NA',
                  'Rephrased Score': 'NA',
                },
              ];
              const merged_json_obj = { ...a[0], ...t[0] };
              const arr = [];
              arr.push(merged_json_obj);
              handleSetRowsColumnstext(a);
            }
            // }

            setSearchText(textValue.trim());
            setTextScore(JSON.parse(payload.output.resultScore));
            if (payload?.rephraseStatus?.status === 'Success') {
              setSnack('Rephrased  the input text successfully', 'success');
            } else if (payload?.rephraseStatus?.status === 'Error-re-phrasement') {
              setSnack('Error occurred while rephrasing input text', 'warning');
            } else if (payload?.rephraseStatus?.status === 'No-Need-Rephrasement') {
              setSnack('Score of original text is greater than 80%', 'info');
            } else if (payload?.rephraseStatus?.status === 'Against-policy') {
              setSnack('It is against policy of openai to Process the text', 'info');
            } else if (
              payload?.rephraseStatus?.status === 'Not Able To Find Better Re-Phrasement'
            ) {
              setSnack('Not Able To Find Better Re-Phrasement', 'info');
            } else if (payload?.rephraseStatus?.status === 'openAI exception') {
              setSnack('No results from OpenAPI', 'info');
            } else {
              // setSnack('Not Able To Find Better Re-Phrasement', 'warning');
            }
          }
        })
        .catch((err) => {
          setSnack(err, 'error');
        })
        .finally(() => {
          setLoading(false);
          setDisplay(true);
        });
      // }
      // else {
      //   incoseText(textValue.trim(), Rules)
      //     .then((payload: any) => {
      //       if (payload) {
      //         const a = JSON.parse(payload?.output?.dataframe);
      //         handleSetRowsColumnstext(a);
      //         setSearchText(textValue.trim());
      //         setTextScore(JSON.parse(payload.output.resultScore));
      //       }
      //     })
      //     .catch((err) => {
      //       setSnack(err, 'error');
      //     })
      //     .finally(() => {
      //       setLoading(false);
      //       setDisplay(true);
      //     });
      // }
    }
  };

  let dataSetRawData: any;
  useEffect(() => {
    // to take the icose Dataset project dropdown
    getiQualimeterFilterData('*', '*', '*', '*', '*', '*', '*')
      .then((payload: any) => {
        setLoadingFilterData(false);
        if (payload) {
          // console.log(payload);
          const ent: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters.productLines) {
            const item = payload.filters.productLines[i];
            ent.push({
              label: item,
              value: item,
            });
          }
          setFilterEntities(ent);
          setEntitiesDefault(ent);
          // another one for platform
          const plat: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters.platforms) {
            const item = payload.filters.platforms[i];
            plat.push({
              label: item,
              value: item,
            });
          }
          // console.log(employees);
          setFilterPlatforms(plat);
          setFilterPlatformsDefault(plat);
          // another one for projects
          const pro: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters.projects) {
            const item = payload.filters.projects[i];
            pro.push({
              label: item,
              value: item,
            });
          }
          // console.log(employees);
          setFilterProjects(pro);
          setFilterProjectsDefault(pro);
          // another one for projects
          const docpro: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters.documentProfiles) {
            const item = payload.filters.documentProfiles[i];
            docpro.push({
              label: item,
              value: item,
            });
          }
          // console.log(employees);
          setFilterDocumentProfiles(docpro);
          setFilterDocumentProfilesDefault(docpro);
          // another one for solutions
          const sol: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters.solutions) {
            const item = payload.filters.solutions[i];
            sol.push({
              label: item,
              value: item,
            });
          }
          // console.log(employees);
          setFilterSolutions(sol);
          setFilterSolutionsDefault(sol);
          // another one for products
          const prod: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters.products) {
            const item = payload.filters.products[i];
            prod.push({
              label: item,
              value: item,
            });
          }
          // console.log(employees);
          setFilterProducts(prod);
          setFilterProductsDefault(prod);
          // seperate code
          setPayloadHere(payload);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
    getiQualimeterRules()
      .then((payload: any) => {
        if (payload) {
          const keys: any = [];
          Object.entries(payload.file).forEach(([key, value]) => {
            keys.push({
              label: `${value}`,
              value: `${key}`,
            });
          });

          const texts: any = [];
          Object.entries(payload.text).forEach(([key, value]) => {
            texts.push({
              label: `${value}`,
              value: `${key}`,
            });
          });
          setAllRulesText(texts);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  }, []);
  useEffect(() => {
    if (pageSize > rowsdataset.length) {
      if (dis) {
        setDis((prev) => {
          return !prev;
        });
      }
    } else if (pageSize < rowsdataset.length) {
      setDis((prev) => {
        return !prev;
      });
    }
  }, [pageSize]);
  useEffect(() => {
    if (pageSize2 > rowstext.length) {
      if (dis) {
        setDis((prev) => {
          return !prev;
        });
      }
    } else if (pageSize2 < rowstext.length) {
      setDis((prev) => {
        return !prev;
      });
    }
  }, [pageSize2]);

  // let fileIs1;
  // if (selection.length > 0 && selection[0] !== undefined && selection.length < 2) {
  //   fileIs1 = files[selection[0]].filename || '';
  //   // console.log('fileIs1', fileIs1);
  // }

  function HandleReg(e: any) {
    if (e.target.name === 'sheetname') {
      setColumnName(['']);
      // console.log(e.currentTarget.value);
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < sheets.length; i++) {
        if (sheets[i].SheetName === e.currentTarget.value) {
          setColumnName(sheets[i].ColumNames);
          setSelectedSheetName(e.currentTarget.value);
        }
      }
    }
    if (e.target.name === 'columnname') {
      setSelectedColumnName(e.currentTarget.value);
    }
    if (e.target.name === 'projectname') {
      const currentprojectname = e.currentTarget.value;
      setSelected([]);
      setSelectedProjectName(e.currentTarget.value);
      incoseDatasetDetails()
        .then((payload: any) => {
          if (payload) {
            let documentnames: any = [];
            // eslint-disable-next-line no-plusplus
            for (let index = 0; index < payload.length; index++) {
              if (payload[index].project === currentprojectname) {
                documentnames = payload[index].documents;
                break;
              }
            }
            // console.log(documentnames);
            const employees: any = [];
            // eslint-disable-next-line guard-for-in
            for (const i in documentnames) {
              const item = documentnames[i];
              employees.push({
                label: item,
                value: item,
              });
            }
            // console.log(employees);
            setOptions(employees);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    }
  }

  function datasetProcess() {
    setDisplayDatasetResult(false);
    setGraphDataDisplay1(false);
    setLoading(true);
    const entity: any = [];
    const platform: any = [];
    const project: any = [];
    const documentTitle: any = [];
    const solution: any = [];
    const product: any = [];

    if (selectedEntities.length > 0) {
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < selectedEntities.length; index++) {
        entity.push(selectedEntities[index].value);
      }
    } else {
      entity.push('*');
    }

    if (selectedPlatforms.length > 0) {
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < selectedPlatforms.length; index++) {
        platform.push(selectedPlatforms[index].value);
      }
    } else {
      platform.push('*');
    }

    if (selectedProjects.length > 0) {
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < selectedProjects.length; index++) {
        project.push(selectedProjects[index].value);
      }
    } else {
      project.push('*');
    }

    if (selectedDocumentProfiles.length > 0) {
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < selectedDocumentProfiles.length; index++) {
        documentTitle.push(selectedDocumentProfiles[index].value);
      }
    } else {
      documentTitle.push('*');
    }

    if (selectedSolutions.length > 0) {
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < selectedSolutions.length; index++) {
        solution.push(selectedSolutions[index].value);
      }
    } else {
      solution.push('*');
    }

    if (selectedProducts.length > 0) {
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < selectedProducts.length; index++) {
        product.push(selectedProducts[index].value);
      }
    } else {
      product.push('*');
    }
    // if (entity.length > 1 || platform.length > 1) {
    //   setSnack('Maximum one Entity and one Plaform allowed to process', 'warning');
    //   setLoading(false);
    // } else {
    inQualimeterDataset(entity, platform, project, documentTitle, projectId, solution, product)
      .then((payload: any) => {
        if (payload) {
          // console.log(payload);
          setDisplayDatasetResult(true);

          // JSON.parse(payload.output.dataframe)
          handleSetRowsColumnsdataset(JSON.parse(payload.output.dataframe));
          setDatasetResultScore(JSON.parse(payload.output.resultScore));
          if (payload.output !== null) {
            if ('polarData' in payload.output === false) {
              setGraphData1([]);
              setHeatChartDataX([]);
              setHeatChartDataY([]);
              setBarplotData([]);
              setGraphDataDisplay1(false);
            } else {
              setGraphData1(JSON.parse(payload.output.polarData));
              setGraphDataDisplay1(true);
              setHeatChartDataX(
                JSON.parse(JSON.stringify(payload.output.heatmapData.xAxis[0].labels)) as string[]
              );
              setHeatChartDataY(
                JSON.parse(JSON.stringify(payload.output.heatmapData.yAxis[0].labels)) as string[]
              );
              setHeatChartData(
                JSON.parse(JSON.stringify(payload.output.heatmapData.graphValue)) as string[]
              );
              setBarplotData(JSON.parse(payload.output.barplotData));
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
    // }
  }

  function onChangeRules(option: any) {
    setSelectedRules(option);
  }
  function onChangeRulesText(option: any) {
    setSelectedRulesText(option);
  }
  function onChangeEntity(option: any) {
    setSelectedEntities(option);
    const extra = 'noChange_entity';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    multifilterCall(
      option,
      selectedPlatforms,
      selectedProjects,
      selectedDocumentProfiles,
      selectedSolutions,
      selectedProducts,
      extra
    );
  }

  function onChangePlatform(option: any) {
    setSelectedPlatforms(option);
    const extra = 'noChange_platform';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    multifilterCall(
      selectedEntities,
      option,
      selectedProjects,
      selectedDocumentProfiles,
      selectedSolutions,
      selectedProducts,
      extra
    );
  }

  function onChangeProject(option: any) {
    setSelectedProjects(option);
    const extra = 'noChange_project';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    multifilterCall(
      selectedEntities,
      selectedPlatforms,
      option,
      selectedDocumentProfiles,
      selectedSolutions,
      selectedProducts,
      extra
    );
  }

  function onChangeDocumentProfile(option: any) {
    setSelectedDocumentProfiles(option);
    const extra = 'noChange_documentProfile';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    multifilterCall(
      selectedEntities,
      selectedPlatforms,
      selectedProjects,
      option,
      selectedSolutions,
      selectedProducts,
      extra
    );
  }

  function onChangeSolutions(option: any) {
    setSelectedSolutions(option);
    const extra = 'noChange_solutions';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    multifilterCall(
      selectedEntities,
      selectedPlatforms,
      selectedProjects,
      selectedDocumentProfiles,
      option,
      selectedProducts,
      extra
    );
  }

  function onChangeProducts(option: any) {
    setSelectedProducts(option);
    const extra = 'noChange_products';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    multifilterCall(
      selectedEntities,
      selectedPlatforms,
      selectedProjects,
      selectedDocumentProfiles,
      selectedSolutions,
      option,
      extra
    );
  }

  function multifilterCall(
    en: any,
    plat: any,
    proj: any,
    docprof: any,
    sol: any,
    prod: any,
    extra: any
  ) {
    const entityVal: any = [];
    const platformVal: any = [];
    const projectVal: any = [];
    const documentProfileVal: any = [];
    const solutionsVal: any = [];
    const productsVal: any = [];
    en.forEach((o: any) => {
      entityVal.push(o.value);
    });
    plat.forEach((o: any) => {
      platformVal.push(o.value);
    });
    proj.forEach((o: any) => {
      projectVal.push(o.value);
    });
    docprof.forEach((o: any) => {
      documentProfileVal.push(o.value);
    });
    sol.forEach((o: any) => {
      solutionsVal.push(o.value);
    });
    prod.forEach((o: any) => {
      productsVal.push(o.value);
    });
    // console.log('solVal', solutionsVal);
    // console.log('prodVal', productsVal);
    getiQualimeterFilterData(
      entityVal,
      platformVal,
      projectVal,
      documentProfileVal,
      solutionsVal,
      productsVal,
      ''
    )
      .then((payload: any) => {
        if (payload) {
          // console.log(payload);
          const entityAfterFilter: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters.productLines) {
            const item = payload.filters.productLines[i];
            entityAfterFilter.push({
              label: item,
              value: item,
            });
          }
          if (extra !== 'noChange_entity') {
            setFilterEntities(entityAfterFilter);
          }
          const platformAfterFilter: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters.platforms) {
            const item = payload.filters.platforms[i];
            platformAfterFilter.push({
              label: item,
              value: item,
            });
          }
          if (extra !== 'noChange_platform') {
            setFilterPlatforms(platformAfterFilter);
          }
          const projectAfterFilter: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters.projects) {
            const item = payload.filters.projects[i];
            projectAfterFilter.push({
              label: item,
              value: item,
            });
          }
          if (extra !== 'noChange_project') {
            setFilterProjects(projectAfterFilter);
          }
          const documentProfileAfterFilter: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters.documentProfiles) {
            const item = payload.filters.documentProfiles[i];
            documentProfileAfterFilter.push({
              label: item,
              value: item,
            });
          }
          if (extra !== 'noChange_documentProfile') {
            setFilterDocumentProfiles(documentProfileAfterFilter);
          }
          const solutionsAfterFilter: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters.solutions) {
            const item = payload.filters.solutions[i];
            solutionsAfterFilter.push({
              label: item,
              value: item,
            });
          }
          if (extra !== 'noChange_solutions') {
            setFilterSolutions(solutionsAfterFilter);
          }
          const productsAfterFilter: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters.products) {
            const item = payload.filters.products[i];
            productsAfterFilter.push({
              label: item,
              value: item,
            });
          }
          if (extra !== 'noChange_products') {
            setFilterProducts(productsAfterFilter);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  }
  // eslint-disable-next-line consistent-return
  const renderEntity = () => {
    if (!selectedEntities.length) {
      return <b style={{ color: '#002A45' }}>Product Line</b>;
    }
  };
  // eslint-disable-next-line consistent-return
  const renderPlatform = () => {
    if (!selectedPlatforms.length) {
      return <b style={{ color: '#002A45' }}>Platform</b>;
    }
  };
  // eslint-disable-next-line consistent-return
  const renderProjects = () => {
    if (!selectedProjects.length) {
      return <b style={{ color: '#002A45' }}>Project</b>;
    }
  };
  // eslint-disable-next-line consistent-return
  const renderDp = () => {
    if (!selectedDocumentProfiles.length) {
      return <b style={{ color: '#002A45' }}>Document Profile</b>;
    }
  };
  // eslint-disable-next-line consistent-return
  const renderSolutions = () => {
    if (!selectedSolutions.length) {
      return <b style={{ color: '#002A45' }}>Solution</b>;
    }
  };
  // eslint-disable-next-line consistent-return
  const renderProducts = () => {
    if (!selectedProducts.length) {
      return <b style={{ color: '#002A45' }}>Product</b>;
    }
  };
  const tableHeaderComponents: React.FunctionComponent<Table.RowProps> = (props) => {
    return (
      <Table.TableHead
        {...props}
        style={{
          position: 'unset',
          top: 0,
          zIndex: 400,
        }}
      />
    );
  };

  if (loadingFilterData) {
    return <Loading />;
  }

  return (
    <div className={classesTab.root}>
      {/* <ReactJson
        src={{
          heatChartData,
        }}
        collapsed={true}
        theme="monokai"
      /> */}

      <AppBar position="static" className={classesTab.appStyle}>
        <Tabs
          value={value}
          onChange={handleChanges}
          indicatorColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
          // style={{ backgroundColor: '#EBE6DC' }}
          style={{
            color: '#002A45',
            fontWeight: 'bold',
            opacity: '1 !important',
            fontSize: '-0.125rem',
          }}
          TabIndicatorProps={{
            style: {
              display: 'none',
            },
          }}
        >
          <Tab
            label={
              <>
                <Typography variant="subtitle2" className={classesTab.tabLebelText}>
                  <span>
                    <AssignmentOutlinedIcon className={classesTab.iconLabelWrapper} />
                  </span>
                  PROJECT QUALITY
                </Typography>
              </>
            }
            {...a11yProps(0)}
            className={value === 0 ? classesTab.tabstyle : ''}
            // style={{ fontSize: '18px', fontWeight: 'bold' }}
          />
          <Tab
            label={
              <>
                <Typography variant="subtitle2" className={classesTab.tabLebelText}>
                  <span>
                    <InsertDriveFileOutlinedIcon className={classesTab.iconLabelWrapper} />
                  </span>
                  Document Quality
                </Typography>
              </>
            }
            {...a11yProps(1)}
            className={value === 1 ? classesTab.tabstyle : ''}
            // style={{ fontSize: '18px', fontWeight: 'bold' }}
          />
          <Tab
            label={
              <>
                <Typography variant="subtitle2" className={classesTab.tabLebelText}>
                  <span>
                    <FontDownloadOutlinedIcon className={classesTab.iconLabelWrapper} />
                  </span>
                  Requirement Quality
                </Typography>
              </>
            }
            {...a11yProps(2)}
            className={value === 2 ? classesTab.tabstyle : ''}
            // style={{ fontSize: '18px', fontWeight: 'bold' }}
          />
        </Tabs>
      </AppBar>
      {/* <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={value}
              onChangeIndex={handleChangeIndex}
            > */}
      <TabPanel value={value} index={0} dir={theme.direction}>
        <div>
          <Paper
            style={{
              backgroundColor: '#EEF1F9',
              // margin: 15,
              borderRadius: 2,
              border: '1px solid #002A45',
              padding: '18px 9px 85px 8px',
            }}
          >
            <div>
              <Grid container spacing={0}>
                <Grid item xs={2}>
                  <FormControl variant="outlined" className={classes.formControlMulti}>
                    <MultiSelect
                      options={filterEntities}
                      value={selectedEntities}
                      // hasSelectAll={false}
                      onChange={onChangeEntity}
                      labelledBy="Entities"
                      valueRenderer={renderEntity}
                      className={classes.MultiSelect}
                      overrideStrings={{
                        allItemsAreSelected: selectedEntities
                          .map((item) => {
                            return item?.value;
                          })
                          .join(','),
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControl variant="outlined" className={classes.formControlMulti}>
                    <MultiSelect
                      options={filterPlatforms}
                      value={selectedPlatforms}
                      onChange={onChangePlatform}
                      labelledBy="Platforms"
                      valueRenderer={renderPlatform}
                      className={classes.MultiSelect}
                      overrideStrings={{
                        allItemsAreSelected: selectedPlatforms
                          .map((item) => {
                            return item?.value;
                          })
                          .join(','),
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControl variant="outlined" className={classes.formControlMulti}>
                    <MultiSelect
                      options={filterSolutions}
                      value={selectedSolutions}
                      onChange={onChangeSolutions}
                      labelledBy="Solutions"
                      valueRenderer={renderSolutions}
                      className={classes.MultiSelect}
                      overrideStrings={{
                        allItemsAreSelected: selectedSolutions
                          .map((item) => {
                            return item?.value;
                          })
                          .join(','),
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControl variant="outlined" className={classes.formControlMulti}>
                    <MultiSelect
                      options={filterProducts}
                      value={selectedProducts}
                      onChange={onChangeProducts}
                      labelledBy="Products"
                      valueRenderer={renderProducts}
                      className={classes.MultiSelect}
                      overrideStrings={{
                        allItemsAreSelected: selectedProducts
                          .map((item) => {
                            return item?.value;
                          })
                          .join(','),
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControl variant="outlined" className={classes.formControlMulti}>
                    <MultiSelect
                      options={filterProjects}
                      value={selectedProjects}
                      onChange={onChangeProject}
                      labelledBy="Projects"
                      valueRenderer={renderProjects}
                      className={classes.MultiSelect}
                      overrideStrings={{
                        allItemsAreSelected: selectedProjects
                          .map((item) => {
                            return item?.value;
                          })
                          .join(','),
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControl variant="outlined" className={classes.formControlMulti}>
                    <MultiSelect
                      options={filterDocumentProfiles}
                      value={selectedDocumentProfiles}
                      onChange={onChangeDocumentProfile}
                      labelledBy="Document Profiles"
                      valueRenderer={renderDp}
                      className={classes.MultiSelect}
                      overrideStrings={{
                        allItemsAreSelected: selectedDocumentProfiles
                          .map((item) => {
                            return item?.value;
                          })
                          .join(','),
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <br />
              <div style={{ float: 'right' }}>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    container
                    alignItems="center"
                    // style={{ float: 'left' }}
                    justify="flex-end"
                    // style={{ display: 'flex', justifyContent: 'end' }}
                  >
                    <Grid item xs={3}>
                      <IconButton
                        size="medium"
                        edge="start"
                        aria-label="Remove filter"
                        title="Remove filter"
                        onClick={() => {
                          setFilterEntities(entitiesDefault);
                          setSelectedEntities([]);
                          setFilterPlatforms(filterPlatformsDefault);
                          setSelectedPlatforms([]);
                          setFilterProjects(filterProjectsDefault);
                          setSelectedProjects([]);
                          setFilterDocumentProfiles(filterDocumentProfilesDefault);
                          setSelectedDocumentProfiles([]);
                          setFilterSolutions(filterSolutionsDefault);
                          setSelectedSolutions([]);
                          setFilterProducts(filterProductsDefault);
                          setSelectedProducts([]);
                        }}
                      >
                        <Avatar
                          alt="removefilter"
                          src={removeFilter1}
                          style={{ border: '1px solid', padding: '5px' }}
                        />
                      </IconButton>
                    </Grid>
                    <Grid item xs={9}>
                      <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        onClick={datasetProcess}
                        style={{
                          height: '50px',
                          width: '150px',
                          // marginTop: -5,
                          // float: 'right',
                        }}
                      >
                        Analyze
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </div>

              {/* old code --
              
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">Project Name</InputLabel>
                <Select
                  native
                  label="Project Name"
                  name="projectname"
                  style={{ width: 200, height: 40 }}
                  onChange={HandleReg}
                  defaultValue=""
                >
                  <option aria-label="None" value="" disabled />
                  {projectNames.length > 0
                    ? projectNames.map((cap: string, index: number) => (
                        <option key={`capture-type-${index}`} value={cap}>
                          &nbsp;&nbsp;{cap}
                        </option>
                      ))
                    : ''}
                </Select>
              </FormControl> */}
              {/* <FormControl style={{ marginTop: '20px', left: '9px' }}>
                <span>Documents1:</span>
              </FormControl>
              <FormControl variant="outlined" className={classes.formControlMulti}>
                <MultiSelect
                  options={options}
                  value={selected}
                  onChange={setSelected}
                  labelledBy="documentname"
                />
              </FormControl> */}
            </div>
          </Paper>
          <div>
            {loading ? (
              <div style={{ width: '100%', textAlign: 'center' }}>
                <Loading />
              </div>
            ) : (
              ''
            )}
            {displayDatasetResult && (
              <>
                <h2>Results: </h2>
                <span>
                  <b> SCORE: </b>
                  {datasetResultScore}%
                </span>
                {/* {console.log('columnsdataset', columnsdataset)} */}
                <div style={{ backgroundColor: '#EEF1F9', border: '1px solid #002A45' }}>
                  <ExpressGrid rows={rowsdataset} columns={columnsdataset}>
                    <DataTypeProvider
                      for={[
                        'Document Title',
                        'Document_Profile',
                        'score',
                        'Entity',
                        'Platform',
                        'Project',
                        'DOORS_Project',
                      ]}
                      formatterComponent={({ value }) => (
                        <CustomWidthTooltip
                          // placement="bottom-start"
                          arrow
                          // style={{
                          //   whiteSpace: `${newLine}`,
                          //   fontWeight: 'bold',
                          //   color: '#002A45',
                          //   overflowY: newLine === 'pre-line' ? 'scroll' : 'hidden',
                          //   maxHeight: '15vh',
                          //   padding: 5,
                          // }}
                          // open={true}
                          title={
                            <>
                              <Card
                                style={{
                                  background: 'while',
                                  color: '#002A45',
                                  fontWeight: 'bold',
                                  margin: 0,
                                  padding: 5,
                                  // overflowY: 'scroll',
                                  // maxHeight: '25vh',
                                  // padding: 5,
                                }}
                                // className={tClasses.customScrollbar}
                              >
                                {value}
                              </Card>
                            </>
                          }
                        >
                          <span
                            title={value}
                            style={{
                              whiteSpace: `${newLine}`,
                              fontWeight: 'bold',
                              color: '#002A45',
                            }}
                          >
                            {value}
                          </span>
                        </CustomWidthTooltip>
                      )}
                    />
                    <DataTypeProvider
                      for={['text', 'warning']}
                      formatterComponent={({ value }) => (
                        <CustomWidthTooltip
                          // placement="bottom-start"
                          arrow
                          // style={{
                          //   whiteSpace: `${newLine}`,
                          //   fontWeight: 'bold',
                          //   color: '#002A45',
                          //   overflowY: newLine === 'pre-line' ? 'scroll' : 'hidden',
                          //   maxHeight: '15vh',
                          //   padding: 5,
                          // }}
                          // open={true}
                          title={
                            <>
                              <Card
                                style={{
                                  background: 'while',
                                  color: '#002A45',
                                  fontWeight: 'bold',
                                  margin: 0,
                                  padding: 5,
                                  overflowY: 'scroll',
                                  maxHeight: '25vh',
                                  // padding: 5,
                                }}
                                className={classes.customScrollbar}
                              >
                                {value?.replace(/-{28,}/g, '')}
                              </Card>
                            </>
                          }
                        >
                          <Card
                            // title={value.replace(/-{28,}/g, '')}
                            style={{
                              whiteSpace: `${newLine}`,
                              fontWeight: 'bold',
                              color: '#002A45',
                              overflowY: newLine === 'pre-line' ? 'scroll' : 'hidden',
                              maxHeight: '15vh',
                              padding: 5,
                            }}
                            className={classes.customScrollbar}
                          >
                            {value?.replace(/-{28,}/g, '')}
                          </Card>
                        </CustomWidthTooltip>
                        // <Card
                        //   title={value.replace(/-{28,}/g, '')}
                        //   style={{
                        //     whiteSpace: `${newLine}`,
                        //     fontWeight: 'bold',
                        //     color: '#002A45',
                        //     overflowY: newLine === 'pre-line' ? 'scroll' : 'hidden',
                        //     maxHeight: '15vh',
                        //     padding: 5,
                        //   }}
                        // >
                        //   {value.replace(/-{28,}/g, '')}
                        // </Card>
                      )}
                    />
                    {/* <DataTypeProvider
                        for={['warning']}
                        formatterComponent={({ value }) => (
                          <span
                            style={{
                              whiteSpace: `${newLine}`,
                              // fontWeight: 'bold',
                              color: 'black',
                            }}
                            title={value}
                          >
                            {String(value).replaceAll('\n', '\n\n')}
                          </span>
                        )}
                      /> */}
                    <FilteringState
                      defaultFilters={[{ columnName: 'Document Title', value: '' }]}
                    />
                    <IntegratedFiltering />
                    <SortingState />
                    <IntegratedSorting />
                    <PagingState
                      currentPage={currentPage}
                      onCurrentPageChange={setCurrentPage}
                      pageSize={pageSize}
                      onPageSizeChange={setPageSize}
                    />
                    {/* <PagingState defaultCurrentPage={0} defaultPageSize={5} /> */}
                    <IntegratedPaging />
                    <Table
                      tableComponent={MultiAllocTableComp}
                      containerComponent={tableAllocatorComponent}
                      headComponent={tableHeaderComponents}
                      columnExtensions={[
                        ...columnsdataset.map((c) => {
                          return {
                            columnName: c.name,
                            width: 'auto',
                            wordWrapEnabled: toggleHeight,
                          };
                        }),
                      ]}
                    />
                    {/* all columns name should be pass then only it will work */}
                    {/* <TableColumnResizing
                        defaultColumnWidths={[
                          { columnName: 'Document_Title', width: 200 },
                          { columnName: 'text', width: 300 },
                          { columnName: 'warning', width: 300 },
                          { columnName: 'score', width: 100 },
                        ]}
                      /> */}
                    {/* <TableColumnResizing
                        defaultColumnWidths={[
                          ...columnsdataset.map((c) => {
                            return {
                              columnName: c.name,
                              width: 400,
                            };
                          }),
                        ]}
                      /> */}
                    <TableColumnResizing
                      onColumnWidthsChange={setColumnWidths}
                      columnWidths={columnWidths}
                      resizingMode="nextColumn"
                    />
                    <TableHeaderRow showSortingControls />
                    <TableFilterRow rowComponent={rowComponent} />
                    <TableColumnVisibility
                      hiddenColumnNames={hiddenColumnNames}
                      onHiddenColumnNamesChange={setHiddenColumnNames}
                    />
                    <Toolbar rootComponent={toolbarComponent} />
                    <ColumnChooser />

                    <ToolBarPlugin name="Download" onClick={startExport} />
                    <ToolBarPlugin
                      name="Height"
                      title="Wrap Text"
                      onClick={() => {
                        setToggleHeight(!toggleHeight);
                        setNewLine('');
                      }}
                    />
                    <ToolBarPlugin
                      name="NewLine"
                      onClick={() =>
                        newLine !== 'pre-line' ? setNewLine('pre-line') : setNewLine('')
                      }
                    />
                    {/* <PagingPanel pageSizes={pageSizes} /> */}
                    <PagingPanel
                      pageSizes={pageSizes}
                      containerComponent={(props) => (
                        <>
                          <PagingPanel.Container
                            {...props}
                            className={classes.pagingPanelContainer}
                          />
                        </>
                      )}
                    />
                  </ExpressGrid>
                  <GridExporter
                    ref={exporterRef}
                    rows={rowsdataset}
                    columns={columnsdataset}
                    onSave={onSave}
                  />
                  <NavigationGotoNew
                    setPageSize={setPageSize}
                    fileLength={rowsdataset?.length}
                    pageSize={pageSize}
                    dis={dis}
                    setTemPage={setTemPage}
                    handlePageNum={handlePageNum}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                  />
                </div>
              </>
            )}
          </div>
          <div>
            <br />
            {graphDataDisplay1 && (
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={12}
                  style={{
                    border: '1px solid',
                    borderRadius: 5,
                    backgroundColor: 'aliceblue',
                  }}
                >
                  {/* <span>Spider Chart Graph</span> */}
                  <center>
                    <RadarChart
                      outerRadius={170}
                      width={400}
                      height={450}
                      style={{}}
                      data={graphData1}
                    >
                      <PolarGrid />
                      <PolarAngleAxis dataKey="index" />
                      <PolarRadiusAxis angle={30} domain={[0, 1]} />
                      <Radar
                        name="Spider Chart Graph"
                        dataKey="0"
                        stroke="#8884d8"
                        fill="#8884d8"
                        fillOpacity={0.6}
                      />
                      {/* <Legend /> */}
                    </RadarChart>
                  </center>
                </Grid>
                <Grid
                  item
                  xs={12}
                  // style={{ border: '1px solid', borderRadius: 5, backgroundColor: 'aliceblue' }}
                >
                  {/* <span>Heat Chart Graph</span> */}
                  <div
                    style={{
                      padding: '10px',
                      alignContent: 'center',
                    }}
                  >
                    <center>
                      <HeatMapComponent
                        id="heatmap"
                        titleSettings={{
                          text: '',
                          textStyle: {
                            size: '10px',
                            fontWeight: '500',
                            fontStyle: 'Normal',
                            fontFamily: 'Segoe UI',
                          },
                        }}
                        xAxis={{
                          labels: heatChartDataY,
                        }}
                        yAxis={{
                          labels: heatChartDataX,
                        }}
                        paletteSettings={{
                          palette: [{ color: '#FF0000' }, { color: '#228B22' }],
                        }}
                        allowSelection={true}
                        dataSource={heatChartData} // heatChartData graphValue
                      >
                        <Inject services={[LegendHeat, TooltipHeat]} />
                      </HeatMapComponent>
                    </center>
                  </div>
                </Grid>
                <Grid container spacing={0}>
                  <Grid item xs={6}>
                    &nbsp;
                  </Grid>
                  <Grid item xs={6}>
                    <div style={{ float: 'right' }}>
                      Vertical Bar Graph
                      <Switch
                        checked={toggleButtonHere}
                        onChange={handleChangeToggleButton}
                        color="primary"
                        name="checkedB"
                        value="true"
                        inputProps={{ 'aria-label': 'checkbox with default color' }}
                      />
                      Horizontal Bar Graph &nbsp;
                    </div>
                  </Grid>
                </Grid>
                {toggleButtonHere === false && (
                  <Grid
                    item
                    xs={12}
                    style={{
                      border: '1px solid',
                      borderRadius: 5,
                      marginTop: '10px',
                      backgroundColor: 'aliceblue',
                      height: '600px',
                      overflowY: 'scroll',
                    }}
                  >
                    {/* <span>Vertical Bar Chart Graph</span> */}
                    <BarChart
                      width={800}
                      height={70 * barplotData?.length}
                      data={barplotData}
                      layout="vertical"
                      margin={{ top: 50, right: 30, left: 150, bottom: 5 }}
                    >
                      {/* angle={30} */}
                      <XAxis type="number" />
                      <YAxis
                        type="category"
                        dataKey="Document_Profile"
                        fontSize={8}
                        angle={-15}
                        textAnchor="end"
                        scaleToFit="true"
                        verticalAnchor="start"
                        interval={0}
                        stroke="#000"
                      />
                      <CartesianGrid strokeDasharray="3 3" />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="Score" fill="#8884d8" barSize={20} />
                    </BarChart>
                  </Grid>
                )}
                {toggleButtonHere === true && (
                  <Grid
                    item
                    xs={12}
                    style={{
                      border: '1px solid',
                      borderRadius: 5,
                      marginTop: '10px',
                      backgroundColor: 'aliceblue',
                      maxHeight: '500px',
                      overflowX: 'scroll',
                      overflowY: 'scroll',
                    }}
                  >
                    <BarChart
                      width={100 * barplotData?.length}
                      height={500}
                      data={barplotData}
                      layout="horizontal"
                      margin={{ top: 20, bottom: 200 }}
                    >
                      <XAxis
                        type="category"
                        dataKey="Document_Profile"
                        fontSize={8}
                        angle={-45}
                        textAnchor="end"
                        scaleToFit="true"
                        verticalAnchor="start"
                        interval={0}
                        stroke="#000"
                      />
                      <YAxis type="number" />
                      <CartesianGrid strokeDasharray="3 3" />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="Score" fill="#8884d8" barSize={20} />
                    </BarChart>
                  </Grid>
                )}
              </Grid>
            )}
          </div>
        </div>
      </TabPanel>
      <TabPanel value={value} index={1} dir={theme.direction}>
        <IQulimeterFile id={workloadId ? Number(workloadId) : undefined} />
      </TabPanel>
      <TabPanel value={value} index={2} dir={theme.direction}>
        <br />
        <div style={{ marginLeft: '10px' }}>
          <TextField
            style={{ width: '78%' }}
            id="outlined-basic"
            label="Enter the text"
            variant="outlined"
            value={textValue}
            onChange={handleTextChange}
          />
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Button
            variant="contained"
            color="primary"
            onClick={getText}
            style={{ height: '50px', width: '150px' }}
          >
            Analyze
          </Button>
          <br />
          <FormControl
            variant="outlined"
            className={classes.formControlMultiFile}
            style={{ marginLeft: -2 }}
          >
            <MultiSelect
              options={allRulesText}
              value={selectedRulesText}
              onChange={onChangeRulesText}
              labelledBy="Rules"
            />
          </FormControl>
        </div>
        <div>
          {loading ? (
            <span>
              <center>
                <Loading />
              </center>
            </span>
          ) : (
            <>
              {display && (
                <>
                  {/* for requirement quality */}
                  <h2>Results: </h2>
                  <span>
                    {/* <b>Searched Text: </b> {searchText} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  */}
                    <b> SCORE: </b>
                    {textScore}%
                  </span>

                  <div style={{ backgroundColor: '#EEF1F9', border: '1px solid #002A45' }}>
                    {console.log('columnstext', columnstext)}
                    <ExpressGrid rows={rowstext} columns={columnstext}>
                      <DataTypeProvider
                        for={['Text', 'Rephrased Text']}
                        formatterComponent={({ value }) => (
                          // <span title={value} style={{ whiteSpace: `${newLine}` }}>
                          //   {value}
                          // </span>
                          <CustomWidthTooltip
                            // placement="bottom-start"
                            arrow
                            // style={{
                            //   whiteSpace: `${newLine}`,
                            //   fontWeight: 'bold',
                            //   color: '#002A45',
                            //   overflowY: newLine === 'pre-line' ? 'scroll' : 'hidden',
                            //   maxHeight: '15vh',
                            //   padding: 5,
                            // }}
                            // open={true}
                            title={
                              <>
                                <Card
                                  style={{
                                    background: 'while',
                                    color: '#002A45',
                                    fontWeight: 'bold',
                                    margin: 0,
                                    padding: 5,
                                    overflowY: 'scroll',
                                    maxHeight: '25vh',
                                    // padding: 5,
                                  }}
                                  className={classes.customScrollbar}
                                >
                                  {value?.replace(/-{28,}/g, '')}
                                </Card>
                              </>
                            }
                          >
                            <Card
                              // title={value.replace(/-{28,}/g, '')}
                              style={{
                                whiteSpace: `${newLine}`,
                                fontWeight: 'bold',
                                color: '#002A45',
                                overflowY: newLine === 'pre-line' ? 'scroll' : 'hidden',
                                maxHeight: '15vh',
                                padding: 5,
                              }}
                              className={classes.customScrollbar}
                            >
                              {value?.replace(/-{28,}/g, '')}
                            </Card>
                          </CustomWidthTooltip>
                        )}
                      />
                      <DataTypeProvider
                        for={['warning', 'Rephrased Warning']}
                        formatterComponent={({ value }) => (
                          // <span
                          //   style={{
                          //     whiteSpace: `${newLine}`,
                          //     // backgroundColor: 'rgb(230,157,231)',
                          //     // borderRadius: '50% 0%',
                          //     // padding: '.5em 1.3em',
                          //     // boxDecorationBreak: 'clone',
                          //     // fontWeight: 'bold',
                          //     color: 'black',
                          //   }}
                          //   title={value}
                          // >
                          //   {String(value).replaceAll('\n', '\n\n')}
                          // </span>
                          <CustomWidthTooltip
                            // placement="bottom-start"
                            arrow
                            // style={{
                            //   whiteSpace: `${newLine}`,
                            //   fontWeight: 'bold',
                            //   color: '#002A45',
                            //   overflowY: newLine === 'pre-line' ? 'scroll' : 'hidden',
                            //   maxHeight: '15vh',
                            //   padding: 5,
                            // }}
                            // open={true}
                            title={
                              <>
                                <Card
                                  style={{
                                    background: 'while',
                                    color: '#002A45',
                                    fontWeight: 'bold',
                                    margin: 0,
                                    padding: 5,
                                    overflowY: 'scroll',
                                    maxHeight: '25vh',
                                    // padding: 5,
                                  }}
                                  className={classes.customScrollbar}
                                >
                                  {value?.replace(/-{28,}/g, '')}
                                </Card>
                              </>
                            }
                          >
                            <Card
                              // title={value.replace(/-{28,}/g, '')}
                              style={{
                                whiteSpace: `${newLine}`,
                                fontWeight: 'bold',
                                color: '#002A45',
                                overflowY: newLine === 'pre-line' ? 'scroll' : 'hidden',
                                maxHeight: '15vh',
                                padding: 5,
                              }}
                              className={classes.customScrollbar}
                            >
                              {String(value).replaceAll('\n', '\n\n')}
                            </Card>
                          </CustomWidthTooltip>
                        )}
                      />
                      <DataTypeProvider
                        for={['score', 'Rephrased Score']}
                        formatterComponent={({ value }) => (
                          <CustomWidthTooltip
                            // placement="bottom-start"
                            arrow
                            // style={{
                            //   whiteSpace: `${newLine}`,
                            //   fontWeight: 'bold',
                            //   color: '#002A45',
                            //   overflowY: newLine === 'pre-line' ? 'scroll' : 'hidden',
                            //   maxHeight: '15vh',
                            //   padding: 5,
                            // }}
                            // open={true}
                            title={
                              <>
                                <Card
                                  style={{
                                    background: 'while',
                                    color: '#002A45',
                                    fontWeight: 'bold',
                                    margin: 0,
                                    padding: 5,
                                    // overflowY: 'scroll',
                                    // maxHeight: '25vh',
                                    // padding: 5,
                                  }}
                                  // className={tClasses.customScrollbar}
                                >
                                  {value}
                                </Card>
                              </>
                            }
                          >
                            <span
                              title={value}
                              style={{
                                whiteSpace: `${newLine}`,
                                fontWeight: 'bold',
                                color: '#002A45',
                              }}
                            >
                              {value}
                            </span>
                          </CustomWidthTooltip>
                        )}
                      />
                      <FilteringState defaultFilters={[{ columnName: 'text', value: '' }]} />
                      <IntegratedFiltering />
                      <SortingState />
                      <IntegratedSorting />
                      <PagingState
                        currentPage={currentPage2}
                        onCurrentPageChange={setCurrentPage2}
                        pageSize={pageSize2}
                        onPageSizeChange={setPageSize2}
                      />
                      {/* <PagingState defaultCurrentPage={0} defaultPageSize={5} /> */}
                      <IntegratedPaging />
                      <Table
                        // tableComponent={TableComponent}
                        tableComponent={MultiAllocTableComp}
                        containerComponent={tableAllocatorComponent}
                        headComponent={tableHeaderComponents}
                        columnExtensions={[
                          {
                            columnName: 'text',
                            width: '500px',
                            wordWrapEnabled: toggleHeight,
                          },
                          {
                            columnName: 'warning',
                            width: '500px',
                            wordWrapEnabled: toggleHeight,
                          },
                          { columnName: 'score', width: '50px' },
                        ]}
                      />
                      {/* <TableColumnResizing
                          defaultColumnWidths={[
                            { columnName: 'text', width: 300 },
                            { columnName: 'warning', width: 400 },
                            { columnName: 'score', width: 100 },
                          ]}
                        /> */}
                      {/* <TableColumnResizing
                          defaultColumnWidths={[
                            ...columnstext.map((c) => {
                              if (c.name === 'text') return { columnName: c.name, width: 600 };
                              if (c.name === 'warning') return { columnName: c.name, width: 600 };
                              return {
                                columnName: c.name,
                                width: Math.ceil(1400 / columnstext.length),
                              };
                            }),
                          ]}
                        /> */}
                      <TableColumnResizing
                        onColumnWidthsChange={setColumnWidthsText}
                        columnWidths={columnWidthsText}
                        resizingMode="nextColumn"
                      />
                      <TableHeaderRow showSortingControls />
                      <TableFilterRow rowComponent={rowComponent} />
                      <Toolbar rootComponent={toolbarComponent} />
                      <ToolBarPlugin name="Download" onClick={startExport} />
                      <ToolBarPlugin
                        name="Height"
                        title="Wrap Text"
                        onClick={() => {
                          setToggleHeight(!toggleHeight);
                          setNewLine('');
                        }}
                      />
                      <ToolBarPlugin
                        name="NewLine"
                        onClick={() =>
                          newLine !== 'pre-line' ? setNewLine('pre-line') : setNewLine('')
                        }
                      />
                      {/* <PagingPanel pageSizes={pageSizes} /> */}
                      {/* <PagingPanel pageSizes={pageSizes} /> */}
                      <PagingPanel
                        pageSizes={pageSizes}
                        containerComponent={(props) => (
                          <>
                            <PagingPanel.Container
                              {...props}
                              className={classes.pagingPanelContainer}
                            />
                          </>
                        )}
                      />
                    </ExpressGrid>
                    <GridExporter
                      ref={exporterRef}
                      rows={rowstext}
                      columns={columnstext}
                      onSave={onSave}
                    />
                    <NavigationGotoNew
                      setPageSize={setPageSize2}
                      fileLength={rowstext?.length}
                      pageSize={pageSize2}
                      dis={dis}
                      setTemPage={setTemPage}
                      handlePageNum={handlePageNum}
                      currentPage={currentPage2}
                      setCurrentPage={setCurrentPage2}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
      </TabPanel>
      {/* </SwipeableViews> */}
    </div>
  );
}

export default IQualimeter;
