/* eslint-disable react/destructuring-assignment */
import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import {
  ColumnChooser,
  Grid as ExpressGrid,
  PagingPanel,
  Table,
  TableColumnResizing,
  TableColumnVisibility,
  TableFilterRow,
  TableHeaderRow,
  TableSelection,
  Toolbar,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/styles';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import MultiSelect from 'react-multi-select-component';
import { useHistory, useParams } from 'react-router-dom';
import { Workload3Create } from 'WorkloadType';
import SettingsApplicationsOutlinedIcon from '@mui/icons-material/SettingsApplicationsOutlined';
import {
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdChevronRight,
  MdKeyboardArrowDown,
  MdAddBox,
  MdIndeterminateCheckBox,
  MdFolder,
  MdFolderOpen,
  MdInsertDriveFile,
} from 'react-icons/md';
import CheckboxTree from 'react-checkbox-tree';
import { fileDetails, iCaptureCsv, iCaptureExcel } from '../../../../../api/module1';
import {
  rfpGetFiles,
  schedulephasesData,
  scheduleStreamDates,
  workloadCreate,
  workloadGetById,
  workloadGetRfpHistorybyPageNumber,
  workloadRunPost,
  workloadRunPostPDF,
  workloadRunschedulePost,
} from '../../../../../api/workloads';
import ExcelDoc from '../../../../../assets/icons8-excel-48.png';
import PptDoc from '../../../../../assets/icons8-powerpoint-48.png';
import DocxDoc from '../../../../../assets/icons8-microsoft-word-2019-48.png';
import PdfDoc from '../../../../../assets/pdf_ins.png';
import CsvDoc from '../../../../../assets/csv_icons.png';
import docIcon from '../../../../../assets/docx_ico.png';
import pdfIcon from '../../../../../assets/pdf_ico.png';
import xlsIcon from '../../../../../assets/xlsx_ico.png';
import ToolBarPlugin from '../../../../../components/Datagrid/ToolBarPlugin';
import TStyles, { rowComponent, tUseStyles } from '../../../../../components/Datagrid/TStyles';
import formatBytes from '../../../../../tools/formatBytes';
// import Module2Context from '../../../../../../../Module2Context';
import NERParamTarget from '../../../../../components/NERParamTarget';
import { getCaptureName } from '../../../../../api/breakdowns';
import Loading from '../../../../../components/Core/Loading';
import NavigationGoto from '../../../../../components/Core/NavigationGoto';
import RFPuploadFile from './RFPuploadFile';
import Module2Context from '../../../../Module2/Module2Context';
import ProjectContext from '../../../../Project/ProjectContext';

// table header
const TableComponentBase = ({ classes, ...restProps }: any) => (
  <Table.Table {...restProps} className={classes.tableStriped} />
);

const TableComponent = withStyles(TStyles, { name: 'TableComponent' })(TableComponentBase);

const columns: any = [
  { name: 'filename', title: 'File Name' },
  { name: 'size', title: 'Size' },
  { name: 'folderName', title: 'Folder' },
  { name: 'date', title: 'Date upload' },
];

type Props = {
  id?: number;
  files?: any;
  setFiles?: any;
};
const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiPaper-root': {
      backgroundColor: theme.palette.background.paper,
    },
    backgroundColor: 'rgb(238, 241, 249)',
  },
  paper: {
    '& .MuiPaper-rounded': {
      background: 'red !important',
    },
  },
  captureHandle: {
    '& .MuiInputLabel-outlined': {
      color: '#002A45 !important',
      fontWeight: 'bold',
    },
  },
  formControl1: {
    minWidth: 120,
  },
}));

function FileAnalysis() {
  const { projectId, workloadId } = useParams() as any;
  const [files, setFiles] = useState([]);
  const history = useHistory();
  const classes = useStyles();
  const tableUseStyles = tUseStyles();
  // const { projectId } = useParams() as any;
  const [toggleHeight, setToggleHeight] = useState(false);
  const [hiddenColumnNames, setHiddenColumnNames] = useState([]);
  const [tempPage, setTemPage] = useState(0);
  const [dis, setDis] = useState(true);
  const [pageSize, setPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const exporterRef = useRef(null);
  const [captureNames, setCaptureNames] = useState<any[]>([]);
  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);
  const onSave = (workbook: any) => {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'data.xlsx');
    });
  };
  const { project } = useContext(Module2Context);
  // const [files, setFiles] = useState<any[]>([]);
  const [selection, setSelection] = useState([]);

  // to control the loader and display data full Grid
  const [display, setDisplay] = useState(false);

  const [loadingResult, setLoadingResult] = useState(false);
  const [loading, setLoading] = useState(false);
  const { setSnack } = useContext(ProjectContext);
  // const { setSnack } = useContext(Module2Context);
  const [messageToDisplay, setMessageToDisplay] = useState<any>();

  const [dataoutput, setDataoutput] = useState<any>([]);
  const [columnWidth, setColumnWidth] = useState<any>([]);

  // required when we dont have fixed named columns
  const [rows, setRows] = useState<any>([]);
  const [columnsAre, setColumnsAre] = useState<any>([]);
  const [columnNamesAre, setColumnNamesAre] = useState<any>([]);
  const [newLine, setNewLine] = useState<any>('');
  const [filter, setFilter] = useState<any>([]);
  const [filterEntity, setFilterEntity] = useState<any>([]);

  const [resourcesAre, setResourcesAre] = useState<any>([]);
  const [inputValue, setInputValue] = useState('');
  const inputHandler = (event: any) => setInputValue(event.target.value);

  const [fileName1, setFileName1] = useState<any>();
  const [fileName2, setFileName2] = useState<any>();

  const [sheetName0, setSheetName0] = useState<any>();
  const [targetName0, setTargetName0] = useState<any>();
  const [columnName0, setColumnName0] = useState<any>();

  const [sheetName1, setSheetName1] = useState<any>();
  const [targetName1, setTargetName1] = useState<any>();
  const [columnName1, setColumnName1] = useState<any>();
  const [columnWidths, setColumnWidths] = useState([] as any);
  const [errorMessage, setErrorMessage] = useState('');
  const [load, setLoad] = useState(false);
  const [commonChecked1, setCommonChecked1] = useState([]);
  const [commonCheckedBool1, setCommonCheckedBool1] = useState(false);
  const [commonExpanded, setCommonExpanded] = useState([]);

  const allRules = [
    { label: 'Urban', value: 'Urban' },
    { label: 'mainLine_onboard', value: 'mainLine_onboard' },
    { label: 'mainLine_wayside', value: 'mainLine_wayside' },
    { label: 'turnkey', value: 'turnkeyvalue' },
    { label: 'infra', value: 'infravalue' },
  ];
  const [selectedRules, setSelectedRules] = useState([]);
  // eslint-disable-next-line consistent-return
  const renderRules = () => {
    if (!selectedRules.length) {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <b style={{ color: '#002A45' }}>Platform Type</b>
        </div>
      );
    }
  };
  const handleCommonTreeCheck1 = (checkedItem) => {
    const isParent1Checked = checkedItem.includes('llm');
    setCommonChecked1(checkedItem);
    setCommonCheckedBool1(isParent1Checked);
  };
  const [pvSelected, setPvSelected] = useState([]);

  const handleChange = (event) => {
    setPvSelected(event.target.value);
  };
  function onChangeRules(option: any, selected: any) {
    // console.log('selected', option);
    if (option.length <= 1) {
      setSelectedRules(option);
    }
  }
  // console.log('sect', selectedRules);
  const [pageSizes] = useState([5, 10, 20, 50]);
  const [currentPage1, setCurrentPage1] = useState(0);
  const [tempPage1, setTemPage1] = useState(0);
  const [dis1, setDis1] = useState(true);
  const [pageSize1, setPageSize1] = useState(5);
  useEffect(() => {
    rfpGetFiles(projectId).then((result) => {
      const allResources = [] as any;
      if (result.length > 0) {
        const { resources } = result[0];
        for (const r of resources) {
          allResources.push({
            ...r,
            size: formatBytes(Number(r.size), 0),
            id: r.etag,
            // folderName: 'user',
            folderId: 0,
            isNew: false,
          });
        }
      }

      // Sort the files by date and set them
      // Uncomment the below line if you want to sort by date
      setFiles(allResources.sort((a, b) => new Date(b.date) - new Date(a.date)));
    });
  }, [projectId]);
  const handlePageNum1 = () => {
    const rel = tempPage1 - 1;
    setCurrentPage1(rel);
  };
  const handleSetRowsColumns = async (myRows: {}[]) => {
    const araryCol = Object.keys(myRows[0]);
    const myCols: any = [];
    araryCol.map((key) => {
      // const keyTemp = key.replace(' ', '_');
      if (key !== 'RawData') {
        if (key !== 'Coordinates') {
          if (key !== 'i18n') {
            myCols.push({
              name: key,
              title: key.toUpperCase(),
            });
          }
        }
      }
      return key;
    });
    const resizeColumns = myCols.map((c) => {
      return {
        columnName: c.name,
        width: Math.ceil(10000 / myCols.length),
      };
    });
    setColumnWidths(resizeColumns);
    setColumnsAre(myCols);
    const tempArr: any = [];
    // eslint-disable-next-line array-callback-return
    myCols.map((c: any) => {
      tempArr.push(c.name);
    });
    setColumnNamesAre(tempArr);
    setRows(myRows);
  };
  useEffect(() => {
    workloadGetRfpHistorybyPageNumber(projectId, 1).then((resp) => {
      if (resp) {
        const arr: any = [];
        resp.map((data) => {
          return arr.push(data?.activityName);
        });
        setCaptureNames(arr);
      }
    });
  }, []);
  /**
   * Get all files from project
   */
  const handlePageNum = () => {
    const rel = tempPage - 1;
    setCurrentPage(rel);
  };

  useEffect(() => {
    if (pageSize > files.length) {
      if (dis) {
        setDis((prev) => {
          return !prev;
        });
      }
    } else if (pageSize < files.length) {
      setDis((prev) => {
        return !prev;
      });
    }
  }, [pageSize]);

  useEffect(() => {
    const temp = files.map((data) => {
      return {
        ...data,
        isNew: false,
      };
    });
    setFiles(temp);
  }, []);

  useEffect(() => {
    if (files.length === 0 || projectId === undefined || workloadId === undefined) {
      return;
    }
    setLoadingResult(true);
    workloadGetById(workloadId)
      .then((payload) => {
        setLoadingResult(false);
        const resourcesForPdf = payload?.resources[0]?.scopeSettingOP ?? [
          payload?.resources[0]?.settingsModule,
        ];
        setResourcesAre(resourcesForPdf);
        handleSetRowsColumns(JSON.parse(payload?.results_data[0]?.data?.output));
        setDisplay(true);
      })
      .catch(() => {
        setDisplay(false);
        setSnack(messageToDisplay, 'error');
      });
  }, [files, messageToDisplay, projectId, setSnack, workloadId]);

  const getFileDetails = (inputFiles: any[]) => {
    setLoad(true);
    setDisplay(false);
    const falseArray: any = [];
    const arrayIs: any = [{ columnName: 'Entity', width: 300 }];
    for (const myFile of inputFiles) {
      falseArray.push(
        fileDetails(files[myFile].filename, files[myFile].filename, files[myFile].container)
      );
      arrayIs.push({
        columnName: files[myFile].filename.split('.').slice(0, -1).join('.'),
        width: 300,
      });
    }

    setColumnWidth(arrayIs);
    Promise.all(falseArray)
      .then((responses) => {
        // Get a JSON object from each of the responses
        return Promise.all(
          responses.map((response) => {
            return response;
          })
        );
      })
      .then((data) => {
        const ar: any = [];

        data.map((c: any) => ar.push(c.output));
        setDataoutput(ar);

        setLoad(false);
      })
      .catch((error) => {
        setLoad(false);
        console.log(error);
      });

    // promise all end
  };

  const handleSelectionChange = (selectedKeys: any) => {
    setErrorMessage('');
    setFilter([]);
    setFilterEntity([]);
    const ArrayFiles = selectedKeys;
    setSelection(selectedKeys);
    if (ArrayFiles.length > 1) {
      setSnack('more than 1 files not allowed!', 'error');
    } else {
      getFileDetails(ArrayFiles);
    }
  };

  const [targetColumnsAre, setTargetColumnsAre] = useState<any>([]);

  function HandleReg(e: any, indexIs: any) {
    if (e.target.name === 'sheetname') {
      const state = [...targetColumnsAre];
      state[indexIs] =
        dataoutput[indexIs].Sheets[dataoutput[indexIs].Sheet_names.indexOf(e.target.value)];
      setTargetColumnsAre(state);
      Object.assign(dataoutput[indexIs], { SheetName: e.target.value });

      if (indexIs === 0) {
        setSheetName0(e.target.value);
      } else {
        setSheetName1(e.target.value);
      }
    }
    if (e.target.name === 'targetColumn') {
      Object.assign(dataoutput[indexIs], { TargetColumn: e.target.value });
      if (indexIs === 0) {
        setTargetName0(e.target.value);
      } else {
        setTargetName1(e.target.value);
      }
    }
    if (e.target.name === 'columnname') {
      Object.assign(dataoutput[indexIs], { TargetColumn: e.target.value });
      if (indexIs === 0) {
        setColumnName0(e.target.value);
      } else {
        setColumnName1(e.target.value);
      }
    }
  }

  function handleAnalyze() {
    setErrorMessage('');

    if (dataoutput[0].FileType === 'Excel') {
      if (!sheetName0 || !targetName0) {
        setSnack('Please select both dropdown options', 'error');
        return;
      }
    } else if (dataoutput[0].FileType === 'CSV') {
      if (!columnName0) {
        setSnack('Please select dropdown options', 'error');
        return;
      }
    }
    if (selectedRules.length === 0) {
      setSnack('please select the PlatForm value', 'error');
      return;
    }
    if (inputValue.trim().length === 0) {
      setSnack('Please enter activity name', 'error');
      return;
    }
    if (captureNames.includes(inputValue.trim())) {
      setSnack('That activity name is taken.Please try another', 'error');
      return;
    }

    setLoading(true);
    setDisplay(false);
    const containerIs = dataoutput[0].Container;
    let tempdataoutput: any = [];
    tempdataoutput = JSON.parse(JSON.stringify(dataoutput));
    if (dataoutput[0].FileType === 'PDF') {
      const r = [
        {
          output: { NameOfCapture: inputValue, FileName: dataoutput[0].Blob },
          TextLength: 100000,
          settingsModule: {
            FileName: dataoutput[0].Blob,
            FilePath: dataoutput[0].FilePath,
            Container: dataoutput[0].Container,
            BlobName: dataoutput[0].Blob,
            id: dataoutput[0].Blob,
            FileType: 'pdf',
            Language: dataoutput[0].Language,
            StartPage: 0,
            EndPage: 0,
            HeaderMargin: 0,
            FooterMargin: 0,
            TypesOfCapture: 'Paragraph_Capture',
            RegId: '',
            TableSwitch: true,
            MergeBullet: false,
            SmartHeaderFooter: true,
            SmartTableOfContent: true,
            TranslateTo: ['en'],
            NameOfCapture: inputValue,
            modeService: 'SelfDoc',
          },
        },
      ];
      workloadCreate({
        project_id: projectId,
        app: 'module6',
        action: 'schedule-rfp',
        resources: r,
      })
        .then((workloadNew: Workload3Create) => {
          workloadRunPostPDF(Number(workloadNew.id), r, 'm2-selfdoc')
            .then(() => {
              window.history.pushState(
                '',
                '',
                `/projects/${projectId}/module6/m6/Customer_RFP/history`
              );
              history.push(`/projects/${projectId}/module6/m6/Customer_RFP/history`);
            })
            .catch((e) => {
              setErrorMessage(e.detail);
              setSnack('Error while workload post.', 'error');
              setDisplay(false);
              setLoading(false);
            })
            .finally(() => {});
        })
        .catch(() => {
          setSnack('Error while workload create.', 'error');
          setDisplay(false);
          setLoading(false);
        })
        .finally(() => {});
    } else {
      Promise.all([
        fileDetails(
          files[selection[0]].filename,
          files[selection[0]].filename,
          files[selection[0]].container
        ),
        // fileDetails(
        //   files[selection[1]].filename,
        //   files[selection[1]].filename,
        //   files[selection[1]].container
        // ),
      ])
        .then(async (responseAll) => {
          // for data 0 process to do iCapture

          const arrPromises: any = [];
          function captureAll(): Promise[] {
            for (const [i, itemData] of responseAll.entries()) {
              if (itemData.output.FileType === 'Excel') {
                let columnPosition: any;

                if (sheetName0 !== undefined) {
                  if (sheetName0 !== null) {
                    columnPosition = (responseAll[0]?.output?.Sheet_names).indexOf(sheetName0);
                  }
                }
                if (sheetName1 !== undefined) {
                  if (sheetName1 !== null) {
                    columnPosition = (responseAll[1]?.output?.Sheet_names).indexOf(sheetName1);
                  }
                }
                const sheetNameFind = i === 0 ? sheetName0 : sheetName1;
                const body = {
                  FilePath: itemData.output.FilePath,
                  Container: itemData.output.Container,
                  BlobName: itemData.output.Blob,
                  NameOfCapture: inputValue,
                  Sheet: sheetNameFind,
                  TargetColumn: i === 0 ? targetName0 : targetName1,
                  ColumnNames: itemData?.output?.Sheets[columnPosition]?.ColumNames,
                  Header: true,
                };
                arrPromises.push(iCaptureExcel(body));

                // to arrange sheet number in first position because of middleware 388-405
                // eslint-disable-next-line no-plusplus
                for (let index = 0; index < tempdataoutput.length; index++) {
                  if (tempdataoutput[index].FileName === itemData.output.Blob) {
                    // eslint-disable-next-line no-plusplus
                    for (let jindex = 0; jindex < tempdataoutput[index].Sheets.length; jindex++) {
                      // eslint-disable-next-line eqeqeq
                      if (tempdataoutput[index].Sheets[jindex].SheetName == sheetNameFind) {
                        tempdataoutput[index].Sheet = tempdataoutput[index].Sheets[jindex];
                      }
                    }
                  }
                }
              } else if (itemData.output.FileType === 'CSV') {
                const body = {
                  FilePath: itemData.output.FilePath,
                  Container: itemData.output.Container,
                  BlobName: itemData.output.Blob,
                  NameOfCapture: inputValue,
                  TargetColumn: i === 0 ? columnName0 : columnName1,
                  ColumnNames: itemData?.output?.ColumnNames,
                  Header: true,
                };
                arrPromises.push(iCaptureCsv(body));
              }
            }
            return arrPromises;
          }

          const responseCharacters = await Promise.all(captureAll());

          const len =
            JSON.parse(responseCharacters[0]?.output?.Text)?.length > 0
              ? JSON.parse(responseCharacters[0]?.output?.Text)?.length
              : 0;
          if (len === 0) {
            setSnack('Invalid file format', 'error');
            setLoading(false);
            setDisplay(false);
            return;
          }
          console.log('responce', responseCharacters);

          const r = [
            {
              // ...d,
              // FileName: responseAll[index].output.Blob,
              target: targetName0,
              dataframe: {
                Text: responseCharacters[0].output.Text,
              },
              platformType: selectedRules.map((c) => c.value),
              ex_type: commonCheckedBool1 === false ? '' : commonChecked1[0],
              // dataURL: '',
              // statusURL: '',
              // asyncFlag: false,
            },
          ];
          const streamData = [
            {
              // ...d,
              // FileName: responseAll[index].output.Blob,
              // target: targetName0,
              dataframe: {
                Text: responseCharacters[0].output.Text,
              },
              // platformType: selectedRules.map((c) => c.value),
              // ex_type: commonCheckedBool1 === false ? '' : commonChecked1[0],
              // column_name: 'Text',
              asyncFlag: false,
              // dataURL: '',
              // statusURL: '',
              // asyncFlag: false,
            },
          ];
          const streamPhases = [
            {
              // ...d,
              // FileName: responseAll[index].output.Blob,
              // target: targetName0,
              dataframe: {
                Text: responseCharacters[0].output.Text,
              },
              // platformType: selectedRules.map((c) => c.value),
              // ex_type: commonCheckedBool1 === false ? '' : commonChecked1[0],
              column_name: 'Text',
              asyncFlag: false,
              // dataURL: '',
              // statusURL: '',
              // asyncFlag: false,
            },
          ];
          const workloadPayload = responseCharacters.map((d, index) => ({
            // ...d,
            fileName: responseAll[index].output.Blob,
            target: targetName0,
            activityName: inputValue,
            // dataframe: {
            //   Text: responseAll[index].output.Sheets[index].Text,
            // },
            platformType: selectedRules.map((c) => c.value),
            // dataURL: '',
            // statusURL: '',
            // asyncFlag: false,
          }));

          // workload here
          workloadCreate({
            project_id: projectId,
            app: 'module6',
            action: 'schedule-rfp',
            resources: workloadPayload[0],
          })
            .then((workloadNew: Workload3Create) => {
              workloadRunschedulePost(Number(workloadNew.id), r[0], 'schedule-rfp')
                .then(() => {
                  // window.history.pushState('', '', `/projects/${projectId}`);
                  // history.push(`/projects/${projectId}`);
                  window.history.pushState(
                    '',
                    '',
                    `/projects/${projectId}/module6/m6/Customer_RFP/history`
                  );
                  history.push(`/projects/${projectId}/module6/m6/Customer_RFP/history`);
                  return scheduleStreamDates(streamData[0], Number(workloadNew.id));
                })
                .then((scheduleStreamDatesResponse) => {
                  // Handle the response of scheduleStreamDates
                  // console.log('scheduleStreamDates response:', scheduleStreamDatesResponse);
                  return schedulephasesData(streamPhases[0], Number(workloadNew.id));
                })
                .then((scheduleStreamDatesResponse) => {
                  // Handle the response of scheduleStreamDates
                  // console.log('scheduleStreamDates response:', scheduleStreamDatesResponse);
                  // return scheduleStreamDates(streamData[0], Number(workloadNew.id));
                })
                .catch((e) => {
                  setErrorMessage(e.detail);
                  setSnack('Error while workload post.', 'error');
                  setDisplay(false);
                  setLoading(false);
                })
                .finally(() => {});
            })
            .catch(() => {
              setSnack('Error while workload create.', 'error');
              setDisplay(false);
              setLoading(false);
            })
            .finally(() => {});
        })
        .catch((error) => {
          console.log(error);
          setSnack('Error while processing the scopeSetting for both files.', 'error');
          setDisplay(false);
          setLoading(false);
        });
    }
  }

  const renderFileName = ({ row }: any) => {
    const ext = row.filename.split('.');
    const extensions = {
      xlsx: ExcelDoc,
      csv: CsvDoc,
      pdf: PdfDoc,
      pptx: PptDoc,
      docx: DocxDoc,
    };
    return (
      <div
        style={{
          position: 'relative',
          padding: '0.3rem',
          width: 'max-content',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: '0.2rem' }}>
          <img src={extensions[ext[ext.length - 1]]} height="30" width="30" alt="img" />
          <Typography>{row.filename}</Typography>
        </div>
        {row?.isNew && (
          <div
            style={{
              width: 'max-content',
              padding: '0.2rem',
              borderRadius: '10px',
              backgroundColor: '#19AA6E',
              color: 'white',
              position: 'absolute',
              top: '-12px',
              right: '-35px',
            }}
          >
            <Typography style={{ fontSize: '0.85em' }}>New !</Typography>
          </div>
        )}
      </div>
    );
  };
  const icons = {
    check: <MdCheckBox className="rct-icon rct-icon-check" />,
    uncheck: <MdCheckBoxOutlineBlank className="rct-icon rct-icon-uncheck" />,
    halfCheck: <MdIndeterminateCheckBox className="rct-icon rct-icon-half-check" />,
    expandClose: <MdChevronRight className="rct-icon rct-icon-expand-close" />,
    expandOpen: <MdKeyboardArrowDown className="rct-icon rct-icon-expand-open" />,
    expandAll: <MdAddBox className="rct-icon rct-icon-expand-all" />,
    collapseAll: <MdIndeterminateCheckBox className="rct-icon rct-icon-collapse-all" />,
    parentClose: <MdFolder className="rct-icon rct-icon-parent-close" />,
    parentOpen: <MdFolderOpen className="rct-icon rct-icon-parent-open" />,
    leaf: <MdInsertDriveFile className="rct-icon rct-icon-leaf-close" />,
  };
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <RFPuploadFile files={files} setFiles={setFiles} projectId={projectId} />
      </div>
      <Box style={{ backgroundColor: '#EEF1F9', border: '1px solid #002A45' }}>
        <ExpressGrid rows={files} columns={columns}>
          <DataTypeProvider for={['filename']} formatterComponent={renderFileName} />
          <SelectionState selection={selection} onSelectionChange={handleSelectionChange} />
          <FilteringState />
          <IntegratedFiltering />
          <SortingState />
          <IntegratedSorting />
          <PagingState
            currentPage={currentPage}
            onCurrentPageChange={setCurrentPage}
            pageSize={pageSize}
            onPageSizeChange={setPageSize}
          />
          <IntegratedPaging />
          <VirtualTable />
          <Table tableComponent={TableComponent} />
          <TableHeaderRow showSortingControls />
          <TableFilterRow rowComponent={rowComponent} />
          <TableSelection />
          <PagingPanel pageSizes={pageSizes} />
        </ExpressGrid>
        {/* <NavigationGoto
          setPageSize={setPageSize}
          fileLength={files.length}
          pageSize={pageSize}
          dis={dis}
          setTemPage={setTemPage}
          handlePageNum={handlePageNum}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        /> */}
      </Box>
      {load && <Loading />}
      <Paper style={{ marginTop: '5px' }}>
        {/* <div style={{ backgroundColor: 'rgb(238, 241, 249)' }}> */}
        {dataoutput.length > 0 && (
          <>
            <div style={{ border: 'solid black', borderWidth: '0.1px' }}>
              <Grid container style={{ backgroundColor: '#002A45', color: 'white' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <div>
                    <SettingsApplicationsOutlinedIcon style={{ width: 35, height: 35 }} />
                  </div>
                  <div>
                    <h3>Scope Settings</h3>
                  </div>
                </div>
              </Grid>
              <Grid spacing={0} style={{ padding: '1rem', paddingBottom: 0 }}>
                <Grid container spacing={0}>
                  <Grid item xs={6}>
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                        gap: '40px',
                      }}
                    >
                      {dataoutput.map((c, indexIs) => (
                        <FormControl variant="outlined">
                          <span>
                            {c?.FileType === 'unsupported' ? (
                              <img
                                src={docIcon}
                                alt="isens"
                                style={{
                                  width: 20,
                                  height: 25,
                                  transform: 'translateY(8px)',
                                }}
                              />
                            ) : (
                              ''
                            )}
                            {c?.FileType === 'Excel' || c?.FileType === 'CSV' ? (
                              <img
                                src={xlsIcon}
                                alt="isens"
                                style={{
                                  width: 20,
                                  height: 25,
                                  transform: 'translateY(8px)',
                                }}
                              />
                            ) : (
                              ''
                            )}
                            {c?.FileType === 'PDF' ? (
                              <img
                                src={pdfIcon}
                                alt="isens"
                                style={{
                                  width: 20,
                                  height: 25,
                                  transform: 'translateY(8px)',
                                }}
                              />
                            ) : (
                              ''
                            )}
                            &nbsp;
                            <Tooltip title={c?.Blob} placement="top" arrow>
                              <b style={{ color: 'blue', wordWrap: 'break-word' }}>
                                {c?.Blob.substring(0, 25)}
                              </b>
                            </Tooltip>
                          </span>
                          {c?.FileType !== 'unsupported' ? (
                            <span>
                              <span style={{ color: 'red' }}>{c?.Error}</span>
                            </span>
                          ) : (
                            ''
                          )}
                        </FormControl>
                      ))}
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                      }}
                    >
                      {dataoutput.map((c, indexIs) => (
                        <>
                          <Box
                            style={{
                              flexBasis: '78%',
                              display: 'flex',
                              height: '2.5rem',
                              gap: '10px',
                              justifyContent: 'flex-end',
                              alignItems: 'center',
                            }}
                          >
                            <Grid container spacing={2}>
                              {c.FileType === 'Excel' && (
                                <Grid item xs={12}>
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      gap: '10px',
                                      // marginBottom: '2rem',
                                    }}
                                  >
                                    <FormControl
                                      variant="outlined"
                                      size="small"
                                      style={{ flexBasis: '50%', height: '2.5rem' }}
                                    >
                                      <InputLabel
                                        htmlFor="outlined-age-native-simple"
                                        style={{ color: '#002A45', fontWeight: 'bold' }}
                                        size="small"
                                      >
                                        Sheet
                                      </InputLabel>
                                      <Select
                                        native
                                        label="Sheet  Name"
                                        size="small"
                                        // name={'sheetname'.concat(indexIs)}
                                        name="sheetname"
                                        style={{ width: '100%' }}
                                        onChange={(e) => HandleReg(e, indexIs)}
                                        defaultValue=""
                                      >
                                        <option aria-label="None" value="" disabled />
                                        {c?.Sheet_names !== null
                                          ? c?.Sheet_names.map((cap: string, index: number) => (
                                              <option key={`capture-type-${index}`} value={cap}>
                                                &nbsp;&nbsp;{cap}
                                              </option>
                                            ))
                                          : ''}
                                      </Select>
                                    </FormControl>
                                    <FormControl
                                      variant="outlined"
                                      size="small"
                                      style={{ flexBasis: '50%', height: '2.5rem' }}
                                    >
                                      <InputLabel
                                        htmlFor="outlined-age-native-simple"
                                        style={{ color: '#002A45', fontWeight: 'bold' }}
                                      >
                                        Target Column
                                      </InputLabel>
                                      <Select
                                        native
                                        label="Target  Column"
                                        name="targetColumn"
                                        style={{ width: '100%' }}
                                        onChange={(e) => HandleReg(e, indexIs)}
                                        defaultValue=""
                                      >
                                        <option aria-label="None" value="" disabled />
                                        <NERParamTarget
                                          key={`NERParamTarget-${indexIs}`}
                                          data={targetColumnsAre[indexIs]}
                                        />
                                      </Select>
                                    </FormControl>
                                  </div>
                                </Grid>
                              )}
                              {/* <Grid item xs={4}>
                            <FormControl
                              variant="outlined"
                              style={{ minWidth: '140px', maxWidth: '140px' }}
                            >
                              <MultiSelect
                                options={allRules}
                                value={selectedRules}
                                onChange={onChangeRules}
                                labelledBy="Rules"
                                valueRenderer={renderRules}
                              />
                            </FormControl>
                          </Grid> */}
                            </Grid>
                            <FormControl
                              variant="outlined"
                              style={{ minWidth: '140px', maxWidth: '140px' }}
                            >
                              <MultiSelect
                                hasSelectAll={false}
                                options={allRules}
                                value={selectedRules}
                                onChange={onChangeRules}
                                labelledBy="Rules"
                                valueRenderer={renderRules}
                              />
                            </FormControl>
                          </Box>
                        </>
                      ))}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container justify="flex-end" style={{ padding: '1rem', paddingBottom: 0 }}>
                {/* <Grid item xs={4}>
                  <TextField
                    id="outlined-basic"
                    label="Enter Activity Name"
                    variant="outlined"
                    name="captureName"
                    onChange={inputHandler}
                    size="small"
                    style={{
                      width: '170px',
                      height: '38px',
                      marginTop: 6,
                      marginBottom: 6,
                      backgroundColor: 'white',
                    }}
                  />
                </Grid>

                <Grid item xs={4}>
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    startIcon={loading && <CircularProgress style={{ color: 'white' }} size={20} />}
                    onClick={handleAnalyze}
                    style={{
                      width: '90%',
                      // flex: 1,
                      // marginTop: 6,
                      // marginBottom: 6,
                    }}
                  >
                    Process
                  </Button>
                </Grid> */}
                <Grid item xs={4}>
                  {}
                </Grid>
                <Grid item xs={8}>
                  <Box style={{ display: 'flex' }}>
                    <Box style={{ flexBasis: '30%' }}>{}</Box>
                    <Box
                      style={{
                        flexBasis: '78%',
                        display: 'flex',
                        height: '2.5rem',
                        gap: '20px',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                      }}
                    >
                      <CheckboxTree
                        nodes={[{ value: 'llm', label: 'LLM' }]}
                        checked={commonChecked1}
                        expanded={commonExpanded}
                        onCheck={handleCommonTreeCheck1}
                        onExpand={() => setCommonChecked1}
                        icons={icons}
                        style={{ width: 'max-content' }}
                      />
                      <TextField
                        id="outlined-basic"
                        label="Enter Activity Name"
                        variant="outlined"
                        name="captureName"
                        onChange={inputHandler}
                        size="small"
                        style={{
                          width: '170px',
                          height: '38px',
                          marginTop: 6,
                          marginBottom: 6,
                          backgroundColor: 'white',
                        }}
                      />
                      <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        startIcon={
                          loading && <CircularProgress style={{ color: 'white' }} size={20} />
                        }
                        onClick={handleAnalyze}
                        // style={{
                        //   width: '90%',
                        //   // flex: 1,
                        //   // marginTop: 6,
                        //   // marginBottom: 6,
                        // }}
                        style={{
                          width: '150px',
                          height: '38px',
                          marginTop: 6,
                          marginBottom: 6,
                        }}
                      >
                        Process
                      </Button>
                      {/* <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        startIcon={
                          loading && <CircularProgress style={{ color: 'white' }} size={20} />
                        }
                        onClick={handleAnalyze}
                        style={{
                          width: '150px',
                          height: '38px',
                          marginTop: 6,
                          marginBottom: 6,
                        }}
                      >
                        Analyze
                      </Button> */}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <br />
              <Grid item xs={4} alignItems="center" justify="center">
                {errorMessage !== '' && (
                  <Typography style={{ color: '#DC3223' }}>Error : {errorMessage}</Typography>
                )}
              </Grid>
            </div>
          </>
        )}
      </Paper>

      <br />
      {/* <ReactJson src={{ rows }} collapsed={true} theme="monokai" /> */}
      {loadingResult ? <Loading /> : ''}
    </>
  );
}

export default FileAnalysis;
