import React from 'react';
import { Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import FullscreenIcon from '@mui/icons-material/Fullscreen';

const ExpandContainer = ({ isChatExpandable, setIsChatExpand, children, mode = 'GenA' }: any) => {
  return (
    <>
      <div
        style={
          isChatExpandable
            ? {
                bottom: 0,
                height: '100vh',
                left: 0,
                margin: 0,
                maxHeight: '100vh',
                maxWidth: '100vw',
                padding: 0,
                position: 'fixed',
                right: 0,
                top: 0,
                width: '100vw',
                zIndex: 1299,
                backgroundColor: 'white',
                // position: 'relative',
              }
            : { position: 'relative' }
        }
      >
        {/* {mode === 'Rex' && (
          <Button
            style={{
              color: '#ffffff',
              position: 'absolute',
              right: isChatExpandable ? -10 : '-14px',
              zIndex: 1299,
            }}
            type="button"
            aria-label="search"
            onClick={() => {
              return setIsChatExpand(false);
            }}
          >
            {isChatExpandable ? <FullscreenExitIcon /> : <FullscreenIcon />}
          </Button>
        )} */}
        {children}
      </div>
    </>
  );
};

export default ExpandContainer;
