const isearcherFilterReducer = (
  state: {
    productLineDef: any;
    filterProjectsDef: any;
    filterDocumentProfilesDef: any;
    filterPlatformsDef: any;
    filterSolutionDef: any;
    filterProductDef: any;
  },
  action: {
    type: any;
    payload: {
      productLineDef: any;
      filterProjectsDef: any;
      filterDocumentProfilesDef: any;
      filterPlatformsDef: any;
      filterSolutionDef: any;
      filterProductDef: any;
      filterCountryDef: any;
      filterPlatforms: any;
      filterSolution: any;
      filterProduct: any;
      filterProjects: any;
      filterDocumentProfiles: any;
      filterCountry: any;
      productLine: any;
      productLinefilt1: any;
      filterPlatformsfilt2: any;
      filterProjectsfilt3: any;
      filterDocumentProfilesfilt4: any;
      filterSolutionfilt6: any;
      filterProductfilt7: any;
    };
  }
) => {
  switch (action.type) {
    case 'set_default_fill': {
      return {
        ...state,
        productLineDef: action.payload.productLineDef,
        filterProjectsDef: action.payload.filterProjectsDef,
        filterDocumentProfilesDef: action.payload.filterDocumentProfilesDef,
        filterPlatformsDef: action.payload.filterPlatformsDef,
        filterSolutionDef: action.payload.filterSolutionDef,
        filterProductDef: action.payload.filterProductDef,
        filterCountryDef: action.payload.filterCountryDef,
        productLine: action.payload.productLineDef,
        filterPlatforms: action.payload.filterPlatformsDef,
        filterSolution: action.payload.filterSolutionDef,
        filterProduct: action.payload.filterProductDef,
        filterProjects: action.payload.filterProjectsDef,
        filterDocumentProfiles: action.payload.filterDocumentProfilesDef,
        filterCountry: action.payload.filterCountryDef,
      };
    }
    case 'clear_isearch_filter': {
      return {
        ...state,
        productLine: state.productLineDef,
        filterProjects: state.filterProjectsDef,
        filterDocumentProfiles: state.filterDocumentProfilesDef,
        filterPlatforms: state.filterPlatformsDef,
        filterSolution: state.filterSolutionDef,
        filterProduct: state.filterProductDef,
        productLinefilt1: [],
        filterPlatformsfilt2: [],
        filterProjectsfilt3: [],
        filterDocumentProfilesfilt4: [],
        filt5: [],
        filterSolutionfilt6: [],
        filterProductfilt7: [],
        filt8: [],
        maxResultsValue: 500,
        door: '*',
      };
    }

    case 'set_artifact_fill': {
      return {
        ...state,
        artiFact: action.payload,
      };
    }

    case 'isearchser_productLine_change': {
      return {
        ...state,
        productLinefilt1: action.payload,
      };
    }

    case 'isearchser_Platform_change': {
      return {
        ...state,
        filterPlatformsfilt2: action.payload,
      };
    }

    case 'isearchser_Project_change': {
      return {
        ...state,
        filterProjectsfilt3: action.payload,
      };
    }

    case 'isearchser_DocProfile_change': {
      return {
        ...state,
        filterDocumentProfilesfilt4: action.payload,
      };
    }

    case 'artifact_change': {
      return {
        ...state,
        filt5: action.payload,
      };
    }
    case 'isearchser_Solution_change': {
      return {
        ...state,
        filterSolutionfilt6: action.payload,
      };
    }
    case 'isearchser_Product_change': {
      return {
        ...state,
        filterProductfilt7: action.payload,
      };
    }
    case 'isearchser_Country_change': {
      return {
        ...state,
        filt8: action.payload,
      };
    }

    case 'noChange_in_productLine': {
      return {
        ...state,
        filterPlatforms: action.payload.filterPlatforms,
        filterSolution: action.payload.filterSolution,
        filterProduct: action.payload.filterProduct,
        filterProjects: action.payload.filterProjects,
        filterDocumentProfiles: action.payload.filterDocumentProfiles,
        filterCountry: action.payload.filterCountry,
      };
    }

    case 'noChange_in_platform': {
      return {
        ...state,
        productLine: action.payload.productLine,
        filterSolution: action.payload.filterSolution,
        filterProduct: action.payload.filterProduct,
        filterProjects: action.payload.filterProjects,
        filterDocumentProfiles: action.payload.filterDocumentProfiles,
        filterCountry: action.payload.filterCountry,
      };
    }
    case 'noChange_in_Solution': {
      return {
        ...state,
        productLine: action.payload.productLine,
        filterPlatforms: action.payload.filterPlatforms,
        filterProduct: action.payload.filterProduct,
        filterProjects: action.payload.filterProjects,
        filterDocumentProfiles: action.payload.filterDocumentProfiles,
        filterCountry: action.payload.filterCountry,
      };
    }
    case 'noChange_in_Product': {
      return {
        ...state,
        productLine: action.payload.productLine,
        filterPlatforms: action.payload.filterPlatforms,
        filterSolution: action.payload.filterSolution,
        filterProjects: action.payload.filterProjects,
        filterDocumentProfiles: action.payload.filterDocumentProfiles,
        filterCountry: action.payload.filterCountry,
      };
    }

    case 'noChange_in_project': {
      return {
        ...state,
        productLine: action.payload.productLine,
        filterPlatforms: action.payload.filterPlatforms,
        filterSolution: action.payload.filterSolution,
        filterProduct: action.payload.filterProduct,
        filterDocumentProfiles: action.payload.filterDocumentProfiles,
        filterCountry: action.payload.filterCountry,
      };
    }

    case 'noChange_in_documentProfile': {
      return {
        ...state,
        productLine: action.payload.productLine,
        filterPlatforms: action.payload.filterPlatforms,
        filterSolution: action.payload.filterSolution,
        filterProduct: action.payload.filterProduct,
        filterProjects: action.payload.filterProjects,
        filterCountry: action.payload.filterCountry,
      };
    }
    case 'noChange_in_Country': {
      return {
        ...state,
        productLine: action.payload.productLine,
        filterPlatforms: action.payload.filterPlatforms,
        filterSolution: action.payload.filterSolution,
        filterProduct: action.payload.filterProduct,
        filterProjects: action.payload.filterProjects,
        filterDocumentProfiles: action.payload.filterDocumentProfiles,
      };
    }

    case 'set_isearcher_max': {
      return {
        ...state,
        maxResultsValue: action.payload,
      };
    }

    case 'set_isearcher_door': {
      return {
        ...state,
        door: action.payload,
      };
    }

    case 'set_db_custom_filter': {
      return {
        ...state,
        productLinefilt1: action.payload.productLinefilt1,
        filterPlatformsfilt2: action.payload.filterPlatformsfilt2,
        filterProjectsfilt3: action.payload.filterProjectsfilt3,
        filterDocumentProfilesfilt4: action.payload.filterDocumentProfilesfilt4,
        filterSolutionfilt6: action.payload.filterSolutionfilt6,
        filterProductfilt7: action.payload.filterProductfilt7,
      };
    }
    default:
      return state;
  }
};

export default isearcherFilterReducer;
