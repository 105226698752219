/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */
import React, { useCallback, useEffect, useState, useRef, useContext, useMemo } from 'react';
import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { useParams } from 'react-router-dom';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { Badge, Grid, Skeleton } from '@mui/material';
import {
  Grid as ExpressGrid,
  PagingPanel,
  Table,
  TableHeaderRow,
  Toolbar,
  TableColumnVisibility,
  ColumnChooser,
  TableColumnResizing,
  TableSelection,
  TableFilterRow,
} from '@devexpress/dx-react-grid-material-ui';
import InfoIcon from '@mui/icons-material/Info';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import Highlighter from 'react-highlight-words';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Paper,
  CardHeader,
  IconButton,
  Button,
  Tooltip as CustomTooltip,
  Avatar,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  CircularProgress,
  Divider,
} from '@material-ui/core';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { ReactSVG } from 'react-svg';
import TStyles, {
  rowComponent,
  tableAllocatorComponent,
  toolbarComponent,
  tableHeaderComponent,
} from '../../../components/Datagrid/TStyles';
import NavigationGotoNew from '../../../components/Core/NavigationGotoNew';
import ToolBarPlugin from '../../../components/Datagrid/ToolBarPlugin';
import './transitionCss.css';
import Kpis from '../../../components/Filter/Kpis';
// import money from '../../../assets/icons8-money-50.png';
import money from '../../../svg/money.svg';
import IsearcherStyle from '../../../components/Layout/IsearcherStyle';
import smile from '../../../svg/smile.svg';
import straightFace from '../../../svg/straightFace.svg';
import sad from '../../../svg/sad.svg';
import MultiAllocTableComp from '../../../components/Datagrid/MultiAllocTableComp';
import {
  rexColumnResize,
  highlightConfig,
  hiddenColumns,
} from '../TextAnalysisResultContainer/const/rexconst';
import {
  generateRexSummary,
  saveRexLike,
  saveRexDisLike,
  getRexCardInfo,
  updateRexLike_Dislike,
} from '../../../api/module2';
import RexCardGenA from './RexCardGenA';
import useAppToken from '../../../app/useAppToken';
import Module2Context from '../../Module2/Module2Context';
import Loading from './core/Loading';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 900,
    fontSize: 16,
    border: '1px solid #dadde9',
  },
  containerBox: {
    // borderRadius: '10px',
    width: '100%',
    // height: '300px',
    display: 'flex',
    flexBasis: '50%',
    flexDirection: 'column',
    border: '1px solid #002A45',
  },
  childBox0: {
    display: 'flex',
    backgroundColor: '#002a45',
    alignItems: 'center',
    color: 'white',
    font: 'normal normal normal 15px/15px Alstom',
  },
  childBox2: {
    display: 'flex',
    backgroundColor: '#EEF1F9',
    // alignItems: 'center',
    color: '#002A45',
    font: 'normal normal normal 15px/15px Alstom',
    flexDirection: 'row',
  },
  childBox21: {
    display: 'flex',
    // backgroundColor: 'green',
    // alignItems: 'center',
    // color: '#002A45',
    flexBasis: '50%',
    font: 'normal normal normal 15px/15px Alstom',
    // padding: '0.5rem 0.5rem',
  },
  childBox22: {
    display: 'flex',
    // backgroundColor: 'red',
    // alignItems: 'center',
    // color: '#002A45',
    flexBasis: '50%',
    font: 'normal normal normal 15px/15px Alstom',
    // padding: '0.5rem 0.5rem',
  },
}));

const ValueWithTooltip = ({ value }) => {
  const shouldShowTooltip = value.length > 25;

  return shouldShowTooltip ? (
    <>
      <Tooltip title={value} arrow>
        <Typography noWrap style={{ marginTop: 0, marginBottom: 0, marginRight: 60 }}>
          :&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {value ? value.slice(0, 20) : 'NA'}...
        </Typography>
      </Tooltip>
    </>
  ) : (
    <>
      <Typography noWrap style={{ marginTop: 0, marginBottom: 0, marginRight: 60 }}>
        :&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        {value || 'NA'}
      </Typography>
    </>
  );
};

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 900,
    background: 'none !important',
    border: '1px solid black',
    // boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    padding: 0,
    transition: 'opacity 1s',
    '& .MuiTooltip-arrow': {
      color: '#eef1f9',
      '&:before': {
        backgroundColor: '#002A45',
        boxShadow: 'none',
        borderBottom: `1px solid black`,
        borderRight: `1px solid black`,
        fontSize: 50,
      },
    },
  },
});
const CustomScrollBox = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',
  color: '#002A45',
  padding: '10px',
  maxHeight: '20rem',
  overflowY: 'scroll',
  '::-webkit-scrollbar': {
    width: '2px',
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: '#002A45',
    borderRadius: '4px',
  },
  '::-webkit-scrollbar-track': {
    backgroundColor: '#f1f1f1',
  },
}));

const TableComponentBase = ({ classes, ...restProps }: any) => (
  <Table.Table {...restProps} className={classes.tableStriped} />
);

export const SummaryCard = ({
  id,
  name,
  title,
  text,
  typology,
  action,
  context,
  problem,
  showCashIcon,
  last,
  justification,
  rexInfo,
  mode = 'rex',
  setSnack,
}: any) => {
  const classes = useStyles();
  const ref = useRef(null);
  const { appJWT } = useAppToken();
  const [thumbUpColor, setThumbUpColor] = useState('white');
  const [thumbDownColor, setThumbDownColor] = useState('white');
  const [open, setOpen] = useState(false);
  const [comment, setComment] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [like_unLikeData, setLike_unLikeData] = useState([]);
  const [likeCount, setLikeCount] = useState(0);
  const [dislikeCount, setDislikeCount] = useState(0);

  const {
    status,
    organization,
    countryIssuerSite,
    productOrchestra,
    platform,
    projectCtCodeOrchestra,
    abs,
    picSupport,
    capitalizationEvidence,
  } = rexInfo || {};

  const getRexCardData = async () => {
    const data = await getRexCardInfo(id);
    setLoading(false);
    setLike_unLikeData(data);
    setLikeCount(data[0]?.rex_like?.length);
    setDislikeCount(data[0]?.rex_dislike?.length);
    data[0]?.rex_like?.forEach((element) => {
      if (element.user_id === appJWT?.user_id && element.like === 1) {
        setThumbUpColor('#19aa6e');
        setThumbDownColor('white');
      }
    });
    data[0]?.rex_dislike?.forEach((element) => {
      if (element.user_id === appJWT?.user_id && element.dislike === 1) {
        setThumbDownColor('#DC3223');
        setThumbUpColor('white');
      }
    });
  };

  useEffect(() => {
    if (mode === 'rex') {
      setLoading(true);
      getRexCardData();
    }
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setComment(''); // Reset the comment when closing
    setError('');
  };

  const handleLike = () => {
    setLoading(true);
    if (
      like_unLikeData?.length === 0 ||
      (like_unLikeData?.rex_dislike?.length === 0 && like_unLikeData?.rex_like?.length === 0)
    ) {
      // create call if user has not liked or disliked
      saveRexLike(id, title, appJWT?.user_id)
        .then((data) => {
          if (data?.rex_like) {
            setThumbUpColor('#19aa6e');
          }
          getRexCardData();
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (
      like_unLikeData[0]?.rex_like?.some((likeEntry) => likeEntry.user_id === appJWT?.user_id)
    ) {
      // this code is for same user already liked
      setLoading(false);
      return setSnack('You have already liked this card', 'error');
    } else {
      // this code is for other user disliked and current user liked
      let new_rex_like = [];
      let new_rex_dislike = [];
      if (comment.length === 0) {
        like_unLikeData[0]?.rex_like?.push({ user_id: appJWT?.user_id, like: 1 });
        new_rex_like = like_unLikeData[0]?.rex_like;

        new_rex_dislike = like_unLikeData[0]?.rex_dislike.filter(
          (item) => item.user_id !== appJWT?.user_id
        );
      } else {
        new_rex_like = [];
      }
      const body = {
        rex_id: id,
        rex_title: title,
        rex_like: new_rex_like,
        rex_dislike: new_rex_dislike,
        // comment.length !== 0 ? [{ user_id: appJWT?.user_id, dislike: 1, comment }] : [],
        created_at: new Date(),
      };
      updateRexLike_Dislike(id, body)
        .then((data) => {
          getRexCardData();
        })
        .catch((error) => {
          console.log(error);
        });
    }
    return setSnack('Successfully liked the card', 'success');
  };

  const handleDislike = () => {
    // if (comment.trim() === '') {
    //   setError('Comment is required.');
    //   return; // Prevent submission if validation fails
    // }

    if (comment.length > 200) {
      setError('Comment must be less than 200 characters.');
      return; // Prevent submission if validation fails
    }
    setLoading(true);
    setError('');
    // Handle the dislike action with the comment here
    if (
      like_unLikeData?.length === 0 ||
      (like_unLikeData[0]?.rex_like?.length === 0 && like_unLikeData?.rex_dislike?.length === 0)
    ) {
      saveRexDisLike(id, title, appJWT?.user_id, comment)
        .then((data) => {
          if (data?.rex_dislike) {
            setThumbDownColor('#DC3223');
          }
          getRexCardData();
        })
        .catch((error) => {
          console.error('Error processing dislike:', error);
          setError('An error occurred while processing your request.');
        });
    } else if (
      like_unLikeData[0]?.rex_dislike?.some(
        (dislikeEntry) => dislikeEntry.user_id === appJWT?.user_id
      )
    ) {
      // this code is for same user already disliked
      setLoading(false);
      return setSnack('You have already disliked this card', 'error');
      // let new_rex_dislike = [];
      // like_unLikeData[0]?.rex_dislike?.push({
      //   user_id: appJWT?.user_id,
      //   dislike: 1,
      //   comment,
      // });
      // new_rex_dislike = like_unLikeData[0]?.rex_dislike;
      // const body = {
      //   rex_id: id,
      //   rex_title: title,
      //   rex_like: like_unLikeData[0]?.rex_like,
      //   rex_dislike: new_rex_dislike,
      //   created_at: new Date(),
      // };
      // updateRexLike_Dislike(id, body)
      //   .then((data) => {
      //     getRexCardData();
      //   })
      //   .catch((error) => {
      //     console.error('Error processing dislike:', error);
      //     setError('An error occurred while processing your request.');
      //   });
    } else {
      let new_rex_dislike = [];
      let new_rex_like = [];

      like_unLikeData[0]?.rex_dislike?.push({
        user_id: appJWT?.user_id,
        dislike: 1,
        comment,
      });
      new_rex_dislike = like_unLikeData[0]?.rex_dislike;
      new_rex_like = like_unLikeData[0]?.rex_like.filter(
        (item) => item.user_id !== appJWT?.user_id
      );

      const body = {
        rex_id: id,
        rex_title: title,
        rex_like: new_rex_like,
        rex_dislike: new_rex_dislike,
        created_at: new Date(),
      };
      updateRexLike_Dislike(id, body)
        .then((data) => {
          getRexCardData();
        })
        .catch((error) => {
          console.error('Error processing dislike:', error);
          setError('An error occurred while processing your request.');
        });
    }
    handleClose(); // Close the modal
    setSnack('Successfully updated feedback', 'success');
  };

  const renderEmoji = () => {
    switch (typology) {
      case 'Negative':
        return (
          <CustomTooltip title={typology} arrow classes={{ tooltip: classes.tooltip }}>
            <ReactSVG src={sad} />
          </CustomTooltip>
        );
      case 'Positive':
        return (
          <CustomTooltip title={typology} arrow classes={{ tooltip: classes.tooltip }}>
            <ReactSVG src={smile} />
          </CustomTooltip>
        );
      case 'Not defined':
        return (
          <CustomTooltip title={typology} arrow classes={{ tooltip: classes.tooltip }}>
            <ReactSVG src={straightFace} />
          </CustomTooltip>
        );
      default:
        return null;
    }
  };

  const formatResponse = (text) => {
    return text.replaceAll('\n', '<br>');
  };

  return (
    <Card
      style={{
        flex: '1',
        // boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
        backgroundColor: '#EEF1F9',
        borderRadius: '15px',
        border: !showCashIcon ? '1px solid #002A45' : '1px solid #A59472',
        padding: 0,
      }}
    >
      <CardContent
        style={{
          // flex: '1',
          // boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
          // backgroundColor: '#EEF1F9',
          padding: 0,
        }}
      >
        <div
          style={{
            padding: '12px 10px',
            borderRadius: '5px 5px 0px 0px',
            backgroundColor: !showCashIcon ? '#002A45' : '#A59472',
            boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              // paddingTop: 5,
              // paddingBottom: 5,
              // paddingLeft: 5,
              // marginBottom: 16,
              gap: 5,
            }}
          >
            <CustomTooltip title={title} arrow classes={{ tooltip: classes.tooltip }}>
              <div
                style={{
                  flexBasis: !showCashIcon ? '100%' : '90%',
                  display: 'flex',
                  // gap: 5,
                  alignItems: 'center',
                }}
              >
                <SummarizeIcon style={{ color: !showCashIcon ? '#FFFFFF' : '#FFFFFF' }} />
                <Typography
                  variant="h6"
                  component="div"
                  style={{ color: !showCashIcon ? '#FFFFFF' : '#FFFFFF' }}
                >
                  {id && (
                    <span style={{ color: !showCashIcon ? '#87C8FF' : '#FFEFCE' }}>
                      ID - {id} &nbsp;
                    </span>
                  )}
                  {title.length > last ? `${title.slice(0, last)}...` : title} {name}
                </Typography>
              </div>
            </CustomTooltip>

            <div
              style={{
                flexBasis: '10%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                paddingRight: '5px',
                gap: '1rem',
              }}
            >
              {rexInfo && (
                <CustomWidthTooltip
                  placement="bottom-end"
                  arrow
                  // open={true}
                  title={
                    <>
                      <Box className={classes.containerBox}>
                        <Box className={classes.childBox0}>
                          <span style={{ paddingLeft: '0.5rem' }}>
                            <InfoIcon />
                          </span>{' '}
                          <Typography style={{ paddingLeft: '0.5rem' }}>REX Card info</Typography>
                        </Box>
                        <Box className={classes.childBox2}>
                          <Box className={classes.childBox21}>
                            <List style={{ paddingTop: 0, paddingBottom: 0 }}>
                              <ListItem key="status" style={{ paddingTop: 4, paddingBottom: 4 }}>
                                <ListItemText
                                  disableTypography
                                  primary={
                                    <Typography
                                      noWrap
                                      style={{ marginTop: 0, marginBottom: 0, fontWeight: 'bold' }}
                                    >
                                      STATUS
                                    </Typography>
                                  }
                                />
                              </ListItem>

                              <ListItem
                                key="organization"
                                style={{ paddingTop: 4, paddingBottom: 4 }}
                              >
                                <ListItemText
                                  disableTypography
                                  primary={
                                    <Typography
                                      noWrap
                                      style={{ marginTop: 0, marginBottom: 0, fontWeight: 'bold' }}
                                    >
                                      Organization
                                    </Typography>
                                  }
                                />
                              </ListItem>

                              <ListItem key="country" style={{ paddingTop: 4, paddingBottom: 4 }}>
                                <ListItemText
                                  disableTypography
                                  primary={
                                    <Typography
                                      noWrap
                                      style={{ marginTop: 0, marginBottom: 0, fontWeight: 'bold' }}
                                    >
                                      Country & Issuer Site(by Origin)
                                    </Typography>
                                  }
                                />
                              </ListItem>

                              <ListItem key="product" style={{ paddingTop: 4, paddingBottom: 4 }}>
                                <ListItemText
                                  disableTypography
                                  primary={
                                    <Typography
                                      noWrap
                                      style={{ marginTop: 0, marginBottom: 0, fontWeight: 'bold' }}
                                    >
                                      Product (Orchestra)
                                    </Typography>
                                  }
                                />
                              </ListItem>

                              <ListItem key="platform" style={{ paddingTop: 4, paddingBottom: 4 }}>
                                <ListItemText
                                  disableTypography
                                  primary={
                                    <Typography
                                      noWrap
                                      style={{ marginTop: 0, marginBottom: 0, fontWeight: 'bold' }}
                                    >
                                      Platform
                                    </Typography>
                                  }
                                />
                              </ListItem>

                              <ListItem key="project" style={{ paddingTop: 4, paddingBottom: 4 }}>
                                <ListItemText
                                  disableTypography
                                  primary={
                                    <Typography
                                      noWrap
                                      style={{ marginTop: 0, marginBottom: 0, fontWeight: 'bold' }}
                                    >
                                      Project & CT code (Orchestra)
                                    </Typography>
                                  }
                                />
                              </ListItem>

                              <ListItem key="ABS" style={{ paddingTop: 4, paddingBottom: 4 }}>
                                <ListItemText
                                  disableTypography
                                  primary={
                                    <Typography
                                      noWrap
                                      style={{ marginTop: 0, marginBottom: 0, fontWeight: 'bold' }}
                                    >
                                      ABS
                                    </Typography>
                                  }
                                />
                              </ListItem>

                              <ListItem
                                key="PIC-Support"
                                style={{ paddingTop: 4, paddingBottom: 4 }}
                              >
                                <ListItemText
                                  disableTypography
                                  primary={
                                    <Typography
                                      noWrap
                                      style={{ marginTop: 0, marginBottom: 0, fontWeight: 'bold' }}
                                    >
                                      {' '}
                                      PIC-Support
                                    </Typography>
                                  }
                                />
                              </ListItem>

                              <ListItem
                                key="Capitalization"
                                style={{ paddingTop: 4, paddingBottom: 4 }}
                              >
                                <ListItemText
                                  disableTypography
                                  primary={
                                    <Typography
                                      noWrap
                                      style={{ marginTop: 0, marginBottom: 0, fontWeight: 'bold' }}
                                    >
                                      {' '}
                                      Capitalization Evidence
                                    </Typography>
                                  }
                                />
                              </ListItem>
                            </List>
                          </Box>
                          <Box className={classes.childBox22}>
                            <List style={{ paddingTop: 0, paddingBottom: 0 }}>
                              <ListItem
                                key="status-resp"
                                style={{ paddingTop: 4, paddingBottom: 4 }}
                              >
                                <ListItemText
                                  disableTypography
                                  primary={<ValueWithTooltip value={status} />}
                                />
                              </ListItem>

                              <ListItem key="org-resp" style={{ paddingTop: 4, paddingBottom: 4 }}>
                                <ListItemText
                                  disableTypography
                                  style={{ marginTop: 0, marginBottom: 0 }}
                                  primary={
                                    <ListItemText
                                      primary={<ValueWithTooltip value={organization} />}
                                    />
                                  }
                                />
                              </ListItem>

                              <ListItem
                                key="country-resp"
                                style={{ paddingTop: 4, paddingBottom: 4 }}
                              >
                                <ListItemText
                                  disableTypography
                                  style={{ marginTop: 0, marginBottom: 0 }}
                                  primary={
                                    <ListItemText
                                      primary={<ValueWithTooltip value={countryIssuerSite} />}
                                    />
                                  }
                                />
                              </ListItem>

                              <ListItem key="prod-resp" style={{ paddingTop: 4, paddingBottom: 4 }}>
                                <ListItemText
                                  disableTypography
                                  style={{ marginTop: 0, marginBottom: 0 }}
                                  primary={
                                    <ListItemText
                                      primary={<ValueWithTooltip value={productOrchestra} />}
                                    />
                                  }
                                />
                              </ListItem>

                              <ListItem key="plat-resp" style={{ paddingTop: 4, paddingBottom: 4 }}>
                                <ListItemText
                                  disableTypography
                                  style={{ marginTop: 0, marginBottom: 0 }}
                                  primary={
                                    <ListItemText primary={<ValueWithTooltip value={platform} />} />
                                  }
                                />
                              </ListItem>
                              <ListItem
                                key="Project-resp"
                                style={{ paddingTop: 4, paddingBottom: 4 }}
                              >
                                <ListItemText
                                  disableTypography
                                  style={{ marginTop: 0, marginBottom: 0 }}
                                  primary={
                                    <ListItemText
                                      primary={<ValueWithTooltip value={projectCtCodeOrchestra} />}
                                    />
                                  }
                                />
                              </ListItem>
                              <ListItem key="ABS-resp" style={{ paddingTop: 4, paddingBottom: 4 }}>
                                <ListItemText
                                  disableTypography
                                  style={{ marginTop: 0, marginBottom: 0 }}
                                  primary={
                                    <ListItemText primary={<ValueWithTooltip value={abs} />} />
                                  }
                                />
                              </ListItem>
                              <ListItem key="PIC-resp" style={{ paddingTop: 4, paddingBottom: 4 }}>
                                <ListItemText
                                  disableTypography
                                  style={{ marginTop: 0, marginBottom: 0 }}
                                  primary={
                                    <ListItemText
                                      primary={<ValueWithTooltip value={picSupport} />}
                                    />
                                  }
                                />
                              </ListItem>
                              <ListItem
                                key="Capitalization-resp"
                                style={{ paddingTop: 4, paddingBottom: 4 }}
                              >
                                <ListItemText
                                  disableTypography
                                  style={{ marginTop: 0, marginBottom: 0 }}
                                  primary={
                                    <ListItemText
                                      primary={<ValueWithTooltip value={capitalizationEvidence} />}
                                    />
                                  }
                                />
                              </ListItem>
                            </List>
                          </Box>
                        </Box>
                      </Box>
                    </>
                  }
                >
                  <IconButton style={{ padding: 0 }}>
                    <InfoIcon style={{ fontSize: 35, color: 'white' }} />
                  </IconButton>
                </CustomWidthTooltip>
              )}

              {renderEmoji()}
              {showCashIcon && (
                <CustomTooltip title={justification} arrow classes={{ tooltip: classes.tooltip }}>
                  <div>
                    {/* <img src={money} height="30" width="30" alt="money" />{' '} */}
                    <ReactSVG src={money} />
                  </div>
                </CustomTooltip>
              )}
              <Divider
                orientation="vertical"
                flexItem
                style={{ width: '1px', backgroundColor: !showCashIcon ? '#EEF1F9' : '#7A6234' }}
              />
              {mode === 'rex' && (
                <>
                  <IconButton
                    style={{ padding: 0, marginBottom: 5 }}
                    onClick={() => {
                      handleLike();
                    }}
                  >
                    {loading ? (
                      <div
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                      >
                        <CircularProgress />
                        <Typography style={{ marginLeft: 10 }}>Loading...</Typography>
                      </div>
                    ) : (
                      <Badge
                        badgeContent={likeCount}
                        color="success"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        sx={{
                          '& .MuiBadge-badge': {
                            backgroundColor: '#19aa6e', // Custom dot color for dislikes
                          },
                        }}
                      >
                        <ThumbUpIcon fontSize="large" style={{ color: thumbUpColor }} />
                      </Badge>
                    )}
                  </IconButton>
                  <IconButton style={{ padding: 0, marginBottom: 5 }} onClick={handleClickOpen}>
                    {loading ? (
                      <Loading />
                    ) : (
                      <Badge badgeContent={dislikeCount} color="error">
                        <ThumbDownIcon fontSize="large" style={{ color: thumbDownColor }} />
                      </Badge>
                    )}
                  </IconButton>
                </>
              )}
              <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{ style: { width: '500px', borderRadius: '8px', padding: '20px' } }}
              >
                <DialogTitle
                  id="form-dialog-title"
                  style={{ fontWeight: 'bold', color: ' #002A45' }}
                >
                  Dislike Feedback Message
                </DialogTitle>
                <DialogContent>
                  <TextField
                    autoFocus
                    margin="dense"
                    label="Comment"
                    type="text"
                    fullWidth
                    variant="outlined"
                    multiline
                    rows={4}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    error={!!error} // Set error state for the TextField
                    helperText={error} // Show error message below the TextField
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      // Handle the dislike action here
                      handleDislike();
                    }}
                    color="primary"
                  >
                    Dislike
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </div>
        </div>
        <CustomScrollBox>
          <>
            {problem && (
              <>
                <Typography style={{ fontWeight: 'bold', fontSize: 15, color: '#DC3223' }}>
                  Problem / Success Oppurtunity :
                </Typography>
                <Typography style={{ textAlign: 'justify', fontSize: 15 }}>{problem}</Typography>
              </>
            )}

            {context && (
              <>
                <Typography style={{ fontWeight: 'bold', fontSize: 15, color: '#2699FB' }}>
                  Context :
                </Typography>
                <Typography style={{ textAlign: 'justify', fontSize: 15 }}>{context}</Typography>
              </>
            )}

            {context || problem ? (
              <>
                <Typography style={{ fontWeight: 'bold', fontSize: 15, color: '#002A45' }}>
                  Key Takeaways :
                </Typography>
                <div
                  dangerouslySetInnerHTML={{
                    __html: formatResponse(action),
                  }}
                />

                {/* <Typography style={{ textAlign: 'justify', fontSize: 15 }}>{}</Typography> */}
              </>
            ) : (
              <ol style={{ fontSize: 15, fontWeight: 'bolds', margin: 0 }}>
                {text?.split('\n').map((data, index) => {
                  const t = data?.split(' ')?.slice(1, data.split(' ')?.length).join(' ');
                  return (
                    <li key={index}>
                      <Typography style={{ fontSize: 15, fontWeight: 'normal' }}>{t}</Typography>
                    </li>
                  );
                })}
              </ol>
            )}
          </>
        </CustomScrollBox>
      </CardContent>
    </Card>
  );
};

const RexChild = ({ rows, columns, data1, data2, data3, data4, rexCashCount, setSnack }) => {
  // const [rows, setRows] = useState(row);
  // const [columns, setColumns] = useState(column);
  const [loading, setLoading] = useState(false);
  const [tempPage, setTemPage] = useState(0);
  const [dis, setDis] = useState(true);
  const [pageSize, setPageSize] = useState(4);
  const classes = IsearcherStyle();
  const pageSizes = [5];
  const [currentPage, setCurrentPage] = useState(0);
  const [toggleHeight, setToggleHeight] = useState(false);
  const [summaryArray, setSummaryArray] = useState([]);
  const [hiddenColumnNames, setHiddenColumnNames] = useState([...hiddenColumns]);
  const exporterRef = useRef(null);
  const [summaryInterval, setSummaryInterval] = useState();

  // console.log('columns', columns);

  const tableScrollComponent: React.FunctionComponent<Table.CellProps> = (props) => {
    return <Table.Container {...props} className={classes.tablescrol} />;
  };

  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);

  const handlePageNum = () => {
    const rel = tempPage - 1;
    setCurrentPage(rel);
  };

  const fetchData = () => {
    const start = Math.abs(pageSize - pageSize * (currentPage + 1));
    const last = start + 4;
    const n = rows.length;
    if (start < n && last <= n) {
      const temp = rows.slice(start, last);
      const IdsPromisses = temp.map((data) => {
        return () => generateRexSummary(data.id);
      });
      Promise.all(IdsPromisses.map((fn) => fn()))
        .then((result) => {
          setSummaryArray(result);
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (start < n && Math.abs(n - start) <= n) {
      const temp = rows.slice(start, Math.abs(n - start) + start);
      const IdsPromisses = temp.map((data) => {
        return () => generateRexSummary(data.id);
      });
      Promise.all(IdsPromisses.map((fn) => fn()))
        .then((result) => {
          setSummaryArray(result);
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    setLoading(true);
    if (summaryInterval) {
      clearTimeout(summaryInterval);
    }
    const newSearchTimeout = setTimeout(() => {
      fetchData();
    }, 1000);
    setSummaryInterval(newSearchTimeout);
  }, [currentPage, rows]);

  const onSave = (workbook: any) => {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Rex_report.xlsx');
    });
    // setRemind(true);
    // setRemidText('Downloaded');
  };

  const renderText = ({ value, row }: DataTypeProvider.ValueFormatterProps) => (
    <a
      id={`search-${row.id}`}
      // href="https://myworkplace.alstom.hub/Citrix/Alstom_PROD_StoreWeb/"
      href={row.url}
      target="_blank"
      rel="noreferrer"
      onClick={() => {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        // urlSearch(String(row.url));
      }}
      style={{ textDecoration: 'none' }}
      title={value}
    >
      {value}
    </a>
  );

  const renderTextHighlighter = ({ row, value }) => {
    const searchWords = Object.keys(highlightConfig);

    return (
      <div
        style={{
          fontWeight: 'bold',
          color: 'white',
          width: ['DIS', 'RSC', 'Services', 'Not Defined'].includes(value) ? '4rem' : '8rem',
          textAlign: 'center',
          height: '100%',
          padding: '0.5rem',
          borderRadius: '5px',
          backgroundColor: highlightConfig[value]?.backgroundColor,
        }}
      >
        {value}
      </div>
    );
  };
  const isOdd = summaryArray?.slice(2, summaryArray.length).length % 2 !== 0;

  return (
    <>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          width: '100%',
        }}
      >
        {/* <RexCardGenA /> */}
        <div style={{ display: 'flex' }}>
          <Typography variant="h6" style={{ fontWeight: 'bold' }}>
            <span>
              <b>{rows.length}</b> results found
            </span>
            &nbsp;&nbsp;&nbsp;
          </Typography>
          {rexCashCount !== 0 && (
            <CustomTooltip title={`REXcash: ${rexCashCount ?? 0}`} arrow>
              <div style={{ position: 'relative' }}>
                <span
                  style={{
                    position: 'absolute',
                    top: '-20px',
                    left: '20px',
                    width: 'min-content',
                    background: 'rgb(102, 187, 106)',
                    color: 'white',
                    padding: '0rem 0.5rem',
                    display: 'flex',
                    justifyContent: 'center',
                    borderRadius: '25px',
                    marginTop: '5px',
                  }}
                >
                  {rexCashCount ?? 0}
                </span>
                {/* <img src={money} height="30" width="30" alt="money" /> */}
                <ReactSVG src={money} />
              </div>
            </CustomTooltip>
          )}
        </div>
        <Box
          style={{ backgroundColor: '#EEF1F9', border: '1px solid #002A45', borderRadius: '20px' }}
        >
          <ExpressGrid rows={rows} columns={columns}>
            <DataTypeProvider for={['title', 'description']} formatterComponent={renderText} />
            <DataTypeProvider
              for={['status', 'organisation']}
              formatterComponent={renderTextHighlighter}
            />
            <FilteringState />
            <IntegratedFiltering />
            <SortingState />
            <IntegratedSorting />
            <PagingState
              currentPage={currentPage}
              onCurrentPageChange={setCurrentPage}
              pageSize={pageSize}
              onPageSizeChange={setPageSize}
            />
            <IntegratedPaging />
            <Table
              tableComponent={MultiAllocTableComp}
              containerComponent={tableScrollComponent}
              headComponent={tableHeaderComponent}
              columnExtensions={[
                { columnName: 'title', wordWrapEnabled: toggleHeight },
                { columnName: 'description', wordWrapEnabled: toggleHeight },
                { columnName: 'analysis', wordWrapEnabled: toggleHeight },
                { columnName: 'capitalizationEvidence', wordWrapEnabled: toggleHeight },
                { columnName: 'proposedAction', wordWrapEnabled: toggleHeight },
              ]}
            />
            <TableColumnResizing defaultColumnWidths={[...rexColumnResize]} />
            <TableHeaderRow showSortingControls />
            <TableFilterRow rowComponent={rowComponent} />
            <TableColumnVisibility
              hiddenColumnNames={hiddenColumnNames}
              onHiddenColumnNamesChange={setHiddenColumnNames}
            />
            <Toolbar rootComponent={toolbarComponent} />
            <ColumnChooser />
            <ToolBarPlugin name="Download" onClick={startExport} />
            <ToolBarPlugin
              name="Height"
              title="Wrap Text"
              onClick={() => setToggleHeight(!toggleHeight)}
            />
            <PagingPanel
              containerComponent={(props) => (
                <>
                  <PagingPanel.Container {...props} className={classes.pagingPanelContainer} />
                </>
              )}
            />
          </ExpressGrid>
          <GridExporter
            ref={exporterRef}
            rows={rows}
            columns={columns}
            onSave={onSave}
            // customizeCell={customizeCell}
            // customizeHeader={customizeHeader}
          />
          <NavigationGotoNew
            setPageSize={setPageSize}
            fileLength={rows.length}
            pageSize={pageSize}
            dis={dis}
            setTemPage={setTemPage}
            handlePageNum={handlePageNum}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </Box>
        {loading ? (
          [1, 2, 3, 4, 5].map((data) => {
            return (
              <Box style={{ marginBottom: '2rem' }}>
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
              </Box>
            );
          })
        ) : (
          <>
            <Box style={{ display: 'flex', flexWrap: 'wrap', gap: '1rem' }}>
              {summaryArray?.slice(0, 2)?.map((results) => {
                if (results?.summary === undefined) {
                  return null;
                }
                const { action, context, problem } = results?.summary || {};
                const { showCashIcon, rexInfo } = results;
                const { title, typology, id } = results.searchResult[0];
                return (
                  <SummaryCard
                    id={id}
                    name=""
                    typology={typology}
                    title={title}
                    text=""
                    showCashIcon={showCashIcon}
                    action={action}
                    context={context}
                    problem={problem}
                    last={30}
                    justification={results?.cashImpactJustification}
                    rexInfo={rexInfo}
                    setSnack={setSnack}
                  />
                );
              })}
            </Box>
            <Box
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '1rem',
                width: isOdd ? '50%' : '100%',
              }}
            >
              {summaryArray?.slice(2, summaryArray.length)?.map((results) => {
                if (results?.summary === undefined) {
                  return null;
                }
                const { action, context, problem } = results?.summary || {};
                const { title, typology, id } = results.searchResult[0];
                const { showCashIcon, rexInfo } = results;
                return (
                  <SummaryCard
                    id={id}
                    name=""
                    typology={typology}
                    title={title}
                    text=""
                    showCashIcon={showCashIcon}
                    action={action}
                    context={context}
                    problem={problem}
                    last={30}
                    justification={results?.cashImpactJustification}
                    rexInfo={rexInfo}
                    setSnack={setSnack}
                  />
                );
              })}
            </Box>
            {/* <Box style={boxStyle}>
              {summaryArray?.slice(4, summaryArray.length)?.map((results) => {
                if (results?.summary === undefined) {
                  return null;
                }
                const { action, context, problem } = results?.summary || {};
                const { title, typology, id } = results.searchResult[0];
                const { showCashIcon, rexInfo } = results;
                return (
                  <SummaryCard
                    id={id}
                    name=""
                    title={title}
                    typology={typology}
                    text=""
                    showCashIcon={showCashIcon}
                    action={action}
                    context={context}
                    problem={problem}
                    last={100}
                    justification={results?.cashImpactJustification}
                    rexInfo={rexInfo}
                  />
                );
              })}
            </Box> */}
          </>
        )}
        <Kpis data1={data1} data2={data2} data3={data3} data4={data4} />
        {/* <SummaryCard name="Global REX Summary" text={result?.rexSummary} /> */}
      </Box>
    </>
  );
};

export default RexChild;
