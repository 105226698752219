import {
  Box,
  FormControl,
  InputLabel,
  Button,
  MenuItem,
  Select,
  TablePagination,
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import CircularProgress from '@mui/material/CircularProgress';
import React, { ChangeEvent, MouseEvent, useEffect, useMemo, useState } from 'react';
import useAxiosAuth from '../../API/axios';
import { Topic } from '../../components';
import NewIdeaFab from '../../components/ui/NewIdeaFab/NewIdeaFab';
import SummaryTopicCardByStatus from '../../components/ui/SummaryCard/SummaryTopicCardByStatus';
import TopicCreationDialog from '../../components/ui/TopicCreation/TopicCreationDialog';
import TopicDetail from '../../components/ui/TopicDetail/TopicDetail';
import { IParamDetail } from '../../interfaces/IParamDetail';
import { ITopic } from '../../interfaces/ITopic';
import Filter from '../../layouts/filter/filter';
import { apiEndpoints } from '../../utils/apiEndpoints';
import {
  crossDomainsList,
  userStatusLifecycle,
  userStatusMapping,
} from '../../utils/statusLifercycle';
import HomeContextProvider from './homeContextProvider';

interface HomePageProps {
  user: 'normal' | 'admin';
  userInfo: any;
  currentUserId: number;
  currentUserFullName: string;
  currentUserEmail: string;
  currentUserFunction: string;
  setCurrentUserFunction: React.Dispatch<React.SetStateAction<string>>;
  currentUserDepartment: string;
  setCurrentUserDepartment: React.Dispatch<React.SetStateAction<string>>;
}

const HomePage: React.FC<HomePageProps> = ({
  user,
  userInfo,
  currentUserId,
  currentUserFullName,
  currentUserEmail,
  currentUserFunction,
  setCurrentUserFunction,
  currentUserDepartment,
  setCurrentUserDepartment,
}) => {
  const [topicStatuses, setTopicStatuses] = useState<IParamDetail[]>([]);
  const [topics, setTopics] = useState<ITopic[]>([]);
  const [selectedValue, setSelectedValue] = useState('');
  const { AxiosCall } = useAxiosAuth();
  const [filterByStatusIds, setFilterByStatusIds] = useState<number[]>([]);
  const [filterByStatus, setFilterByStatus] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [filterByTitle, setFilterByTitle] = useState<string>('');
  const rowsPerPageOptions = [25, 50, 100, 150];
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(0);
  const [openTopicCreationDlg, setOpenTopicCreationDlg] = useState({
    open: false,
    draft: null,
    id: 0,
  });
  const [topicSharingMailToSubjectTemplate, setTopicSharingMailToSubjectTemplate] = useState<
    string
  >('');
  const [topicSharingMailToContentTemplate, setTopicSharingMailToContentTemplate] = useState<
    string
  >('');

  const popupTopic = useMemo(() => {
    const topicId = +window.location.hash.replace(/#\/topics\/(\d+)/, '$1');
    const topic = topics?.find((t) => t.id === topicId);
    return topic;
  }, [window.location.hash, topics]);

  const [allStatusAdmin, setAllStatusAdmin] = useState<any>({});
  const [allStatusUser, setAllStatusUser] = useState<any>({});

  function fetchTopics() {
    setLoading(true);
    const payload = {
      url: apiEndpoints.API_TOPIC_URL,
      method: 'GET',
      data: null,
      header: null,
    };
    AxiosCall(payload)
      .then(setTopics)
      .then(() => setLoading(false));
  }

  useEffect(() => {
    AxiosCall({
      url: `${apiEndpoints.API_TOPIC_STATUS}/all`,
      method: 'GET',
    })
      // .then((res) => res.json())
      .then((res) => {
        if (res) {
          const temp = { ...res };
          delete temp.Postponed;
          console.log('temp', temp);
          setAllStatusAdmin(res);
        }
      });

    AxiosCall({
      url: `${apiEndpoints.API_TOPIC_STATUS}/all/user`,
      method: 'GET',
    })
      // .then((res) => res.json())
      .then((res) => {
        setAllStatusUser(res);
      });
    function fetchTopicStatus() {
      const payload = {
        url: apiEndpoints.API_TOPIC_STATUS_URL,
        method: 'GET',
        data: null,
        header: null,
      };

      AxiosCall(payload).then((res) => {
        const filtered = res.filter((data) => {
          return data.label !== 'Postponed';
        });
        setTopicStatuses(filtered);
      });
    }

    function fetchEmailTemplate() {
      fetch('/email-templates/topic-sharing.html')
        .then((response) => {
          if (!response.ok) {
            throw new Error('Failed to load template');
          }
          return response.text(); // Read the HTML file as text
        })
        .then((template) => {
          setTopicSharingMailToSubjectTemplate(
            template.replace(/@@subject\n(.*)\n@@subject\n*@@body([\w\W]+)@@body/g, '$1')
          );
          setTopicSharingMailToContentTemplate(
            template.replace(/@@subject\n(.*)\n@@subject\n*@@body([\w\W]+)@@body/g, '$2').trim()
          );
        });
    }

    fetchTopicStatus();
    fetchTopics();
    fetchEmailTemplate();
  }, []);

  const filterTopics = useMemo(() => {
    if (filterByStatusIds.length === 0) {
      return topics;
    }

    return topics.filter((t) => filterByStatusIds.includes(t.topicStatus.id));
  }, [topics, filterByStatusIds]);

  const visibleFilterTopics = useMemo(() => {
    return filterTopics.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [filterTopics, page, rowsPerPage]);

  const replaceOldTopicByUpdatedTopic = (
    updatedStatusTopic: ITopic,
    isDelete: boolean = false,
    isAdd: boolean = false
  ) => {
    let newTopics = JSON.parse(JSON.stringify(topics)) as ITopic[];
    if (isDelete) {
      newTopics = newTopics.filter((t) => t.id !== updatedStatusTopic.id);
    } else if (isAdd) {
      updatedStatusTopic.likedUsers = [];
      updatedStatusTopic.followedByUsers = [];
      updatedStatusTopic.topicComments = [];
      newTopics = [updatedStatusTopic, ...newTopics];
    } else {
      for (let i = 0; i < newTopics.length; i++) {
        if (newTopics[i].id === updatedStatusTopic.id) {
          newTopics[i].topicStatus = updatedStatusTopic.topicStatus;
          newTopics[i].submittedAt = updatedStatusTopic.submittedAt;
          newTopics[i].acceptedBacklogAt = updatedStatusTopic.acceptedBacklogAt;
          newTopics[i].estimationDeliveredAt = updatedStatusTopic?.estimationDeliveredAt;
          newTopics[i].devInProgressAt = updatedStatusTopic.devInProgressAt;
          newTopics[i].inNonProdAt = updatedStatusTopic.inNonProdAt;
          newTopics[i].likedUsers = updatedStatusTopic.likedUsers;
          newTopics[i].followedByUsers = updatedStatusTopic.followedByUsers;
          newTopics[i].topicComments = updatedStatusTopic.topicComments;
          break;
        }
      }
    }
    setTopics(newTopics);
  };

  function handleChangePage(_: MouseEvent<HTMLButtonElement> | null, newPage: number) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  function handleFilterStatus(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    let newFilterStatus = [];
    if (checked) {
      newFilterStatus = [...filterByStatus, event.target.value];
    } else {
      newFilterStatus = filterByStatus.filter((e) => e !== event.target.value);
    }

    setFilterByStatus(newFilterStatus);
  }

  const topStatusList =
    userStatusLifecycle.map((status: any) => {
      return {
        title: status,
        count: topics.filter((t) => t.topicStatus.label === status).length,
      };
    }) ?? [];

  const handleSearchByTitle = (search: string) => {
    setFilterByTitle(search);
  };

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <>
      <HomeContextProvider
        value={{
          user: userInfo,
          allStatus: topicStatuses,
          allStatusAdmin: allStatusAdmin,
          allStatusUser: allStatusUser,
        }}
      >
        <div className="bg-gray-100 md:px-20 px-5 min-h-screen pt-8 pb-20">
          {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
           
          </div> */}
          <Box style={{ display: 'flex', gap: '5px' }}>
            <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
              <Filter
                currentUserId={currentUserId}
                topicStatuses={topicStatuses}
                filterByStatusIds={filterByStatusIds}
                setFilterByStatusIds={setFilterByStatusIds}
                setTopics={setTopics}
                onSearchByTitle={handleSearchByTitle}
              />
              <FormControl style={{ marginLeft: '16px', height: '4rem' }} fullWidth>
                <InputLabel id="Cross-Domain">Cross Domain</InputLabel>
                <Select
                  name="cross_domain"
                  label="Cross Domain"
                  labelId="Cross-Domain"
                  value={selectedValue}
                  onChange={handleChange}
                  // size="small"
                  sx={{ borderRadius: '0px' }}
                  // displayEmpty
                >
                  <MenuItem value="">
                    <em>None</em> {/* Option for empty selection */}
                  </MenuItem>
                  {crossDomainsList.map((c) => (
                    <MenuItem key={c} value={c}>
                      {c}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
              {topStatusList.map((status: any) => (
                <SummaryTopicCardByStatus
                  key={`summary-topic-card-${status.title}`}
                  statusname={status.title}
                  count={status.count}
                  onFilter={handleFilterStatus}
                />
              ))}
            </div>
          </Box>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={
                loading ? <CircularProgress size={20} color="secondary" /> : <RefreshIcon />
              }
              onClick={() => {
                fetchTopics();
              }}
            >
              Refresh
            </Button>
          </div>
          <div className="py-3 items-center mt-3">
            <ul className="space-y-3">
              {visibleFilterTopics
                .filter((topic) => {
                  if (!filterByTitle || filterByTitle.length === 0) return true;
                  const regex = new RegExp(filterByTitle, 'gi');
                  return regex.test(topic?.title);
                })
                .filter((topic) => {
                  if (filterByStatus.length === 0) return true;
                  return filterByStatus.includes(userStatusMapping[topic.topicStatus.label]);
                })
                .filter((topic) => {
                  // Filter by selected dropdown value (if applicable)
                  if (!selectedValue) return true; // No selection, show all
                  return topic.cross_domain === selectedValue; // Adjust this based on your mapping
                })
                .map((topic) => (
                  <li key={`li-topic-${topic.id}`}>
                    <Topic
                      key={`topic-${topic.id}`}
                      mailToSubjectTemplate={topicSharingMailToSubjectTemplate}
                      mailToContentTemplate={topicSharingMailToContentTemplate}
                      topicStatuses={topicStatuses}
                      user={user}
                      topic={topic}
                      visibleFilterTopics={visibleFilterTopics}
                      currentUserId={currentUserId}
                      replaceOldTopicByUpdatedTopic={replaceOldTopicByUpdatedTopic}
                      setOpenTopicCreationDlg={setOpenTopicCreationDlg}
                    />
                  </li>
                ))}
            </ul>
          </div>
          <div className="flex justify-center">
            <TablePagination
              component="div"
              rowsPerPageOptions={rowsPerPageOptions}
              count={filterTopics.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
          <NewIdeaFab
            onClick={() => setOpenTopicCreationDlg({ open: true, draft: null, id: 0 })}
            title="Create topic"
            sx={{ position: 'fixed', bottom: 16, right: 16 }}
          />
          <TopicCreationDialog
            open={openTopicCreationDlg.open}
            checkDialog={openTopicCreationDlg}
            setOpen={setOpenTopicCreationDlg}
            topicStatuses={topicStatuses}
            currentUserId={currentUserId}
            currentUserFullName={currentUserFullName}
            currentUserEmail={currentUserEmail}
            currentUserFunction={currentUserFunction}
            setCurrentUserFunction={setCurrentUserFunction}
            currentUserDepartment={currentUserDepartment}
            setCurrentUserDepartment={setCurrentUserDepartment}
            replaceOldTopicByUpdatedTopic={replaceOldTopicByUpdatedTopic}
          />
          {window.location.hash.match(/#\/topics\/\d+/) && popupTopic && (
            <TopicDetail
              mailToSubjectTemplate={topicSharingMailToSubjectTemplate}
              mailToContentTemplate={topicSharingMailToContentTemplate}
              topicStatuses={topicStatuses}
              user={user}
              topic={popupTopic}
              visibleFilterTopics={[popupTopic]}
              currentUserId={currentUserId}
              replaceOldTopicByUpdatedTopic={replaceOldTopicByUpdatedTopic}
            />
          )}
        </div>
      </HomeContextProvider>
    </>
  );
};

export default HomePage;
