/* eslint-disable no-continue */
/* eslint-disable no-loop-func */
/* eslint-disable no-lonely-if */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import React, { memo, useCallback, useContext, useEffect, useMemo, useState, useRef } from 'react';
import { chatGPTContinueConversation, chatGPTCreateConversation } from '../../../../api/chat_gpt';

import useAppToken from '../../../../app/useAppToken';
import AlertSnack from '../../../../components/Alert/AlertSnack';
import M2GlobalContext from '../../../ContextMager/M2GlobalContext';
import Module2Context from '../../Module2Context';
import { ChatContext } from './ChatContext';
import { parseResponseAzureOpenAI, parseResponseGpt3 } from './tools/parseApiResponse';

const extractLayer = (subLayer) => {
  return subLayer.map((element) => element.label);
};

const InputPrompt = ({ init }: { init?: string }) => {
  const { state, dispatch } = useContext(ChatContext);
  const { project } = useContext(Module2Context);
  // const { state: stateSixlayer } = useContext(M2GlobalContext);
  const { appJWT } = useAppToken();

  const { state: rexState, dispatch: rexDispatch } = useContext(M2GlobalContext);
  const { rexFilter, similarityFilter } = rexState;

  function openArray(arr) {
    const r = arr?.map((obj) => obj.value);
    return r;
  }
  const passedData = {
    // m2 rex filter
    Product_Line:
      rexFilter?.productrexLine?.length !== 0 ? openArray(rexFilter?.productrexLine) : null,
    Platform: rexFilter?.rexPlatform?.length !== 0 ? openArray(rexFilter?.rexPlatform) : null,
    Solution: rexFilter?.solution?.length !== 0 ? openArray(rexFilter?.solution) : null,
    Product: rexFilter?.product?.length !== 0 ? openArray(rexFilter?.product) : null,
    Project: rexFilter?.project?.length !== 0 ? openArray(rexFilter?.project) : null,
    Country_of_Destination: rexFilter?.country?.length !== 0 ? openArray(rexFilter?.country) : null,
  };

  const passedDataPrivate = useMemo(() => {
    return {
      Product_Line:
        similarityFilter?.dropDown1?.length !== 0 ? openArray(similarityFilter?.dropDown1) : null,
      Platform:
        similarityFilter?.dropDown2?.length !== 0 ? openArray(similarityFilter?.dropDown2) : null,
      Solution:
        similarityFilter?.dropDown5?.length !== 0 ? openArray(similarityFilter?.dropDown5) : null,
      Product:
        similarityFilter?.dropDown6?.length !== 0 ? openArray(similarityFilter?.dropDown6) : null,
      Project:
        similarityFilter?.dropDown3?.length !== 0 ? openArray(similarityFilter?.dropDown3) : null,
      Document_Profile:
        similarityFilter?.dropDown4?.length !== 0 ? openArray(similarityFilter?.dropDown4) : null,
    };
  }, [similarityFilter]);

  // Define the state and ref for passedDataPrivate
  const [passedDataPrivateInt, setPassedDataPrivateInt] = useState(passedDataPrivate);
  const passedDataPrivateRef = useRef(passedDataPrivateInt);

  // Update the ref whenever the state changes
  useEffect(() => {
    passedDataPrivateRef.current = passedDataPrivateInt;
  }, [passedDataPrivateInt]);

  useEffect(() => {
    if (passedDataPrivate) {
      setPassedDataPrivateInt(passedDataPrivate);
    }
  }, [passedDataPrivate]);

  function removeEmpty(passedData: any) {
    const newData = Object.fromEntries(
      Object.entries(passedData).filter(([_, value]) => value != null)
    );
    return newData;
  }

  function transformQueryNew(input) {
    try {
      console.log('input ', input);
      if (typeof input !== 'object' || input === null) {
        throw new Error('Input must be a non-null object.');
      }

      // Step 1: Process each key-value pair in the input object
      const outputClauses = Object.keys(input).map((key) => {
        const valuesArray = input[key];

        console.log('values array ', valuesArray);

        // Validate that the value associated with each key is an array
        if (!Array.isArray(valuesArray) || valuesArray.length === 0) {
          console.warn(`Invalid value for key "${key}". Expected a non-empty array.`);
          return '';
        }

        // Step 2: Create output clause
        if (valuesArray.length > 1) {
          return `(${valuesArray
            .map((val) => `${key} eq '${val.replace(/'/g, "''")}'`)
            .join(' or ')})`;
        }
        return `(${key} eq '${valuesArray[0].replace(/'/g, "''")}')`;
      });

      // Filter out empty strings that may result from invalid inputs
      const filteredClauses = outputClauses.filter((clause) => clause !== '');

      // Join the clauses using 'and' to form the final query string
      const finalQueryString = filteredClauses.join(' and ');

      console.log('final query', finalQueryString);

      // If no valid clauses were generated, throw an error
      if (!finalQueryString) {
        throw new Error('No valid query could be generated from the input.');
      }

      return finalQueryString;
    } catch (error) {
      console.error('Error generating query:', error.message);
      // Return an empty string or a default safe response
      return '';
    }
  }

  // function transformQueryNew(input) {
  //   // Step 1: Process each key-value pair in the input object
  //   const outputClauses = Object.keys(input).map((key) => {
  //     const valuesArray = input[key];
  //     // Step 2: Create output clause
  //     if (valuesArray.length > 1) {
  //       return `(${valuesArray.map((val) => `${key} eq '${val}'`).join(' or ')})`;
  //     }
  //     return `(${key} eq '${valuesArray}')`;
  //   });

  //   // Step 3: Join output clauses back together with ' and '
  //   return outputClauses.join(' and ');
  // }

  const handleCloseSnack = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch({
      type: 'SET_ERROR',
      payload: '',
    });
  };

  // const buildSixLayer = useMemo(() => {
  //   return {
  //     sixlayer: {
  //       product_lines: extractLayer(stateSixlayer.similarityFilter.dropDown1),
  //       platforms: extractLayer(stateSixlayer.similarityFilter.dropDown2),
  //       solutions: extractLayer(stateSixlayer.similarityFilter.dropDown5),
  //       products: extractLayer(stateSixlayer.similarityFilter.dropDown6),
  //       project_names: extractLayer(stateSixlayer.similarityFilter.dropDown3),
  //     },
  //   };
  // }, [stateSixlayer.similarityFilter]);

  const resetPrompt = () => {
    try {
      if (document.getElementById('chat-textbox')) {
        document.getElementById('chat-textbox').value = '';
        document.getElementById('chat-textbox').focus();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const _handleSubmitPrompt = () => {
    const sixlayerFilter = transformQueryNew(
      removeEmpty(state.gptEngine === 'private' ? passedDataPrivateRef.current : passedData)
    );

    const promptText = document.getElementById('chat-textbox').value.trim();
    // validation text  not empty
    if (promptText.length === 0) {
      dispatch({
        type: 'SET_ERROR',
        payload: 'Please enter a valid text',
      });
      return;
    }
    resetPrompt();
    try {
      const abortControllerPost = new AbortController();
      const abortController = new AbortController();
      dispatch({
        type: 'ABORTCONTROLLER_ADD',
        payload: abortControllerPost,
      });
      dispatch({
        type: 'ABORTCONTROLLER_ADD',
        payload: abortController,
      });
      dispatch({
        type: 'LOADING_PROMPT',
        payload: true,
      });
      dispatch({
        type: 'PROMPT_START',
        payload: promptText,
      });
      if (state.gptEngine === 'public') {
        const streamMode = true;

        // new discussion public
        if (!state.discussionId) {
          chatGPTCreateConversation(
            state.gptEngine,
            promptText,
            { stream: streamMode, signal: abortControllerPost.signal },
            project
          )
            .then((responseNewDiscuss) => {
              dispatch({
                type: 'DISCUSSION_INIT_STATE',
                payload: responseNewDiscuss,
              });
              chatGPTContinueConversation(
                state.gptEngine,
                responseNewDiscuss.id,
                promptText,
                {
                  stream: streamMode,
                  token: appJWT.access_token,
                  signal: abortController.signal,
                },
                project
              )
                .then(async (response) => {
                  if (response?.body && streamMode) {
                    if (response.status !== 200) {
                      dispatch({
                        type: 'SET_ERROR',
                        payload: 'An error occurred while streaming',
                      });
                      return;
                    }
                    await parseResponseGpt3(response, dispatch);
                  } else {
                    dispatch({
                      type: 'SUBMIT_PROMPT',
                      payload: response,
                    });
                  }
                })
                .catch((e) => {
                  if (abortController.signal.aborted) return;
                  dispatch({
                    type: 'SET_ERROR',
                    payload: e.message,
                  });
                })
                .finally(() => {
                  dispatch({
                    type: 'LOADING_PROMPT',
                    payload: false,
                  });
                  resetPrompt();
                });
            })
            .catch((e) => {
              if (abortController.signal.aborted) return;
              dispatch({
                type: 'SET_ERROR',
                payload: e,
              });
              dispatch({
                type: 'LOADING_PROMPT',
                payload: false,
              });
              resetPrompt();
            });
        } else {
          // old discussion
          chatGPTContinueConversation(
            state.gptEngine,
            state.discussionId,
            promptText,
            {
              stream: streamMode,
              token: appJWT.access_token,
              signal: abortController.signal,
            },
            project
          )
            .then(async (response) => {
              if (response?.body && streamMode) {
                if (response.status !== 200) {
                  dispatch({
                    type: 'SET_ERROR',
                    payload: 'An error occurred while streaming',
                  });
                  dispatch({
                    type: 'LOADING_PROMPT',
                    payload: false,
                  });
                  resetPrompt();
                  return;
                }
                await parseResponseGpt3(response, dispatch);
              } else {
                dispatch({
                  type: 'SUBMIT_PROMPT',
                  payload: response,
                });
              }
            })
            .catch((e) => {
              if (abortController.signal.aborted) return;
              dispatch({
                type: 'SET_ERROR',
                payload: e.message,
              });
            })
            .finally(() => {
              dispatch({
                type: 'LOADING_PROMPT',
                payload: false,
              });
              resetPrompt();
            });
        }
      } else {
        const streamMode = true;
        // new discussion private
        if (!state.discussion?.id) {
          chatGPTCreateConversation(
            state.gptEngine,
            promptText,
            {
              stream: streamMode,
              signal: abortControllerPost.signal,
            },
            project
            // buildSixLayer
          )
            .then((responseNewDiscuss) => {
              dispatch({
                type: 'DISCUSSION_INIT_STATE',
                payload: responseNewDiscuss,
              });
              // common api for rex and private gpt
              chatGPTContinueConversation(
                state.gptEngine,
                responseNewDiscuss.id,
                promptText,
                {
                  stream: streamMode,
                  token: appJWT.access_token,
                  signal: abortController.signal,
                  filters: sixlayerFilter,
                },
                project
              )
                .then(async (response) => {
                  if (response?.body && streamMode) {
                    // await parseResponseGpt4Private(response, dispatch);
                    await parseResponseAzureOpenAI(response, dispatch);
                    dispatch({
                      type: 'LOADING_PROMPT',
                      payload: false,
                    });
                  } else {
                    dispatch({
                      type: 'SUBMIT_PROMPT',
                      payload: response,
                    });
                  }
                })
                .catch((e) => {
                  if (abortController.signal.aborted) return;
                  dispatch({
                    type: 'SET_ERROR',
                    payload: e.message,
                  });
                })
                .finally(() => {
                  dispatch({
                    type: 'LOADING_PROMPT',
                    payload: false,
                  });
                  resetPrompt();
                });
            })
            .catch((e) => {
              dispatch({
                type: 'SET_ERROR',
                payload: e,
              });
              dispatch({
                type: 'LOADING_PROMPT',
                payload: false,
              });
              resetPrompt();
            });
        } else {
          chatGPTContinueConversation(
            state.gptEngine,
            state.discussionId,
            promptText,
            {
              stream: streamMode,
              token: appJWT.access_token,
              signal: abortController.signal,
              filters: sixlayerFilter,
            },
            project
          )
            .then(async (response) => {
              if (response?.body && streamMode) {
                // await parseResponseGpt4Private(response, dispatch);
                await parseResponseAzureOpenAI(response, dispatch);
                dispatch({
                  type: 'LOADING_PROMPT',
                  payload: false,
                });
              } else {
                dispatch({
                  type: 'SUBMIT_PROMPT',
                  payload: response,
                });
              }
            })
            .catch((e) => {
              if (abortController.signal.aborted) return;
              dispatch({
                type: 'SET_ERROR',
                payload: e.message,
              });
            })
            .finally(() => {
              dispatch({
                type: 'LOADING_PROMPT',
                payload: false,
              });
              resetPrompt();
            });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSubmitPrompt = useCallback(() => _handleSubmitPrompt(), [
    state.discussionId,
    state.gptEngine,
  ]);

  useEffect(() => {
    if (init) {
      document.getElementById('chat-textbox').value = init;
      setTimeout(() => {
        handleSubmitPrompt();
      }, 200);
    }
  }, [init]);

  // Function to format the values of passedDataPrivateInt keys
  const formatPassedDataPrivateIntValues = () => {
    if (passedDataPrivateInt && typeof passedDataPrivateInt === 'object') {
      return Object.entries(passedDataPrivateInt)
        .filter(([key, value]) => value !== null) // Filter out null values
        .map(([key, value]) => (
          <span key={key}>
            <strong style={{ color: '#133a53' }}>{key}:</strong>{' '}
            <span title={value}>{value.join(',')}</span>
            <br />
          </span>
        ));
    }
    return '';
  };

  return (
    <>
      <Paper
        style={{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          paddingLeft: 30,
          background: '#FFFFFF',
          paddingRight: 30,
          paddingBottom: 5,
          boxShadow: 'None',
        }}
      >
        <Grid container style={{ width: '100%' }}>
          <Grid
            item
            xs={12}
            style={{
              // border: '1px solid #002A45',
              boxShadow: '-1px 1px 8px 7px rgba(234,238,240,1)',
              backgroundColor: state.loadingPrompt ? 'lightgrey' : '',
              // borderRadius: 10,
              padding: 2,
              borderRadius: 23,
              marginBottom: 5,
              backgroundColor: '#ffffff',
              // background: '#F8F8F8',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              paddingLeft: '18px',
              // backgroundColor: 'red',
            }}
          >
            <TextField
              disabled={state.loadingPrompt}
              multiline
              type="text"
              name="textbox"
              id="chat-textbox"
              style={{
                fontSize: '17px',
                padding: '2px',
                fontWeight: 'bold',
                backgroundColor: '#ffffff',
                width: '100%',
                border: 'none',
                borderRadius: '10px',
                borderBottom: 'none',
                maxHeight: '300px', // Limiting the maximum height
                overflowY: 'auto', // Enable vertical scrolling
              }}
              InputProps={{ disableUnderline: true }}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey && !e.ctrlKey && !e.altKey && !e.metaKey) {
                  handleSubmitPrompt();
                }
              }}
              placeholder="Send a message"
            />
            &nbsp;
            <Box>
              <IconButton
                onClick={handleSubmitPrompt}
                style={{
                  backgroundColor: '#002a45',
                  color: 'white',
                  padding: 10,
                }}
                size="small"
                disabled={state.loadingPrompt}
              >
                {state.loadingPrompt ? (
                  <CircularProgress color="secondary" size={20} />
                ) : (
                  <SendIcon />
                )}
              </IconButton>
            </Box>
          </Grid>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <div>
              <Typography style={{ color: '#B6B6B6', fontSize: '10px', opacity: '100%' }}>
                Shift+enter for new line
              </Typography>
            </div>
          </div>
          {state.errorMsg && state.errorMsg !== '' && (
            <AlertSnack
              msg={state.errorMsg}
              handleClose={handleCloseSnack}
              severity="error"
              duration={3000}
            />
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            {formatPassedDataPrivateIntValues().length > 0 && state.gptEngine === 'private' && (
              <Grid
                container
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  // marginTop: state?.discussion?.id === undefined ? 240 : 5,
                  // marginLeft: 5,
                  border: '1px solid #d7dee7',
                  padding: 5,
                  borderRadius: 10,
                  backgroundColor: '#eef1f9',
                  marginBottom: '25px',
                  overflowY: 'scroll',
                  maxHeight: 55,
                }}
              >
                <Typography
                  style={{
                    maxWidth: '100%',
                    fontSize: 12,
                    wordWrap: 'break-word',
                  }}
                >
                  {formatPassedDataPrivateIntValues()}
                </Typography>
              </Grid>
            )}
          </div>
        </Grid>
      </Paper>
    </>
  );
};

export default memo(InputPrompt);
