import { AnalysisData } from 'OwningType';
import axios from './axios';
import { BACKEND_URL } from '../const';

// eslint-disable-next-line import/prefer-default-export
export const get_workbench = async (): Promise<any[]> => {
  try {
    const response = await axios.get(`${BACKEND_URL.root}/allocator_workbench/workbench_config`);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

export const getAllTemplate = async (id: any): Promise<any[]> => {
  try {
    const response = await axios.get(`${BACKEND_URL.root}/allocator_workbench/template/${id}`);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

// allocator_workbench/list_allfiles
export const getAllFiles = async (): Promise<any[]> => {
  try {
    const response = await axios.get(`${BACKEND_URL.root}/allocator_workbench/list_allfiles`);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

export const getTemplatTableData = async (id): Promise<any[]> => {
  try {
    const response = await axios.get(
      `${BACKEND_URL.root}/allocator_workbench/${id}/template/results`
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

export const put_label_Table = async (body): Promise<any[]> => {
  try {
    const response = await axios.put(`${BACKEND_URL.root}/allocator_workbench/save/labels`, body);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

export const put_training_Table = async (body): Promise<any[]> => {
  try {
    const response = await axios.put(`${BACKEND_URL.root}/allocator_workbench/save/training`, body);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

// delete_label_Table
export const delete_label_Table = async (body): Promise<any[]> => {
  try {
    const response = await axios.delete(`${BACKEND_URL.root}/allocator_workbench/delete/labels`, {
      data: body,
    });
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

export const delete_training_Table = async (body): Promise<any[]> => {
  try {
    const response = await axios.delete(`${BACKEND_URL.root}/allocator_workbench/delete/training`, {
      data: body,
    });
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

export const post_workbench = async (body): Promise<any[]> => {
  try {
    const response = await axios.post(
      `${BACKEND_URL.root}/allocator_workbench/workbench_config`,
      body
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

export const post_icapture_createTemplate = async (body): Promise<any[]> => {
  try {
    const response = await axios.post(`${BACKEND_URL.root}/allocator_workbench/process`, body);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

// eslint-disable-next-line consistent-return
export const uploadURLCall = async (uploadUrl: any, formdata: FormData) => {
  try {
    const uplResponse = await axios.post(
      `${BACKEND_URL.root}/allocator_workbench/file_upload`,
      formdata,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return uplResponse.data;
    // setFiles(allResources);
  } catch (error) {
    console.log(error);
  }
};

// template process change URL here
// eslint-disable-next-line consistent-return
export const uploadURLCall_process = async (uploadUrl: any, formdata: FormData) => {
  try {
    const uplResponse = await axios.post(
      `${BACKEND_URL.root}/allocator_workbench/allocator/files/upload`,
      formdata,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return uplResponse.data;
    // setFiles(allResources);
  } catch (error) {
    console.log(error);
  }
};

export const post_icapture_allocator_workbench_allocation = async (body): Promise<any[]> => {
  try {
    const response = await axios.post(
      `${BACKEND_URL.root}/allocator_workbench/allocation/process`,
      body
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

// allocator_workbench/allocation/activity

// eslint-disable-next-line import/prefer-default-export
export const get_allocationActivity = async (): Promise<any[]> => {
  try {
    const response = await axios.get(`${BACKEND_URL.root}/allocator_workbench/allocation/activity`);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

// eslint-disable-next-line import/prefer-default-export
export const get_allocationActivity_byId = async (id): Promise<any[]> => {
  try {
    const response = await axios.get(`${BACKEND_URL.root}/allocator_workbench/results/${id}`);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

// https://isens-middleware.20.31.20.157.sslip.io/download/samplefile/template/allocation
// https://isens-middleware.20.31.20.157.sslip.io/download/samplefile/allocator/allocation

export const get_template_file = async (): Promise<any[]> => {
  try {
    const response = await axios.get(`${BACKEND_URL.root}/download/samplefile/template/allocation`);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

export const get_allocator_file = async (): Promise<any[]> => {
  try {
    const response = await axios.get(
      `${BACKEND_URL.root}/download/samplefile/allocator/allocation`
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};
