import React, { useEffect, useState, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
} from '@material-ui/core';
import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import { makeStyles } from '@material-ui/styles';
import {
  Grid as ExpressGrid,
  PagingPanel,
  Table,
  TableFilterRow,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import { Workload3Create } from 'WorkloadType';
import MultiAllocTableComp from '../../../components/Datagrid/MultiAllocTableComp';
import TStyles, {
  rowComponent,
  tableAllocatorComponent,
  tableHeaderComponent,
  toolbarComponent,
  tUseStyles,
} from '../../../components/Datagrid/TStyles';
import { fileDetails, iCaptureCsv, iCaptureExcel, iCapturePdf } from '../../../api/module1';
// import ProjectContext from '../../../Project/ProjectContext';
import formatBytes from '../../../tools/formatBytes';
import UploadFile from '../../../components/Core/UploadFile';
import ExcelDoc from '../../../assets/icons8-excel-48.png';
import PdfDoc from '../../../assets/pdf_ins.png';
import PptDoc from '../../../assets/icons8-powerpoint-48.png';
import DocxDoc from '../../../assets/icons8-microsoft-word-2019-48.png';
import docIcon from '../../../assets/docx_ico.png';
import pdfIcon from '../../../assets/pdf_ico.png';
import scopeIcon from '../../../assets/scopeSet.png';
import xlsIcon from '../../../assets/xlsx_ico.png';
import CsvDoc from '../../../assets/csv_icons.png';
import Loading from '../../../components/Core/Loading';
// import Module2Context from '../../../Module2Context';
import { getCaptureName } from '../../../api/breakdowns';
import { workloadCreate, workloadRunPost, workloadRunPostREX } from '../../../api/workloads';
import NERParamTarget from '../../../components/NERParamTarget';
import Module2Context from '../../Module2/Module2Context';
import ProjectContext from '../../Project/ProjectContext';
import M2GlobalContext from '../../ContextMager/M2GlobalContext';
import { ENV } from '../../../const';

const useStyles = makeStyles((theme) => ({
  root: {
    // '& .MuiPaper-root': {
    //   backgroundColor: theme.palette.background.paper,
    // },
    // backgroundColor: 'rgb(238, 241, 249)',
  },
  paper: {
    '& .MuiPaper-rounded': {
      background: 'red !important',
    },
  },
  captureHandle: {
    '& .MuiInputLabel-outlined': {
      color: '#002A45 !important',
      fontWeight: 'bold',
    },
  },
  pagingPanelContainer: {
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    // marginTop: '10px',
    // width: '50% !important',
    padding: '0px !important',
    marginRight: '244px !important',
    '& .Pager-pager': {
      padding: 0,
    },
    // Apply other custom styles as needed
    '& .MuiIconButton-label': {
      color: '#001F3C',
      padding: 5,
      backgroundColor: '#FFFFFF',
      borderRadius: '50%',
      boxShadow: '#00000029',
    },
    // '& .MuiButton-label': {
    //   color: '#001F3C',
    //   padding: 2,
    //   backgroundColor: '#FFFFFF',
    //   borderRadius: '50%',
    //   boxShadow: '#00000029',
    // },
    '& .MuiButton-root': {
      '&.MuiButton-textPrimary .Pagination-activeButton': {
        backgroundColor: 'red !important',
        color: 'white !important',
        // Add other custom styles as needed
      },
      color: '#001F3C',
      padding: '0px 5px',
      backgroundColor: 'white',
      // borderRadius: '50%',
      boxShadow: '#00000029',
      // gap: 10,
      margin: 9,
      // '&.Pagination-activeButton': {
      //   background: 'red !important',
      // },
      // '& .Pagination-button': {
      //   '& .Pagination-activeButton': {
      //     background: 'red !important',
      //   },
      // },
    },
  },
}));

const columns: any = [
  { name: 'filename', title: 'File Name' },
  { name: 'size', title: 'Size' },
  { name: 'folderName', title: 'Folder' },
  { name: 'date', title: 'Date upload' },
  { name: 'userName', title: 'Uploaded by' },
];

const Rexbook = () => {
  const classes = useStyles();
  const history = useHistory();
  const { getProject } = useContext(ProjectContext);
  const { setSnack } = useContext(Module2Context);
  const { state, dispatch } = useContext(M2GlobalContext);
  const { rexFilter } = state;
  const [selection, setSelection] = useState([]);
  const [targetColumnsAre, setTargetColumnsAre] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [pageSizes] = useState([5, 10, 20, 50]);
  const [errorMessage, setErrorMessage] = useState('');
  const [disOP, setDisOP] = useState(false);
  const [loading, setLoading] = useState(false);
  const [display, setDisplay] = useState(false);
  const [dataoutput, setDataoutput] = useState<any>([]);
  const [files, setFiles] = useState<any[]>([]);
  const [row, setRow] = useState([]);
  const { projectId } = useParams() as any;
  const project = getProject(Number(projectId));
  const [inputValue, setInputValue] = useState('');
  const [captureNames, setCaptureNames] = useState<any[]>([]);
  const [sheetName0, setSheetName0] = useState<any>();
  const [targetName0, setTargetName0] = useState<any>();
  const [columnName0, setColumnName0] = useState<any>();
  const [selectedValue, setSelectedValue] = useState('Tender');

  const inputHandler = (event: any) => setInputValue(event.target.value);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  useEffect(() => {
    getCaptureName(projectId).then((resp) => {
      if (resp) {
        setCaptureNames(resp);
      }
    });
    const { folders } = project;
    const allResources = [] as any[];
    if (folders) {
      for (const eachFolder of folders) {
        if (eachFolder.resources && eachFolder.resources.length > 0)
          for (const r of eachFolder.resources) {
            const fileExtension = r.filename.toLowerCase().split('.').pop();
            if (
              fileExtension === 'pdf'
              //   fileExtension === 'docx'
            ) {
              allResources.push({
                ...r,
                size: formatBytes(Number(r.size), 0),
                id: r.etag,
                folderName: eachFolder.name,
                folderId: eachFolder.id,
                isNew: false,
              });
            }
          }
      }
    }
    setRow(allResources.sort((a, b) => new Date(b.date) - new Date(a.date)));
  }, [files]);

  const getFileDetails = (inputFiles: any[]) => {
    setLoading(true);
    setDisplay(false);
    const falseArray: any = [];
    const arrayIs: any = [{ columnName: 'Entity', width: 300 }];
    for (const myFile of inputFiles) {
      falseArray.push(
        fileDetails(row[myFile].filename, row[myFile].filename, row[myFile].container)
      );
      arrayIs.push({
        columnName: row[myFile].filename.split('.').slice(0, -1).join('.'),
        width: 300,
      });
    }

    Promise.all(falseArray)
      .then((responses) => {
        // Get a JSON object from each of the responses
        return Promise.all(
          responses.map((response) => {
            return response;
          })
        );
      })
      .then((data) => {
        const ar: any = [];
        // console.log(data);
        data.map((c: any) => ar.push(c.output));
        setDataoutput(ar);
        // console.log(ar);
        setLoading(false);
        setDisOP(true);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });

    // promise all end
  };

  function handleAnalyze() {
    setErrorMessage('');
    if (dataoutput[0].FileType === 'Excel') {
      if (!sheetName0 || !targetName0) {
        setSnack('Please select both dropdown options', 'error');
        return;
      }
    } else if (dataoutput[0].FileType === 'CSV') {
      if (!columnName0) {
        setSnack('Please select dropdown options', 'error');
        return;
      }
    }
    if (captureNames.includes(inputValue.trim())) {
      setSnack('That activity name is taken.Please try another', 'error');
      return;
    }
    if (selectedValue.trim().length === 0) {
      setSnack('Please enter file type', 'error');
      return;
    }
    if (inputValue.trim().length === 0) {
      setSnack('Please enter activity name', 'error');
      return;
    }
    setLoading(true);
    setDisplay(false);

    let tempdataoutput: any = [];
    tempdataoutput = JSON.parse(JSON.stringify(dataoutput));
    // console.log(tempdataoutput);
    Promise.all([
      fileDetails(
        row[selection[0]].filename,
        row[selection[0]].filename,
        row[selection[0]].container
      ),
    ])
      .then(async (responseAll) => {
        const scopSettingResp = responseAll.map((itemData, i) => {
          if (itemData.output.FileType === 'Excel') {
            return {
              ...itemData.output,
              FilePath: itemData.output.FilePath,
              FileName: itemData.output.FileName,
              Container: itemData.output.Container,
              BlobName: itemData.output.Blob,
              NameOfCapture: inputValue,
              fileName: inputValue,
              target: 'Text',
              fileType: selectedValue,
              Sheet: responseAll[0]?.output?.Sheet_names,
              TargetColumn: responseAll[0]?.output?.targetColumn,
              ColumnNames: responseAll[0]?.output?.columns,
              Header: true,
            };
          }
          if (itemData.output.FileType === 'CSV') {
            return {
              ...itemData.output,
              FilePath: itemData.output.FilePath,
              FileName: itemData.output.FileName,
              Container: itemData.output.Container,
              BlobName: itemData.output.Blob,
              fileName: inputValue,
              fileType: selectedValue,
              target: 'Text',
              NameOfCapture: inputValue,
              TargetColumn: responseAll[0]?.output?.targetColumn,
              ColumnNames: responseAll[0]?.output?.columns,
              Header: true,
            };
            // arrPromises.push(iCaptureCsv(body));
          }
          if (itemData.output?.FileType === 'PDF') {
            return {
              ...itemData.output,
              FilePath: itemData.output.FilePath,
              FileName: itemData.output.FileName,
              Container: itemData.output.Container,
              BlobName: itemData.output.Blob,
              NameOfCapture: inputValue,
              TypesOfCapture: itemData.output?.TypesOfCapture[0],
              target: 'Text',
              fileType: selectedValue,
              Header: true,
              RegId: '',
              fileName: inputValue,
              AutoHeaderFooterDetection: false,
              TableSwitch: false,
              MergeBullet: false,
            };
          }
          return null;
        });

        function openArray(arr) {
          return arr?.map((obj) => obj.value);
        }
        const passedData = {
          // productLine: openArray(rexFilter?.filterrexProductLine) || ['*'],
          // platform: openArray(rexFilter?.filterexPlatform) || ['*'],
          // product: openArray(rexFilter?.filterrexProduct) || ['*'],
          // solution: openArray(rexFilter?.filterrexSolution) || ['*'],
          // project: openArray(rexFilter?.filterrexProject) || ['*'],
          // country: openArray(rexFilter?.filterrexCountry) || ['*'],
          // status: openArray(rexFilter?.filterrexStatus) || ['*'],
          // issuerSite: openArray(rexFilter?.filterissureSite) || ['*'],
          // typology: openArray(rexFilter?.filterTypology) || ['*'],
          // rexSource: openArray(rexFilter?.filterrexSource) || ['*'],

          productLine:
            rexFilter?.productrexLine?.length !== 0 ? openArray(rexFilter?.productrexLine) : ['*'],
          platform:
            rexFilter?.rexPlatform?.length !== 0 ? openArray(rexFilter?.rexPlatform) : ['*'],
          product: rexFilter?.product?.length !== 0 ? openArray(rexFilter?.product) : ['*'],
          solution: rexFilter?.solution?.length !== 0 ? openArray(rexFilter?.solution) : ['*'],
          project: rexFilter?.project?.length !== 0 ? openArray(rexFilter?.project) : ['*'],
          country: rexFilter?.country?.length !== 0 ? openArray(rexFilter?.country) : ['*'],
          status: rexFilter?.rexStatus?.length !== 0 ? openArray(rexFilter?.rexStatus) : ['*'],
          issuerSite:
            rexFilter?.issureSite?.length !== 0 ? openArray(rexFilter?.issureSite) : ['*'],
          typology:
            rexFilter?.rexTypology?.length !== 0 ? openArray(rexFilter?.rexTypology) : ['*'],
          rexSource: rexFilter?.rexSource?.length !== 0 ? openArray(rexFilter?.rexSource) : ['*'],
        };

        const passedDataBlank = {
          productLine: ['*'],
          platform: ['*'],
          product: ['*'],
          solution: ['*'],
          project: ['*'],
          country: ['*'],
          status: ['*'],
          issuerSite: ['*'],
          typology: ['*'],
          rexSource: ['*'],
        };

        // workload here
        workloadCreate({
          project_id: projectId,
          app: 'module2',
          action: 'RexBook',
          resources: [
            {
              settingsModule: { ...scopSettingResp[0] },
              filters: passedData,
            },
          ],
        })
          .then((workloadNew: Workload3Create) => {
            workloadRunPostREX(Number(workloadNew.id), 'RexBook')
              .then(() => {
                window.history.pushState('', '', `/projects/${projectId}`);
                history.push(`/projects/${projectId}`);
              })
              .catch((e) => {
                setErrorMessage(e.detail);
                setSnack('Error while workload post.', 'error');
                setDisplay(false);
                setLoading(false);
              })
              .finally(() => {});
          })
          .catch(() => {
            setSnack('Error while workload create.', 'error');
            setDisplay(false);
            setLoading(false);
          })
          .finally(() => {});
      })
      .catch((error) => {
        console.log(error);
        setSnack('Error while processing the scopeSetting for both files.', 'error');
        setDisplay(false);
        setLoading(false);
      });
  }

  function HandleReg(e: any, indexIs: any) {
    // console.log('dataoutput is: ', dataoutput);
    if (e.target.name === 'sheetname') {
      const state = [...targetColumnsAre];
      state[indexIs] =
        dataoutput[indexIs].Sheets[dataoutput[indexIs].Sheet_names.indexOf(e.target.value)];
      setTargetColumnsAre(state);
      Object.assign(dataoutput[indexIs], { SheetName: e.target.value });
      // console.log('F:', dataoutput);
      if (indexIs === 0) {
        setSheetName0(e.target.value);
      }
    }
    if (e.target.name === 'targetColumn') {
      Object.assign(dataoutput[indexIs], { TargetColumn: e.target.value });
      if (indexIs === 0) {
        setTargetName0(e.target.value);
      }
    }
    if (e.target.name === 'columnname') {
      Object.assign(dataoutput[indexIs], { TargetColumn: e.target.value });
      if (indexIs === 0) {
        setColumnName0(e.target.value);
      }
    }
  }

  const handleSelectionChange = (selectedKeys: any) => {
    const ArrayFiles = selectedKeys;
    setSelection(selectedKeys);
    if (ArrayFiles.length > 1) {
      setSnack('more than 1 files not allowed!', 'error');
    } else {
      getFileDetails(ArrayFiles);
    }
  };

  const renderFileName = ({ row }: any) => {
    const ext = row.filename.split('.');
    const extensions = {
      xlsx: ExcelDoc,
      csv: CsvDoc,
      pdf: PdfDoc,
      pptx: PptDoc,
      docx: DocxDoc,
    };
    return (
      <div
        style={{
          position: 'relative',
          padding: '0.3rem',
          width: 'max-content',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: '0.2rem' }}>
          <img src={extensions[ext[ext.length - 1]]} height="30" width="30" alt="img" />
          <Typography>{row.filename}</Typography>
        </div>
        {row?.isNew && (
          <div
            style={{
              width: 'max-content',
              padding: '0.2rem',
              borderRadius: '10px',
              backgroundColor: '#19AA6E',
              color: 'white',
              position: 'absolute',
              top: '-12px',
              right: '-35px',
            }}
          >
            <Typography style={{ fontSize: '0.85em' }}>New !</Typography>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <UploadFile files={files} setFiles={setFiles} projectId={projectId} />
      </div>
      <Box style={{ backgroundColor: '#EEF1F9', border: '1px solid #002A45' }}>
        <ExpressGrid rows={row} columns={columns}>
          <DataTypeProvider for={['filename']} formatterComponent={renderFileName} />
          <SelectionState selection={selection} onSelectionChange={handleSelectionChange} />
          <FilteringState />
          <IntegratedFiltering />
          <SortingState />
          <IntegratedSorting />
          <PagingState
            currentPage={currentPage}
            onCurrentPageChange={setCurrentPage}
            pageSize={pageSize}
            onPageSizeChange={setPageSize}
          />
          <IntegratedPaging />
          <VirtualTable />
          <Table
            tableComponent={MultiAllocTableComp}
            containerComponent={tableAllocatorComponent}
            headComponent={tableHeaderComponent}
          />
          <TableHeaderRow showSortingControls />
          <TableFilterRow rowComponent={rowComponent} />
          {/* <PagingPanel pageSizes={pageSizes} /> */}
          <PagingPanel
            pageSizes={pageSizes}
            containerComponent={(props) => (
              <>
                <PagingPanel.Container {...props} className={classes.pagingPanelContainer} />
              </>
            )}
          />
          <TableSelection />
        </ExpressGrid>
      </Box>
      {loading && <Loading />}
      {dataoutput.length > 0 && (
        <Paper>
          <>
            <div
              style={{
                border: 'solid black',
                backgroundColor: 'rgb(255, 255, 255)',
                borderWidth: '0.1px',
              }}
            >
              <Grid container>
                <div
                  style={{
                    float: 'left',
                    display: 'flex',
                    gap: '5px',
                    alignItems: 'center',
                    margin: '5px',
                  }}
                >
                  <img src={scopeIcon} alt="isens" style={{ width: 40, height: 40 }} />
                  <h1>Scope Settings</h1>
                </div>
              </Grid>
              <Grid spacing={0}>
                <Grid container>
                  {dataoutput.map((c, indexIs) => (
                    <Grid container spacing={1} style={{ padding: '10px' }} key={indexIs}>
                      <Grid item xs={3}>
                        <FormControl variant="outlined">
                          <span
                            style={{
                              display: 'flex',
                              gap: '5px',
                              alignItems: 'center',
                              height: '100%',
                            }}
                          >
                            {c?.FileType === 'unsupported' ? (
                              <img
                                src={docIcon}
                                alt="isens"
                                style={{
                                  width: 30,
                                  height: 30,
                                  transform: 'translateY(8px)',
                                }}
                              />
                            ) : (
                              ''
                            )}
                            {c?.FileType === 'Excel' || c?.FileType === 'CSV' ? (
                              <img
                                src={xlsIcon}
                                alt="isens"
                                style={{
                                  width: 30,
                                  height: 30,
                                  transform: 'translateY(8px)',
                                }}
                              />
                            ) : (
                              ''
                            )}
                            {c?.FileType === 'PDF' ? (
                              <img
                                src={pdfIcon}
                                alt="isens"
                                style={{
                                  width: 30,
                                  height: 30,
                                  transform: 'translateY(8px)',
                                }}
                              />
                            ) : (
                              ''
                            )}
                            &nbsp;
                            <Tooltip title={c?.Blob} placement="top" arrow>
                              <b
                                style={{
                                  color: 'blue',
                                  wordWrap: 'break-word',
                                  // marginTop: '10px',
                                }}
                              >
                                {c?.Blob.substring(0, 25)}
                              </b>
                            </Tooltip>
                          </span>
                          {c?.FileType !== 'unsupported' ? (
                            <span>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <span style={{ color: 'red' }}>{c?.Error}</span>
                            </span>
                          ) : (
                            ''
                          )}
                        </FormControl>
                      </Grid>
                      {c?.FileType === 'Excel' ? (
                        <>
                          <Grid item xs={3}>
                            <FormControl variant="outlined" size="small">
                              <InputLabel htmlFor="outlined-age-native-simple">
                                Sheet Column
                              </InputLabel>
                              <Select
                                native
                                label="Sheet  Name"
                                // name={'sheetname'.concat(indexIs)}
                                name="sheetname"
                                style={{ width: 200 }}
                                onChange={(e) => HandleReg(e, indexIs)}
                                defaultValue=""
                              >
                                <option aria-label="None" value="" disabled />
                                {c?.Sheet_names !== null
                                  ? c?.Sheet_names.map((cap: string, index: number) => (
                                      <option key={`capture-type-${index}`} value={cap}>
                                        &nbsp;&nbsp;{cap}
                                      </option>
                                    ))
                                  : ''}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={3} style={{ marginLeft: '40px' }}>
                            <FormControl variant="outlined" size="small">
                              <InputLabel htmlFor="outlined-age-native-simple">
                                Target Column
                              </InputLabel>
                              <Select
                                native
                                label="Target  Column"
                                name="targetColumn"
                                style={{ width: 200 }}
                                onChange={(e) => HandleReg(e, indexIs)}
                                defaultValue=""
                              >
                                <option aria-label="None" value="" disabled />
                                <NERParamTarget
                                  key={`NERParamTarget-${indexIs}`}
                                  data={targetColumnsAre[indexIs]}
                                />
                              </Select>
                            </FormControl>
                          </Grid>
                        </>
                      ) : (
                        ''
                      )}
                      {c?.FileType === 'CSV' ? (
                        <>
                          <Grid item xs={3}>
                            <FormControl variant="outlined" size="small">
                              <InputLabel htmlFor="outlined-age-native-simple">
                                Column Name
                              </InputLabel>
                              <Select
                                native
                                label="Column Name"
                                name="columnname"
                                style={{ minWidth: 450 }}
                                onChange={(e) => HandleReg(e, indexIs)}
                                defaultValue=""
                              >
                                <option aria-label="None" value="" disabled />
                                {c?.ColumnNames !== null
                                  ? c?.ColumnNames.map((cap: string, index: number) => (
                                      <option key={`capture-type-${index}`} value={cap}>
                                        &nbsp;&nbsp;{cap}
                                      </option>
                                    ))
                                  : ''}
                              </Select>
                            </FormControl>
                          </Grid>
                        </>
                      ) : (
                        ''
                      )}
                    </Grid>
                  ))}
                  <div
                    style={{
                      borderTop: 'solid rgba(0, 0, 0, 0.87)',
                      width: '100%',
                      backgroundColor: 'rgb(238, 241, 249)',
                      borderWidth: '0.5px',
                    }}
                  >
                    <Grid item xs={12}>
                      <div style={{ float: 'right', marginLeft: 0 }}>
                        {/* <FormControl component="fieldset">
                          <FormLabel component="legend">Choose an option:</FormLabel>
                          <RadioGroup
                            aria-label="options"
                            name="options"
                            value={selectedValue}
                            onChange={handleChange}
                          >
                            <div style={{ display: 'flex' }}>
                              <FormControlLabel value="Tender" control={<Radio />} label="Tender" />
                              <FormControlLabel
                                value="Project"
                                control={<Radio />}
                                label="Project"
                              />
                            </div>
                          </RadioGroup>
                        </FormControl> */}
                        <TextField
                          id="outlined-basic"
                          size="small"
                          label="Enter Activity Name"
                          variant="outlined"
                          name="captureName"
                          onChange={inputHandler}
                          style={{
                            width: '170px',
                            height: '38px',
                            marginTop: 6,
                            marginRight: 10,
                            marginBottom: 6,
                            backgroundColor: 'white',
                          }}
                        />
                        &nbsp;&nbsp;
                        <Button
                          type="button"
                          disabled={selection.length < 1 || selection.length > 5}
                          variant="contained"
                          color="primary"
                          startIcon={
                            loading && <CircularProgress style={{ color: 'white' }} size={20} />
                          }
                          onClick={handleAnalyze}
                          style={{
                            width: '150px',
                            height: '38px',
                            marginTop: 6,
                            marginRight: 10,
                            marginBottom: 6,
                          }}
                        >
                          Analyze
                        </Button>
                      </div>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </div>
          </>
        </Paper>
      )}
      {/* <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10 }}>
        <Button
          type="button"
          variant="contained"
          color="primary"
          startIcon={loading && <CircularProgress style={{ color: 'white' }} size={20} />}
          // onClick={handleAnalyze}
          //   style={{
          //     // width: '150px',
          //     // height: '38px',
          //     // flex: 1,
          //     marginTop: 6,
          //     marginBottom: 6,
          //   }}
        >
          REX Book Analyze
        </Button>
      </div> */}
    </>
  );
};

export default Rexbook;
