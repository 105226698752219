import { AuthenticationResult } from '@azure/msal-browser';
import { Button, Grid, Paper, ThemeProvider, Typography, Tooltip } from '@material-ui/core';
import ErrorIcon from '@mui/icons-material/Error';
import { styled } from '@mui/styles';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { AuthenticationAlstom } from 'Auth';
import React, { useEffect, useState } from 'react';
import msalInstance, { scopes } from '../../api/auth';
import { registerUserWithAzToken } from '../../api/users';
import { isensTheme, useStyles } from '../../app/isensTheme';
import useAppToken from '../../app/useAppToken';
import useAzureToken from '../../app/useAzureToken';
import Alstom_logo from '../../assets/images/Alstom-new-logo.jpg';
import image3_background from '../../assets/images/welocomeImage.png';
import login_icon from '../../assets/images/login_icon.jpg';
import AppTopBarBlank from '../../components/Layout/AppTopBarBlank';
import { getVersion } from '../../api/public';

type UserStateType = 'newuser' | 'errorconnection' | 'user' | 'dberror';

const AlstomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: 12,
    border: '1px solid #dadde9',
  },
}));

export default function LoginPage() {
  const { setJWT } = useAzureToken();
  const { setAppJWT } = useAppToken();
  const classes = useStyles();
  const [userState, setUserState] = useState<UserStateType>('user');
  const [errorForUser, setErrorForUser] = useState<any>('');

  // eslint-disable-next-line no-console
  console.info('Isens App version =', process.env.REACT_APP_VERSION);

  useEffect(() => {
    getVersion().catch((err) => {
      if (err.message === 'Error: Connection error') {
        // localStorage.clear();
        // sessionStorage.clear();
        setUserState('errorconnection');
      }
    });
  }, []);

  const handleLogin = async () => {
    // clear all storage
    // localStorage.clear();
    // sessionStorage.clear();

    const request = {
      scopes,
    };

    try {
      // abckjsdksdsdasd('hi');
      const loginResponse: AuthenticationResult &
        AuthenticationAlstom = (await msalInstance.loginPopup(request)) as AuthenticationResult &
        AuthenticationAlstom;

      if (loginResponse) {
        const resp = await registerUserWithAzToken({
          name: loginResponse.account?.name,
          email: loginResponse.account?.username,
          details: {
            site_code: loginResponse.idTokenClaims['site code'] as string,
            job_function: loginResponse.idTokenClaims.jobTitle,
            department: loginResponse.idTokenClaims.Department,
          },
        });
        // user need to be activated
        if (resp.is_new_user === true) {
          setUserState('newuser');
        } else {
          // create "token" put all data from SSO
          setJWT(loginResponse);
          // brearer token for axios
          setAppJWT(resp);

          window.location = '/' as any;
        }
      }
    } catch (err) {
      // localStorage.clear();
      sessionStorage.clear();
      setUserState('dberror');
      setErrorForUser(String(err).replace('\n', ''));
      // console.log('dberror');
    }
  };

  return (
    <>
      {/* <AppTopBarBlank /> */}
      <ThemeProvider theme={isensTheme}>
        <div
          className={classes.root}
          style={{
            // height: 'auto',
            height: '100vh',
          }}
        >
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{
              width: '100%',
              background: `url(${image3_background})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              position: 'relative',
            }}
          >
            <div>
              <div>
                <center>
                  {userState === 'dberror' && (
                    <>
                      <Grid container justify="center">
                        <Typography
                          variant="h5"
                          style={{
                            backgroundColor: 'white',
                            color: 'red',
                            padding: '10px',
                            borderRadius: '10px',
                            position: 'absolute',
                            top: '80px',
                          }}
                        >
                          We are currently experiencing high traffic,please try again in a few
                          moments.
                        </Typography>
                      </Grid>
                    </>
                  )}
                  <Paper
                    style={{
                      // margin: '15rem 33rem 15rem 33rem',
                      width: '280px',
                      // boxShadow:
                      //   '0px 3px 1px -2px #00000070,0px 2px 2px 0px rgba(100,0,0,0.9),0px 1px 5px 0px rgba(30,50,70,0.12)',
                      height: '195px',
                      borderRadius: '20px',
                      // position: 'relative',
                    }}
                  >
                    <Grid container justify="center">
                      <Grid
                        item
                        style={{
                          textAlign: 'center',
                          padding: '15px 20px',
                          minWidth: '200px',
                          borderRadius: '20px',
                          marginTop: '20px',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                        }}
                      >
                        <img src={Alstom_logo} alt="alstomLogo" style={{ width: '70%' }} />
                        <br />

                        <hr
                          style={{
                            width: '100%',
                            height: '2px',
                            backgroundColor: '#d6d6d6',
                            border: 'none',
                          }}
                        />
                        <br />
                        <Grid container spacing={10}>
                          {userState === 'newuser' && (
                            <Grid item xs={12} style={{ marginLeft: '20px' }} alignContent="center">
                              <h3>Please contact iSenS support team to activate your account.</h3>
                            </Grid>
                          )}
                          {/* {userState === 'dberror' && (
                            <>
                              <Grid
                                item
                                xs={12}
                                style={{ marginLeft: '20px' }}
                                alignContent="center"
                              >
                                <h3>
                                  Please contact iSenS support team.
                                  <AlstomTooltip title={errorForUser}>
                                    <ErrorIcon
                                      style={{ marginLeft: '200px', marginTop: '-20px' }}
                                    />
                                  </AlstomTooltip>
                                </h3>
                              </Grid>
                            </>
                          )} */}
                          {userState === 'errorconnection' && (
                            <Grid item xs={12} style={{ marginLeft: '20px' }} alignContent="center">
                              <h3>Connection error</h3>
                            </Grid>
                          )}

                          {(userState === 'user' || userState === 'dberror') && (
                            <>
                              <Grid container spacing={18} justify="center" position="relative">
                                <Grid item xs={1} style={{ marginLeft: '20px', marginTop: '30px' }}>
                                  <img
                                    src={login_icon}
                                    alt="loginicon"
                                    style={{
                                      width: '50px',
                                      marginTop: '5px',
                                      marginLeft: 11,
                                      position: 'absolute',
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={9} style={{ marginTop: '25px', width: '100%' }}>
                                  <Button
                                    onClick={handleLogin}
                                    style={{
                                      backgroundColor: 'transparent',
                                      floodColor: 'transparent',
                                      color: 'transparent',
                                      textTransform: 'none',
                                    }}
                                  >
                                    <Typography variant="h5">
                                      <span style={{ color: 'black', textTransform: 'none' }}>
                                        Login with your
                                      </span>{' '}
                                      <br />
                                      <span style={{ color: 'red' }}>Alstom</span>{' '}
                                      <span style={{ color: 'black' }}>account</span>
                                    </Typography>
                                  </Button>{' '}
                                </Grid>
                              </Grid>
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                </center>
              </div>
            </div>
          </Grid>
          <p
            style={{
              color: 'white',
              position: 'absolute',
              top: '95%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              fontWeight: 'bold',
            }}
          >
            Copyright © Alstom SA 2024
          </p>
        </div>
      </ThemeProvider>
    </>
  );
}
