import {
  Column,
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSelection,
  IntegratedSorting,
  PagingState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  Grid as ExpressGrid,
  PagingPanel,
  Table,
  TableFilterRow,
  TableHeaderRow,
  TableSelection,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { Grid, makeStyles } from '@material-ui/core';
import { Autocomplete, MenuItem, Select, TextField } from '@mui/material';
import { GroupUser, TransfertItem } from 'GroupType';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import ReactJson from 'react-json-view';
import { useParams } from 'react-router-dom';
import { createProjectGroupUsers, getProjectGroupsUsers } from '../../../../api/projects';
import useAppToken from '../../../../app/useAppToken';
import useProjectId from '../../../../app/useProjectId';
import Loading from '../../../../components/Core/Loading';
import TableComponent from '../../../../components/Datagrid/TableComponent';
import ToolBarPlugin from '../../../../components/Datagrid/ToolBarPlugin';
import { IS_DEBUG_MODE } from '../../../../const';
import { OptionItem, Project } from '../../../../react-app-env';
import ProjectGroupsContext from '../../PorjectGroupsContext';
import ProjectContext from '../../ProjectContext';
import {
  rowComponent,
  tableContainerComponent,
  tableHeaderComponent,
  toolbarComponent,
} from '../../../../components/Datagrid/TStyles';
import { getUserByProjectId } from '../../../../api/users';

export const useStyles = makeStyles(() => ({
  root: { flexGrow: 1 },
  inputField: {
    width: '200px',
    fontSize: '0.875rem',
    // marginTop: 2,
    // marginBottom: 2,
  },
  gridPadding: {
    // paddingTop: '-10px',
    // paddingBottom: '-10px',
    marginTop: -20,
    marginBottom: -20,
  },
}));

const convertSlugToItem = (defaultList: TransfertItem[], labelIds: string[]) => {
  return defaultList
    .filter((grp: TransfertItem) => labelIds.includes(grp.value))
    .sort((a, b) => (a.label < b.label ? -1 : 1));
};

let nextRowId = 0;

const ProjectGroupsLeaders = () => {
  const { appJWT } = useAppToken();
  const { setProject, setSnack, projects, projects_lite } = useContext(ProjectContext);
  const {
    allDefaultGroups,
    datatype,
    RBSCustom,
    SBSCustom,
    PBSCustom,
    ABSCustom,
    OBSCustom,
  } = useContext(ProjectGroupsContext);
  const classes = useStyles();
  const { projectId } = useParams() as any;
  // const { projects } = useContext(ProjectContext);
  const { project } = useProjectId(projectId, projects);
  // const { project } = useProjectId(projectId, projects);
  const [_project, _setproject] = useState(project);
  const [selectionRows, setSelectionRows] = useState<any[]>([]);
  const [projectUsers, setProjectUsers] = useState<OptionItem[]>([]);

  const [rows, setRows] = useState<GroupUser[]>([]);
  const [columns, setColumns] = useState<Columns[]>([]);
  const [loading, setLoading] = useState(true);
  const exporterRef = useRef(null);

  const handleExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);
  const handleSaveAs = (filenameSave: string) => (workbook: any) => {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), filenameSave);
    });
  };

  const usersPredicate = (value, filter) => {
    if (filter.value.length < 3) return true;
    // console.log(value);

    const userNames = value.map((row) => row.label) || [];
    // console.log(userNames);
    const i = userNames.findIndex(
      (username) => String(username).search(new RegExp(filter.value, 'gi')) > 0
    );

    return i !== -1;
  };
  const [integratedFilteringColumnExtensions] = useState([
    { columnName: 'users', predicate: usersPredicate },
  ]);

  useEffect(() => {
    if (projects.length > 0) {
      Promise.all([
        getUserByProjectId(Number(projects[0]?.id)),
        getProjectGroupsUsers(Number(projects[0]?.id)),
      ])
        .then((paylaod) => {
          const obj = { groupusers: [], groups: [], users: [] };
          const result = paylaod.map((data, index) => {
            if (data[0]?.groupusers) {
              obj.groupusers = data[0]?.groupusers ?? [];
            }
            if (data[0]?.groups) {
              obj.groups = data[0]?.groups ?? [];
            }
            if (data[0]?.users) {
              obj.users = data[0]?.users ?? [];
            }
            return data;
          });
          if (obj) {
            // console.log(obj?.users);
            const allUserInProject = obj?.users.map((user: any) => {
              return { label: user.user.name, value: user.user.id };
            });
            setProjectUsers(allUserInProject);

            if (obj?.groups && obj?.groups.length !== 0) {
              if (obj?.groupusers) {
                // must add fields users from user_ids
                const groupUsers = obj.groupusers.map((groupuser: GroupUser) => {
                  return {
                    ...groupuser,
                    users: allUserInProject.filter((el: OptionItem) =>
                      groupuser.user_ids.includes(Number(el.value))
                    ),
                  };
                });
                setRows(groupUsers.sort((a, b) => (a.pbs < b.pbs ? -1 : 1)));
              }
            }
            setColumns(
              project?.entity === 'RSC'
                ? [
                    { name: 'pbs', title: 'SBS Customized' },
                    // { name: 'abs', title: 'ABS Customized' },
                    { name: 'obs', title: 'OBS Customized' },
                    { name: 'users', title: 'Performers' },
                  ]
                : [
                    { name: 'pbs', title: 'PBS Customized' },
                    { name: 'abs', title: 'ABS Customized' },
                    { name: 'obs', title: 'OBS Customized' },
                    { name: 'users', title: 'Performers' },
                  ]
            );
            _setproject({ ...projects[0], ...obj });
          }
        })
        .catch((error) => {})
        .finally(() => {
          setLoading(false);
        });
    }
  }, [projects, project, projects_lite]);

  // console.log(_project);

  const handleRoleChange = (e: any, row: any, column: Column) => {
    if (
      (_project?.entity === 'D&IS' || _project?.entity === 'Services') &&
      (column.name === 'pbs' || column.name === 'abs')
    ) {
      let column2 = 'abs';
      if (column.name === 'abs') column2 = 'pbs';
      const pos = rows.findIndex(
        (eachrow) => eachrow[column.name] === e.target.value && eachrow[column2] === row[column2]
      );
      if (pos > -1) {
        setSnack(`This combination of PBS-ABS is already present`, 'warning');
        return;
      }
    }

    if (_project?.entity === 'RSC' && (column.name === 'obs' || column.name === 'pbs')) {
      let column2 = 'obs';
      if (column.name === 'obs') column2 = 'pbs';
      const pos = rows.findIndex(
        (eachrow) => eachrow[column.name] === e.target.value && eachrow[column2] === row[column2]
      );
      if (pos > -1) {
        setSnack(`This combination of SBS-OBS is already present`, 'warning');
        return;
      }
    }

    const rowsEditedNew: [] = rows.map((r) => {
      if (r.id === row.id) {
        return { ...r, [column.name]: e.target.value };
      }
      return r;
    });

    setRows(rowsEditedNew);
  };

  const renderCustomedGroupList = ({
    value,
    row,
    column,
  }: DataTypeProvider.ValueFormatterProps) => {
    if (column.name === 'pbs') {
      switch (_project?.entity) {
        case 'D&IS':
          return (
            <Grid container className={classes.gridPadding}>
              <Grid item xs={6}>
                <Select
                  value={value}
                  onChange={(e) => handleRoleChange(e, row, column)}
                  className={classes.inputField}
                >
                  {convertSlugToItem(allDefaultGroups.PBS, PBSCustom.label_ids).map(({ label }) => {
                    return (
                      <MenuItem
                        key={`key-rbs-${label}`}
                        value={label}
                        style={{ fontSize: '0.875rem' }}
                      >
                        {label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
            </Grid>
          );
        case 'Services':
          return (
            <Grid container className={classes.gridPadding}>
              <Grid item xs={6}>
                <Select
                  value={value}
                  onChange={(e) => handleRoleChange(e, row, column)}
                  className={classes.inputField}
                >
                  {convertSlugToItem(allDefaultGroups.PBS, PBSCustom.label_ids).map(({ label }) => {
                    return (
                      <MenuItem
                        key={`key-rbs-${label}`}
                        value={label}
                        style={{ fontSize: '0.875rem' }}
                      >
                        {label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
            </Grid>
          );

        case 'RSC':
          return (
            <Grid container className={classes.gridPadding}>
              <Grid item xs={6}>
                <Select
                  value={value}
                  onChange={(e) => handleRoleChange(e, row, column)}
                  className={classes.inputField}
                >
                  {convertSlugToItem(allDefaultGroups.SBS, SBSCustom.label_ids).map(({ label }) => {
                    return (
                      <MenuItem
                        key={`key-rbs-${label}`}
                        value={label}
                        style={{ fontSize: '0.875rem' }}
                      >
                        {label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
            </Grid>
          );

        default:
          return null;
      }
    }

    let columnValues = [] as any[];
    switch (column.name) {
      case 'abs':
        columnValues = convertSlugToItem(allDefaultGroups.ABS, ABSCustom.label_ids);
        break;
      case 'obs':
        columnValues = convertSlugToItem(allDefaultGroups.OBS, OBSCustom.label_ids);
        break;
      default:
        break;
    }

    return (
      <Grid container className={classes.gridPadding}>
        <Grid item xs={6}>
          <Select
            value={value}
            onChange={(e) => handleRoleChange(e, row, column)}
            className={classes.inputField}
          >
            {columnValues.map(({ label }) => (
              <MenuItem
                key={`key-${column.name}-${label}`}
                value={label}
                style={{ fontSize: '0.875rem' }}
              >
                {label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
    );
  };

  const handleAddRow = () => {
    // eslint-disable-next-line eqeqeq
    const foundValue: any = _project?.users?.find((obj) => obj.user_id == appJWT.user_id);
    if (foundValue?.role_id === 3) {
      setSnack('You are not allowed as you are a performer', 'warning');
      return;
    }
    const newRows = [...rows];
    nextRowId += 1;
    const newRow = {
      id: nextRowId,
      project_id: projectId,
      pbs: '',
      abs: '',
      obs: '',
      user_ids: [],
    };
    newRows.push(newRow);
    setRows(newRows);
  };

  const handlePluginAddUser = (users: OptionItem[], rowId) => {
    if (users !== null) {
      const rowsEditedNew: [] = rows.map((r) => {
        if (r.id === rowId) {
          return { ...r, users, user_ids: users.map((u) => u.value) };
        }
        return r;
      });
      setRows(rowsEditedNew);
    }
  };

  const handlePluginSave = () => {
    // eslint-disable-next-line eqeqeq
    const foundValue: any = _project?.users?.find((obj) => obj.user_id == appJWT.user_id);
    if (foundValue?.role_id === 3) {
      setSnack('You are not allowed as you are a performer', 'warning');
      return;
    }
    createProjectGroupUsers(rows, projectId)
      .then((resp) => {
        setProject({ ...project, groupusers: resp } as Project);
        setProjectUsers(resp);
        setSnack('Groups saved');
      })
      .catch((error) => {
        setSnack(JSON.stringify(error.detail), 'error');
      });
  };

  const handlePluginRemoveRows = () => {
    // eslint-disable-next-line eqeqeq
    const foundValue: any = _project?.users?.find((obj) => obj.user_id == appJWT.user_id);
    if (foundValue?.role_id === 3) {
      setSnack('You are not allowed as you are a performer', 'warning');
      return;
    }
    const selectedRows = rows.filter((row, index) => !selectionRows.includes(index));
    setRows(selectedRows);
    setSelectionRows([]);
  };

  const renderUserSearch = useCallback(
    ({ value, row }: DataTypeProvider.ValueFormatterProps) => {
      return (
        <Autocomplete
          className={classes.gridPadding}
          multiple
          id="auto-complete-users"
          options={projectUsers}
          getOptionLabel={(option: OptionItem) => option.label}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField {...params} label="Search by user name" margin="normal" variant="standard" />
          )}
          value={value}
          onChange={(event, newValue) => {
            handlePluginAddUser(newValue, row.id);
          }}
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectUsers, rows]
  );
  if (loading) return <Loading />;
  return (
    <div className={classes.root}>
      {IS_DEBUG_MODE && (
        <ReactJson
          src={{
            selectionRows,
            rows,
            columns,
            datatype,
            allDefaultGroups,
            // groupusers: project?.groupusers,
            PBSCustom,
            RBSCustom,
            SBSCustom,
          }}
          collapsed={true}
          theme="monokai"
        />
      )}
      <ExpressGrid rows={rows} columns={columns}>
        <DataTypeProvider
          for={['pbs', 'abs', 'obs', 'sbs']}
          formatterComponent={renderCustomedGroupList}
        />
        <DataTypeProvider for={['users']} formatterComponent={renderUserSearch} />
        <SelectionState selection={selectionRows} onSelectionChange={setSelectionRows} />
        <IntegratedSelection />
        <SortingState />
        <IntegratedSorting />
        {/* <PagingState defaultCurrentPage={0} defaultPageSize={10} />
        <IntegratedPaging /> */}
        <FilteringState />
        <IntegratedFiltering columnExtensions={integratedFilteringColumnExtensions} />
        <Table
          columnExtensions={[{ columnName: 'delete', width: '50px' }]}
          tableComponent={TableComponent}
          // tableComponent={multiTableRowComponent}
          containerComponent={tableContainerComponent}
          headComponent={tableHeaderComponent}
        />
        <TableHeaderRow showSortingControls />
        <TableFilterRow rowComponent={rowComponent} />
        <Toolbar rootComponent={toolbarComponent} />
        {/* selection */}
        <TableSelection showSelectAll />
        <ToolBarPlugin name="Add" onClick={handleAddRow} />
        <ToolBarPlugin name="Delete" onClick={handlePluginRemoveRows} />
        <ToolBarPlugin name="Save" onClick={handlePluginSave} />
        <ToolBarPlugin name="Download" title="Download to excel file" onClick={handleExport} />
        {/* <PagingPanel pageSizes={[10, 20, 50]} /> */}
      </ExpressGrid>
      <GridExporter
        ref={exporterRef}
        rows={rows}
        columns={columns.map((c) => ({ name: c.name, title: c.name }))}
        onSave={handleSaveAs(`projectleaders-${projectId}-${datatype}.xlsx`)}
      />
    </div>
  );
};

export default ProjectGroupsLeaders;
