import React, { SyntheticEvent, useCallback, useContext, useState } from 'react';
import {
  ArrowBack,
  CloseOutlined,
  HighlightOff,
  MessageOutlined,
  SkipNext,
  SkipPrevious,
  Star,
  StarOutline,
  ThumbUp,
  ThumbUpOutlined,
} from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { ITopic } from '../../../interfaces/ITopic';
import ColorBoxes from '../ColorBoxes/ColorBoxes';
// import { useAppContext } from "../../appContextProvider/AppContextProvider";
import useAxiosAuth from '../../../API/axios';
import { IParamDetail } from '../../../interfaces/IParamDetail';
import { HomeContext } from '../../../pages/home/homeContextProvider';
import { apiEndpoints } from '../../../utils/apiEndpoints';
import {
  LIFECYCLE_COLORS_CHIP,
  stageStatus,
  userStatusLifecycle,
  userStatusMapping,
} from '../../../utils/statusLifercycle';
import CustomBackgroundButton from '../CustomBackgroundButton/CustomBackgroundButton';
import TopicCommentDialog from '../TopicComment/TopicCommentDialog';
import { topicTypeLevels, topicUrgencyLevels } from '../TopicCreation/urgencyLevel';
import StatusChangedConfirmation from './StatusChangedConfirmation';
import TopicDeletionDlg from './TopicDeletionDlg';
import TopicSharing from './TopicSharing';
import MuiDatePicker from '../DatePicker/MuiDatePicker';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers';
import EmailIcon from '@mui/icons-material/Email';
import SendIcon from '@mui/icons-material/Send';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AttachmentManager from '../FileAttachment/AttachmentManager';
import CustomStepper from './CustomStepper';

interface TopicProps {
  topic: ITopic;
  user: 'admin' | 'normal';
  topicStatuses: IParamDetail[];
  // replaceOldTopicByUpdatedStatusTopic: (callback: (updatedStatusTopic: ITopic) => void) => void;
  replaceOldTopicByUpdatedTopic: (updatedTopic: ITopic) => void;
  currentUserId: number;
  visibleFilterTopics: ITopic[];
  defaultExpanded?: boolean;
  disabledCollapse?: boolean;
  showBackButton?: boolean;
  handleCloseDlg?: () => void;
  mailToSubjectTemplate: string;
  mailToContentTemplate: string;
  setOpenTopicCreationDlg: (open: boolean) => void;
}

const styleObj = { flexBasis: '50%', padding: '0.3rem', fontSize: '1rem' };

const Topic: React.FC<TopicProps> = ({
  mailToSubjectTemplate,
  mailToContentTemplate,
  topic,
  visibleFilterTopics,
  topicStatuses,
  user,
  replaceOldTopicByUpdatedTopic,
  currentUserId,
  defaultExpanded = false,
  disabledCollapse = false,
  showBackButton = false,
  handleCloseDlg = undefined,
  setOpenTopicCreationDlg,
}) => {
  // const { state } = useAppContext();
  const { allStatus, allStatusAdmin } = useContext<any>(HomeContext);

  console.log('allStatus', allStatus);

  console.log('topic', topic);

  const { AxiosCall } = useAxiosAuth();
  const [expanded, setExpanded] = useState<string | false>(defaultExpanded ? 'panel1' : '');
  const [isOpenTopicCommentDialog, setIsOpenTopicCommentDialog] = useState(false);
  const [isOpenTopicDeletionDlg, setIsOpenTopicDeletionDlg] = useState(false);
  const [activeTopicIdInCommentDialog, setActiveTopicIdInCommentDialog] = useState<
    number | undefined
  >();
  const [openConfirmChangeBackwardDlg, setOpenConfirmChangeBackwardDlg] = useState(false);

  const handleChange = (panel: string) => (_: SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleConfirmChangeStatusBackward = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    setOpenConfirmChangeBackwardDlg(true);
  };

  const changeStatusTo = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.FormEvent<HTMLFormElement>,
    statusId: number,
    changeStatusReason?: string,
    dateDelivery?: any,
    clarification?: any
  ) => {
    e.stopPropagation();
    const payload = {
      url: `${apiEndpoints.API_TOPIC_URL}/${topic.id}?change-status=true`,
      method: 'PATCH',
      data: {
        topicStatus: { id: statusId },
        changeStatusReason,
        ...(dateDelivery && { estimationDeliveredAt: dateDelivery }),
        ...(clarification && { clarification }),
      },
    };
    AxiosCall(payload).then(replaceOldTopicByUpdatedTopic);
  };

  const changeStatusCallback = (e: React.FormEvent<HTMLFormElement>, changeStatusReason?: string) =>
    changeStatusTo(e, (topic.topicStatus.sortOrder || 0) - 1, changeStatusReason);

  const handleConfirmDeletion = () => {
    setIsOpenTopicDeletionDlg(true);
  };

  const toggleVoteTopic = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const payload = {
      url: `${apiEndpoints.API_TOPIC_URL}/${topic.id}/toggle-vote`,
      method: 'PATCH',
    };

    AxiosCall(payload).then(replaceOldTopicByUpdatedTopic);
  };

  const toggleFollowTopic = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const payload = {
      url: `${apiEndpoints.API_TOPIC_URL}/${topic.id}/toggle-follow`,
      method: 'PATCH',
    };
    AxiosCall(payload).then(replaceOldTopicByUpdatedTopic);
  };

  const openTopicCommentDialog = (topicId: number) => {
    const payload = {
      url: `${apiEndpoints.API_TOPIC_URL}/${topicId}`,
      method: 'GET',
    };
    AxiosCall(payload).then((updatedTopic) => {
      replaceOldTopicByUpdatedTopic(updatedTopic);
      setIsOpenTopicCommentDialog(true);
      setActiveTopicIdInCommentDialog(topicId);
    });
  };

  const left = 80;
  const right = 20;

  const [selectedDate, setSelectedDate] = useState('');
  const [open, setOpen] = useState(false);
  const [openTabs, setOpenTabs] = useState('Details');
  const [openNeedClarification, setOpenMessage] = useState(false);
  const [dialogMsg, setDialogMsg] = useState('');
  const [statusSelected, setStatusSelected] = useState<any>();

  const handleOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setOpen(false);
  };

  const handleChangeStatus = useCallback(
    (e: any, c: any) => {
      e.stopPropagation();
      if (c === 'MakeInProgress') {
        if (selectedDate && dayjs(selectedDate).isValid()) {
          changeStatusTo(e, allStatus.find((s: any) => s.label === c)?.id, '', selectedDate);
        } else {
          alert('Select date of delivery');
        }
      } else {
        changeStatusTo(e, allStatus.find((s: any) => s.label === c)?.id);
      }
    },
    [allStatus, changeStatusTo, selectedDate]
  );

  const handleOpenMessage = (e) => {
    e.stopPropagation();
    setOpenMessage(true);
  };
  const handleSendMessage = useCallback(
    (e) => {
      e.stopPropagation();
      if (dialogMsg.length) {
        changeStatusTo(
          e,
          allStatus.find((s: any) => s.label === statusSelected)?.id,
          dialogMsg,
          undefined
        );
        setOpenMessage(false);
      }
    },
    [allStatus, changeStatusTo, dialogMsg, statusSelected]
  );

  console.log('topic', topic.topicStatus.label);

  const handleEdit = () => {
    setOpenTopicCreationDlg({ open: true, draft: true, id: topic.id });
  };

  const CheckModifiedBy = () => {
    try {
      if (topic?.histories?.length === 0) {
        return '--';
      }
      const temp = topic?.histories?.sort((a, b) => b.id - a.id)[0]?.user;
      return `${temp?.firstName} ${temp?.lastName}`;
    } catch (error) {
      console.log('error', error);
      return '';
    }
  };

  const CheckModifiedOn = () => {
    try {
      if (topic?.histories?.length === 0) {
        return '--';
      }
      const temp = topic?.histories?.sort((a, b) => b.id - a.id)[0]?.user;
      return dayjs(temp?.updatedAt).format('MMM D YYYY');
    } catch (error) {
      console.log('error', error);
      return '';
    }
  };

  return (
    <>
      <div className="w-full bg-white">
        <Accordion
          sx={{
            borderRadius: 0,
          }}
          expanded={expanded === 'panel1'}
          onChange={disabledCollapse ? undefined : handleChange('panel1')}
        >
          <AccordionSummary
            sx={{
              borderRadius: 0,
              padding: '0px 8px',
              margin: 0,
              borderBottom: 'solid 1px rgb(229, 231, 235)',
            }}
          >
            <Grid container>
              <Grid item md={user === 'admin' ? 4 : 12} alignItems="center">
                <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: 16,
                      fontWeight: 700,
                    }}
                    minWidth="90ch"
                    maxWidth="90ch"
                  >
                    {topic.title}
                  </Typography>
                </div>
              </Grid>
              {user === 'admin' && (
                <Grid item container md={8} justifyContent="flex-end">
                  <Grid item container md={4} justifyContent="flex-end">
                    <div>
                      <CustomBackgroundButton
                        onClick={(e) => e.stopPropagation()}
                        sx={{
                          textTransform: 'none',
                          cursor: 'auto',
                          width: 200,
                        }}
                        bgColor="#0000ff"
                        disableRipple
                      >
                        {topic.topicStatus.title}
                      </CustomBackgroundButton>
                    </div>
                  </Grid>
                  <Grid item container md={4} justifyContent="flex-end">
                    {!allStatusAdmin[topic.topicStatus.label]
                      ? null
                      : allStatusAdmin[topic.topicStatus.label]
                          .filter((data) => data !== 'Postponed')
                          ?.map((c) => {
                            return (
                              <span key={`b-${c}`}>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center', // Ensures vertical alignment
                                  }}
                                >
                                  <CustomBackgroundButton
                                    bgColor="#19AB6C"
                                    sx={{
                                      textTransform: 'none',
                                      width: 200,
                                      marginBottom: 1,
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      if (c === 'MakeInProgress') {
                                        if (!selectedDate || !dayjs(selectedDate).isValid()) {
                                          alert('Select date of delivery');
                                          return;
                                        }
                                      }
                                      if (
                                        ['NeedClarification', 'Postponed', 'Rejected'].includes(c)
                                      ) {
                                        handleOpenMessage(e);
                                        setStatusSelected(c);
                                        return;
                                      }
                                      handleChangeStatus(e, c);
                                    }}
                                    startIcon={<SkipNext />}
                                  >
                                    {allStatus.find((a) => a.label === c)?.title}
                                  </CustomBackgroundButton>
                                  {(c === 'MakeInProgress' || c === 'BuyInProgress') && (
                                    <>
                                      <CalendarMonthIcon
                                        key={`cal-${c}`}
                                        // className={"mx-5"}
                                        titleAccess={'Date of delivery'}
                                        onClick={handleOpen}
                                      />
                                    </>
                                  )}
                                </Box>
                              </span>
                            );
                          })}
                  </Grid>
                </Grid>
              )}
              <Grid item md={0} textAlign="right">
                {/* <div className="font-[300]">
                  {`${new Date(topic.submittedAt!).toDateString()}`}
                </div>
                {topic.estimationDeliveredAt && (
                  <div className="font-[300]">
                    Preview Available :
                    {` ${new Date(topic.estimationDeliveredAt!).toDateString()}`}
                  </div>
                )} */}
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              borderRadius: 0,
              paddingLeft: 3,
              paddingRight: 3,
            }}
          >
            <Grid item md={12} flexDirection="row">
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Box
                  style={{
                    padding: '0.3rem 1rem',
                    fontSize: '1.2rem',
                    fontWeight: 'bold',
                    borderBottom: openTabs === 'Details' ? '5px solid #DC3223' : 'none',
                    cursor: 'pointer',
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenTabs('Details');
                  }}
                >
                  Details
                </Box>
                <Box
                  style={{
                    padding: '0.3rem 1rem',
                    fontSize: '1.2rem',
                    cursor: 'pointer',
                    fontWeight: 'bold',
                    borderBottom: openTabs === 'Status' ? '5px solid #DC3223' : 'none',
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenTabs('Status');
                  }}
                >
                  Status
                </Box>
              </div>
            </Grid>
            {openTabs === 'Details' ? (
              <>
                {' '}
                <Grid container>
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <div style={{ ...styleObj, flexBasis: `${100 - left}%` }}>
                      <strong>Request_ID :</strong>
                    </div>
                    <div style={{ ...styleObj, flexBasis: `${100 - right}%` }}>
                      {
                        topicTypeLevels.find((e) => e.value === (topic?.typeOfTopic || 'idea'))
                          ?.label
                      }
                      _{topic?.id}
                    </div>
                    <div style={{ ...styleObj, flexBasis: `${100 - left}%` }}>
                      <strong>User Story :</strong>
                    </div>
                    <div style={{ ...styleObj, flexBasis: `${100 - right}%` }}>
                      {topic?.description}
                    </div>
                    <div style={{ ...styleObj, flexBasis: `${100 - left}%` }}>
                      <strong>Cross Domain :</strong>
                    </div>
                    <div style={{ ...styleObj, flexBasis: `${100 - right}%` }}>
                      {topic?.cross_domain}
                    </div>
                    <div style={{ ...styleObj, flexBasis: `${100 - left}%` }}>
                      <strong>Problem statement :</strong>
                    </div>
                    <div style={{ ...styleObj, flexBasis: `${100 - right}%` }}>
                      {topic?.problemStatement}
                    </div>
                    <div style={{ ...styleObj, flexBasis: `${100 - left}%` }}>
                      <strong>Created by :</strong>
                    </div>
                    <div style={{ ...styleObj, flexBasis: `${100 - right}%` }}>
                      {topic?.createdBy?.username}
                    </div>
                    <div style={{ ...styleObj, flexBasis: `${100 - left}%` }}>
                      <strong>Created on :</strong>
                    </div>
                    <div style={{ ...styleObj, flexBasis: `${100 - right}%` }}>
                      {dayjs(topic?.createdAt).format('MMM D YYYY')}
                    </div>{' '}
                    <div style={{ ...styleObj, flexBasis: `${100 - left}%` }}>
                      <strong>Modified by :</strong>
                    </div>
                    <div style={{ ...styleObj, flexBasis: `${100 - right}%` }}>
                      {CheckModifiedBy()}
                    </div>{' '}
                    <div style={{ ...styleObj, flexBasis: `${100 - left}%` }}>
                      <strong>Modified on :</strong>
                    </div>
                    <div style={{ ...styleObj, flexBasis: `${100 - right}%` }}>
                      {CheckModifiedOn()}
                    </div>{' '}
                    {/* <div style={{ ...styleObj, flexBasis: `${100 - left}%` }}>
                      <strong>Type :</strong>
                    </div>
                    <div style={{ ...styleObj, flexBasis: `${100 - right}%` }}>
                      {
                        topicTypeLevels.find((e) => e.value === (topic?.typeOfTopic || 'idea'))
                          ?.label
                      }
                    </div> */}
                  </div>
                  {topic.estimationDeliveredAt && (
                    <>
                      <Grid item md={2}>
                        <strong>Preview Available</strong>
                      </Grid>
                      <Grid item md={10}>
                        : {dayjs(topic?.estimationDeliveredAt).format('MMM D YYYY')}
                      </Grid>
                    </>
                  )}
                  <Grid
                    item
                    container
                    md={12}
                    style={{ borderTop: '1px solid', paddingTop: '1rem', marginTop: '1rem' }}
                    alignItems="center"
                  >
                    {topic.filename && (
                      <>
                        <Grid item md={6}>
                          <Grid item md={2}>
                            <strong>Attachment</strong>
                          </Grid>
                          <Grid item md={10}>
                            :
                            <AttachmentManager
                              creatorId={topic.createdBy.id}
                              currentUserId={currentUserId}
                              userRole={user}
                              topicId={topic.id}
                              fileName={topic.filename}
                              replaceOldTopicByUpdatedTopic={replaceOldTopicByUpdatedTopic}
                              topicTitle={topic.title}
                              mimeType={topic.mimetype}
                            />
                          </Grid>
                        </Grid>
                      </>
                    )}
                    <Grid item md={topic.filename ? 6 : 12} textAlign="right">
                      <Button
                        onClick={toggleVoteTopic}
                        startIcon={
                          topic?.likedUsers.map((u) => u.id).includes(currentUserId) ? (
                            <ThumbUp />
                          ) : (
                            <ThumbUpOutlined />
                          )
                        }
                      >
                        {topic?.likedUsers.length} {topic?.likedUsers.length > 1 ? 'votes' : 'vote'}
                      </Button>
                      <Button
                        onClick={() => openTopicCommentDialog(topic.id)}
                        startIcon={<MessageOutlined />}
                      >
                        {topic.topicComments.length}{' '}
                        {topic.topicComments.length > 1 ? 'comments' : 'comment'}
                      </Button>
                      <Button
                        onClick={toggleFollowTopic}
                        startIcon={
                          topic.followedByUsers.map((u) => u.id).includes(currentUserId) ? (
                            <Star />
                          ) : (
                            <StarOutline />
                          )
                        }
                      >
                        Follow
                      </Button>
                      <TopicSharing
                        {...topic}
                        mailToSubjectTemplate={mailToSubjectTemplate}
                        mailToContentTemplate={mailToContentTemplate}
                      />
                    </Grid>
                    {activeTopicIdInCommentDialog && (
                      <TopicCommentDialog
                        visibleFilterTopics={visibleFilterTopics}
                        currentUserId={currentUserId}
                        topicId={activeTopicIdInCommentDialog}
                        open={isOpenTopicCommentDialog}
                        replaceOldTopicByUpdatedTopic={replaceOldTopicByUpdatedTopic}
                        handleClose={() => setIsOpenTopicCommentDialog(false)}
                      />
                    )}
                  </Grid>
                </Grid>{' '}
              </>
            ) : (
              <CustomStepper
                status={topic.topicStatus.label ?? ''}
                handleEdit={handleEdit}
                id={topic.id ?? ''}
              />
            )}
          </AccordionDetails>
        </Accordion>
      </div>
      {user === 'admin' && (
        <>
          <TopicDeletionDlg
            open={isOpenTopicDeletionDlg}
            setOpen={setIsOpenTopicDeletionDlg}
            topic={topic}
            currentUserId={currentUserId}
            replaceOldTopicByUpdatedTopic={replaceOldTopicByUpdatedTopic}
          />
          <StatusChangedConfirmation
            open={openConfirmChangeBackwardDlg}
            setOpen={setOpenConfirmChangeBackwardDlg}
            changeStatusCallback={changeStatusCallback}
          />
        </>
      )}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Delivery date</DialogTitle>
        <DialogContent>
          <MuiDatePicker
            name="eventDate"
            label="Estimation Delivery Date"
            initialDate={dayjs()}
            onChange={(newValue: any) => setSelectedDate(newValue)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openNeedClarification} onClose={() => setOpenMessage(false)}>
        <DialogTitle>Your message</DialogTitle>
        <DialogContent sx={{ width: 600 }}>
          <div>
            <TextField
              label="Your message"
              multiline
              rows={4}
              variant="outlined"
              fullWidth
              value={dialogMsg}
              onChange={(e) => setDialogMsg(e.target.value)}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button startIcon={<SendIcon />} onClick={handleSendMessage}>
            Send mail
          </Button>
          <Button startIcon={<CloseOutlined />} onClick={() => setOpenMessage(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Topic;
