import { Button } from '@mui/material';
import React from 'react';
import { ITopic } from '../../../interfaces/ITopic';
import { IosShare } from '@mui/icons-material';

type TopicSharingProps = ITopic & {
  mailToSubjectTemplate: string;
  mailToContentTemplate: string;
};

const TopicSharing: React.FC<TopicSharingProps> = (props) => {
  const {
    mailToSubjectTemplate,
    mailToContentTemplate,
    createdAt,
    updatedAt,
    inNonProdAt,
    submittedAt,
    acceptedBacklogAt,
    devInProgressAt,
    topicStatus,
    likedUsers,
    ...toBeReplacedInTemplate
  } = props;
  const objToBeReplacedInTemplate = {
    ...toBeReplacedInTemplate,
    inNonProdDateString: inNonProdAt ? new Date(inNonProdAt).toDateString() : '',
    submittedDateString: submittedAt ? new Date(submittedAt).toDateString() : '',
    acceptedBacklogDateString: acceptedBacklogAt ? new Date(acceptedBacklogAt).toDateString() : '',
    crossDomian: toBeReplacedInTemplate?.cross_domain || '',
    typeOfTopic: toBeReplacedInTemplate?.typeOfTopic || '',
    createdDateString: createdAt ? new Date(createdAt).toDateString() : '',
    lastUpdatedDateString: updatedAt ? new Date(updatedAt).toDateString() : '',
    // topicUrl: `${import.meta.env.VITE_BASE_URL}/#/topics/${props.id}`,
    topicStatusLabel: topicStatus.label,
    upvotedBy: likedUsers.map((u) => u.firstName + ' ' + u.lastName).join(','),
    lineBreak: '%0D%0A',
  };
  let mailToSubject = mailToSubjectTemplate;
  let mailToContent = mailToContentTemplate;

  for (const key of Object.keys(objToBeReplacedInTemplate)) {
    const replaceWith = (
      objToBeReplacedInTemplate[key as keyof typeof objToBeReplacedInTemplate] || ''
    ).toString();
    mailToSubject = mailToSubject.replace(new RegExp(`{{${key}}}`, 'g'), replaceWith);
    mailToContent = mailToContent.replace(new RegExp(`{{${key}}}`, 'g'), replaceWith);
  }

  return (
    <Button
      href={`mailto:?subject=${mailToSubject}&to=&body=${mailToContent}`}
      startIcon={<IosShare />}
    >
      Share
    </Button>
  );
};

export default TopicSharing;
