import { Column } from '@devexpress/dx-react-grid';
import React from 'react';
import logoIcon from '../../../assets/logo-icon.png';
import { optionsColumnsType } from './allColumns';

const RenderElement = (props) => {
  const { title } = props;
  return (
    <div style={{ display: 'flex', gap: '5px' }}>
      <img src={logoIcon} width={20} alt="" style={{ verticalAlign: 'text-bottom' }} />
      <p>{title}</p>
    </div>
  );
};

export const columnsCharacterize = (
  optionColumns: optionsColumnsType,
  isExported = false
): (Column | string)[] => {
  const { targetColumn, entity, displayColumns, isRegExp, levelsHierarchy } = optionColumns;
  const defaultColumns =
    entity === 'RSC'
      ? ([
          // 'Menu',
          {
            name: 'id',
            title: 'iSenS_ID',
          },
          'PageNo',
          'Original_Hierarchy',
          'Hierarchy',
          'Text',
          'SubType',
          {
            name: 'Characterization',
            title: (
              <>
                <RenderElement props="Characterisation" />
              </>
            ),
          },
          {
            name: 'Char_Score',
            title: (
              <>
                <RenderElement title="Char Score" />
              </>
            ),
          },
          {
            name: 'Deeper_Characterization',
            title: <>Category</>,
          },
          {
            name: 'Deeper_Char_Score',
            title: <>Category_Score</>,
          },
          'updated_at',
          ...levelsHierarchy,
          // 'Rawdata',
          'Context',
          'Digital',
          {
            name: 'comment',
            title: <>Comment</>,
          },
          // entity === 'RSC' ? project_RSC : null,
        ] as (Column | string)[])
      : ([
          // 'Menu',
          {
            name: 'id',
            title: 'iSenS_ID',
          },
          'PageNo',
          'Original_Hierarchy',
          'Hierarchy',
          'Text',
          'SubType',
          {
            name: 'Characterization',
            title: (
              <>
                <RenderElement title="Characterization" />
              </>
            ),
          },
          {
            name: 'Char_Score',
            title: (
              <>
                <RenderElement title="Char Score" />
              </>
            ),
          },
          'updated_at',
          ...levelsHierarchy,
          // 'Rawdata',
          'Context',
          'Digital',
          {
            name: 'comment',
            title: <>Comment</>,
          },
          // entity === 'RSC' ? project_RSC : null,
        ] as (Column | string)[]);

  if (isRegExp) {
    const iReq = defaultColumns.findIndex((v: any) => v === 'ReqID');
    const i = defaultColumns.findIndex((v: any) => v === 'Text');
    // regexp column
    if (i !== -1 && iReq === -1) defaultColumns.splice(i, 0, 'ReqID');
  }

  // export into EXCEL
  if (isExported) {
    if (targetColumn && displayColumns) {
      return [
        'id',
        'updated_at',
        ...Array.from(new Set([targetColumn, ...displayColumns])),
        'Characterization',
        'Char_Score',
        'Context',

        'comment',
      ];
    }
    return defaultColumns;
  }

  if (targetColumn && displayColumns) {
    if (entity === 'RSC') {
      return [
        'id',
        'updated_at',
        ...Array.from(new Set([targetColumn, ...displayColumns])),
        {
          name: 'Characterization',
          title: (
            <>
              <img src={logoIcon} width={20} alt="" style={{ verticalAlign: 'text-bottom' }} />
              Characterization
            </>
          ),
        },
        {
          name: 'Char_Score',
          title: (
            <>
              <img src={logoIcon} width={20} alt="" style={{ verticalAlign: 'text-bottom' }} />
              Char Score
            </>
          ),
        },
        {
          name: 'Deeper_Characterization',
          title: <>Category</>,
        },
        {
          name: 'Deeper_Char_Score',
          title: <>Category_Score</>,
        },

        {
          name: 'comment',
          title: <>Comment</>,
        },
      ];
    }
    return [
      'id',
      'updated_at',
      ...Array.from(new Set([targetColumn, ...displayColumns])),
      {
        name: 'Characterization',
        title: (
          <>
            <img src={logoIcon} width={20} alt="" style={{ verticalAlign: 'text-bottom' }} />
            Characterization
          </>
        ),
      },
      {
        name: 'Char_Score',
        title: (
          <>
            <img src={logoIcon} width={20} alt="" style={{ verticalAlign: 'text-bottom' }} />
            Char Score
          </>
        ),
      },

      {
        name: 'comment',
        title: <>Comment</>,
      },
    ];
  }
  return defaultColumns;
};

export default columnsCharacterize;
