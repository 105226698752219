/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import { Navbar } from '../../components';
import { useAppContext } from '../../components/appContextProvider/AppContextProvider';

interface HeaderProps {
  user: 'admin' | 'normal';
  setUser: React.Dispatch<React.SetStateAction<'normal' | 'admin'>>;
  currentUserId: number;
  setCurrentUserId: React.Dispatch<React.SetStateAction<number>>;
}

const Header: React.FC<HeaderProps> = ({ currentUserId, setCurrentUserId }) => {
  return (
    <div>
      <Navbar currentUserId={currentUserId} setCurrentUserId={setCurrentUserId} />
    </div>
  );
};

export default Header;
