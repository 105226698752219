/* eslint-disable react/no-children-prop */
/* eslint-disable react/style-prop-object */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/button-has-type */
/**
 * This component renders of a Rex analysis comp of the page
 * new api iSearcherSearchRTE changes done by @Ranjan for the Req, Test , Expert
 * Css also fixed here
 * @param {string} props
 * @returns {ReactNode} A React element that renders Rex Analysis component of the page.
 *  Copyright @ Alstom 2023
 *  auther @Ranjan
 */
import {
  Box,
  Button,
  IconButton,
  InputBase,
  List,
  Paper,
  Popover,
  Typography,
  Tooltip,
  Slider,
} from '@material-ui/core';
import clsx from 'clsx';
import { isEmpty } from '@antv/util';
import React, { useContext, useEffect, useState } from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  useParams,
  useHistory,
} from 'react-router-dom';
import GroupsIcon from '@mui/icons-material/Groups';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import ImageIcon from '@mui/icons-material/Image';
import QuestionAnswerOutlinedIcon from '@material-ui/icons/QuestionAnswerOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined';
import ShortcutIcon from '@mui/icons-material/Shortcut';
import SearchIcon from '@mui/icons-material/Search';
import MultiSelect from 'react-multi-select-component';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import BookIcon from '@mui/icons-material/Book';
import ThreeSixtyIcon from '@mui/icons-material/ThreeSixty';
import RuleOutlinedIcon from '@mui/icons-material/RuleOutlined';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import ChatIcon from '@mui/icons-material/Chat';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';

import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import logoGenA from '../../assets/images/chat_icon_mini.png';

import '../ResultContainer/RexResultsContainer/transitionCss.css';
import { ContainerStyle, ListItem } from '../Workspace/Components/style/ContainerStyle';
import {
  iSearcherSearch,
  getREX360Response,
  getCRsResponse,
  SixSigmaRex,
  iSearcherSearchRTE,
} from '../../api/module2';
import { getAllProjectCreationLanguages } from '../../api/projects';

// import Remind from '../coredata/Remind';
import { BACKEND_URL, ENV } from '../../const';
import LoadingApp from '../../components/Core/Loading';
// import Module1Context from '../../../Module1/Module1Context';
import NoResultsFound from '../../components/Core/NoResultsFound';
import rexSvgIcon from '../../assets/rexicons/rexSvgIcon.svg';
import Requirement from '../../assets/rexicons/Requirement.svg';
import expert from '../../assets/rexicons/expert.svg';
import change_request from '../../assets/rexicons/change_request.svg';
import test from '../../assets/rexicons/test.svg';
import chat_isens from '../../assets/rexicons/chat_isens.svg';
// import TranslationNew from '../Translaor/Components/TextTranslation';
// import ProjectContext from '../../../Project/ProjectContext';
import useAppToken from '../../app/useAppToken';
// import Module2Context from '../../Module2Context';
// import REX360 from './REX360';
import Filters from '../../components/Filter/Components/Filters';
import M2GlobalContext from '../ContextMager/M2GlobalContext';
import UploadButton from '../Workspace/Components/REXAnalysis/core/components/UploadButton';
import { RexAnalysisContextProvider } from '../Workspace/Components/REXAnalysis/RexAnalysisContext';
import REX360 from '../ResultContainer/RexResultsContainer/REX360';
import RexGenA from '../ResultContainer/RexResultsContainer/REXGenA';
import ExpandContainer from './ExpandComponent';
import ChatContainer from '../Module2/NewModule/GPT2/ChatContainer';
// import UploadButton from '../../core/components/UploadButton';

const RexIcon = () => {
  return <>{/* <img src={rexSvgIcon} alt="rex" style={{ fill: 'blue' }} /> */}</>;
};

const RexIcon1 = () => {
  return (
    <>
      {/* <img src={rexSvgIcon} alt="rex" /> */}
      <ThreeSixtyIcon />
    </>
  );
};

const RexIcon2 = () => {
  return (
    <>
      {/* <img src={Requirement} alt="Requirement" /> */}
      <FactCheckOutlinedIcon />
    </>
  );
};

const RexIcon3 = () => {
  return (
    <>
      {/* <img src={expert} alt="rex" /> */}
      <EngineeringOutlinedIcon />
    </>
  );
};

const RexIcon4 = () => {
  return (
    <>
      {/* <img src={test} alt="rex" /> */}
      <RuleOutlinedIcon />
    </>
  );
};

const RexIcon5 = () => {
  return (
    <>
      {/* <img src={change_request} alt="rex" /> */}
      <DisplaySettingsIcon />
    </>
  );
};

const RexIcon6 = () => {
  return (
    <>
      {/* <img src={chat_isens} alt="rex" /> */}
      <ForumOutlinedIcon />
    </>
  );
};

const RexIcon7 = () => {
  return (
    <>
      <MenuBookIcon />
    </>
  );
};

const RexIcon8 = () => {
  return (
    <>
      <BookIcon />
    </>
  );
};

const iconstabs = [
  <QueryStatsIcon />,
  <AssignmentOutlinedIcon />,
  <PsychologyOutlinedIcon />,
  <GroupsIcon />,
  <QuestionAnswerOutlinedIcon />,
  <QuestionAnswerIcon />,
  // <TranslateOutlinedIcon />,
  <AssessmentOutlinedIcon />,
  <ImageIcon />,
  <RexIcon />,
];

const productionIconstabs = [
  <QueryStatsIcon />,
  <AssignmentOutlinedIcon />,
  <PsychologyOutlinedIcon />,
  <GroupsIcon />,
  // <QuestionAnswerOutlinedIcon />,
  <RexIcon />,
  <QuestionAnswerIcon />,
  // <TranslateOutlinedIcon />,
  <AssessmentOutlinedIcon />,
  <ImageIcon />,
  <RexIcon />,
];

const rexIconsShow = [
  <RexIcon1 />,
  <RexIcon7 />,
  <RexIcon2 />,
  <RexIcon3 />,
  <RexIcon4 />,
  <RexIcon5 />,
  // <RexIcon2 />,
  <RexIcon6 />,
  <RexIcon8 />,
];

const RexButtons = [
  'REX Cards',
  'REX Book',
  // 'REX Chat',
  'Requirements',
  'Experts',
  'Tests',
  'Change Requests',
  'Lean Six Sigma',
  'APSYS',
  'CSS Project',
];

const Link = React.forwardRef<HTMLAnchorElement, RouterLinkProps>(function Link(itemProps, ref) {
  return <RouterLink ref={ref} {...itemProps} role={undefined} />;
});

function REXAnalysis({ setSnack }) {
  // TextAnalsis operation states
  const classes = ContainerStyle();
  const history = useHistory();
  const { appJWT } = useAppToken();
  // const { shortCuts, setShortCuts } = useContext(ProjectContext);
  const { state, dispatch } = useContext(M2GlobalContext);
  const { rexFilter, isearcherFilter, similarityFilter } = state;
  const {
    issureSiteDef,
    rexPlatformDef,
    rexSourceDef,
    rexStatusDef,
    rexTypologydef,
    issureSite,
    maxResultsValue: rexMaxValue,
    rexPlatform,
    rexSource,
    rexStatus,
    rexTypology,
    productrexLine,
    product,
    solution,
    project,
    country,
  } = rexFilter;

  const productRex = product;
  const solutionRex = solution;
  const projectRex = project;
  const countryRex = country;

  const {
    productLineDef,
    productLine,
    filterProjectsDef,
    filterProjects,
    filterDocumentProfilesDef,
    filterDocumentProfiles,
    filterPlatformsDef,
    filterPlatforms,
    filterSolutionDef,
    filterProductDef,
    filterCountryDef,
    artiFact,
    productLinefilt1,
    filterPlatformsfilt2,
    filterProjectsfilt3,
    filterDocumentProfilesfilt4,
    filt5,
    filterSolutionfilt6,
    filterProductfilt7,
    filt8,
    maxResultsValue,
    checkedA,
    door,
  } = isearcherFilter;

  const [opArray, setOpArray] = useState([
    'REX Cards',
    'REX Book',
    // 'REX Chat',
    'Requirements',
    'Experts',
    'Tests',
    'Change Requests',
    'Lean Six Sigma',
    'APSYS',
    'CSS Project',
  ]);
  const [toolTipCont, setToolTipCont] = useState([
    'REX Cards',
    'REX Book',
    // 'REX Chat',
    'Requirements',
    'Experts',
    'Tests',
    'Change Requests',
    'Lean Six Sigma',
    'APSYS',
    'CSS Project',
  ]);
  // const [remind, setRemind] = useState();
  const { projectId, userId } = useParams() as any;
  const [errorMsg, setErrorMsg] = useState(false);
  const [remidtext, setRemidText] = useState('');
  const [operation, setOperation] = useState(0);
  const [inputValue, setInputValue] = useState('');
  const [langResult, setLangResult] = useState('');
  const queryParams = new URLSearchParams(window.location.search);
  const actionIs = queryParams.get('service');
  // const [callOperation, setcallOperation] = useState('Content Search');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [callOperation, setCallOperation] = useState('Content Search');
  const [display, setDisplay] = useState(false);
  const [load, setLoad] = useState(false);
  const [REXData, setREXdata] = useState();
  const [showIcons, setShowIcons] = useState(iconstabs);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const [rexChat, setRexChat] = useState(false);

  const [countResults, setCountResult] = useState([]);

  function intlFormat(num: number) {
    return new Intl.NumberFormat().format(Math.round(num * 10) / 10);
  }
  function makeFriendly(num: number) {
    if (num >= 1000000) return `${intlFormat(num / 1000000)}M`;
    if (num >= 1000) return `${intlFormat(num / 1000)}k`;
    return intlFormat(num);
  }

  // translate
  // const { setSnack } = useContext(Module1Context);

  // Changing permission for Production and development
  useEffect(() => {
    if (ENV !== 'production' && ENV !== 'test') {
      setOpArray(RexButtons);
      setToolTipCont(RexButtons);
      // setReset(true);
      // setErrorMsg(false);
      setOperation(0);
      setCallOperation('REX Cards');
      setShowIcons(rexIconsShow);
    } else {
      // setOpArray(RexButtons.filter((data) => data !== 'Lean Six Sigma'));
      // setToolTipCont(RexButtons.filter((data) => data !== 'Lean Six Sigma'));
      // setReset(true);
      // setErrorMsg(false);
      setOperation(0);
      setCallOperation('REX Cards');
      setShowIcons(rexIconsShow);
    }
  }, []);

  // Calling api when user move one tab to another
  useEffect(() => {
    if (
      callOperation === 'REX Book' ||
      callOperation === 'APSYS' ||
      callOperation === 'CSS Project'
    ) {
      setDisplay(true);
      // handleApiCall();
    }
  }, [callOperation]);

  // Api Call for REX360
  function generateREX360ApiCall(inputValue) {
    // let entityIs: any = ['*'];
    // let platform: any = ['*'];
    // let project: any = ['*'];
    // let documentProfile: any = ['*'];
    // let artifactEnt: any = ['*'];

    let issuerParseValue: any = [];
    let rexplatfromParseValue: any = [];
    let rexSourceParseValue: any = [];
    let rexStatusPareValue: any = [];
    let rexTypologyParseValue: any = [];
    // added @RK
    let rexProductLineVal: any = [];
    let rexsolutionVal: any = [];
    let rexproductVal: any = [];
    let rexprojectVal: any = [];
    let rexcountryVal: any = [];

    let entityIs: any = [];
    let platform: any = [];
    let project: any = [];
    let documentProfile: any = [];
    let artifactEnt: any = [];
    // @ ranjan
    let solution: any = [];
    let product: any = [];
    let country: any = [];
    productLinefilt1.forEach((obj: any) => {
      entityIs.push(obj.value);
    });
    filterPlatformsfilt2.forEach((obj: any) => {
      platform.push(obj.value);
    });
    filterProjectsfilt3.forEach((obj: any) => {
      project.push(obj.value);
    });
    filterDocumentProfilesfilt4.forEach((obj: any) => {
      documentProfile.push(obj.value);
    });
    filterSolutionfilt6.forEach((obj: any) => {
      solution.push(obj.value);
    });
    filterProductfilt7.forEach((obj: any) => {
      product.push(obj.value);
    });
    filt8.forEach((obj: any) => {
      country.push(obj.value);
    });
    filt5.forEach((obj: any) => {
      artifactEnt.push(obj.value);
    });
    const startTime = performance.now();
    if (entityIs.length === 0 || entityIs.length === productLineDef.length) {
      entityIs = ['*'];
    }
    if (platform.length === 0 || platform.length === filterPlatformsDef.length) {
      platform = ['*'];
    }
    if (project.length === 0 || project.length === filterProjectsDef.length) {
      project = ['*'];
    }
    if (artifactEnt.length === 0 || artifactEnt.length === artiFact.length) {
      artifactEnt = ['*'];
    }
    if (
      documentProfile.length === 0 ||
      documentProfile.length === filterDocumentProfilesDef.length
    ) {
      documentProfile = ['*'];
    }
    if (solution.length === 0 || solution.length === filterSolutionDef.length) {
      solution = ['*'];
    }
    if (product.length === 0 || product.length === filterProductDef.length) {
      product = ['*'];
    }
    if (country.length === 0 || country.length === filterCountryDef.length) {
      country = ['*'];
    }

    issureSite.forEach((obj: any) => {
      issuerParseValue.push(obj.value);
    });
    rexPlatform.forEach((obj: any) => {
      rexplatfromParseValue.push(obj.value);
    });
    rexSource.forEach((obj: any) => {
      rexSourceParseValue.push(obj.value);
    });
    rexStatus.forEach((obj: any) => {
      rexStatusPareValue.push(obj.value);
    });
    rexTypology.forEach((obj: any) => {
      rexTypologyParseValue.push(obj.value);
    });

    if (productrexLine?.length > 0) {
      productrexLine.forEach((obj: any) => {
        rexProductLineVal.push(obj.value);
      });
    }

    if (solutionRex?.length > 0) {
      solutionRex.forEach((obj: any) => {
        rexsolutionVal.push(obj.value);
      });
    }

    if (productRex?.length > 0) {
      productRex.forEach((obj: any) => {
        rexproductVal.push(obj.value);
      });
    }
    if (projectRex?.length > 0) {
      projectRex.forEach((obj: any) => {
        rexprojectVal.push(obj.value);
      });
    }
    if (countryRex?.length > 0) {
      countryRex.forEach((obj: any) => {
        rexcountryVal.push(obj.value);
      });
    }

    if (issuerParseValue.length === 0 || issuerParseValue.length === issureSiteDef.length) {
      issuerParseValue = ['*'];
    }
    if (
      rexplatfromParseValue.length === 0 ||
      rexplatfromParseValue.length === rexPlatformDef.length
    ) {
      rexplatfromParseValue = ['*'];
    }
    if (rexSourceParseValue.length === 0 || rexSourceParseValue.length === rexSourceDef.length) {
      rexSourceParseValue = ['*'];
    }
    if (rexStatusPareValue.length === 0 || rexStatusPareValue.length === rexStatusDef.length) {
      rexStatusPareValue = ['*'];
    }
    if (
      rexTypologyParseValue.length === 0 ||
      rexTypologyParseValue.length === rexTypologydef.length
    ) {
      rexTypologyParseValue = ['*'];
    }

    if (rexProductLineVal.length === 0) {
      rexProductLineVal = ['*'];
    }
    if (rexsolutionVal.length === 0) {
      rexsolutionVal = ['*'];
    }
    if (rexproductVal.length === 0) {
      rexproductVal = ['*'];
    }
    if (rexprojectVal.length === 0) {
      rexprojectVal = ['*'];
    }
    if (rexcountryVal.length === 0) {
      rexcountryVal = ['*'];
    }

    // setEnt(entityIs);
    // setPlat(platform);
    // setProj(project);
    // setDocpro(documentProfile);
    // setArtPro(artifactEnt);

    const fetchData = async () => {
      try {
        const body: any = {
          query: inputValue,
          maxResults: rexMaxValue,
          platform: rexplatfromParseValue,
          status: rexStatusPareValue,
          issuerSite: issuerParseValue,
          typology: rexTypologyParseValue,
          rexSource: rexSourceParseValue,
          solution: rexsolutionVal,
          product: rexproductVal,
          project: rexprojectVal,
          country: rexcountryVal,
          productLine: rexProductLineVal,
          translation: true,
          searchTitle: true,
          searchDescription: true,
          ner: [''],
        };
        const sigSigmaBody: any = {
          query: inputValue,
          maxResults: maxResultsValue,
          translation: true,
          ner: [''],
          searchTitle: true,
          searchDescription: true,
        };
        const allpromises = [];
        allpromises.push(
          ...[
            getREX360Response(body, projectId ?? undefined),
            iSearcherSearchRTE(
              'Query Search',
              inputValue,
              platform,
              project,
              documentProfile,
              ['Requirement'], // artifactEnt
              entityIs,
              solution,
              product,
              country,
              maxResultsValue,
              1,
              door,
              checkedA,
              projectId ?? undefined
            ),
            iSearcherSearchRTE(
              'Expert Search',
              inputValue,
              platform,
              project,
              documentProfile,
              artifactEnt,
              entityIs,
              solution,
              product,
              country,
              maxResultsValue,
              1,
              door,
              checkedA,
              projectId ?? undefined
            ),
            iSearcherSearchRTE(
              'Query Search',
              inputValue,
              platform,
              project,
              ['RTD', 'RVT'],
              artifactEnt, // artifactEnt
              entityIs,
              solution,
              product,
              country,
              maxResultsValue,
              1,
              door,
              checkedA,
              projectId ?? undefined
            ),
            getCRsResponse(inputValue),
            // iSearcherSearch(
            //   'Query Search',
            //   inputValue,
            //   ['*'],
            //   ['*'],
            //   ['*'],
            //   ['*'],
            //   ['*'],
            //   maxResultsValue,
            //   1,
            //   'AEC',
            //   checkedA,
            //   projectId
            // ),
            SixSigmaRex(sigSigmaBody),
          ]
        );

        const response = await Promise.all(allpromises);
        const jsonData = await Promise.all(response.map((res) => res));
        const obj = {
          data: jsonData,
          result: true,
        };
        setREXdata(obj);
        const resultsCount = [
          'numberOfResults',
          'numberOfResultsFound',
          'experts',
          'numberOfResultsFound',
          'numberOfResults',
          // 'numberOfResultsFound',
          'numberOfResults',
        ];
        const Count = [] as any;
        jsonData?.map((count, index) => {
          // eslint-disable-next-line no-self-compare
          if (count === null || isEmpty(count)) {
            Count.push(0);
          } else if (resultsCount[index] in count) {
            if (resultsCount[index] === 'experts') {
              Count.push(Object.keys(count[resultsCount[index]]).length);
            } else {
              Count.push(count[resultsCount[index]]);
            }
          }
          return count;
        });
        const temp = [];
        temp.push(Count[0]);
        temp.push(0);
        // temp.push(0);

        setCountResult([...temp, ...Count.slice(1, Count.length)]);
        // setLanguage(jsonData[2]?.translation?.detectedLanguage);
      } catch (error) {
        console.error('Error fetching data:', error);
        // setSnack(`something went wrong ${error}`, 'warning');
      } finally {
        // if (!opArray.includes('Requirements')) {
        //   if (ENV !== 'production' && ENV !== 'test') {
        //     setOpArray(RexButtons);
        //     setToolTipCont(RexButtons);
        //     setReset(true);
        //     setOperation(0);
        //     setCallOperation('REX');
        //     setShowIcons(rexIconsShow);
        //   } else {
        //     setOpArray(RexButtons.filter((data) => data !== 'Lean Six Sigma'));
        //     setToolTipCont(RexButtons.filter((data) => data !== 'Lean Six Sigma'));
        //     setReset(true);
        //     setOperation(0);
        //     setCallOperation('REX');
        //     setShowIcons(rexIconsShow);
        //   }
        // }
        setDisplay(true);
        setLoad(false);
      }
    };

    fetchData();
  }

  // handeling common function call for all operational API
  /* 
    This function is making a call for selected Call Operation so here in ths 
    function switch case is used, it will call an API for respective selected 
    Services
    */
  const handleApiCall = () => {
    // These three State are to reset the display and errormsg
    // setInerDisplay(false);
    // setLoadIner(false);
    setErrorMsg(false);
    if (langResult) setLangResult(false);
    if (!inputValue && callOperation !== 'REX Book') {
      // setRemind(true);
      setRemidText('Please Enter Input');
      return;
    }
    switch (callOperation) {
      case 'REX Book': {
        setDisplay(true);
        break;
      }
      case 'CSS Project': {
        setDisplay(true);
        break;
      }

      default:
        break;
    }

    if (opArray.includes('Requirements') && callOperation !== 'REX Book') {
      setLoad(true);
      setDisplay(false);
      generateREX360ApiCall(inputValue);
      // setCallOperation('REX 360');
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box className={classes.root}>
        {/* {remind && <Remind open={remind} text={remidtext} setRemind={setRemind} />} */}
        <Box
          style={{
            padding: '10px',
            borderBottom: display ? '1px solid #D1D9EF' : '',
          }}
        >
          <Box className="text-analysis-parent">
            <Box className={classes.subMain2Child1}>
              <Box className="text-analysis-parent-container">
                <Box className="text-analysis-parent-container-child">
                  {callOperation !== 'REX 360' &&
                    callOperation !== 'REX Book' &&
                    callOperation !== 'APSYS' &&
                    callOperation !== 'CSS Project' && (
                      <Paper
                        style={{
                          width: '100%',
                          height: '100%',
                          paddingLeft: '1rem',
                          // padding: '5px',
                          display: 'flex',
                          justifyContent: 'space-between',
                          gap: '10px',
                          border: '0.5px solid #002A45',
                          borderRadius: '22px',
                          boxShadow: '0px 4px 8px #00000029',
                        }}
                      >
                        <InputBase
                          style={{
                            flexBasis: '95%',
                            fontSize: '1.3rem',
                            color: '#002A45',
                          }}
                          placeholder="Search Rex Content"
                          inputProps={{
                            maxLength: 2500,
                            // style: { '&::placeholder': { fontSize: '0.5rem' } },
                          }}
                          onChange={(event) => {
                            setInputValue(event.target.value);
                          }}
                          value={inputValue}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') handleApiCall();
                          }}
                        />
                        <Tooltip title="GenA" arrow>
                          <Box
                            style={{
                              color: '#002A45',
                              fontWeight: 'bold',
                              display: 'flex',
                              cursor: 'pointer',
                              alignItems: 'center',
                            }}
                            // value={inputValue}
                            // type="button"
                            // aria-label="search"
                            onClick={() => {
                              setRexChat(true);
                            }}
                          >
                            <img
                              src={logoGenA}
                              alt="isens genA"
                              height="30"
                              width="30"
                              style={{ borderRadius: '50%', marginRight: '5px' }}
                            />
                            <span>Chat</span>
                          </Box>
                        </Tooltip>
                        <IconButton
                          style={{ color: '#002A45' }}
                          value={inputValue}
                          type="button"
                          aria-label="search"
                          onClick={handleApiCall}
                        >
                          <SearchIcon />
                        </IconButton>
                      </Paper>
                    )}
                </Box>
                {RexButtons.includes(callOperation) &&
                  callOperation !== 'REX 360' &&
                  // callOperation !== 'REX Book' &&
                  callOperation !== 'APSYS' &&
                  callOperation !== 'CSS Project' && (
                    <>
                      {' '}
                      <IconButton
                        style={{
                          backgroundColor: '#EEF1F9',
                          boxShadow: '0px 4px 8px #00000029',
                          width: '3rem',
                          color: '#002A45',
                          height: '3rem',
                        }}
                        aria-describedby={id}
                        variant="contained"
                        onClick={handleClick}
                        disabled={['Change Requests', 'AEC', 'Lean Six Sigma'].includes(
                          callOperation
                        )}
                      >
                        <FilterAltOutlinedIcon />
                      </IconButton>
                      <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        PaperProps={{
                          style: {
                            backgroundColor: '#EEF1F9',
                            marginTop: '10px',
                            boxShadow: 'none',
                            overflowX: 'visible',
                            overflowY: 'visible',
                            height: '220px',
                          },
                        }}
                      >
                        <Filters callOperation={callOperation} />
                      </Popover>
                    </>
                  )}
              </Box>

              <Box className="feature-list-parent">
                <Box className="feature-list-parent-container-workspace">
                  <List className={classes.listParent}>
                    {opArray.map((item, i) => {
                      const RouteName = item
                        .split(' ')
                        .map((data) => data.toLowerCase())
                        .join('');
                      return (
                        <>
                          <Tooltip
                            title={`${
                              toolTipCont[i] === 'Experts' ? 'Referents' : toolTipCont[i]
                            } : ${countResults[i] ?? 0}`}
                            arrow
                          >
                            <ListItem
                              // component={Link}
                              // to={`/projects/${projectId}/module2/${'m2'}/short/?action=${`TextAnalysis`}&service=${RouteName}`}
                              button
                              id={`${item} is ${i}`}
                              onClick={() => {
                                setOperation(i);
                                setCallOperation(item);
                                // if (
                                //   filt1 ||
                                //   filt2 ||
                                //   filt3 ||
                                //   filt4 ||
                                //   filt5 ||
                                //   maxResultsValue < 100
                                // )
                                //   dispatch({ type: 'clear_isearch_filter' });
                                // if (
                                //   issureSite ||
                                //   rexPlatform ||
                                //   rexSource ||
                                //   rexStatus ||
                                //   rexTypology ||
                                //   maxResultsValue < 100
                                // )
                                //   dispatch({ type: 'clear_rex_filter' });
                                // HandleRexClick(i);
                                // rex360Click();
                              }}
                              className={classes.operationListItem}
                              selected={operation === i}
                            >
                              {showIcons[i]}
                              {item === 'Experts' ? (
                                <>
                                  <Typography>Referents</Typography>
                                </>
                              ) : (
                                <Typography>{item}</Typography>
                              )}
                              {/* <Typography>{item}</Typography> */}
                              {countResults[i] > 0 && (
                                <span className={classes.badge}>
                                  {makeFriendly(countResults[i])}
                                </span>
                              )}
                            </ListItem>
                          </Tooltip>
                        </>
                      );
                    })}
                  </List>
                  {/* {callOperation === 'REX Book' && <UploadButton />} */}
                  {load && <LoadingApp />}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* <ChatContainer action="rex" initPrompt={prompt} /> */}
        {rexChat && (
          <ExpandContainer isChatExpandable={rexChat} setIsChatExpand={setRexChat} mode="Rex">
            <ChatContainer
              action="rex"
              initPrompt={
                inputValue
                  ? `
  extract and summarize the content about  ${inputValue}`
                  : undefined
              }
              setIsChatExpand={setRexChat}
            />
          </ExpandContainer>
        )}
        {display && (
          <>
            <Box className="result-container">
              {errorMsg && <NoResultsFound />}
              {!errorMsg && (
                <RexAnalysisContextProvider>
                  <REX360
                    input={inputValue}
                    REXData={REXData}
                    operation={operation}
                    setCountResult={setCountResult}
                    setSnack={setSnack}
                  />
                </RexAnalysisContextProvider>
              )}
            </Box>
          </>
        )}
      </Box>
    </>
  );
}

export default REXAnalysis;
