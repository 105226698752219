import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import Paper from '@mui/material/Paper';
import { EditingState, PagingState, IntegratedPaging } from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableEditRow,
  TableEditColumn,
  Toolbar,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { Button } from '@material-ui/core';
import {
  delete_label_Table,
  delete_training_Table,
  getTemplatTableData,
  put_label_Table,
  put_training_Table,
} from '../../../api/allocationNew';
import ProjectContext from '../../Project/ProjectContext';
import ToolBarPlugin from '../../../components/Datagrid/ToolBarPlugin';
import {
  tableContainerComponent,
  tableHeaderComponent,
  toolbarComponent,
} from '../../../components/Datagrid/TStyles';

const getRowId = (row) => row.id;

const TrainingTable = () => {
  const { setSnack } = useContext(ProjectContext);
  const [labelTableData, setLabelTableData] = useState([]);

  const [columns] = useState([
    // { name: 'id', title: 'id' },
    { name: 'expert_allocation', title: 'Expert Allocation' },
    { name: 'requirement_text', title: 'Requirement Text' },
    // { name: 'template_id', title: 'Template Id' },
  ]);
  const [rows, setRows] = useState([]);

  const exporterRef = useRef(null);
  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);
  const onSave = (workbook: any) => {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'data.xlsx');
    });
  };

  useEffect(() => {
    const queryParams = window.location.pathname;
    const id = queryParams.split('/')[2];
    getTemplatTableData(id)
      .then((data) => {
        console.log('data', data);
        setRows(data.training);
      })
      .catch((error) => {
        console.log('error', error);
      });
  }, []);

  const commitChanges = ({ changed, deleted }) => {
    let changedRows;
    let changedRow;
    if (changed) {
      // get the changed row only
      changedRow = rows
        .filter((row) => changed[row.id])
        .map((row) => ({ ...row, ...changed[row.id] }));
      // console.log('changedRow: ', changedRow);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      editAPICall(changedRow);
      // get the changed row with other rows means table data
      changedRows = rows.map((row) => (changed[row.id] ? { ...row, ...changed[row.id] } : row));
      // console.log('changedRows: ', changedRows);
      setSnack('Saved!', 'success');
    }
    if (deleted) {
      const deletedSet = new Set(deleted);
      const deletedRowIds = rows
        .filter((row) => deletedSet.has(row.id))
        .map((row) => ({ id: row.id }));
      // console.log('deletedRowIds: ', deletedRowIds);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      deleteAPICall(deletedRowIds);
      changedRows = rows.filter((row) => !deletedSet.has(row.id));
      // console.log('deletedSet: ', changedRows);
      setSnack('deleted!', 'warning');
    }
    setRows(changedRows);
  };

  const editAPICall = (rows) => {
    put_training_Table(rows)
      .then((data) => {
        console.log('data', data);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const deleteAPICall = (ids) => {
    delete_training_Table(ids)
      .then((data) => {
        console.log('data', data);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const saveMe = (e) => {
    console.log('save me clicked', e);
  };

  return (
    <Paper>
      <Grid rows={rows} columns={columns} getRowId={getRowId}>
        <EditingState onCommitChanges={commitChanges} />
        <Table />
        <TableHeaderRow />
        <TableEditRow />
        <TableEditColumn showEditCommand showDeleteCommand />
        <PagingState defaultCurrentPage={0} defaultPageSize={10} />
        <IntegratedPaging />
        <PagingPanel pageSizes={[10, 20, 50]} />
        <Toolbar rootComponent={toolbarComponent} />
        <ToolBarPlugin name="Download" onClick={startExport} />
      </Grid>
      <GridExporter ref={exporterRef} rows={rows} columns={columns} onSave={onSave} />
    </Paper>
  );
};

export default TrainingTable;
