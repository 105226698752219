/* eslint-disable no-template-curly-in-string */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/destructuring-assignment */

import {
  AppBar,
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { columnsTotalWidthSelector } from '@material-ui/data-grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FindInPageOutlinedIcon from '@material-ui/icons/FindInPageOutlined';
import KeyboardArrowUpOutlinedIcon from '@material-ui/icons/KeyboardArrowUpOutlined';
import { TreeItem, TreeView } from '@material-ui/lab';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import Skeleton from '@mui/material/Skeleton';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import saveAs from 'file-saver';
import { Owning } from 'OwningType';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import ReactJson from 'react-json-view';
import MultiSelect from 'react-multi-select-component';
import { Link, useParams, useHistory } from 'react-router-dom';

import { Workload3Create } from 'WorkloadType';
import { iNer, runAllocator, runCharacterize, ScopeOutput } from '../../../../../api/module1';
import { getOwning } from '../../../../../api/ownings';
import Loading from '../../../../../components/Core/Loading';
import { a11yProps } from '../../../../../components/Datagrid/TStyles';
import TabStyle from '../../../../../components/Layout/TabStyle';
import TreeArowIcons from '../../../../../components/TreeMenu/TreeArowIcons';
import { BACKEND_URL, IS_DEBUG_MODE } from '../../../../../const';
import ModalConfirm from '../../../../Admin/components/ModalConfirm';
import ProjectContext from '../../../../Project/ProjectContext';
import Module1Context from '../../../Module1Context';
import { SettingsForm } from '../../../RunContext';
import { getLanguageCode } from '../TranslateSelection';
// import AnalysisFlow from './AnalysisFlow';
import AssignFlow from './AssignmentFlow/components/AssignFlow';
import AssignmentFlow from './AssignmentFlow';
import CaptureFlow from './CaptureFlow';
import CaptureFlowSplit from './CaptureFlowSplit';
import CharacterizerFlow from './ChararcterizerFlow';
import INer from './INer';
import OwningFlow from './OwningFlow';
import AnalysisFlow from './AnalysisFlow/components/AnalysisFlow';

dayjs.extend(utc);

export const useStyles = makeStyles((theme) => ({
  root: {},
  blockPdf: {
    height: 310,
    borderRadius: 10,
    border: 'solid 1px;',
    borderColor: theme.palette.primary.light,
    margin: 10,
    backgroundColor: '#EEF1F9',
    color: '#002A45',
  },
  bloc: {
    height: 250,
    borderRadius: 10,
    border: 'solid 1px;',
    borderColor: theme.palette.primary.light,
    margin: 10,
    backgroundColor: '#EEF1F9',
    color: '#002A45',
  },
  overflow: {
    height: 250,
    overflowY: 'scroll',
    wordWrap: 'break-word',
  },
  iconMargin: {
    marginRight: '16px',
    // color: theme.palette.primary.dark,
  },
  title: {
    textAlign: 'center',
    color: '#FFFFFF',
  },
  error: {
    color: theme.palette.error.main,
  },
  // tabs: {
  //   borderBottom: `2px solid ${theme.palette.primary.main}`,
  // },
  // tabstyle: {
  //   border: `1px solid ${theme.palette.primary.main}`,
  //   borderRadius: '5px 5px 0 0',
  //   backgroundColor: theme.palette.primary.main,
  //   color: '#fff !important',
  //   fontSize: 14,
  //   fontWeight: 700,
  // },
  pdfPanel: {
    margin: 10,
    padding: '0px !important',
    minHeight: 250,
  },
  treeRoot: {
    width: '98%',
    margin: '13px',
    flexGrow: 1,
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
    '& .MuiTreeItem-iconContainer svg': {
      fontSize: '18px',
      position: 'relative',
      left: '-20px',
      color: '#FFFFFF',
    },
    '& .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label': {
      backgroundColor: '#EEF1F9 !important',
    },
    border: '1px solid #002A45',
    borderRadius: '10px',
  },
  content: {
    flexDirection: 'row-reverse',
    backgroundColor: '#002A45',
  },
  treeitem: {
    width: '99%',
  },
  treeLabel: {
    borderBottom: '1px solid #002A45',
    paddingLeft: 10,
    color: '#FFFFFF',
    font: 'normal normal bold 25px/28px Alstom',
    letterSpacing: '0px',
    padding: '0 0 10px 0',
  },
  label: {
    '&hover': {
      backgroundColor: 'none',
    },
    '&.MuiTreeItem-label': {
      width: '100%',
      position: 'relative',
      paddingLeft: '0px',
    },
  },
  treeIcon: {
    width: '40px',
    height: '40px',
    transform: 'translateY(8px)',
    marginRight: '10px',
  },
  heading: {
    backgroundColor: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 8,
    textAlign: 'center',
    marginBottom: 10,
    color: 'white',
  },
  active: {
    background: theme.palette.success.main,
    border: 'solid 1px green',
    borderRadius: 5,
    padding: 5,
    color: 'white',
  },
  inactive: {
    background: theme.palette.error.main,
    border: 'solid 1px red',
    borderRadius: 5,
    padding: 5,
    color: 'white',
  },
  formControlMulti: {
    margin: theme.spacing(1),
    marginTop: -2,
    minWidth: 200,
    maxWidth: 400,
    border: 2,
    borderColor: 'black',
  },
  captureTypography: {
    backgroundColor: '#002A45',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    color: 'white',
  },
  captureBody: {
    backgroundColor: '#EEF1F9',
  },
  IconButton: {
    '& .MuiIconButton-root:hover': {
      backgroundColor: 'none',
    },
  },
  IconDesignMain: {
    display: 'flex',
    border: '1px solid #002A45',
    borderRadius: 70,
  },
  IconDesign: {
    padding: '6px 20px',
    backgroundColor: '#002A45',
    borderRadius: 70,
    display: 'flex',
    // justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    marginTop: -1,
  },
  IconDesign2: {
    padding: '6px 5px',
    width: 30,
    // backgroundColor: 'white',
    borderRadius: 70,
    display: 'flex',
    // justifyContent: 'center',
    alignItems: 'center',
    color: '#002A45',
  },
}));

type Props = {
  onClose: any;
  scope?: ScopeOutput;
  settings?: SettingsForm;
  results?: any;
  otherResult?: any;
  urlFile?: string;
  projectname?: string;
  workload: Workload3Create;
};

type TabState = {
  capture: boolean;
  characterizer: boolean;
  assignment: boolean;
  owning: boolean;
  analyze: boolean;
};

const initTab = {
  capture: true,
  characterizer: false,
  assignment: false,
  owning: false,
  analyze: false,
} as TabState;

type actionType =
  | 'init'
  | 'capture'
  | 'characterizer'
  | 'allocator'
  | 'assignment'
  | 'owning'
  | 'analyze';

const reducer = (
  state: TabState,
  action: {
    type: actionType;
    payload?: any;
  }
) => {
  switch (action.type) {
    case 'init':
      return initTab;
    default:
      return {
        ...state,
        [action.type]: true,
      };
  }
};
const disableTab = (role: number, tabNumber: number, currentTab?: number): boolean => {
  // performer or on loading : only own + analyze tabs allowed
  if (role === 3) {
    return true;
  }
  // project leader : all tabs allowed but if tab assignement is done we must disable capture + characterize
  if (role === 2 || role === 1) {
    if (currentTab !== undefined && Number(currentTab) > 1) {
      if ([0, 1].includes(tabNumber)) {
        return true;
      }
    }
  }
  return false;
};
const queryParams = new URLSearchParams(window.location.search);
const owningId = queryParams.get('owning_id');
const loadTabIndex = (userProfile: any) => {
  const queryParams = new URLSearchParams(window.location.search);
  const actionIs = queryParams.get('action') as actionType;
  // default for performer
  if (userProfile === 3) return 3;
  if (actionIs === 'capture' && disableTab(userProfile, 0, 0) === false) {
    return 0;
  }

  if (actionIs === 'characterizer' && disableTab(userProfile, 1, 1) === false) {
    return 1;
  }
  if (['assignment', 'allocator'].includes(actionIs) && disableTab(userProfile, 2, 2) === false) {
    return 2;
  }
  if (actionIs === 'owning' && disableTab(userProfile, 3, 3) === false) {
    return 3;
  }
  if (actionIs === 'analyze' && disableTab(userProfile, 4, 0) === false) {
    return 4;
  }

  // default for leader & admin
  return 0;
};
function DocumentReview({
  scope,
  settings,
  results,
  otherResult,
  urlFile,
  projectname,
  workload,
}: Props) {
  const [iNerUsableData, setINerUsableData] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [checked, setChecked] = React.useState(true);
  const [refreshOwningTab, setRefreshOwningTab] = useState(0);
  const classes = useStyles();
  const arowClasses = TreeArowIcons();
  const classesTab = TabStyle();
  const { projectId } = useParams() as any;
  const { userJWT } = useContext(ProjectContext);
  const history = useHistory();
  const { project, setSnack, setTranslationCode, languagesCode } = useContext(Module1Context);
  const [tabValue, setTabValue] = React.useState<number>(
    loadTabIndex(project.users?.find((u) => u.user_id === userJWT?.user_id)?.role_id ?? 3)
  );
  const [originalText, setOriginalText] = useState('');
  const [actionForFile, setActionForFile] = useState('');
  const [levelsHierarchy, setLevelsHierarchy] = useState([]);
  const [modalOpen1, setModalOpen1] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [label, setLabel] = useState('');
  // on editing
  const [rowsEdited, setRowsEdited] = useState<any>([]);
  // reducer for tab authorization
  const [stateTab, dispatch] = useReducer(reducer, initTab);
  // take initial onLoad time capture data (having id)
  // const [initialCapture, setInitialCapture] = useState<any>();
  // iner page display
  const [inerDisplay, setInerDisplay] = useState<any>(false);
  // loading for extract entities
  const [loader, setLoader] = useState<any>();
  const [pageDefault, setPageDefault] = useState(0);
  const [selected, setSelected] = useState([
    { label: 'System', value: 'System' },
    { label: 'Activities', value: 'ABS_ent' },
    { label: 'Acronyms', value: 'Acronym' },
  ]);

  const queryParams = new URLSearchParams(window.location.search);
  const actionIs = queryParams.get('action') as actionType;

  useEffect(() => {
    switch (actionIs) {
      case 'allocator': {
        return disableTab(userProfile, 2, 2) === false ? setTabValue(2) : setTabValue(4);
      }
      case 'capture': {
        return disableTab(userProfile, 0, 0) === false ? setTabValue(0) : setTabValue(4);
      }
      case 'characterizer': {
        return disableTab(userProfile, 1, 1) === false ? setTabValue(1) : setTabValue(4);
      }
      case 'analyze': {
        return setTabValue(4);
      }
      default:
        break;
    }
    return null;
  }, [actionIs, workload.id]);
  /*
  remove all dispatch above > assigment not needed
  keep dispatch assigment for redirection async
  */
  useEffect(() => {
    if (tabValue === 0) {
      if (!['characterizer', 'allocator'].includes(actionForFile)) {
        // setActionForFile('capture');
        dispatch({ type: 'capture' });
      }
    }
    if (tabValue === 1) {
      if (!['allocator'].includes(actionForFile)) {
        setLoading(false);
        // setActionForFile('characterizer');
        dispatch({ type: 'characterizer' });
      }
    }
    if (tabValue === 2 || tabValue === 3 || tabValue === 4) {
      // setActionForFile('allocator');
      dispatch({ type: 'assignment' });
    }
  }, [projectId, tabValue, scope?.FileName, workload.id]);

  const lang = settings?.language ?? scope?.Language;

  // console.log('lang', lang);
  const userProfile = project.users?.find((u) => u.user_id === userJWT?.user_id)?.role_id ?? 3;
  const fileNameExt = new URL(String(urlFile)).searchParams
    .get('filename')
    ?.split('.')
    .pop()
    ?.toLowerCase();

  const languageOriginCode = getLanguageCode(
    ['PDF', 'CSV'].includes((scope?.FileType || '').toUpperCase())
      ? scope?.Language || '-'
      : scope?.Sheets.find((s) => String(s.SheetName) === String(settings?.sheetname))?.Language ||
          '-'
  );

  const rowTabNames = ['capture', 'characterizer', 'assignment', 'owning', 'analyze'];

  const options = [
    { label: 'System', value: 'System' },
    { label: 'Activities', value: 'ABS_ent' },
    { label: 'Acronyms', value: 'Acronym' },
    { label: 'Standards', value: 'standards' },
    { label: 'Cyber Security', value: 'cybersecurity' },
    { label: 'Project Management', value: 'project management' },
    { label: 'Commercial', value: 'Commercial' },
    { label: 'Finance', value: 'Finance' },
    { label: 'Contractual', value: 'Contractual' },
    { label: 'Digital', value: 'Digital' },
  ];

  const RunCharacter = () => {
    history.push(`/projects/${projectId}/module1/m1/results/${workload.id}?action=characterizer`);
    setLoading(true);
    setModalOpen1(false);
    if (rowsEdited.length > 0) {
      const originalRow = rowsEdited.map((row) => {
        const { i18n } = row;
        if ('i18n' in row && Object?.keys(i18n)?.length > 1) {
          if (settings?.targetColumn) {
            const { targetColumn } = settings;
            return { ...row, [targetColumn]: i18n.en };
          }
          return { ...row, Text: i18n.en };
        }
        return row;
      });
      runCharacterize(
        {
          // need to put all informations for database
          project_id: Number(projectId),
          workload_id: Number(workload.id),
          filename: scope?.FileName,
          content: {
            dataframe: { Text: JSON.stringify(originalRow) },
            target: settings.targetColumn || 'Text',
          },
          model: project.entity === 'RSC' ? 'RSC' : 'D&IS',
        },
        Number(workload.id)
      )
        .then((payload: any) => {
          if (payload) {
            dispatch({ type: 'characterizer' });
          }
        })
        .catch((err) => {
          console.log(err);
        });

      setTabValue(1);
      setLoading(false);
    }
  };

  const RunAllocator = () => {
    history.push(`/projects/${projectId}/module1/m1/results/${workload.id}?action=allocator`);
    setLoading(true);
    setModalOpen2(false);
    let projectLabel = project.entity;
    if (['D&IS', 'Services'].includes(project.entity)) {
      projectLabel = project?.region === 'DACH' ? 'DACH' : 'D&IS';
    }
    const originalRow = rowsEdited.map((row) => {
      const { i18n } = row;
      if ('i18n' in row && Object?.keys(i18n)?.length > 1) {
        if (settings?.targetColumn) {
          const { targetColumn } = settings;
          return { ...row, [targetColumn]: i18n.en };
        }
        return { ...row, Text: i18n.en };
      }
      return row;
    });
    runAllocator(
      {
        // need to put all informations for database
        project_id: Number(projectId),
        workload_id: Number(workload.id),
        filename: scope?.FileName,
        content: {
          dataframe: {
            Text: JSON.stringify(originalRow),
          },
          target: settings.targetColumn || 'Text',
          multiAllocation: true,
          // model: project.entity,
          // warning possible confusion entity and SBS category
          model: project.entity === 'RSC' ? 'SBS' : projectLabel,
          ner: true,
        },
      },
      Number(workload.id)
    )
      .then((payload: any) => {
        if (payload) {
          dispatch({ type: 'assignment' });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setTabValue(2);
    setLoading(false);
  };
  //  // langeCheck={settings?.language} // old code (working) due to changes in middleware it was not working
  //  langCheck={scope?.Language} // new code (& working) shivprasad

  const handleTabChangeSplit = (event, newReportValue) => {
    switch (0) {
      default:
      case 0:
        setTabValue(newReportValue);
        setLoading(true);
        dispatch({ type: 'capture' });
        setLoading(false);
    }
  };

  const handleTabChange = (event, newReportValue) => {
    switch (newReportValue) {
      default:
      case 0:
        history.push(`/projects/${projectId}/module1/m1/results/${workload.id}?action=capture`);
        setTabValue(newReportValue);
        setLoading(true);
        dispatch({ type: 'capture' });
        setLoading(false);
        return;

      case 1:
        // force reload data
        if (stateTab.characterizer === false) {
          if (rowsEdited.length > 0) {
            setLabel('characterizer');
          } else {
            setLabel('waitCapture');
          }
          setModalOpen1(true);
        } else {
          history.push(
            `/projects/${projectId}/module1/m1/results/${workload.id}?action=characterizer`
          );
          setTabValue(newReportValue);
          setLoading(false);
        }

        return;

      case 2:
        // assignment
        if (stateTab.characterizer === false) {
          setSnack('Click on Characterizer Report', 'info');
          setLoading(false);
          return;
        }

        if (stateTab.assignment === false) {
          if (rowsEdited.length > 0 && 'Char_Score' in rowsEdited[0]) {
            setLabel('assgin');
          } else {
            setLabel('waitCharacterizer');
          }
          setModalOpen2(true);
        } else {
          history.push(`/projects/${projectId}/module1/m1/results/${workload.id}?action=allocator`);
          setTabValue(newReportValue);
          setLoading(false);
        }
        // no needed redirection

        break;

      case 3:
      case 4:
        // see useEffect
        if (stateTab.assignment === false) {
          setSnack('Click on Assign Report', 'info');
          setLoading(false);
          return;
        }
        setTabValue(newReportValue);
        break;
    }
  };

  const runLoading = (timeout = 500) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, timeout);
  };

  const handleSaveAs = (filenameSave: string) => (workbook: any) => {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), filenameSave);
    });
  };

  const handleReset = (rows: undefined | any[] = undefined, page = undefined) => {
    runLoading();
    if (rows) setRowsEdited(rows);
    if (page !== undefined) setPageDefault(page);
    dispatch({ type: 'init' });
  };

  function getINer() {
    // const temp = [] as any;
    const originalRow = rowsEdited.map((row: any) => {
      const obj = { ...row };
      const { i18n } = obj;
      const temp = i18n?.en;
      if ('i18n' in obj && Object?.keys(i18n)?.length > 1) {
        if (settings?.targetColumn) {
          const { targetColumn } = settings;
          // eslint-disable-next-line no-delete-var
          // eslint-disable-next-line no-param-reassign
          delete obj?.i18n;
          return { ...obj, [targetColumn]: temp };
        }
        // eslint-disable-next-line no-delete-var
        // eslint-disable-next-line no-param-reassign
        delete obj?.i18n;
        return { ...obj, Text: temp };
      }
      // eslint-disable-next-line no-delete-var
      // eslint-disable-next-line no-param-reassign
      delete obj?.i18n;
      return { ...obj };
    });

    const arr: any = [];
    // eslint-disable-next-line array-callback-return
    selected.map((v, i) => {
      arr.push(v.value);
    });
    if (arr.length === 0) {
      setSnack('Please select Entities', 'error');
    } else {
      setInerDisplay(false);
      setLoader(true);
      const targetPass = fileNameExt === 'pdf' ? 'Text' : settings.targetColumn;
      iNer({
        dataframe: JSON.stringify(originalRow),
        target: targetPass,
        onto: true,
        filterList: arr,
      })
        .then((payload) => {
          setINerUsableData(payload);
          setInerDisplay(true);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoader(false);
        });
    }
  }

  const handleSearchTextSplit = (
    myText: string,
    coordinates: {
      width: number;
      height: number;
      x1: number;
      y1: number;
      x2: number;
      y2: number;
      page: number;
    }
  ) => {
    if (!scope?.FilePath) return;
    const highlightContent = {
      content: {
        text: myText,
      },
      position: {
        boundingRect: {
          x1: coordinates.x1,
          x2: coordinates.x2,
          y1: coordinates.y1,
          y2: coordinates.y2,
          width: coordinates.width,
          height: coordinates.height,
        },
        rects: [
          {
            x1: coordinates.x1,
            x2: coordinates.x2,
            y1: coordinates.y1,
            y2: coordinates.y2,
            width: coordinates.width,
            height: coordinates.height,
          },
        ],
        pageNumber: coordinates.page,
      },
      comment: {
        text: '',
        emoji: '',
      },
    };
    window.frames.pdf_iframe?.postMessage(highlightContent, BACKEND_URL.iframePdfHost);
  };
  const handleSearchText = (
    myText: string,
    coordinates: {
      width: number;
      height: number;
      x1: number;
      y1: number;
      x2: number;
      y2: number;
      page: number;
    }
  ) => {
    if (!scope?.FilePath) return;
    const highlightContent = {
      content: {
        text: myText,
      },
      position: {
        boundingRect: {
          x1: coordinates?.x1,
          x2: coordinates?.x2,
          y1: coordinates?.y1,
          y2: coordinates?.y2,
          width: coordinates?.width,
          height: coordinates?.height,
        },
        rects: [
          {
            x1: coordinates?.x1,
            x2: coordinates?.x2,
            y1: coordinates?.y1,
            y2: coordinates?.y2,
            width: coordinates?.width,
            height: coordinates?.height,
          },
        ],
        pageNumber: coordinates?.page,
      },
      comment: {
        text: '',
        emoji: '',
      },
    };
    window.frames.pdf_iframe?.postMessage(highlightContent, BACKEND_URL.iframePdfHost);
  };
  let ms = 0;
  if (fileNameExt === 'pdf') {
    const hmss: any = results.CapturedIn; // your input string
    const a = hmss.split(':'); // split it at the colons

    // minutes are worth 60 seconds. Hours are worth 60 minutes.
    // eslint-disable-next-line prettier/prettier
    ms = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
  }
  const pms = (
    (window.performance.timing.domContentLoadedEventEnd -
      window.performance.timing.navigationStart) /
    360
  ).toFixed(0); // your input string
  const convertMinsToHrsMins = (value) => {
    const sec = parseInt(value, 10); // convert value to number if it's string
    let hours = Math.floor(sec / 3600); // get hours
    let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
    let seconds = sec - hours * 3600 - minutes * 60; //  get seconds
    // add 0 if value < 10; Example: 2 => 02
    if (hours < 10) {
      hours = `0${hours}`;
    }
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }
    if (seconds < 10) {
      seconds = `0${seconds}`;
    }
    return `${hours}:${minutes}:${seconds}`;
  };

  return (
    <div>
      {IS_DEBUG_MODE && (
        <ReactJson
          src={{
            actionForFile,
            scope,
            settings,
            stateTab,
            tabValue,
            userProfile,
            // workload,
            // results,
            // reportValue,
          }}
          collapsed={true}
          theme="monokai"
        />
      )}
      <Grid container direction="row" justify="flex-start" alignItems="stretch" spacing={1}>
        <Grid
          container
          justify="flex-end"
          alignItems="center"
          style={{
            display:
              fileNameExt === 'pdf' || fileNameExt === 'PDF' || fileNameExt === 'Pdf' ? '' : 'none',
          }}
        >
          <Grid item>
            <IconButton
              sx={{
                ml: 1,
                '&.MuiButtonBase-root:hover': {
                  bgcolor: 'transparent',
                },
              }}
              onClick={() => {
                setChecked(!checked);
                setLoading(false);
              }}
            >
              {checked ? (
                <Grid className={classes.IconDesignMain}>
                  <div className={classes.IconDesign}>
                    <FormatListBulletedOutlinedIcon />
                    <span style={{ fontSize: 16 }}>Detail View</span>
                  </div>
                  <div className={classes.IconDesign2}>
                    <AppsOutlinedIcon />
                  </div>
                </Grid>
              ) : (
                <Grid className={classes.IconDesignMain}>
                  <div className={classes.IconDesign2}>
                    <FormatListBulletedOutlinedIcon />
                  </div>
                  <div className={classes.IconDesign}>
                    <AppsOutlinedIcon />
                    <span style={{ fontSize: 16 }}>Split View</span>
                  </div>
                </Grid>
              )}
            </IconButton>
          </Grid>
        </Grid>

        {checked === false ? (
          <>
            <Grid container>
              {fileNameExt === 'pdf' && (
                <Grid item xs={6}>
                  <Grid className={classes.pdfPanel} item lg={12} sm={12} md={12}>
                    <Box className={classesTab.boxDocumentReview}>
                      <AppBar position="static" className={classesTab.appStyle}>
                        <Tabs
                          className={classesTab.root}
                          value={tabValue}
                          onChange={handleTabChangeSplit}
                          variant="fullWidth"
                          indicatorColor="primary"
                          textColor="primary"
                          aria-label="Capture Characterize Allocate"
                          TabIndicatorProps={{ style: { display: 'none' } }}
                        >
                          <Tab
                            key="tab-capture-table"
                            {...a11yProps(0)}
                            className={tabValue === 0 ? classesTab.tabstyle : classesTab.tabstyle}
                            label={
                              <>
                                <Typography
                                  variant="subtitle2"
                                  title="Capture"
                                  className={classesTab.tabLebelText}
                                >
                                  <span>Capture</span>
                                </Typography>
                              </>
                            }
                            title="Capture"
                            disabled={disableTab(userProfile, 0, tabValue)}
                          />
                        </Tabs>
                      </AppBar>
                      {loading ? (
                        <Loading />
                      ) : (
                        <>
                          <CaptureFlowSplit
                            rows={rowsEdited}
                            onChange={setRowsEdited}
                            workloadId={Number(workload.id)}
                            filename={String(scope?.FileName ?? '')}
                            dispatch={dispatch}
                            onReset={handleReset}
                            onSearchText={handleSearchTextSplit}
                            onDownload={handleSaveAs(
                              `project${projectId}_${results.NameOfCapture || ''}_capture.xlsx`
                            )}
                            isRegExp={settings?.regId}
                            targetColumn={settings?.targetColumn}
                            displayColumns={settings?.columnNames}
                            pageDefault={pageDefault}
                          />
                        </>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              )}
              {fileNameExt === 'pdf' && (
                <Grid item xs={6}>
                  <Grid
                    id={`top-pdf-${scope?.FileName}`}
                    className={classes.pdfPanel}
                    item
                    lg={12}
                    sm={12}
                    md={12}
                  >
                    <>
                      <iframe
                        name="pdf_iframe"
                        src={`${BACKEND_URL.iframePdfHost}?url=${encodeURI(urlFile || '')}`}
                        title="fff"
                        height="510"
                        width="100%"
                      />
                    </>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12}>
              {!IS_DEBUG_MODE && fileNameExt === 'pdf' && (
                <TreeView
                  className={classes.treeRoot}
                  defaultCollapseIcon={<ExpandMoreIcon className={arowClasses.ArowdownStyle} />}
                  defaultExpandIcon={
                    <KeyboardArrowUpOutlinedIcon className={arowClasses.ArowupStyle} />
                  }
                  defaultExpanded={['']}
                >
                  <TreeItem
                    nodeId="1"
                    label={
                      <Typography variant="subtitle1" className={classes.treeLabel}>
                        <span>
                          <FindInPageOutlinedIcon className={classes.treeIcon} />
                        </span>
                        PDF Preview
                      </Typography>
                    }
                    className={classes.treeitem}
                    classes={{
                      label: classes.label,
                      selected: classes.label,
                      content: classes.content,
                      group: classes.group,
                    }}
                  >
                    <Grid
                      id={`top-pdf-${scope?.FileName}`}
                      className={classes.pdfPanel}
                      item
                      lg={12}
                      sm={12}
                      md={12}
                    >
                      <>
                        <iframe
                          name="pdf_iframe"
                          src={`${BACKEND_URL.iframePdfHost}?url=${encodeURI(urlFile || '')}`}
                          title="fff"
                          height="400"
                          width="100%"
                        />
                      </>
                    </Grid>
                  </TreeItem>
                </TreeView>
              )}
            </Grid>

            {/* <Grid item xs={12}>
              {!IS_DEBUG_MODE && fileNameExt === 'pdf' && (
                <Grid
                  id={`top-pdf-${scope?.FileName}`}
                  className={classes.pdfPanel}
                  item
                  lg={12}
                  sm={12}
                  md={12}
                >
                  <>
                    <iframe
                      name="pdf_iframe"
                      src={`${BACKEND_URL.iframePdfHost}?url=${encodeURI(urlFile || '')}`}
                      title="fff"
                      height="400"
                      width="100%"
                    />
                  </>
                </Grid>
              )}
            </Grid> */}
            <Grid item xs={12}>
              <Grid className={classes.pdfPanel} item lg={12} sm={12} md={12}>
                <Box className={classesTab.box}>
                  <AppBar position="static" className={classesTab.appStyle}>
                    <Tabs
                      className={classesTab.root}
                      value={tabValue}
                      onChange={handleTabChange}
                      variant="fullWidth"
                      indicatorColor="primary"
                      textColor="primary"
                      aria-label="Capture Characterize Allocate"
                      TabIndicatorProps={{ style: { display: 'none' } }}
                    >
                      <Tab
                        style={{
                          display: workload?.resources[0]?.fastm1 === 'yes' ? 'none' : '',
                        }}
                        key="tab-capture-table"
                        {...a11yProps(0)}
                        className={tabValue === 0 ? classesTab.tabstyle : ''}
                        label={
                          <>
                            <Typography
                              variant="subtitle2"
                              title="Capture"
                              className={classesTab.tabLebelText}
                            >
                              <span>Capture</span>
                            </Typography>
                          </>
                        }
                        title="Capture"
                        disabled={disableTab(userProfile, 0, tabValue)}
                      />

                      <Tab
                        style={{
                          display: workload?.resources[0]?.fastm1 === 'yes' ? 'none' : '',
                        }}
                        key="tab-characterize-table"
                        {...a11yProps(1)}
                        className={tabValue === 1 ? classesTab.tabstyle : ''}
                        label={
                          <>
                            <Typography
                              variant="subtitle2"
                              title="Characterize"
                              className={classesTab.tabLebelText}
                            >
                              <span>Characterize</span>
                            </Typography>
                          </>
                        }
                        title="Characterize"
                        disabled={disableTab(userProfile, 1, tabValue)}
                      />

                      <Tab
                        key="tab-allocation-table"
                        {...a11yProps(2)}
                        className={tabValue === 2 ? classesTab.tabstyle : ''}
                        label={
                          <>
                            <Typography
                              variant="subtitle2"
                              title="Assign"
                              className={classesTab.tabLebelText}
                            >
                              <span>Assign</span>
                            </Typography>
                          </>
                        }
                        title="Assign"
                        disabled={disableTab(userProfile, 2, tabValue)}
                      />
                      <Tab
                        style={{ display: 'none' }}
                        key="tab-owning-table"
                        {...a11yProps(3)}
                        className={tabValue === 3 ? classesTab.tabstyle : ''}
                        label={
                          <>
                            <Typography
                              variant="subtitle2"
                              title="Own"
                              className={classesTab.tabLebelText}
                            >
                              <span>Own</span>
                            </Typography>
                          </>
                        }
                        title="Own"
                      />
                      <Tab
                        key="tab-analysis-table"
                        {...a11yProps(4)}
                        className={tabValue === 4 ? classesTab.tabstyle : ''}
                        label={
                          <>
                            <Typography
                              variant="subtitle2"
                              title="Analyze"
                              className={classesTab.tabLebelText}
                            >
                              <span>Analyze</span>
                            </Typography>
                          </>
                        }
                        title="Analyze"
                      />
                    </Tabs>
                  </AppBar>

                  <>
                    {tabValue === 0 && (
                      <CaptureFlow
                        rows={rowsEdited}
                        onChange={setRowsEdited}
                        workloadId={Number(workload.id)}
                        filename={String(scope?.FileName ?? '')}
                        actionForFile={actionForFile}
                        originalText={originalText}
                        dispatch={dispatch}
                        setActionForFile={setActionForFile}
                        onReset={handleReset}
                        levelsHierarchy={levelsHierarchy}
                        setLevelsHierarchy={setLevelsHierarchy}
                        setOriginalText={setOriginalText}
                        setTranslationCode={setTranslationCode}
                        onSearchText={handleSearchText}
                        onDownload={handleSaveAs(
                          `project${projectId}_${results.NameOfCapture || ''}_capture.xlsx`
                        )}
                        isRegExp={settings?.regId}
                        targetColumn={settings?.targetColumn}
                        displayColumns={settings?.columnNames}
                        pageDefault={pageDefault}
                        // langeCheck={settings?.language} // old code (working) due to changes in middleware it was not working
                        // langCheck={scope?.Language} // new code (& working) shivprasad
                        langCheck={lang} // changes due to original lang varies based on different result so condition ?? between scope and setting
                        languageOriginCode={languageOriginCode}
                        languages={settings?.translateTo}
                      />
                    )}
                    {tabValue === 1 && (
                      <CharacterizerFlow
                        rows={rowsEdited}
                        workloadId={Number(workload.id)}
                        filename={String(scope?.FileName ?? '')}
                        actionForFile={actionForFile}
                        setActionForFile={setActionForFile}
                        levelsHierarchy={levelsHierarchy}
                        entity={project?.entity}
                        originalText={originalText}
                        setOriginalText={setOriginalText}
                        onChange={setRowsEdited}
                        onSearchText={handleSearchText}
                        onDownload={handleSaveAs(
                          `project${projectId}_${results.NameOfCapture || ''}_characterize.xlsx`
                        )}
                        dispatch={dispatch}
                        isRegExp={settings?.regId}
                        targetColumn={settings?.targetColumn}
                        displayColumns={settings?.columnNames}
                        // langeCheck={settings?.language} // old code (working) due to changes in middleware it was not working
                        // langCheck={scope?.Language} // new code (& working) shivprasad
                        langCheck={lang} // changes due to original lang varies based on different result so condition ?? between scope and setting
                        onReset={handleReset}
                        languageOriginCode={languageOriginCode}
                        languages={settings?.translateTo}
                      />
                    )}
                    {tabValue === 2 && (
                      <AssignFlow
                        filename={String(scope?.FileName ?? '')}
                        workloadId={Number(workload.id)}
                        actionForFile={actionForFile}
                        setActionForFile={setActionForFile}
                        originalText={originalText}
                        setOriginalText={setOriginalText}
                        levelsHierarchy={levelsHierarchy}
                        targetColumn={settings?.targetColumn}
                        displayColumns={settings?.columnNames}
                        // langeCheck={settings?.language} // old code (working) due to changes in middleware it was not working
                        // langCheck={scope?.Language} // new code (& working) shivprasad
                        langCheck={lang} // changes due to original lang varies based on different result so condition ?? between scope and setting
                        entity={project?.entity}
                        dispatch={dispatch}
                        isRegExp={settings?.regId}
                        languageOriginCode={languageOriginCode}
                        languages={settings?.translateTo}
                        onChange={setRowsEdited}
                        urlFile={urlFile}
                      />
                      // <AssignmentFlow
                      //   rows={rowsEdited}
                      //   workloadId={Number(workload.id)}
                      //   filename={String(scope?.FileName ?? '')}
                      //   actionForFile={actionForFile}
                      //   setActionForFile={setActionForFile}
                      //   originalText={originalText}
                      //   setOriginalText={setOriginalText}
                      //   levelsHierarchy={levelsHierarchy}
                      //   targetColumn={settings?.targetColumn}
                      //   displayColumns={settings?.columnNames}
                      //   // langeCheck={settings?.language} // old code (working) due to changes in middleware it was not working
                      //   // langCheck={scope?.Language} // new code (& working) shivprasad
                      //   langCheck={lang} // changes due to original lang varies based on different result so condition ?? between scope and setting
                      //   entity={project?.entity}
                      //   onChange={setRowsEdited}
                      //   onSearchText={handleSearchText}
                      //   onDownload={handleSaveAs(
                      //     `project${projectId}_${results.NameOfCapture || ''}_assign.xlsx`
                      //   )}
                      //   dispatch={dispatch}
                      //   onRefresh={setRefreshOwningTab}
                      //   isRegExp={settings?.regId}
                      //   entityProject={project.entity}
                      //   onReset={handleReset}
                      //   languageOriginCode={languageOriginCode}
                      //   languages={settings?.translateTo}
                      // />
                    )}
                    {tabValue === 3 && (
                      <OwningFlow
                        rows={rowsEdited}
                        onSearchText={handleSearchText}
                        workloadId={Number(workload.id)}
                        filename={String(scope?.FileName ?? '')}
                        targetColumn={settings?.targetColumn}
                        levelsHierarchy={levelsHierarchy}
                        displayColumns={settings?.columnNames}
                        originalText={originalText}
                        setActionForFile={setActionForFile}
                        setOriginalText={setOriginalText}
                        onDownload={handleSaveAs(
                          `project${projectId}_${results.NameOfCapture || ''}_owning.xlsx`
                        )}
                        isRegExp={settings?.regId}
                        onChange={setRowsEdited}
                        dispatch={dispatch}
                        entityProject={project.entity}
                        languageOriginCode={languageOriginCode}
                        languages={settings?.translateTo}
                        // langeCheck={settings?.language} // old code (working) due to changes in middleware it was not working
                        // langCheck={scope?.Language} // new code (& working) shivprasad
                        langCheck={lang} // changes due to original lang varies based on different result so condition ?? between scope and setting
                      />
                    )}
                    {tabValue === 4 && (
                      <AnalysisFlow
                        filename={String(scope?.FileName ?? '')}
                        workloadId={Number(workload.id)}
                        actionForFile={actionForFile}
                        setActionForFile={setActionForFile}
                        originalText={originalText}
                        setOriginalText={setOriginalText}
                        levelsHierarchy={levelsHierarchy}
                        targetColumn={settings?.targetColumn}
                        displayColumns={settings?.columnNames}
                        // langeCheck={settings?.language} // old code (working) due to changes in middleware it was not working
                        // langCheck={scope?.Language} // new code (& working) shivprasad
                        langCheck={lang} // changes due to original lang varies based on different result so condition ?? between scope and setting
                        entity={project?.entity}
                        dispatch={dispatch}
                        isRegExp={settings?.regId}
                        languageOriginCode={languageOriginCode}
                        languages={settings?.translateTo}
                        onChange={setRowsEdited}
                        urlFile={urlFile}
                      />
                      // <AnalysisFlow
                      //   filename={String(scope?.FileName ?? '')}
                      //   onSearchText={handleSearchText}
                      //   // langeCheck={settings?.language} // old code (working) due to changes in middleware it was not working
                      //   // langCheck={scope?.Language} // new code (& working) shivprasad
                      //   langCheck={lang} // changes due to original lang varies based on different result so condition ?? between scope and setting
                      //   setActionForFile={setActionForFile}
                      //   setOriginalText={setOriginalText}
                      //   rows={rowsEdited}
                      //   targetColumn={settings?.targetColumn}
                      //   displayColumns={settings?.columnNames}
                      //   levelsHierarchy={levelsHierarchy}
                      //   workloadId={Number(workload.id)}
                      //   originalText={originalText}
                      //   onDownload={handleSaveAs(
                      //     `project${projectId}_${results.NameOfCapture || ''}_analysis.xlsx`
                      //   )}
                      //   onRefresh={setRefreshOwningTab}
                      //   dispatch={dispatch}
                      //   isRegExp={settings?.regId}
                      //   entityProject={project.entity}
                      //   onChange={setRowsEdited}
                      //   owningId={owningId}
                      //   languageOriginCode={languageOriginCode}
                      //   languages={settings?.translateTo}
                      // />
                    )}
                  </>
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: '10px' }}>
              {/* fileNameExt === 'pdf' */}
              <div style={{ float: 'right' }}>
                {/* <FormControl variant="outlined" className={classes.formControlMulti}>
                  <MultiSelect
                    options={options}
                    value={selected}
                    onChange={setSelected}
                    labelledBy="Entities"
                  />
                </FormControl> */}
                {/* <Button
                  variant="contained"
                  color="primary"
                  onClick={getINer}
                  style={{ float: 'right' }}
                  disabled
                >
                  Extract Entities
                </Button> */}
              </div>

              <br />
              <br />
              {loader && <Loading style={{ marginTop: '50px' }} />}
              {inerDisplay && <INer data={iNerUsableData} />}
            </Grid>
          </>
        )}
      </Grid>
      {modalOpen1 && (
        <ModalConfirm
          open={modalOpen1}
          onConfirm={RunCharacter}
          onCancel={() => {
            history.push(`/projects/${projectId}/module1/m1/results/${workload.id}?action=capture`);
            setModalOpen1(false);
            setTabValue(0);
          }}
          label={label}
          // label={rowsEdited.length ? 'characterizer' : 'waitCharaterizer'}
        />
      )}
      {modalOpen2 && (
        <ModalConfirm
          open={modalOpen2}
          onConfirm={RunAllocator}
          onCancel={() => {
            history.push(
              `/projects/${projectId}/module1/m1/results/${workload.id}?action=characterizer`
            );
            setModalOpen2(false);
            setTabValue(1);
          }}
          label={label}
        />
      )}
    </div>
  );
}

export default DocumentReview;
