import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import AssignmentIcon from '@mui/icons-material/Assignment';
import RenderPdfFile from './RenderPdfFile';
// import { ButtonMUI } from '../Styles/utils';

const RowChildContainer = ({ urlFile, row, textColum, children, filename }) => {
  const [show, setShow] = useState(true);
  const { Coordinates, Characterization } = row;
  const Text = row[textColum];
  const ext = filename?.split('.');
  const hidePdfView = ext.length > 0 ? ext[ext.length - 1] === 'pdf' : false;
  const hideAllocation = Characterization === 'Requirement';

  return (
    <>
      <div style={{ display: 'flex', gap: '10px', width: '70%' }}>
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '5px' }}>
          <IconButton
            disabled={!hideAllocation}
            onClick={() => {
              setShow(true);
            }}
            variant="contained"
            color={show ? 'secondary' : 'info'}
          >
            <AssignmentIcon />
          </IconButton>

          <IconButton
            disabled={!hidePdfView}
            onClick={() => {
              setShow(false);
            }}
            variant="contained"
            color={show ? 'info' : 'secondary'}
          >
            <PictureAsPdfIcon />
          </IconButton>
        </div>
        {show ? (
          <div style={{ flex: 99 }}>{children}</div>
        ) : (
          <div style={{ flex: 99, width: '50%' }}>
            <RenderPdfFile
              urlFile={urlFile}
              Text={Text}
              coordinates={Coordinates}
              propStyle={{ width: '50%', display: 'flex', height: '100%', position: 'relative' }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default RowChildContainer;
