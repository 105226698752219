const fileTranslateReducer = (
  state: { selectedFileData: any; selected_glossary: any },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case 'Update_selected_docment': {
      return {
        ...state,
        selectedFileData: action.payload,
      };
    }

    case 'Update_selected_glossary': {
      return {
        ...state,
        selected_glossary: action.payload,
      };
    }

    case 'remove_selected_glossary': {
      return {
        ...state,
        selected_glossary: action.payload,
      };
    }

    case 'Change_in_From_Lang': {
      return {
        ...state,
        FromLang: action.payload,
      };
    }

    case 'Change_in_To_Lang': {
      return {
        ...state,
        ToLang: action.payload,
      };
    }

    case 'Change_in_From_Lang_for_glossary': {
      return {
        ...state,
        GlossaryFromLang: action.payload,
      };
    }

    case 'Change_in_To_Lang_for_glossary': {
      return {
        ...state,
        GlossaryToLang: action.payload,
      };
    }

    case 'auto_detect_doc_lang': {
      // console.log(action.payload);

      return {
        ...state,
        FromLang: action.payload.FromLang,
        ToLang: action.payload.ToLang,
        glossaryToLanuageMatch: action.payload.ToLang,
      };
    }

    case 'change_model': {
      return {
        ...state,
        model: action.payload,
      };
    }

    default:
      return state;
  }
};

export default fileTranslateReducer;
