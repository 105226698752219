import * as React from 'react';
import { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

interface MuiDatePickerProps {
  name: string;
  label: string;
  initialDate: Dayjs | null;
  //onDateAccepted: (date: Date | undefined) => void;
  onChange: (date: Date | undefined) => void;
}

const MuiDatePicker: React.FC<MuiDatePickerProps> = ({
  name,
  label,
  initialDate,
  //onDateAccepted,
  onChange,
}) => {
  const [value, setValue] = React.useState<Dayjs | null>(initialDate);

  React.useEffect(() => {
    onChange(value?.toDate());
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker']}>
        <DatePicker
          format="DD/MM/YYYY"
          label={
            <>
              {label}
              <span style={{ color: 'red' }}> *</span>
            </>
          }
          name={name}
          value={value}
          // onAccept={date => {
          //   onDateAccepted(date?.toDate());
          // }}
          onChange={(newValue) => {
            setValue(newValue);
            onChange(newValue?.toDate());
          }}
          slotProps={{ textField: { size: 'small' } }}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
};

export default MuiDatePicker;
