import React from 'react';
import { Link } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import { Logo } from '../index';
// import { useIsAuthenticated } from "@azure/msal-react";
import { SignOut } from '../Auth/SignOut';
import { useAppContext } from '../appContextProvider/AppContextProvider';
// import { SignOut } from "../Auth/SignOut";
// import { SignIn } from "../Auth/SignIn";

const Navbar: React.FC<any> = () => {
  const { state } = useAppContext();
  return (
    <div className="px-2 md:px-[50px] border-b border-gray-300 bg-[#EEF1FB] flex items-center">
      <Link to="/">
        <Logo />
      </Link>
      <div className="flex-1 text-end align-middle">
        <span className="pr-5">
          {state.userInfo.firstName} {state.userInfo.lastName}{' '}
        </span>
        {/* <Avatar>{`${state.userInfo.firstName
          ?.split('')[0]
          ?.toUpperCase()}${state?.userInfo?.lastName?.split('')[0].toUpperCase()}`}</Avatar> */}
        {state.user === 'admin' && (
          <span
            className="pr-5"
            style={{
              // fontWeight: 'bold',
              backgroundColor: '#002A45',
              color: 'white',
              padding: '5px',
              borderRadius: '5px',
              textAlign: 'center',
              // display: 'flex',
              // alignItems: 'center',
              // justifyContent: 'center',
            }}
          >
            Admin
          </span>
        )}
        {/* <SignOut /> */}
      </div>
    </div>
  );
};

export default Navbar;
