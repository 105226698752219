const rexFilterReducer = (
  state: any,
  action: {
    type: any;
    payload: {
      issureSiteDef: any;
      rexPlatformDef: any;
      rexSourceDef: any;
      rexStatusDef: any;
      rexTypologydef: any;
      // added
      rexProductLineDef: any;
      rexProductDef: any;
      rexSolutionDef: any;
      rexProjectDef: any;
      rexCountryDef: any;
      // Added
      filterissureSite: any;
      filterexPlatform: any;
      filterrexSource: any;
      filterrexStatus: any;
      filterTypology: any;
      // added
      filterrexProductLine: any;
      filterrexProduct: any;
      filterrexSolution: any;
      filterrexProject: any;
      filterrexCountry: any;
    };
  }
) => {
  switch (action.type) {
    case 'set_default_filter': {
      console.log('actionm', action);
      return {
        ...state,
        issureSiteDef: action.payload.issureSiteDef,
        rexPlatformDef: action.payload.rexPlatformDef,
        rexSourceDef: action.payload.rexSourceDef,
        rexStatusDef: action.payload.rexStatusDef,
        rexTypologydef: action.payload.rexTypologydef,
        rexProductLineDef: action.payload.rexProductLineDef,
        rexProductDef: action.payload.rexProductDef,
        rexSolutionDef: action.payload.rexSolutionDef,
        rexProjectDef: action.payload.rexProjectDef,
        rexCountryDef: action.payload.rexCountryDef,
        filterissureSite: action.payload.issureSiteDef,
        filterexPlatform: action.payload.rexPlatformDef,
        filterrexSource: action.payload.rexSourceDef,
        filterrexStatus: action.payload.rexStatusDef,
        filterTypology: action.payload.rexTypologydef,
        // added
        filterrexProductLine: action.payload.rexProductLineDef,
        filterrexProduct: action.payload.rexProductDef,
        filterrexSolution: action.payload.rexSolutionDef,
        filterrexProject: action.payload.rexProjectDef,
        filterrexCountry: action.payload.rexCountryDef,
      };
    }
    case 'clear_rex_filter': {
      // console.log('sstt', state);
      return {
        ...state,
        filterissureSite: state.issureSiteDef,
        filterexPlatform: state.rexPlatformDef,
        filterrexSource: state.rexSourceDef,
        filterrexStatus: state.rexStatusDef,
        filterTypology: state.rexTypologydef,
        filterrexProductLine: state.rexProductLineDef,
        filterrexProduct: state.rexProductDef,
        filterrexSolution: state.rexSolutionDef,
        filterrexProject: state.rexProjectDef,
        filterrexCountry: state.rexCountryDef,
        issureSite: [],
        rexPlatform: [],
        rexSource: [],
        rexStatus: [],
        rexTypology: [],
        productrexLine: [],
        product: [],
        solution: [],
        project: [],
        country: [],
        maxResultsValue: 350,
        door: '*',
      };
    }

    case 'change_ProductLine_rex': {
      return {
        ...state,
        productrexLine: action.payload,
      };
    }
    case 'nochange_ProductLine_rex': {
      // console.log('starte', state);
      return {
        ...state,
        filterissureSite: action.payload.filterissureSite,
        filterexPlatform: action.payload.filterexPlatform,
        filterrexSource: action.payload.filterrexSource,
        filterrexStatus: action.payload.filterrexStatus,
        filterTypology: action.payload.filterTypology,
        // added
        // filterrexProductLine: action.payload.filterrexProductLine,
        filterrexProduct: action.payload.filterrexProduct,
        filterrexSolution: action.payload.filterrexSolution,
        filterrexProject: action.payload.filterrexProject,
        filterrexCountry: action.payload.filterrexCountry,
      };
    }
    case 'change_issuer_site': {
      // console.log('starte', state);
      return {
        ...state,
        issureSite: action.payload,
      };
    }
    case 'nochange_issuer_site': {
      // console.log('starte', state);
      return {
        ...state,
        // filterissureSite: action.payload.filterissureSite,
        filterexPlatform: action.payload.filterexPlatform,
        filterrexSource: action.payload.filterrexSource,
        filterrexStatus: action.payload.filterrexStatus,
        filterTypology: action.payload.filterTypology,
        // added
        filterrexProductLine: action.payload.filterrexProductLine,
        filterrexProduct: action.payload.filterrexProduct,
        filterrexSolution: action.payload.filterrexSolution,
        filterrexProject: action.payload.filterrexProject,
        filterrexCountry: action.payload.filterrexCountry,
      };
    }
    case 'change_Solution_rex': {
      return {
        ...state,
        solution: action.payload,
      };
    }
    case 'nochange_Solution_rex': {
      // console.log('starte', state);
      return {
        ...state,
        filterissureSite: action.payload.filterissureSite,
        filterexPlatform: action.payload.filterexPlatform,
        filterrexSource: action.payload.filterrexSource,
        filterrexStatus: action.payload.filterrexStatus,
        filterTypology: action.payload.filterTypology,
        // added
        filterrexProductLine: action.payload.filterrexProductLine,
        filterrexProduct: action.payload.filterrexProduct,
        // filterrexSolution: action.payload.filterrexSolution,
        filterrexProject: action.payload.filterrexProject,
        filterrexCountry: action.payload.filterrexCountry,
      };
    }
    case 'change_Product_rex': {
      return {
        ...state,
        product: action.payload,
      };
    }
    case 'nochange_Product_rex': {
      // console.log('starte', state);
      return {
        ...state,
        filterissureSite: action.payload.filterissureSite,
        filterexPlatform: action.payload.filterexPlatform,
        filterrexSource: action.payload.filterrexSource,
        filterrexStatus: action.payload.filterrexStatus,
        filterTypology: action.payload.filterTypology,
        // added
        filterrexProductLine: action.payload.filterrexProductLine,
        // filterrexProduct: action.payload.filterrexProduct,
        filterrexSolution: action.payload.filterrexSolution,
        filterrexProject: action.payload.filterrexProject,
        filterrexCountry: action.payload.filterrexCountry,
      };
    }
    case 'change_Project_rex': {
      return {
        ...state,
        project: action.payload,
      };
    }
    case 'nochange_Project_rex': {
      // console.log('starte', state);
      return {
        ...state,
        filterissureSite: action.payload.filterissureSite,
        filterexPlatform: action.payload.filterexPlatform,
        filterrexSource: action.payload.filterrexSource,
        filterrexStatus: action.payload.filterrexStatus,
        filterTypology: action.payload.filterTypology,
        // added
        filterrexProductLine: action.payload.filterrexProductLine,
        filterrexProduct: action.payload.filterrexProduct,
        filterrexSolution: action.payload.filterrexSolution,
        // filterrexProject: action.payload.filterrexProject,
        filterrexCountry: action.payload.filterrexCountry,
      };
    }
    case 'change_Country_rex': {
      return {
        ...state,
        country: action.payload,
      };
    }
    case 'nochange_Country_rex': {
      // console.log('starte', state);
      return {
        ...state,
        filterissureSite: action.payload.filterissureSite,
        filterexPlatform: action.payload.filterexPlatform,
        filterrexSource: action.payload.filterrexSource,
        filterrexStatus: action.payload.filterrexStatus,
        filterTypology: action.payload.filterTypology,
        // added
        filterrexProductLine: action.payload.filterrexProductLine,
        filterrexProduct: action.payload.filterrexProduct,
        filterrexSolution: action.payload.filterrexSolution,
        filterrexProject: action.payload.filterrexProject,
        // filterrexCountry: action.payload.filterrexCountry,
      };
    }

    case 'change_RexPlatForm': {
      return {
        ...state,
        rexPlatform: action.payload,
      };
    }
    case 'nochange_RexPlatForm': {
      // console.log('starte', state);
      return {
        ...state,
        filterissureSite: action.payload.filterissureSite,
        // filterexPlatform: action.payload.filterexPlatform,
        filterrexSource: action.payload.filterrexSource,
        filterrexStatus: action.payload.filterrexStatus,
        filterTypology: action.payload.filterTypology,
        // added
        filterrexProductLine: action.payload.filterrexProductLine,
        filterrexProduct: action.payload.filterrexProduct,
        filterrexSolution: action.payload.filterrexSolution,
        filterrexProject: action.payload.filterrexProject,
        filterrexCountry: action.payload.filterrexCountry,
      };
    }

    case 'change_RexSource': {
      return {
        ...state,
        rexSource: action.payload,
      };
    }
    case 'nochange_RexSource': {
      // console.log('starte', state);
      return {
        ...state,
        filterissureSite: action.payload.filterissureSite,
        filterexPlatform: action.payload.filterexPlatform,
        // filterrexSource: action.payload.filterrexSource,
        filterrexStatus: action.payload.filterrexStatus,
        filterTypology: action.payload.filterTypology,
        // added
        filterrexProductLine: action.payload.filterrexProductLine,
        filterrexProduct: action.payload.filterrexProduct,
        filterrexSolution: action.payload.filterrexSolution,
        filterrexProject: action.payload.filterrexProject,
        filterrexCountry: action.payload.filterrexCountry,
      };
    }

    case 'change_RexStatus': {
      return {
        ...state,
        rexStatus: action.payload,
      };
    }
    case 'nochange_RexStatus': {
      // console.log('starte', state);
      return {
        ...state,
        filterissureSite: action.payload.filterissureSite,
        filterexPlatform: action.payload.filterexPlatform,
        filterrexSource: action.payload.filterrexSource,
        // filterrexStatus: action.payload.filterrexStatus,
        filterTypology: action.payload.filterTypology,
        // added
        filterrexProductLine: action.payload.filterrexProductLine,
        filterrexProduct: action.payload.filterrexProduct,
        filterrexSolution: action.payload.filterrexSolution,
        filterrexProject: action.payload.filterrexProject,
        filterrexCountry: action.payload.filterrexCountry,
      };
    }

    case 'change_RexTypology': {
      return {
        ...state,
        rexTypology: action.payload,
      };
    }
    case 'nochange_RexTypology': {
      // console.log('starte', state);
      return {
        ...state,
        filterissureSite: action.payload.filterissureSite,
        filterexPlatform: action.payload.filterexPlatform,
        filterrexSource: action.payload.filterrexSource,
        filterrexStatus: action.payload.filterrexStatus,
        // filterTypology: action.payload.filterTypology,
        // added
        filterrexProductLine: action.payload.filterrexProductLine,
        filterrexProduct: action.payload.filterrexProduct,
        filterrexSolution: action.payload.filterrexSolution,
        filterrexProject: action.payload.filterrexProject,
        filterrexCountry: action.payload.filterrexCountry,
      };
    }
    case 'set_db_Rex_custom_filter': {
      // console.log('action', action);
      return {
        ...state,

        filterexPlatform: action.payload.filterexPlatform,
        // added
        filterrexProductLine: action.payload.filterrexProductLine,
        filterrexProduct: action.payload.filterrexProduct,
        filterrexSolution: action.payload.filterrexSolution,
        filterrexProject: action.payload.filterrexProject,
        // filterrexCountry: action.payload.filterrexCountry,
      };
    }

    case 'change_Rex_Max': {
      return {
        ...state,
        maxResultsValue: action.payload,
      };
    }
    default:
      return state;
  }
};

export default rexFilterReducer;
