import { FileUploadOutlined } from '@mui/icons-material';
import { Button, Chip, Fab } from '@mui/material';
import React, { ChangeEvent, useState, useRef } from 'react';
// import { truncateText } from "../../../utils/textTruncator";
import { getFileBase64Promise } from '../../../utils/fileUtil';
import { apiEndpoints } from '../../../utils/apiEndpoints';
import { ITopic } from '../../../interfaces/ITopic';

interface FileAttachmentProps {
  currentUserId: number;
  topicId?: number;
  onFileChange?: (
    fileBuffer: string | ArrayBuffer | null,
    mimeType: string,
    fileName: string
  ) => void;
  replaceOldTopicByUpdatedTopic?: (updatedTopic: ITopic) => void;
}

const FileAttachment: React.FC<FileAttachmentProps> = ({
  topicId,
  currentUserId,
  onFileChange,
  replaceOldTopicByUpdatedTopic,
}) => {
  const fileRef = useRef<HTMLInputElement | null>(null);
  const [fileName, setFileName] = useState('');
  const [mimeType, setMimeType] = useState('');
  const [fileBuffer, setFileBuffer] = useState<string | ArrayBuffer | null>(null);
  const acceptedFileTypes = "";

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      const fileToUpload =
        fileRef && fileRef.current && fileRef.current.files && fileRef.current.files.length
          ? fileRef.current.files[0]
          : null;
      if (fileToUpload) {
        // const safeFileName = encodeURIComponent(truncateText(e.target.files[0].name, 15, "end").trim().replace(/\s/g, ""));
        const safeFileName = e.target.files[0].name;
        getFileBase64Promise(fileToUpload).then((base64Content) => {
          setFileBuffer(base64Content as ArrayBuffer);
          setFileName(safeFileName);
          setMimeType(fileToUpload.type);
          if (onFileChange) {
            onFileChange(base64Content as ArrayBuffer, fileToUpload.type, safeFileName);
          }
          e.target.value = ''; // Normally, choosing the same file will not fire the onChange event, hence we need to clear this target value
        });
      }
    }
  };

  const resetFileUpload = () => {
    setFileName('');
    setMimeType('');
    setFileBuffer(null);
  };

  const startUpload = async () => {
    try {
      const response = await fetch(`${apiEndpoints.API_TOPIC_URL}/${topicId}/attach-file`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-USER-ID': currentUserId.toString(),
        },
        body: JSON.stringify({
          fileName,
          mimeType,
          base64FileContent: fileBuffer,
        }),
      });

      if (!response.ok) {
        console.error('Error uploading file.');
        return;
      }

      const updatedTopic = await response.json();
      if (replaceOldTopicByUpdatedTopic) {
        replaceOldTopicByUpdatedTopic(updatedTopic);
      }

      setFileName('');
      setFileBuffer(null);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  return (
    <>
      <input
        type="file"
        ref={fileRef}
        name="attachFile"
        hidden
        accept={acceptedFileTypes}
        onChange={handleFileChange}
      />
      <Button
        startIcon={<FileUploadOutlined />}
        onClick={() => {
          const fileInput = fileRef.current as HTMLInputElement;
          if (fileInput) fileInput.click();
        }}
      >
        Attach Document
      </Button>
      {fileName && (
        <>
          <Chip
            onDelete={() => {
              resetFileUpload();
              if (onFileChange) {
                onFileChange(null, '', '');
              }
            }}
            sx={{ marginLeft: 1 }}
            label={fileName}
            color="info"
          />
          {topicId && currentUserId && (
            <Fab
              color="error"
              size="small"
              sx={{ marginLeft: 1, marginRight: 1 }}
              onClick={startUpload}
            >
              <FileUploadOutlined fontSize="small" />
            </Fab>
          )}
        </>
      )}
    </>
  );
};

export default FileAttachment;
