/* eslint-disable react/no-unused-prop-types */
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStylesCsvExel = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      flexGrow: 1,
      '& > *': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
    buttonContainer: {
      alignItems: 'flex-end',
      '& > *': {
        margin: theme.spacing(3),
      },
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    control: {
      padding: theme.spacing(2),
    },
    paper: {
      // textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      width: '95%',
    },
    formControl1: {
      minWidth: 120,
    },
    paper1: {
      // color: theme.palette.text.secondary,
      width: '99%',
      border: '1px solid #002A45',
      color: '#002A45',
      fontWeight: 'bold',
      background: '#FFFFFF 0% 0% no-repeat padding-box',
    },
    paper2: {
      // color: theme.palette.text.secondary,
      width: '99%',
      marginTop: 20,
    },
    treeitem: {
      width: '95%',
    },
    radiobtn: {
      textAlign: 'left',
    },

    thead: {
      backgroundColor: 'red',
    },
    formControlMultiFile: {
      margin: theme.spacing(1),
      minWidth: 180,
      maxWidth: 180,
    },
    textField: {
      // color: '#002A45 !important',
      backgroundColor: '#EEF1F9',
      borderRadius: '5px !important',
      '& .MuiInputBase-input': {
        // '&&:before': {
        //   borderBottom: 'none',
        // },
        // '&&:after': {
        //   borderBottom: 'none',
        // },
        color: '#002A45 !important',
        fontWeight: 'bold',
        border: '1px solid #002A45',
        borderRadius: 4,
      },
      '& .MuiFilledInput-underline:after': {
        borderBottom: 'none',
      },
      '& .MuiFilledInput-underline:before': {
        borderBottom: 'none',
      },
      '& .MuiFormLabel-root': {
        color: '#002A45 !important',
        fontWeight: 'bold',
      },
      '& .MuiFilledInput-root': {
        // position: 'relative',
        // backgroundColor: 'rgba(0, 0, 0, 0.09) !important',
        // borderTopLeftRadius: '4px !important',
        // borderTopRightRadius: '4px !important',
        // borderRadius: '4px !important',
        borderBottomLeftRadius: '4px !important',
        borderBottomRightRadius: '4px !important',
      },
    },
    textFieldSecond: {
      // color: '#002A45 !important',
      backgroundColor: '#EEF1F9',
      borderRadius: '5px !important',
      '& .MuiInputBase-input': {
        // '&&:before': {
        //   borderBottom: 'none',
        // },
        // '&&:after': {
        //   borderBottom: 'none',
        // },
        color: '#002A45 !important',
        fontWeight: 'bold',
      },
      '& .MuiFilledInput-underline:after': {
        borderBottom: 'none',
      },
      '& .MuiFilledInput-underline:before': {
        borderBottom: 'none',
      },
      '& .MuiFormLabel-root': {
        color: '#002A45 !important',
        fontWeight: 'bold',
      },
      '& .MuiFormLabel-asterisk.MuiInputLabel-asterisk': {
        // these are the classes used from material-ui library for the asterisk element
        // '&::after': {
        //   color: 'red', // add your text here
        // },
        color: 'red',
      },
      '& .MuiFilledInput-root': {
        // position: 'relative',
        // backgroundColor: 'rgba(0, 0, 0, 0.09) !important',
        // borderTopLeftRadius: '4px !important',
        // borderTopRightRadius: '4px !important',
        // borderRadius: '4px !important',
      },
      borderBottomLeftRadius: '4px !important',
    },
    multiSelectText: {
      // border: '1px solid red',

      // '&.rmsc .dropdown-heading': {
      //   height: '50px',
      // },
      '&.rmsc .dropdown-container': {
        border: '1px solid #002A45',
      },
    },
  })
);
export default useStylesCsvExel;
