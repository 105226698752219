/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-no-undef */
import React, { useEffect, useState, useContext } from 'react';
import {
  Button,
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Avatar,
  IconButton,
  Typography,
  makeStyles,
} from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import { isEmpty } from '@antv/util';
import MultiSelect from 'react-multi-select-component';
import { createUserProfile, updateUserProfile } from '../../../api/module1';
import m1 from '../../../assets/m1_blue.png';
import m2 from '../../../assets/m2_blue.png';
import m3 from '../../../assets/m3_blue.png';
import m4 from '../../../assets/m4_blue.png';
import m5 from '../../../assets/m5_blue.png';
import { ENV } from '../../../const';
import Sixlayer from './Sixlayer';
import M2GlobalContext from '../../ContextMager/M2GlobalContext';

const useStyles = makeStyles((theme: Theme) => ({
  multiselect: {
    '& .select-panel': {
      // overflowY: 'scroll',
      maxHeight: '22.5vh',
      overflowX: 'hidden',
    },
    '& .options': {
      overflowY: 'unset',
    },
  },
}));

function Usercustomprofile({ userId, profileData, open, setOpen, setUserSetting }) {
  const selClasses = useStyles();
  const { state, dispatch } = useContext(M2GlobalContext);
  const { similarityFilter } = state;
  const { dropDown1, dropDown2, dropDown3, dropDown4, dropDown5, dropDown6 } = similarityFilter;

  const optionsM1: any[] = [{ label: 'Smart Requirement', value: 'Smart Requirement' }];

  const optionsM2: any[] = [
    { label: 'Translator', value: 'Translator' },
    // { label: 'Text Analysis', value: 'Text Analysis' },
    // { label: 'Document Analysis', value: 'Document Analysis' },
    { label: 'Knowledge360', value: 'Knowledge360' },
    { label: 'GenA', value: 'GenA' },
    { label: 'Rex360', value: 'Rex360' },
  ];

  const optionsM3: any[] = [
    { label: 'iDCA', value: 'iDCA', disabled: !(ENV !== 'production' && ENV !== 'test') },
    { label: 'iQualimeter', value: 'iQualimeter' },
    { label: 'iVerifier', value: 'iVerifier' },
    {
      label: 'iTestGenerator',
      value: 'iTestGenerator',
      // disabled: !(ENV !== 'production' && ENV !== 'test'),
    },
  ];

  const optionsM4: any[] = [{ label: 'iTracer', value: 'iTracer' }];

  const optionsM5: any[] = [{ label: 'Safety', value: 'Safety' }];

  const optionsM6: any[] = [{ label: 'Schedule Analysis', value: 'Schedule Analysis' }];

  const [value1, setValue1] = useState<any[]>(profileData.length === 0 ? optionsM1 : []);
  const [value2, setValue2] = useState<any[]>(profileData.length === 0 ? optionsM2 : []);
  const [value3, setValue3] = useState<any[]>(
    profileData.length === 0 ? [{ label: 'iQualimeter', value: 'iQualimeter' }] : []
  );
  const [value4, setValue4] = useState<any[]>([]);
  const [value5, setValue5] = useState<any[]>([]);
  const [value6, setValue6] = useState<any[]>([]);

  useEffect(() => {
    if (profileData.length > 0) {
      // console.log('data', profileData);
      profileData.map((data: any) => {
        if (data) {
          setValue1(data?.m1);
          setValue2(data?.m2);
          setValue3(data?.m3);
          setValue4(data?.m4);
          setValue5(data?.m5);
          setValue6(data?.m6);
          dispatch({ type: 'change_simi_entity', payload: data?.layer1 ?? [] });
          dispatch({ type: 'change_simi_Platform', payload: data?.layer2 ?? [] });
          dispatch({ type: 'change_simi_Project', payload: data?.layer3 ?? [] });
          dispatch({ type: 'change_simi_DocumentProfile', payload: data?.layer4 ?? [] });
          dispatch({ type: 'change_simi_Solution', payload: data?.layer5 ?? [] });
          dispatch({ type: 'change_simi_Product', payload: data?.layer6 ?? [] });
          dispatch({ type: 'change_ProductLine_rex', payload: data?.layer1 ?? [] });
          dispatch({ type: 'change_RexPlatForm', payload: data?.layer2 ?? [] });
          dispatch({ type: 'change_Project_rex', payload: data?.layer3 ?? [] });
          // dispatch({ type: 'change_simi_DocumentProfile', payload: data?.layer4 ?? [] });
          dispatch({ type: 'change_Solution_rex', payload: data?.layer5 ?? [] });
          dispatch({ type: 'change_Product_rex', payload: data?.layer6 ?? [] });

          const obj = {
            productLinefilt1: data?.layer1 ?? [],
            filterPlatformsfilt2: data?.layer2 ?? [],
            filterProjectsfilt3: data?.layer3 ?? [],
            filterDocumentProfilesfilt4: data?.layer4 ?? [],
            filterSolutionfilt6: data?.layer5 ?? [],
            filterProductfilt7: data?.layer6 ?? [],
          };
          const obj2 = {
            filterexPlatform: data?.layer2 ?? [],
            // added
            filterrexProductLine: data?.layer1 ?? [],
            filterrexProduct: data?.layer6 ?? [],
            filterrexSolution: data?.layer5 ?? [],
            filterrexProject: data?.layer3 ?? [],
          };
          // console.log(obj);

          // dispatch({ type: 'set_db_custom_filter', payload: obj });
          dispatch({ type: 'set_db_Rex_custom_filter', payload: obj2 });
        }
      });
    }
  }, [profileData]);

  const handleClose = () => {
    if (profileData.length !== 0) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  const renderM1 = () => {
    if (!value1.length) {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar alt="Module 1" style={{ height: 30, width: 30, marginRight: '5px' }} src={m1} />
          <b style={{ color: '#002A45' }}>Smart Requirement Managment</b>
        </div>
      );
    }
  };

  const renderM2 = () => {
    if (!value2.length) {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar alt="Module 2" style={{ height: 30, width: 30, marginRight: '5px' }} src={m2} />
          <b style={{ color: '#002A45' }}>Knowledge & Efficiency</b>
        </div>
      );
    }
  };

  const renderM3 = () => {
    if (!value3.length) {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar alt="Module 3" style={{ height: 30, width: 30, marginRight: '5px' }} src={m3} />
          <b style={{ color: '#002A45' }}>Verification & Quality</b>
        </div>
      );
    }
  };

  const renderM4 = () => {
    if (!value4.length) {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar alt="Module 4" style={{ height: 30, width: 30, marginRight: '5px' }} src={m4} />
          <b style={{ color: '#002A45' }}>Performance & Maturity</b>
        </div>
      );
    }
  };

  const renderM5 = () => {
    if (!value5.length) {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar alt="Module 5" style={{ height: 30, width: 30, marginRight: '5px' }} src={m5} />
          <b style={{ color: '#002A45' }}>Safety & Cyber Security</b>
        </div>
      );
    }
  };

  const renderM6 = () => {
    if (!value6.length) {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar alt="Module 5" style={{ height: 30, width: 30, marginRight: '5px' }} src={m5} />
          <b style={{ color: '#002A45' }}>Project Management</b>
        </div>
      );
    }
  };

  const clearFilter = () => {
    dispatch({ type: 'clear_simiFilters' });
    dispatch({ type: 'clear_isearch_filter' });
  };

  const clearModules = () => {
    setValue1([]);
    setValue2([]);
    setValue3([]);
    setValue4([]);
    setValue5([]);
    setValue6([]);
  };

  const saveUserProfile = async () => {
    const date = new Date();
    const formattedDate = date.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });
    const body = {
      profiles: [
        {
          m1: value1,
          m2: value2,
          m3: value3,
          m4: value4,
          m5: value5,
          m6: value6,
          layer1: dropDown1,
          layer2: dropDown2,
          layer3: dropDown3,
          layer4: dropDown4,
          layer5: dropDown5,
          layer6: dropDown6,
        },
      ],
      user_id: userId,
      created_at: formattedDate,
    };

    // { m1: value1, m2: value2, m3: value3, m4: value4, m5: value5, m6: value6 };
    let updated_data;
    if (isEmpty(profileData)) {
      updated_data = await createUserProfile(body, userId);
    } else {
      updated_data = await updateUserProfile(body, userId);
    }
    setOpen(false);
    setUserSetting(updated_data?.profiles);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        PaperProps={{
          style: {
            maxHeight: 'unset', // or remove this line if you want default behavior
          },
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: '#002A45',
            color: 'white',
            height: '20px',
          }}
        >
          <DialogTitle style={{ m: 0, p: 2 }}>
            User Service Customization(*please select the services you would like to use)
          </DialogTitle>
          <IconButton aria-label="close" onClick={handleClose} style={{ color: 'white' }}>
            <CloseIcon />
          </IconButton>
        </div>
        <div style={{ backgroundColor: '#EEf1F9' }}>
          <DialogContent style={{ maxHeight: '500px', height: '258px', paddingTop: '10px' }}>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '40px' }}>
              <FormControl variant="outlined" style={{ minWidth: '170px', maxWidth: '170px' }}>
                <MultiSelect
                  isOpen={true}
                  options={optionsM1 ?? []}
                  value={value1 ?? []}
                  onChange={(newValue: any) => setValue1(newValue)}
                  labelledBy="Smart Requirement Managment"
                  valueRenderer={renderM1}
                  disableSearch={true}
                />
              </FormControl>

              <FormControl variant="outlined" style={{ minWidth: '180px', maxWidth: '180px' }}>
                <MultiSelect
                  // className={selClasses.multiselect}
                  isOpen={true}
                  options={optionsM2 ?? []}
                  value={value2 ?? []}
                  onChange={(newValue: any) => setValue2(newValue)}
                  labelledBy="Knowledge & Efficiency"
                  valueRenderer={renderM2}
                  disableSearch={true}
                />
              </FormControl>

              <FormControl variant="outlined" style={{ minWidth: '160px', maxWidth: '160px' }}>
                <MultiSelect
                  isOpen={true}
                  options={optionsM3 ?? []}
                  value={value3 ?? []}
                  onChange={(newValue: any) => setValue3(newValue)}
                  labelledBy="Verification & Quality"
                  valueRenderer={renderM3}
                  disableSearch={true}
                />
              </FormControl>

              <FormControl variant="outlined" style={{ minWidth: '160px', maxWidth: '160px' }}>
                <MultiSelect
                  isOpen={true}
                  options={optionsM4 ?? []}
                  value={value4 ?? []}
                  onChange={(newValue: any) => setValue4(newValue)}
                  labelledBy="Performance & Maturity"
                  valueRenderer={renderM4}
                  disabled={!(ENV !== 'production' && ENV !== 'test')}
                  disableSearch={true}
                />
              </FormControl>

              <FormControl variant="outlined" style={{ minWidth: '160px', maxWidth: '160px' }}>
                <MultiSelect
                  isOpen={true}
                  options={optionsM5 ?? []}
                  value={value5 ?? []}
                  onChange={(newValue: any) => setValue5(newValue)}
                  labelledBy="Safety & Cyber Security"
                  valueRenderer={renderM5}
                  disabled={!(ENV !== 'production' && ENV !== 'test')}
                  disableSearch={true}
                />
              </FormControl>

              <FormControl variant="outlined" style={{ minWidth: '170px', maxWidth: '170px' }}>
                <MultiSelect
                  isOpen={true}
                  options={optionsM6 ?? []}
                  value={value6 ?? []}
                  onChange={(newValue: any) => setValue6(newValue)}
                  labelledBy="Project Management"
                  valueRenderer={renderM6}
                  disableSearch={true}
                />
              </FormControl>
            </div>
          </DialogContent>
          {/* <DialogActions>
            <Button
              style={{ backgroundColor: 'green', color: 'white' }}
              variant="contained"
              size="small"
              color="default"
              onClick={() => clearModules()}
            >
              Clear Services
            </Button>
          </DialogActions> */}
          <DialogContent style={{ maxHeight: '500px', height: '250px' }}>
            <div style={{ borderBottom: 'solid 1px lightgray', marginBottom: '20px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  justifyContent: 'space-between',
                  // gap: 50,
                }}
              >
                <Typography style={{ marginBottom: '5px', fontWeight: 'bolder', fontSize: '20px' }}>
                  Select below details tailored to your needs:
                </Typography>
                <div>
                  <Button
                    style={{ backgroundColor: 'green', color: 'white' }}
                    variant="contained"
                    size="small"
                    color="default"
                    onClick={() => clearModules()}
                  >
                    Clear Services
                  </Button>
                </div>
              </div>
            </div>
            <div
              style={{ display: 'flex', flexDirection: 'row', gap: '40px', overflow: 'visible' }}
            >
              <Sixlayer />
            </div>
          </DialogContent>
          <div style={{ display: 'flex', flexDirection: 'row-reverse', gap: '10px' }}>
            <DialogActions>
              <Button variant="contained" color="primary" onClick={() => saveUserProfile()}>
                Submit
              </Button>
            </DialogActions>
            <DialogActions>
              <Button
                style={{ backgroundColor: 'green', color: 'white' }}
                variant="contained"
                color="default"
                onClick={() => clearFilter()}
              >
                Clear Filter
              </Button>
            </DialogActions>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default Usercustomprofile;
