// import React, { useState } from 'react';
// import {
//   Box,
//   Tabs,
//   Tab,
//   Table,
//   TableBody,
//   TableCell,
//   TableHead,
//   TableRow,
//   Checkbox,
//   TextField,
//   IconButton,
// } from '@mui/material';
// import SaveIcon from '@mui/icons-material/Save';
// import EditIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/Delete';
// import DownloadIcon from '@mui/icons-material/Download';

// const AllocationTable = () => {
//   const [selectedTab, setSelectedTab] = useState(0);
//   const [filterText, setFilterText] = useState('');

//   const handleTabChange = (event, newValue) => {
//     setSelectedTab(newValue);
//   };

//   // Data
//   const rows = [
//     {
//       requirement:
//         'The Signaling and Train Control System shall be designed, manufactured, supplied, installed, tested, and commissioned.',
//       experts: 'ATC, ATS',
//     },
//     {
//       requirement:
//         'The Contractor shall produce a life cycle cost plan in accordance with IEC 60300-3-3 or requirements.',
//       experts: 'ATC, IXL',
//     },
//     {
//       requirement:
//         'The Signaling and Train Control System shall be designed, manufactured, supplied, installed, tested, and commissioned.',
//       experts: 'ILS, Safety, Quality, RAM',
//     },
//   ];

//   // Filter rows based on input
//   const filteredRows = rows.filter((row) =>
//     row.requirement.toLowerCase().includes(filterText.toLowerCase())
//   );

//   return (
//     <Box>
//       {/* Tabs */}
//       <Box sx={{ display: 'flex', alignItems: 'center', bgcolor: 'black', color: 'white', p: 1 }}>
//         <Tabs
//           value={selectedTab}
//           onChange={handleTabChange}
//           textColor="inherit"
//           TabIndicatorProps={{ style: { backgroundColor: 'white' } }}
//         >
//           <Tab label="Labels" sx={{ textTransform: 'none', fontWeight: 'bold' }} />
//           <Tab label="Training Data" sx={{ textTransform: 'none', fontWeight: 'bold' }} />
//         </Tabs>
//         <Box sx={{ ml: 'auto', display: 'flex', gap: 1 }}>
//           <IconButton color="inherit">
//             <SaveIcon />
//           </IconButton>
//           <IconButton color="inherit">
//             <EditIcon />
//           </IconButton>
//           <IconButton color="inherit">
//             <DeleteIcon />
//           </IconButton>
//           <IconButton color="inherit">
//             <DownloadIcon />
//           </IconButton>
//         </Box>
//       </Box>

//       {/* Filter */}
//       <Box sx={{ p: 2 }}>
//         <TextField
//           placeholder="Filter"
//           variant="outlined"
//           fullWidth
//           value={filterText}
//           onChange={(e) => setFilterText(e.target.value)}
//           sx={{ bgcolor: 'white', borderRadius: 1 }}
//         />
//       </Box>

//       {/* Table */}
//       <Box sx={{ p: 2 }}>
//         <Table sx={{ border: '1px solid black', bgcolor: 'black', color: 'white' }}>
//           <TableHead>
//             <TableRow>
//               <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Requirement Text</TableCell>
//               <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Expert Allocation</TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {filteredRows.map((row, index) => (
//               <TableRow key={index} sx={{ bgcolor: 'white', color: 'black' }}>
//                 <TableCell>
//                   <Checkbox /> {row.requirement}
//                 </TableCell>
//                 <TableCell>{row.experts}</TableCell>
//               </TableRow>
//             ))}
//           </TableBody>
//         </Table>
//       </Box>
//     </Box>
//   );
// };

// export default AllocationTable;
// import React, { useState } from 'react';
// import {
//   Grid,
//   Table,
//   TableHeaderRow,
//   TableEditRow,
//   TableEditColumn,
// } from '@devexpress/dx-react-grid-material-ui';
// import { EditingState } from '@devexpress/dx-react-grid';

// const AllocationTable = () => {
//   // Initial rows
//   const [rows, setRows] = useState([
//     {
//       id: 0,
//       requirement:
//         'The Signaling and Train Control System shall be designed, manufactured, supplied, installed, tested, and commissioned.',
//       experts: 'ATC, ATS',
//     },
//     {
//       id: 1,
//       requirement:
//         'The Contractor shall produce a life cycle cost plan in accordance with IEC 60300-3-3 or requirements.',
//       experts: 'ATC, IXL',
//     },
//     {
//       id: 2,
//       requirement:
//         'The Signaling and Train Control System shall be designed, manufactured, supplied, installed, tested, and commissioned.',
//       experts: 'ILS, Safety, Quality, RAM',
//     },
//   ]);

//   // Columns for the table
//   const columns = [
//     { name: 'requirement', title: 'Requirement Text' },
//     { name: 'experts', title: 'Expert Allocation' },
//   ];

//   // Editing State Handlers
//   const commitChanges = ({ added, changed, deleted }) => {
//     let updatedRows = [...rows];

//     if (added) {
//       const startingId = rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
//       updatedRows = [...rows, { id: startingId, ...added[0] }];
//     }
//     if (changed) {
//       updatedRows = updatedRows.map((row) =>
//         changed[row.id] ? { ...row, ...changed[row.id] } : row
//       );
//     }
//     if (deleted) {
//       updatedRows = updatedRows.filter((row) => !deleted.includes(row.id));
//     }

//     setRows(updatedRows);
//   };

//   return (
//     <div style={{ padding: '20px' }}>
//       <Grid rows={rows} columns={columns}>
//         {/* Enable Editing */}
//         <EditingState onCommitChanges={commitChanges} />

//         {/* Table Components */}
//         <Table />
//         <TableHeaderRow />
//         <TableEditRow />
//         <TableEditColumn showAddCommand showEditCommand showDeleteCommand />
//       </Grid>
//     </div>
//   );
// };

// export default AllocationTable;
/* eslint-disable react/button-has-type */
import { Box, Button, Grid, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import TranslateIcon from '@mui/icons-material/Translate';
// import FileTranslator from './FileTranslator';
import './Css/Schedule.css';

import LabelTable from './LabelTable';
import TrainingTable from './TrainingTable';
import { getTemplatTableData } from '../../../api/allocationNew';
// import ScheduleAnalysis from './ScheduleAnalysis';
// import TabContainer from './Core/CustomerRFP/TabContainer';

const mode = {
  Labels: { left: '0%' },
  Training_Data: { left: '50%' },
};
const SupportedFiles = [
  'xlsx',
  'csv',
  'pdf',
  'docx',
  'pptx',
  'ppt',
  'htm',
  'msg',
  'xls',
  'xlf',
  'html',
  'markdown',
  'mdown',
  'mkdn',
  'md',
  'mkd',
  'mdwn',
  'mdtxt',
  'mdtext',
  'rmd',
  'mthml',
  'mht',
  'doc',
  'odt',
  'odp',
  'ods',
  'rtf',
  'tsv',
  'tab',
  'txt',
];
const EditTemplateTab = () => {
  const [tab, setTab] = useState('Labels');
  // const queryParams = window.location.pathname;
  // const getLastPath = queryParams.split('/').pop();
  const history = useHistory();
  const [languageCode, setLanguageCode] = useState([]);
  const [files, setFiles] = useState([]);
  const { projectId, moduleroute } = useParams() as any;
  const [glossaryFiles, setGlossaryFiles] = useState([]);
  const [labelTableData, setLabelTableData] = useState([]);
  const [trainingTableData, setTrainingTableData] = useState([]);

  return (
    <>
      <br />
      <br />
      <Box display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          color="primary"
          onClick={history.goBack}
          style={{ marginRight: 10 }}
          size="small"
        >
          Back
        </Button>
      </Box>
      <Grid container style={{ marginTop: 10 }}>
        <Box className="Translator-home">
          <div className="Translator-home-button-Box">
            <div style={mode[tab]} className="Translator-home-btn" />
            <button
              type="button"
              className="Translator-home-toggleBtn"
              style={mode[tab]?.left === '0%' ? { color: 'white' } : {}}
              onClick={() => setTab('Labels')}
            >
              {/* <TranslateIcon /> */}
              Labels
            </button>
            <button
              type="button"
              className="Translator-home-toggleBtn"
              style={mode[tab]?.left === '50%' ? { color: 'white' } : {}}
              onClick={() => setTab('Training_Data')}
            >
              {/* <DocumentScannerIcon /> */}
              Training Data
            </button>
          </div>
          {/* {console.log('labels', tab, mode)} */}

          {tab === 'Labels' && <LabelTable />}
          {tab === 'Training_Data' && <TrainingTable />}
        </Box>
      </Grid>
    </>
  );
};

export default EditTemplateTab;
