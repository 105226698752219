import { MaterialReactTable } from 'material-react-table';
import { ThemeProvider } from '@mui/material/styles';
import React, { useContext, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Module1Context from '../../../../../Module1Context';
import useLoadResponse from '../../Hooks/useLoadResponse';
import FindSimilarity from '../../Modal/FindSimilarity';
import AllocationExtraction, {
  AnalysisAllocationExtraction,
} from '../../utils/allocationExtraction';
import theme from '../../Theme/AssginTheme';
import Loading from '../../components/Loading';
import AssignModalKpi from '../../AssignModalKpi';
import useLoadAnalysisData from '../Hooks/useLoadAnalysisData';
import ProjectContext from '../../../../../../Project/ProjectContext';
import useAnalysis from '../Hooks/useAnalysis';
import useAnalysisTable from '../Hooks/useAnalysisTable';
import useProjectData from '../../Hooks/useProjectData';
import useAllDataTable from '../../Hooks/useAllDataTable';
import ModalAnalysis from '../../Modal/ModalAnalysis';
import ModalKpi from '../../ModalKpi';
import AllocationHistory from '../../Modal/AllocationHistory';

const AnalysisFlow = ({
  filename,
  workloadId,
  actionForFile,
  setActionForFile,
  originalText,
  setOriginalText,
  levelsHierarchy,
  targetColumn,
  displayColumns,
  langCheck,
  entity,
  dispatch,
  isRegExp,
  languageOriginCode,
  languages,
  onChange,
  urlFile,
}) => {
  const { setSnack, project, translationCode, setTranslationCode, languagesCode } = useContext(
    Module1Context
  );
  const { userJWT, rbsLabels } = useContext(ProjectContext);
  const [metaData, setMetaDAta] = useState({});
  const [toggleTable, setToogleTable] = useState(true);
  const [isFullScreen, setFullScreen] = useState(false);
  const { projectId } = useParams() as any;
  const [i18n, setI18n] = useState();
  const [modalData, setModalDAta] = useState({
    findSimilarity: { open: false, requirements: [], keys: [] },
    kpis: { open: false, requirements: [], keys: [] },
    Analysis: { open: false, requirements: [], keys: [] },
    allocationHistory: { open: false, requirements: [], keys: [] },
  });

  const { findSimilarity, kpis, Analysis, allocationHistory } = modalData;

  const { groups, users } = useProjectData({ project });

  const {
    dataLoading,
    percentage,
    refreshDATA,
    anlayisRows,
    projectGroups,
    refreshLoading,
  } = useLoadAnalysisData({
    projectId,
    setNotify: setSnack,
    metaData,
    targetColumn,
    userId: userJWT?.user_id,
    workloadId,
    filename,
    langCheck,
    languagesCode,
    translationCode,
    setTranslationCode,
    setMetaDAta,
    setI18n,
    dispatch,
    setActionForFile,
    actionForFile,
    groups,
    users,
    onChange,
    displayColumns,
  });

  const handleModalClose = (newState) => {
    setModalDAta((prev) => {
      return {
        ...prev,
        ...newState,
      };
    });
  };

  const allDataTable = useAllDataTable({
    rows: metaData,
    toggleTable,
    onChangeToggle: setToogleTable,
    onChangeFullScreen: setFullScreen,
    isFullScreen,
    targetColumn,
    displayColumns,
    refreshLoading,
    groups,
    users,
    langCheck,
    languagesCode,
    languages,
    translationCode,
    projectId,
    workloadId,
    setMetaDAta,
    transCode: translationCode,
    setTranslationCode,
    onChangeModalData: setModalDAta,
    languageOriginCode,
    i18n,
    setNotify: setSnack,
    levelsHierarchy,
    filename,
    refreshDATA,
    projectGroups,
    service: 'analysis',
    user_id: userJWT?.user_id,
    urlFile,
  });

  const analysisTable = useAnalysisTable({
    rows: anlayisRows,
    targetColumn,
    onChangeToggle: setToogleTable,
    toggleTable,
    isFullScreen,
    refreshLoading,
    onChangeFullScreen: setFullScreen,
    onChangeModalData: setModalDAta,
    user_id: userJWT?.user_id,
    projectId,
    workloadId,
    filename,
    setMetaDAta,
    refreshDATA,
    metaData,
    project,
    setNotify: setSnack,
    displayColumns,
    levelsHierarchy,
    type: filename.split('.').reverse()[0] ?? '',
  });

  const handleCloseAllocationHistory = () => {
    setModalDAta((prev) => {
      return {
        ...prev,
        allocationHistory: { open: false, requirements: [], keys: [] },
      };
    });
  };

  const renderTable = () => {
    return toggleTable ? (
      <MaterialReactTable table={allDataTable} />
    ) : (
      <MaterialReactTable table={{ ...analysisTable }} />
    );
  };

  return (
    <>
      {' '}
      <ThemeProvider theme={theme}>
        <div
          style={
            isFullScreen
              ? {
                  bottom: 0,
                  height: '100vh',
                  left: 0,
                  margin: 0,
                  maxHeight: '100vh',
                  maxWidth: '100vw',
                  padding: 0,
                  position: 'fixed',
                  right: 0,
                  top: 0,
                  width: '100vw',
                  zIndex: 1299,
                  backgroundColor: 'white',
                }
              : {}
          }
        >
          {dataLoading ? (
            <div style={{ padding: '0px 5px', backgroundColor: '#EEF1F9' }}>{renderTable()}</div>
          ) : (
            <Loading percentage={percentage} />
          )}

          {findSimilarity.open && (
            <FindSimilarity
              open={findSimilarity.open}
              onClose={() => {
                handleModalClose({ findSimilarity: { open: false, requirements: [], keys: [] } });
              }}
              rowsSim={findSimilarity.requirements}
              t
              targetColumn={targetColumn}
            />
          )}

          {kpis.open && (
            <ModalKpi
              project_id={projectId}
              workload_id={workloadId}
              file_name={filename}
              user_id={userJWT.user_id}
              title={filename}
              onClose={() => {
                handleModalClose({ kpis: { open: false, requirements: [], keys: [] } });
              }}
            />
          )}

          {Analysis.open && (
            <ModalAnalysis
              open={Analysis.open}
              onClose={() => {
                handleModalClose({ Analysis: { open: false, requirements: [], keys: [] } });
              }}
              requirements={Analysis.requirements}
              selectedIds={Analysis.keys}
              workloadId={workloadId}
              targetColumn={targetColumn}
              filename={filename}
              project={project}
              projectId={projectId}
              setNotify={setSnack}
              userId={userJWT?.user_id}
              setMetaDAta={setMetaDAta}
              langCheck={langCheck}
              languagesCode={languagesCode}
              metaData={metaData}
              urlFile={urlFile}
              type={filename.split('.').reverse()[0] ?? ''}
            />
          )}

          {allocationHistory.open && (
            <AllocationHistory
              handleClose={handleCloseAllocationHistory}
              open={allocationHistory.open}
              project_id={projectId}
              workload_id={workloadId}
              rows_allocation={allocationHistory.keys}
            />
          )}
        </div>
      </ThemeProvider>
    </>
  );
};

export default AnalysisFlow;
