import {
  AttachFile,
  DeleteForever,
  DownloadOutlined,
  FileUploadOutlined,
} from '@mui/icons-material';
import { IconButton, Tooltip, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';
import { ITopic } from '../../../interfaces/ITopic';
import { apiEndpoints } from '../../../utils/apiEndpoints';
import { getFileBase64Promise } from '../../../utils/fileUtil';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import useAxiosAuth from '../../../API/axios';

interface AttachmentManagerProps {
  userRole: 'admin' | 'normal';
  currentUserId: number;
  topicId: number;
  creatorId: number;
  topicTitle: string;
  fileName?: string;
  mimeType?: string;
  replaceOldTopicByUpdatedTopic: (updatedTopic: ITopic) => void;
}

const AttachmentManager: React.FC<AttachmentManagerProps> = ({
  fileName,
  mimeType,
  topicId,
  topicTitle,
  currentUserId,
  userRole,
  creatorId,
  replaceOldTopicByUpdatedTopic,
}) => {
  const { AxiosCall, state } = useAxiosAuth();

  const fileRef = useRef<HTMLInputElement | null>(null);
  const [isOpenRemoveDoc, setIsOpenRemoveDoc] = useState(false);
  const [isOpenReplaceDoc, setIsOpenReplaceDoc] = useState(false);

  const acceptedFileTypes = "";

  const hasAttachment = () => fileName && mimeType;

  const userCanRemoveExistingOrUploadDoc = () =>
    userRole === 'admin' || creatorId === currentUserId;

  const verifyUploadDoc = () => {
    if (hasAttachment()) {
      setIsOpenReplaceDoc(true);
      return;
    }

    chooseFileToUpload();
  };

  const chooseFileToUpload = () => {
    const fileInput = fileRef.current as HTMLInputElement;
    if (fileInput) fileInput.click();
  };

  const startUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      const fileToUpload =
        fileRef && fileRef.current && fileRef.current.files && fileRef.current.files.length
          ? fileRef.current.files[0]
          : null;
      if (fileToUpload) {
        // const safeFileName = encodeURIComponent(truncateText(e.target.files[0].name, 15, "end").trim().replace(/\s/g, ""));
        const safeFileName = e.target.files[0].name;
        getFileBase64Promise(fileToUpload).then(async (base64Content) => {
          try {
            // const response = await fetch(
            //   `${apiEndpoints.API_TOPIC_URL}/${topicId}/attach-file`,
            //   {
            //     method: "PATCH",
            //     body: JSON.stringify({
            //       fileName: safeFileName,
            //       mimeType: fileToUpload.type,
            //       base64FileContent: base64Content,
            //     }),
            //   }
            // );

            const payload = {
              url: `${apiEndpoints.API_TOPIC_URL}/${topicId}/attach-file`,
              method: 'PATCH',
              data: JSON.stringify({
                fileName: safeFileName,
                mimeType: fileToUpload.type,
                base64FileContent: base64Content,
              }),
            };
            const response = await AxiosCall(payload);

            if (!response.ok) {
              console.error('Error uploading file.');
              return;
            }

            const updatedTopic = await response.json();
            replaceOldTopicByUpdatedTopic(updatedTopic);
            setIsOpenReplaceDoc(false);
          } catch (error) {
            console.error('Error uploading file:', error);
          }
          e.target.value = ''; // Normally, choosing the same file will not fire the onChange event, hence we need to clear this target value
        });
      }
    }
  };

  const downloadAttachment = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    try {
      const token = state?.jwtTokenDetails?.accessToken || JSON.parse(localStorage.getItem('appJWT'))?.access_token;

      const response = await fetch(`${apiEndpoints.API_TOPIC_URL}/${topicId}/download`, {
        headers: {
          Authorization: `Bearer ${token.replace(/"/g, '')}`,
        },
      });

      //   const payload = {
      //     url: `${apiEndpoints.API_TOPIC_URL}/${topicId}/download`,
      //     responseType: "blob",
      //     method: "GET",
      //   };
      //   const response = await AxiosCall(payload);
      //   console.log(response);

      if (!response.ok) {
        throw new Error('Failed to download file');
      }

      // Extract the filename from Content-Disposition header
      const disposition = response.headers.get('Content-Disposition');
      const filename = disposition
        ? disposition.split('filename=')[1].replace(/"/g, '')
        : 'downloaded_file';

      // Create a blob from the response
      const blob = await response.blob();
      //   const blob = new Blob([response.data], { type: response.data.type });

      // Create a link element, set the download attribute, and trigger a click to download the file
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  const handleRemoveAttachment = async () => {
    try {
      //   const response = await fetch(
      //     `${apiEndpoints.API_TOPIC_URL}/${topicId}/remove-attachment`,
      //     {
      //       method: "DELETE",
      //       // headers: {
      //       //     'X-USER-ID': currentUserId.toString()
      //       // },
      //     }
      //   );

      const payload = {
        url: `${apiEndpoints.API_TOPIC_URL}/${topicId}/remove-attachment`,
        method: 'DELETE',
      };
      const response = await AxiosCall(payload);

      if (!response.ok) {
        console.error('Error removing attachment.');
        return;
      }

      const updatedTopic = await response.json();
      replaceOldTopicByUpdatedTopic(updatedTopic);
      setIsOpenRemoveDoc(false);
    } catch (error) {
      console.error('Error removing attachment:', error);
    }
  };
  return (
    <React.Fragment>
      <>
        <AttachFile />
        <Typography style={{ cursor: 'pointer' }} display="inline" onClick={downloadAttachment}>
          {fileName}
        </Typography>
        <br />
      </>
      {/* {userCanRemoveExistingOrUploadDoc() && (
        <>
          <Tooltip title="Upload document">
            <IconButton color="primary" onClick={verifyUploadDoc}>
              <FileUploadOutlined />
            </IconButton>
          </Tooltip>
          <ConfirmationModal
            dialogTitle="Attention !"
            dialogMessage={
              <span>
                Attention, attaching a new file will replace the existing one.
                Do you want to continue?
              </span>
            }
            open={isOpenReplaceDoc}
            onYesClick={chooseFileToUpload}
            onClose={() => setIsOpenReplaceDoc(false)}
          />
        </>
      )} */}
      {/* {hasAttachment() && (
        <>
          <Tooltip title="Download document">
            <IconButton color="primary" onClick={downloadAttachment}>
              <DownloadOutlined />
            </IconButton>
          </Tooltip>
          {userCanRemoveExistingOrUploadDoc() && (
            <>
              <Tooltip title="Remove document">
                <IconButton
                  color="error"
                  onClick={() => setIsOpenRemoveDoc(true)}
                >
                  <DeleteForever />
                </IconButton>
              </Tooltip>
              <ConfirmationModal
                dialogTitle="Remove document"
                dialogMessage={
                  <span>
                    You are about to remove an attachment of the topic `
                    {topicTitle}`.
                    <br />
                    Are you sure ?
                  </span>
                }
                open={isOpenRemoveDoc}
                onYesClick={handleRemoveAttachment}
                onClose={() => setIsOpenRemoveDoc(false)}
              />
            </>
          )}
        </>
      )} */}
    </React.Fragment>
  );
};

export default AttachmentManager;
