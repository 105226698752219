import React from 'react';
// import { Outlet } from 'react-router-dom';
import Header from './header/header';
// import { useEffect, useState } from "react";
// import { ITopicNotification } from "../interfaces/ITopicNotification";
// import ConsecutiveSnackbars from "../components/ui/ConsecutiveSnackbars/ConsecutiveSnackbars";

interface AppLayoutProps {
  user: 'admin' | 'normal';
  setUser: React.Dispatch<React.SetStateAction<'normal' | 'admin'>>;
  currentUserId: number;
  setCurrentUserId: React.Dispatch<React.SetStateAction<number>>;
}

const AppLayout: React.FC<AppLayoutProps> = ({
  user,
  setUser,
  currentUserId,
  setCurrentUserId,
  children,
}) => {
  // const [notifications, setNotifications] = useState<ITopicNotification[]>([]);

  // useEffect(() => {
  //   fetch(`${apiEndpoints.API_TOPIC_NOTIFICATION_URL}`, {
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'X-USER-ID': currentUserId.toString()
  //     },
  //   })
  //     .then(res => res.json())
  //     .then(setNotifications);
  // }, [currentUserId]);

  return (
    <div>
      <Header
        user={user}
        setUser={setUser}
        currentUserId={currentUserId}
        setCurrentUserId={setCurrentUserId}
      />
      <main className="min-h-screen">
        {/* <ConsecutiveSnackbars notifications={notifications} currentUserId={currentUserId} /> */}
        {/* <Outlet /> */}
        {children}
      </main>
    </div>
  );
};

export default AppLayout;
