/* eslint-disable prefer-template */
/* eslint-disable import/prefer-default-export */
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import AppLayout from '../layouts/app-layout';
import { HomePage } from '../pages';
import { apiEndpoints } from '../utils/apiEndpoints';
import useAxiosAuth from '../API/axios';
import { useAppContext } from '../components/appContextProvider/AppContextProvider';

export const Router = () => {
  const [user, setUser] = useState<'normal' | 'admin'>('normal');
  const [userInfo, setUserInfo] = useState<any>({});
  const [currentUserId, setCurrentUserId] = useState<number>(0);
  const [currentUserFullName, setCurrentUserFullName] = useState('');
  const [currentUserEmail, setCurrentUserEmail] = useState('');
  const [currentUserFunction, setCurrentUserFunction] = useState('');
  const [currentUserDepartment, setCurrentUserDepartment] = useState('');
  const { AxiosCall } = useAxiosAuth();
  const { dispatch } = useAppContext();

  useEffect(() => {
    try {
      const payload = {
        url: `${apiEndpoints.API_USER_URL}`,
        method: 'GET',
      };
      AxiosCall(payload).then((userInfo) => {
        dispatch({ type: 'SET_USER', payload: userInfo });
        setUserInfo(userInfo);
        setCurrentUserId(Number(userInfo.id));
        setCurrentUserFullName(userInfo.firstName + ' ' + userInfo.lastName);
        setCurrentUserEmail(userInfo.email);
        setCurrentUserFunction(userInfo.function);
        setCurrentUserDepartment(userInfo.department);
        if (userInfo?.userRole?.id === 1) {
          setUser('admin');
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  // return (
  //   <>
  //     <HomePage
  //       user={user}
  //       userInfo={userInfo}
  //       currentUserId={currentUserId}
  //       currentUserFullName={currentUserFullName}
  //       currentUserEmail={currentUserEmail}
  //       currentUserFunction={currentUserFunction}
  //       setCurrentUserFunction={setCurrentUserFunction}
  //       currentUserDepartment={currentUserDepartment}
  //       setCurrentUserDepartment={setCurrentUserDepartment}
  //     />
  //   </>
  // );

  return (
    <>
      <AppLayout
        user={user}
        setUser={setUser}
        currentUserId={currentUserId}
        setCurrentUserId={setCurrentUserId}
      >
        <HomePage
          user={user}
          userInfo={userInfo}
          currentUserId={currentUserId}
          currentUserFullName={currentUserFullName}
          currentUserEmail={currentUserEmail}
          currentUserFunction={currentUserFunction}
          setCurrentUserFunction={setCurrentUserFunction}
          currentUserDepartment={currentUserDepartment}
          setCurrentUserDepartment={setCurrentUserDepartment}
        />
      </AppLayout>
    </>
  );
};
