import React, { useContext, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Select,
  TextField,
  Tooltip,
  FormLabel,
  CircularProgress,
  Paper,
} from '@material-ui/core';
import { isEmpty } from '@antv/util';

import PublishIcon from '@mui/icons-material/Publish';
import DescriptionIcon from '@material-ui/icons/Description';
import { DataGrid, GridColDef, GridRowsProp, GridToolbar } from '@mui/x-data-grid';
import { useHistory } from 'react-router-dom';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRight from '@mui/icons-material/ArrowRight';
import { textToText } from '../../../api/module2';
import {
  get_template_file,
  get_workbench,
  getAllTemplate,
  post_icapture_createTemplate,
  post_workbench,
  uploadURLCall,
} from '../../../api/allocationNew';
import useAppToken from '../../../app/useAppToken';
import Module1Context from '../../Module1/Module1Context';
import ProjectContext from '../../Project/ProjectContext';
import { BACKEND_URL } from '../../../const';
import { iCaptureExcel } from '../../../api/module1';
import axios from '../../../api/axios';

const useStyles = makeStyles({
  root: {
    marginTop: 60,
    marginLeft: 20,
    width: '90%',
    borderRadius: 10,
    height: 200,
  },
  root1: {
    marginTop: 0,
    marginLeft: 20,
    width: '90%',
    borderRadius: 10,
    height: 500,
  },
  root2: {
    marginTop: 40,
    marginLeft: 20,
    width: '90%',
    borderRadius: 10,
    height: 40,
  },
  content: {
    backgroundColor: '#002A45',
    height: 25,
  },
  title: {
    marginTop: -10,
    fontSize: 14,
    opecity: 1,
    color: 'white',
    fontWeight: 'bold',
  },
  pos: {
    marginBottom: 12,
  },
});

const columns: GridColDef[] = [
  { field: 'id', headerName: 'Id', width: 80 },
  { field: 'name', headerName: 'Name', width: 300 },
  { field: 'template_config_id', headerName: 'Template Id', width: 200 },
  { field: 'multilabel', headerName: 'Multilabel', width: 150 },
];

export default function AllocationUpload() {
  const { appJWT } = useAppToken();
  const history = useHistory();
  const inputRef = useRef();
  const { setSnack } = useContext(ProjectContext);
  const classes = useStyles();
  const queryParams = window.location.pathname;
  const id = queryParams.split('/')[2];

  const [paramId, setParamId] = useState(id);
  const [iconLoad, setIconload] = useState(false);

  const [uploadedAPIResult, setUploadedAPIResult] = useState<any>('');
  const [checkAState, setCheckAState] = useState<boolean>(false);
  const [activityName, setActivityName] = useState<any>('');
  const [getAllTemplateAPI, setGetAllTemplateAPI] = useState<any>([]);
  const [loadingTable, setLoadingTable] = useState<boolean>(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);

  const [submitLoader, setSubmitLoader] = useState<boolean>(false);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    getTemp();
  }, []);

  function getTemp() {
    getAllTemplate(paramId)
      .then((res) => {
        setGetAllTemplateAPI(res);
        // console.log('res', res);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleChangeCheckedA = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckAState(event.target.checked);
    // console.log('checkAState', event.target.checked);
  };
  const filehandleChangeFiles = (event: any) => {
    const e = event?.target?.files || '';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    handleChangeFiles(e);
  };

  const handleChangeFiles = (event: any) => {
    try {
      const len = Object?.keys(event)?.length;
      const arraylist = [];
      for (let i = 0; i < len; i += 1) {
        arraylist.push(event[i]);
      }
      for (const eachFile of arraylist) {
        const ext = eachFile?.name?.split('.');
        if (!['xlsx'].includes(ext[ext?.length - 1])) {
          setSnack(`${eachFile.name} not supported`, 'error');
          return;
        }
        if (eachFile.name?.length >= 80) {
          setSnack('file name should be less than 80 characters', 'error');
          return;
        }
      }

      const formdata = new FormData();
      const names = arraylist.map((e: any) => {
        formdata.append('files', e);
        return e.name;
      });
      for (const eachFile of names) {
        // const fileNameWithoutExt = eachFile.split('.')[0];
        // console.log('fileNameWithoutExt', fileNameWithoutExt, fileNameWithoutExt.match(format));
        // if (fileNameWithoutExt.match(format)) {
        //   setSnack(`File name ${eachFile} contains special character not allowed`, 'error');
        //   return;
        // }
        // if (names.includes(eachFile)) {
        //   setSnack(`File name ${eachFile} already exists.`, 'error');
        //   return;
        // }
        // for (const eachFiles of files) {
        //   if (eachFiles.filename === filenameToCheck) {
        //     setSnack(`File name ${filenameToCheck} already exists.`, 'error');
        //     return;
        //   }
        // }
      }

      if (formdata.get('files')) {
        setIconload(true);
        const uploadUrl = '';
        uploadURLCall(uploadUrl, formdata)
          .then((result) => {
            // console.log('result: ', typeof result);
            // console.log('result: ', result);
            setIconload(false);
            if (typeof result === 'string') {
              setSnack(result, 'error');
              setUploadedAPIResult('');
            } else {
              setUploadedAPIResult(result);
              setSnack('Uploaded successfully!', 'success');
            }
          })
          .catch((err) => {
            setUploadedAPIResult('');
            console.log(err);
          });
      }
    } catch (e) {
      console.log(e);
    }
  };

  function passforCapture() {
    const names = getAllTemplateAPI.map((item) => item.name);
    // console.log(names);
    if (
      activityName.trim() === null ||
      activityName.trim() === '' ||
      activityName.trim() === undefined
    ) {
      setSnack('Please enter the Template Name', 'error');
    } else if (names.includes(activityName)) {
      setSnack('Template Name already exists. Please enter another name', 'error');
    } else if (activityName.trim().length > 15) {
      setSnack('Template Name should be less than 15 characters', 'error');
    } else {
      setSubmitLoader(true);
      iCaptureExcel({
        FileName: uploadedAPIResult?.filename,
        FilePath: uploadedAPIResult?.filename,
        Container: uploadedAPIResult?.container,
        BlobName: uploadedAPIResult?.filename,
        FileType: 'xlsx',
        Sheet: 'Sheet1',
        TargetColumn: 'text',
        ColumnNames: ['text', 'label'],
        Header: true,
        NameOfCapture: activityName,
      })
        .then((res) => {
          // console.log('res capture', res);
          if (res?.message === 'Non-Extractable') {
            setSnack('File is Non-Extractable. Please select another file', 'error');
            if (res?.error !== undefined) {
              setSnack(res?.error, 'error');
            }
            setSubmitLoader(false);
          } else {
            post_icapture_createTemplate({
              dataframe: {
                Text: res.output.Text,
              },
              multilabel: checkAState,
              template_config_id: paramId,
              name: activityName,
            })
              .then((res1) => {
                // console.log('res1: ', res1);
                setSnack('Template creation processed => moving page to next step', 'success');
                getTemp();
                history.push(
                  `/editTemplate/${res1?.labels[0]?.template_id || res1?.training[0]?.template_id}`
                ); // redirect to edit template page
              })
              .catch((err) => {
                console.log(err);
                setSnack(
                  'File is extractable but not able to create template. Upload another file',
                  'error'
                );
              })
              .finally(() => {
                setSubmitLoader(false);
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  const handleSelectionModelChange = (newSelection) => {
    setSelectedIds(newSelection);
  };

  const editTemplate = () => {
    if (selectedIds.length === 0) {
      setSnack('Plese select a template', 'error');
    } else {
      history.push(`/editTemplate/${selectedIds}`);
    }
  };

  const testTemplate = () => {
    if (selectedIds.length === 0) {
      setSnack('Plese select a template', 'error');
    } else {
      history.push(`/allocationTest/${selectedIds}`);
      localStorage.setItem('allocationUpload', `/allocationTest/${selectedIds}`);
    }
  };

  const getFile = async () => {
    try {
      // Set the snack message before starting the download
      setSnack('Downloading the sample template file...', 'info');
      // Call the get_template_file API to get the file URL or content
      const response = await axios.get(
        `${BACKEND_URL.root}/download/samplefile/template/allocation`,
        {
          responseType: 'blob', // Important for handling binary data
        }
      );
      // Create a link element
      const link = document.createElement('a');
      const url = window.URL.createObjectURL(new Blob([response.data]));
      link.href = url;
      link.setAttribute('download', 'sample_template_file.xlsx'); // Specify the file name
      // Append the link to the body
      document.body.appendChild(link);
      // Programmatically click the link to trigger the download
      link.click();
      // Remove the link from the DOM
      link.parentNode?.removeChild(link);
      // Clear the snack message after the download starts
    } catch (error) {
      console.error('Error downloading the template file:', error);
      setSnack('Error downloading the template file', 'error');
    }
  };

  return (
    <>
      <div>
        <div style={{ float: 'right', marginRight: 20, marginTop: 10 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              history.push('/allocationActivity');
            }}
          >
            Allocation Activity
          </Button>
        </div>
      </div>
      <Card className={classes.root}>
        <CardContent className={classes.content}>
          <div>
            <div style={{ float: 'left' }}>
              <Typography className={classes.title} color="textSecondary" gutterBottom>
                Create Template{' '}
              </Typography>
            </div>
            <div style={{ float: 'right' }}>
              <Typography className={classes.title} color="textSecondary" gutterBottom>
                {/* <span style={{ color: 'white' }}>Sample file</span> */}
                <span>
                  <Button onClick={getFile} style={{ color: 'white' }}>
                    Sample File
                  </Button>
                </span>
                <ArrowLeftIcon
                  fontSize="large"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    history.push(`/allocation`);
                  }}
                />
                <ArrowRight
                  fontSize="large"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    history.push(localStorage.getItem('allocationUpload'));
                  }}
                />
              </Typography>
            </div>
          </div>
          <Grid>
            <Tooltip title="upload file">
              <FormLabel
                style={{
                  marginTop: '2rem',
                  margin: 'auto',
                  backgroundColor: 'White',
                  boxShadow: '0px 4px 8px #00000029',
                  width: '3rem',
                  color: '#002A45',
                  height: '3rem',
                  borderRadius: '50%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                {iconLoad ? (
                  <CircularProgress style={{ color: '#002A45' }} size={10} />
                ) : (
                  <PublishIcon />
                )}
                <input
                  type="file"
                  //   multiple
                  ref={inputRef}
                  hidden
                  onChange={filehandleChangeFiles}
                />
              </FormLabel>
            </Tooltip>
          </Grid>
          {uploadedAPIResult && (
            <Grid style={{ padding: 10, marginTop: 5 }}>
              <Typography>
                <b>Uploaded File details:</b>
              </Typography>
              <Grid container style={{ width: '80%', marginLeft: 20, marginTop: 40 }}>
                <Grid xs={4} style={{ marginTop: 8 }} spacing={2}>
                  <DescriptionIcon /> <b>{uploadedAPIResult?.filename}</b>
                </Grid>
                <Grid xs={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkAState}
                        onChange={handleChangeCheckedA}
                        name="checkedA"
                      />
                    }
                    label="Multi Allocation"
                  />
                </Grid>
                <Grid xs={4}>
                  <TextField
                    id="outlined-basic"
                    label="Template Name"
                    variant="outlined"
                    style={{ marginTop: -10 }}
                    onChange={(evt) => {
                      setActivityName(evt.target.value);
                    }}
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {submitLoader ? (
                    <CircularProgress size={20} style={{ color: '#002A45' }} />
                  ) : (
                    <Button variant="contained" color="secondary" onClick={passforCapture}>
                      Submit
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card>
      <Card className={classes.root2}>
        <CardContent className={classes.content}>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            <b>Select from already created Template List: </b>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Total Rows:{' '}
            {getAllTemplateAPI.length}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Button variant="contained" color="secondary" size="small" onClick={testTemplate}>
              Test
            </Button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Button variant="contained" color="default" size="small" onClick={editTemplate}>
              Edit
            </Button>
          </Typography>
        </CardContent>
      </Card>
      <Card className={classes.root1}>
        {loadingTable && (
          <h4>
            <span style={{ color: 'red' }}>Loading...</span>
          </h4>
        )}
        {!loadingTable && (
          <DataGrid
            rows={getAllTemplateAPI}
            columns={columns}
            checkboxSelection
            selectionModel={selectionModel}
            disableMultipleRowSelection
            onRowSelectionModelChange={(newSelection) => handleSelectionModelChange(newSelection)}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
          />
        )}
      </Card>
    </>
  );
}
