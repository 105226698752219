/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/button-has-type */
/**
 * This component renders of a Isearcher Text analysis comp of the page
 * new api iSearcherSearchCPE changes done by @Ranjan @Rahul for the People, content , Expert search
 * Css also fixed here
 * Ui changes done by @Ranjan @Rahul
 * @param {string} props
 * @returns {ReactNode} A React element that renders Isearcher Text analysis component of the page.
 *  Copyright @ Alstom 2023
 *  auther @Ranjan
 */
import {
  Box,
  Button,
  IconButton,
  InputBase,
  List,
  Paper,
  Popover,
  Typography,
  Tooltip,
  Slider,
} from '@material-ui/core';
import clsx from 'clsx';
import { isEmpty } from '@antv/util';
import React, { useContext, useEffect, useState } from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  useParams,
  useHistory,
} from 'react-router-dom';
import GroupsIcon from '@mui/icons-material/Groups';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import ImageIcon from '@mui/icons-material/Image';
import QuestionAnswerOutlinedIcon from '@material-ui/icons/QuestionAnswerOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined';
import ShortcutIcon from '@mui/icons-material/Shortcut';
import SearchIcon from '@mui/icons-material/Search';
import MultiSelect from 'react-multi-select-component';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import '../ResultContainer/RexResultsContainer/transitionCss.css';
import { ContainerStyle, ListItem } from '../Workspace/Components/style/ContainerStyle';
// import FindSimilarTexts from './Components/FindSimilarTexts';
import {
  iNeriSearcher,
  text2Dataset,
  iSearcherSearch,
  iQnA,
  getGenerateImages,
  iSearcherSearchCPE,
} from '../../api/module2';
import { getAllProjectCreationLanguages } from '../../api/projects';
// import ContentSearchResult from './Components/ContentSearchResult';
// import ExpertSearch from './Components/ExpertSearch';
// // import Remind from '../coredata/Remind';
// import QnAGenerator from './Components/QnAGenerator';
// import AskQuestion from './Components/AskQuestion';
import { BACKEND_URL, ENV } from '../../const';
import Axios from '../../api/axios';
import LoadingApp from '../../components/Core/Loading';
// import PeopleSearch from './Components/PeopleSearch';
// import Ontology from './Components/Ontology';
// import INer from '../INer';
// import GenerateImages from './Components/GenerateImages';
// import Module1Context from '../../../Module1/Module1Context';
import NoResultsFound from '../../components/Core/NoResultsFound';
import rexSvgIcon from '../../assets/rexicons/rexSvgIcon.svg';
import { updateShortcuts } from '../../api/shortcuts';
import useAppToken from '../../app/useAppToken';
// import Module2Context from '../../Module2Context';
import Filters from '../../components/Filter/Components/Filters';
import M2GlobalContext from '../ContextMager/M2GlobalContext';
import INer from '../Module2/NewModule/INer';
import FindSimilarTexts from '../ResultContainer/TextAnalysisResultContainer/FindSimilarTexts';
import ContentSearchResult from '../ResultContainer/TextAnalysisResultContainer/ContentSearchResult';
import ExpertSearch from '../ResultContainer/TextAnalysisResultContainer/ExpertSearch';
import AskQuestion from '../ResultContainer/TextAnalysisResultContainer/AskQuestion';
import PeopleSearch from '../ResultContainer/TextAnalysisResultContainer/PeopleSearch';
import QnAGenerator from '../ResultContainer/TextAnalysisResultContainer/QnAGenerator';
import GenerateImages from '../ResultContainer/TextAnalysisResultContainer/GenerateImages';
import Ontology from '../ResultContainer/TextAnalysisResultContainer/Ontology';

const developmentArray = [
  'Content Search',
  'Similarity Search',
  'Expert Search',
  'People Search',
  'Ask Question',
  'Summarize Text',
  // 'Translate',
  'Ontology',
  'Generate Images',
  // 'REX 360',
];

const productionArray = [
  'Content Search',
  'Similarity Search',
  'Expert Search',
  'People Search',
  // 'Ask Question',
  // 'REX 360',
  // 'Translate',
  // 'Ontology',
];
const RexIcon = () => {
  return (
    <>
      <img src={rexSvgIcon} alt="rex" />
    </>
  );
};

const iconstabs = [
  <QueryStatsIcon />,
  <AssignmentOutlinedIcon />,
  <PsychologyOutlinedIcon />,
  <GroupsIcon />,
  <QuestionAnswerOutlinedIcon />,
  <QuestionAnswerIcon />,
  // <TranslateOutlinedIcon />,
  <AssessmentOutlinedIcon />,
  <ImageIcon />,
  <RexIcon />,
];

const productionIconstabs = [
  <QueryStatsIcon />,
  <AssignmentOutlinedIcon />,
  <PsychologyOutlinedIcon />,
  <GroupsIcon />,
  // <QuestionAnswerOutlinedIcon />,
  <RexIcon />,
  <QuestionAnswerIcon />,
  // <TranslateOutlinedIcon />,
  <AssessmentOutlinedIcon />,
  <ImageIcon />,
  // <RexIcon />,
];

const developmentToolTip = [
  'Find information from the database',
  'Find similar text in meaning from the database',
  'Find specialists of a given skill',
  'Search for the specification writers',
  'Find domain related technical information',
  'Extract Question-Answer Pairs from Text',
  // 'Translate the text to other langauges',
  'Find relationships between concepts, data and entities',
  'Generate images',
  // 'REX 360',
];

const productionToolTip = [
  'Find information from the database',
  'Find similar text in meaning from the database',
  'Find specialists of a given skill',
  'Search for the specification writers',
  // 'Find domain related technical information',
  // 'REX 360',
  // 'Translate the text to other langauges',
  // 'Find relationships between concepts, data and entities',
];

const RexButtons = [
  'REX',
  'Requirements',
  'Experts',
  'Tests',
  'Change Requests',
  'Lean Six Sigma',
  'APSYS',
  'REX Book',
];

const Link = React.forwardRef<HTMLAnchorElement, RouterLinkProps>(function Link(itemProps, ref) {
  return <RouterLink ref={ref} {...itemProps} role={undefined} />;
});

function TextAnalysisMode({ setReset }) {
  // TextAnalsis operation states
  const classes = ContainerStyle();
  const { appJWT } = useAppToken();
  const history = useHistory();
  // const { clicked, setClicked, points, setPoints, eachShort, setEachShort } = useContextMenu();
  // const { shortCuts, setShortCuts } = useContext(ProjectContext);
  const { state, dispatch } = useContext(M2GlobalContext);
  const { rexFilter, isearcherFilter, similarityFilter } = state;
  const {
    issureSiteDef,
    rexPlatformDef,
    rexSourceDef,
    rexStatusDef,
    rexTypologydef,
    issureSite,
    rexPlatform,
    rexSource,
    rexStatus,
    rexTypology,
  } = rexFilter;
  // console.log('issuer', issureSite);

  const {
    productLine,
    productLineDef,
    filterProjectsDef,
    filterProjects,
    filterDocumentProfilesDef,
    filterDocumentProfiles,
    filterPlatformsDef,
    filterPlatforms,
    filterSolutionDef,
    filterProductDef,
    filterCountryDef,
    artiFact,
    productLinefilt1,
    filterPlatformsfilt2,
    filterProjectsfilt3,
    filterDocumentProfilesfilt4,
    filt5,
    filterSolutionfilt6,
    filterProductfilt7,
    filt8,
    maxResultsValue,
    checkedA,
    door,
  } = isearcherFilter;

  // console.log('door', door);

  const {
    simi_AlstomEntitiesDef,
    simi_AlstomEntities,
    simi_FilterProjectsDef,
    simi_FilterProjects,
    simi_FilterDocumentProfilesDef,
    simi_FilterDocumentProfiles,
    simi_FilterPlatformsDef,
    simi_FilterPlatforms,
    simi_FilterSolutionDef,
    simi_FilterProductDef,
    dropDown1,
    dropDown2,
    dropDown3,
    dropDown4,
    dropDown5,
    dropDown6,
    countValue,
    threshold,
  } = similarityFilter;

  const [opArray, setOpArray] = useState([
    'Content Search',
    'Similarity Search',
    'Expert Search',
    'People Search',
    'Ask Question',
    'Summarize Text',
    // 'Translate',
    'Ontology',
    'Generate Images',
    // 'REX 360',
  ]);
  const [toolTipCont, setToolTipCont] = useState([
    'Find information from the database',
    'Find similar text in meaning from the database',
    'Find specialists of a given skill',
    'Search for the specification writers',
    'Find domain related technical information',
    // 'Translate the text to other langauges',
    'Find relationships between concepts, data and entities',
  ]);
  // const [remind, setRemind] = useState();
  const { projectId, userId } = useParams() as any;
  const [errorMsg, setErrorMsg] = useState(false);
  const [remidtext, setRemidText] = useState('');
  const [operation, setOperation] = useState(0);
  const [inputValue, setInputValue] = useState('');
  const [langResult, setLangResult] = useState('');
  const queryParams = new URLSearchParams(window.location.search);
  const actionIs = queryParams.get('service');
  // const [callOperation, setcallOperation] = useState('Content Search');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [callOperation, setCallOperation] = useState('Content Search');
  const [showTranslation, setShowTranslation] = useState();
  const [showTransParent, setShowTransParent] = useState(false);
  const [display, setDisplay] = useState(false);
  const [load, setLoad] = useState(false);
  const [images, setImages] = useState('');
  const [REXData, setREXdata] = useState();
  const [showRexButton, setShowRexButton] = useState(false);
  const [showIcons, setShowIcons] = useState(iconstabs);

  // content search payload
  const [contentSearchPayload, setContentSearchPayload] = useState();

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [countResults, setCountResult] = useState([]);
  const [similarTextRow, setSimilarTextRow] = useState<any>([]);

  // common  states to Content Search, Expert Search
  const [rows, setRows] = React.useState([]);
  const [language, setLanguage] = React.useState();
  const [detectLanguage, setDetectLanguage] = React.useState();
  const [timeTaken, setTimeTaken] = useState<any>();
  const [totalResult, setTotalResult] = useState<any>();

  // common graph states to Content Search, Expert Search, People Search
  const [alstomEntityWiseDistribution, setAlstomEntityWiseDistribution] = useState<any>([]);
  const [platformWiseDistribution, setPlatformWiseDistribution] = useState<any>([]);
  const [projectWiseDistribution, setProjectWiseDistribution] = useState<any>([]);
  const [documentProfileWiseDistribution, setDocumentProfileWiseDistribution] = useState<any>([]);
  const [solutionWiseDistribution, setSolutionWiseDistribution] = useState<any>([]);
  const [countryWiseDistribution, setCountryWiseDistribution] = useState<any>([]);
  const [productWiseDistribution, setProductWiseDistribution] = useState<any>([]);
  const [artifactWiseDistribution, setArticatWiseDistribution] = useState<any>([]);
  const [searchPayload, setSearchPayload] = useState<any>();
  const [displaySmart, setDisplaySmart] = useState(false);

  // Expert Search States
  const [expertDomain, setExpertDomain] = useState<any>([]);
  const [smartData, setSmartData] = useState<any>();
  const [TotalPeopleResult, setTotalPeopleResult] = useState<any>();
  const [payloadMain, setPayloadMain] = useState() as any;
  const [artifactKpi, setArtifactKpi] = useState<any>([]);

  // Pople search state
  const [payloadPeopleSearch, setPayloadPeopleSearch] = React.useState<any>();
  const [oldPayload, setOldPayload] = useState() as any;

  // Ask Question Use States
  const [askQuesRes, setAskQuesRes] = useState();
  const [apiData, setApiData] = useState();
  const [url, seturl] = useState('');

  // translate
  const [langaugeValue, setLangaugeValue] = React.useState('');
  const [languageCode, setLanguageCode] = useState([]);

  // required for Iner new API call
  const [ent, setEnt] = useState<any>();
  const [proj, setProj] = useState<any>();
  const [plat, setPlat] = useState<any>();
  const [docpro, setDocpro] = useState<any>();
  const [artPro, setArtPro] = useState<any>();
  const [inerDisplay, setInerDisplay] = useState<any>(false);
  const [loadIner, setLoadIner] = useState<any>(false);
  const [iNerUsableData, setINerUsableData] = useState<any>();
  // const { setSnack } = useContext(Module1Context);

  // Changing permission for Production and development
  useEffect(() => {
    if (ENV !== 'production' && ENV !== 'test') {
      setOpArray(developmentArray);
      setToolTipCont(developmentToolTip);
      setShowIcons(iconstabs);
    } else {
      setOpArray(productionArray);
      setToolTipCont(productionToolTip);
      setShowIcons(productionIconstabs);
    }
    Axios.post(`${BACKEND_URL.root}/modules/pipe/m2-onto-iframe`, {}).then((response) => {
      seturl(response.data);
    });

    // APi call for languagecode
    getAllProjectCreationLanguages().then((dbProjects) => {
      setLangaugeValue(dbProjects);
      const db = dbProjects.map((item) => {
        return `${item.language}-${item.codes}`;
      });
      setLanguageCode(db);
    });
  }, []);

  /* This useEffect for selceting the service when user redirect from Home page
     it will check the actionIS from params, and select the service
   */
  useEffect(() => {
    for (let index = 0; index < opArray.length; index += 1) {
      const RouteName = opArray[index].toLowerCase().replace(/ /g, '');
      if (RouteName === actionIs) {
        setCallOperation(opArray[index]);
        setOperation(index);
        break;
      }
    }
  }, [opArray, actionIs]);

  // Calling api when user move one tab to another
  useEffect(() => {
    if (
      callOperation === 'Ontology' ||
      callOperation === 'Summarize Text' ||
      callOperation === 'REX 360' ||
      callOperation === 'REX Book'
    ) {
      handleApiCall();
    }
  }, [callOperation]);
  // Api Call for Find SimilarText
  function FindSimilarText(
    inputValue,
    countValue,
    dropDown1,
    dropDown2,
    dropDown3,
    dropDown4,
    dropDown5,
    dropDown6
  ) {
    // language is harded changes done on 06th jan 2022
    let entityIs: any = [];
    let platform: any = [];
    let project: any = [];
    let documentProfile: any = [];
    let solution: any = [];
    let product: any = [];
    dropDown1?.forEach((obj: any) => {
      entityIs.push(obj.value);
    });
    dropDown2?.forEach((obj: any) => {
      platform.push(obj.value);
    });
    dropDown3?.forEach((obj: any) => {
      project.push(obj.value);
    });
    dropDown4?.forEach((obj: any) => {
      documentProfile.push(obj.value);
    });
    dropDown5?.forEach((obj: any) => {
      solution.push(obj.value);
    });
    dropDown6?.forEach((obj: any) => {
      product.push(obj.value);
    });

    if (entityIs.length === 0 || entityIs.length === simi_AlstomEntitiesDef.length) {
      entityIs = ['*'];
    }
    if (platform.length === 0 || platform.length === simi_FilterPlatformsDef.length) {
      platform = ['*'];
    }
    if (project.length === 0 || project.length === simi_FilterProjectsDef.length) {
      project = ['*'];
    }
    if (
      documentProfile.length === 0 ||
      documentProfile.length === simi_FilterDocumentProfilesDef.length
    ) {
      documentProfile = ['*'];
    }

    if (solution.length === 0 || solution.length === simi_FilterSolutionDef.length) {
      solution = ['*'];
    }

    if (product.length === 0 || product.length === simi_FilterProductDef.length) {
      product = ['*'];
    }
    text2Dataset(
      inputValue,
      countValue,
      'All',
      entityIs,
      platform,
      project,
      documentProfile,
      solution,
      product,
      threshold,
      projectId ?? undefined
    )
      .then((payload: any) => {
        const checkData = JSON.parse(payload.output.result_data);
        if (checkData.length === 0) {
          setDisplay(true);
          setErrorMsg(true);
        } else {
          if (errorMsg) setErrorMsg(false);
          const initRow = JSON.parse(payload.output.result_data);
          const thresholdFiltered = initRow.filter((initRow) => initRow.Score >= threshold);
          setSimilarTextRow(thresholdFiltered);
          setDisplay(true);
          if (payload.output.text_is_translated) {
            setLangResult(payload.output.translated_text);
          }
          setLanguage(payload.output.translated_language);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoad(false);
      });
  }

  // Api Call for generate images
  function generateImagesApiCall(inputValue) {
    getGenerateImages(inputValue)
      .then((data) => {
        setImages(data.output);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setDisplay(true);
        setLoad(false);
      });
  }

  // Api Call for Content Search, Expert search and People search
  function IsearcherApiCall(
    searchValue: any,
    inputValue: any,
    filt2: any,
    filt3: any,
    filt4: any,
    filt1: any,
    filt5: any,
    filt6: any,
    filt7: any,
    filt8: any,
    maxResultsValue: any,
    minResulstValue: any,
    doorValue: any,
    modeV: any
  ) {
    let entityIs: any = [];
    let platform: any = [];
    let project: any = [];
    let documentProfile: any = [];
    let artifactEnt: any = [];
    // @ ranjan
    let solution: any = [];
    let product: any = [];
    let country: any = [];
    filt1.forEach((obj: any) => {
      entityIs.push(obj.value);
    });
    filt2.forEach((obj: any) => {
      platform.push(obj.value);
    });
    filt3.forEach((obj: any) => {
      project.push(obj.value);
    });
    filt4.forEach((obj: any) => {
      documentProfile.push(obj.value);
    });
    filt6.forEach((obj: any) => {
      solution.push(obj.value);
    });
    filt7.forEach((obj: any) => {
      product.push(obj.value);
    });
    filt8.forEach((obj: any) => {
      country.push(obj.value);
    });
    filt5.forEach((obj: any) => {
      artifactEnt.push(obj.value);
    });
    const startTime = performance.now();
    if (entityIs.length === 0 || entityIs.length === productLineDef.length) {
      entityIs = ['*'];
    }
    if (platform.length === 0 || platform.length === filterPlatformsDef.length) {
      platform = ['*'];
    }
    if (project.length === 0 || project.length === filterProjectsDef.length) {
      project = ['*'];
    }
    if (artifactEnt.length === 0 || artifactEnt.length === artiFact.length) {
      artifactEnt = ['*'];
    }
    if (
      documentProfile.length === 0 ||
      documentProfile.length === filterDocumentProfilesDef.length
    ) {
      documentProfile = ['*'];
    }
    if (solution.length === 0 || solution.length === filterSolutionDef.length) {
      solution = ['*'];
    }
    if (product.length === 0 || product.length === filterProductDef.length) {
      product = ['*'];
    }
    if (country.length === 0 || country.length === filterCountryDef.length) {
      country = ['*'];
    }

    setEnt(entityIs);
    setPlat(platform);
    setProj(project);
    setDocpro(documentProfile);
    setArtPro(artifactEnt);
    // console.log(entityIs, platform, solution, product, project, documentProfile, country);

    iSearcherSearchCPE(
      searchValue,
      inputValue,
      platform,
      project,
      documentProfile,
      artifactEnt,
      solution,
      product,
      country,
      entityIs,
      maxResultsValue,
      minResulstValue,
      doorValue,
      checkedA,
      projectId ?? undefined
    )
      .then((payload: any) => {
        if (!payload) {
          setDisplay(true);
          setErrorMsg(true);
        } else if (searchValue === 'Query Search') {
          if (payload?.numberOfResultsFound === 0 && payload?.alstopedia.terms.length === 0) {
            setDisplay(true);
            setErrorMsg(true);
          } else {
            if (errorMsg) setErrorMsg(false);
            setContentSearchPayload(payload);
            setRows(payload?.searchResults);
            setLanguage(payload?.translation?.detectedLanguage);
            setDetectLanguage(payload?.translation?.translatedText);
            setShowTranslation(payload?.translation?.showTranslation);
            setLangResult(payload?.translation?.translatedText);
            setShowTransParent(true);

            const r: any = payload.searchResults;
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < r.length; i++) {
              // eslint-disable-next-line dot-notation
              r[i]['id'] = i;
            }
            // setINerUsableData(JSON.stringify(r));
            setSearchPayload(JSON.stringify(r));
            const endTime = performance.now();
            const t = ((endTime - startTime) / 3600).toFixed(2);
            setTimeTaken(t);
            const count1 = payload?.visualizationData?.productLineWiseDistribution?.['D&IS'] || 0;
            const count2 = payload?.visualizationData?.productLineWiseDistribution?.RSC || 0;
            const count = count1 + count2;
            setTotalResult(payload?.numberOfResultsFound);
            // for graph code starts here
            const keysAlstomEntityWiseDistribution = Object.keys(
              searchValue === 'Query Search'
                ? payload.visualizationData?.productLineWiseDistribution
                : payload.individualResults[
                    inputValue.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
                  ]?.visualizationData?.productLineWiseDistribution
            );
            const valuesAlstomEntityWiseDistribution = Object.values(
              searchValue === 'Query Search'
                ? payload.visualizationData.productLineWiseDistribution
                : payload.individualResults[
                    inputValue.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
                  ]?.visualizationData?.productLineWiseDistribution
            );
            const alstomEntityWiseDistribution_array: any = [];
            // eslint-disable-next-line no-plusplus
            for (let index = 0; index < keysAlstomEntityWiseDistribution.length; index++) {
              if (valuesAlstomEntityWiseDistribution[index] !== 0) {
                alstomEntityWiseDistribution_array.push({
                  name: keysAlstomEntityWiseDistribution[index],
                  value: valuesAlstomEntityWiseDistribution[index],
                });
              }
            }
            setAlstomEntityWiseDistribution(alstomEntityWiseDistribution_array);
            // second graph
            const keysPlatformWiseDistribution = Object.keys(
              searchValue === 'Query Search'
                ? payload.visualizationData.platformWiseDistribution
                : payload.individualResults[
                    inputValue.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
                  ]?.visualizationData?.platformWiseDistribution
            );
            const valuesPlatformWiseDistribution = Object.values(
              searchValue === 'Query Search'
                ? payload.visualizationData.platformWiseDistribution
                : payload.individualResults[
                    inputValue.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
                  ]?.visualizationData?.platformWiseDistribution
            );
            const platformWiseDistribution_array: any = [];
            // eslint-disable-next-line no-plusplus
            for (let index = 0; index < keysPlatformWiseDistribution.length; index++) {
              if (valuesPlatformWiseDistribution[index] !== 0) {
                platformWiseDistribution_array.push({
                  name: keysPlatformWiseDistribution[index],
                  value: valuesPlatformWiseDistribution[index],
                });
              }
            }
            setPlatformWiseDistribution(platformWiseDistribution_array);
            const keysProjectWiseDistribution = Object.keys(
              searchValue === 'Query Search'
                ? payload.visualizationData.projectWiseDistribution
                : payload.individualResults[
                    inputValue.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
                  ]?.visualizationData?.projectWiseDistribution
            );
            const valuesProjectWiseDistribution = Object.values(
              searchValue === 'Query Search'
                ? payload.visualizationData.projectWiseDistribution
                : payload.individualResults[
                    inputValue.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
                  ]?.visualizationData?.projectWiseDistribution
            );
            const projectWiseDistribution_array: any = [];
            // eslint-disable-next-line no-plusplus
            for (let index = 0; index < keysProjectWiseDistribution.length; index++) {
              if (valuesProjectWiseDistribution[index] !== 0) {
                projectWiseDistribution_array.push({
                  name: keysProjectWiseDistribution[index],
                  value: valuesProjectWiseDistribution[index],
                });
              }
            }
            setProjectWiseDistribution(projectWiseDistribution_array);
            // third graph documentProfileWiseDistribution
            const keysdocumentProfileWiseDistribution = Object.keys(
              searchValue === 'Query Search'
                ? payload.visualizationData.documentProfileWiseDistribution
                : payload.individualResults[
                    inputValue.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
                  ]?.visualizationData?.documentProfileWiseDistribution
            );
            const valuesdocumentProfileWiseDistribution = Object.values(
              searchValue === 'Query Search'
                ? payload.visualizationData.documentProfileWiseDistribution
                : payload.individualResults[
                    inputValue.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
                  ]?.visualizationData?.documentProfileWiseDistribution
            );
            const documentProfileWiseDistribution_array: any = [];
            // eslint-disable-next-line no-plusplus
            for (let index = 0; index < keysdocumentProfileWiseDistribution.length; index++) {
              if (valuesdocumentProfileWiseDistribution[index] !== 0) {
                documentProfileWiseDistribution_array.push({
                  name: keysdocumentProfileWiseDistribution[index],
                  value: valuesdocumentProfileWiseDistribution[index],
                });
              }
            }
            setDocumentProfileWiseDistribution(documentProfileWiseDistribution_array);
            // Fourth Graph
            const keysArtifactWiseDistribution = Object.keys(
              searchValue === 'Query Search'
                ? payload.visualizationData.artifactWiseDistribution
                : payload.individualResults[
                    inputValue.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
                  ]?.visualizationData?.artifactWiseDistribution
            );
            const valuesArtifactWiseDistribution = Object.values(
              searchValue === 'Query Search'
                ? payload.visualizationData.artifactWiseDistribution
                : payload.individualResults[
                    inputValue.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
                  ]?.visualizationData?.artifactWiseDistribution
            );
            const artifactWiseDistribution_array: any = [];
            // eslint-disable-next-line no-plusplus
            for (let index = 0; index < keysArtifactWiseDistribution.length; index++) {
              if (valuesArtifactWiseDistribution[index] !== 0) {
                artifactWiseDistribution_array.push({
                  name: keysArtifactWiseDistribution[index],
                  value: valuesArtifactWiseDistribution[index],
                });
              }
            }
            setArticatWiseDistribution(artifactWiseDistribution_array);
            // Fiffth graph documentProfileWiseDistribution
            const keysdocumentSolutionWiseDistribution = Object.keys(
              searchValue === 'Query Search'
                ? payload.visualizationData.solutionWiseDistribution
                : payload.individualResults[
                    inputValue.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
                  ]?.visualizationData?.solutionWiseDistribution
            );
            const valuesdocumentSolutionWiseDistribution = Object.values(
              searchValue === 'Query Search'
                ? payload.visualizationData.solutionWiseDistribution
                : payload.individualResults[
                    inputValue.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
                  ]?.visualizationData?.solutionWiseDistribution
            );
            const solutionWiseDistribution_array: any = [];
            // eslint-disable-next-line no-plusplus
            for (let index = 0; index < keysdocumentSolutionWiseDistribution.length; index++) {
              if (valuesdocumentSolutionWiseDistribution[index] !== 0) {
                solutionWiseDistribution_array.push({
                  name: keysdocumentSolutionWiseDistribution[index],
                  value: valuesdocumentSolutionWiseDistribution[index],
                });
              }
            }
            setSolutionWiseDistribution(solutionWiseDistribution_array);
            // sixth graph documentProfileWiseDistribution
            const keysProductWiseDistribution = Object.keys(
              searchValue === 'Query Search'
                ? payload.visualizationData.productWiseDistribution
                : payload.individualResults[
                    inputValue.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
                  ]?.visualizationData?.productWiseDistribution
            );
            const valuesProductWiseDistribution = Object.values(
              searchValue === 'Query Search'
                ? payload.visualizationData.productWiseDistribution
                : payload.individualResults[
                    inputValue.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
                  ]?.visualizationData?.productWiseDistribution
            );
            const productWiseDistribution_array: any = [];
            // eslint-disable-next-line no-plusplus
            for (let index = 0; index < keysProductWiseDistribution.length; index++) {
              if (
                valuesProductWiseDistribution[index] !== 0 &&
                keysdocumentSolutionWiseDistribution[index] &&
                valuesdocumentSolutionWiseDistribution[index]
              ) {
                productWiseDistribution_array.push({
                  name: keysdocumentSolutionWiseDistribution[index],
                  value: valuesdocumentSolutionWiseDistribution[index],
                });
              }
            }
            setProductWiseDistribution(productWiseDistribution_array);
            // Fiffth graph documentProfileWiseDistribution
            const keysCountryWiseDistribution: any = Object.keys(
              searchValue === 'Query Search'
                ? payload.visualizationData.countryWiseDistribution
                : payload.individualResults[
                    inputValue.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
                  ]?.visualizationData?.countryWiseDistribution
            );
            const valuesCountryWiseDistribution = Object.values(
              searchValue === 'Query Search'
                ? payload.visualizationData.countryWiseDistribution
                : payload.individualResults[
                    inputValue.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
                  ]?.visualizationData?.countryWiseDistribution
            );
            const countryWiseDistribution_array: any = [];
            // eslint-disable-next-line no-plusplus
            for (let index = 0; index < keysCountryWiseDistribution.length; index++) {
              if (valuesCountryWiseDistribution[index] !== 0) {
                countryWiseDistribution_array.push({
                  name: keysCountryWiseDistribution[index],
                  value: valuesCountryWiseDistribution[index],
                });
              }
            }
            setCountryWiseDistribution(countryWiseDistribution_array);
            setDisplay(true);
          }
          // console.log('content', solutionWiseDistribution);
        } else if (searchValue === 'Expertise Search') {
          // console.log('Expertise Search', payload);
          if (isEmpty(payload)) {
            setDisplay(true);
            setErrorMsg(true);
          } else {
            if (errorMsg) setErrorMsg(false);
            setShowTranslation(payload?.translation?.showTranslation);
            setLangResult(payload?.translation?.translatedText);
            setShowTransParent(true);
            const totalExpertiseResult = payload ? payload.experts : {};
            const keysExpertDomain = Object.keys(totalExpertiseResult);
            const valuesExpertDomain = Object.values(totalExpertiseResult);
            const expertDomain_array: any = [];
            // eslint-disable-next-line no-plusplus
            for (let index = 0; index < keysExpertDomain.length; index++) {
              if (valuesExpertDomain[index] !== 0) {
                expertDomain_array.push({
                  name: keysExpertDomain[index],
                  'Number Of Artifacts': valuesExpertDomain[index],
                });
              }
            }
            setExpertDomain(expertDomain_array);
            nameClick(Object.keys(payload?.experts)[0], true, payload);
            setDisplay(true);
          }
        }
        if (searchValue === 'People Search') {
          if (isEmpty(payload)) {
            setDisplay(true);
            setErrorMsg(true);
          } else {
            if (errorMsg) setErrorMsg(false);
            const x = payload?.people[0];
            const payL = payload[x];
            if (modeV) {
              setOldPayload(payload);
            }
            setPayloadPeopleSearch(payL);
            const r: any = payL?.searchResults;
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < r.length; i++) {
              // eslint-disable-next-line dot-notation
              r[i]['id'] = i;
            }
            // setINerUsableData(JSON.stringify(r));
            setSearchPayload(JSON.stringify(r));
            // console.log(JSON.stringify(f));
            setTotalPeopleResult(payL?.numberOfResults);
            const endTime = performance.now();
            const t = ((endTime - startTime) / 3600).toFixed(2);
            setTimeTaken(t);
            setRows(payL?.searchResults);
            const count1 =
              payL?.visualizationData?.productLineWiseDistribution?.[
                'Digital & Integrated Systems'
              ] || 0;
            const count2 = payL?.visualizationData?.productLineWiseDistribution?.RSC || 0;
            const count = count1 + count2;
            // for graph code starts here
            const keysAlstomEntityWiseDistribution = Object.keys(
              searchValue === 'People Search'
                ? payL.visualizationData?.productLineWiseDistribution
                : null
            );
            const valuesAlstomEntityWiseDistribution = Object.values(
              searchValue === 'People Search'
                ? payL.visualizationData.productLineWiseDistribution
                : null
            );
            const alstomEntityWiseDistribution_array: any = [];
            // eslint-disable-next-line no-plusplus
            for (let index = 0; index < keysAlstomEntityWiseDistribution.length; index++) {
              if (valuesAlstomEntityWiseDistribution[index] !== 0) {
                alstomEntityWiseDistribution_array.push({
                  name: keysAlstomEntityWiseDistribution[index],
                  value: valuesAlstomEntityWiseDistribution[index],
                });
              }
            }
            setAlstomEntityWiseDistribution(alstomEntityWiseDistribution_array);
            // second graph
            const keysPlatformWiseDistribution = Object.keys(
              searchValue === 'People Search'
                ? payL.visualizationData.platformWiseDistribution
                : null
            );
            const valuesPlatformWiseDistribution = Object.values(
              searchValue === 'People Search'
                ? payL.visualizationData.platformWiseDistribution
                : null
            );
            const platformWiseDistribution_array: any = [];
            // eslint-disable-next-line no-plusplus
            for (let index = 0; index < keysPlatformWiseDistribution.length; index++) {
              if (valuesPlatformWiseDistribution[index] !== 0) {
                platformWiseDistribution_array.push({
                  name: keysPlatformWiseDistribution[index],
                  value: valuesPlatformWiseDistribution[index],
                });
              }
            }
            setPlatformWiseDistribution(platformWiseDistribution_array);
            // console.log(platformWiseDistribution_array);
            // second graph
            const keysProjectWiseDistribution = Object.keys(
              searchValue === 'People Search'
                ? payL.visualizationData.projectWiseDistribution
                : null
            );
            const valuesProjectWiseDistribution = Object.values(
              searchValue === 'People Search'
                ? payL.visualizationData.projectWiseDistribution
                : null
            );
            const projectWiseDistribution_array: any = [];
            // eslint-disable-next-line no-plusplus
            for (let index = 0; index < keysProjectWiseDistribution.length; index++) {
              if (valuesProjectWiseDistribution[index] !== 0) {
                projectWiseDistribution_array.push({
                  name: keysProjectWiseDistribution[index],
                  value: valuesProjectWiseDistribution[index],
                });
              }
            }
            // console.log(projectWiseDistribution_array);
            setProjectWiseDistribution(projectWiseDistribution_array);
            // third graph documentProfileWiseDistribution
            const keysdocumentProfileWiseDistribution = Object.keys(
              searchValue === 'People Search'
                ? payL.visualizationData.documentProfileWiseDistribution
                : null
            );
            const valuesdocumentProfileWiseDistribution = Object.values(
              searchValue === 'People Search'
                ? payL.visualizationData.documentProfileWiseDistribution
                : null
            );
            const documentProfileWiseDistribution_array: any = [];
            // eslint-disable-next-line no-plusplus
            for (let index = 0; index < keysdocumentProfileWiseDistribution.length; index++) {
              if (valuesdocumentProfileWiseDistribution[index] !== 0) {
                documentProfileWiseDistribution_array.push({
                  name: keysdocumentProfileWiseDistribution[index],
                  value: valuesdocumentProfileWiseDistribution[index],
                });
              }
            }
            // console.log(documentProfileWiseDistribution_array);
            setDocumentProfileWiseDistribution(documentProfileWiseDistribution_array);
            // Fourth Graph
            const keysArtifactWiseDistribution = Object.keys(
              searchValue === 'People Search'
                ? payL.visualizationData.artifactWiseDistribution
                : null
            );
            const valuesArtifactWiseDistribution = Object.values(
              searchValue === 'People Search'
                ? payL.visualizationData.artifactWiseDistribution
                : null
            );
            const artifactWiseDistribution_array: any = [];
            // eslint-disable-next-line no-plusplus
            for (let index = 0; index < keysArtifactWiseDistribution.length; index++) {
              if (valuesArtifactWiseDistribution[index] !== 0) {
                artifactWiseDistribution_array.push({
                  name: keysArtifactWiseDistribution[index],
                  value: valuesArtifactWiseDistribution[index],
                });
              }
            }
            setArticatWiseDistribution(artifactWiseDistribution_array);
            // Fiffth graph documentProfileWiseDistribution
            const keysdocumentSolutionWiseDistribution = Object.keys(
              searchValue === 'People Search'
                ? payL.visualizationData.solutionWiseDistribution
                : payL.individualResults[
                    inputValue.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
                  ]?.visualizationData?.solutionWiseDistribution
            );
            const valuesdocumentSolutionWiseDistribution = Object.values(
              searchValue === 'People Search'
                ? payL.visualizationData.solutionWiseDistribution
                : payL.individualResults[
                    inputValue.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
                  ]?.visualizationData?.solutionWiseDistribution
            );
            const solutionWiseDistribution_array: any = [];
            // eslint-disable-next-line no-plusplus
            for (let index = 0; index < keysdocumentSolutionWiseDistribution.length; index++) {
              if (valuesdocumentSolutionWiseDistribution[index] !== 0) {
                solutionWiseDistribution_array.push({
                  name: keysdocumentSolutionWiseDistribution[index],
                  value: valuesdocumentSolutionWiseDistribution[index],
                });
              }
            }
            setSolutionWiseDistribution(solutionWiseDistribution_array);
            // sixth graph documentProfileWiseDistribution
            const keysProductWiseDistribution = Object.keys(
              searchValue === 'People Search'
                ? payL.visualizationData.productWiseDistribution
                : payL.individualResults[
                    inputValue.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
                  ]?.visualizationData?.productWiseDistribution
            );
            const valuesProductWiseDistribution = Object.values(
              searchValue === 'People Search'
                ? payL.visualizationData.productWiseDistribution
                : payL.individualResults[
                    inputValue.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
                  ]?.visualizationData?.productWiseDistribution
            );
            const productWiseDistribution_array: any = [];
            // eslint-disable-next-line no-plusplus
            for (let index = 0; index < keysProductWiseDistribution.length; index++) {
              if (valuesProductWiseDistribution[index] !== 0) {
                productWiseDistribution_array.push({
                  name: keysdocumentSolutionWiseDistribution[index],
                  value: valuesdocumentSolutionWiseDistribution[index],
                });
              }
            }
            setProductWiseDistribution(productWiseDistribution_array);
            // // Fiffth graph documentProfileWiseDistribution
            // const keysCountryWiseDistribution = Object.keys(
            //   searchValue === 'People Search'
            //     ? payL.visualizationData.countryWiseDistribution
            //     : payL.individualResults[
            //         inputValue.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
            //       ]?.visualizationData?.countryWiseDistribution
            // );
            // const valuesCountryWiseDistribution = Object.values(
            //   searchValue === 'People Search'
            //     ? payL.visualizationData.countryWiseDistribution
            //     : payL.individualResults[
            //         inputValue.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
            //       ]?.visualizationData?.countryWiseDistribution
            // );
            // const countryWiseDistribution_array: any = [];
            // // eslint-disable-next-line no-plusplus
            // for (let index = 0; index < keysCountryWiseDistribution.length; index++) {
            //   if (valuesCountryWiseDistribution[index] !== 0) {
            //     countryWiseDistribution_array.push({
            //       name: keysCountryWiseDistribution[index],
            //       value: valuesCountryWiseDistribution[index],
            //     });
            //   }
            // }
            // setCountryWiseDistribution(countryWiseDistribution_array);
            setDisplay(true);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setDisplay(true);
        setErrorMsg(true);
      })
      .finally(() => {
        setLoad(false);
      });
  }

  // API call for people serach when user click on list of peoples
  const handleClickText2DatasetNew = (popleName, modeV) => {
    const searchValue = 'People Search';
    setInerDisplay(false);
    setLoadIner(false);
    IsearcherApiCall(
      searchValue,
      popleName,
      filterPlatformsfilt2,
      filterProjectsfilt3,
      filterDocumentProfilesfilt4,
      productLinefilt1,
      filt5,
      filterSolutionfilt6,
      filterProductfilt7,
      filt8,
      maxResultsValue,
      1,
      door,
      modeV
    );
  };

  const HandleHomeClick = (i: any) => {
    // setcallOperation(opArray[i]);
    setOperation(i);
    if (display) setDisplay(false);
    setCallOperation(opArray[i]);
    if (errorMsg) setErrorMsg(false);
    // if (
    //   productLinefilt1 ||
    //   filterPlatformsfilt2 ||
    //   filterProjectsfilt3 ||
    //   filterDocumentProfilesfilt4 ||
    //   filt5 ||
    //   maxResultsValue < 100
    // )
    //   dispatch({ type: 'clear_isearch_filter' });
    // ClearFilter();
    if (load) setLoad(false);
  };

  const HandleRexClick = (i: any) => {
    setOperation(i);
    setCallOperation(opArray[i]);
    if (
      productLinefilt1 ||
      filterPlatformsfilt2 ||
      filterProjectsfilt3 ||
      filterDocumentProfilesfilt4 ||
      filt5 ||
      maxResultsValue < 100
    )
      dispatch({ type: 'clear_isearch_filter' });
    if (issureSite || rexPlatform || rexSource || rexStatus || rexTypology || maxResultsValue < 100)
      dispatch({ type: 'clear_rex_filter' });
    // ClearFilterRex();
  };
  const nameClick = (getName: string, _e_targetDefault: any = false, payload: any) => {
    const startTime = performance.now();
    const nameValue = getName;
    const payloadMain = payload;
    const specsKpis = payloadMain.specs[nameValue]?.artifactKPIs;
    const keysSpecsKpi = Object.keys(specsKpis);
    const valuesSpecsKpi = Object.values(specsKpis);
    const expertSpecsKpi_array: any = [];
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < keysSpecsKpi.length; index++) {
      if (valuesSpecsKpi[index] !== 0) {
        expertSpecsKpi_array.push({
          name: keysSpecsKpi[index],
          value: valuesSpecsKpi[index],
        });
      }
    }
    // for graph code starts here
    const alstomEntity = payloadMain?.specKPIs[nameValue];
    const keysAlstomEntityWiseDistribution = Object.keys(alstomEntity?.productLineWiseDistribution);
    const valuesAlstomEntityWiseDistribution = Object.values(
      alstomEntity?.productLineWiseDistribution
    );
    const alstomEntityWiseDistribution_array: any = [];
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < keysAlstomEntityWiseDistribution.length; index++) {
      if (valuesAlstomEntityWiseDistribution[index] !== 0) {
        alstomEntityWiseDistribution_array.push({
          name: keysAlstomEntityWiseDistribution[index],
          value: valuesAlstomEntityWiseDistribution[index],
        });
      }
    }
    // second graph
    const keysPlatformWiseDistribution = Object.keys(alstomEntity?.platformWiseDistribution);
    const valuesPlatformWiseDistribution = Object.values(alstomEntity?.platformWiseDistribution);
    const platformWiseDistribution_array: any = [];
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < keysPlatformWiseDistribution.length; index++) {
      if (valuesPlatformWiseDistribution[index] !== 0) {
        platformWiseDistribution_array.push({
          name: keysPlatformWiseDistribution[index],
          value: valuesPlatformWiseDistribution[index],
        });
      }
    }
    // Third graph
    const keysProjectWiseDistribution = Object.keys(alstomEntity?.projectWiseDistribution);
    const valuesProjectWiseDistribution = Object.values(alstomEntity?.projectWiseDistribution);
    const projectWiseDistribution_array: any = [];
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < keysProjectWiseDistribution.length; index++) {
      if (valuesProjectWiseDistribution[index] !== 0) {
        projectWiseDistribution_array.push({
          name: keysProjectWiseDistribution[index],
          value: valuesProjectWiseDistribution[index],
        });
      }
    }
    // Fourth graph documentProfileWiseDistribution
    const keysdocumentProfileWiseDistribution = Object.keys(
      alstomEntity?.documentProfileWiseDistribution
    );
    const valuesdocumentProfileWiseDistribution = Object.values(
      alstomEntity?.documentProfileWiseDistribution
    );
    const documentProfileWiseDistribution_array: any = [];
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < keysdocumentProfileWiseDistribution.length; index++) {
      if (valuesdocumentProfileWiseDistribution[index] !== 0) {
        documentProfileWiseDistribution_array.push({
          name: keysdocumentProfileWiseDistribution[index],
          value: valuesdocumentProfileWiseDistribution[index],
        });
      }
    }
    // Fifth Graph
    const keysArtifactWiseDistribution = Object.keys(alstomEntity?.artifactWiseDistribution);
    const valuesArtifactWiseDistribution = Object.values(alstomEntity?.artifactWiseDistribution);
    const artifactWiseDistribution_array: any = [];
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < keysArtifactWiseDistribution.length; index++) {
      if (valuesArtifactWiseDistribution[index] !== 0) {
        artifactWiseDistribution_array.push({
          name: keysArtifactWiseDistribution[index],
          value: valuesArtifactWiseDistribution[index],
        });
      }
    }
    // Fiffth graph documentProfileWiseDistribution
    const keysdocumentSolutionWiseDistribution = Object.keys(
      alstomEntity?.solutionWiseDistribution
    );
    const valuesdocumentSolutionWiseDistribution = Object.values(
      alstomEntity?.solutionWiseDistribution
    );
    const solutionWiseDistribution_array: any = [];
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < keysdocumentSolutionWiseDistribution.length; index++) {
      if (valuesdocumentSolutionWiseDistribution[index] !== 0) {
        solutionWiseDistribution_array.push({
          name: keysdocumentSolutionWiseDistribution[index],
          value: valuesdocumentSolutionWiseDistribution[index],
        });
      }
    }
    setSolutionWiseDistribution(solutionWiseDistribution_array);
    // sixth graph documentProfileWiseDistribution
    const keysProductWiseDistribution = Object.keys(alstomEntity?.productWiseDistribution);
    const valuesProductWiseDistribution = Object.values(alstomEntity?.productWiseDistribution);
    const productWiseDistribution_array: any = [];
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < keysProductWiseDistribution.length; index++) {
      if (valuesProductWiseDistribution[index] !== 0) {
        productWiseDistribution_array.push({
          name: keysdocumentSolutionWiseDistribution[index],
          value: valuesdocumentSolutionWiseDistribution[index],
        });
      }
    }
    setProductWiseDistribution(productWiseDistribution_array);
    // Fiffth graph documentProfileWiseDistribution
    const keysCountryWiseDistribution = Object.keys(alstomEntity?.countryWiseDistribution);
    const valuesCountryWiseDistribution = Object.values(alstomEntity?.countryWiseDistribution);
    const countryWiseDistribution_array: any = [];
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < keysCountryWiseDistribution.length; index++) {
      if (valuesCountryWiseDistribution[index] !== 0) {
        countryWiseDistribution_array.push({
          name: keysCountryWiseDistribution[index],
          value: valuesCountryWiseDistribution[index],
        });
      }
    }
    setCountryWiseDistribution(countryWiseDistribution_array);
    setLanguage(payloadMain?.translation?.detectedLanguage);
    setDetectLanguage(payloadMain?.translation?.translatedText);
    setSmartData(payloadMain?.specs[nameValue]?.smartIDCard);
    setArtifactKpi(expertSpecsKpi_array);
    const endTime = performance.now();
    const t = ((endTime - startTime) / 3600).toFixed(2);
    setTimeTaken(t);
    setRows(payloadMain?.specs[nameValue]?.searchResults);
    setTotalPeopleResult(payloadMain?.specs[nameValue]?.numberOfResults);
    setAlstomEntityWiseDistribution(alstomEntityWiseDistribution_array);
    setPlatformWiseDistribution(platformWiseDistribution_array);
    setProjectWiseDistribution(projectWiseDistribution_array);
    setDocumentProfileWiseDistribution(documentProfileWiseDistribution_array);
    setArticatWiseDistribution(artifactWiseDistribution_array);
    setPayloadMain(payload);
    setDisplaySmart(false);
  };
  function urlSearch(a: any) {
    getiQnA2(a);
  }

  function getiQnA2(a: any) {
    // setTextValue(a);
    const bodyObj = a;
    iQnA(bodyObj)
      .then((payload: any) => {
        if (payload) {
          setAskQuesRes(payload);
          setApiData(`${url}/01/${payload.concept?.label}`);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // API call for Ask QuestionAskQuesApi
  const AskQuesApi = (inputValue) => {
    iQnA(inputValue, undefined)
      .then((payload: any) => {
        if (payload.noAnswer) {
          setDisplay(true);
          setErrorMsg(true);
        } else {
          if (errorMsg) setErrorMsg(false);
          setAskQuesRes(payload);
          setApiData(`${url}/01/${payload.concept?.label}`);
          setDisplay(true);
        }
      })
      .catch((err) => {
        console.log(err, 'error');
      })
      .finally(() => {
        setLoad(false);
      });
  };

  // Movement of search bar when result is displayed
  const HomeMove = clsx({
    [classes.subMain2Move]: display,
    [classes.subMain2]: !display,
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // handeling common function call for all operational API
  /* 
  This function is making a call for selected Call Operation so here in ths 
  function switch case is used, it will call an API for respective selected 
  Services
  */
  const handleApiCall = () => {
    // These three State are to reset the display and errormsg
    setInerDisplay(false);
    setLoadIner(false);
    setErrorMsg(false);
    if (langResult) setLangResult(false);
    if (
      !inputValue &&
      callOperation !== 'Ontology' &&
      callOperation !== 'Summarize Text' &&
      callOperation !== 'REX 360'
    ) {
      // setRemind(true);
      setRemidText('Please Enter Input');
      return;
    }

    switch (callOperation) {
      case 'Similarity Search': {
        setDisplay(false);
        setLoad(true);
        FindSimilarText(
          inputValue,
          countValue,
          dropDown1,
          dropDown2,
          dropDown3,
          dropDown4,
          dropDown5,
          dropDown6
        );
        break;
      }

      case 'Content Search': {
        const searchValue = 'Query Search';
        setLoad(true);
        setDisplay(false);
        IsearcherApiCall(
          searchValue,
          inputValue,
          filterPlatformsfilt2,
          filterProjectsfilt3,
          filterDocumentProfilesfilt4,
          productLinefilt1,
          filt5,
          filterSolutionfilt6,
          filterProductfilt7,
          filt8,
          maxResultsValue,
          1,
          door,
          ''
        );
        break;
      }
      case 'Expert Search': {
        const searchValue = 'Expertise Search';
        setLoad(true);
        setDisplay(false);
        IsearcherApiCall(
          searchValue,
          inputValue,
          filterPlatformsfilt2,
          filterProjectsfilt3,
          filterDocumentProfilesfilt4,
          productLinefilt1,
          filt5,
          filterSolutionfilt6,
          filterProductfilt7,
          filt8,
          maxResultsValue,
          1,
          door,
          ''
        );
        break;
      }

      case 'People Search': {
        const searchValue = 'People Search';
        setLoad(true);
        setDisplay(false);
        IsearcherApiCall(
          searchValue,
          inputValue,
          filterPlatformsfilt2,
          filterProjectsfilt3,
          filterDocumentProfilesfilt4,
          productLinefilt1,
          filt5,
          filterSolutionfilt6,
          filterProductfilt7,
          filt8,
          maxResultsValue,
          1,
          door,
          true
        );
        break;
      }

      case 'Ask Question': {
        setLoad(true);
        setDisplay(false);
        AskQuesApi(inputValue);
        break;
      }

      case 'Ontology': {
        setDisplay(true);
        break;
      }

      case 'REX 360': {
        const url =
          projectId !== undefined
            ? `/projects/${projectId}/module2/m2/REXAnalysis`
            : `/workspace/REXAnalysis/${userId}`;
        history.push(`${url}`);
        break;
      }

      case 'Summarize Text': {
        setDisplay(true);
        break;
      }

      case 'Generate Images': {
        setLoad(true);
        setDisplay(false);
        generateImagesApiCall(inputValue);
        break;
      }
      default:
        break;
    }
  };

  /* The getIner function */
  function getINer() {
    setInerDisplay(false);
    setLoadIner(true);
    const r = {
      dataframe: searchPayload,
      target: 'artifactText',
    };
    // console.log(JSON.stringify(r));
    let searchValue;
    if (operation === 0) {
      searchValue = 'Query Search';
    } else if (operation === 3) {
      searchValue = 'People Search';
    }
    const bodyHere = {
      query: inputValue,
      maxResults: maxResultsValue,
      minResultLen: 1,
      alstomEntity: ent,
      platform: plat,
      project: proj,
      documentProfile: docpro,
      artiFact: artPro,
      dataset: door,
      queryType: searchValue?.replace(/ /g, ''),
      ner: 'false',
    };
    iNeriSearcher(bodyHere)
      .then((payload) => {
        setINerUsableData(JSON.stringify(payload));
        setInerDisplay(true);
        // console.log('iNerUsableData', iNerUsableData);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        // console.log(iNerUsableData);
        setLoadIner(false);
      });
  }

  /* UpdateShortcut defined to update shortcuts in Home page */
  // const updateShortCut = (eachShort) => {
  //   for (let i = 0; i < shortCuts.length; i += 1) {
  //     const { name } = shortCuts[i];
  //     if (eachShort === name) {
  //       // setRemind(true);
  //       // setRemidText('Shortcut exist in Home Page');
  //       return;
  //     }
  //   }
  //   let short: any[] = [];
  //   setShortCuts((prev: any) => {
  //     const obj = {
  //       name: eachShort,
  //       ProjectId: projectId,
  //     };
  //     short = [...prev, obj];
  //     return [...prev, obj];
  //   });
  //   const date = new Date();
  //   const formattedDate = date.toLocaleDateString('en-US', {
  //     month: '2-digit',
  //     day: '2-digit',
  //     year: 'numeric',
  //   });

  //   // here its making an API call for save shortcuts in DB
  //   updateShortcuts(short, projectId, formattedDate, appJWT?.user_id);
  //   // setRemind(true);
  //   // setRemidText('Shortcut Added To Home Page');
  // };

  return (
    <>
      {/* {remind && <Remind open={remind} text={remidtext} setRemind={setRemind} />} */}
      <Box
        style={{
          padding: '20px',
          borderBottom: display ? '1px solid #D1D9EF' : '',
        }}
      >
        <Box className="text-analysis-parent">
          <Box className={classes.subMain2Child1}>
            <Box className="text-analysis-parent-container">
              <Box className="text-analysis-parent-container-child">
                {callOperation !== 'Ontology' &&
                  callOperation !== 'Summarize Text' &&
                  callOperation !== 'REX 360' &&
                  callOperation !== 'Translate' && (
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '10px',
                        height: '100%',
                      }}
                    >
                      {RexButtons.includes(callOperation) && (
                        <Tooltip title="Back">
                          <IconButton
                            onClick={() => {
                              if (ENV !== 'production' && ENV !== 'test') {
                                setOpArray(developmentArray);
                                setToolTipCont(developmentToolTip);
                              } else {
                                setOpArray(productionArray);
                                setToolTipCont(productionToolTip);
                              }
                              // setOpArray(developmentArray);
                              // setToolTipCont(developmentToolTip);
                              setReset(false);
                              setOperation(0);
                              // setcallOperation('Content Search');
                              setCallOperation('Content Search');
                              setDisplay(false);
                              setShowIcons(iconstabs);
                            }}
                          >
                            <ArrowBackIcon />
                          </IconButton>
                        </Tooltip>
                      )}

                      <Paper
                        style={{
                          width: '100%',
                          height: '100%',
                          paddingLeft: '1rem',
                          // padding: '5px',
                          display: 'flex',
                          justifyContent: 'space-between',
                          gap: '10px',
                          border: '0.5px solid #002A45',
                          borderRadius: '22px',
                          boxShadow: '0px 4px 8px #00000029',
                        }}
                      >
                        <InputBase
                          style={{
                            flexBasis: '95%',
                            fontSize: '1.3rem',
                            color: '#002A45',
                          }}
                          multiline={callOperation === 'Translate'}
                          inputProps={{ maxLength: 2500 }}
                          onChange={(event) => {
                            setInputValue(event.target.value);
                          }}
                          value={inputValue}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') handleApiCall();
                          }}
                        />
                        <IconButton
                          style={{ color: '#002A45' }}
                          value={inputValue}
                          type="button"
                          aria-label="search"
                          onClick={handleApiCall}
                        >
                          <SearchIcon />
                        </IconButton>
                      </Paper>
                    </Box>
                  )}
                {langResult &&
                  [
                    'Content Search',
                    'Expert Search',
                    'People Search',
                    'Similarity Search',
                  ].includes(callOperation) && (
                    <Tooltip title={`Translated In English : ${langResult}`} arrow>
                      <Box className="toolTip-Translation">
                        <Typography style={{ fontSize: '17px', color: '#AFAFAF' }}>
                          {langResult.slice(0, 135)}...
                        </Typography>
                      </Box>
                    </Tooltip>
                  )}
              </Box>
              {(['Content Search', 'Expert Search', 'People Search', 'Similarity Search'].includes(
                callOperation
              ) ||
                RexButtons.includes(callOperation)) &&
                callOperation !== 'Ontology' &&
                callOperation !== 'Summarize Text' &&
                callOperation !== 'REX 360' && (
                  <>
                    {' '}
                    <IconButton
                      style={{
                        backgroundColor: '#EEF1F9',
                        boxShadow: '0px 4px 8px #00000029',
                        width: '3rem',
                        color: '#002A45',
                        height: '3rem',
                        display:
                          [
                            'Ask Question',
                            'Chat iSenS',
                            'Change Requests',
                            'AEC',
                            'Lean Six Sigma',
                            'People Search',
                          ].includes(callOperation) === true
                            ? 'none'
                            : 'unset',
                      }}
                      aria-describedby={id}
                      variant="contained"
                      onClick={handleClick}
                      disabled={[
                        'Ask Question',
                        'Chat iSenS',
                        'Change Requests',
                        'AEC',
                        'Lean Six Sigma',
                        'People Search',
                      ].includes(callOperation)}
                    >
                      <FilterAltOutlinedIcon />
                    </IconButton>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      PaperProps={{
                        style: {
                          backgroundColor: '#EEF1F9',
                          marginTop: '10px',
                          boxShadow: 'none',
                          overflowX: 'visible',
                          overflowY: 'visible',
                        },
                      }}
                    >
                      <Filters callOperation={callOperation} />
                    </Popover>
                  </>
                )}
            </Box>

            <Box className="feature-list-parent">
              <Box className="feature-list-parent-container">
                <List className={classes.listParent}>
                  {opArray.map((item, i) => {
                    const RouteName = item
                      .split(' ')
                      .map((data) => data.toLowerCase())
                      .join('');
                    return (
                      <>
                        <Tooltip title={`${toolTipCont[i]}`} arrow>
                          <ListItem
                            button
                            // component={Link}
                            // eslint-disable-next-line max-len
                            // to={`/projects/${projectId}/module2/${'m2'}/short/?action=${`TextAnalysis`}&service=${RouteName}`}
                            onClick={() => {
                              if (callOperation === 'REX 360') {
                                HandleRexClick(i);
                              } else {
                                HandleHomeClick(i);
                              }
                              // rex360Click();
                            }}
                            className={classes.operationListItem}
                            selected={operation === i}
                          >
                            {showIcons[i]}
                            {/* <Typography>{item}</Typography> */}
                            {item === 'Expert Search' ? (
                              <>
                                <Typography>Referents Search</Typography>
                              </>
                            ) : (
                              <Typography>{item}</Typography>
                            )}
                          </ListItem>
                        </Tooltip>
                      </>
                    );
                  })}
                </List>

                {/* {clicked && (
                  <ContextMenu top={points.y} left={points.x}>
                    <ul>
                      <li
                        onClick={() => {
                          updateShortCut(eachShort);
                        }}
                      >
                        <ShortcutIcon />
                        Add Shortcut in Home Page
                      </li>
                    </ul>
                  </ContextMenu>
                )} */}
                {load && <LoadingApp />}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {display && (
        <>
          <Box style={{ padding: '0.5rem' }} className="result-container">
            {errorMsg && <NoResultsFound />}
            {callOperation === 'Similarity Search' && !errorMsg && (
              <FindSimilarTexts rowResult={similarTextRow} />
            )}
            {callOperation === 'Content Search' && !errorMsg && (
              <ContentSearchResult
                lable=""
                rowResult={rows}
                contentSearchPayload={contentSearchPayload}
                inputValue={inputValue}
                alstomEntityWiseDistribution={alstomEntityWiseDistribution}
                platformWiseDistribution={platformWiseDistribution}
                projectWiseDistribution={projectWiseDistribution}
                documentProfileWiseDistribution={documentProfileWiseDistribution}
                solutionWiseDistribution={solutionWiseDistribution}
                countryWiseDistribution={countryWiseDistribution}
                productWiseDistribution={productWiseDistribution}
                artifactWiseDistribution={artifactWiseDistribution}
                timeTaken={timeTaken}
                totalResult={totalResult}
              />
            )}
            {callOperation === 'Expert Search' && !errorMsg && (
              <>
                <ExpertSearch
                  rowResult={rows}
                  alstomEntityWiseDistribution={alstomEntityWiseDistribution}
                  platformWiseDistribution={platformWiseDistribution}
                  projectWiseDistribution={projectWiseDistribution}
                  documentProfileWiseDistribution={documentProfileWiseDistribution}
                  solutionWiseDistribution={solutionWiseDistribution}
                  countryWiseDistribution={countryWiseDistribution}
                  productWiseDistribution={productWiseDistribution}
                  artifactWiseDistribution={artifactWiseDistribution}
                  expertDomain={expertDomain}
                  nameClick={nameClick}
                  smartData={smartData}
                  artifactKpi={artifactKpi}
                  TotalPeopleResult={TotalPeopleResult}
                  timeTaken={timeTaken}
                  payloadMain={payloadMain}
                />
              </>
            )}
            {callOperation === 'Ask Question' && !errorMsg && (
              <AskQuestion
                askQuesRes={askQuesRes}
                urlSearch={urlSearch}
                apiData={apiData}
                searchKey={inputValue}
              />
            )}
            {callOperation === 'People Search' && !errorMsg && (
              <PeopleSearch
                rows={rows}
                payloadPeopleSearch={payloadPeopleSearch}
                alstomEntityWiseDistribution={alstomEntityWiseDistribution}
                platformWiseDistribution={platformWiseDistribution}
                projectWiseDistribution={projectWiseDistribution}
                documentProfileWiseDistribution={documentProfileWiseDistribution}
                solutionWiseDistribution={solutionWiseDistribution}
                countryWiseDistribution={countryWiseDistribution}
                productWiseDistribution={productWiseDistribution}
                artifactWiseDistribution={artifactWiseDistribution}
                timeTaken={timeTaken}
                TotalPeopleResult={TotalPeopleResult}
                oldPayload={oldPayload}
                handleClickText2DatasetNew={handleClickText2DatasetNew}
              />
            )}
            {callOperation === 'Ontology' && <Ontology />}
            {callOperation === 'Summarize Text' && <QnAGenerator />}
            {/* {callOperation === 'Translate' && <TranslationNew languageCode={languageCode} />} */}
            {callOperation === 'Generate Images' && (
              <GenerateImages images={images} input={inputValue} />
            )}
          </Box>
          {['Content Search', 'People Search'].includes(callOperation) &&
            !errorMsg &&
            rows.length > 0 && (
              <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  onClick={getINer}
                  disabled
                  type="button"
                  variant="contained"
                  color="primary"
                >
                  Extract Entities
                </Button>
              </Box>
            )}
          {loadIner && <LoadingApp />}
          {inerDisplay && !errorMsg && <INer data={iNerUsableData} />}
        </>
      )}
    </>
  );
}

export default TextAnalysisMode;
