import React, { ReactNode, useContext, useReducer } from 'react';
import authReducer, { AppAction, AppState, initialState } from '../../reducers/authReducer';

interface AppContextProviderProps {
  children?: ReactNode;
}

const AppContext = React.createContext<{ state: AppState; dispatch: React.Dispatch<AppAction> }>({
  state: initialState,
  dispatch: () => null,
});
export const useAppContext = () => {
  return useContext(AppContext);
};

const AppContextProvider: React.FC<AppContextProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  return <AppContext.Provider value={{ state, dispatch }}>{children}</AppContext.Provider>;
};

export default AppContextProvider;
