import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Grid,
  Paper,
  StepConnector,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/styles';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FlagIcon from '@mui/icons-material/Flag';
import CancelIcon from '@mui/icons-material/Cancel';
import { apiEndpoints } from '../../../utils/apiEndpoints';
import useAxiosAuth from '../../../API/axios';

const LabelDot = styled((props) => <Box {...props} />)(({ theme }) => ({
  backgroundColor: '#eef1f9',
  padding: ' 0.8rem',
}));

const BoldStepLabel = styled(StepLabel)({
  fontWeight: 'bold',
});

const defaultEvaluation = {
  isAccepted: false,
  isNeedClarification: false,
  isDeclined: false,
};

const defaultBuyOrMake = {
  isPurchased: false,
  isDeveloped: false,
};

const StepFourLabel = ({ step, data }) => {
  const obj = data;

  const temp =
    Number(step) === 2
      ? [
          { name: 'Buy (to be purchase)', isChecked: obj.isPurchased },
          { name: 'Make (to be develop)', isChecked: obj.isDeveloped },
        ]
      : [
          { name: 'Accepted', isChecked: obj.isAccepted },
          { name: 'Need Clarification', isChecked: obj.isNeedClarification },
          { name: 'Declined', isChecked: obj.isDeclined },
        ];
  return (
    <>
      {' '}
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          fontWeight: 'bold',
          gap: '5px',
          border: '2px solid #002A45',
          padding: '0.6rem',
          borderStyle: 'dashed',
        }}
      >
        {temp.map((status, index) => (
          <Grid item xs={3} key={index}>
            <Paper
              elevation={3}
              style={{
                padding: '16px',
                textAlign: 'center',
                display: 'flex',
                gap: '5px',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {/* {status.isChecked && status.name !== 'Declined' && (
                <CheckCircleIcon style={{ color: 'green' }} />
              )} */}

              <CheckCircleIcon
                style={{
                  color: 'green',
                  display: status.isChecked && status.name !== 'Declined' ? 'block' : 'none',
                }}
              />
              <CancelIcon
                style={{
                  color: 'red',
                  display: status.isChecked && status.name === 'Declined' ? 'block' : 'none',
                }}
              />

              {status.name}
            </Paper>
          </Grid>
        ))}
      </Box>
    </>
  );
};

const CustomStepIcon = (props, check, name) => {
  const { active, completed, className } = props;

  if (name === 'Declined') {
    return (
      <div className={className}>
        <CancelIcon style={{ color: 'red' }} />
      </div>
    );
  }

  if (check) {
    return <></>;
  }

  if (completed) {
    return (
      <div className={className}>
        <CheckCircleIcon style={{ color: 'green' }} />
      </div>
    );
  }

  if (active) {
    return (
      <div
        className={className}
        style={{ border: '3px solid #002A45', padding: '0.3rem', borderRadius: '50%' }}
      >
        <LocationOnIcon color="secondary" />
      </div>
    );
  }
  return (
    <>
      {' '}
      <div
        className={className}
        style={{ border: '3px solid #002A45', padding: '0.3rem', borderRadius: '50%' }}
      >
        <FlagIcon color="primary" />
      </div>
    </>
  );
};

const CustomStepper = ({ status, handleEdit, id }) => {
  const [activeStep, setActiveStep] = useState(0);

  const steps = useMemo(() => {
    if (status === 'Drafted') {
      setActiveStep(0);
    }

    let temp = [] as any;

    switch (status) {
      case 'Drafted':
        setActiveStep(1);
        temp = [
          ...[
            { name: 'Drafted', isComponent: false },
            { name: 'Submitted', isComponent: false },
            {
              name: 'Evaluted',
              isComponent: true,
              id: 1,
              child: { ...defaultEvaluation },
            },
          ],
          ...[
            {
              name: 'Buy or make',
              isComponent: true,
              id: 2,
              child: { ...defaultBuyOrMake },
            },
            { name: 'Delivered', isComponent: false },
          ],
        ];
        break;
      case 'Submitted':
        setActiveStep(1);
        temp = [
          ...[
            { name: 'Submitted', isComponent: false },
            { name: 'Evaluation', isComponent: false },
          ],
          ...[
            { name: 'Evaluated', isComponent: true, id: 1, child: { ...defaultEvaluation } },
            { name: 'Buy or make', isComponent: true, id: 2, child: { ...defaultBuyOrMake } },
            { name: 'Delivered', isComponent: false },
          ],
        ];
        break;
      case 'EvalForum':
        setActiveStep(1);
        temp = [
          ...[
            { name: 'Submitted', isComponent: false },
            { name: 'Evaluation in progress', isComponent: false },
          ],
          ...[
            { name: 'Evaluated', isComponent: true, id: 1, child: { ...defaultEvaluation } },
            { name: 'Buy or make', isComponent: true, id: 2, child: { ...defaultBuyOrMake } },
            { name: 'Delivered', isComponent: false },
          ],
        ];
        break;
      case 'Accepted':
        setActiveStep(2);
        temp = [
          ...[
            { name: 'Submitted', isComponent: false },
            {
              name: 'Evaluated',
              isComponent: true,
              id: 1,
              child: {
                ...defaultEvaluation,
                isAccepted: true,
              },
            },
          ],
          ...[
            { name: 'Make or buy in progress', isComponent: false },
            { name: 'Buy or make', isComponent: true, id: 2, child: { ...defaultBuyOrMake } },
            { name: 'Delivered', isComponent: false },
          ],
        ];
        break;
      case 'Rejected':
        setActiveStep(3);
        temp = [
          ...[
            { name: 'Submitted', isComponent: false },
            {
              name: 'Evaluated',
              isComponent: false,
            },
            {
              name: 'Declined',
              isComponent: false,
            },
          ],
        ];
        break;

      case 'NeedClarification':
        setActiveStep(1);
        temp = [
          ...[
            {
              name: 'Evaluated',
              isComponent: true,
              id: 1,
              child: {
                ...defaultEvaluation,
                isNeedClarification: true,
              },
            },
            { name: 'Drafted', isComponent: false },
            { name: 'Submitted', isComponent: false },
          ],
          ...[
            { name: 'Buy or make', isComponent: true, id: 2, child: { ...defaultBuyOrMake } },
            { name: 'Delivered', isComponent: false },
          ],
        ];
        break;
      case 'BuyInProgress':
        setActiveStep(2);
        temp = [
          ...[
            { name: 'Submitted', isComponent: false },
            {
              name: 'Evaluted',
              isComponent: false,
            },
          ],
          ...[
            {
              name: 'Buy in progress',
              isComponent: false,
            },
            { name: 'Delivered', isComponent: false },
          ],
        ];
        break;
      case 'MakeOrBuy':
        setActiveStep(3);
        temp = [
          ...[
            { name: 'Submitted', isComponent: false },
            {
              name: 'Evaluted',
              isComponent: false,
            },
            {
              name: 'Accepted',
              isComponent: false,
            },
            {
              name: 'Make Or Buy',
              isComponent: false,
            },
          ],
          ...[
            { name: 'Buy or make', isComponent: true, id: 2, child: { ...defaultBuyOrMake } },
            { name: 'Delivered', isComponent: false },
          ],
        ];
        break;

      case 'MakeInProgress':
        setActiveStep(2);
        temp = [
          ...[
            { name: 'Submitted', isComponent: false },
            {
              name: 'Evaluted',
              isComponent: false,
            },
          ],
          ...[
            {
              name: 'Make in progress',
              isComponent: false,
            },
            { name: 'Delivered', isComponent: false },
          ],
        ];
        break;
      case 'DeliveryInProd':
        setActiveStep(2);
        temp = [
          ...[
            { name: 'Submitted', isComponent: false },
            {
              name: 'Evaluted',
              isComponent: false,
            },
          ],
          ...[{ name: 'Delivered', isComponent: false }],
        ];
        setActiveStep(4);
        break;
      default:
        setActiveStep(0);
    }
    return temp;
  }, [status]);

  console.log('steps', steps);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box sx={{ width: '100%', padding: '2rem 1rem' }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={index}>
            <BoldStepLabel
              StepIconComponent={(props) => {
                return CustomStepIcon(props, label.isComponent, label.name);
              }}
              style={{ fontWeight: 'bold' }}
            >
              {label.isComponent ? (
                <StepFourLabel step={label.id} data={label.child} />
              ) : (
                <div style={{ fontWeight: 'bold' }}>
                  {label.name}
                  {label.name === 'Drafted' && (
                    <IconButton color="primary" onClick={handleEdit}>
                      <EditIcon />
                    </IconButton>
                  )}
                </div>
              )}
            </BoldStepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default CustomStepper;
