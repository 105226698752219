import { Telegram } from '@mui/icons-material';
import { Grid, IconButton, TextField } from '@mui/material';
import React, { useState } from 'react';
import { ITopic } from '../../../interfaces/ITopic';
import { apiEndpoints } from '../../../utils/apiEndpoints';
import useAxiosAuth from '../../../API/axios';

interface InputChatMessageProps {
  currentUserId: number;
  topicId: number;
  replaceOldTopicByUpdatedTopic: (topic: ITopic) => void;
  handleClose: () => void;
}

const InputChatMessage: React.FC<InputChatMessageProps> = ({
  currentUserId,
  topicId,
  handleClose,
  replaceOldTopicByUpdatedTopic,
}) => {
  const [message, setMessage] = useState('');
  const { AxiosCall } = useAxiosAuth();

  const handleSubmitComment = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!(message || '').trim()) return;

    const payload = {
      url: `${apiEndpoints.API_TOPIC_COMMENT_URL}`,
      method: 'POST',
      data: {
        topicId,
        commentatorId: currentUserId,
        comment: message,
      },
      header: {
        'Content-Type': 'application/json',
        'X-USER-ID': currentUserId.toString(),
      },
    };
    AxiosCall(payload).then((updatedTopic) => {
      replaceOldTopicByUpdatedTopic(updatedTopic);
      setMessage('');
      handleClose();
    });
  };

  return (
    <>
      <form onSubmit={handleSubmitComment} style={{ flex: 1 }}>
        <Grid container>
          <Grid item md={11}>
            <TextField
              fullWidth
              rows={4}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Leave your comment here"
            />
          </Grid>
          <Grid item md={1} textAlign="center" alignContent="center">
            <IconButton type="submit">
              <Telegram />
            </IconButton>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default InputChatMessage;
