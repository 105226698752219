import React from 'react';
import PlaylistAddCheckCircleIcon from '@mui/icons-material/PlaylistAddCheckCircle';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import PersonIcon from '@mui/icons-material/Person';
import { AlstomText, Card, Container, Icon, Text, GlassyBox } from './styles';

const TalentHR: React.FC = () => {
  return (
    <Container>
      <Card to="/me/createobjective" className="green">
        <GlassyBox>
          <Icon>
            <PersonIcon style={{ fontSize: '130px' }} />
          </Icon>
          <Text>Generate Objectives for employees</Text>
        </GlassyBox>
      </Card>
      <Card to="/me/checkobjective" className="red">
        <GlassyBox>
          <Icon>
            <PlaylistAddCheckCircleIcon style={{ fontSize: '130px' }} />
          </Icon>
          <Text>Check Objective</Text>
        </GlassyBox>
      </Card>
      <Card to="/me/organizationAwareness" className="blue">
        <GlassyBox>
          <Icon>
            <AccountTreeIcon style={{ fontSize: '130px' }} />
          </Icon>
          <Text>ALSTOM</Text>
          <Text>Organization Awareness</Text>
        </GlassyBox>
      </Card>
    </Container>
  );
};

export default TalentHR;
