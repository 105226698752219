/* eslint-disable import/prefer-default-export */
export const apiEndpoints = {
  // eslint-disable-next-line max-len
  API_TOPIC_STATUS_URL: `${process.env.REACT_APP_IDEATION_BACKEND_URL}/sys-parameter-details?type=TOPIC_STATUS`,
  API_TOPIC_URL: `${process.env.REACT_APP_IDEATION_BACKEND_URL}/topics`,
  API_TOPIC_NOTIFICATION_URL: `${process.env.REACT_APP_IDEATION_BACKEND_URL}/topic-notifications`,
  API_TOPIC_COMMENT_URL: `${process.env.REACT_APP_IDEATION_BACKEND_URL}/topic-comments`,
  API_USER_URL: `${process.env.REACT_APP_IDEATION_BACKEND_URL}/users`,
  API_TOPIC_STATUS: `${process.env.REACT_APP_IDEATION_BACKEND_URL}/status`,
};
