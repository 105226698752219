/* eslint-disable no-continue */
/* eslint-disable no-loop-func */
export async function parseResponseGpt3(response: Response, dispatch: any): Promise<string> {
  const reader = response?.body?.getReader();
  let runningText = '';

  while (true && reader) {
    try {
      const { done, value } = await reader.read();
      if (done) break;

      const text = new TextDecoder('utf-8').decode(value);
      // console.log(text);

      try {
        const test = JSON.parse(text);
        if (test.idResponse) {
          dispatch({
            type: 'STREAM_REPLY_UUID',
            payload: test.idResponse,
          });
          continue;
        }
      } catch (error) {
        console.log(error);
      }
      runningText += text;
      dispatch({
        type: 'STREAM_REPLY',
        payload: runningText,
      });
    } catch (error) {
      break;
    }
  }

  return runningText;
}

const getRoleFromChoice = (choice: any): 'user' | 'tool' | 'assistant' | 'system' => {
  if (choice.delta.context) {
    return 'tool';
  }
  if (choice.delta.role) {
    return choice.delta.role as any;
  }
  return 'user';
};

export const parseResponseRex = async (response: Response, dispatch: any) => {
  const reader = response?.body?.getReader();
  let runningText = '';

  while (true && reader) {
    try {
      const { done, value } = await reader.read();
      if (done) break;

      const text = new TextDecoder('utf-8').decode(value);
      runningText += text;
      dispatch({
        type: 'STREAM_REPLY',
        payload: runningText,
      });
    } catch (error) {
      break;
    }
  }

  return runningText;
};
export const parseResponseAzureOpenAI = async (response: Response, dispatch: any) => {
  if (response?.body) {
    const outputJson = {
      id: '',
      model: '',
      created: 0,
      object: '',
      choices: [] as any[],
      history_metadata: {} as any,
    };
    let isStreamReadable = true;
    // when citation is too long json will be truncated and cannot be parsed
    let contentTruncated = '';
    for await (const value of response?.body as any) {
      if (!isStreamReadable) break;
      const lineText = new TextDecoder('utf-8').decode(value);
      // console.log(lineText);
      const objects = lineText.split('\n');

      for (const obj of objects) {
        if (!obj) break;
        try {
          // detect end of stream in string
          if (obj.toLowerCase().search(/\[done\]|"finishReason":"stop"/) !== -1) {
            console.log('done');
            isStreamReadable = false;
            break;
          }
          let lineJson;
          try {
            // console.log('contentTruncated + obj', contentTruncated + obj);
            lineJson = JSON.parse(contentTruncated + obj) as any;
            contentTruncated = '';
          } catch (error) {
            contentTruncated += obj;
            break;
          }

          // display error from stream
          if (lineJson.message) {
            dispatch({
              type: 'SET_ERROR',
              payload: lineJson.message,
            });
            return;
          }

          const choice = lineJson.choices[0];
          // console.log('choice', choice);
          // detect end of stream in JSON
          if (choice.endTurn) {
            console.log('done 2');
            isStreamReadable = false;
            break;
          }

          if (choice.delta?.role === 'assistant') {
            const message = {
              ...choice.delta,
              content: '',
              id: lineJson.id,
            };
            outputJson.choices.push(message);
          } else if (choice.delta?.content) {
            const deltaText = choice.delta.content;
            outputJson.choices[outputJson.choices.length - 1].content += deltaText;
          }
          // console.log('outputJson', outputJson);
        } catch (error) {
          console.log('error', error);
          isStreamReadable = false;
          dispatch({
            type: 'SET_ERROR',
            payload: error.message,
          });
          break;
        }
      }

      // --------- end loop
      dispatch({
        type: 'STREAM_REPLY_WITH_DATA',
        payload: outputJson,
      });
    }
  }
};

export const parseResponseGpt4Private = async (response: Response, dispatch: any) => {
  const reader = response?.body?.getReader();

  const outputJson = {
    id: '',
    model: '',
    created: 0,
    object: '',
    choices: [] as any[],
    history_metadata: {} as any,
  };
  let isStreamReadable = true;
  let contentTruncated = '';
  let errorMsg = '';
  while (isStreamReadable && reader) {
    try {
      const { done, value } = await reader.read();
      if (done) {
        console.log('done stream');
        break;
      }

      const valueText = new TextDecoder('utf-8').decode(value);
      if (response.status !== 200) {
        errorMsg = valueText;
        break;
      }
      if (valueText.startsWith('data:')) {
        // init content
        contentTruncated = valueText;
      } else {
        contentTruncated += valueText;
        // continue;
      }
      // --------- start block push end of json and start new
      if (contentTruncated.length > 0) {
        const objects = contentTruncated.split('\n');
        objects.forEach((obj: string) => {
          if (!obj.startsWith('data:')) return;
          // remove prefixe data:
          const lineText = String(obj)
            .replace(/^data:/gi, '')
            .trim();
          // console.log('lineText', lineText);

          // detect end of stream in string
          if (lineText.toLowerCase().search(/\[done\]|"finish_reason":"stop"/) !== -1) {
            console.log('done');
            isStreamReadable = false;
            return;
          }

          try {
            JSON.parse(lineText);
          } catch (error) {
            // console.log('not json');
            return;
          }

          const lineJson = JSON.parse(lineText) as any;
          // detect end of stream in JSON
          if (lineJson.choices[0].end_turn) {
            console.log('done 2');
            isStreamReadable = false;
          }

          // get role
          const role = getRoleFromChoice(lineJson.choices[0]);
          // console.log(role);

          switch (role) {
            case 'tool':
              outputJson.choices.push({
                role: 'assistant',
                context: lineJson.choices[0].delta.context,
              });
              break;
            case 'assistant':
              // init empty content
              outputJson.choices[outputJson.choices.length - 1] = {
                ...outputJson.choices[outputJson.choices.length - 1],
                content: '',
              };
              break;
            default:
              if (lineJson.choices[0].delta.content) {
                const deltaText = lineJson.choices[0].delta.content;
                outputJson.choices[0].content += deltaText;
              } else {
                // end of stream
                isStreamReadable = false;
              }
              break;
          }
        });
        // --------- end loop
        dispatch({
          type: 'STREAM_REPLY_WITH_DATA',
          payload: outputJson,
        });
      }
    } catch (error) {
      break;
    }
  }
  if (errorMsg) {
    throw new Error(JSON.stringify(errorMsg));
  }
};
