import React, { useContext, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Select,
  TextField,
  Tooltip,
  FormLabel,
  CircularProgress,
  Paper,
} from '@material-ui/core';
import { isEmpty } from '@antv/util';

import PublishIcon from '@mui/icons-material/Publish';
import DescriptionIcon from '@material-ui/icons/Description';
import { DataGrid, GridColDef, GridRowsProp, GridToolbar } from '@mui/x-data-grid';
import { useHistory } from 'react-router-dom';
import { textToText } from '../../../api/module2';
import {
  get_allocationActivity,
  get_workbench,
  getAllFiles,
  getAllTemplate,
  getTemplatTableData,
  post_icapture_allocator_workbench_allocation,
  post_icapture_createTemplate,
  post_workbench,
  uploadURLCall,
  uploadURLCall_process,
} from '../../../api/allocationNew';
import useAppToken from '../../../app/useAppToken';
import Module1Context from '../../Module1/Module1Context';
import ProjectContext from '../../Project/ProjectContext';
import { BACKEND_URL } from '../../../const';
import { iCaptureExcel } from '../../../api/module1';
import { generateUniqueId } from '../../Module1/components/RunStepper/components/utils/extractdata';
import axios from '../../../api/axios';

const useStyles = makeStyles({
  root: {
    marginTop: 60,
    marginLeft: 20,
    width: '90%',
    borderRadius: 10,
    height: 100,
  },
  root1: {
    marginTop: 0,
    marginLeft: 20,
    width: '90%',
    borderRadius: 10,
    height: 500,
  },
  root2: {
    marginTop: 40,
    marginLeft: 20,
    width: '90%',
    borderRadius: 10,
    height: 40,
  },
  content: {
    backgroundColor: '#002A45',
    height: 25,
  },
  title: {
    marginTop: -10,
    fontSize: 14,
    opecity: 1,
    color: 'white',
    fontWeight: 'bold',
  },
  pos: {
    marginBottom: 12,
  },
});

const columns: GridColDef[] = [
  { field: 'filename', headerName: 'Filename', width: 350 },
  { field: 'size', headerName: 'Size', width: 150 },
  { field: 'id', headerName: 'Id', width: 300 },
  { field: 'date', headerName: 'Date', width: 200 },
];

export default function AllocationTest() {
  const { appJWT } = useAppToken();
  const history = useHistory();
  const inputRef = useRef();
  const { setSnack } = useContext(ProjectContext);
  const classes = useStyles();
  const queryParams = window.location.pathname;
  const id = queryParams.split('/')[2];

  const [paramId, setParamId] = useState(id);
  const [iconLoad, setIconload] = useState(false);

  const [uploadedAPIResult, setUploadedAPIResult] = useState<any>([]);
  const [checkAState, setCheckAState] = useState<boolean>(false);
  const [activityName, setActivityName] = useState<any>('');
  const [getAllFilesAPI, setGetAllFilesAPI] = useState<any>([]);
  const [loadingTable, setLoadingTable] = useState<boolean>(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [templateIdHere, setTemplateIdHere] = useState<any>('');

  const [submitLoader, setSubmitLoader] = useState<boolean>(false);

  useEffect(() => {
    setSelectedIds([]);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    getTemp();
  }, []);

  function getTemp() {
    getAllFiles()
      .then((result) => {
        setGetAllFilesAPI(result);
        if (typeof result === 'string') {
          setSnack(result, 'error');
          setUploadedAPIResult([]);
        } else {
          const rr = result[0].resources;
          // console.log(rr);
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          // console.log('result: ', addIdHere(rr));
          setSnack('Files fetched successfully!', 'success');
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          setUploadedAPIResult(addIdHere(rr));
          setTemplateIdHere(result[0].id);
        }
      })
      .catch((err) => {
        setUploadedAPIResult([]);
        console.log(err);
      });
  }

  // Assuming this is where you want to add the ID
  const addIdHere = (result) => {
    // Add an id to each object in the result array
    const resultWithIds = result.map((item) => ({ ...item, id: generateUniqueId() }));
    // Use the result with the ids
    return resultWithIds;
  };

  const handleChangeCheckedA = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckAState(event.target.checked);
    // console.log('checkAState', event.target.checked);
  };
  const filehandleChangeFiles = (event: any) => {
    const e = event?.target?.files || '';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    handleChangeFiles(e);
  };

  const handleChangeFiles = (event: any) => {
    try {
      const len = Object?.keys(event)?.length;
      const arraylist = [];
      for (let i = 0; i < len; i += 1) {
        arraylist.push(event[i]);
      }
      for (const eachFile of arraylist) {
        const ext = eachFile?.name?.split('.');
        if (!['xlsx'].includes(ext[ext?.length - 1])) {
          setSnack(`${eachFile.name} not supported`, 'error');
          return;
        }
        if (eachFile.name?.length >= 80) {
          setSnack('file name should be less than 80 characters', 'error');
          return;
        }
      }

      const formdata = new FormData();
      const names = arraylist.map((e: any) => {
        formdata.append('files', e);
        return e.name;
      });
      for (const eachFile of names) {
        // const fileNameWithoutExt = eachFile.split('.')[0];
        // console.log('fileNameWithoutExt', fileNameWithoutExt, fileNameWithoutExt.match(format));
        // if (fileNameWithoutExt.match(format)) {
        //   setSnack(`File name ${eachFile} contains special character not allowed`, 'error');
        //   return;
        // }
        // if (names.includes(eachFile)) {
        //   setSnack(`File name ${eachFile} already exists.`, 'error');
        //   return;
        // }
        // for (const eachFiles of files) {
        //   if (eachFiles.filename === filenameToCheck) {
        //     setSnack(`File name ${filenameToCheck} already exists.`, 'error');
        //     return;
        //   }
        // }
      }

      if (formdata.get('files')) {
        setIconload(true);
        const uploadUrl = '';
        uploadURLCall_process(uploadUrl, formdata)
          .then((result) => {
            // console.log(result);
            // console.log('result: ', typeof result);
            setIconload(false);
            if (typeof result === 'string') {
              setSnack(result, 'error');
              setUploadedAPIResult([]);
            } else {
              const rr = result[0].resources;
              // console.log(rr);
              // console.log('result: ', addIdHere(rr));
              setSnack('Uploaded successfully!', 'success');
              setUploadedAPIResult(addIdHere(rr));
              setTemplateIdHere(result[0].id);
            }
          })
          .catch((err) => {
            setUploadedAPIResult([]);
            console.log(err);
          });
      }
    } catch (e) {
      console.log(e);
    }
  };

  function passforCapture() {
    console.log('call process api here');
  }

  const handleSelectionModelChange = (newSelection) => {
    setSelectedIds(newSelection);
  };

  const editTemplate = () => {
    if (selectedIds.length === 0) {
      setSnack('Plese select a template', 'error');
    } else {
      history.push(`/editTemplate/${selectedIds}`);
    }
  };

  const testTemplate = () => {
    if (selectedIds.length === 0) {
      setSnack('Plese select a template', 'error');
    } else {
      history.push(`/allocationTest/${selectedIds}`);
    }
  };

  const passToProcess = () => {
    console.log('pass to process');
  };

  const handleProcessFile = () => {
    if (selectedIds.length === 0) {
      setSnack('Plese select a file', 'error');
    } else {
      const selectedObjects = uploadedAPIResult.filter((item) => selectedIds.includes(item.id));
      // console.log('selectedObjects', selectedObjects);
      getTemplatTableData(paramId)
        .then((data) => {
          // console.log('data', data);
        })
        .catch((error) => {
          console.log('error', error);
        });
      iCaptureExcel({
        FileName: selectedObjects[0]?.filename,
        FilePath: selectedObjects[0]?.container,
        Container: selectedObjects[0]?.container,
        BlobName: selectedObjects[0]?.filename,
        FileType: 'xlsx',
        Sheet: 'Sheet1',
        TargetColumn: 'text',
        ColumnNames: ['text'],
        Header: true,
        NameOfCapture: `${selectedObjects[0]?.filename}_${generateUniqueId().slice(-4)}`,
      })
        .then((res) => {
          // console.log('res capture', res);
          if (res?.message === 'Non-Extractable') {
            setSnack('File is Non-Extractable. Please select another file', 'error');
          } else {
            setSnack('File is Extractable', 'success');
            getTemplatTableData(paramId)
              .then((data) => {
                // console.log('labels and training data: ', data);
                const descrArr: any = [];
                const labelArr: any = [];
                const textArr: any = [];
                const traininglabelArr: any = [];
                // eslint-disable-next-line array-callback-return
                data?.labels?.map((val, i) => {
                  descrArr.push(val?.description);
                  labelArr.push(val?.allocation);
                });
                // eslint-disable-next-line array-callback-return
                data?.training?.map((val, i) => {
                  textArr.push(val?.requirement_text);
                  traininglabelArr.push(val?.expert_allocation);
                });
                setSnack('Getting labels & Training Data', 'success');
                post_icapture_allocator_workbench_allocation({
                  param_render: {
                    labels_df: {
                      dataframe: {
                        description: descrArr,
                        label: labelArr,
                      },
                    },
                    train_df: {
                      dataframe: {
                        text: textArr,
                        label: traininglabelArr,
                      },
                    },
                  },
                  param_run: {
                    dataframe: {
                      Text: res.output.Text,
                    },
                  },
                  template_id: paramId,
                  name: `${selectedObjects[0]?.filename}_${generateUniqueId().slice(-4)}`,
                  file_name: selectedObjects[0]?.filename,
                })
                  .then((res) => {
                    // console.log(res);
                    history.push(`/allocationActivity`);
                    // setTimeout(() => {
                    //   get_allocationActivity()
                    //     .then((res) => {
                    //       console.log('res', res);
                    //       setSnack('Activity completed', 'success');
                    //     })
                    //     .catch((err) => {});
                    // }, 4000);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              })
              .catch((error) => {
                console.log('error', error);
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getFile = async () => {
    try {
      // Set the snack message before starting the download
      setSnack('Downloading the sample template file...', 'info');
      // Call the get_template_file API to get the file URL or content
      const response = await axios.get(
        `${BACKEND_URL.root}/download/samplefile/allocator/allocation`,
        {
          responseType: 'blob', // Important for handling binary data
        }
      );
      // Create a link element
      const link = document.createElement('a');
      const url = window.URL.createObjectURL(new Blob([response.data]));
      link.href = url;
      link.setAttribute('download', 'sample_upload_file.xlsx'); // Specify the file name
      // Append the link to the body
      document.body.appendChild(link);
      // Programmatically click the link to trigger the download
      link.click();
      // Remove the link from the DOM
      link.parentNode?.removeChild(link);
      // Clear the snack message after the download starts
    } catch (error) {
      console.error('Error downloading the template file:', error);
      setSnack('Error downloading the template file', 'error');
    }
  };

  return (
    <>
      <Card className={classes.root}>
        <CardContent className={classes.content}>
          <div>
            <div style={{ float: 'left' }}>
              <Typography className={classes.title} color="textSecondary" gutterBottom>
                Template Id: {paramId}
              </Typography>
            </div>
            <div style={{ float: 'right' }}>
              <Typography className={classes.title} color="textSecondary" gutterBottom>
                <span>
                  <Button onClick={getFile} style={{ color: 'white' }}>
                    Sample File
                  </Button>
                </span>
                <Button variant="contained" color="primary" onClick={history.goBack} size="small">
                  Back
                </Button>
              </Typography>
            </div>
          </div>
          <Grid>
            <Tooltip title="upload file">
              <FormLabel
                style={{
                  marginTop: '2rem',
                  margin: 'auto',
                  backgroundColor: 'White',
                  boxShadow: '0px 4px 8px #00000029',
                  width: '3rem',
                  color: '#002A45',
                  height: '3rem',
                  borderRadius: '50%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                {iconLoad ? (
                  <CircularProgress style={{ color: '#002A45' }} size={10} />
                ) : (
                  <PublishIcon />
                )}
                <input
                  type="file"
                  multiple
                  ref={inputRef}
                  hidden
                  onChange={filehandleChangeFiles}
                />
              </FormLabel>
            </Tooltip>
          </Grid>
        </CardContent>
      </Card>

      {uploadedAPIResult && (
        <Grid style={{ marginTop: 10 }}>
          <Typography>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <span>
              <b>Uploaded File details:</b>
            </span>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Button variant="contained" color="secondary" size="small" onClick={handleProcessFile}>
              Proceed
            </Button>
          </Typography>
          <br />
          <Grid container className={classes.root1}>
            {uploadedAPIResult.length > 0 ? (
              <>
                <DataGrid
                  rows={uploadedAPIResult}
                  columns={columns}
                  checkboxSelection
                  selectionModel={selectionModel}
                  disableMultipleRowSelection
                  onRowSelectionModelChange={(newSelection) =>
                    handleSelectionModelChange(newSelection)
                  }
                  slots={{ toolbar: GridToolbar }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                    },
                  }}
                />
              </>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
}
